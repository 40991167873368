import cleanedFilename from '~/util/cleanedFilename';

/**
 * This code can be loaded outside of a browser, so we lazy initialize this
 */
let canvas;

export const measureTextWidth = (text: string, fontSize: number): number => {
  if (canvas == null) {
    canvas = document.getElementById('drawing-canvas');
  }

  if (!(canvas instanceof HTMLCanvasElement)) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} -> canvasDrawers trying to create an image where the canvas element is not of type canvas`,
    );
  }

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx.font = `${fontSize}px Metropolis`;

  return ctx.measureText(text).width;
};
