import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import { Heading2 } from '~/components/atom/Typography';
import Loading from '~/components/atom/Loading';
import { useGetWidgetSettingsQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ErrorScreen from '~/components/page/ErrorScreen';
import MasterDetail from '~/components/template/MasterDetail';
import TEST_ID_MASTER_DETAIL from '~/components/template/MasterDetail/index.testid';
import Tokens from './components/Tokens';
import useApp from '~/hooks/useApp';
import groupNameToAnchorId from '~/components/organism/SelectableAccordion/utils/groupNameToAnchorId';

type Props = RouteComponentProps & {};

export const text = {
  appTitle: 'Trustoo',
};

const MAX_CONTAINER_WIDTH = '1400px';
const Trustoo: React.FCC<Props> = () => {
  const account = useCurrentAccount();
  const { data, loading } = useGetWidgetSettingsQuery({
    variables: {
      accountId: account.id,
    },
  });

  const { app } = useApp('AppStatus_Trustoo');

  const trustooAppsInWidget = data?.getWidgetSettings?.appsConfiguration.find(
    app => app.type === 'AP_AppStatus_Trustoo',
  );
  const trustooWidgetAppsId = trustooAppsInWidget?.category;

  const pathToWidget = trustooWidgetAppsId
    ? `widget/apps#${groupNameToAnchorId(trustooWidgetAppsId)}`
    : 'widget/apps';

  if (loading) return <Loading />;
  if (!data || !app?.appStatus.enabled) {
    return <ErrorScreen />;
  }

  return (
    <ContentContainerDefault
      maxContentWidth={MAX_CONTAINER_WIDTH}
      breadcrumbs={[
        {
          to: '/-/apps',
          label: 'Apps',
        },
        { label: text.appTitle },
      ]}
    >
      <MetaTags>
        <title>{text.appTitle}</title>
      </MetaTags>
      <Heading2 color={{ group: 'primary' }} margin={[null, null, 'xl', null]}>
        {text.appTitle}
      </Heading2>
      <MasterDetail
        basePath={'/-/'}
        dataTestId={TEST_ID_MASTER_DETAIL.CONTAINER}
        navbar={[
          {
            type: 'link',
            to: 'apps/trustoo',
            icon: 'incomingLink',
            name: 'Koppeling',
          },
          {
            type: 'link',
            to: pathToWidget,
            icon: 'externalLink',
            name: 'Dienstverleners in de widget',
          },
        ]}
      >
        <React.Component path={pathToWidget} />
        <Tokens path="/" default />
      </MasterDetail>
    </ContentContainerDefault>
  );
};

export default Trustoo;
