export enum INPUT_BUTTON_TYPE {
  SUCCESS = 'SUCCES',
  PENDING = 'PENDING',
  DANGER = 'DANGER',
  ACCENT = 'ACCENT',
}

export type InputButtonProps = {
  /** The icon to show */
  icon: React.ReactNode;

  /** Styling based on button type */
  type: INPUT_BUTTON_TYPE;

  /** Tooltip to show */
  tooltipText: string | null;

  /** What to do on click, will be a status indicator if none given */
  onClick?: () => void;
};

export type InputPropsBase = {
  //...HTMLInputElement, <-- Wait for actual input props, see https://github.com/facebook/flow/pull/7569
  onBlur?: (arg0: React.FocusEvent<any> | null | undefined) => void;
  onChange?: (arg0: React.SyntheticEvent<any> | any) => void;
  onFocus?: (arg0: React.FocusEvent<any> | null | undefined) => void;
  onKeyPress?: (arg0: React.KeyboardEvent<any>) => void;

  /** Label to be displayed, will also act as placeholder if nothing is entered. */
  label?: string;

  /** name for the input */
  name?: string;

  /** Renderes a small element. */
  small?: boolean;

  /** Renderes a large element. */
  large?: boolean;

  /** Error, this replaces the label if its set. */
  error?: string | null | undefined;

  /** Disable the input */
  disabled?: boolean;

  /** Label to show before the value in the input */
  prependValue?: string;

  /** Label to show after the value in the input */
  appendValue?: string;

  /** Value of the input */
  value: string | null | undefined;

  /** Type of the input */
  type?: string | undefined;

  /** Function for getting Refs of input element*/
  setRef?: (refElement: React.ReactNode | HTMLInputElement) => void;

  /** If the input should be displayed with accent styling */
  accent?: boolean;

  /** Sidebutton */
  button?: InputButtonProps;

  /** If the input should only allow numbers in the field */
  onlyAllowNumbers?: boolean;

  placeholder?: string;

  size?: string;
  autoFocus?: boolean;

  dataTestId?: string;
};

export type InputProps = InputPropsBase;
