import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const Automation: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 76 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4001 36.4C22.4001 36.4 22.1001 36.4 27.2001 36.4C34.4001 36.4 33.8001 49.4 41.6001 49.4H45.1001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.1 23C45.1 23 45.4 23 40.3 23C33.1 23 33.7 36 25.9 36H22.4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.4 17.2H45.1V28.8H69.4V17.2Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.4 43.3H45.1V54.9H69.4V43.3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8187 36.4357L13.2021 26.8192L3.58559 36.4357L13.2021 46.0523L22.8187 36.4357Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2 21.5H53.2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2 21.5H66.0999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2 47.5H65.4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.2 50.6H59.2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {secondaryColor && (
        <>
          <circle cx="63.5" cy="18" r="12" fill={secondaryColor} />
          <path
            d="M63.5058 23.168C65.9698 23.168 67.7058 21.11 67.7058 18.184C67.7058 15.272 65.9698 13.214 63.5058 13.214C61.0278 13.214 59.2778 15.272 59.2778 18.184C59.2778 21.11 61.0278 23.168 63.5058 23.168ZM63.5058 21.572C62.0778 21.572 61.0698 20.172 61.0698 18.184C61.0698 16.196 62.0778 14.81 63.5058 14.81C64.9198 14.81 65.9278 16.196 65.9278 18.184C65.9278 20.172 64.9198 21.572 63.5058 21.572Z"
            fill="white"
          />
        </>
      )}
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default Automation;
