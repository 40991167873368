import React from 'react';
import {
  DELETE_ENTITY_TYPE,
  DELETION_TYPE,
} from '~/components/bad/Modals/util/constants';
import DeleteEntityModal from './DeleteEntityModal';
import { navigate } from '@gatsbyjs/reach-router';
import useIsMounted from '~/hooks/useIsMounted';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useSessionHydration from '~/hooks/useSessionHydration';
import { Highlight } from '~/components/atom/Typography';
import type { ListItemForDeletion } from './components/ListItemCard';
import { useDeleteOfficeMutation } from '~/graphql/types';

const text = {
  modalHeader: (
    <>
      Vestiging verwijderen uit je <Highlight>account</Highlight>{' '}
    </>
  ),
  modalDescription:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker of vestiging',
};

type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** The id of the office that we want to delete */
  officeId: string;
};
const DeleteOfficeModal: React.FCC<Props> = ({
  list,
  onClose,
  onCancel,
  officeId,
}) => {
  const [, refetchSessionHydration] = useSessionHydration();
  const isMounted = useIsMounted();
  const account = useCurrentAccount();

  const [deleteOffice, { error }] = useDeleteOfficeMutation();

  return (
    <DeleteEntityModal
      list={list}
      onClose={onClose}
      onCancel={onCancel}
      modalHeader={text.modalHeader}
      modalDescription={text.modalDescription}
      entityId={officeId}
      entityType={DELETE_ENTITY_TYPE.OFFICE}
      onSuccess={() => {
        if (!isMounted()) return;

        onClose();
        void navigate('/-/settings/offices');
        void refetchSessionHydration();
      }}
      onDelete={(changeSet, transferOfficeResourcesTo) =>
        deleteOffice({
          variables: {
            accountId: account.id,
            officeId: officeId,
            changeSet,
            transferOfficeResourcesTo,
          },
        }).then(result => {
          if (result == null || result.data == null) {
            return null;
          }

          return result.data.deleteOffice;
        })
      }
      mutationError={error}
      deletionType={DELETION_TYPE.DELETE_OFFICE}
    />
  );
};

export default DeleteOfficeModal;
