import React, { useState } from 'react';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import CopyCode from '~/components/atom/CopyCode';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';
import { Label, Variant } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';
import {
  widgetHelpLink,
  widgetScript,
} from '~/components/page/Widget/constants';

const text = {
  formattedEmailToCopy: (accountId: string) => `
  Beste heer / mevrouw,

  Zojuist heb ik bij DatHuis een account aangemaakt. Die leveren een widget voor op mijn website. Kan je onderstaande code op mijn website plaatsen?

  Bij voorbaat dank.

  ${widgetScript({ accountId })}
  `,
  copyLabel: 'E-mail kopiëren',
  header: 'Instructies voor jouw webbeheerder',
  header2: 'Of plak deze code in je website HTML',
  scriptInstructionsLinkLabel:
    'Bekijk hier de uitgebreide installatie en integratie instructies',
  sendTextToDeveloper:
    'Kopieer deze e-mail en stuur deze naar jouw webbeheerder',
  insertScriptYourself: 'Of kopieer alleen het script',
};

export type Props = {
  dataTestId?: string;
  accountId: string;
};

const SetupScriptManually: React.FCC<Props> = ({
  dataTestId,
  accountId,
  ...rest
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(true);

  return (
    <CollapsibleChevronBlock
      isOpen={isExpanded}
      data-testid={dataTestId}
      header={
        <Label size="m" withoutMargin variant={Variant.primary}>
          {text.header}
        </Label>
      }
      onToggle={() => setExpanded(prev => !prev)}
      {...rest}
    >
      <JustificationContainer direction="column" width="100%" gap="m">
        <CopyCode
          label={
            <Label
              withoutMargin
              fontWeight="semiBold"
              variant={Variant.primary}
              size="base"
            >
              {text.sendTextToDeveloper}
            </Label>
          }
          dataTestId={TEST_ID.CODE_SNIPPET}
          code={text.formattedEmailToCopy(accountId)}
          copyLabel={text.copyLabel}
        />

        <CopyCode
          label={
            <Label
              withoutMargin
              fontWeight="semiBold"
              variant={Variant.primary}
              size="base"
            >
              {text.insertScriptYourself}
            </Label>
          }
          dataTestId={TEST_ID.CODE_SNIPPET}
          code={widgetScript({ accountId })}
        />
        <Link to={widgetHelpLink} target="_blank">
          {text.scriptInstructionsLinkLabel}
        </Link>
      </JustificationContainer>
    </CollapsibleChevronBlock>
  );
};

export default SetupScriptManually;
