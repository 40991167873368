import { createGlobalStyle, css } from 'styled-components';
import { WithTheme } from '../';

export const DH_UPLOADING_IMAGE_CLASS = 'dh-uploading-image';

type GlobalStyleProps = WithTheme;
export default createGlobalStyle<GlobalStyleProps>`
  *, *:after, *:before {
    box-sizing: border-box;
  }

  *:focus { outline: none; }

  html {
    overflow-y: scroll;
  }

  html, body {
    ${({ theme }) => css`
      font-family: 'Metropolis', 'Helvetica Neue', 'Helvetica', 'Arial',
        sans-serif;
      color: ${theme.color('text')};
      font-size: ${theme.getTokens().type.baseFontSize};
      line-height: ${theme.lineHeight('base')};
    `}
    margin: 0;
    padding: 0;
  }

  #___gatsby, #modal-portal {
    ${({ theme }) => css`
      font-size: ${theme.getTokens().type.baseFontSize};
    `}
  }

  button, input {
    margin: 0;
    ${({ theme }) => css`
      line-height: ${theme.lineHeight('s')};
    `}
  }

   optgroup, select, textarea  {
    margin: 0;
  }

  input, select, textarea, button {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }

  /** To disable auto-fill background color in stripe elements */
  .StripeElement--webkit-autofill {
    background-color: transparent !important;
  }

  #modal-portal {
    float: left;
  }

  [data-redacted="true"],
  [data-blurred="true"] {
    filter: blur(0.15em);
  }

`;
