import React from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
// import UpgradePlanButton from '~/components/UpgradePlanButton';
import IntercomButton from '../IntercomButton';
import UpgradePlanButton from '~/components/organism/UpgradePlanButton';
import type {
  ButtonAction,
  StrapiButtons,
} from '~/components/template/ConversionPage/types';

export type Props = {
  buttons: StrapiButtons;
};

const StrapiButtons: React.FC<Props> = ({ buttons }) => (
  <JustificationContainer margin={['l', null, null, null]} gap="m">
    {buttons.data.map(({ attributes, id }) => {
      const ActionButton = getButtonComponent(attributes.button.action);

      return (
        <ActionButton
          key={'button_upgrade_' + id}
          label={attributes.button.label}
          icon={attributes.button.icon.name}
          appearance={attributes.button.appearance}
          ghost={attributes.button.ghost}
          size="large"
        />
      );
    })}
  </JustificationContainer>
);

const getButtonComponent = (action: ButtonAction) => {
  switch (action) {
    case 'OPEN_INTERCOM':
      return IntercomButton;
    case 'OPEN_UPGRADE':
      return UpgradePlanButton;

    default:
      return () => null;
  }
};

export default StrapiButtons;
