import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import AccountSwitcher from '~/components/organism/AccountSwitcher';
import FullPageInformation from '~/components/template/FullPageInformation';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';

type Props = {
  dataTestId?: string;
  title: string;
  pageTitle: string;
  explanation: string;
  withAccountSwitcher?: boolean;
};

const StatusPage: React.FCC<Props> = ({
  dataTestId,
  pageTitle,
  title,
  explanation,
  withAccountSwitcher = true,
}) => (
  <JustificationContainer
    dataTestId={dataTestId}
    width="100%"
    height="100vh"
    justification="center"
    align="center"
    backgroundColor={{ group: 'white', variant: 'dark' }}
  >
    <MetaTags>
      <title>{pageTitle}</title>
    </MetaTags>
    <JustificationContainer align="center" direction="column">
      <FullPageInformation
        dataTestId={TEST_ID.CONTAINER}
        title={title}
        explanation={explanation}
      />
      {withAccountSwitcher === true && <AccountSwitcher />}
    </JustificationContainer>
  </JustificationContainer>
);

export default StatusPage;
