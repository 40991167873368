import gql from 'graphql-tag';

import TaskListTaskFields from './TaskListTaskFields';

export default gql`
  fragment ActivityFields on Activity {
    __typename
    _v
    id
    accountId
    category
    createdDate

    source {
      ... on Actor_Contact {
        __typename
        id
        name
      }

      ... on Actor_User {
        __typename
        id
        name
      }

      ... on Actor_App {
        __typename
        name
      }

      ... on Actor_Flow {
        __typename
        flowBlueprintId
        flowBlueprintActionId
      }
    }

    ... on Event_Contact_Generic {
      # we do not add the body here as it is very large and not needed everywhere
      highlighted
      sortingDate
      icon {
        url
      }
      header {
        text
      }
      summary
      attachments {
        src
        fileName
        fileType
        label
      }
      activityType
    }

    ... on Event_Contact_Email {
      deleted
      receivedAt
      highlighted
      sortingDate
      summary
      header {
        text
      }
      icon {
        url
      }
      hasMore
      subject
      snippet
      deliveryStatus
      deliveryErrorMessage
      activityType
      from {
        name
        email
      }
      to {
        name
        email
      }
      cc {
        name
        email
      }
      bcc {
        name
        email
      }
      tracking {
        opened
        clicked {
          details {
            url
            count
          }
          total
        }
      }
    }

    ... on Task_Contact {
      ...TaskListTaskFields
    }
  }

  ${TaskListTaskFields}
`;
