import React, { useRef, useState, useEffect, Ref, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import usePrevious from '~/hooks/usePrevious';

type Props = {
  /** Possible styled component className added when open */
  className?: string;

  /** if the component is open */
  isOpen: boolean;

  /** If the overflow should be hidden (will hide shadows/absolute positioned items) -
   * Still needed sometimes because microsoft edge behaves differently. Once we do no support the non-chromium version we can remove this*/
  useOverflowHidden?: boolean;

  /** Sends down:
   *  - the ref to be attached to the element that should be checked for full height
   */
  children: (ref: Ref<any>) => ReactNode;

  width?: string;
};

const SlideOpenAnimationContainer = ({
  children,
  isOpen,
  className,
  useOverflowHidden,
  width = 'auto',
}: Props) => {
  const componentRef = useRef<HTMLElement | null>(null);
  const animationTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [height, setHeight] = useState(0);
  const [isAnimating, setAnimating] = useState(false);

  // clearTimeout on unmount
  useEffect(
    () => () => {
      if (animationTimer.current != null) {
        clearTimeout(animationTimer.current);
      }
    },
    [],
  );

  const prevIsOpen = usePrevious(isOpen);
  const justChanged = prevIsOpen != isOpen;

  // If isOpen changed, start animating
  useEffect(() => {
    if (prevIsOpen != null && prevIsOpen !== isOpen) {
      setAnimating(true);

      // Stop animation at the end
      animationTimer.current = setTimeout(() => {
        setAnimating(false);
      }, SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS * 1000);
    }
  }, [isOpen, prevIsOpen]);

  // keep the height up to date for animation
  const recalculateHeight = () => {
    let newHeight = 0;
    if (componentRef.current) {
      newHeight = componentRef.current.getBoundingClientRect().height;
    }

    if (newHeight !== height) {
      setHeight(newHeight);
    }
  };

  // recalculate the height AFTER every rerender
  useEffect(recalculateHeight);

  return (
    <AnimationContainer
      $width={width}
      isOpen={isOpen}
      height={height}
      isAnimating={isAnimating}
      forceHeightInCss={justChanged}
      className={className}
      useOverflowHidden={useOverflowHidden}
    >
      {children(componentRef)}
    </AnimationContainer>
  );
};

export const SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS = 0.3;
type AnimationContainerProps = {
  height: number;
  isOpen: boolean;
  isAnimating: boolean;
  forceHeightInCss: boolean;
  useOverflowHidden?: boolean;
  $width: string;
};
const AnimationContainer = styled.div<AnimationContainerProps>`
  ${({
    height,
    isOpen,
    isAnimating,
    forceHeightInCss,
    useOverflowHidden,
    $width,
  }) => {
    const withAnimationStyle = css`
      width: ${$width};
      transition:
        height ${SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS}s ease-out,
        opacity ${SLIDE_OPEN_ANIMATION_DURATION_IN_SECONDS}s ease-out;
    `;
    const animatingOverflowStyle = `
      ${useOverflowHidden ? 'overflow-y: hidden;' : ''}
    `;

    if (isOpen) {
      // first step of opening is to set the initial height
      if (forceHeightInCss) {
        return css`
          ${withAnimationStyle}

          height: ${height}px;
        `;
      } else if (isAnimating) {
        return css`
          ${withAnimationStyle}
          ${animatingOverflowStyle}

            height: ${height}px;
        `;
      } else {
        return css`
          ${withAnimationStyle}
        `;
      }
    } else {
      // Is closing
      // first step of closing is to set the initial height
      if (forceHeightInCss) {
        return css`
          ${withAnimationStyle}

          height: ${height}px;
        `;
      } else if (isAnimating) {
        return css`
          ${withAnimationStyle}
          ${animatingOverflowStyle}

          height: 0px;
        `;
      } else {
        return css`
          ${withAnimationStyle}
          overflow: hidden;
          display: none;
          height: 0px;
        `;
      }
    }
  }};
`;

export default SlideOpenAnimationContainer;
