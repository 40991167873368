import React, { ReactElement, Component } from 'react';
import styled, { css } from 'styled-components';

import { InputProps, INPUT_BUTTON_TYPE } from './Input.type';

import InputGroupElement from './InputGroupElement';
import InputElement from './InputElement';

import InputGroupButton from './InputGroupButton';
import { onlyDigits } from '~/util/string';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Tooltip from '~/components/molecule/Tooltip';
import useHover from '~/components/bad/util/useHover';
import chainCallback from '~/util/chainCallback';
import FloatingLabel from '~/components/atom/FloatingLabel';

type State = {
  hasFocus: boolean;
  onChangeEventObject: React.SyntheticEvent<any, Event> | null;
};

/**
 * Input Element
 *
 * Should pretty much always be used in combination with a InputGroup
 * in order to have consistent styling.
 */
type Props = InputProps & {
  showTooltip: boolean;
  tooltipProps: { onMouseOver: () => void; onMouseOut: () => void };
};
class Input extends Component<Props, State> {
  static defaultProps = {
    large: false,
    disabled: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hasFocus: false,
      onChangeEventObject: null,
    };
  }

  onKeyPress = (event: React.KeyboardEvent<any>) => {
    if (event.key === 'Enter' && this.props.type === 'email') {
      event.preventDefault();
    }

    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
    }
  };

  gotFocus = () => {
    this.setState({ hasFocus: true });
  };

  gotChange = (e: React.SyntheticEvent<any, Event>) => {
    e.persist();
    const { onChange, onlyAllowNumbers } = this.props;

    const target = e.target as HTMLInputElement;
    if (onlyAllowNumbers === true && target.value != null) {
      target.value = onlyDigits(target.value);
    }

    this.setState({ onChangeEventObject: e });

    if (onChange != null) {
      onChange(e);
    }
  };

  lostFocus = () => {
    const { onChangeEventObject } = this.state;
    const { type, value, onChange } = this.props;

    this.setState({ hasFocus: false });
    if (type !== 'password') {
      const convertedValue = value == null ? '' : value;
      const trimmedValue = convertedValue.trim
        ? convertedValue.trim()
        : convertedValue;

      if (typeof onChange === 'function') {
        let valueToChange;

        if (onChangeEventObject != null) {
          const changedEvent = {
            ...onChangeEventObject,
            target: {
              ...onChangeEventObject.target,
              value: trimmedValue,
            },
          };
          valueToChange = changedEvent;
        } else {
          valueToChange = trimmedValue;
        }

        onChange(valueToChange);
      }
    }
  };

  render() {
    const {
      label,
      error,
      large,
      small,
      value,
      onFocus,
      onBlur,
      prependValue,
      appendValue,
      setRef,
      button,
      dataTestId,
      autoFocus,
      showTooltip,
      tooltipProps,
      ...rest
    } = this.props;

    const { hasFocus } = this.state;
    // The value in the input should always be a string. So convert any undefined/null values to ''
    let valueToShow = value == null ? '' : value;

    // If we don't have focus, pre/append stuff to the value to show the user
    if (!hasFocus) {
      if (valueToShow && typeof prependValue === 'string')
        valueToShow = `${prependValue}${valueToShow}`;
      if (valueToShow && typeof appendValue === 'string') {
        valueToShow = `${valueToShow}${appendValue}`;
      }
    }
    const refsProps: {
      ref?: (refElement: React.ReactNode) => void;
    } = {};
    if (typeof setRef === 'function') {
      refsProps.ref = setRef;
    }

    let buttonComponent: ReactElement | null = null;
    if (button != null) {
      const { icon, type, onClick, tooltipText } = button;

      const buttonComponentProps = {
        onClick,
        small,
        large,
        success: false,
        successOnHover: false,
        danger: false,
        dangerOnHover: false,
        pending: false,
        pendingOnHover: false,
        accent: false,
        accentOnHover: false,
      };
      if (type === INPUT_BUTTON_TYPE.SUCCESS) {
        buttonComponentProps.success = true;
        buttonComponentProps.successOnHover = true;
      } else if (type === INPUT_BUTTON_TYPE.PENDING) {
        buttonComponentProps.pending = true;
        buttonComponentProps.pendingOnHover = true;
      } else if (type === INPUT_BUTTON_TYPE.ACCENT) {
        buttonComponentProps.accent = true;
        buttonComponentProps.accentOnHover = true;
      } else {
        buttonComponentProps.danger = true;
        buttonComponentProps.dangerOnHover = true;
      }

      buttonComponent = tooltipText ? (
        <JustificationContainer align="end" justification="end">
          <StyledInputGroupButton {...buttonComponentProps} {...tooltipProps}>
            {icon}
          </StyledInputGroupButton>
          {showTooltip && <StyledTooltip>{tooltipText}</StyledTooltip>}
        </JustificationContainer>
      ) : (
        <JustificationContainer align="end" justification="end">
          <StyledInputGroupButton {...buttonComponentProps}>
            {icon}
          </StyledInputGroupButton>
        </JustificationContainer>
      );
    }

    const inputElement = (
      // @ts-ignore
      <InputElement
        data-testid={dataTestId}
        {...rest} /// set refs props if ref cb function is exist or set nothing
        {...refsProps}
        autoFocus={autoFocus}
        value={valueToShow}
        error={error}
        onKeyPress={this.onKeyPress}
        data-error={error} // Forward the events to hoc if necessary
        onChange={this.gotChange}
        onFocus={chainCallback(onFocus, this.gotFocus)}
        onBlur={chainCallback(onBlur, this.lostFocus)}
        large={large}
        small={small}
        // size is completely incompatible just set it to undefined to please TS
        size={undefined}
      />
    );

    let component =
      buttonComponent != null ? (
        <InputElementContainer>
          {inputElement}
          {buttonComponent}
        </InputElementContainer>
      ) : (
        inputElement
      );

    if (label != null) {
      // Add a label to it
      const actAsPlaceholder = !hasFocus && valueToShow === '' && !error;
      component = (
        <Label>
          <FloatingLabel
            actAsPlaceholder={actAsPlaceholder}
            error={error}
            large={large}
            small={small}
          >
            {error || label}
          </FloatingLabel>
          {component}
        </Label>
      );
    }

    return component;
  }
}

export const InputWithTooltip: React.FCC<InputProps> = props => {
  const [showTooltip, tooltipProps] = useHover();

  return (
    <Input {...props} showTooltip={showTooltip} tooltipProps={tooltipProps} />
  );
};

const StyledTooltip = styled(Tooltip)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('base')};
    margin: ${theme.space('xxl')} 0;
  `,
);

const Label = styled.label<{}>`
  display: block;
  width: 100%;
`;

const StyledInputGroupButton = styled(InputGroupButton)<{}>(
  () => css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `,
);

const InputElementContainer = styled.div<{}>`
  display: flex;

  > :nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }

  > :nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export default InputGroupElement<InputProps>(Input);
