import styled, { css } from 'styled-components';

export type Props = {
  color: string;
  active?: boolean;
};

const LegendIcon = styled.div<Props>(
  ({ color, active, theme }) => css`
    height: ${theme.space('m')};
    width: ${theme.space('m')};
    min-width: ${theme.space('m')};
    background-color: ${active ? color : theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: background-color 0.2s ease-out;
  `,
);

export default LegendIcon;
