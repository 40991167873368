import React from 'react';
import {
  interactionState,
  type FormBuilder_Interaction,
  type InteractionState,
} from '../../state';
import { useRecoilState, useSetRecoilState } from 'recoil';
import EditNodeScreen, {
  type ScreenFormState,
} from './components/EditNodeScreen';
import EditNodeEvent, {
  type EventNodeFormState,
} from './components/EditNodeEvent';
import EditEvent from './components/EditEvent';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Modal from '~/components/organism/ModalsV2/Modal';
import { nodeById } from '../../state/nodesAndEvents';

type Props = {};

const InteractionHandler: React.FCC<Props> = () => {
  const [interaction, setInteraction] = useRecoilState(interactionState);
  const setNode = useSetRecoilState(nodeById(interaction?.subjectId ?? ''));

  if (interaction === null) return null;
  const Component = getInteractionComp(interaction);

  if (Component === null) return null;

  const onCancel = () => setInteraction(null);
  const onSave = (update: ScreenFormState | EventNodeFormState) => {
    setNode(update);
    setInteraction(null);
  };

  return (
    <Overlay onClose={onCancel}>
      <Modal onClose={onCancel} maxWidth="90%">
        <Component
          id={interaction.subjectId ?? ''}
          onCancel={onCancel}
          onSave={onSave}
        />
      </Modal>
    </Overlay>
  );
};

export type EditProps<T> = {
  id: string;
  onCancel: () => void;
  onSave: (update: T) => void;
};

const interactionMap: Record<
  FormBuilder_Interaction,
  React.FC<EditProps<ScreenFormState | EventNodeFormState>>
> = {
  'edit-event': EditEvent,
  'edit-node-event': EditNodeEvent,
  'edit-node-screen': EditNodeScreen,
};

const getInteractionComp = (interaction: InteractionState) =>
  interaction ? interactionMap[interaction.interactionType] : null;

export default InteractionHandler;
