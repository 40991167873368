import { pluck } from 'ramda';
import React, { useState } from 'react';
import Input from '~/components/molecule/Input';
import { Props as FormProps } from '../ActionForm';
import Validation from '~/util/Validation';
import { useRecoilValue } from 'recoil';
import { issuesByActionId } from '~/components/page/Automation/v2/state/flowIssues';
import { messages } from '../../utils/getIssuesForAction/validationMap';
import {
  ClientFlowActionContactAddTag,
  ClientFlowActionContactDeleteTag,
} from '~/graphql/types.client';

export type Props = FormProps & {
  dataTestId?: string;
  action: ClientFlowActionContactAddTag | ClientFlowActionContactDeleteTag;
};

const text = {
  placeholder: 'Waarde',
  errorMsg: messages.tag.tagInvalid,
  label: 'Voer een tag in',
};

const ContactTagAction: React.FCC<Props> = ({ action, onChange }) => {
  const issues = useRecoilValue(issuesByActionId(action.id));
  const [tag, setTag] = useState(action.name);
  const [error, setError] = useState<Array<string>>(pluck('message', issues));

  const onHandleChange = (tag: string) => {
    const nextTag = Validation.Tag.format(tag);
    setTag(nextTag);
    onChange({ ...action, name: nextTag });

    return !Validation.Tag.isValid(tag)
      ? setError([text.errorMsg])
      : setError([]);
  };

  return (
    <Input
      name="tag-input"
      value={tag}
      externalErrors={error}
      onChange={e => onHandleChange(e.target.value)}
      label={text.label}
      placeholder={text.placeholder}
    />
  );
};

export default ContactTagAction;
