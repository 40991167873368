import React from 'react';
import type { InputBlockType } from '~/components/page/Forms/components/Builder/utils/isInputTypeBlock';
import BlockLayout from '../BlockLayout';
import LocalesInput from '../LocalesInput';
import Dropdown, { type OptionOf } from '~/components/molecule/Dropdown';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import Catalog from '~/Catalog';
import type { FormBuilder_ScreenNode_Block } from '~/graphql/types';
import { BLOCK_OPTIONS } from '../../constants';

type Props = {
  onChange: (next: InputBlockType) => void;
  nodeId: string;
  block: InputBlockType;
};

const text = {
  required: Catalog.requiredField,
  changeInputType: 'Invoertype wijzigen',
};

const componentsToSelect: Array<FormBuilder_ScreenNode_Block['__typename']> = [
  'FormData_Input_Email',
  'FormData_Input_Integer',
  'FormData_Input_Multiline',
  'FormData_Input_Text',
];

const selectComponentOptions: Array<OptionOf<string>> = BLOCK_OPTIONS.filter(
  option => componentsToSelect.includes(option.payload),
);

const InputForType: React.FCC<Props> = ({ onChange, nodeId, block }) => (
  <BlockLayout blockKey={block.key} nodeId={nodeId}>
    <Dropdown
      onChange={option =>
        onChange({ ...block, __typename: option.option.payload })
      }
      options={selectComponentOptions}
      selectedOptionIdx={selectComponentOptions.findIndex(
        opt => opt.payload === block.__typename,
      )}
      label={text.changeInputType}
    />
    <LocalesInput
      block={block}
      onChange={onChange}
      subject="label"
      blockKey={block.key}
      nodeId={nodeId}
    />
    <ToggleCheckbox
      label={text.required}
      size="small"
      value={block.required}
      onChange={() => onChange({ ...block, required: !block.required })}
      containerProps={{
        justification: 'space-between',
        direction: 'row',
        align: 'center',
        width: '100%',
      }}
      disabled={block.__typename === 'FormData_Input_Contact_Email'}
    />
  </BlockLayout>
);

export default InputForType;
