import React from 'react';
import Div, { type Props as DivProps } from '~/components/atom/Div';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = DivProps;

const Toolbar: React.FCC<Props> = ({
  dataTestId,
  children,
  margin = [null],
  ...rest
}) => (
  <Div
    data-testid={dataTestId}
    margin={margin}
    backgroundColor={{ group: 'white' }}
    border={{ radius: 'base' }}
    padding={['xxs']}
    width="100%"
    {...rest}
  >
    <JustificationContainer justification="space-between" align="center">
      {children}
    </JustificationContainer>
  </Div>
);

export default Toolbar;
