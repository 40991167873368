import React from 'react';
import { Field } from 'react-final-form';

import { FieldState } from 'final-form';

import FoldableCardBlock from '../FoldableCardBlock';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import TEST_ID from './index.testid';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import Dropdown, { SelectedOption } from '~/components/molecule/Dropdown';
import useUserOptions from '~/hooks/useUserOptions';

const text = {
  officeLabel: 'Vestiging',
  userLabel: 'Gebruiker',
  noOfficeLabel: 'Alle vestigingen',
  noUserLabel: 'Alle gebruikers',
};
type Props = {
  /** If the form is disabled */
  isFormDisabled: boolean;
  getFieldState: (arg0: string) => FieldState<any> | null | undefined;
  change: (arg0: string, arg1: any) => void;
};

const ContactSettingsBlock: React.FCC<Props> = ({
  isFormDisabled,
  getFieldState,
  change,
}) => {
  const officeFieldState = getFieldState('officeId');
  const userFieldState = getFieldState('userId');

  const officeOptions = useOfficeOptions({
    withAllOfficesOption: officeFieldState?.value === null,
  });
  const userOptions = useUserOptions({ officeId: officeFieldState?.value });
  const noOfficeSelected = officeFieldState?.value == null;

  return (
    <FoldableCardBlock
      icon="GEAR"
      title="Eigenaar"
      dataTestId="card-block-settings"
    >
      <InputGroup>
        <Field name="officeId">
          {({ input: { onChange } }) => (
            <Dropdown
              disabled={isFormDisabled}
              dataTestId={TEST_ID.ASSIGNED_OFFICE_DROPDOWN}
              label={text.officeLabel}
              onChange={(selectedOption: SelectedOption) => {
                const { option } = selectedOption;
                const payload = option ? option.payload : null;
                const newOfficeId = payload?.id ? payload.id : null;

                onChange(newOfficeId || null);

                // keep previous selection if it still exists in the options
                const userFieldState = getFieldState('userId');
                if (
                  userFieldState != null &&
                  userOptions.find(
                    user => user.payload?.id == userFieldState.value,
                  ) == null
                ) {
                  change('userId', null);
                }
              }}
              selectedOptionIdx={officeOptions.findIndex(officeOption => {
                if (officeFieldState?.value === null)
                  return officeOption.payload === null;
                return officeOption.payload?.id == officeFieldState?.value;
              })}
              options={officeOptions}
            />
          )}
        </Field>
      </InputGroup>
      <InputGroup>
        <Field name="userId">
          {({ input: { onChange } }) => (
            <Dropdown
              disabled={isFormDisabled}
              dataTestId={TEST_ID.ASSIGNED_USER_DROPDOWN}
              label={text.userLabel}
              onChange={(selectedOption: SelectedOption) => {
                const { option } = selectedOption;
                const payload = option ? option.payload : null;
                onChange(payload?.id ? payload.id : null);
              }}
              selectedOptionIdx={userOptions.findIndex(user => {
                if (userFieldState?.value === null)
                  return user.payload === null;
                return user.payload?.id == userFieldState?.value;
              })}
              options={noOfficeSelected ? [] : userOptions}
            />
          )}
        </Field>
      </InputGroup>
    </FoldableCardBlock>
  );
};

export default ContactSettingsBlock;
