import React, { useState } from 'react';
import Catalog from '~/Catalog';
import CallToActionBlock from '~/components/organism/CallToActionBlock';

import {
  EmailStatus,
  EmailStatusType,
  useUpdateContactMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { bouncedReasons } from '~/components/page/Contacts/constants';
import formatToastMessage from '~/util/formatToastMessage';

export type Props = {
  dataTestId?: string;
  emailStatus?: EmailStatusType | null;
  contactId: string;
};

const text = {
  header: 'E-mailadres is geblokkeerd',
  buttonLabel: 'E-mailadres vrijgeven',
  successMessage: 'Het contact is succesvol vrijgegeven',
};

const BouncedEmailCTA: React.FCC<Props> = ({
  dataTestId,
  emailStatus,
  contactId,
}) => {
  const { id: accountId } = useCurrentAccount();
  const [updateContact, { loading }] = useUpdateContactMutation();
  const [isBouncedEmail, setIsBouncedEmail] = useState<boolean>(
    emailStatus?.status === EmailStatus.Bounced,
  );
  const addToast = useAddToast();

  const onUnbounce = async () => {
    const { data, errors } = await updateContact({
      variables: {
        accountId,
        id: contactId,
        update: {
          clearBounced: true,
        },
      },
    });

    if (errors?.length) {
      return addToast([
        formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
      ]);
    }

    if (data && !data.updateContact.emailStatus) {
      setIsBouncedEmail(false);
      return addToast([formatToastMessage(text.successMessage, 'success')]);
    }
  };

  return (
    <>
      {isBouncedEmail && emailStatus && (
        <CallToActionBlock
          dataTestId={dataTestId}
          icon={{ name: 'exclamation', background: 'warning' }}
          header={text.header}
          description={bouncedReasons[emailStatus?.reason]}
          button={{
            onClick: onUnbounce,
            label: text.buttonLabel,
            appearance: 'secondary',
            icon: 'exclamation',
            loading,
          }}
          padding={['base']}
          margin={['base', null, null, null]}
        />
      )}
    </>
  );
};

export default BouncedEmailCTA;
