import React, { useEffect, useState } from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { isEmpty } from 'ramda';
import Icon from '~/components/atom/Icon';
import {
  AppValuationReport_Update,
  useGetAppValuationReportRouteAvailablityLazyQuery,
} from '~/graphql/types';
import useDebounce from '~/hooks/useDebounce';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Input from '~/components/page/Apps/components/Input';
import styled, { css } from 'styled-components';
import { useRecoilState } from 'recoil';
import { defaultErrorsByPath } from '../../state';
import { WAARDERAPPORT_URL_PREFIX } from '~/components/page/Apps/ValueReport';

const text = {
  routeUnavailable: 'Deze route is niet beschikbaar',
  emptyMessage: 'Het veld kan niet leeg zijn',
  validationMessage:
    'Alleen letters, cijfers en het streepje(-) zijn toegestaan',
  checkAvailability: 'Beschikbaarheid controleren',
  available: 'Beschikbaar',
  notAvailable: 'Niet beschikbaar',
};

const ROUTE_VALIDATION_REGEX = /^([a-z0-9]|-)+$/;
const pathname = '/';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  data?: AppValuationReport_Update['route'] | null;
  updated?: AppValuationReport_Update['route'] | null;
  reportId: string;
  onUpdate: (value: AppValuationReport_Update['route']) => void;
};

const General: React.FCC<Props> = ({
  data,
  updated,
  loading,
  reportId,
  onUpdate,
}) => {
  const { id: accountId } = useCurrentAccount();
  const [errorsByPath, setErrorsByPath] = useRecoilState(defaultErrorsByPath);

  const [statusIdx, setStatusIdx] = useState<number | undefined>(undefined);

  const onRouteUnavailable = () => {
    setStatusIdx(2);
    setErrorsByPath(prev => ({
      ...prev,
      [pathname]: text.routeUnavailable,
    }));
  };

  const [getAvailability, { loading: queryLoading }] =
    useGetAppValuationReportRouteAvailablityLazyQuery({
      fetchPolicy: 'network-only',
      onCompleted: data => {
        if (data.isAvailable) {
          setStatusIdx(1);
          setErrorsByPath(prev => ({ ...prev, [pathname]: null }));
        } else {
          onRouteUnavailable();
        }
      },
      onError: () => onRouteUnavailable(),
    });

  const debouncedRoute = useDebounce(updated, 300);

  useEffect(() => {
    if (
      data !== updated &&
      debouncedRoute &&
      error !== text.validationMessage
    ) {
      void getAvailability({
        variables: {
          accountId,
          id: reportId,
          route: debouncedRoute,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRoute]);

  const error = errorsByPath[pathname];

  return (
    <AppDetailsContainer
      header="Algemeen"
      icon="gear"
      loading={loading}
      dataTestId="design-page"
    >
      <Input
        name="url-link"
        statuses={!isEmpty(updated) ? statuses : undefined}
        statusIndex={queryLoading ? 0 : statusIdx}
        prefix={`https://${WAARDERAPPORT_URL_PREFIX}`}
        label="Gewenste adres"
        reset="Bekijk live"
        onReset={() => {
          window.open(
            `https://${WAARDERAPPORT_URL_PREFIX}${data}`,
            '_blank',
            'noopener,noreferrer',
          );
        }}
        error={error || undefined}
        value={updated}
        onChange={async value => {
          onUpdate(value);

          if (!value) {
            setErrorsByPath(prev => ({
              ...prev,
              [pathname]: text.emptyMessage,
            }));
          } else if (!ROUTE_VALIDATION_REGEX.test(value)) {
            setErrorsByPath(prev => ({
              ...prev,
              [pathname]: text.validationMessage,
            }));
          } else {
            setErrorsByPath(prev => ({ ...prev, [pathname]: null }));
          }
        }}
      />
    </AppDetailsContainer>
  );
};

const StyledLabel = styled.span<{ backgroundColor: string }>(
  ({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor};
    color: ${theme.color('white')};
    padding: ${theme.space('xxs')};
    transform: translateY(-${theme.space('xxs')});
    border-radius: ${theme.getTokens().border.radius.m};
    display: flex;
    align-items: center;

    & > span {
      margin-right: 5px;
    }
  `,
);

/** Expected as an array in Input component */
const statuses = [
  <StyledLabel key="checking" backgroundColor={'#5191b7'}>
    <Icon name="spinner" /> {text.checkAvailability}
  </StyledLabel>,
  <StyledLabel key="available" backgroundColor={'#3da07b'}>
    <Icon name="check" strokeWidth={2.5} /> {text.available}
  </StyledLabel>,
  <StyledLabel key="taken" backgroundColor={'#fc7e64'}>
    <Icon name="close" strokeWidth={2.5} /> {text.notAvailable}
  </StyledLabel>,
];

export default General;
