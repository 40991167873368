import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';
import arrayToCss from '~/util/arrayToCss';
import Icon from '~/components/atom/Icon';

type MaxWidth = string | 'auto';

export type Props = {
  /**
   * The representation of any message you want to show here
   */
  label: React.ReactNode;

  /**
   * This filter has an error. This changes the appearance into a danger state
   * and it will ignore the label and use the error string instead
   */
  error: string | null;

  /**
   * Changes the appearance into active appearance
   */
  active?: boolean;

  /**
   * Disabled all interactions
   */
  disabled?: boolean;

  /**
   * Fired when the label is clicked
   */
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;

  /**
   * Fired when the Trash icon is clicked
   */
  onRemove: () => void;

  /**
   * Pass unit as `rem` or `'auto'`
   */
  maxWidth?: MaxWidth;

  /**
   * The number of filters not showing in the main representation
   */
  moreFilters?: number | null;
};

const FilterRepresentation = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      dataTestId,
      label,
      onClick,
      onRemove,
      error = null,
      maxWidth = 'auto',
      active = false,
      disabled = false,
      moreFilters = null,
    },
    ref,
  ) => {
    const hasError = error !== null;
    return (
      <Container
        ref={ref}
        data-testid={dataTestId}
        $active={active}
        $disabled={disabled}
        $hasError={hasError}
      >
        <Label
          type="button"
          onClick={onClick}
          disabled={disabled}
          $hasError={hasError}
        >
          {/* When error is not null, we will use it as a label */}
          <LabelInner $maxWidth={maxWidth}>{error ?? label}</LabelInner>
          {!hasError && moreFilters != null && (
            <span style={{ whiteSpace: 'nowrap' }}>
              {/* U+200e is added to trick 'nowrap'*/}
              {`‎ + ${moreFilters} ${moreFilters === 1 ? 'andere' : 'anderen'}`}
            </span>
          )}
        </Label>
        <RemoveButton
          type="button"
          onClick={onRemove}
          disabled={disabled}
          $hasError={hasError}
        >
          <Icon name="trashcan" />
        </RemoveButton>
      </Container>
    );
  },
);

const Container = styled.div<{
  $active: boolean;
  $disabled: boolean;
  $hasError: boolean;
}>(
  ({ theme, $active, $disabled, $hasError }) => css`
    width: 100%;
    color: ${getColor(theme, $active, $disabled, $hasError)};
    border: ${theme.getTokens().border.width.s} solid currentColor;
    border-radius: ${theme.getTokens().border.radius.s};

    background-color: ${$active
      ? $hasError
        ? theme.color('danger')
        : theme.color('primary')
      : 'transparent'};

    overflow: hidden;

    transition: background 0.3s ease-out;

    &:hover {
      &:not([disabled]) {
        background: ${$hasError
          ? theme.color('danger', 'light')
          : theme.color('primary', 'light')};
        color: ${theme.color('white')};
      }
    }

    display: flex;
  `,
);

const buttonStyles = ({
  theme,
}: {
  theme: DefaultTheme;
  $hasError: boolean;
}) => css`
  background: none;
  outline: none;
  margin: 0;
  cursor: pointer;

  font-size: ${theme.fs('s')};

  padding: ${arrayToCss(['xxs', 's'], theme)};
  transition: background 0.3s ease-out;
`;

const Label = styled.button<{ $hasError: boolean }>(
  ({ theme }) => css`
    ${buttonStyles}

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: ${theme.getTokens().border.width.s} solid transparent;
    font-weight: ${theme.fw('semiBold')};
    text-align: left;
  `,
);
const LabelInner = styled.span<{ $maxWidth: MaxWidth }>(
  ({ $maxWidth }) => css`
    width: 100%;
    max-width: ${$maxWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  `,
);

const RemoveButton = styled.button<{ $hasError: boolean }>(
  ({ theme, $hasError }) => css`
    ${buttonStyles}
    border: 0px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${theme.space('xxl')};

    height: 100%;

    &:hover {
      &:not([disabled]) {
        background: ${$hasError
          ? theme.color('danger', 'dark')
          : theme.color('primary', 'dark')};
        color: ${theme.color('white')};
      }
    }

    /* Target icon container */
    & > span {
      display: inline-block;
      /* The trash icon is not properly layout so we touch up the position for good measure. */
      transform: translate(0, 2px);
    }
  `,
);

export default FilterRepresentation;

const getColor = (
  theme: DefaultTheme,
  $active: boolean,
  $disabled: boolean,
  $hasError: boolean,
) => {
  if ($disabled === true) return theme.color('grey');
  if ($active === true) return theme.color('white');
  if ($hasError === true && $active === false) return theme.color('danger');

  return theme.color('primary', 'light');
};
