import React from 'react';
import styled from 'styled-components';
import { Props as JustificationContainerProps } from '~/components/atom/JustificationContainer';
import Small from './components/Small';
import Large from './components/Large';
import { BaseColor } from '~/theme/System/tokens';
import useMeasure from '~/hooks/useMeasure';
import type { IconType } from '~/components/atom/Icon';
import type { Appearance } from '~/components/atom/Button';

export type CTAIcon = {
  name?: IconType;
  background?: BaseColor;
};

export type Props = {
  header?: string;
  description?: string;

  /** Icon on the left */
  icon?: CTAIcon;

  /** Button on the right */
  button?: {
    dataTestId?: string;
    label: string;
    appearance?: Appearance;
    icon?: IconType;
    onClick: () => void;
    loading?: boolean;
  };

  linkButton?: {
    label: string;
    appearance: Appearance;
    icon: IconType;
    to: string;
  };

  /** Margin of the container */
  margin?: JustificationContainerProps['margin'];

  /** Padding of the container */
  padding?: JustificationContainerProps['padding'];

  /** Aligns content at the top or the center */
  align?: 'start' | 'center';

  width?: string;

  /** If not passed, close button will not be rendered */
  onClose?: () => void;
};

const CallToActionBlock: React.FCC<Props> = ({ ...rest }) => {
  const { ref, bounds } = useMeasure();

  const isSmall = bounds.width < 500 && bounds.width !== 0;

  return (
    <Container ref={ref}>
      {isSmall ? <Small {...rest} /> : <Large {...rest} />}
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;
`;
export default CallToActionBlock;
