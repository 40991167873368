import type { SetterOrUpdater } from 'recoil';
import type { OptionOf } from '~/components/molecule/Dropdown';
import type {
  FormBuilder_Node,
  FormBuilder_NodeFragment,
} from '~/graphql/types';
import deleteNode from '../../../../utils/deleteNode';

const text = {
  deleteScreen: 'Scherm verwijderen',
  deleteSubmitScreen: 'Verzendscherm verwijderen',
  deleteSubmitScreenWarning:
    'Weet je het zeker dat je dit scherm wilt verwijderen? Je ontvangt geen contactgebeurtenissen.',
  deleteEvent: 'Gebeurtenis verwijderen',
};

/**
 * Used to display a modal if there is a need
 */
export type ModalProps = {
  /** Should the modal be shown */
  shown: boolean;

  /** Modal header */
  header?: string;

  /** Modal body */
  body?: string;

  /** Function to call on confirm */
  onConfirm: () => void;
};

type MenuArgs = {
  /** Type of node that options belong to */
  nodeType: FormBuilder_Node['__typename'];

  /** Is the node the start node */
  isStartNode: boolean;

  /** Is the node the submit screen in the basic form */
  isSubmitScreenNode?: boolean;

  /** Used to update nodes state */
  setNodesState: SetterOrUpdater<Array<FormBuilder_NodeFragment>>;
};

type OptionPayload = (values: {
  /** Id of the node that was clicked */
  nodeId: string;

  /** Used if you want to show a confirm modal before running the action */
  setModalOptions: (modalProps: ModalProps) => void;
}) => void;

type Option = OptionOf<OptionPayload>;

/**
 * Returns options for menus
 */
const getMenuOptions = (args: MenuArgs): Array<Option> => {
  switch (args.nodeType) {
    case 'FormBuilder_EventNode':
      if (args.isStartNode) return [];
      return [getDeleteOption({ args, header: text.deleteEvent })];

    case 'FormBuilder_ScreenNode':
      if (args.isStartNode) return [];
      if (args.isSubmitScreenNode)
        return [
          getDeleteOption({
            args,
            header: text.deleteSubmitScreen,
            description: text.deleteSubmitScreenWarning,
          }),
        ];

      return [getDeleteOption({ args, header: text.deleteScreen })];

    default:
      return [];
  }
};

const getDeleteOption = ({
  args,
  header,
  description,
}: {
  args: MenuArgs;
  header: string;
  description?: string;
}): Option => ({
  key: 'delete-node',
  label: 'Verwijderen',
  payload: ({ nodeId, setModalOptions }) => {
    if (!nodeId) return;

    setModalOptions({
      shown: true,
      header,
      body: description ?? 'Weet je het zeker?',
      onConfirm: () =>
        args.setNodesState(prev => deleteNode({ nodeId, nodes: prev })),
    });
  },
});

export default getMenuOptions;
