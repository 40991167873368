import type {
  FormBuilder_Node_DefaultNext,
  FormBuilder_NodeFragment,
} from '~/graphql/types';
import type { CreateRelation, CreateType } from '../../components/AddNodeMenu';
import { SUBMIT_SCREEN_TITLE } from '../../components/InteractionHandler/components/EditNodeScreen/constants';

/**
 * Creates a node
 * @param {string} newNodeId
 * @param {CreateType} createType
 * @param {CreateRelation} relation
 * keywords:
 */
const getNewNodeByCreateType = (
  createType: CreateType,
  relation: CreateRelation,
  newNodeId: string,
): FormBuilder_NodeFragment | null => {
  const isLastNode = relation.target.startsWith('trailing-node');
  const defaultNext: FormBuilder_Node_DefaultNext | null = isLastNode
    ? null
    : {
        __typename: 'FormBuilder_Node_DefaultNext',
        targetNodeId: relation.target,
      };

  switch (createType) {
    case 'event':
      return {
        __typename: 'FormBuilder_EventNode',
        formBuilderEventId: '',
        id: newNodeId,
        mapping: [],
        name: 'Nieuw Event',
        defaultNext,
      };

    case 'screen':
      return {
        __typename: 'FormBuilder_ScreenNode',
        id: newNodeId,
        name: 'Nieuw Scherm',
        blocks: [],
        defaultNext,
      };

    case 'submitScreen':
      return {
        __typename: 'FormBuilder_ScreenNode',
        id: newNodeId,
        name: SUBMIT_SCREEN_TITLE,
        blocks: [],
        defaultNext,
      };

    default:
      return null;
  }
};

export default getNewNodeByCreateType;
