import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Body, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import FullEmailMessage from '~/components/organism/FullEmailMessage';
import TEST_ID from './index.testid';
import TextButton from '~/components/atom/TextButton';

const text = {
  showLessButton: 'Toon minder',
  showMoreButton: 'Laat volledige bericht zien',
};
type Props = {
  id: string;
  emailText: string;
  hasMore: boolean;
  canClickOpen: boolean;
};
const EmailEventMessageBlock = ({
  id,
  emailText,
  hasMore,
  canClickOpen,
}: Props) => {
  const [isFullMessageVisible, setFullMessageVisible] = useState(false);

  if (isFullMessageVisible) {
    return (
      <Container direction="column" margin={['s', null, 'm', null]}>
        <MessageContainer data-testid={TEST_ID.FULL_MESSAGE_BLOCK} size="base">
          <FullEmailMessage eventId={id} />
        </MessageContainer>
        <TextButton
          size="medium"
          withoutPadding
          dataTestId={TEST_ID.HIDE_FULL_MESSAGE_BUTTON}
          onClick={() => setFullMessageVisible(false)}
          label={text.showLessButton}
        />
      </Container>
    );
  }

  return (
    <Container direction="column" margin={['s', null, 'm', null]}>
      <MessageContainer
        variant={Variant.primary}
        margin={[null, null, 's', null]}
        data-testid={TEST_ID.MESSAGE_BLOCK}
        size="base"
      >
        <div
          className={'dh-email-container'} // Can safely set it as backend has sanitized it
          dangerouslySetInnerHTML={{
            __html: hasMore ? `${emailText} ...` : emailText,
          }}
        />
      </MessageContainer>

      {canClickOpen && (
        <TextButton
          withoutPadding
          size="medium"
          dataTestId={TEST_ID.SHOW_FULL_MESSAGE_BUTTON}
          onClick={() => setFullMessageVisible(true)}
          label={text.showMoreButton}
        />
      )}
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  ${() => css`
    word-break: break-word;
  `};
`;

// the min-height is needed to make sure
const MessageContainer = styled(Body)<{}>`
  min-height: 1px;
`;

export default EmailEventMessageBlock;
