import React from 'react';
import styled, { css } from 'styled-components';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Input from '~/components/molecule/Input';
import Dropdown from '~/components/molecule/Dropdown';
import TextButton from '~/components/atom/TextButton';
import { ZapierField, ZapierFieldType } from '~/graphql/types';
import withIconTooltip from '~/hocs/withIconTooltip';
import { ErrorObj } from '../..';
import TEST_ID from './index.testid';
import JustificationContainer, {
  Props as JustificationProps,
} from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';

export type Props = {
  /** Id for tests */

  /** Current active Zapier field  */
  field: Omit<ZapierField, '__typename'> | undefined;

  errors: ErrorObj;

  /** Save changes made in Zapier field  */
  onChange: (newValue: string, key: keyof ZapierField) => void;

  /** Delete Zapier field  */
  onDelete: () => void;
};

const text = {
  tipHeader: 'Wat zijn velden?',
  tip: (
    <>
      Met velden bepaal je welke gegevens je beschikbaar wil maken voor de
      koppeling met Zapier. <br />
      <br />
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
  nameLabel: 'Naam',
  nameTooltip: 'Naam van het veld',
  keyLabel: 'Key',
  keyTooltip: 'Key voor het veld',
  typeLabel: 'Type',
  typeTooltip: 'Type voor het veld',

  deleteButtonLabel: 'Verwijder dit veld',
};

const FieldEditingSection: React.FCC<Props> = ({
  dataTestId,
  field,
  onChange,
  onDelete,
  errors,
  ...rest
}) => {
  if (!field)
    return (
      <Container>
        <TipBanner
          id="field-editing-section-tip"
          dismissible={false}
          withoutBorder
          headerText={text.tipHeader}
        >
          {text.tip}
        </TipBanner>
      </Container>
    );

  const { label, key, type } = field;

  const inputWithLabelGroupProps: JustificationProps = {
    width: '100%',
    direction: 'column',
    align: 'start',
    justification: 'center',
    margin: [null, null, 'base', null],
  };

  return (
    <Container data-testid={dataTestId} {...rest}>
      <JustificationContainer {...inputWithLabelGroupProps}>
        <LabelWithTooltip tooltipMessage={text.nameTooltip}>
          {text.nameLabel}
        </LabelWithTooltip>
        <Input
          width="100%"
          value={label}
          onChange={e => onChange(e.target.value, 'label')}
          externalErrors={errors.label ?? []}
          dataTestId={TEST_ID.INPUT_NAME}
        />
      </JustificationContainer>
      <JustificationContainer {...inputWithLabelGroupProps}>
        <LabelWithTooltip tooltipMessage={text.keyTooltip}>
          {text.keyLabel}
        </LabelWithTooltip>
        <Input
          width="100%"
          value={key}
          onChange={e => onChange(e.target.value, 'key')}
          externalErrors={errors.key ?? []}
          dataTestId={TEST_ID.INPUT_KEY}
        />
      </JustificationContainer>

      <JustificationContainer {...inputWithLabelGroupProps}>
        <LabelWithTooltip tooltipMessage={text.typeTooltip}>
          {text.typeLabel}
        </LabelWithTooltip>
        <Dropdown
          options={fieldTypeOptions}
          selectedOptionIdx={fieldTypeOptions.findIndex(
            option => option.payload === type,
          )}
          onChange={({ option }) => onChange(option.payload, 'type')}
        />
      </JustificationContainer>

      <TextButton
        label={text.deleteButtonLabel}
        appearance="danger"
        onClick={onDelete}
        dataTestId={TEST_ID.DELETE_FIELD_BUTTON}
      />
    </Container>
  );
};

const LabelWithTooltip = withIconTooltip(Heading4, {
  variant: Variant.primary,
  withoutMargin: true,
});

const fieldTypeOptions = [
  {
    key: 'string',
    label: 'String (tekst)',
    payload: ZapierFieldType.String,
  },
  {
    key: 'number',
    label: 'Nummer',
    payload: ZapierFieldType.Number,
  },
  {
    key: 'boolean',
    label: 'Boolean',
    payload: ZapierFieldType.Boolean,
  },
];

const Container = styled.div<{}>`
  flex: 1;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    box-shadow: ${theme.getTokens().boxShadow.card};
    background-color: ${theme.color('white')};
    padding: ${theme.space('base')};
    margin-left: ${theme.space('base')};
  `}
`;

export default FieldEditingSection;
