import React, { useState } from 'react';

import { navigate } from '@gatsbyjs/reach-router';
import { useGetEmailEffectivenessGroupedByFlowV2BlueprintQuery } from '~/graphql/types';
import TEST_ID from './index.testid';
import TabBody from '~/components/molecule/Tabs/components/TabBody';
import Tabs from '~/components/molecule/Tabs';
import GroupedBarChart, {
  DataType,
} from '~/components/organism/Charts/GroupedBarChart';
import { Points, tabsConfig } from './constants/tabsConfig';
import Loading from '~/components/organism/Charts/GroupedBarChart/components/Loading';
import { Body, Heading5 } from '~/components/atom/Typography';
import Button from '~/components/atom/Button';
import CardHeader from '../CardHeader';
import useToday from '~/hooks/useToday';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import getRows from './utils/getRows';
import { take } from 'ramda';
import getDateRangeOptionsWithEndDate from '../../utils/getDateRangeOptionsWithEndDate';
import withMasonryCard from '../withMasonryCard';
import { LINK_PREFIX } from '~/components/page/Automation/v2/components/Wizard';

export type Props = {
  today?: Date;
};

const formatOptions: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
};

const text = {
  heading: 'E-mail effectiviteit',
  noFlowHeader: 'Geen flows',
  noFlowBody: 'Je hebt nog geen flows aangemaakt.',
  noFlowCTA: 'Maak een flow',

  errorHeader: 'Uh-oh 😱',
  errorBody:
    'Het lijkt er op dat er iets mis is gegaan bij het ophalen van jouw flows. Probeer het later opnieuw.',
};

export const BUILDER_V2_PATH = '/-/automation/flows/builder-v2/update/';

const EmailEffectivenessComp: React.FCC<Props> = ({
  dataTestId,
  today: originalToday,
}) => {
  const today = useToday(originalToday);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);

  const options = getDateRangeOptionsWithEndDate(today);
  const dateRange = options[selectedOptionIndex].payload;

  const { id: accountId } = useCurrentAccount();
  const variables = {
    accountId,
    start: dateRange.start,
    end: dateRange.end,
  };

  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetEmailEffectivenessGroupedByFlowV2BlueprintQuery({
    variables,
  });

  const FilledCardHeader = () => (
    <CardHeader
      headingText={text.heading}
      onOptionChange={({ selectedOptionIdx }) =>
        setSelectedOptionIndex(selectedOptionIdx)
      }
      options={options}
      selectedOptionIndex={selectedOptionIndex}
      dataTestId={TEST_ID.CARD_HEADER}
    />
  );

  if (loading) {
    return (
      <>
        <FilledCardHeader />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <FilledCardHeader />
        <Heading5>{text.errorHeader}</Heading5>
        <Body>{text.errorBody}</Body>
      </>
    );
  }

  const noFlowsExist =
    data && data.getEmailEffectivenessGroupedByFlowV2Blueprint.length <= 0;

  if (noFlowsExist) {
    return (
      <>
        <>
          <FilledCardHeader />
          <Heading5>{text.noFlowHeader}</Heading5>
          <Body>{text.noFlowBody}</Body>
          <Button
            size="medium"
            dataTestId={TEST_ID.NO_FLOWS_CTA}
            onClick={() => navigate(`${LINK_PREFIX}/v2/`)}
            label={text.noFlowCTA}
          />
        </>
      </>
    );
  }

  const getChartItems = (points: Points): DataType => {
    const rowsV2 = getRows({
      data: data?.getEmailEffectivenessGroupedByFlowV2Blueprint,
      points,
      link: BUILDER_V2_PATH,
    });

    return take(5, rowsV2);
  };

  return (
    <>
      <FilledCardHeader />
      <Tabs dataTestId={`${TEST_ID.CONTAINER}-${TEST_ID.TABS}`}>
        {tabsConfig.map(({ label, points }) => (
          <TabBody label={label} key={label}>
            <GroupedBarChart
              dataTestId={dataTestId}
              data={getChartItems(points)}
              formatOptions={formatOptions}
            />
          </TabBody>
        ))}
      </Tabs>
    </>
  );
};

export default withMasonryCard(EmailEffectivenessComp, {
  containerTestId: TEST_ID.CONTAINER,
});
