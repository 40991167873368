import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const User: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.7 57.3V52.4C55.7 47 49.8 42.5 45.8 42.5C44 42.5 39.9 47.5 35.9 47.5C31.9 47.5 28 42.5 26 42.5C21.8 42.5 16.1 46.9 16.1 52.4V57.3"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 14.7002C40.9 14.7002 44.7999 18.9002 44.7999 25.4002C44.7999 31.9002 40.9 38.1002 36 38.1002C31.1 38.1002 27.2 31.8002 27.2 25.4002C27.2 19.0002 31.1 14.7002 36 14.7002Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default User;
