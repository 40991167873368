import React from 'react';
import Button, { Props as ButtonProps } from '~/components/atom/Button';
import useOnUpgradeSubscription from '~/hooks/useOnUpgradeSubscription';

export type Props = ButtonProps;

const UpgradePlanButton: React.FCC<Props> = ({ ...rest }) => {
  const onUpgrade = useOnUpgradeSubscription({});

  return <Button size="large" onClick={onUpgrade} {...rest} />;
};

export default UpgradePlanButton;
