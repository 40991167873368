import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import arrayToCss from '~/util/arrayToCss';
import Icon from '~/components/atom/Icon';
import Input, { Props as InputProps } from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import BankIcon, { BankIconType } from './components/BankIcon';
import forceIBANFormat from './utils/forceIBANFormat';
import formatIBAN from './utils/formatIBAN';
import getIbanValidationOutput from './utils/getIbanValidationOutput';

export type Props = Omit<InputProps, 'onChange'> & {
  value?: string | null;
  disabled?: boolean;
  showValidation?: boolean;
  label?: string;

  /** Provide external errors if there are any on IBAN submit. Tip: you can use ibanErrors object for common errors */
  externalErrors?: Array<string>;

  /** Will be shown in the input placeholder */
  previousValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onError?: (error?: string | null) => void;
};

// Generate valid IBAN - https://wise.com/us/iban/netherlands , https://creatoio.com/iban-generator

type BankCodes =
  | 'INGB'
  | 'ABNA'
  | 'ASNB'
  | 'DNIB'
  | 'NIBQ'
  | 'AHBK'
  | 'FVLB'
  | 'TRIO'
  | 'RABO'
  | 'BUNQ'
  | 'HAND'
  | 'KNAB'
  | 'RBRB'
  | 'SNSB';

const iconByBankCode: {
  [key in BankCodes]: BankIconType;
} = {
  INGB: 'ing',
  ABNA: 'abn',
  ASNB: 'volks',
  DNIB: 'nibc',
  NIBQ: 'nibc',
  AHBK: 'achmea',
  FVLB: 'vlk',
  TRIO: 'tridos',
  RABO: 'rabo',
  BUNQ: 'bunq',
  HAND: 'hand',
  KNAB: 'knab',
  RBRB: 'regio',
  SNSB: 'sns',
};

export const ibanErrors = {
  invalid: 'Het opgegeven IBAN is ongeldig',
  countryCode: 'IBAN moet beginnen met "NL"',
  length: 'IBAN moet 18 karakters bevatten',
  empty: 'IBAN kan niet leeg zijn',
};

const text = {
  label: 'IBAN',
  placeholder: 'Voer uw IBAN in',
};

const IBANInput: React.FCC<Props> = ({
  value,
  previousValue,
  onChange,
  onError,
  disabled = false,
  label = text.label,
  dataTestId,
  externalErrors = [],
  showValidation = false,
  ...rest
}) => {
  const theme = useTheme();
  const validationOutput = getIbanValidationOutput(value);
  const localErrors = [...externalErrors];

  let error: string | null = null;

  if (showValidation && validationOutput?.error) {
    localErrors.push(ibanErrors[validationOutput.error]);
    error = validationOutput.error;
  }

  const bankIcon: BankIconType | undefined = validationOutput?.bankCode
    ? iconByBankCode[validationOutput.bankCode]
    : undefined;

  useEffect(() => {
    onError && onError(error);
  }, [error, onError]);

  return (
    <Container align="center">
      <IconContainer>
        <Inner>
          {bankIcon && !error ? (
            <BankIcon name={bankIcon} />
          ) : (
            <Icon
              name="bank"
              color={
                error ? theme.color('danger') : theme.color('text', 'light')
              }
            />
          )}
        </Inner>
      </IconContainer>
      <Input
        {...rest}
        width="100%"
        placeholder={previousValue ?? text.placeholder}
        dataTestId={dataTestId}
        onChange={event => {
          event.preventDefault();
          onChange(event, forceIBANFormat(event));
        }}
        // If we don't type but pass the IBAN, we also format it here
        value={value ? formatIBAN(value) : ''}
        externalErrors={localErrors}
        label={label}
        className="ibanInput"
        disabled={disabled}
        name="iban"
      />
    </Container>
  );
};

const Inner = styled.div(
  ({ theme }) => css`
    position: absolute;
    z-index: 1;
    top: 0;
    left: ${theme.space('xxs')};
    display: flex;
    align-items: center;

    svg {
      width: 1.2em;
      height: 1.2em;
    }
  `,
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    width: 100%;

    .ibanInput {
      width: 100%;

      input {
        /* Solves the font issue,
        where all capital letters are not centered inside the input - https://i.stack.imgur.com/GNsll.gif */
        padding: ${arrayToCss(['xxxs', null, null, 'base'], theme)};
      }
    }
  `,
);

const IconContainer = styled.div`
  position: relative;
`;

export default IBANInput;
