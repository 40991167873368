import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';
import type { OptionOf } from '~/components/molecule/Dropdown';
import cleanedFilename from '~/util/cleanedFilename';
import useErrorModal from '~/hooks/useErrorModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TEST_ID from './index.testid';
import useIsMounted from '~/hooks/useIsMounted';
import ContactsContext from '../../ContactsContext/ContactsContext';
import markedForDeletionContactList from '../../util/markedForDeletionContactList';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import TextButton from '~/components/atom/TextButton';
import ConfirmModal from '~/components/template/ConfirmModal';
import { useMarkContactForDeletionMutation } from '~/graphql/types';

type ButtonType = 'SUBSCRIBE' | 'UNSUBSCRIBE' | 'DELETE';

const text = {
  agree: 'Ok',
  subscribeModalTitle: 'Contact ingeschreven',
  unsubscribeModalTitle: 'Contact uitgeschreven',
  subscribeModalMessage:
    'Een ingeschreven contact kan door flows gestuurde marketing e-mail ontvangen. Het contact dient hiervoor toestemming te hebben gegeven.',
  unsubscribeModalMessage:
    'Een uitgeschreven contact ontvangt geen marketing e-mail vanuit flows. Actieve flows met een e-mail actie zullen worden gestopt.',
  deleteErrorTitle: 'Oeps!',
  deleteErrorMessage:
    'Er is iets misgegaan bij het verwijderen van het contact. Probeer het nog eens.',
  updateErrorMessage:
    'Er is iets misgegaan bij het updaten van het contact. Probeer het nog eens.',
};

type Props = {
  blockMarketing: boolean | null | undefined;
  contactId: string;
};

const ContactInformationSettings: React.FCC<Props> = ({
  blockMarketing,
  contactId,
}) => {
  const isMounted = useIsMounted();
  const { updateContactFn } = useContext(ContactsContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modal, setModal] = useState({
    display: false,
    title: text.subscribeModalTitle,
    message: text.subscribeModalMessage,
  });
  const [errorModal, showErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.deleteErrorMessage,
  });
  const [updateErrorModal, showUpdateErrorModal] = useErrorModal({
    title: text.deleteErrorTitle,
    message: text.updateErrorMessage,
  });
  const account = useCurrentAccount();

  const dropdownOptions: Array<OptionOf<ButtonType>> = [
    {
      label: blockMarketing === true ? 'Inschrijven' : 'Uitschrijven',
      key: blockMarketing === true ? 'subscribe' : 'unsubscribe',
      payload: blockMarketing === true ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
      type: null,
    },
    {
      label: 'Verwijderen',
      key: 'delete',
      payload: 'DELETE',
      type: 'DANGER',
    },
  ];

  const [markContactForDeletion] = useMarkContactForDeletionMutation({
    onError: () => showErrorModal(),
  });

  const markForDeletion = async () => {
    const { data, errors } = await markContactForDeletion({
      variables: {
        accountId: account.id,
        id: contactId,
      },
    });

    if (!errors && data) {
      markedForDeletionContactList.writeMarkedForDeletionContact(contactId);

      if (isMounted()) void navigate('/-/contacts');
    }
  };

  return (
    <ButtonContainer>
      <TextButton
        size="medium"
        appearance="default"
        icon="dot-menu"
        onClick={() => {
          setDropdownOpen(prev => !prev);
        }}
        dataTestId={TEST_ID.MENU_BUTTON}
      />

      <StyledDropdownHideableList
        options={dropdownOptions}
        selectedOptionIdx={-1}
        dropdownListOpen={dropdownOpen}
        onChange={({ option }) => {
          if (option.payload === 'DELETE') {
            void markForDeletion();
          } else if (
            option.payload === 'SUBSCRIBE' ||
            option.payload === 'UNSUBSCRIBE'
          ) {
            if (blockMarketing) {
              setModal(() => ({
                display: true,
                title: text.subscribeModalTitle,
                message: text.subscribeModalMessage,
              }));
            } else {
              setModal(() => ({
                display: true,
                title: text.unsubscribeModalTitle,
                message: text.unsubscribeModalMessage,
              }));
            }

            setDropdownOpen(false);
            updateContactFn({
              variables: {
                accountId: account.id,
                id: contactId,
                update: {
                  blockMarketing: !blockMarketing,
                },
              },
            }).catch(() => {
              showUpdateErrorModal();
              throw Error(
                `${cleanedFilename(
                  __filename,
                )} | Should not occur | Something went wrong with (${option})`,
              );
            });
          } else {
            throw Error(
              `${cleanedFilename(
                __filename,
              )} | Should not occur | Received an option other than delete, subscribe or unsubscribe (${option})`,
            );
          }
        }}
        onClickOutside={() => setDropdownOpen(false)}
        onClose={() => setDropdownOpen(false)}
        dataTestId={TEST_ID.NAV_SETTINGS_DROPDOWN}
      />
      {modal.display && (
        <ConfirmModal
          title={modal.title}
          message={modal.message}
          buttonConfirmTitle={text.agree}
          hideCancel={true}
          handleAction={() => {
            setModal(prevState => ({
              ...prevState,
              display: false,
            }));
          }}
        />
      )}
      {errorModal}
      {updateErrorModal}
    </ButtonContainer>
  );
};

const StyledDropdownHideableList = styled(DropdownListContainer)<{}>`
  right: 0;
`;

const ButtonContainer = styled.div<{}>`
  position: relative;
`;

export default ContactInformationSettings;
