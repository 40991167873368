import React from 'react';
import MarkdownEditorBase from '~/components/molecule/MarkdownEditorBase';
import deserializeMarkdown from '~/components/molecule/MarkdownEditorBase/utils/deserializeMarkdown';
import serializeMarkdown from '~/components/molecule/MarkdownEditorBase/utils/serializeMarkdown';

export type Props = {
  /** Markdown string */
  value: string;

  /** Update the form state */
  onChange: (value: string) => void;
};

const MarkdownEditor: React.FCC<Props> = ({ value = '', onChange }) => {
  const editorValue = deserializeMarkdown(value);

  return (
    <MarkdownEditorBase
      value={editorValue}
      onChange={value => {
        onChange(serializeMarkdown(value));
      }}
    />
  );
};

export default MarkdownEditor;
