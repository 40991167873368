import React, { useReducer, useState } from 'react';
import styled, { css } from 'styled-components';

import type { NamedEmail } from '~/util/namedEmailFormatter';
import { EventContactEmail_New_Attachment } from '~/graphql/types';
import Button from '~/components/atom/Button';
import CaretDropdownButton from '~/components/molecule/CaretDropdownButton';
import MessageHeader from '../MessageHeader';
import NoSyncedEmailCTA from '../NoSyncedEmailCTA';
import Validation from '~/util/Validation';
import TEST_ID from './index.testid';
import convertNamedEmailToEmailNew from '~/util/converters/convertNamedEmailToEmailNew';
import { isSuccessEmailSyncStatus } from '~/util/constants';
import sendEmail, { ReducerType } from '../../reducers/sendEmail';
import useAvailableEmailsToSendFrom from '~/hooks/useAvailableEmailsToSendFrom';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import EmailErrorMessage from '../EmailErrorMessage';
import DefaultTextEditor from '~/components/organism/DefaultTextEditor';
import useEditorStates from '~/components/organism/PluginsEditor/hooks/useEditorStates';
import serializeAllElements from '~/components/organism/PluginsEditor/utils/serialize';
import getImagesAsAttachmentInputs from '~/components/organism/PluginsEditor/utils/signatures/getImagesAsAttachmentInputs';
import useErrorReporter from '~/hooks/useErrorReporter';
import { Errors } from '~/components/organism/PluginsEditor/utils/getEditorErrors';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import { PassthroughProps, EmailInputFieldsVariables } from '../..';
import getSenderNamedEmailValue from '../../utils/getSenderNamedEmailValue';
import getInitialFormValues from '../../utils/getInitialFormValues';
import withSignature from '~/components/organism/PluginsEditor/plugins/withSignature';
import hash from '~/util/hash';
import type { SelectedOptionOf } from '~/components/molecule/Dropdown';
import type { EmailOption } from '../MessageHeader/hooks/useGetEmailOptions';
import isOverMaxEmailSize from '~/components/bad/HTMLEditor/util/isOverMaxEmailSize';
import { EMAIL_EDITOR_BUTTONS } from './constants';

type EmailEventAttachment = {
  s3key: string;
  filename: string;
  inlineId?: string | null | undefined;
  contentLength: number;
};

export type Props = PassthroughProps & {
  /** Event id to generate a unique key for DefaultTextEditor */
  eventId?: string;

  /** If the email is sending */
  loading: boolean;

  /** If there was an error from the mutation */
  hasError: boolean;

  /** When the send button is press */
  onSendPressed: (variables: EmailInputFieldsVariables) => Promise<any>;

  /** Function to set if needed to change task status or close a modal */
  setAfterSendActions: (arg0: {
    allowUpdateStatus: boolean;
    allowClose: boolean;
  }) => void;

  /** Set submit button text on completed status  */
  isCompleted: boolean;

  /** Allow to close modal after submit  */
  canClose: boolean;
};

const text = {
  topic: 'Onderwerp:',
  to: 'Aan:',
  from: 'Van:',
  placeholder: 'Typ hier je bericht...',
  buttons: {
    send: 'Versturen',
    sendAndCloseModal: 'Verstuur & sluiten',
    sendAndComplete: 'Verstuur & deze taak voltooien',
    sendAndOpen: 'Verstuur & deze taak heropenen',
  },
  tooLargeError:
    'De bijlagen zijn te groot, een e-mail mag maximaal 20MB aan bijlagen bevatten',
  invalidCCorBCC: 'Ongeldige waarden in CC- en/of BCC-velden',
};

const EmailInputFields: React.FCC<Props> = props => {
  const { onSendPressed, receiver, hasError } = props;

  const errorReporter = useErrorReporter();
  const addToast = useAddToast();

  const mailboxes = useAvailableEmailsToSendFrom();

  const hasNoAvailableMailboxes = mailboxes.length === 0;

  const initialValues = getInitialFormValues(props, mailboxes);

  const [shouldValidate, setShouldValidate] = useState(false);

  const [emailState, dispatch] = useReducer<ReducerType>(
    sendEmail,
    initialValues,
  );

  const {
    key: editorKey,
    value,
    onChange,
    errors: editorErrors,
    resetEditor,
  } = useEditorStates({
    initialValue: emailState.body,
    initialKey: props.eventId ? hash(props.eventId) : 0,
  });

  if (hasNoAvailableMailboxes) {
    return (
      <CTAContainer data-testid={TEST_ID.NO_SYNCED_EMAIL_CONTAINER}>
        <NoSyncedEmailCTA />
      </CTAContainer>
    );
  }

  const setSenderEmail = (selectedOption: SelectedOptionOf<NamedEmail>) => {
    const { selectedOptionIdx } = selectedOption;

    dispatch({
      type: 'setKey',
      payload: { key: 'selectedSenderIdx', value: selectedOptionIdx },
    });
  };

  const setSubject = (value: string) =>
    dispatch({ type: 'setKey', payload: { key: 'subject', value } });

  const onAddToList = (
    key: 'selectedBCCs' | 'selectedCCs',
    value: EmailOption,
  ) => dispatch({ type: 'addToList', payload: { key, value } });

  const onRemoveFromList = (
    key: 'selectedBCCs' | 'selectedCCs',
    value: EmailOption,
  ) =>
    dispatch({ type: 'removeFromList', payload: { key, removedValue: value } });

  const reset = () => {
    dispatch({ type: 'reset', payload: { value: initialValues } });
    setShouldValidate(false);
  };

  const onSendMessage = () => {
    const { selectedSenderIdx, subject, selectedCCs, selectedBCCs } =
      emailState;

    const senderNamedEmailValue = getSenderNamedEmailValue(emailState);

    const html = serializeAllElements({
      fragment: value,
      customElements: [ELEMENTS.IMAGE, ELEMENTS.DH_IMAGE, ELEMENTS.SIGNATURE],
    });
    const inlineAttachments = getImagesAsAttachmentInputs(value);

    if (senderNamedEmailValue == null) {
      return errorReporter.captureException(
        new Error(`"senderNamedEmailValue" is null or undefined`),
      );
    }

    if (
      !Validation.String.isNonEmptyString(subject) ||
      !Validation.String.isNonEmptyString(html) ||
      selectedSenderIdx == null
    ) {
      setShouldValidate(true);
      return;
    }

    const isOverMaxSize = isOverMaxEmailSize(
      html,
      inlineAttachments.map(({ file }) => file.contentLength),
    );

    if (isOverMaxSize) {
      addToast([formatToastMessage(text.tooLargeError, 'danger')]);
      return;
    }

    return onSendPressed({
      subject: subject,
      body: html,
      from: convertNamedEmailToEmailNew(senderNamedEmailValue),
      to: [convertNamedEmailToEmailNew(receiver)],
      attachments: inlineAttachments.map(({ file, inlineId }) =>
        convertAttachment(file, inlineId),
      ),
      cc: selectedCCs.map(ccValue =>
        convertNamedEmailToEmailNew(ccValue.payload.namedEmail),
      ),
      bcc: selectedBCCs.map(bccValue =>
        convertNamedEmailToEmailNew(bccValue.payload.namedEmail),
      ),
    }).then(() => {
      reset();
      resetEditor({ value: initialValues.body });
    });
  };

  const senderEmail = getSenderNamedEmailValue(emailState)?.email;

  const selectedSenderEmail = (mailboxes ?? []).find(mailbox =>
    senderEmail ? mailbox.namedEmail.email === senderEmail : false,
  );

  const isEmailSynced =
    selectedSenderEmail && selectedSenderEmail.syncState
      ? isSuccessEmailSyncStatus(selectedSenderEmail.syncState)
      : null;

  const pendingImagesError = editorErrors.some(
    ({ errorType }) => errorType === Errors.PendingImage,
  );

  const loadingImagesError = editorErrors.some(
    ({ errorType }) => errorType === Errors.LoadingImage,
  );

  const ccOrBccError =
    emailState.selectedCCs.some(option => option.isValid === false) ||
    emailState.selectedBCCs.some(option => option.isValid === false);

  return (
    <div data-testid={TEST_ID.CONTAINER}>
      <MessageHeader
        isEmailSynced={isEmailSynced}
        sender={{
          readOnly: props.sender?.readOnly,
          selectedIdx: emailState.selectedSenderIdx,
          options: emailState.senderOptions,
          onChange: option => setSenderEmail(option),
        }}
        receiver={props.receiver}
        cc={{
          namedEmailList: [],
          readOnly: props.cc?.readOnly,
          selectedValues: emailState.selectedCCs,
          onSelected: value => onAddToList('selectedCCs', value),
          onRemoved: value => onRemoveFromList('selectedCCs', value),
        }}
        bcc={{
          namedEmailList: [],
          readOnly: props.bcc?.readOnly,
          selectedValues: emailState.selectedBCCs,
          onSelected: value => onAddToList('selectedBCCs', value),
          onRemoved: value => onRemoveFromList('selectedBCCs', value),
        }}
        subject={{
          readOnly: props.subject?.readOnly,
          value: emailState.subject,
          onChange: setSubject,
        }}
        disabled={props.loading}
        shouldValidate={shouldValidate}
      />
      <TextareaContainer data-testid={TEST_ID.BODY}>
        <DefaultTextEditor
          key={editorKey}
          value={value}
          onChange={onChange}
          dataTestId={TEST_ID.CONTAINER}
          customElements={[ELEMENTS.SIGNATURE]}
          plugins={[{ name: 'withSignature', fn: withSignature }]}
          minHeight={50}
          applyBrandSettingsFontFamily
          toolbarButtons={EMAIL_EDITOR_BUTTONS}
        />
      </TextareaContainer>
      <ButtonBlock hasError={hasError}>
        <EmailErrorMessage
          hasError={hasError}
          hasNonUploadedImagesError={pendingImagesError}
          isUploading={loadingImagesError}
        />
        <EmailErrorMessage
          hasError={ccOrBccError}
          errorMessage={text.invalidCCorBCC}
        />

        {props.canClose ? (
          <CaretDropdownButton
            appearance="secondary"
            loading={props.loading}
            dataTestId={TEST_ID.SEND_BUTTON}
            dropdownOptions={[
              {
                label: text.buttons.sendAndCloseModal,
                onClickAction: () => {
                  props.setAfterSendActions({
                    allowUpdateStatus: false,
                    allowClose: true,
                  });
                  return onSendMessage();
                },
              },
              {
                label: props.isCompleted
                  ? text.buttons.sendAndOpen
                  : text.buttons.sendAndComplete,
                onClickAction: () => {
                  props.setAfterSendActions({
                    allowUpdateStatus: true,
                    allowClose: true,
                  });
                  return onSendMessage();
                },
              },
            ]}
            mainButtonOption={{
              label: text.buttons.send,
              onClickAction: () => {
                props.setAfterSendActions({
                  allowUpdateStatus: false,
                  allowClose: false,
                });
                return onSendMessage();
              },
            }}
          />
        ) : (
          <Button
            dataTestId={TEST_ID.SEND_BUTTON}
            appearance="secondary"
            size="medium"
            loading={props.loading}
            disabled={
              editorErrors.length > 0 || isEmailSynced === false || ccOrBccError
            }
            onClick={() => onSendMessage()}
            label={text.buttons.send}
          />
        )}
      </ButtonBlock>
    </div>
  );
};

type ButtonBlockType = {
  hasError: boolean;
};
const ButtonBlock = styled.div<ButtonBlockType>`
  display: flex;
  align-items: center;

  ${({ hasError }) => {
    if (hasError) {
      return css`
        justify-content: space-between;
      `;
    } else {
      return css`
        justify-content: flex-end;
      `;
    }
  }};
`;

const convertAttachment = (
  attachment: EmailEventAttachment,
  inlineId: string,
): EventContactEmail_New_Attachment => {
  const { s3key, filename } = attachment;

  return {
    s3key,
    filename,
    inlineId,
  };
};

const sharedBorderStyle = theme => css`
  border: 1px solid ${theme.color('grey', 'light')};

  margin: ${theme.space('s')} 0;
  padding: ${theme.space('s')} 0;
`;

const CTAContainer = styled.div<{}>(
  ({ theme }) => css`
    ${sharedBorderStyle(theme)}
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white', 'dark')};
  `,
);

const TextareaContainer = styled.div<{}>(
  ({ theme }) => css`
    ${sharedBorderStyle(theme)}
    border-left-width: 0;
    border-right-width: 0;
  `,
);

export default EmailInputFields;
