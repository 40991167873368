import React from 'react';
import CallToActionBlock from '../CallToActionBlock';
import useOnUpgradeSubscription from '~/hooks/useOnUpgradeSubscription';
import { IconType } from '~/components/atom/Icon';

export type Props = {
  /**
   * Allows overwrite of default header text
   */
  header?: string;

  /**
   * Allows overwrite of default description
   */
  description?: string;

  /**
   * Allows overwrite of default icon
   */
  icon?: IconType;

  /**
   * Allows overwrite of default button label
   */
  buttonLabel?: string;

  /**
   * Allows overwrite of default icon in the button
   */
  buttonIcon?: IconType;
};

const text = {
  fallbackHeader:
    'Let op! Sommige features op deze pagina zijn niet beschikbaar voor jouw abonnement',
  fallbackDescription:
    'Jouw abonnement laat momenteel niet toe dat je deze features gebruikt. Wil je graag gebruik maken van deze feature upgrade dan naar Pro',
  buttonLabel: 'Upgrade nu!',
};

const UpgradePlanCta: React.FCC<Props> = ({
  dataTestId,
  header,
  description,
  icon,
  buttonIcon,
  buttonLabel,
  ...rest
}) => {
  const onUpgrade = useOnUpgradeSubscription({});

  return (
    <CallToActionBlock
      dataTestId={dataTestId}
      header={header ?? text.fallbackHeader}
      description={description ?? text.fallbackDescription}
      icon={{
        background: 'warning',
        name: icon ?? 'arrow',
      }}
      button={{
        appearance: 'secondary',
        icon: buttonIcon ?? 'arrow',
        label: buttonLabel ?? text.buttonLabel,
        onClick: onUpgrade,
      }}
      {...rest}
    />
  );
};

export default UpgradePlanCta;
