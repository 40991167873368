import React, { useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  type FormBuilder_ScreenNode_Block,
  type FormBuilder_ScreenNode_BlockFragment,
  type Maybe,
} from '~/graphql/types';
import { DND_SOURCES } from '../../../../constants';
import Dropdown from '~/components/molecule/Dropdown';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useDragLayer, useDrop } from 'react-dnd';
import { ItemType, type DnDItemProps } from '~/components/atom/DraggableItem';
import getEmptyBlock from './utils/getEmptyBlock';
import { Body } from '~/components/atom/Typography';
import useBlockOptions from '~/components/page/Forms/components/Builder/hooks/useBlockOptions';
import { nodeById } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import { optionListsState } from '~/components/page/Forms/components/Builder/state/optionList';
import isSelectTypeBlock from '~/components/page/Forms/components/Builder/utils/isSelectTypeBlock';
import createEmptyList from '../../../../utils/createEmptyList';

type Props = {
  index: number;
  nodeId: string;
  onBlockFocus: (
    block: Maybe<FormBuilder_ScreenNode_BlockFragment> | null,
  ) => void;
};

const text = {
  dndLabel: 'Sleep en zet het item hier neer',
  newBlock: '+ Blok toevoegen',
};

const AddNewBlock: React.FC<Props> = ({ onBlockFocus, index, nodeId }) => {
  const ref = useRef<HTMLDivElement>(null);
  const setNodeState = useSetRecoilState(nodeById(nodeId));
  const { options } = useBlockOptions({ nodeId });
  const [optionsList, setOptionsList] = useRecoilState(optionListsState);

  const { isDragging, itemType, source } = useDragLayer(monitor => ({
    source: monitor.getItem()?.source,
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }));

  const insertNewBlock = (
    typename: FormBuilder_ScreenNode_Block['__typename'],
  ) => {
    let newBlock = getEmptyBlock(typename);
    const newListItem = createEmptyList(
      `Nieuw opties ${optionsList.length + 1}`,
    );
    if (isSelectTypeBlock(newBlock)) {
      setOptionsList(prev => [...prev, newListItem]);
      newBlock = {
        ...newBlock,
        optionListId: newListItem.id,
      };
    }
    setNodeState(prev => {
      if (!prev || prev.__typename === 'FormBuilder_EventNode') return prev;

      onBlockFocus(newBlock);
      const updatedBlocks = [...prev.blocks];
      // Insert the new block at the specified index
      updatedBlocks.splice(index, 0, newBlock);

      return {
        ...prev,
        blocks: updatedBlocks,
      };
    });
  };

  const [{ isOver }, drop] = useDrop({
    accept: ItemType,
    drop: (item: DnDItemProps) => {
      // Only accept new blocks from components collection
      if (item.source !== DND_SOURCES.componentsCollection) return;

      const typename: FormBuilder_ScreenNode_Block['__typename'] =
        item.itemId as FormBuilder_ScreenNode_Block['__typename'];

      insertNewBlock(typename);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  drop(ref);

  const showDnDUI =
    isDragging &&
    itemType === ItemType &&
    source === DND_SOURCES.componentsCollection;

  return (
    <Container
      ref={ref}
      padding={['base']}
      width="100%"
      align="center"
      justification="center"
      $dragging={showDnDUI}
      $isHovered={isOver}
    >
      {showDnDUI ? (
        <JustificationContainer padding={['base']} border={{ radius: 'base' }}>
          <Body size="base" color={{ group: 'primary' }} withoutMargin>
            {text.dndLabel}
          </Body>
        </JustificationContainer>
      ) : (
        <StyledDropdown
          icon={null}
          appearance="borderless"
          width="200px"
          placeholder={text.newBlock}
          options={options}
          onChange={selected => {
            const typename: FormBuilder_ScreenNode_Block['__typename'] =
              selected.option.payload;

            insertNewBlock(typename);
          }}
        />
      )}
    </Container>
  );
};

const StyledDropdown = styled(Dropdown)(
  ({ theme }) => css`
    padding: 0;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: ${theme.color('primary')};
    }
  `,
);

const pulse = keyframes`
    0% {
        transform: scale(1.02);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.02);
      }
`;

const Container = styled(JustificationContainer)<{
  $dragging: boolean;
  $isHovered: boolean;
}>(
  ({ theme, $dragging, $isHovered }) => css`
    position: relative;
    background-color: ${$dragging
      ? theme.color('primary', 'translucent')
      : 'transparent'};
    border-radius: ${theme.getTokens().border.radius.base};
    border: 1px dashed
      ${$dragging ? theme.color('primary', 'light') : 'transparent'};
    transition: transform 0.3s ease-out;

    ${$dragging &&
    css`
      margin: ${theme.space('base')} 0;
    `};

    ${$isHovered &&
    css`
      animation: ${pulse} 1.5s infinite;
    `};
  `,
);

export default AddNewBlock;
