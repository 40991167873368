import * as React from 'react';

import PromoSection from './PromoSection';
import styled, { css } from 'styled-components';
import useIntercom from '~/hooks/useIntercom';

type Props = {
  children: React.ReactNode;
};
const AuthWrapperHOC: React.FCC<Props> = ({ children }) => {
  const intercom = useIntercom();
  intercom.boot();

  return (
    <AuthWrapper>
      <PromoSection />
      <FormSection>{children}</FormSection>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div<{}>`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
  `};
`;

const FormSection = styled.main<{}>`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  transition: width 0.1s linear;
  padding: 32px 64px 16px;
`;

export default AuthWrapperHOC;
