import React from 'react';
import styled, { css } from 'styled-components';

import { CARD_Z } from '../BaseEventCard';
import { ActivityV2 } from '../../types';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = Pick<ActivityV2, 'icon'> & {
  dataTestId?: string;
};

const TimelineWrapper: React.FCC<Props> = ({ icon, children }) => (
  <JustificationContainer gap="xl">
    <IconContainer justification="center" align="center">
      <ServerIcon src={icon?.url} />
    </IconContainer>

    {children}
  </JustificationContainer>
);

const IconContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.full};

    flex: 1 0 ${theme.space('xxxl')};
    width: ${theme.space('xxxl')};
    height: ${theme.space('xxxl')};

    background-color: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('modal')};

    z-index: ${CARD_Z - 1};

    &:after {
      content: '';
      border-top: 1px solid ${theme.color('grey')};
      position: absolute;
      width: ${theme.space('xxxl')};
      left: ${theme.space('xxxl')};
      z-index: ${CARD_Z - 2};
    }
  `,
);
const ServerIcon = styled.img(
  () => css`
    width: 2em;
    z-index: ${CARD_Z};
  `,
);

export default TimelineWrapper;
