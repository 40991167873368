import React, { useContext, useEffect, useState } from 'react';

import { Helmet as MetaTags } from 'react-helmet';
import { FORM_ERROR } from 'final-form';
import { Form, EmailField, PasswordField } from '~/components/organism/Forms';
import Amplify from '~/amplify';
import AuthWrapperHOC from '~/components/page/Auth/components/AuthWrapperHOC';
import { AuthContext } from '~/Root/Auth';
import AuthNav from '~/components/page/Auth/components/AuthNav';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import { Link } from '~/components/molecule/Link';
import TEST_ID from './index.testid';
import Catalog from '~/Catalog';
import { fireTrackingEvent } from '~/hooks/useFireTrackingEvent';
import {
  navigate,
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@gatsbyjs/reach-router';
import { TAPFILLIATE_REF, setLocalStorageItem } from '~/util/localStorageKeys';
import validate from './utils/validate';
import getParamsFromMagicLink from './utils/getParamsFromMagicLink';
import { Body, Heading1 } from '~/components/atom/Typography';
import useStoreUtmParams from '~/hooks/useStoreUtmParams';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AuthFormWrapper from '../components/AuthFormWrapper';
import { TEMP_PASSWORD_EXPIRED_MESSAGE } from './constants';
import Input from '~/components/molecule/Input';
import triggerResetTempPassword from '../utils/triggerResetTempPassword';

type Props = RouteComponentProps<{
  location: {
    state: {
      from?: string;
      email?: string;
      message?: string;
    };
  };
}>;

const text = {
  title: 'Inloggen',
  userNotConfirmed: 'Dit e-mailadres is nog niet geverifiëerd.',
  emailFieldLabel: Catalog.emailLabel,
  passwordFieldLabel: 'Wachtwoord',
  forgotPassword: 'Wachtwoord vergeten?',
  loginButton: 'Inloggen',
  noAccount: 'Nog geen account?',
  registerHere: 'Registreer je hier',
  loginFailMessage:
    'De combinatie van e-mailadres en wachtwoord is niet geldig.',
  unknownLoginError: Catalog.genericUnknownErrorMessage,
  successMessage:
    'Je e-mailadres is succesvol geverifieerd. Je kan nu inloggen.',
};

export type Credentials = {
  email: string | null;
  password: string | null;
};

export type WindowState = {
  from?: string;
  email?: string;
  message?: string;
};

const Login: React.FCC<Props> = () => {
  useStoreUtmParams();
  const location = useLocation() as WindowLocation<WindowState>;
  const { ensureLogin, setTmpUser } = useContext(AuthContext);
  const [infoMsg, setInfoMsg] = useState(location.state?.message || '');
  const [initialCredentials, setInitialCredentials] = useState<Credentials>({
    email: location.state?.email ?? null,
    password: null,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tapfillateRef = params.get('ref');
    if (tapfillateRef) {
      setLocalStorageItem(
        TAPFILLIATE_REF,
        JSON.stringify({ date: Date.now(), ref: tapfillateRef }),
      );
    }

    // email just got verified and we landed on login again. Send event
    if (location.state?.message === text.successMessage) {
      fireTrackingEvent({
        event: 'register',
        message: location.state?.message,
      });
    }

    const credentials = getParamsFromMagicLink(location);
    if (credentials.email) proceedMagicLinkLogin(credentials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const loweredEmail = email.toLowerCase();

    return Amplify.Auth.signIn(loweredEmail, password)
      .then(user => {
        /**
         * We might get a challenge from cognito that a new
         * password is required. This means this user has
         * been invited. Setup their user details.
         *
         * I do not think that we should do this in a separate route.
         */
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setTmpUser(user);

          const userAttributes = user?.challengeParam?.userAttributes;
          if (userAttributes) {
            return navigate('/setup-user-details', {
              state: {
                email,
                name: userAttributes?.name,
                phone: userAttributes?.['custom:phone'],
              },
            });
          }
          return navigate('/setup-user-details', {
            state: { email },
          });
        }

        ensureLogin();

        // When the submit button gets clicked and there is no password challenge
        // we should fire an event to GTM
        fireTrackingEvent({
          event: 'login',
          loginMethod: 'email',
        });
        return;
      })
      .catch(async err => {
        // The temp password has expired so we need to trigger
        // new password generate
        if (
          (err.code === 'NotAuthorizedException' ||
            err.__type === 'NotAuthorizedException') &&
          err.message === TEMP_PASSWORD_EXPIRED_MESSAGE
        ) {
          const result = await triggerResetTempPassword(loweredEmail);
          return {
            [FORM_ERROR]: result,
          };
        }

        if (
          err.__type === 'UserNotConfirmedException' ||
          err.code === 'UserNotConfirmedException'
        ) {
          void navigate('/verify', { state: { email } });
          return {
            [FORM_ERROR]: text.userNotConfirmed,
          };
        } else {
          return authErrorMsg(err);
        }
      });
  };

  const autoSubmitForm = () => {
    setTimeout(() => {
      if (document != null && document.getElementById !== null) {
        // @ts-ignore
        const element = document.getElementById('signin-form');

        if (element != null) {
          element.dispatchEvent(new Event('submit', { cancelable: true }));
        }
      }
    }, 0);
  };

  const proceedMagicLinkLogin = ({ email, password }: Credentials) => {
    setInitialCredentials(() => ({
      email,
      password: password ?? null,
    }));

    if (password) {
      autoSubmitForm();
    }
  };

  const login = () => {
    setInfoMsg('');
  };

  return (
    <AuthWrapperHOC>
      <MetaTags>
        <title>{text.title}</title>
        <meta
          name="description"
          content="Inloggen op het portaal van DatHuis"
        />
      </MetaTags>

      <AuthNav selectedIdx={0} location={location} />
      <AuthFormWrapper>
        <Heading1
          lineHeight="base"
          margin={['l', null]}
          size="xxxl"
          color={{ group: 'primary' }}
        >
          {text.title}
        </Heading1>

        <Form
          initialValues={initialCredentials}
          onSubmit={onFormSubmit}
          validate={validate}
        >
          {({ handleSubmit, submitError, submitting, pristine }) => {
            const submitDisabled =
              (pristine && // make it available for automagical link submit ->
                !initialCredentials.email &&
                !initialCredentials.password) ||
              submitting;

            return (
              <form
                id="signin-form"
                onSubmit={handleSubmit}
                data-testid="sign-in-form"
              >
                {submitError ? (
                  <Body
                    withoutMargin
                    color={{ group: 'danger', variant: 'light' }}
                    size="base"
                    data-testid={TEST_ID.SIGN_IN_ERROR_MESSAGE}
                  >
                    {submitError}
                  </Body>
                ) : null}

                {infoMsg && (
                  <Body
                    withoutMargin
                    color={{ group: 'info' }}
                    size="base"
                    data-testid={TEST_ID.INFO_MESSAGE}
                  >
                    {infoMsg}
                  </Body>
                )}

                <JustificationContainer align="center" margin={['m', null]}>
                  <EmailField name="email">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        {...input}
                        size="large"
                        width="100%"
                        label={text.emailFieldLabel}
                        externalErrors={
                          error && touched
                            ? [FormUtils.showError(error, touched)]
                            : undefined
                        }
                        disabled={submitting}
                        autoFocus
                        autoComplete="username"
                      />
                    )}
                  </EmailField>
                </JustificationContainer>
                <JustificationContainer align="center" margin={['m', null]}>
                  <PasswordField name="password">
                    {({ input, meta: { error, touched } }) => (
                      <Input
                        {...input}
                        size="large"
                        width="100%"
                        label={text.passwordFieldLabel}
                        type="password"
                        autoComplete="current-password"
                        externalErrors={
                          error && touched
                            ? [FormUtils.showError(error, touched)]
                            : undefined
                        }
                        disabled={submitting}
                      />
                    )}
                  </PasswordField>
                </JustificationContainer>
                <JustificationContainer
                  align="center"
                  justification="space-between"
                  gap="base"
                >
                  <Link
                    to={'/forgot-password'}
                    dataTestId={TEST_ID.FORGOT_PASSWORD_LINK}
                  >
                    {text.forgotPassword}
                  </Link>
                  <Button
                    onClick={login}
                    appearance="secondary"
                    size="medium"
                    type="submit"
                    disabled={submitDisabled || submitting}
                    dataTestId="login-button"
                    loading={submitting}
                    label={text.loginButton}
                  />
                </JustificationContainer>

                <Body
                  align="center"
                  size="base"
                  margin={['xl', null, null, null]}
                >
                  {text.noAccount}{' '}
                  <Link
                    to={`/register/${location.search}`}
                    dataTestId="register-link"
                  >
                    {text.registerHere}
                  </Link>
                </Body>
              </form>
            );
          }}
        </Form>
      </AuthFormWrapper>
    </AuthWrapperHOC>
  );
};

export default Login;

const authErrorMsg = (err: { __type: string; code: string }) => {
  if (
    err.__type === 'UserNotFoundException' ||
    err.__type === 'NotAuthorizedException' ||
    err.code === 'UserNotFoundException' ||
    err.code === 'NotAuthorizedException'
  ) {
    return { [FORM_ERROR]: text.loginFailMessage };
  }

  return {
    [FORM_ERROR]: text.unknownLoginError,
  };
};
