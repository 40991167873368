import React, { useCallback, useState } from 'react';
import { FORM_ERROR } from 'final-form';

import {
  Link,
  RouteComponentProps,
  WindowLocation,
  useLocation,
} from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';
import { Form, Field } from '~/components/organism/Forms';
import Amplify from '~/amplify';
import AuthNav from '~/components/page/Auth/components/AuthNav';
import AuthWrapperHOC from '~/components/page/Auth/components/AuthWrapperHOC';
import PasswordInputGroup from '~/components/page/Auth/components/PasswordInputGroup';
import Catalog from '~/Catalog';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';
import { Body, Heading1, Heading3 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AuthFormWrapper from '../components/AuthFormWrapper';
import usePasswordValidation from '~/hooks/usePasswordValidation';
import Input from '~/components/molecule/Input';

type FormData = {
  verificationCode: string;
  password: string;
  passwordRepeat?: string;
};

type Props = RouteComponentProps<{}>;

type WindowState = {
  email?: string;
  code?: string;
};

type Credentials = {
  email: string | null | undefined;
  code: string | null | undefined;
};

const SetupPassword: React.FCC<Props> = () => {
  const location = useLocation() as WindowLocation<WindowState>;
  const initialCredentials = getParamsFromMagicLink(location);

  const [errorMsg] = useState(
    !initialCredentials.email ? Catalog.genericUnknownErrorMessage : null,
  );

  const { validatePassword, passwordConditions } = usePasswordValidation();

  const [isFocused] = useState<boolean>(
    initialCredentials.email && initialCredentials.code ? true : false,
  );

  const validate = useCallback(
    ({ verificationCode, password, passwordRepeat }: FormData) => {
      const errors: {
        verificationCode: undefined | string;
        password: undefined | string;
        passwordRepeat: undefined | string;
      } = {
        verificationCode: undefined,
        password: undefined,
        passwordRepeat: undefined,
      };

      const didEnterCode = verificationCode != undefined;

      if (!didEnterCode) {
        errors.verificationCode = 'Voer verificatienummer in';
      }

      const passwordErrors = validatePassword({ password, passwordRepeat });

      return { ...errors, ...passwordErrors };
    },

    [validatePassword],
  );

  const onFormSubmit = ({ verificationCode, password }: FormData) => {
    const email = initialCredentials.email || location?.state.email;

    if (!email) return;

    return Amplify.Auth.forgotPasswordSubmit(email, verificationCode, password)
      .then(() => {
        void navigate('/login', {
          state: {
            message: 'Jouw wachtwoord is aangepast. Je kan nu inloggen!',
          },
        });
      })
      .catch(err => {
        if (
          err.__type === 'CodeMismatchException' ||
          err.code === 'CodeMismatchException'
        ) {
          return {
            [FORM_ERROR]:
              err.message ||
              'Het verificatienummer klopt niet. Probeer het nog eens.',
          };
        }

        return {
          [FORM_ERROR]: err.message || Catalog.genericUnknownErrorMessage,
        };
      });
  };

  // Render
  const title = 'Maak nieuw wachtwoord';
  const emailMissed = !initialCredentials.email;

  return (
    <AuthWrapperHOC>
      <MetaTags>
        <title>{title}</title>
      </MetaTags>

      <AuthNav selectedIdx={0} showBackLink={true} />

      <AuthFormWrapper>
        <Heading1
          margin={['l', null]}
          lineHeight="base"
          color={{ group: 'primary' }}
          size="xxxl"
        >
          {title}
        </Heading1>

        {emailMissed && (
          <Heading3 fontWeight="regular">
            <Body
              size="base"
              color={{ group: 'danger', variant: 'light' }}
              withoutMargin
              data-testid={TEST_ID.INITIAL_ERROR_MESSAGE}
            >
              {errorMsg}
            </Body>
          </Heading3>
        )}

        {!emailMissed && (
          <React.Fragment>
            {location?.state && (
              <p>
                Er is een mail verstuurd naar {location?.state.email} met een
                verificatienummer erin.
              </p>
            )}
            <Form
              onSubmit={onFormSubmit}
              validate={values => {
                const errors = validate(values);
                return errors;
              }}
              initialValues={{
                verificationCode: initialCredentials.code ?? null,
                password: null,
                passwordRepeat: null,
              }}
            >
              {({
                handleSubmit,
                submitError,
                submitting,
                pristine,
                hasValidationErrors,
              }) => (
                <form
                  id="setupPassword"
                  onSubmit={handleSubmit}
                  data-testid={TEST_ID.SETUP_PASSWORD_FORM}
                >
                  {submitError ? (
                    <Body
                      size="base"
                      color={{ group: 'danger', variant: 'light' }}
                      withoutMargin
                      data-testid={TEST_ID.SETUP_PASSWORD_ERROR_MESSAGE}
                    >
                      {submitError}
                    </Body>
                  ) : null}
                  <JustificationContainer margin={['m', null]}>
                    <Field name="verificationCode">
                      {({ input, meta: { error, touched } }) => (
                        <Input
                          {...input}
                          size="large"
                          width="100%"
                          label="Verificatienummer"
                          type="text"
                          externalErrors={
                            error && touched
                              ? [FormUtils.showError(error, touched)]
                              : undefined
                          }
                          disabled={submitting}
                        />
                      )}
                    </Field>
                  </JustificationContainer>

                  <PasswordInputGroup
                    submitting={submitting}
                    passwordConditions={passwordConditions}
                    isFocused={isFocused}
                  />

                  <JustificationContainer align="center" justification="end">
                    <Button
                      size="large"
                      appearance="secondary"
                      type="submit"
                      disabled={submitting || pristine || hasValidationErrors}
                      data-testid={TEST_ID.SUBMIT_PASSWORD_BUTTON}
                      label="Maak nieuw wachtwoord aan"
                    />
                  </JustificationContainer>
                </form>
              )}
            </Form>
            <Body align="center" size="base" margin={['xl', null, null, null]}>
              Nog geen account?{' '}
              <Link to={`/register${location?.search}`}>
                Registreer je hier
              </Link>
            </Body>
          </React.Fragment>
        )}
      </AuthFormWrapper>
    </AuthWrapperHOC>
  );
};

const getParamsFromMagicLink = (
  location: WindowLocation<WindowState>,
): Credentials => {
  if (location.state && location.state.email) {
    return {
      email: location.state.email,
      code: location.state.code,
    };
  }
  const searchString = location.search;

  const email = searchString.match(new RegExp(/email=.*(?=&code=)/, 'g'));
  const code = searchString.match(new RegExp(/code=.*$/, 'g'));

  return {
    email: email ? email[0].slice(6) : null,
    code: code ? code[0].slice(5) : null,
  };
};

export default SetupPassword;
