import React from 'react';
import { animated, SpringValue, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import { Step as StepType } from '../..';
import { isNil } from 'ramda';

export type Props = {
  currentStep: number | null;
  index: number;
  item: StepType;
  isLastItem?: boolean;
  mainStepIndex?: number;
  style: {
    opacity: SpringValue<number>;
    transform: SpringValue<string>;
  };
};

const SPRING_CONFIG = { mass: 1, tension: 500, friction: 20 };

const Step: React.FCC<Props> = React.memo(
  ({ index, item, currentStep, style, isLastItem, mainStepIndex }) => {
    const _step = index + 1;
    const step = isNil(mainStepIndex) ? _step : `${mainStepIndex + 1}.${_step}`;
    const hasChecked = currentStep !== null ? index < currentStep : false;
    const listItemProps: ListItemProps = {
      $hasChecked: hasChecked,
      $isLastItem: isLastItem,
    };

    const land = useTransition(index === currentStep, {
      from: { transform: 'translateY(-10px)' },
      enter: { transform: 'translateY(0px)' },
      leave: { transform: 'translateY(-10px)' },
      config: SPRING_CONFIG,
    });

    const slideIn = useTransition(index === currentStep, {
      from: { transform: 'translateX(-40px) scale(0.5)', opacity: 0 },
      enter: { transform: 'translateX(0px) scale(1)', opacity: 1 },
      leave: { transform: 'translateX(-40px) scale(0.5)', opacity: 0 },
      config: SPRING_CONFIG,
    });

    if (item.disabled) {
      return (
        <ListItem {...listItemProps} style={style}>
          <CircleContainer $isDisabled>{step}</CircleContainer>
          <LabelContainer $isDisabled>{item.label}</LabelContainer>
        </ListItem>
      );
    }

    if (hasChecked) {
      return (
        <ListItem {...listItemProps} style={style}>
          <CircleContainer $isComplete>
            <StyledIcon name="check" strokeWidth={2.5} />
          </CircleContainer>
          <LabelContainer>{item.label}</LabelContainer>
        </ListItem>
      );
    }

    if (index === currentStep) {
      return land(
        (transition, cleared) =>
          cleared && (
            <ListItem {...listItemProps}>
              <CircleContainer $isActive style={transition}>
                {step}
              </CircleContainer>
              {slideIn(
                (slideInTransition, label) =>
                  label && (
                    <LabelContainer $isActive style={slideInTransition}>
                      {item.label}
                    </LabelContainer>
                  ),
              )}
            </ListItem>
          ),
      );
    }

    return (
      <ListItem {...listItemProps} style={style}>
        <CircleContainer>{step}</CircleContainer>
        <LabelContainer>{item.label}</LabelContainer>
      </ListItem>
    );
  },
);

type LabelProps = {
  $isActive?: boolean;
  $isDisabled?: boolean;
};

const LabelContainer = styled(animated.div)<LabelProps>(
  ({ $isActive, $isDisabled, theme }) => css`
    margin-left: ${theme.space('l')};
    font-weight: ${$isActive ? theme.fw('semiBold') : 'normal'};
    color: ${$isDisabled ? theme.color('grey') : 'inherit'};
  `,
);

const StyledIcon = styled(Icon)<{}>`
  font-size: ${({ theme }) => theme.fs('m')};
`;

type ListItemProps = { $hasChecked?: boolean; $isLastItem?: boolean };

const ListItem = styled(animated.li)<ListItemProps>(
  ({ theme, $hasChecked, $isLastItem }) => css`
    display: flex;
    align-items: center;

    padding: ${theme.space('m')} 0;
    font-size: ${theme.fs('base')};
    transition: border-color 0.5s ease;

    ${!$isLastItem &&
    css`
      &::before {
        content: '';
        top: 0;
        border-left: ${theme.getTokens().border.width.base} solid
          ${$hasChecked ? theme.color('success') : theme.color('white')};
        height: 100%;
        width: 100%;
        position: absolute;
        margin-left: ${theme.space('m')};
        margin-top: ${theme.space('xl')};
        z-index: -1;
      }
    `}
  `,
);

type CircleProps = {
  $isComplete?: boolean;
  $isActive?: boolean;
  $isDisabled?: boolean;
};

const CircleContainer = styled(animated.div)<CircleProps>`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.color('white')};
    font-weight: ${theme.fw('semiBold')};
    border-radius: ${theme.getTokens().border.radius.full};

    width: ${theme.fs('xl')};
    height: ${theme.fs('xl')};
    min-width: ${theme.fs('xl')};
  `}

  ${({ $isComplete, $isActive, $isDisabled, theme }) => {
    if ($isComplete) {
      return css`
        transition: all 0.3s ease;
        background-color: ${theme.color('success')};
        color: ${theme.color('white')};
      `;
    }

    if ($isActive) {
      return css`
        background-color: ${theme.color('primary')};
        color: ${theme.color('white')};

        box-shadow: 0px 7px 10px -4px rgba(74, 74, 74, 0.55);
      `;
    }

    if ($isDisabled) {
      return css`
        background-color: ${theme.color('grey')};
        color: ${theme.color('white')};
      `;
    }

    return null;
  }};
`;

export default Step;
