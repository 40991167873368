import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { createEditor, Element } from 'slate';
import { Slate } from 'slate-react';
import { withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { DHEditor, Plugin, type EditorValue } from './types';
import StaticToolbar from './components/StaticToolbar';
import withInlineVoidElements from './plugins/withInlineVoidElements';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import HoveringToolbar from './components/HoveringToolbar';
import withNormalizer from './plugins/withNormalizer';
import { EditableProps } from 'slate-react/dist/components/editable';
import { isEmpty } from 'ramda';
import withErrorBoundary from '~/ErrorBoundary';

import AppErrorScreen from '~/components/template/AppErrorScreen';
import EditableContainer from './components/EditableContainer';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import useBrandStyle from '~/hooks/useBrandStyle';
import type { ToolbarComponent } from './components/StaticToolbar/components/Buttons';

const text = {
  brokenEditorMessage:
    'Er is iets misgegaan bij het ophalen van de data van de teksteditor. Ververs de pagina. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

export type EditorPlugins = Array<{
  name: 'withHtml' | 'withImages' | 'withSignature';
  fn: Plugin;
}>;

export type Props = {
  /** Calls plugins starting from right to left so the left ones might override the values you set */
  plugins?: EditorPlugins;

  /** Additional elements to support in the editor, adds the corresponding toolbar button */
  customElements?: Array<ELEMENTS>;

  /** Value of the editor */
  value: EditorValue;

  /** Updates editor value onChange */
  onChange: (value: EditorValue) => void;

  /** Makes the editor and toolbar disabled */
  readOnly?: boolean;

  /** Makes the editor single line by disabling Enter key press */
  singleLine?: boolean;

  /** Hides static toolbar. Difference between singleLine is hideToolbar does not block adding new lines */
  hideToolbar?: boolean;

  /** Props to overwrite the Editable component's props */
  editableProps?: EditableProps;

  /** Extra buttons, components you want to add to the editor that needs the editor state */
  children?: (editor?: DHEditor) => React.ReactElement;

  /**
   * The placeholder text
   */
  placeholder?: string;

  /**
   * Sets a custom minHeight on the editable container.
   */
  minHeight?: number;

  /*
   * Blurs the editable container
   */
  blurred?: boolean;

  /** Passed where the font-family is applied to the html by the backend */
  applyBrandSettingsFontFamily?: boolean;

  /** Which buttons to render in the static toolbar */
  toolbarButtons?: Array<ToolbarComponent>;
};

const PluginsEditor: React.FCC<Props> = ({
  dataTestId,
  value,
  onChange,
  plugins = [],
  customElements = [],
  readOnly = false,
  editableProps,
  singleLine = false,
  hideToolbar = false,
  placeholder,
  minHeight = 50,
  blurred,
  applyBrandSettingsFontFamily,
  children,
  toolbarButtons = [],
}) => {
  const brandStyle = useBrandStyle();
  const defaultFontFamily = brandStyle?.fontFamily ?? null;

  const pluginFunctions = plugins.map(({ fn }) => fn);
  const allPlugins: Array<Plugin> = [
    withHistory,
    withReact,
    withInlineVoidElements,
    withNormalizer,
    ...pluginFunctions,
  ];

  const pluginsEditor = useMemo<DHEditor>(
    () => allPlugins.reduce((prev, plugin) => plugin(prev), createEditor()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [isFullScreen, setIsFullScreen] = useState(false);
  useGlobalKeyBinding({
    keys: 'esc',
    enabled: isFullScreen,
    callback: () => setIsFullScreen(false),
  });

  if (
    isEmpty(value) ||
    (Element.isElement(value[0]) && value[0].type === ELEMENTS.ERROR_BOUNDARY)
  ) {
    return (
      <AppErrorScreen
        message={text.brokenEditorMessage}
        inline
        setBackgroundColor={false}
      />
    );
  }

  return (
    <Container data-testid={dataTestId} isFullScreen={isFullScreen}>
      <Slate
        editor={pluginsEditor}
        value={value}
        onChange={newValue => {
          if (isEmpty(newValue)) return;

          /** Limits rerenders by not triggering onChange when the editor is only clicked */
          const isAstChange = pluginsEditor.operations.some(
            op => 'set_selection' !== op.type,
          );

          if (isAstChange) onChange(newValue);
        }}
      >
        {!singleLine && !hideToolbar && (
          <StaticToolbar
            customElements={customElements}
            readOnly={readOnly}
            onChangeFullScreen={value => setIsFullScreen(value)}
            isFullScreen={isFullScreen}
            buttons={toolbarButtons}
          />
        )}

        <HoveringToolbar />

        <EditableContainer
          readOnly={readOnly}
          editableProps={editableProps}
          pluginsEditor={pluginsEditor}
          singleLine={singleLine}
          hideToolbar={hideToolbar}
          customElements={customElements}
          placeholder={placeholder}
          minHeight={minHeight}
          blurred={blurred}
          defaultFontFamily={
            applyBrandSettingsFontFamily ? defaultFontFamily : null
          }
        >
          {/* TODO: Check typing here -- Error Boundary issue */}
          {/* @ts-ignore */}
          {children}
        </EditableContainer>
      </Slate>
    </Container>
  );
};

const Container = styled.div<{ isFullScreen: boolean }>(
  ({ isFullScreen, theme }) => {
    if (isFullScreen)
      return css`
        position: fixed;
        margin: 0;
        padding: ${theme.space('m')};
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        /** Make sure insert html modal or any other modal is visible */
        z-index: ${theme.getTokens().zIndex.top - 1};
        width: auto !important;
        background: ${theme.color('white')};
      `;

    return css`
      width: 100%;
    `;
  },
);

export default withErrorBoundary(
  PluginsEditor,
  <AppErrorScreen setBackgroundColor={false} />,
);
