import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

import { NamedEmail } from '~/util/namedEmailFormatter';

import ReceiverRow from './components/ReceiverRow';
import SenderRow from './components/SenderRow';
import SubjectRow from './components/SubjectRow';
import MultipleEmailRow from './components/MultipleEmailRow';
import TEST_ID from './index.testid';
import TextButton from '~/components/atom/TextButton';
import { OnChangeFunctionOf, OptionOf } from '~/components/molecule/Dropdown';
import type { EmailOption } from './hooks/useGetEmailOptions';

const text = {
  ccLabel: 'Cc:',
  bccLabel: 'Bcc:',
  ccButtonLabel: 'Cc',
  bccButtonLabel: 'Bcc',
};
export type ChangeableEmailProps = {
  readOnly?: boolean;
  selectedIdx: number | null;
  options: Array<OptionOf<NamedEmail>>;
  onChange: OnChangeFunctionOf<NamedEmail>;
  namedEmail?: NamedEmail;
};

export type ReceiverProps = {
  namedEmail: NamedEmail;
};
export type ChangeableEmailListProps = {
  namedEmailList: Array<NamedEmail>;
  readOnly?: boolean;
  selectedValues: Array<EmailOption>;
  onSelected: (value: EmailOption) => void;
  onRemoved: (value: EmailOption) => void;
};
export type AuthorisableSubject = {
  readOnly?: boolean;
  value?: string | null | undefined;
  onChange?: (newValue: string) => void;
};
type Props = {
  isEmailSynced: boolean | null;
  sender: ChangeableEmailProps;
  receiver: NamedEmail;
  cc: ChangeableEmailListProps;
  bcc: ChangeableEmailListProps;
  subject: AuthorisableSubject;
  disabled?: boolean;
  shouldValidate: boolean;
};
const MessageHeader: React.FCC<Props> = ({
  isEmailSynced = false,
  receiver,
  sender,
  subject,
  cc,
  bcc,
  disabled,
  shouldValidate,
}) => {
  const [showCc, setShowCc] = useState(shouldInitiallyShowEmailList(cc));
  const [showBcc, setShowBcc] = useState(shouldInitiallyShowEmailList(bcc));

  let ccComponent: ReactElement | null = null;
  if (showCc) {
    ccComponent = (
      <MultipleEmailRow
        rowLabel={text.ccLabel}
        grid={{ start: 'cc-start', end: 'cc-end' }}
        changeableEmailList={cc}
        disabled={disabled}
        dataTestId={TEST_ID.CC_ROW}
      />
    );
  }
  let bccComponent: ReactElement | null = null;
  if (showBcc) {
    bccComponent = (
      <MultipleEmailRow
        rowLabel={text.bccLabel}
        grid={{ start: 'bcc-start', end: 'bcc-end' }}
        changeableEmailList={bcc}
        disabled={disabled}
        dataTestId={TEST_ID.BCC_ROW}
      />
    );
  }
  let ccButtonComponent: ReactElement | null = null;
  if (!showCc) {
    ccButtonComponent = (
      <TextButton
        withoutPadding
        size="medium"
        dataTestId={TEST_ID.ADD_CC_BUTTON}
        onClick={() => setShowCc(true)}
        disabled={disabled}
        label={text.ccButtonLabel}
      />
    );
  }
  let bccButtonComponent: ReactElement | null = null;
  if (!showBcc) {
    bccButtonComponent = (
      <TextButton
        margin={[null, null, null, 'xxs']}
        withoutPadding
        size="medium"
        dataTestId={TEST_ID.ADD_BCC_BUTTON}
        onClick={() => setShowBcc(true)}
        disabled={disabled}
        label={text.bccButtonLabel}
      />
    );
  }

  return (
    <Container
      showCc={showCc}
      showBcc={showBcc}
      data-testid={TEST_ID.CONTAINER}
    >
      <ReceiverRow namedEmail={receiver} dataTestId={TEST_ID.RECEIVER} />
      {ccComponent}
      {bccComponent}
      <SenderRow
        isEmailSynced={isEmailSynced}
        changeableEmail={sender}
        disabled={disabled}
        shouldValidate={shouldValidate}
      />
      <SubjectRow
        subject={subject.value}
        readOnly={subject.readOnly}
        disabled={disabled}
        shouldValidate={shouldValidate}
        onChange={(newValue: string) => {
          if (subject.readOnly) return;

          if (subject.onChange) subject.onChange(newValue);
        }}
        dataTestId={TEST_ID.SUBJECT}
      />
      <CcBccButtonsContainer>
        {ccButtonComponent} {bccButtonComponent}
      </CcBccButtonsContainer>
    </Container>
  );
};

const shouldInitiallyShowEmailList = (
  list: ChangeableEmailListProps,
): boolean => {
  if (list.readOnly) {
    return list.namedEmailList.length > 0;
  } else {
    return list.selectedValues.length > 0;
  }
};

const CcBccButtonsContainer = styled.div<{}>`
  grid-row: receiver-start / receiver-end;
  grid-column: button-start / button-end;

  display: flex;
  align-items: center;
  white-space: nowrap;
`;

type ContainerProps = {
  showCc: boolean;
  showBcc: boolean;
};
const Container = styled.div<ContainerProps>`
  display: grid;

  ${({ showCc, showBcc }) => {
    const onlyShowingCc = showCc && !showBcc;
    const onlyShowingBcc = !showCc && showBcc;
    const notShowingAnyCcOrBcc = !showCc && !showBcc;

    if (notShowingAnyCcOrBcc) {
      return css`
        grid-template-rows:
          [receiver-start]
          min-content [receiver-end sender-start]
          min-content [sender-end subject-start]
          min-content [subject-end];
      `;
    }

    if (onlyShowingCc) {
      return css`
        grid-template-rows:
          [receiver-start]
          min-content [receiver-end cc-start]
          min-content [cc-end sender-start]
          min-content [sender-end subject-start]
          min-content [subject-end];
      `;
    }

    if (onlyShowingBcc) {
      return css`
        grid-template-rows:
          [receiver-start]
          min-content [receiver-end bcc-start]
          min-content [bcc-end sender-start]
          min-content [sender-end subject-start]
          min-content [subject-end];
      `;
    }

    return css`
      grid-template-rows:
        [receiver-start]
        min-content [receiver-end cc-start]
        min-content [cc-end bcc-start]
        min-content [bcc-end sender-start]
        min-content [sender-end subject-start]
        min-content [subject-end];
    `;
  }};

  grid-template-columns:
    [label-start]
    min-content [label-end input-start]
    1fr [input-end button-start]
    min-content [button-end];

  ${({ theme }) => css`
    grid-column-gap: ${theme.space('s')};
    grid-row-gap: ${theme.space('xxs')};
  `}
`;

export default MessageHeader;
