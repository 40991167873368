import { memoize } from 'underscore';
import {
  InvoiceListFieldsFragment,
  GetInvoicesQueryVariables,
  InvoiceStatus,
} from '~/graphql/types';
import { Link } from '~/components/bad/DataTables/types.flow';
import { convertServerDateStringToDate } from '~/util/date';
import { pluck } from 'ramda';

export type InvoiceListData = {
  id: string;
  invoiceNumber: string;
  dueDate: Date | null;
  amount: number;
  status: InvoiceStatus;
  hostedInvoiceUrl: Link | null;
  invoicePdf: Link | null;
};
const composeInvoiceListData = (
  items: Array<InvoiceListFieldsFragment> | null | undefined,
  _variables: GetInvoicesQueryVariables,
): Array<InvoiceListData> => {
  if (items == null) return [];

  return items.map(item => {
    const {
      amount,
      dueDate,
      hostedInvoiceUrl,
      id,
      invoiceNumber,
      invoicePdf,
      status,
    } = item;

    return {
      amount,
      dueDate: convertServerDateStringToDate(dueDate),
      hostedInvoiceUrl:
        hostedInvoiceUrl == null
          ? null
          : { label: 'Bekijk', link: hostedInvoiceUrl },
      id,
      invoiceNumber,
      invoicePdf:
        invoicePdf == null ? null : { label: 'Download', link: invoicePdf },
      status,
    };
  });
};

const memoizedComposeInvoiceListData = memoize(
  composeInvoiceListData,
  (items: Array<any>, variables) => {
    const { nextToken, currentPage, id } = variables;
    const itemIds = pluck('invoiceNumber', items).join('-');

    const result = `${items == null ? 'null' : itemIds}*@@*${
      nextToken || ''
    }*@@*${currentPage}*@@*${id}`;

    return result;
  },
);

export default memoizedComposeInvoiceListData;
