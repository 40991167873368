const leadScoreColors = {
  eq99: '#e91313',
  gte90: '#fc7e64',
  gte70: '#ecb75c',
  gte50: '#3da07b',
  gte30: '#2676a5',
  lt30: '#798697',
};

const getColorForLeadscore = (leadScoreToDisplay: number) => {
  if (leadScoreToDisplay === 99) return leadScoreColors.eq99;
  if (leadScoreToDisplay >= 90) return leadScoreColors.gte90;
  if (leadScoreToDisplay >= 70) return leadScoreColors.gte70;
  if (leadScoreToDisplay >= 50) return leadScoreColors.gte50;
  if (leadScoreToDisplay >= 30) return leadScoreColors.gte30;
  return leadScoreColors.lt30;
};
export default getColorForLeadscore;
