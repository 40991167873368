import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';

import { useRecoilState } from 'recoil';
import { Heading5, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import ColorInput from '~/components/molecule/ColorInput';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import useMeasure from '~/hooks/useMeasure';
import { layoutState } from '../../../../state/layout';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  emptyInputError: 'De naam mag niet leeg zijn',
  backgroundColor: 'Achtergrond kleur',
  backgroundImage: 'Achtergrond plaatje',
};

const LayoutSettings: React.FCC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
}) => {
  const [state, setState] = useRecoilState(layoutState);
  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const backgroundImage = useImageUpload({
    initialUrl: state.backgroundImage?.url,
    s3Key: state.backgroundImage?.s3key,
    onUpload: ({ s3Key, url }) => {
      setState(prev =>
        prev
          ? {
              ...prev,
              backgroundImage: { __typename: 'DHImage', s3key: s3Key, url },
            }
          : prev,
      );
    },
    onClear: () =>
      setState(prev =>
        prev
          ? {
              ...prev,
              backgroundImage: undefined,
            }
          : prev,
      ),
  });

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading="Layout"
        icon="pencil"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.backgroundColor}
            </Heading5>
            <ColorInput
              value={state.backgroundColor}
              onChange={color => {
                setState(prev =>
                  prev ? { ...prev, backgroundColor: color } : prev,
                );
              }}
            />
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.backgroundImage}
            </Heading5>
            <ImageUploadComponent
              onDelete={() => backgroundImage.clear()}
              uploading={backgroundImage.uploading}
              imageUrl={
                backgroundImage.s3Key != null ? backgroundImage.url : null
              }
              onUpload={file =>
                void backgroundImage.upload({
                  file: file[0],
                  filename: 'default_layout_background_image',
                })
              }
              direction="column"
            />
          </InputContainer>
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const InputContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow: hidden;
`;

export default LayoutSettings;
