import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import {
  AppStatusFields_AppStatus_Contaqt_Fragment,
  Exact,
  GetAppStatusesQuery,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';
import useAddToast from '~/hooks/useAddToast';
import TEST_ID from './index.testid';
import isDuplicatedToken from '~/components/page/Apps/Realworks/utils/isDuplicatedToken';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AddTokenModal from '../AddTokenModal';
import { ExtendedAppConfig } from '~/hooks/useApps';
import EditableText from '~/components/organism/EditableText';
import { Body } from '~/components/atom/Typography';
import InfoBlock from '~/components/molecule/InfoBlock';
import { isNil } from 'ramda';
import type { WatchQueryOptions } from '@apollo/client';
import Link from '~/components/molecule/Link';
import { CONTAQT_HELP_LINK } from '../../appConfig';

type Props = RouteComponentProps & {
  app: ExtendedAppConfig;
  updateQuery: (
    mapFn: (
      previousQueryResult: GetAppStatusesQuery,
      options: Pick<
        WatchQueryOptions<Exact<{ accountId: string }>, GetAppStatusesQuery>,
        'variables'
      >,
    ) => GetAppStatusesQuery,
  ) => void;
};

export const text = {
  appTitle: 'Contaqt - Nieuwsbrief',
  header: 'Koppeling aanpassen',
  addToken: 'Koppeling toevoegen',
  noTokens: 'Activeer de koppeling met Contaqt',
  description:
    'Pas hieronder de API token aan. Wil je de koppeling verwijderen? Zet dan de app uit.',
  emptyStateDescription: (
    <>
      <Link
        to="https://app.contaqt.marketing/dashboard/api-key"
        target="_blank"
      >
        Maak hier een API token aan.
      </Link>{' '}
      <br />
      Heb je geen account?{' '}
      <Link to={CONTAQT_HELP_LINK} target="_blank">
        Lees meer over Contaqt
      </Link>{' '}
    </>
  ),
  errorMessage:
    'Er is iets misgegaan bij het toevoegen van de token. Zorg ervoor dat je een valide token invoert.',
  duplicatedErrorMessage:
    'Dit token is al in gebruik, probeer een ander token.',
  success: 'Token is opgeslagen',
  infoHeader: 'De koppeling is actief',
};
const TokenPage: React.FCC<Props> = ({ app, updateQuery, ...rest }) => {
  const account = useCurrentAccount();
  const addToast = useAddToast();

  const initialToken = 'token' in app.appStatus ? app.appStatus.token : null;

  const [showModal, setShowModal] = useState<boolean>(isNil(initialToken));

  const [updateAppStatus, { loading: updateLoading }] =
    useUpdateAppStatusMutation();

  const updateToken = async (
    updatedToken: AppStatusFields_AppStatus_Contaqt_Fragment['token'],
  ) => {
    await updateAppStatus({
      variables: {
        accountId: account.id,
        update: {
          AppStatus_Contaqt: {
            enabled: true,
            token: updatedToken,
          },
        },
      },
    }).then(({ data, errors }) => {
      if (errors && errors?.length) {
        let errorMessage: string;

        if (isDuplicatedToken(errors)) {
          errorMessage = text.duplicatedErrorMessage;
        } else {
          errorMessage = text.errorMessage;
        }

        return addToast([formatToastMessage(errorMessage, 'danger')]);
      }

      if (data) {
        addToast([formatToastMessage(text.success, 'success')]);
        updateQuery(prevResult => ({
          ...prevResult,
          getAppStatuses: prevResult.getAppStatuses.map(appStatus => {
            if (appStatus.__typename !== 'AppStatus_Contaqt') return appStatus;
            return data.updateAppStatus;
          }),
        }));

        return setShowModal(false);
      }
    });
  };

  const hasToken = !isNil(initialToken);

  return (
    <AppDetailsContainer
      header={text.header}
      icon="key"
      {...rest}
      data-testid={TEST_ID.CONTAINER}
    >
      {hasToken ? (
        <>
          <Body>{text.description}</Body>
          <InfoBlock headerText={text.infoHeader}>
            <EditableText
              text={initialToken || ''}
              fontWeight="regular"
              headerSize="m"
              iconSize="base"
              onSave={updateToken}
              loading={updateLoading}
            />
          </InfoBlock>
        </>
      ) : (
        <>
          {showModal && (
            <AddTokenModal
              dataTestId={TEST_ID.ADD_TOKEN_MODAL}
              loading={updateLoading}
              onClose={() => setShowModal(false)}
              onAddToken={updateToken}
            />
          )}
          <EmptyStateComponent
            illustration="list"
            header={text.noTokens}
            description={text.emptyStateDescription}
            buttonLabel={text.addToken}
            onButtonClick={() => setShowModal(true)}
            dataTestId={TEST_ID.EMPTY_STATE}
          />
        </>
      )}
    </AppDetailsContainer>
  );
};

export default TokenPage;
