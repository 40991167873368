import React from 'react';
import { useState } from 'react';
import Textarea from '~/components/bad/Inputs/Textarea';

type Props = {
  label: string;
  placeholder: string;
  required: boolean;
};

const LargeText: React.FC<Props> = ({ label, placeholder, required }) => {
  const [value, setValue] = useState('');
  return (
    <Textarea
      label={label}
      placeholder={placeholder}
      required={required}
      name={''}
      onChange={value => setValue(value ?? '')}
      value={value}
    />
  );
};

export default LargeText;
