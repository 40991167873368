import { HeadingOneElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the OrderedListElement into html element
 * @param {HeadingOneElement} node - HeadingOneElement
 */
const serialize = (node: HeadingOneElement, children: string): string =>
  `<h1>${children}</h1>`;

export default serialize;
