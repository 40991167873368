/**
 * Export to allow other inputs that use the input styling to use the same calculations
 */

import { Theme } from '~/theme';

// The utils are only used in old components

export const calculateSpaceInsideComponent = (
  theme: Theme,
  small?: boolean,
  large?: boolean,
  xsmall?: boolean,
): number => {
  if (xsmall) return 6;
  if (small) return 8;
  if (large) return 16;
  return 12;
};

export const calculateFontSize = (
  theme: Theme,
  small?: boolean,
  large?: boolean,
  xsmall?: boolean,
): number => {
  if (xsmall) return 10;
  if (small) return 12;
  if (large) return 16;
  return 14;
};

export const calculateLineHeight = (
  theme: Theme,
  small?: boolean,
  large?: boolean,
  xsmall?: boolean,
): number => {
  if (xsmall) return 12;
  if (small) return 14;
  if (large) return 18;
  return 16;
};

export const calculateInputSize = (
  theme: Theme,
  small?: boolean,
  large?: boolean,
  xsmall?: boolean,
): number =>
  calculateFontSize(theme, small, large, xsmall) +
  2 * calculateSpaceInsideComponent(theme, small, large, xsmall);
