import React, { useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';

import useRepositionToBeInView from '~/components/bad/util/useRepositionToBeInView';
import { SystemSize } from '~/theme';
import arrayToCss from '~/util/arrayToCss';
import { Appearance } from '~/styles/constants';

export type Props = {
  className?: string;
  padding?: Array<SystemSize | null>;
  appearance?: Appearance;
};

const Tooltip: React.FCC<Props> = ({
  children,
  padding = ['base'],
  ...rest
}) => {
  const [componentRef, transform, recalculatePosition] =
    useRepositionToBeInView<HTMLDivElement>();

  useLayoutEffect(() => {
    const tooltip = componentRef.current;
    if (tooltip) {
      const handleMouseMove = (event: MouseEvent) => {
        const rect = tooltip.getBoundingClientRect();
        if (
          event.clientX >= rect.left &&
          event.clientX <= rect.right &&
          event.clientY >= rect.top &&
          event.clientY <= rect.bottom
        ) {
          // Mouse is within the tooltip area, prevent recalculating position
          return;
        }
        return recalculatePosition();
      };

      document.addEventListener('mouseenter', handleMouseMove);

      return () => {
        document.removeEventListener('mouseenter', handleMouseMove);
      };
    }
    return recalculatePosition();
  }, [componentRef, recalculatePosition]);

  return (
    <Container
      {...rest}
      $padding={padding}
      ref={componentRef}
      $translateX={transform.x}
      $translateY={transform.y}
    >
      {children}
    </Container>
  );
};

type ContainerProps = {
  $translateX: number;
  $translateY: number;
  $padding: Array<SystemSize | null>;
};
export const Container = styled.div<ContainerProps>(
  ({ theme, $translateX, $translateY, $padding }) => {
    const colorGroup = 'primary';
    const colorVariant = 'base';

    return css`
      position: absolute;
      transform: translateX(${$translateX}px) translateY(${$translateY}px);
      color: ${theme.color('white')};
      background-color: ${theme.color(colorGroup, colorVariant)};
      border: ${theme.getTokens().border.width.s} solid
        ${theme.color(colorGroup, colorVariant)};
      border-radius: ${theme.getTokens().border.radius.base};
      margin-top: ${theme.space('s')};
      padding: ${arrayToCss($padding, theme)};
      z-index: ${theme.z('dropdown')};
      font-size: ${theme.fs('base')};
      font-weight: normal;
      overflow-wrap: break-word;
    `;
  },
);

export default Tooltip;
