import { HeadingThreeElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the OrderedListElement into html element
 * @param {HeadingThreeElement} node - HeadingThreeElement
 */
const serialize = (node: HeadingThreeElement, children: string): string =>
  `<h3>${children}</h3>`;

export default serialize;
