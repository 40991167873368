import React from 'react';
import { MARKDOWN_EDITOR_BUTTONS } from './constants';
import PluginsEditor from '~/components/organism/PluginsEditor';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import type { ToolbarComponent } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons';

export type Props = {
  buttons?: Array<ToolbarComponent>;
  value: EditorValue;
  onChange: (value: EditorValue) => void;
};

const MarkdownEditorBase: React.FCC<Props> = ({ buttons, value, onChange }) => (
  <PluginsEditor
    value={value}
    onChange={onChange}
    toolbarButtons={buttons || MARKDOWN_EDITOR_BUTTONS}
    minHeight={200}
    customElements={[
      ELEMENTS.H1,
      ELEMENTS.H2,
      ELEMENTS.H3,
      ELEMENTS.H4,
      ELEMENTS.H5,
      ELEMENTS.H6,
    ]}
  />
);

export default MarkdownEditorBase;
