import { Router, RouterProps } from '@gatsbyjs/reach-router';
import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  children: React.ReactNode;
  height?: '100%' | 'auto';
} & RouterProps;

const DHRouter: React.FCC<Props> = ({ children, height = '100%', ...rest }) => (
  <StyledRouter $height={height} {...rest}>
    {children}
  </StyledRouter>
);

const StyledRouter = styled(Router)<{ $height: '100%' | 'auto' }>(
  ({ $height = '100%' }) => css`
    height: ${$height};
  `,
);

export default DHRouter;
