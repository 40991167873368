import React from 'react';
import DHRouter from '~/components/atom/DHRouter';
import OfficeList from './components/OfficeList';
import OfficeUserList from './components/OfficeUserList';
import OfficeDetails from './components/OfficeDetails';
import WorkingArea from './components/WorkingArea';

export default () => (
  <DHRouter>
    <OfficeList path="/" />
    <OfficeDetails path="/:officeId" />
    <OfficeUserList path="/:officeId/users" />
    <WorkingArea path="/:officeId/working-area" />
  </DHRouter>
);
