import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import React from 'react';
import styled from 'styled-components';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import AccountUsersTable from './components/AccountUsersTable';

type Props = RouteComponentProps<{ accountId: string }>;

const AccountPage: React.FCC<Props> = ({ ...rest }) => (
  <Container {...rest}>
    <MetaTags>
      <title>Account users</title>
    </MetaTags>

    <>
      <ContentContainerDefault>
        <OverviewListHeader title={'Account users'} />

        <AccountUsersTable accountId={rest.accountId} />
      </ContentContainerDefault>
    </>
  </Container>
);

const Container = styled.div<{}>``;

export default AccountPage;
