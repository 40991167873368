import { isNil } from 'ramda';
import { WindowConfig } from '../../components/WidgetPreview';

/* eslint-disable prefer-rest-params */
export const WIDGET_SCRIPT_ID = 'dh_umbrella_widget_script';
export const WIDGET_NAMESPACE = 'UmbrellaWidget';
const WIDGET_PROD_URL = 'https://btstrp.dathuis.nl/assets/widget.js';
const WIDGET_DEV_URL = 'https://dfpdk4k7cplv2.cloudfront.net/assets/widget.js';
// const WIDGET_DEV_URL = 'http://localhost:8080/assets/widget.js';
// const WIDGET_PROD_URL = 'http://localhost:8080/assets/widget.js';

const loadWidget = (accountId: string, config?: WindowConfig) =>
  new Promise<Function>(resolve => {
    const alreadyPlacedScript = document.getElementById(WIDGET_SCRIPT_ID);

    /**
     * @param {object} window - Reference to window object to make method shorter.
     * @param {object} document - Reference to the element that will contain the script tag and to make method shorter.
     * @param {string} scriptTagName - Contains the word 'script' to make method shorter.
     * @param {string} scriptSrc - The address of the main script that we need to load.
     * @param {string} WIDGET_NAMESPACE - This allows dynamically define global variable name to avoid conflicts with other SDKs.
     */
    // Store the name of the global variable
    window['DatHuisObject'] = WIDGET_NAMESPACE;
    // Creates a global variable to delegate, so it will be available before script loaded
    window[WIDGET_NAMESPACE] =
      window[WIDGET_NAMESPACE] ||
      function () {
        // Push arguments that were passed to delegate into queue
        // so they can be accessed later from the main script
        (window[WIDGET_NAMESPACE].q = window[WIDGET_NAMESPACE].q || []).push(
          arguments,
        );
      };

    // @ts-ignore
    window[WIDGET_NAMESPACE].l = 1 * new Date();

    if (
      alreadyPlacedScript != null &&
      // We reassign the space in the bootstrap so .q will be undefined when script is already ready
      typeof window[WIDGET_NAMESPACE].q === undefined
    ) {
      return resolve(window[WIDGET_NAMESPACE]);
    }
    // Dynamically create a script tag element with async execution
    const scriptElement: HTMLScriptElement = document.createElement(
      'script',
    ) as HTMLScriptElement;
    const firstScript: HTMLScriptElement = document.getElementsByTagName(
      'script',
    )[0] as HTMLScriptElement;

    scriptElement.id = WIDGET_SCRIPT_ID;
    // scriptElement.async = true;
    scriptElement.src =
      process.env.STAGE_NAME === 'prod' ? WIDGET_PROD_URL : WIDGET_DEV_URL;

    // Inject the script tag before first script tag found within document
    firstScript.parentNode?.insertBefore(scriptElement, firstScript);

    window[WIDGET_NAMESPACE]('init', { accountId, initialConfig: config });

    const checkAndResolve = () =>
      new Promise<Function>(_resolve => {
        if (isNil(window[WIDGET_NAMESPACE].q)) {
          return _resolve(window[WIDGET_NAMESPACE]);
        }

        // Keep trying until we get the API
        setTimeout(() => {
          void checkAndResolve().then(widgetApi => _resolve(widgetApi));
        }, 200);
      });

    window[WIDGET_NAMESPACE].onReady = function onReady() {
      void checkAndResolve().then(widgetApi => resolve(widgetApi));
    };
  });

export default loadWidget;
