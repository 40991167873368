import type { Option } from '~/components/molecule/Dropdown';
import { FormBuilder_Locale } from '~/graphql/types';

export type LocaleOption = Omit<Option, 'key'> & { key: FormBuilder_Locale };

const useLocaleOptions = (): Array<LocaleOption> => {
  const options = [FormBuilder_Locale.Nl, FormBuilder_Locale.En].map(curr => ({
    key: curr,
    label: `${curr} ${curr === FormBuilder_Locale.En ? '🇺🇸' : '🇳🇱'}`,
    payload: curr,
  }));

  return options;
};

export default useLocaleOptions;
