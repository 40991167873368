import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTable, useSortBy, useFlexLayout } from 'react-table';

import {
  TableColumnDefinitions,
  TableHeaderCellType,
} from '~/components/bad/DataTables/types.flow';
import { FlowListData } from '../util/composeFlowListData';

import { HeaderCell, RowCell } from '~/components/bad/DataTables';
import Catalog from '~/Catalog';
import { ACTION_COLUMN_ACCESSOR } from '~/components/bad/DataTables/util/TableHeaderCell/action';
import FlowActionColumnCell from './FlowActionColumnCell';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';

import TEST_ID from './FlowListTable.testid';
import useViewportSize from '~/components/bad/util/useViewportSize';
import Row from '~/components/bad/DataTables/styling/Row';
import { Header } from '~/components/bad/DataTables/styling';
import LoadingBody from '~/components/bad/DataTables/styling/LoadingBody';
import TableBody from '~/components/bad/DataTables/styling/Body';
import useSortSettings from '~/hooks/useSortSettings';
import { MAIN_CONTENT_WIDTH } from '~/components/organism/NavigationFrame/constants';
import flowActions from '../../v2/state';
import { useResetRecoilState } from 'recoil';
import NoResultSection from '~/components/page/NoResultSection';

type Props = {
  /** Table columns, column grouping limited to at most one level */
  columns: TableColumnDefinitions;

  /** Table data*/
  data: Array<FlowListData>;
  networkStatus: number;
  loading: boolean;
  error: boolean;
  onSuccessfulDelete: (flowId: string) => void;
  onSuccessfulEnabledChange: (flowId: string, newStatus: boolean) => void;
  options?: {
    renderHeaderCell?: (
      key: string,
      column: TableHeaderCellType<any>,
      isLastColumn: boolean,
    ) => React.ReactNode;
  };
};

const text = {
  toggleSortBy: '',
  queryError: Catalog.genericUnknownErrorMessage,
  noSearchResults: Catalog.noResults,
};

const FlowListTable: React.FCC<Props> = ({
  columns,
  data,
  error,
  loading,
  onSuccessfulDelete,
  onSuccessfulEnabledChange,
  options,
  networkStatus,
  ...rest
}) => {
  const theme = useTheme();
  const [sortSettings] = useSortSettings('flowSortSettings');
  const resetActions = useResetRecoilState(flowActions);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetHiddenColumns: false,
      getRowId: ({ id }) => id,
      manualSortBy: true,
      disableSortRemove: true,
      initialState: {
        sortBy: [sortSettings],
      },
    },
    useFlexLayout,
    useSortBy,
  );

  const [viewportSize] = useViewportSize();
  useEffect(() => {
    if (viewportSize.width > MAIN_CONTENT_WIDTH) {
      toggleHideAllColumns(false);
      setHiddenColumns(['description']);
    } else {
      setHiddenColumns(['lastModified', 'Statistics', 'description']);
    }
  }, [viewportSize.width, setHiddenColumns, toggleHideAllColumns, theme]);

  let bodyComponent;
  if (error) {
    bodyComponent = (
      <StyledNoResultSection data-testid={TEST_ID.ERROR_MESSAGE}>
        {text.queryError}
      </StyledNoResultSection>
    );
  } else if (isInitialLoadStatus(networkStatus)) {
    bodyComponent = (
      <LoadingBody
        rowAmount={rows.length || 10}
        getTableBodyProps={getTableBodyProps}
      />
    );
  } else if (data.length === 0 && !loading) {
    bodyComponent = (
      <StyledNoResultSection>{text.noSearchResults}</StyledNoResultSection>
    );
  } else {
    bodyComponent = (
      <TableBody.Standard.Inner {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);

          const rowProps = { ...row.getRowProps() };
          const flowId = row.original.id;
          const flowName = row.original.name;
          const flowDescription = row.original.description;
          const isDeleted = row.original && row.original.isDeleted === true;
          const runningFlowInstances =
            row.original.Statistics.nonCompletedFlowInstancesTotal;

          return (
            <Row
              {...rowProps}
              $isDeleted={isDeleted}
              key={rowProps.key}
              data-objectid={flowId}
              data-deleted={isDeleted}
              dataTestId={TEST_ID.LIST_ITEM}
              onClick={() => resetActions()}
              to={`/-/automation/flows/builder-v2/update/${flowId}`}
            >
              {row.cells.map((cell, idx) => {
                const isLastColumn = idx === row.cells.length - 1;

                if (cell.column.id === ACTION_COLUMN_ACCESSOR) {
                  return (
                    <FlowActionColumnCell
                      key={`cell-${idx}`}
                      flowName={flowName}
                      flowDescription={flowDescription}
                      flowId={flowId}
                      enabled={row.original.enabled}
                      cell={cell}
                      onSuccessfulDelete={() => onSuccessfulDelete(flowId)}
                      onSuccessfulEnabledChange={newState =>
                        onSuccessfulEnabledChange(flowId, newState)
                      }
                      runningFlowInstances={runningFlowInstances}
                    />
                  );
                }

                return (
                  <RowCell
                    key={`cell-${idx}`}
                    cell={cell}
                    isLastColumn={isLastColumn}
                  />
                );
              })}
            </Row>
          );
        })}
      </TableBody.Standard.Inner>
    );
  }

  return (
    <Container {...getTableProps()} {...rest} data-testid={TEST_ID.CONTAINER}>
      <Header.Large>
        {headerGroups.map(headerGroup => {
          const headerGroupProps = {
            ...headerGroup.getHeaderGroupProps(),
          };
          return (
            <StyledHeaderTr
              key={headerGroupProps.key}
              data-testid={TEST_ID.HEADER_ROW}
            >
              {headerGroup.headers.map((column, idx) => {
                const isLastColumn = idx === headerGroup.headers.length - 1;
                const headerCellKey = `header-${idx}`;

                if (options && options.renderHeaderCell) {
                  return options.renderHeaderCell(
                    headerCellKey,
                    column,
                    isLastColumn,
                  );
                }

                return (
                  <HeaderCell
                    key={headerCellKey}
                    column={column}
                    isLastColumn={isLastColumn}
                  />
                );
              })}
            </StyledHeaderTr>
          );
        })}
      </Header.Large>
      <TableBody.Standard.Outer>{bodyComponent}</TableBody.Standard.Outer>
    </Container>
  );
};

const StyledNoResultSection = styled(NoResultSection)<{}>`
  max-width: 800px;
`;

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

type TableWithStyleProps = {
  onClick?: () => void;
};
const Container = styled.div<TableWithStyleProps>(
  ({ onClick, theme }) => css`
    cursor: ${onClick ? 'pointer' : 'default'};
    user-select: none;
    border-spacing: 0;
    border-radius: ${theme.getTokens().border.radius.s};
    margin-bottom: ${theme.space('l')};
  `,
);

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

export default FlowListTable;
