import React, { useCallback } from 'react';
import InputWithButtons, {
  Props as InputWithButtonsProps,
} from '~/components/molecule/InputWithButtons';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { isEmpty as isEmptyString } from '~/util/Validation/String';
import TEST_ID from './index.testid';

export type Props = Omit<InputWithButtonsProps, 'buttonActions'> & {
  /** Input value */
  value: string;

  /** Initial value to check if the input value has changed */
  initialValue: string;

  /** Disabled state */
  disabled: boolean;

  /** onChange event for input field */
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;

  /** Saves changes */
  onSave: () => void;

  /** Cancels changes */
  onCancel: () => void;

  /** Removes input field */
  onClose: () => void;
};

/**
 * Includes Save and Cancel buttons and corresponding keybindings for them.
 */
const InputWithSaveButton: React.FCC<Props> = ({
  value,
  initialValue,
  disabled,
  size = 'small',
  onChange,
  onSave,
  onCancel,
  onClose,
  ...rest
}) => {
  const hasChanges = value !== initialValue;

  const onEnterPress = useCallback(() => {
    if (hasChanges) {
      onSave();
    } else {
      onClose();
    }
  }, [hasChanges, onSave, onClose]);

  useGlobalKeyBinding({
    keys: 'enter',
    callback: onEnterPress,
    enabled: !disabled,
  });

  useGlobalKeyBinding({
    keys: ['escape'],
    callback: onCancel,
    enabled: true,
  });

  return (
    <InputWithButtons
      {...rest}
      value={value}
      onChange={onChange}
      disabled={disabled}
      size={size}
      buttonActions={[
        {
          icon: 'close',
          appearance: 'danger',
          onClick: onCancel,
          dataTestId: TEST_ID.CANCEL_BUTTON,
        },
        {
          disabled: isEmptyString(value),
          onClick: onSave,
          dataTestId: TEST_ID.SAVE_BUTTON,
          icon: 'check',
        },
      ]}
    />
  );
};

export default InputWithSaveButton;
