import cleanedFilename from '~/util/cleanedFilename';

export default {
  LIST_ITEM: `${cleanedFilename(__filename)}-list-item`,
  HEADER_ROW: `${cleanedFilename(__filename)}-header-row`,
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  NAME: `${cleanedFilename(__filename)}-name`,
  FIELDS: `${cleanedFilename(__filename)}-fields`,
  ACTION: `${cleanedFilename(__filename)}-action`,
  CONFIRM_MODAL: `${cleanedFilename(__filename)}-confirm-modal`,
};
