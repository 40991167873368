import React from 'react';
import styled, { css } from 'styled-components';

import { TableCell } from '~/components/bad/DataTables/types.flow';

import { cellProps } from '../util/propGetters';
import ColumnResizer from './ColumnResizer';
import restrictedFields from './restrictedFields';
import { useLocation } from '@gatsbyjs/reach-router';
import useViewingModeProps from '~/hooks/useViewingModeProps';

type Props = {
  isLastColumn: boolean;
  cell: TableCell<any>;
  allowFieldCopying?: boolean;
};
const RowCell: React.FCC<Props> = ({
  cell,
  isLastColumn,
  allowFieldCopying,
}) => {
  const location = useLocation();
  const tdCellPropsInitial = cell.getCellProps(cellProps);
  const viewingModeProps = useViewingModeProps();
  const tdCellProps = {
    ...tdCellPropsInitial,
    style: {
      ...tdCellPropsInitial.style,
      minWidth: tdCellPropsInitial.style.width,
    },
  };

  const isOnFlows = location.pathname.includes('flows');

  return (
    <StyledTd
      {...tdCellProps}
      key={tdCellProps.key}
      data-testid={cell.column.dataTestId}
      onClick={e => allowFieldCopying && e.stopPropagation()}
      data-redacted={
        viewingModeProps['data-redacted'] &&
        // @ts-ignore ignore because according to TS there is no ID but there is...
        restrictedFields.includes(cell.column.id) &&
        !isOnFlows // Only use behaviour anywhere but flows
      }
    >
      {cell.render('Cell')}

      {cell.column.canResize &&
      !cell.column.disableResizing &&
      !isLastColumn ? (
        <ColumnResizer column={cell.column} />
      ) : null}
    </StyledTd>
  );
};

const StyledTd = styled.div<{}>`
  display: flex;
  align-items: center;
  align-content: center;
  word-break: break-word;
  ${'' /* Required for absolutely positioned resizer */}
  position: relative;

  ${({ theme }) => css`
    padding: 0 ${theme.space('xxs')};
    font-size: ${theme.fontSize('s')};
  `};
`;

export default RowCell;
