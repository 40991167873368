import React from 'react';
import styled, { css } from 'styled-components';
import Catalog from '~/Catalog';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Toolbar from '~/components/molecule/Toolbar';
import ControlHUD from '~/components/organism/ControlHUD';
import deserializeFormBuilder from '~/components/page/Forms/utils/deserializeFormBuilder';
import {
  useUpdateFormMutation,
  type FormBuilderFragment,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatToastMessage from '~/util/formatToastMessage';

import Breadcrumbs from '~/components/molecule/ContentContainer/components/Breadcrumbs';
import Button from '~/components/atom/Button';
import { REACT_FLOW_MINI_MAP } from '~/components/page/Automation/v2/components/Builder/constants/reactFlowLayers';
import AsideContainer from '../AsideContainer';
import useResetFormBuilder from '../../hooks/useResetFormBuilder';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { issueState } from '../../state/issues';
import { formState as formStateAtom, initialFormAtom } from '../../state';

const text = {
  save: 'Opslaan',
  cancel: 'Annuleren',
  viewLive: 'Bekijk live',
};

type Props = {
  id: string;
  formState: FormBuilderFragment | null;
  isFullScreen?: boolean;
  hasChanges: boolean;
  toggleFullScreen: () => void;
};

const ControlComponents: React.FC<Props> = ({
  id,
  formState,
  isFullScreen = false,
  hasChanges,
  toggleFullScreen,
}) => {
  const account = useCurrentAccount();
  const addToast = useAddToast();
  const [updateForm, { loading: updateLoading }] = useUpdateFormMutation({});

  const setFormState = useSetRecoilState(formStateAtom);
  const setInitialFormState = useSetRecoilState(initialFormAtom);

  const resetAllStates = useResetFormBuilder();
  const issues = useRecoilValue(issueState);
  const hasBlockingIssues =
    issues.filter(({ level }) => level === 'error').length > 0;

  const breadcrumbs = [
    { label: 'Formulieren', to: '/-/forms' },
    { label: formState?.name ?? 'form' },
  ];

  return (
    <Container>
      {!isFullScreen && (
        <JustificationContainer
          align="start"
          justification="start"
          direction="column"
        >
          <BreadcrumbContainer align="center" justification="space-between">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </BreadcrumbContainer>
          <Toolbar>
            <JustificationContainer gap="m">
              <Button
                size="medium"
                label={text.viewLive}
                ghost
                icon="eye"
                onClick={() => {
                  window.open(formState?.liveURL, '_blank');
                }}
              />
            </JustificationContainer>
            <JustificationContainer gap="s">
              <Button
                size="medium"
                label={text.cancel}
                ghost
                onClick={() => {
                  resetAllStates();
                }}
                disabled={!hasChanges}
              />
              <Button
                size="medium"
                appearance="secondary"
                label={text.save}
                icon="save"
                disabled={updateLoading || hasBlockingIssues || !hasChanges}
                loading={updateLoading}
                onClick={() => {
                  if (formState) {
                    const deserialized = deserializeFormBuilder(formState);
                    void updateForm({
                      variables: {
                        accountId: account.id,
                        formBuilderId: id,
                        formBuilder: deserialized,
                      },
                    }).then(({ errors, data }) => {
                      if (errors && errors.length !== 0) {
                        return addToast([
                          formatToastMessage(
                            Catalog.genericUnknownErrorMessage,
                            'danger',
                          ),
                        ]);
                      }

                      if (data) {
                        // Update the states so the change counter gets reset.
                        setFormState(data.updateForm);
                        setInitialFormState(data.updateForm);

                        return addToast([
                          formatToastMessage(
                            Catalog.genericSuccessMessage,
                            'success',
                          ),
                        ]);
                      }
                    });
                  }
                }}
              />
            </JustificationContainer>
          </Toolbar>
        </JustificationContainer>
      )}
      <ControlHUDContainer
        direction="column"
        align="end"
        isFullScreen={isFullScreen}
      >
        <ControlHUD
          isFullScreen={isFullScreen}
          onFullScreen={toggleFullScreen}
          infoLink="https://help.dathuis.nl/nl/articles/9653252-bouwen-van-formulieren"
        />
      </ControlHUDContainer>
      <AsideContainer isFullScreen={isFullScreen} />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: 0 ${theme.space('l')};
    width: 100%;
    background: ${theme.color('white', 'dark')};

    ${REACT_FLOW_MINI_MAP} {
      background: ${theme.color('primary', 'light')};
      position: relative;
      right: 0;
      bottom: 0;
      margin-top: ${theme.space('m')};
    }
  `,
);
const ControlHUDContainer = styled(JustificationContainer)<{
  isFullScreen: boolean;
}>(
  ({ theme, isFullScreen }) => css`
    position: absolute;
    right: ${theme.space('xl')};
    /* Prioritize over the canvas */
    z-index: 1;
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};

    ${REACT_FLOW_MINI_MAP} {
      background: ${theme.color('primary', 'light')};
      position: relative;
      right: 0;
      bottom: 0;
      margin: 0;
      margin-top: ${theme.space('m')};
      border-radius: ${theme.getTokens().border.radius.s};
    }
  `,
);

const BREADCRUMB_MOBILE_HEIGHT = '50px';
const BREADCRUMB_HEIGHT = '72px';
const BreadcrumbContainer = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    height: ${BREADCRUMB_HEIGHT};

    ${theme.mq.lessThan('tabletLandscape')`
        height: ${BREADCRUMB_MOBILE_HEIGHT};
      `}
  `};
`;

export default ControlComponents;
