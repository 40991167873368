import React from 'react';
import styled, { css } from 'styled-components';

import Icon from '~/components/atom/Icon';
import Tooltip from '~/components/molecule/Tooltip/';
import { Heading2 } from '~/components/atom/Typography';
import useHover from '~/components/bad/util/useHover';

export type Props = {
  headingText: React.ReactNode;
  tooltipText?: string | null;
};
const HeadingContainer: React.FCC<Props> = ({ headingText, tooltipText }) => {
  const [showTooltip, props] = useHover();

  return (
    <Container withoutMargin>
      {headingText}
      <div>
        {tooltipText && <StyledQuestionMark name="question-mark" {...props} />}
        {showTooltip && <StyledTooltip>{tooltipText}</StyledTooltip>}
      </div>
    </Container>
  );
};

const Container = styled(Heading2)<{}>`
  display: flex;
`;

const StyledQuestionMark = styled(Icon)<{}>(
  ({ theme }) => css`
    color: ${theme.color('primary', 'light')};
    margin-left: ${theme.space('s')};
  `,
);

const StyledTooltip = styled(Tooltip)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('m')};
  `,
);

export default HeadingContainer;
