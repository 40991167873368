import React, { useContext } from 'react';
import { navigate, type RouteComponentProps } from '@gatsbyjs/reach-router';
import { useEffect, useState } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import { FORM_ERROR } from 'final-form';
import Catalog from '~/Catalog';
import AuthWrapperHOC from '../components/AuthWrapperHOC';
import Amplify from '~/amplify';
import AuthNav from '~/components/page/Auth/components/AuthNav';
import SetupUserDetailsForm from './components/SetupUserDetailsForm';
import { AuthContext } from '~/Root/Auth';
import { reporter } from '~/hooks/useErrorReporter';
import { Body, Heading1 } from '~/components/atom/Typography';
import type { CognitoUser } from '@aws-amplify/auth';
import AuthFormWrapper from '../components/AuthFormWrapper';

export type FormData = {
  name: string | null;
  email: string | null;
  password: string | null;
  passwordRepeat: string | null;
  phone: string | null;
};

type Props = RouteComponentProps<{
  location?: RouteComponentProps['location'] & {
    state: {
      email?: string;
      phone?: string;
      name?: string;
    };
  };
}>;

const text = {
  genericErrorMessage: Catalog.genericUnknownErrorMessage,
  pageTitle: 'Vul je gegevens in',
};

type UserAttributes = {
  email: string;
  name?: string | null;
  phone?: string | null;
};

const SetupUserDetails: React.FC<Props> = ({ location }) => {
  const { ensureLogin, tmpUser } = useContext(AuthContext);
  const [userState, setUserState] = useState<UserAttributes | null>(null);

  const [routeError, setRouteError] = useState<boolean>(false);

  useEffect(() => {
    if (!location?.state || !location?.state.email) {
      setRouteError(true);
    } else {
      setUserState({
        email: location.state.email,
        phone: location.state.phone,
        name: location.state.name,
      });
    }
  }, [location?.state]);

  const onFormSubmit = ({
    name,
    password,
    phone,
    tempUser,
    ensureLogin,
  }: FormData & { tempUser: CognitoUser | null; ensureLogin: () => void }) => {
    Amplify.Auth.completeNewPassword(tempUser, password || '', {
      name,
      'custom:phone': phone,
    })
      .then(() => {
        ensureLogin();
        void navigate('/-/');
      })
      .catch(err => {
        reporter.captureException(err);
        return {
          [FORM_ERROR]: text.genericErrorMessage,
        };
      });
  };

  const hasUnknownError = !tmpUser || routeError;

  return (
    <AuthWrapperHOC>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>

      {!routeError && <AuthNav showBackLink={true} />}

      <AuthFormWrapper>
        <Heading1
          lineHeight="base"
          margin={['l', null]}
          color={{ group: 'primary' }}
          size="xxxl"
        >
          {text.pageTitle}
        </Heading1>

        {hasUnknownError ? (
          <Body
            size="base"
            color={{ group: 'danger', variant: 'light' }}
            withoutMargin
            data-testid="setup-user-error-message"
          >
            {text.genericErrorMessage}
          </Body>
        ) : null}
        <SetupUserDetailsForm
          disabled={hasUnknownError}
          onFormSubmit={data =>
            onFormSubmit({
              ...data,
              ensureLogin,
              tempUser: tmpUser,
            })
          }
          initialValues={{
            name: userState?.name || null,
            password: null,
            passwordRepeat: null,
            phone: userState?.phone || null,
            email: userState?.email || null,
          }}
          loading={false}
        />
      </AuthFormWrapper>
    </AuthWrapperHOC>
  );
};

export default SetupUserDetails;
