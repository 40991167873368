import React from 'react';
import styled from 'styled-components';
import { useTransition } from 'react-spring';
import APIBlock from '~/components/organism/APIBlock';
import { ZapierApiKey } from '~/graphql/types';
import useConfirmModal from '~/hooks/useConfirmModal';
import Description from './components/Description';
import sortApiKeys from './util';
import TEST_ID from './index.testid';

const text = {
  defaultName: 'API key',

  deleteApiKey: 'API key verwijderen',
  deletionErrorMessage:
    'Er is iets fout gegaan bij het verwijderen van de api key, probeer het later opnieuw.',
  deletionSuccessMessage: 'Api key succesvol verwijderd.',

  confirmMessage: 'Weet je zeker dat je deze api key wilt verwijderen?',
  confirmButton: 'Verwijderen',
  tooltipText:
    'Je kunt de laatste API key niet verwijderen, wanneer je alles wilt verwijderen moet je de app uitzetten via de apps pagina',
};

export type Props = {
  /** Api keys returned from getZapierApiKeys query */
  apiKeys?: Array<ZapierApiKey>;

  /** Disable text button for removing Api Key */
  disabled?: boolean;

  /** Updates getZapierApiKeysQuery result on delete */
  onDelete: (apiKey: string) => void;
};

const ApiKeysContainer: React.FCC<Props> = ({
  dataTestId,
  apiKeys,
  onDelete,
  disabled = false,
  ...rest
}) => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useConfirmModal(
    {
      title: text.deleteApiKey,
      message: text.confirmMessage,
      buttonConfirmTitle: text.confirmButton,
    },
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );

  const sortedApiKeys = sortApiKeys(apiKeys || []);

  const transitions = useTransition(sortedApiKeys, {
    from: {
      opacity: 0,
      transform: 'translateX(-40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0px)',
    },
    leave: {
      opacity: 0,
      transform: 'translateX(-40px)',
    },
    trail: 125,
    config: {
      mass: 1,
      tension: 110,
      friction: 14,
    },
    keys: item => item.apiKey,
  });

  return (
    <Container data-testid={dataTestId} {...rest}>
      {transitions(
        (style, { name, apiKey, lastConnectedAt }) =>
          apiKey && (
            <APIBlock
              heading={name || text.defaultName}
              description={
                <Description
                  lastConnectedAt={lastConnectedAt}
                  dataTestId={TEST_ID.API_KEY_DESCRIPTION}
                />
              }
              variant={lastConnectedAt ? 'success' : 'primary'}
              token={apiKey}
              cancelText={text.deleteApiKey}
              onDelete={() => {
                setShowDeleteConfirmModal(() => onDelete(apiKey));
                showDeleteConfirmModal();
              }}
              key={apiKey}
              dataTestId={TEST_ID.API_BLOCK}
              style={style}
              tooltipText={disabled ? text.tooltipText : undefined}
              disabled={disabled}
            />
          ),
      )}
    </Container>
  );
};

const Container = styled.div<{}>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  margin-bottom: 1rem;
`;

export default ApiKeysContainer;
