import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const HouseSwap: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 41.9001C16.6 41.5001 16 41.5001 15.7 41.9001L1 56.4001H5.2V69.9001H13.7V59.9001H18.9V69.9001H27.2V56.4001H31.4L17 41.9001Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6 51.1999V41.8999H22.2V46.7999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.6 3.3C54.2 2.9 53.6 2.9 53.3 3.3L38.6 17.8H42.8V31.3H51.3V21.3H56.5V31.3H64.8V17.8H69L54.6 3.3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.3 12.5998V3.2998H59.9V8.2998"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.80005 38.9001C7.80005 23.0001 20.7 10.1001 36.6 10.1001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.5 38.8999C65.5 54.7999 52.6 67.6999 36.7 67.6999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2 34.0002L7.80005 39.6002L2.30005 33.2002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.1 43.8999L65.5 38.3999L71 44.6999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default HouseSwap;
