import React from 'react';
import formatDate from '~/components/template/EventTimelineV2/utils/generateActivityV2/utils/formatDate';
import { Strong } from '~/components/atom/Typography';
import { ActivityFields_Task_Contact_Fragment } from '~/graphql/types';

export type Props = {
  dataTestId?: string;
  closedDate?: string | null;
  closedBy: ActivityFields_Task_Contact_Fragment['closedBy'];
};

const text = {
  closedBy: 'Voltooid door ',
  at: ' op ',
  unknown: 'Onbekend',
};

const ClosedTask: React.FC<Props> = ({ closedDate, closedBy }) => {
  const formattedDate = closedDate ? formatDate(closedDate) : '-';

  let closedByName: string | null = text.unknown;
  if (
    closedBy != null &&
    'name' in closedBy &&
    typeof closedBy.name === 'string'
  ) {
    closedByName = closedBy.name;
  }

  return (
    <>
      {text.closedBy}
      <Strong inline color={{ group: 'primary', variant: 'light' }}>
        {closedByName}
      </Strong>
      {text.at}
      <Strong inline color={{ group: 'primary', variant: 'light' }}>
        {formattedDate}
      </Strong>
    </>
  );
};

export default ClosedTask;
