import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil, isEmpty, equals, omit } from 'ramda';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';
import RunXTimes from './components/RunXTimes';
import { useRecoilState, useSetRecoilState } from 'recoil';
import flowSettings, {
  FlowSettings,
} from '~/components/page/Automation/v2/state/flowSettings';
import { Body, Heading5, Variant } from '~/components/atom/Typography';
import Textarea from '~/components/bad/Inputs/Textarea';
import TEST_ID from './index.testid';
import flowChanges, {
  FlowChanges,
} from '~/components/page/Automation/v2/state/flowChanges';
import useBuilderContext from '../../hooks/useBuilderContext';
import useDebounce from '~/hooks/useDebounce';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import useSize from '~/hooks/useSize';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  emptyInputError: 'De naam mag niet leeg zijn',
  name: 'Naam',
  description: 'Omschrijving',
  confirm: 'Bevestigen',
  cancel: 'Annuleer',
  modalHeader: 'Pas op!',
  modalBody:
    'Door het uitzetten van deze flow kan het zijn dat je contacten misloopt. Weet je zeker dat je deze flow wilt uitzetten?',
};

const SettingsMenu: React.FCC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
}) => {
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const [{ height }] = useSize(listContainerRef);

  const { initialFlow } = useBuilderContext();
  const [flowSettingsValues, setFlowSettings] = useRecoilState(flowSettings);

  const [localSettings, setLocalSettings] = useState<FlowSettings>({
    flowName: flowSettingsValues.flowName || initialFlow.flowName,
    flowDescription:
      flowSettingsValues.flowDescription || initialFlow.flowDescription || null,
    enabled: initialFlow.enabled,
    maximumFlowRun: initialFlow.maximumFlowRun || null,
  });

  const { enabled, maximumFlowRun, flowName, flowDescription } = localSettings;
  const setFlowHasChanges = useSetRecoilState(flowChanges);

  // Do not allow to save the changes if there's an error
  const [error, setError] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const spring = useSpring({
    height: expanded ? height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const onChange = useCallback(
    (
      key: 'flowName' | 'flowDescription' | 'enabled' | 'maximumFlowRun',
      value: any,
    ) => {
      setLocalSettings(prev => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const debounced = useDebounce(localSettings, 200);
  const hasNoChanges = equals(debounced, flowSettingsValues);

  useEffect(() => {
    if (hasNoChanges) return;

    setFlowSettings(prev => ({ ...prev, ...localSettings }));
    setFlowHasChanges(prev => {
      const res: FlowChanges = { ...prev };
      const obj = omit(['actions'], prev);
      for (const key in obj) {
        res[key] = localSettings[key] !== initialFlow[key];
      }
      return res;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounced]);

  const valuesStr = JSON.stringify(flowSettingsValues);
  useEffect(() => {
    /* Update local state when changes get cancelled */
    setLocalSettings(flowSettingsValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesStr]);

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading="Instellingen"
        icon="gear"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={listContainerRef}>
          <InputContainer>
            <Heading5 variant={Variant.primary}>{text.name}</Heading5>
            <Textarea
              dataTestId={TEST_ID.NAME}
              name="name"
              error={error}
              label={error ?? ''}
              value={flowName}
              onChange={value => {
                if (isNil(value) || isEmpty(value)) {
                  setError(text.emptyInputError);
                  onChange('flowName', '');
                  return;
                }
                setError(null);
                onChange('flowName', value);
              }}
            />
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>{text.description}</Heading5>
            <Textarea
              dataTestId={TEST_ID.DESCRIPTION}
              name="description"
              value={flowDescription}
              onChange={value => {
                if (isNil(value) || isEmpty(value)) {
                  onChange('flowDescription', null);
                  return;
                }
                onChange('flowDescription', value);
              }}
            />
          </InputContainer>
          <RunXTimes
            value={maximumFlowRun}
            onChange={value => onChange('maximumFlowRun', value)}
          />
          <ToggleCheckbox
            dataTestId={TEST_ID.CHECKBOX}
            label="Activeer flow"
            value={enabled}
            onChange={() => {
              if (initialFlow.enabled && enabled) {
                setShowConfirmModal(true);
              } else onChange('enabled', !enabled);
            }}
          />
        </Inner>
      </OverflowContainer>

      {showConfirmModal && (
        <Overlay onClose={() => setShowConfirmModal(false)}>
          <Dialog
            dataTestId={TEST_ID.DIALOG}
            header={text.modalHeader}
            body={<Body>{text.modalBody}</Body>}
            onConfirm={() => {
              onChange('enabled', false);
              setShowConfirmModal(false);
            }}
            confirmAction={{
              label: text.confirm,
            }}
            cancelAction={{
              label: text.cancel,
            }}
          />
        </Overlay>
      )}
    </Container>
  );
};

const Container = styled.aside`
  user-select: none;
  width: 100%;
`;

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const InputContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow: hidden;
`;

export default SettingsMenu;
