import React from 'react';
import styled from 'styled-components';

import InputGroupElement from '~/components/bad/Inputs/InputGroupElement';

type Props = {
  children: string;
};
const FieldLabel = ({ children }: Props) => <Container>{children}</Container>;

const Container = styled.div<{}>``;

export default InputGroupElement(FieldLabel, 'align-self: center');
