import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import styled, { css, useTheme } from 'styled-components';
import { Theme } from '~/theme';
import { sumArr } from '~/util/array';
import ChartTooltip from '~/components/molecule/Tooltip';
import useLocale from '~/hooks/useLocale';
import Legend from './components/Legend';
import CenterLabel from './components/CenterLabel';
import { ChartDataPoint } from '..';
import TEST_ID from './index.testid';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  /** Text inside the pie chart */
  label: string;
  data: Array<ChartDataPoint<number>>;
  /** To format the value in ChartDataPoint */
  formatOptions: Intl.NumberFormatOptions;
  /** Optional information to be added below the legend items */
  additionalInfo?: React.ReactNode | string;

  total?: number;
};

type UniqueItemsArray = Array<ChartDataPoint<number> & { uuid: string }>;

const DonutChart: React.FCC<Props> = ({
  label,
  data,
  formatOptions,
  additionalInfo,
  dataTestId,
  total,
}) => {
  const withUUIDs = useRef<UniqueItemsArray>(
    data.map(i => ({ ...i, uuid: uuid() })) || [],
  );

  const locale = useLocale();
  const theme: Theme = useTheme();
  const [activeOption, setActiveOption] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const totalAmount =
    total ?? sumArr(withUUIDs.current.map(({ value }) => value));
  const numberFormatter = new Intl.NumberFormat(locale, formatOptions);
  const formattedTotalAmount = numberFormatter.format(totalAmount ?? 0);

  const _data = addColorToDataPoint(withUUIDs.current, theme);

  return (
    <>
      <Container
        data-testid={`${dataTestId}-${TEST_ID.CONTAINER}`}
        align="center"
        wrap="wrap"
        justification="space-between"
      >
        <Inner>
          <ResponsiveContainer minWidth={280} height={300}>
            <PieChart>
              <Pie
                data={_data}
                cx={145}
                cy={145}
                innerRadius={105}
                outerRadius={123}
                paddingAngle={2}
                onAnimationEnd={() => setLoaded(true)}
                dataKey="value"
                animationEasing="ease"
              >
                {_data.map(({ color, uuid }, index) => (
                  <Cell
                    fill={
                      activeOption && uuid !== activeOption
                        ? theme.color('grey', 'light')
                        : color
                    }
                    key={`cell-${index}`}
                    stroke={uuid === activeOption ? color : undefined}
                    strokeWidth={4}
                    onMouseLeave={loaded ? () => setActiveOption(null) : f => f}
                    onMouseEnter={loaded ? () => setActiveOption(uuid) : f => f}
                  />
                ))}
                {loaded && (
                  <Label
                    content={
                      <CenterLabel
                        dataTestId={dataTestId}
                        formattedTotalAmount={formattedTotalAmount}
                        label={label}
                        loaded={loaded}
                      />
                    }
                  />
                )}
              </Pie>
              <Tooltip
                content={<ChartTooltip />}
                formatter={value => {
                  if (typeof value !== 'number') return value;
                  return `${((value / totalAmount) * 100).toFixed(0)}%`;
                }}
                isAnimationActive={true}
                animationEasing="ease"
              />
            </PieChart>
          </ResponsiveContainer>
        </Inner>
        <ScrollContainer>
          <Legend
            data={_data}
            numberFormatter={numberFormatter}
            additionalInfo={additionalInfo}
            setActiveOption={setActiveOption}
            loaded={loaded}
            activeOption={activeOption}
            dataTestId={dataTestId}
          />
        </ScrollContainer>
      </Container>
    </>
  );
};

const addColorToDataPoint = (arr: UniqueItemsArray, theme: Theme) => {
  const colors = theme.getTokens().colors.chart;

  return arr.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));
};

const ScrollContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  ${({ theme }) => css`
    padding: ${theme.space('base')};
  `};

  ${scrollBarStyles};
`;

const Container = styled(JustificationContainer)<{}>`
  width: 100%;
  height: 100%;
`;

const Inner = styled.div<{}>`
  min-width: 50%;
`;

export default DonutChart;
