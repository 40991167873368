import type { ConditionType } from '../Selector/utils/getFieldsByPath';
import type { RelativeMaps } from '../../../Builder/utils/getRelativeMaps';

import React, { useEffect, useState } from 'react';
import { clone, remove } from 'ramda';
import styled, { css } from 'styled-components';
import {
  FlowAction,
  FlowData___InstanceConditionFragment,
  FlowData___SubjectFieldFragment,
  Flow___ArgumentFragment,
  Flow___ConditionFragment,
  Flow___InstanceConditionFragment,
  Flow___SubjectFieldConditionFragment,
  ConnectorOperator,
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
} from '~/graphql/types';

import ConditionGroupElement from './components/ConditionGroup';
import Icon from '~/components/atom/Icon';
import ConnectorComponent from './components/ConditionGroup/components/ConnectorComponent';
import isActiveConditionSubExpression from './utils/isActiveConditionSubExpression';
import generateRelativeMaps from '../../utils/generateRelativeMaps';
import TextButton from '~/components/atom/TextButton';
import Div from '~/components/atom/Div';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { CONDITION_ARROW_TRAIL } from '../../../Builder/constants/zIndexes';

/**
 * 1. If a condition group changes - the output / instance might change.
 * 2.
 *
 * Action
 *  - Condition
 *    - Condition
 *    - Condition
 *  - Condition
 *  ...
 * Action...
 */

export type ConditionContext = {
  action: FlowAction;
  type: ConditionType;
};

export type ArgumentId = {
  conditionSubExpressionIdx: number;
  conditionGroupIdx: number;
  conditionIdx?: number;
  argumentIdx?: number;
  inputRef?: HTMLButtonElement | null;
};

export type BaseConditionOpts = {
  /** Flow builder data maps */
  maps: RelativeMaps;

  /** Input primitives for this condition editor */
  inputPrimitives: Array<FlowData___PrimitiveInputFragment>;
  /** Input list primitives for this condition editor */
  inputListPrimitives: Array<FlowData___PrimitiveListInputFragment>;

  /** Intl of the different fields in the condition editor */
  text: {
    newTrigger: string | React.ReactNode;
    emptyTrigger: string | React.ReactNode;
    newCondition: string | React.ReactNode;
    emptyCondition: string | React.ReactNode;
    addValue: string | React.ReactNode;
  };
};

export type ConditionOpts = BaseConditionOpts & {
  /** Context this condition is rendered in */
  context: ConditionContext;

  activeSelector: ArgumentId | null;
  setActiveSelector: (nextActiveSelector: ArgumentId | null) => void;
};

/**
 * Parses a ConditionExpression into ConditionSubExpressions and ConditionGroups.
 *
 * A ConditionExpression represents the entire expression, for example:
 * ([1] && [2] && [3]) && ([4 && 5]).
 *
 * ConditionSubExpressions are parts of the ConditionExpression separated by connectors,
 * for example:
 * - ([1] && [2] && [3])
 * - ([4 && 5])
 *
 * A ConditionGroup is denoted by square brackets ([]). ConditionSubExpressions contain
 * ConditionGroups. For example:
 *
 * - ([1] && [2] && [3])
 *   -> [1]
 *   -> [2]
 *   -> [3]
 *
 * - ([4 && 5])
 *   -> [4 && 5]
 *
 * If we are currently inside of a Start/Wait action or anything that triggers,
 * the first condition inside of the ConditionSubExpressions is considered the trigger.
 * In the case of our example:
 *
 * - ([1] && [2] && [3])
 *   -> [1] is the Trigger.
 *   -> [2]
 *   -> [3]
 *
 * - ([4 && 5])
 *   -> [4 && 5] is the Trigger.
 */

export type ConditionExpression = {
  connector: ConnectorOperator;
  conditionSubExpression: Array<ConditionSubExpression>;
};

export type ConditionSubExpression = {
  connector: ConnectorOperator;
  conditionGroups: Array<ConditionGroup>;
};

export type ConditionGroup = {
  connector: ConnectorOperator;
  conditions: Array<Flow___ConditionPartial | null>;
};

export type CompleteConditionExpression = {
  connector: ConnectorOperator;
  conditionSubExpression: Array<CompleteConditionSubExpression>;
};

export type CompleteConditionSubExpression = {
  connector: ConnectorOperator;
  conditionGroups: Array<CompleteConditionGroup>;
};

export type CompleteConditionGroup = {
  connector: ConnectorOperator;
  conditions: Array<Flow___Condition>;
};

type Props = {
  dataTestId?: string;
  /** Type of action this is rendered in */
  action: FlowAction;
  /** FlowBlueprintActionId - used to make pointer to self */
  actionId: string;
  /** Current condition to be rendered */
  conditionExpression: ConditionExpression;
  /** onChange handler - updating the condition whenever a change happened */
  onChange: (nextConditionExpression: ConditionExpression) => void;
  /** Outer container which will never be exceeded */
  outerContainer: HTMLElement | null;
  /** Assumes the first condition as the trigger */
  hasTriggers: boolean;
  /** If hasTriggers is set to true, this limits the max amount of triggers */
  limitTriggers?: number;
  /** Condition opts */
  opts: BaseConditionOpts;
};

export const text = {
  addCondition: 'Conditie toevoegen',
  refineCondition: 'Conditie verfijnen',
  done: 'Klaar',
  cancel: 'Afbreken',
  removeBlock: 'Stap verwijderen',
  triggerRowLabel: 'Trigger',
  conditionRowLabel: 'Condities',
};

/**
 * A potentially not yet completed instance condition.
 * Every `args` which is null is not yet filled.
 *
 * @see isCompleteCondition to validate the completeness of the condition
 */
export type Flow___InstanceConditionPartial = Omit<
  Flow___InstanceConditionFragment,
  'args'
> & {
  args: Array<Flow___ArgumentFragment | null>;
};

/**
 * A potentially not yet completed subject field condition.
 * Every `args` which is null is not yet filled.
 *
 * @see isCompleteCondition to validate the completeness of the condition
 */
export type Flow___SubjectFieldConditionPartial = Omit<
  Flow___SubjectFieldConditionFragment,
  'args'
> & {
  args: Array<Flow___ArgumentFragment | null>;
};

/**
 * Conditions can be partial - if they do not have all arguments filled
 */
export type Flow___ConditionPartial =
  | Flow___InstanceConditionPartial
  | Flow___SubjectFieldConditionPartial;

export type Flow___Condition =
  | Flow___InstanceConditionFragment
  | Flow___SubjectFieldConditionFragment;

export type FlowData___Condition =
  | FlowData___InstanceConditionFragment
  | FlowData___SubjectFieldFragment;

/**
 * Checks whether a partial condition is complete or not.
 */
export const isCompleteCondition = (
  condition: Flow___ConditionPartial,
): condition is Flow___ConditionFragment => {
  for (const arg of condition.args) {
    if (arg == null) return false;
  }

  return true;
};

/**
 * Checks whether a ConditionExpression is complete.
 *
 * E.g. can be send to the backend.
 */
export const isCompleteConditionExpression = (
  condition: ConditionExpression,
): condition is CompleteConditionExpression => {
  if (condition.conditionSubExpression.length === 0) return false;

  for (const subExpression of condition.conditionSubExpression) {
    if (subExpression.conditionGroups.length === 0) return false;

    for (const conditionGroup of subExpression.conditionGroups) {
      if (conditionGroup.conditions.length === 0) return false;

      for (const condition of conditionGroup.conditions) {
        if (!condition) return false;
        if (!isCompleteCondition(condition)) return false;
      }
    }
  }

  return true;
};

/**
 * Checks whether a ConditionExpression is complete.
 *
 * E.g. can be send to the backend.
 */
export const isEmptyConditionExpression = ({
  conditionSubExpression,
}: ConditionExpression): boolean => {
  if (conditionSubExpression.length === 0) return true;
  if (conditionSubExpression.length > 1) return false;

  const [{ conditionGroups }] = conditionSubExpression;
  if (conditionGroups.length === 0) return true;
  if (conditionGroups.length > 1) return false;

  const [{ conditions }] = conditionGroups;
  if (conditions.length === 0) return true;
  if (conditions.length > 1) return false;

  const [condition] = conditions;
  return condition == null;
};

export const isSubjectCondition = (
  condition?: any,
): condition is Flow___SubjectFieldConditionFragment =>
  condition != null && condition.__typename === 'Flow___SubjectFieldCondition';

const ConditionEditorV2: React.FCC<Props> = ({
  onChange,
  actionId,
  action,
  conditionExpression,
  outerContainer,
  opts,
  hasTriggers,
  limitTriggers,
}) => {
  const [activeSelector, setActiveSelector] = useState<ArgumentId | null>(null);

  useEffect(() => {
    /** Initial setting of the active selector */
    if (!isEmptyConditionExpression(conditionExpression)) return;

    setActiveSelector({
      conditionSubExpressionIdx: 0,
      conditionGroupIdx: 0,
      conditionIdx: 0,
      argumentIdx: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { conditionSubExpression, connector: conditionExpressionConnector } =
    conditionExpression;

  const triggerLimitExceeded =
    limitTriggers != null
      ? limitTriggers <= conditionSubExpression.length
      : false;

  const setConditionExpression = (
    nextConditionExpression: ConditionExpression,
  ) => {
    if (nextConditionExpression.conditionSubExpression.length !== 0) {
      return onChange(nextConditionExpression);
    }

    /** We removed the last conditionSubExpression - create empty one */
    onChange({
      conditionSubExpression: [
        {
          conditionGroups: [
            { conditions: [null], connector: ConnectorOperator.And },
          ],
          connector: ConnectorOperator.And,
        },
      ],
      connector: conditionExpressionConnector,
    });

    setActiveSelector({
      conditionSubExpressionIdx: 0,
      conditionGroupIdx: 0,
      conditionIdx: 0,
    });
  };

  const conditionSubExpressionElements = conditionSubExpression.map(
    (
      { conditionGroups, connector: conditionSubExpressionConnector },
      conditionSubExpressionIdx,
    ) => {
      const expressionKey = `${conditionSubExpression.length}-${conditionSubExpressionIdx}`;
      const triggerCondition = conditionGroups[0]?.conditions[0];

      const conditionGroupElements = conditionGroups.map(
        (
          { conditions, connector: conditionGroupConnector },
          conditionGroupIdx,
        ) => {
          const conditionGroupKey = `${expressionKey}-${conditionGroups.length}-${conditionGroupIdx}`;
          const isFirst = conditionGroupIdx === 0;
          const isTrigger = hasTriggers && isFirst;
          const type = isTrigger ? 'trigger' : 'condition';

          const relativeOpts = ((): BaseConditionOpts => {
            if (isTrigger) return opts;
            if (triggerCondition == null) return opts;

            return {
              ...opts,
              maps: generateRelativeMaps({
                triggerCondition,
                actionId,
                maps: opts.maps,
              }),
            };
          })();

          return (
            <ConditionGroupElement
              key={conditionGroupKey}
              conditionSubExpressionIdx={conditionSubExpressionIdx}
              conditionGroupIdx={conditionGroupIdx}
              conditions={conditions}
              connector={conditionGroupConnector}
              outerContainer={outerContainer}
              onDelete={() => {
                if (conditionGroupIdx === 0) {
                  /**
                   * You delete the first expression which is
                   * either the trigger, or the only element.
                   *
                   * Remove the whole thing.
                   */
                  setConditionExpression({
                    conditionSubExpression: remove(
                      conditionSubExpressionIdx,
                      1,
                      conditionSubExpression,
                    ),
                    connector: conditionExpressionConnector,
                  });

                  return;
                }

                const nextConditionSubExpression = [...conditionSubExpression];

                nextConditionSubExpression[
                  conditionSubExpressionIdx
                ].conditionGroups = remove(
                  conditionGroupIdx,
                  1,
                  nextConditionSubExpression[conditionSubExpressionIdx]
                    .conditionGroups,
                );

                setConditionExpression({
                  conditionSubExpression: nextConditionSubExpression,
                  connector: conditionExpressionConnector,
                });
              }}
              onUpdate={nextConditionGroup => {
                const nextConditionSubExpression = clone(
                  conditionSubExpression,
                );

                nextConditionSubExpression[
                  conditionSubExpressionIdx
                ].conditionGroups[conditionGroupIdx] = nextConditionGroup;

                setConditionExpression({
                  conditionSubExpression: nextConditionSubExpression,
                  connector: conditionExpressionConnector,
                });
              }}
              opts={{
                ...relativeOpts,
                context: { action, type },
                activeSelector,
                setActiveSelector,
              }}
            />
          );
        },
      );

      const activeConditionSubExpression = isActiveConditionSubExpression({
        activeSelector,
        argumentId: {
          conditionSubExpressionIdx,
        },
      });

      if (hasTriggers) {
        /** We render them expecting the first one to be a trigger */
        const triggerCondition = conditionGroups[0].conditions[0];
        const trigger = conditionGroupElements[0];

        const subConditionsOpts = (() => {
          if (triggerCondition == null) return { canHaveSubconditions: false };

          const subConditions = conditionGroupElements
            .slice(1)
            .map((subCondition, index) => {
              const isFirst = index === 0;
              const label = isFirst ? (
                <SubConditionLabel
                  backgroundColor={{ group: 'white' }}
                  padding={['xxs']}
                  border={{ radius: 's', width: 's', color: { group: 'grey' } }}
                >
                  De volgende condities moeten aanwezig zijn:
                </SubConditionLabel>
              ) : (
                <ConnectorComponent
                  connector={conditionSubExpressionConnector}
                  onChange={nextValue => {
                    const nextConditionSubExpression = clone(
                      conditionSubExpression,
                    );

                    nextConditionSubExpression[
                      conditionSubExpressionIdx
                    ].connector = nextValue;

                    setConditionExpression({
                      conditionSubExpression: nextConditionSubExpression,
                      connector: conditionExpressionConnector,
                    });
                  }}
                  editable={true}
                  label={{ and: 'en ', or: 'of' }}
                />
              );

              return (
                <React.Fragment key={`${subCondition.key}`}>
                  <SubConditionsConnectorContainerContainer
                    $isFirst={isFirst}
                    padding={['s', null]}
                    margin={[null, null, null, 'xxxs']}
                  >
                    <SubConditionsConnectorContainer>
                      {label}
                    </SubConditionsConnectorContainer>
                  </SubConditionsConnectorContainerContainer>
                  <SubConditionContainer
                    backgroundColor={{ group: 'white' }}
                    border={{ radius: 's' }}
                    padding={['xs']}
                  >
                    {subCondition}
                    <DeleteExpressionContainer
                      onClick={e => {
                        e.preventDefault();

                        const nextConditionSubExpression = clone(
                          conditionSubExpression,
                        );

                        nextConditionSubExpression[
                          conditionSubExpressionIdx
                        ].conditionGroups = remove(
                          index + 1,
                          1,
                          nextConditionSubExpression[conditionSubExpressionIdx]
                            .conditionGroups,
                        );

                        setActiveSelector(null);
                        setConditionExpression({
                          conditionSubExpression: nextConditionSubExpression,
                          connector: conditionExpressionConnector,
                        });
                      }}
                    >
                      <Icon name="trashcan" />
                    </DeleteExpressionContainer>
                  </SubConditionContainer>
                </React.Fragment>
              );
            });

          return { canHaveSubconditions: true, subConditions };
        })();

        return (
          <ConditionSubExpressionContainer
            key={conditionSubExpressionIdx}
            $activeConditionSubExpression={activeConditionSubExpression}
          >
            <TriggerContainer>{trigger}</TriggerContainer>
            {subConditionsOpts.canHaveSubconditions && (
              <SubConditionsContainer>
                {subConditionsOpts.subConditions}
                <TextButton
                  withoutPadding
                  label={opts.text.newCondition}
                  icon="plus"
                  onClick={() => {
                    const nextConditionSubExpression = clone(
                      conditionSubExpression,
                    );

                    nextConditionSubExpression[
                      conditionSubExpressionIdx
                    ].conditionGroups.push({
                      conditions: [null],
                      connector: ConnectorOperator.And,
                    });

                    setConditionExpression({
                      conditionSubExpression: nextConditionSubExpression,
                      connector: conditionExpressionConnector,
                    });

                    setActiveSelector({
                      conditionSubExpressionIdx,
                      conditionGroupIdx:
                        nextConditionSubExpression[conditionSubExpressionIdx]
                          .conditionGroups.length - 1,
                    });
                  }}
                />
              </SubConditionsContainer>
            )}
            <DeleteExpressionContainer
              onClick={e => {
                e.preventDefault();

                setActiveSelector(null);
                setConditionExpression({
                  conditionSubExpression: remove(
                    conditionSubExpressionIdx,
                    1,
                    conditionSubExpression,
                  ),
                  connector: conditionExpressionConnector,
                });
              }}
            >
              <Icon name="trashcan" />
            </DeleteExpressionContainer>
          </ConditionSubExpressionContainer>
        );
      }

      return (
        <ConditionSubExpressionContainer
          key={conditionSubExpressionIdx}
          $activeConditionSubExpression={activeConditionSubExpression}
        >
          {conditionGroupElements}
          <DeleteExpressionContainer
            onClick={e => {
              e.preventDefault();

              setActiveSelector(null);
              setConditionExpression({
                conditionSubExpression: remove(
                  conditionSubExpressionIdx,
                  1,
                  conditionSubExpression,
                ),
                connector: conditionExpressionConnector,
              });
            }}
          >
            <Icon name="trashcan" />
          </DeleteExpressionContainer>
        </ConditionSubExpressionContainer>
      );
    },
  );

  const elements = conditionSubExpressionElements.map((item, index) => {
    const isLast = conditionSubExpressionElements.length === index + 1;

    return (
      <GroupContainer key={item.key}>
        <ArrowTrail>
          <Arrow />
        </ArrowTrail>
        {item}
        {!isLast && (
          <ConditionSelect>
            <ConnectorComponent
              connector={conditionExpressionConnector}
              onChange={nextValue => {
                setConditionExpression({
                  conditionSubExpression,
                  connector: nextValue,
                });
              }}
              editable={true}
              label={{
                and: 'en',
                or: 'of',
              }}
            />
          </ConditionSelect>
        )}
        {isLast && !triggerLimitExceeded && (
          <TextButton
            label={opts.text.newTrigger}
            icon="plus"
            withoutPadding
            margin={['l', null, null, null]}
            size="large"
            onClick={() => {
              const nextConditionSubExpression = clone(conditionSubExpression);

              nextConditionSubExpression.push({
                conditionGroups: [
                  {
                    conditions: [null],
                    connector: ConnectorOperator.And,
                  },
                ],
                connector: ConnectorOperator.And,
              });

              setConditionExpression({
                conditionSubExpression: nextConditionSubExpression,
                connector: conditionExpressionConnector,
              });

              setActiveSelector({
                conditionSubExpressionIdx:
                  nextConditionSubExpression.length - 1,
                conditionGroupIdx: 0,
              });
            }}
          />
        )}
      </GroupContainer>
    );
  });

  return <div style={{ minHeight: '150px' }}>{elements}</div>;
};

const GroupContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    padding-left: ${theme.space('s')};
    padding-bottom: ${theme.space('xxxxl')};
  `,
);

const SubConditionLabel = styled(Div)(
  ({ theme }) => css`
    font-size: ${theme.fontSize('s')};
  `,
);

const ConditionSelect = styled.div(
  () => css`
    position: absolute;
    bottom: 8px;
    left: -8px;
    z-index: ${CONDITION_ARROW_TRAIL + 1};
  `,
);

const ArrowTrail = styled.div(
  () => css`
    position: absolute;
    top: -3px;
    left: 0px;
    transition: transform 200ms ease-out;
    z-index: ${CONDITION_ARROW_TRAIL};
    font-size: bold;
    height: 32px;
    width: 23px;
  `,
);

const Arrow = styled.div<{}>(
  ({ theme }) => css`
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    border-width: 0px 0px 2px 2px;
    border-style: dashed;
    border-color: ${theme.color('grey')};

    border-bottom-left-radius: 6px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      border-style: solid;
      border-width: 4px 0px 4px 8px;
      border-color: transparent transparent transparent ${theme.color('grey')};
    }
  `,
);

const DeleteExpressionContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: ${theme.color('white')};
    cursor: pointer;
    padding: ${theme.space('base')};
    transition: all 300ms ease-out;
    box-shadow: ${theme.boxShadow('base')};

    &:hover {
      color: ${theme.color('white')};
      background-color: ${theme.color('danger')};
      box-shadow: ${theme.boxShadow('s')};
    }
  `,
);

const ConditionSubExpressionContainer = styled.div<{
  $activeConditionSubExpression: boolean;
}>(
  ({ $activeConditionSubExpression, theme }) => css`
    position: relative;
    background-color: ${theme.color('white')};
    margin-bottom: 8px;
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 200ms ease-out;

    box-shadow: ${theme.boxShadow('sleek')};

    ${$activeConditionSubExpression &&
    css`
      outline: 1px solid ${theme.color('info')};
    `};
  `,
);

const TriggerContainer = styled.div(() => css``);

const SubConditionsContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color('tertiary', 'light')};
    padding: ${theme.space('s')} ${theme.space('m')};
    border-bottom-right-radius: ${theme.getTokens().border.radius.base};
    border-bottom-left-radius: ${theme.getTokens().border.radius.base};
  `,
);

const SubConditionContainer = styled(Div)(
  ({ theme }) => css`
    position: relative;
    border: 1px solid ${theme.color('info')};
    border-left: 4px solid ${theme.color('info', 'dark')};
    margin-bottom: ${theme.space('s')};
  `,
);

const SubConditionsConnectorContainerContainer = styled(
  JustificationContainer,
)<{
  $isFirst: boolean;
}>(
  ({ theme, $isFirst }) => css`
    display: flex;
    border-left: 1px solid ${theme.color('grey')};
    margin-left: ${theme.space('xxxs')};
    padding: ${theme.space('s')} 0px;
    z-index: ${theme.z('top')};

    ${() => {
      if (!$isFirst) {
        return css`
          margin-top: -12px;
        `;
      }

      return css`
        margin-top: -12px;
      `;
    }}
  `,
);

const SubConditionsConnectorContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color('white')};
    position: relative;
    left: -5px;
  `,
);

export default ConditionEditorV2;
