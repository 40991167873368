import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@gatsbyjs/reach-router';
import { Label } from '~/components/atom/Typography';
import type { ErrorsByPath } from '../../state';

export type Props = {
  dataTestId?: string;
  totalErrorCount: number;
  reportId: string;
  errorsByPath: ErrorsByPath;
};

/**
 * Used to display the error link in the save bar. If this is rendered the change count is not displayed.
 */
const ErrorDescription: React.FCC<Props> = ({
  totalErrorCount,
  errorsByPath,
  reportId,
}) => {
  const message = totalErrorCount === 1 ? 'fout' : 'fouten';
  const firstErrorIdx = Object.values(errorsByPath).findIndex(a => a !== null);
  const firstErrorPath = Object.keys(errorsByPath)[firstErrorIdx];
  const path = `/-/apps/value-report/${reportId}${firstErrorPath}`;

  return (
    <Label withoutMargin>
      <ErrorLink to={`${path}#${path}`}>
        {totalErrorCount} {message}
      </ErrorLink>
    </Label>
  );
};

const ErrorLink = styled(Link)<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

export default ErrorDescription;
