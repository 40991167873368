import React from 'react';
import styled, { css } from 'styled-components';

import { Heading1, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';

export type Props = {
  header: React.ReactNode;
  layoutClassName?: string;
};

const WizardLayout: React.FCC<Props> = ({
  header,
  children,
  layoutClassName,
  ...rest
}) => (
  <Container data-testid={TEST_ID.CONTAINER} {...rest}>
    <Heading1 variant={Variant.primary}>{header}</Heading1>
    <LayoutContainer className={layoutClassName}>{children}</LayoutContainer>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    max-width: ${theme.getTokens().grid.outer};
    width: 100%;
    margin: 0 auto;
    min-height: 80vh;
    padding: ${theme.space('xxl')};

    ${theme.mq.lessThan('mobile')`
        padding: ${theme.space('m')} 0;
     `};
  `,
);

const LayoutContainer = styled.div<{}>`
  display: grid;
  grid-template-columns: 0.3fr auto;

  ${({ theme }) => css`
    ${theme.mq.lessThan('mobile')`
      grid-template-columns: 1fr;
    `};
  `}
`;

export default WizardLayout;
