import React from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { ANIMATION_CONFIG } from '~/styles/constants';

export type Props = {
  dataTestId?: string;
  onClick?: () => void;
  /** If this element should be rendered hidden */
  hidden: boolean;
  label: string | React.ReactNode;
};

const AddConditionToGroupComponent: React.FC<Props> = ({
  dataTestId,
  onClick,
  hidden,
  label,
  ...rest
}) => {
  const labelStyle = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: hidden ? 0 : 1,
      transform: hidden ? `translateX(-20px)` : `translateX(0px)`,
    },
    config: ANIMATION_CONFIG.config,
  });

  return (
    <Container
      data-testid={dataTestId}
      $hidden={hidden}
      onClick={event => {
        event.stopPropagation();

        return onClick?.();
      }}
      {...rest}
      style={labelStyle}
    >
      {label}
    </Container>
  );
};

const Container = styled(animated.button)<{ $hidden: boolean }>(
  ({ theme }) => css`
    all: unset;
    cursor: pointer;
    font-size: ${theme.fontSize('s')};
    font-weight: bold;
    padding: ${theme.space('xs')};
    color: ${theme.color('info', 'dark')};
  `,
);

export default AddConditionToGroupComponent;
