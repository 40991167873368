import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { OptionOf } from '~/components/molecule/Dropdown';
import Dropdown from '~/components/molecule/Dropdown';
import { optionListsState } from '~/components/page/Forms/components/Builder/state/optionList';
import type {
  FormBuilder_ScreenNode_Block,
  FormBuilder_ScreenNode_BlockFragment,
  FormData_Select_Dropdown,
  FormData_Select_MultiButton,
  FormData_Select_Radio,
} from '~/graphql/types';
import OptionsEditor from './components/OptionsEditor';
import BlockLayout from '../BlockLayout';
import LocalesInput from '../LocalesInput';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import { BLOCK_OPTIONS } from '../../constants';
import Catalog from '~/Catalog';
import InAdvancedForm from '../../../../../InAdvancedForm';
import createEmptyList from '../../utils/createEmptyList';

const text = {
  required: Catalog.requiredField,
  changeInputType: 'Invoertype wijzigen',
};

type Props = {
  onChange: (next: FormBuilder_ScreenNode_BlockFragment) => void;
  nodeId: string;
  block:
    | FormData_Select_Dropdown
    | FormData_Select_MultiButton
    | FormData_Select_Radio;
};

const NEW_OPTION_PAYLOAD = 'new-options';

const componentsToSelect: Array<FormBuilder_ScreenNode_Block['__typename']> = [
  'FormData_Select_Dropdown',
  'FormData_Select_Radio',
  'FormData_Select_MultiButton',
];

const selectComponentOptions: Array<OptionOf<string>> = BLOCK_OPTIONS.filter(
  option => componentsToSelect.includes(option.payload),
);

const InputWithOptions: React.FCC<Props> = ({ onChange, nodeId, block }) => {
  const [optionLists, setOptionLists] = useRecoilState(optionListsState);
  const dropdownOptions: Array<OptionOf<string>> = [
    ...optionLists.map(
      ({ id, name }): OptionOf<string> => ({
        key: id,
        label: name,
        payload: id,
      }),
    ),
    {
      key: 'new-list',
      icon: { name: 'plus' },
      label: 'Nieuwe opties defineren',
      payload: NEW_OPTION_PAYLOAD,
    },
  ];

  const [selectedOptionListId, setSelectedOptionListId] = useState<
    string | null
  >(block.optionListId ?? null);

  const addNewOptionList = () => {
    const newEmptyList = createEmptyList(
      `Nieuw opties ${optionLists.length + 1}`,
    );
    setOptionLists(prev => [...prev, newEmptyList]);
    setSelectedOptionListId(newEmptyList.id);
  };

  return (
    <BlockLayout blockKey={block.key} nodeId={nodeId}>
      <JustificationContainer direction="column" gap="m" width="100%">
        <Dropdown
          selectedOptionIdx={selectComponentOptions.findIndex(
            opt => opt.payload === block.__typename,
          )}
          onChange={option =>
            onChange({ ...block, __typename: option.option.payload })
          }
          options={selectComponentOptions}
          label={text.changeInputType}
        />

        <InAdvancedForm>
          <Dropdown
            selectedOptionIdx={dropdownOptions.findIndex(
              ({ payload }) => payload === selectedOptionListId,
            )}
            options={dropdownOptions}
            onChange={({ option }) => {
              if (option.payload === NEW_OPTION_PAYLOAD) {
                return addNewOptionList();
              }
              return setSelectedOptionListId(option.payload);
            }}
          />
        </InAdvancedForm>

        {selectedOptionListId && (
          <>
            <LocalesInput
              block={block}
              onChange={onChange}
              subject="label"
              blockKey={block.key}
              nodeId={nodeId}
            />
            <OptionsEditor
              listId={selectedOptionListId}
              blockKey={block.key}
              nodeId={nodeId}
            />
          </>
        )}
        <ToggleCheckbox
          label={text.required}
          size="small"
          value={block.required}
          onChange={() => onChange({ ...block, required: !block.required })}
          containerProps={{
            justification: 'space-between',
            direction: 'row',
            align: 'center',
            width: '100%',
          }}
        />
      </JustificationContainer>
    </BlockLayout>
  );
};

export default InputWithOptions;
