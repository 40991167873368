import React from 'react';
import styled from 'styled-components';
import illustrations from './components';

export type Props = React.HTMLAttributes<HTMLSpanElement> & {
  name: keyof typeof illustrations;
  color?: string;
  secondaryColor?: string;
  strokeWidth?: number | string;
  className?: string;
};

const Illustration: React.FCC<Props> = ({
  name,
  color,
  secondaryColor,
  ...props
}) => {
  const PickedIllustration = illustrations[name];

  return (
    <Container {...props}>
      <PickedIllustration
        color={color || 'currentColor'}
        strokeWidth={props.strokeWidth || 2}
        secondaryColor={secondaryColor}
      />
    </Container>
  );
};

const Container = styled.span<{}>``;

export default Illustration;
