import styled, { css } from 'styled-components';

const StandardOuter = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    border-top: 1px solid ${theme.color('grey')};
  `}
`;

const StandardInner = styled.div<{}>`
  flex-grow: 1;
  align-self: start;
`;

export default {
  Standard: {
    Inner: StandardInner,
    Outer: StandardOuter,
  },
};
