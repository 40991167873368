import type { BrandSettings } from '~/hooks/useBrandSettings';
import basic from './basic';
import type { AppType, FormBuilderFragment } from '~/graphql/types';

export type FormTemplate = {
  appTypes: Array<AppType>;
  categories: Array<string>;
  id: string;
  name: string;
  description: string;
  weight: number;
  form: (args: TemplateArgs) => FormBuilderFragment;
};
export type TemplateArgs = {
  accountId: string;
  brandSettings: BrandSettings;
};

export const MOST_USED_CATEGORY_SLUG = 'meest-gebruikt';
export const MOST_USED_CATEGORY_LABEL = 'Meest gebruikt';

const formTemplates: Array<FormTemplate> = [
  {
    appTypes: [],
    categories: [MOST_USED_CATEGORY_LABEL],
    description: 'The most basic form',
    form: basic,
    id: 'basic',
    name: 'Basic template',
    weight: 900,
  },
];
export default formTemplates;
