import React from 'react';
import { useSpring, animated, AnimationProps } from 'react-spring';
import styled, { css } from 'styled-components';

import Link from '~/components/molecule/Link';
import useLocale from '~/hooks/useLocale';

export type Props = {
  icon: React.ReactNode;
  description: string;
  value: number;
  label: string;
  link: string;
  formatOptions?: Intl.NumberFormatOptions | null;
  animation?: AnimationProps<object>;
  /* Used to delay the animation, defaults to 0 */
  index?: number;
};
const ActivitySummary: React.FCC<Props> = ({
  icon,
  value,
  description,
  label,
  link,
  formatOptions = null,
  animation = {},
  index = 0,
  dataTestId,
}) => {
  const locale = useLocale();
  const _value = formatOptions
    ? new Intl.NumberFormat(locale, formatOptions).format(value)
    : value.toString();

  const spring = useSpring({
    from: { opacity: 0, transform: 'translate3d(0,-30px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    config: {
      mass: 1,
      tension: 60,
      friction: 12,
    },
    delay: 250 + index * 125,
  });

  return (
    <Container style={animation} data-testid={dataTestId}>
      <BoldText>{description}</BoldText>
      <LinkContainer style={spring} as={animated.div}>
        <IconContainer>{icon}</IconContainer>
        <Link to={link}>
          {_value} {label}
        </Link>
      </LinkContainer>
    </Container>
  );
};

const Container = styled(animated.div)<{ style: any }>`
  min-width: 200px;
  white-space: nowrap;

  ${({ theme }) => css`
    margin: ${theme.space('s')} 0;
    padding: 0 ${theme.space('xl')};

    &:not(:last-of-type) {
      border-right: 1px solid ${theme.color('grey')};
    }

    ${theme.mq.lessThan('mobile')`
      width: 100%;
      margin: 0;
      padding: ${theme.space('m')};

      &:not(:last-of-type) {
        border-right: none;
        border-bottom: 1px solid ${theme.color('grey', 'base')};
      }
    `}

    &:first-of-type {
      padding-left: 0;
    }
  `}
`;

const LinkContainer = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    font-size: ${theme.fs('xl')};
    color: ${theme.color('primary', 'light')};
    margin-top: ${theme.space('s')};
  `};
`;

const IconContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-right: ${theme.space('s')};
  `,
);

const BoldText = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
  `,
);

export default ActivitySummary;
