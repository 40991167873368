import React from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import {
  AppValuationReportReportPage_Update,
  AppValuationReport_Update,
} from '~/graphql/types';
import TextAndColorInput from '../../components/TextAndColorInput';
import ColorInput from '~/components/molecule/ColorInput';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  title: 'Rapport',
  buttonMainText: 'Hoofdtekst contactknop',
  buttonMainTextColor: 'Hoofdtekst kleur contactknop',
  buttonSubText: 'Subtekst contactknop',
  buttonSubTextColor: 'Subtekst kleur contactknop',
  buttonBackgroundColor: 'Achtergrondkleur contactknop',
  formSubText: 'Formulier subtekst',
  formSubTextColor: 'Formulier subtekstkleur',
  contactButtonClickedTextColor: 'Contact button aangeklikt kleur',
  contactButtonClickedText: 'Contact button aangeklikt tekst',
};

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportReportPage_Update | null;
  onUpdate: (value: AppValuationReportReportPage_Update) => void;
};

type Update = keyof AppValuationReport_Update['report'];

const FinalisedReport: React.FCC<Props> = ({ updated, loading, onUpdate }) => {
  if (!updated) return null;

  const { contactButton, contactButtonClickedText, formSubtext } = updated;

  return (
    <AppDetailsContainer
      header={text.title}
      icon="home"
      loading={loading}
      dataTestId="finalisedReport-page"
    >
      <JustificationContainer direction="column" gap="l">
        <TextAndColorInput
          text={{
            label: text.buttonMainText,
            value: contactButton?.mainText?.text || undefined,
            testId: 'text-edit-mainText-text',
          }}
          color={{
            label: text.buttonMainTextColor,
            value: contactButton?.mainText?.color || undefined,
            testId: 'picker_mainText-color',
          }}
          onUpdate={(value: Update) =>
            onUpdate({ contactButton: { mainText: value } })
          }
        />
        <TextAndColorInput
          text={{
            label: text.buttonSubText,
            value: contactButton?.subText?.text || undefined,
            testId: 'text-edit-subText-text',
          }}
          color={{
            label: text.buttonSubTextColor,
            value: contactButton?.subText?.color || undefined,
            testId: 'picker_subText-color',
          }}
          onUpdate={(value: Update) =>
            onUpdate({ contactButton: { subText: value } })
          }
        >
          <ColorInput
            value={contactButton?.backgroundColor || ''}
            onChange={color =>
              onUpdate({ contactButton: { backgroundColor: color } })
            }
            label={text.buttonBackgroundColor}
            dataTestId="picker_contactButton-backgroundColor"
          />
        </TextAndColorInput>

        <TextAndColorInput
          text={{
            label: text.contactButtonClickedText,
            value: contactButtonClickedText?.text || undefined,
            testId: 'text-edit-contactButtonClickedText-text',
          }}
          color={{
            label: text.contactButtonClickedTextColor,
            value: contactButtonClickedText?.color || undefined,
            testId: 'picker_contactButtonClickedText-color',
          }}
          onUpdate={(value: Update) =>
            onUpdate({ contactButtonClickedText: value })
          }
        />
        <TextAndColorInput
          text={{
            label: text.formSubText,
            value: formSubtext?.text || undefined,
            testId: 'text-edit-formSubtext-text',
          }}
          color={{
            label: text.formSubTextColor,
            value: formSubtext?.color || undefined,
            testId: 'picker_formSubtext-color',
          }}
          onUpdate={(value: Update) => onUpdate({ formSubtext: value })}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default FinalisedReport;
