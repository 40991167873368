import React from 'react';
import styled, { css } from 'styled-components';
import { useTransition, animated, UseTransitionProps } from 'react-spring';
import TEST_ID from '../../index.testid';
import { SizeMap } from '~/theme';
import { isNil } from 'ramda';
import { ANIMATION_CONFIG } from '~/styles/constants';

const MAX_CHAR_AMOUNT = 7;

type Props = {
  loaded: boolean;
  formattedTotalAmount: string;
  label: string;
};
const CenterLabel: React.FCC<Props> = ({
  loaded,
  formattedTotalAmount,
  label,
  dataTestId,
}) => {
  const transitionProps: UseTransitionProps = {
    from: ANIMATION_CONFIG.from,
    enter: ANIMATION_CONFIG.to,
    leave: ANIMATION_CONFIG.from,
    config: ANIMATION_CONFIG.config,
    trail: 100,
  };
  const transitions = useTransition(loaded, transitionProps);

  const fontSize = formattedTotalAmount.length > MAX_CHAR_AMOUNT ? 'xl' : 'xxl';

  return (
    <>
      {transitions(
        (style, item) =>
          !isNil(item) && (
            <foreignObject x={69} y={105} width="160" height="100">
              <animated.div style={style}>
                <TotalAmountContainer
                  data-testid={`${dataTestId}-${TEST_ID.CENTER_LABEL}`}
                  $fontSize={fontSize}
                >
                  {formattedTotalAmount}
                </TotalAmountContainer>
                <LabelContainer>{label}</LabelContainer>
              </animated.div>
            </foreignObject>
          ),
      )}
    </>
  );
};

const TotalAmountContainer = styled.div<{ $fontSize: keyof SizeMap }>(
  ({ theme, $fontSize }) => css`
    font-size: ${theme.fs($fontSize)};
    font-weight: ${theme.fw('semiBold')};
    text-align: center;
    overflow-wrap: break-word;
  `,
);

const LabelContainer = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('info', 'dark')};
    font-size: ${theme.fs('l')};
    font-weight: ${theme.fw('semiBold')};
    text-align: center;
  `,
);

export default CenterLabel;
