import React, { useEffect, useMemo } from 'react';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import useWizardStep from '~/hooks/useWizardStep';
import ChatComponent from './components/ChatComponent';
import Instructions from './components/Instructions';
import {
  firstVariantOfInstructions,
  secondVariantOfInstructions,
} from './instructionsText';

const text = {
  header: 'Volgende stappen',
  pageDescription:
    'Het inrichten van de Zapier koppeling is gelukt! Om je Zapier koppeling aan het werk te zetten, moet je nog een aantal stappen uitvoeren in zowel Zapier als in DatHuis. We leggen het kort uit.',
  firstInstructionHeader: 'Wil je contacten in Zapier verwerken?',
  secondInstructionHeader:
    'Wil je contacten vanuit Zapier in DatHuis ontvangen?',
  chatHeader:
    'Op zoek naar ideeen hoe je met Zapier jouw marketing een boost geeft?',
  chatLinkText: 'Start een chat met ons!',
  buttonLabel: 'Afronden',
  exitButtonLabel: 'Afbreken',
};

export const id = 'ZapierNextSteps';
export const title = text.header;

export type OutputType = {
  type: typeof id;
};

export const Component: React.FCC<WizardStepProps> = props => {
  const stepOptions = useMemo(
    () => ({ onBeforeNext: async () => ({ type: id }) as OutputType }),
    [],
  );

  const [, api] = useWizardStep(props.step.id, stepOptions);

  useEffect(() => {
    api.free({ type: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Body withoutMargin>{text.pageDescription}</Body>
      <Instructions
        header={text.firstInstructionHeader}
        instructions={firstVariantOfInstructions}
      />
      <Instructions
        header={text.secondInstructionHeader}
        instructions={secondVariantOfInstructions}
      />
      <ChatComponent header={text.chatHeader} linkText={text.chatLinkText} />
    </>
  );
};

export default {
  id,
  title,
};
