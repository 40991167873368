import React, { useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import Amplify from '~/amplify';
import Loading from '~/components/atom/Loading';
import useIntercom from '~/hooks/useIntercom';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { AuthContext } from '~/Root/Auth';

const Logout: React.FCC<RouteComponentProps> = () => {
  const client = useApolloClient();
  const intercom = useIntercom();
  const { ensureLogin } = useContext(AuthContext);

  useEffect(() => {
    const logout = async () => {
      await Amplify.Auth.signOut();
      await client.clearStore();

      intercom.logOff();
      ensureLogin();
    };

    void logout();
  }, [client, intercom, ensureLogin]);

  return <Loading />;
};

export default Logout;
