import React from 'react';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';

type Props = {
  /** Show edit buttons mode */
  editMode: boolean;

  /** Set name to use in data-testid of buttons */
  testName?: string;

  /** Function callback for save button click */
  handleSubmit: (e: React.SyntheticEvent<HTMLButtonElement>) => void;

  /** Edit mode callback toggler  */
  setEditMode: (setEdit: boolean) => void;

  /** large size of icons in buttons */
  large?: boolean;

  /** if the save command is loading */
  saveLoading?: boolean;
};

const UpdateFormButtons = (props: Props) => {
  const {
    editMode,
    handleSubmit,
    setEditMode,
    testName = 'name',
    saveLoading,
  } = props;

  return editMode ? (
    <JustificationContainer gap="xs">
      <Button
        ghost
        appearance="danger"
        size="medium"
        dataTestId={`editable-${testName}-cancel-update`}
        icon="close"
        onClick={() => setEditMode(false)}
      />
      <Button
        size="medium"
        appearance="secondary"
        icon="check"
        loading={saveLoading}
        dataTestId={`editable-${testName}-submit-update`}
        onClick={handleSubmit}
      />
    </JustificationContainer>
  ) : (
    <TextButton
      size="medium"
      withoutPadding
      appearance="default"
      onClick={() => setEditMode(true)}
      dataTestId={`editable-${testName}-editmode`}
      icon="pencil"
    />
  );
};

export default UpdateFormButtons;
