import React from 'react';
import styled from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Label, Variant } from '~/components/atom/Typography';
import hasValue from '~/util/hasValue';

export type Plain = Array<{
  label?: string;
  value: string | number;
}>;

export type Props = {
  dataTestId?: string;

  /** Values to display. Each on a new line */
  values: Plain;

  /** Category of the values */
  category: React.ReactNode;
};

const OutputPlain: React.FCC<Props> = ({ category, values = [] }) => {
  const cleanedValues = values.filter(value => hasValue(value.value));

  return (
    <>
      {cleanedValues.length > 0 && (
        <Container>
          <Heading4 variant={Variant.primary}>{category}</Heading4>

          <JustificationContainer width="100%" gap="xxs" direction="column">
            {cleanedValues.map(({ label, value }) => (
              <JustificationContainer key={value} gap="xxs" align="center">
                {hasValue(label) && (
                  <Label withoutMargin variant={Variant.primary}>
                    {label}:
                  </Label>
                )}
                <Body withoutMargin size={hasValue(label) ? 's' : 'm'}>
                  {value}
                </Body>
              </JustificationContainer>
            ))}
          </JustificationContainer>
        </Container>
      )}
    </>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

export default OutputPlain;
