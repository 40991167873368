import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Body } from '~/components/atom/Typography';

export type Props = {
  duration: number;
  postfix?: React.ReactNode;
  loop?: boolean;
};

const CountdownTimer: React.FCC<Props> = ({
  dataTestId,
  loop,
  duration,
  postfix,
  ...rest
}) => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(duration);

  useEffect(() => {
    const timer = setInterval(() => {
      const nextTime = remainingSeconds - 1;
      if (nextTime !== -1) {
        setRemainingSeconds(remainingSeconds - 1);
      } else if (loop === true) {
        setRemainingSeconds(duration);
      }
    }, 1000);

    return () => {
      if (timer != null) {
        clearInterval(timer);
      }
    };
  }, [duration, loop, remainingSeconds]);

  return (
    <Container data-testid={dataTestId} {...rest}>
      <CircleContainer
        viewBox="-50 -50 100 100"
        strokeWidth="10"
        width={22}
        // We need to add 1 sec to ensure the animation is in sync with the countdown
        $duration={duration + 1}
        $loop={loop}
      >
        <circle r="45" />
        <circle
          r="45"
          strokeDasharray="282.7433388230814"
          strokeDashoffset="282.7433388230814px"
        />
      </CircleContainer>
      <TimeLabel withoutMargin>
        {/* Width needs to be set to prevent reflow */}
        <AntiReflowContainer>{remainingSeconds}</AntiReflowContainer>
        {postfix && <>{postfix}</>}
      </TimeLabel>
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AntiReflowContainer = styled.span(
  ({ theme }) => css`
    width: 0.8rem;
    display: inline-block;
    margin-right: ${theme.space('xxs')};
  `,
);

const TimeLabel = styled(Body)(
  ({ theme }) => css`
    margin: 0 0 0 ${theme.space('xxs')};
  `,
);

const arc = keyframes`
  0% {
    stroke-dashoffset: 0px;
  }
`;

const CircleContainer = styled.svg<{ $duration: number; $loop?: boolean }>(
  ({ $duration, $loop = false, theme }) => css`
    grid-column: 1;
    grid-row: 1;

    [r] {
      fill: none;
      stroke: ${theme.color('grey', 'light')};
    }

    [r] + [r] {
      transform: rotate(-90deg);
      stroke-linecap: round;
      stroke: ${theme.color('primary', 'light')};
      animation: ${arc} ${$duration}s linear
        ${$loop === true ? 'infinite' : '1'};
    }
  `,
);

export default CountdownTimer;
