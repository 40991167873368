import { AppType } from '~/graphql/types';
import type { AppConfig } from '../types';

const description = `## Omschrijving
Via het Duurzaamheidsprofiel kunnen zowel de verkoper als koper zich oriënteren op de status van de woning. Wie tegenwoordig een woning koopt, kijkt niet alleen naar het prijskaartje. Ook het energielabel speelt een rol.

Met het Duurzaamheidsprofiel haal je een nieuwe krachtige leadgeneratie- en servicetool in huis. Met deze app kunnen bezoekers van jouw website gratis een online rapport opvragen met een overzicht hoe hun huis scoort in energieverbruik en hoe dit verbruik kan worden verbeterd. Het rapport bevat een overzicht van de te nemen maatregelen, kosten en besparingen. Alle Nederlandse woningen zijn opvraagbaar!

Het Duurzaamheidsprofiel wordt mede mogelijk gemaakt door o.a. de Nationale Hypotheekbond, ABN AMRO en MUNT Hypotheken.

## Installatie
Nadat je de app hebt geactiveerd, wordt het Duurzaamheidsprofiel automatisch getoond in de widget op je website. Daarnaast wordt automatisch de marketing automation gekoppeld.
`;

const termsOfUse = `## Aansprakelijkheid
Dutch Media Lab doet haar uiterste best om ervoor te zorgen dat de informatie op de websites geen fouten bevat. Helaas kunnen we dit niet garanderen. Beslissingen die bezoekers en derden nemen op basis van informatie op de websites zijn geheel voor eigen rekening en risico van de gebruiker of derden. Dutch Media Lab kan niet garanderen dat de websites foutloos of ononderbroken werken. Dutch Media Lab aanvaardt geen enkele aansprakelijkheid voor de inhoud van de websites en de daarop weergegeven informatie, noch voor de juistheid en/of volledigheid daarvan. Aan de gegevens op de websites en tools kunnen geen rechten ontleend worden.

## Geen advies
De of via deze internet aangeboden informatie is bedoeld voor algemene doeleinden en mag niet worden gebruikt als vervanging voor advies. Beslissingen op basis van deze informatie zijn voor uw eigen rekening en risico.

## Rechten
Dutch Media Lab, of de rechthebbende, behoudt alle rechten, waaronder auteurs- en merkrechten en/of andere intellectueel eigendomsrechten, met betrekking tot alles wat op de sites staat. Daaronder vallen onder meer teksten, vergelijkingen, tools, illustraties, grafisch materiaal, (handels)namen, logo’s en merken. Het is niet toegestaan om iets van de sites overnemen, te vermenigvuldigen, te verspreiden of op een andere manier ter beschikking stellen aan anderen, zonder uitdrukkelijke schriftelijke toestemming van Dutch Media Lab

## Wijzingen
Dutch Media Lab mag de informatie op de websites, met inbegrip van de tekst van de disclaimer, altijd wijzigen zonder dit van tevoren aan te kondigen.

## Links
De websites kunnen links en verwijzingen bevatten naar andere websites. Voor inhoud en uitstraling van deze websites kan Dutch Media Lab geen verantwoordelijkheid dragen.
`;

export const susReport: AppConfig = {
  __typename: 'AppStatus_SustainabilityReport',
  appType: AppType.SustainabilityReport,
  type: 'activate_only',
  slug: 'sus-report',
  name: 'Duurzaamheidsprofiel',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Informatievoorziening'],
  provider: 'Dutch Media Lab',
  logo: {
    src: 'https://dhstash.s3.eu-west-1.amazonaws.com/duurzaamheidsprofiel-logo.png',
  },
  image: {
    src: 'https://dhstash.s3.eu-west-1.amazonaws.com/duurzaamheidsprofiel-logo.png',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/7231377-over-de-duurzaamheidsprofiel-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
