import React from 'react';
import { FormData } from './CompanyDetails';
import { Form, Field } from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import Validation from '~/util/Validation';
import { Body, Heading1 } from '~/components/atom/Typography';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import Input from '~/components/bad/Inputs/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {
  handleSubmit: (fields: FormData) => void;
  validate: (fields: FormData) => Object;
  submitError: string | false;
  loading: boolean;
  initialValues?: FormData;
};
const text = {
  title: 'Account inrichten',
  companyLabel: 'Kantoornaam',
  saveLabel: 'Opslaan',
};

const CompanyDetailsForm: React.FCC<Props> = ({
  handleSubmit,
  validate,
  submitError,
  loading,
  initialValues,
}) => (
  <Form
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validate={validate}
  >
    {({ handleSubmit, submitting, pristine }) => {
      const formSubmitting = loading || submitting;
      const initialForm =
        pristine &&
        (!initialValues || Validation.String.isEmpty(initialValues.company));

      return (
        <form onSubmit={handleSubmit} data-testid="step1-form">
          <Heading1 color={{ group: 'primary' }} size="xxxl">
            {text.title}
          </Heading1>

          {submitError ? (
            <Body
              size="base"
              withoutMargin
              color={{ group: 'danger', variant: 'light' }}
            >
              {submitError}
            </Body>
          ) : null}

          <InputGroup>
            <Field name="company">
              {({ input, meta: { error, touched } }) => (
                <Input
                  large
                  label={text.companyLabel}
                  type="text"
                  error={FormUtils.showError(error, touched)}
                  disabled={formSubmitting}
                  {...input}
                />
              )}
            </Field>
          </InputGroup>

          <JustificationContainer align="center" justification="end">
            <Button
              size="large"
              appearance="secondary"
              type="submit"
              disabled={formSubmitting || initialForm}
              data-testid="save-company-details-button"
              label={text.saveLabel}
            />
          </JustificationContainer>
        </form>
      );
    }}
  </Form>
);

export default CompanyDetailsForm;
