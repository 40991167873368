import React from 'react';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import withErrorBoundary from '~/ErrorBoundary';
import ErrorScreen from '~/components/page/ErrorScreen';
import Catalog from '~/Catalog';
import { WithNavigationFrame as NotFound } from '~/components/page/404';
import FlowList from './FlowList';
import FlowBuilderV2 from '../v2/index';
import DHRouter from '~/components/atom/DHRouter';
import withPermissionCheck from '~/hocs/withPermissionCheck';

type Props = RouteComponentProps;
const Flows: React.FC<Props> = () => (
  <DHRouter>
    <FlowList path="/" />
    <FlowBuilderV2.Create path="/builder-v2/create/:id" />
    <FlowBuilderV2.Update path="/builder-v2/update/:id" />
    <NotFound default />
  </DHRouter>
);

export default withPermissionCheck(
  withErrorBoundary<Props>(
    Flows,
    <ErrorScreen explanation={Catalog.genericUnknownErrorMessage} />,
  ),
  ['root.automation'],
);
