import type { RefObject } from 'react';
import { useEffect, useCallback } from 'react';
import isCursorInBoundary from '~/util/isCursorInBoundary';

/**
 * Checks whether a click registered outside the element
 *
 * keywords: click,outside,element
 */
const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => any,
) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(e.target as Node)) {
        if (
          isCursorInBoundary({
            boundary: ref.current.getBoundingClientRect(),
            event: e,
          })
        )
          return;
        callback();
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return handleClick;
};

export default useOutsideClick;
