import React from 'react';
import DHRouter from '~/components/atom/DHRouter';

import withErrorBoundary from '~/ErrorBoundary';
import { RouteComponentProps, useLocation } from '@gatsbyjs/reach-router';
import SaveBarBuster from '~/components/bad/SaveBarBuster';
import NotFound from '~/components/page/404';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import useApp from '~/hooks/useApp';
import ValueReportDetails from './components/ValueReportDetails';
import ValueReportList from './components/ValueReportList';

type Props = RouteComponentProps;

export const WAARDERAPPORT_URL_PREFIX = 'www.mijnwaarderapport.nl/c/';

const ValueReport: React.FCC<Props> = withErrorBoundary<Props>(() => {
  const location = useLocation();
  const { app, loading } = useApp('AppStatus_ValuationReport');

  if (loading) return <DatHuisLoading />;
  if (!app?.appStatus.enabled) return <NotFound />;

  return (
    <>
      <DHRouter>
        <ValueReportList path="/" location={location} />
        <ValueReportDetails path="/:reportId/*" location={location} />
      </DHRouter>
      <SaveBarBuster />
    </>
  );
});

export default ValueReport;
