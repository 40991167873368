/**
 * Collection of utility functions in order to facilitate the typescript conversion.
 *
 * In our codebase we ( had ) the concept of different users ( ViewableUser, ViewableAccountUser etc. )
 * We want to move towards only using immutable types coming from the graphql endpoint.
 *
 * In this package we hold some of the convenience methods to allow for previously possible functionality
 * without mutating the objects.
 */

import { SessionHydrationUserFieldsFragment } from '~/graphql/types';

export const getUserName = (
  user?: SessionHydrationUserFieldsFragment | null,
): string => {
  if (user == null) return `Onbekend`;

  switch (user.__typename) {
    case 'PendingUser':
      return user.email;
    case 'User':
      return user.name;
  }
};
