import React from 'react';

import Checkbox from '~/components/bad/Inputs/Checkbox';
import ColorPicker from '~/components/molecule/ColorPicker';
import { isNil } from 'ramda';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label, Variant } from '~/components/atom/Typography';

const DEFAULT_COLOR = '#fff';
const text = {
  transparent: 'Transparant',
};

type Props = {
  color: string | null | undefined;

  /** Field name */
  title: string;

  /** Name of the field you want to edit */
  name: string;

  /** Test name for the color picker */
  testName: string;

  /** Callback function which call data updating in container state */
  onChange: (value: string | null) => void;
};

const ColorPickerWithTransparentOption = ({
  color: initialColor,
  title,
  name,
  testName,
  onChange,
}: Props) => (
  <JustificationContainer
    direction="column"
    data-testid="transparent-color-picker-container"
  >
    <Label variant={Variant.primary}>{title}</Label>

    <JustificationContainer gap="base">
      <ColorPicker
        initialColor={initialColor || DEFAULT_COLOR}
        onChange={color => {
          if (initialColor === null) return;
          onChange(color);
        }}
        disabled={initialColor === null}
        dataTestId={`picker_${testName}-${name}`}
      />
      <Checkbox
        value={isNil(initialColor)}
        onChange={() => {
          onChange(initialColor !== null ? null : DEFAULT_COLOR);
        }}
        label={text.transparent}
        name="transparentCheckbox"
        dataTestId="transparent-checkbox"
      />
    </JustificationContainer>
  </JustificationContainer>
);

export default ColorPickerWithTransparentOption;
