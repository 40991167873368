import React from 'react';
import styled, { css } from 'styled-components';
import { SystemColorPalette } from '~/theme';
import { Appearance, Direction, outline } from '~/components/atom/Button';
import { SystemSize } from '~/theme/System/tokens';
import { AnimatedComponent } from 'react-spring';
import Icon, { IconType } from '~/components/atom/Icon';
import { isNil } from 'ramda';
import arrayToCss from '~/util/arrayToCss';
import { SystemFontWeight } from '~/theme';
import type { ComponentSizes, Size } from '~/styles/constants';
import { componentAppearances, componentSizes } from '~/styles/constants';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

type Width = 'max-content' | 'auto';

const textButtonSizes: ComponentSizes = {
  ...componentSizes,
  large: {
    fontSize: 'm',
    padding: ['m', 'm'],
  },
};

export type Props = {
  label?: React.ReactNode;
  icon?: IconType;
  margin?: Array<SystemSize | null>;
  padding?: Array<SystemSize | null>;
  /* Changes the order of the icon and the label if both provided */
  direction?: 'ltr' | 'rtl';
  appearance?: Appearance | 'default';
  size?: Size;
  withoutPadding?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
  as?: AnimatedComponent<any>;
  width?: Width;

  /* Replaces the icon for our loading icon and forces the disabled state as well */
  loading?: boolean;
} & React.HtmlHTMLAttributes<HTMLButtonElement>;

const TextButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      dataTestId,
      label,
      appearance = 'primary',
      size = 'small',
      withoutPadding = false,
      type = 'button',
      direction = 'ltr',
      margin = [null, null, null, null],
      padding,
      icon,
      width = 'max-content',
      loading,
      disabled,
      ...rest
    },
    ref,
  ) => (
    <Button
      data-testid={dataTestId}
      $appearance={appearance}
      $icon={icon}
      $size={size}
      $withoutPadding={withoutPadding}
      $margin={margin}
      $padding={padding}
      type={type}
      ref={ref}
      $width={width}
      disabled={disabled || loading}
      {...rest}
    >
      {icon ? (
        <TextButtonWithIconContainer>
          <IconContainer $direction={direction} $addMargin={!isNil(label)}>
            <Icon name={loading ? 'spinner' : icon} strokeWidth={2.5} />
          </IconContainer>
          <LabelWrapper $fontWeight="semiBold">{label}</LabelWrapper>
        </TextButtonWithIconContainer>
      ) : (
        <LabelWrapper>{label}</LabelWrapper>
      )}
    </Button>
  ),
);

const TextButtonWithIconContainer = styled.div<{}>(
  ({}) => css`
    display: flex;
    align-items: center;
  `,
);
const LabelWrapper = styled.span<{
  $fontWeight?: SystemFontWeight;
}>(
  ({ theme, $fontWeight }) => css`
    font-weight: ${theme.fw($fontWeight || 'regular')};
  `,
);

const appearanceMapping: {
  [key in Appearance | 'default']: ThemeColor;
} = {
  ...componentAppearances,
  default: {
    group: 'text',
    variant: 'light',
  },
};

const Button = styled.button<{
  $appearance: keyof SystemColorPalette;
  $size: Size;
  $icon?: IconType;
  $withoutPadding: boolean;
  $padding: Array<SystemSize | null>;
  $margin: Array<SystemSize | null>;
  disabled: boolean;
  $width: Width;
}>(
  ({
    theme,
    $appearance,
    $icon,
    $size,
    $withoutPadding,
    $margin,
    $padding,
    $width,
    disabled,
  }) => {
    const { group, variant } = appearanceMapping[$appearance];
    const color = theme.color(group, variant);

    return css`
      border: none;
      background: none;
      text-align: left;

      display: flex;
      align-items: center;
      width: ${$width};

      /* Only add margin if provided */
      ${$margin.filter(m => !isNil(m)).length !== 0
        ? `margin: ${arrayToCss($margin, theme)};`
        : ''}

      text-decoration: ${$icon != null ? 'none' : 'underline'};
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      color: ${disabled ? theme.color('grey') : color};

      font-size: ${$size === 'small'
        ? theme.fontSize('s')
        : theme.fontSize(textButtonSizes[$size].fontSize)};

      border-radius: ${theme.getTokens().border.radius.s};
      padding: ${$withoutPadding
        ? '0'
        : $padding
          ? arrayToCss($padding, theme)
          : textButtonSizes[$size].padding
              .map(size => theme.space(size))
              .join(' ')};

      margin: ${arrayToCss($margin, theme)};

      &:active,
      &:hover {
        color: ${theme.color(group, 'dark')};
        background-color: ${!$withoutPadding
          ? theme.color(group, 'translucent')
          : 'none'};
        transition: all 0.3s ease;
        ${outline(color)}
      }

      &:disabled {
        pointer-events: none;
      }
    `;
  },
);

const IconContainer = styled.div<{
  $direction: Direction;
  $addMargin: boolean;
}>(
  ({ $direction, $addMargin, theme }) => css`
    /* Scale the icon to the font-size of the button */
    /*font-size: ${theme.fs('s')};*/
    margin: ${$addMargin &&
    ($direction === 'ltr'
      ? [0, theme.space('xxs'), 0, 0]
      : [0, 0, 0, theme.space('xxs')]
    ).join(' ')};
    display: flex;

    & svg {
      transform: translateY(-1px);
    }
  `,
);

export default TextButton;
