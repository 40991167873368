import React from 'react';
import styled, { css } from 'styled-components';
import { StrapiLinkBlock } from '~/graphql/types';
import StrapiLink from '~/components/organism/StrapiLink';
import Icon from '~/components/atom/Icon';
import { animated, useSpring } from 'react-spring';

export type Props = Omit<StrapiLinkBlock, '__typename'> & {
  dataTestId?: string;
};

const CUSTOM_CONFIG = { mass: 10, tension: 120, friction: 80, clamp: true };

const StrapiLinkBlock: React.FC<Props> = ({ strapi, css }) => {
  const [shine, setShine] = React.useState(true);

  const shineProps = useSpring({
    from: { backgroundPosition: '100% center' },
    to: { backgroundPosition: '-250% center' },
    config: CUSTOM_CONFIG,
    reset: true,
    reverse: !shine,
    onRest: ({ finished }) => {
      if (finished) setShine(prev => !prev);
    },
    loop: true,
  });

  const pulseStyle = useSpring({
    to: [
      { transform: 'scale(1.5)', opacity: 1 },
      { transform: 'scale(1.1)', opacity: 0.9 },
    ],
    from: { transform: 'scale(1)', opacity: 0.8 },
    config: { ...CUSTOM_CONFIG, mass: 1, friction: 40, clamp: false },
    loop: true,
  });

  return (
    <MainContainer>
      <Container style={shineProps} css={css}>
        <Icon
          name="star"
          strokeWidth={2.5}
          $color="gold"
          as={animated.span}
          // @ts-ignore style is in react-spring shape
          style={pulseStyle}
          margin={[null, 's', null, null]}
        />

        <StrapiLink
          label={strapi.label}
          reason={{ __typename: 'Reason_Strapi', id: strapi.id }}
          color="white"
        />
      </Container>
    </MainContainer>
  );
};

const MainContainer = styled.div(
  () => css`
    z-index: 1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  `,
);

const Container = styled(animated.div)(
  ({ theme }) => css`
    margin: ${theme.space('xxxs')} 0;
    position: relative;
    display: flex;
    background-color: ${theme.color('primary')};
    color: ${theme.color('white')};
    padding: ${theme.space('m')};
    border-radius: ${theme.getTokens().border.radius.s};

    background-image: linear-gradient(
      110deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.1) 40%,
      rgba(255, 255, 255, 0.1) 60%,
      rgba(255, 255, 255, 0.3) 100%
    );
    background-size: 150% 100%;
    overflow: hidden;
  `,
);

export default StrapiLinkBlock;
