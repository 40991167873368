import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Button, { Appearance } from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import { Size } from '~/styles/constants';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import type { Option } from '~/components/molecule/Dropdown';

export type DropdownActionOption = {
  label: string;
  onClickAction: (arg0?: any) => void;
  type?: Option['type'];
};

export type Props = {
  /** Options for dropdown with actions handler */
  dropdownOptions: Array<DropdownActionOption>;
  mainButtonOption: DropdownActionOption;

  /** Size of the button: 'small', 'medium', 'large' */
  size?: Size;

  /** Color of the button */
  appearance?: Appearance;

  /** If button is loading, shows the spinner in only main button */
  loading?: boolean;

  /** Disable dropdown and main button */
  disabled?: boolean;
};

type DropdownHandler = {
  selectedOptionIdx: number;
};

const CaretDropdownButton: React.FCC<Props> = ({
  dataTestId,
  size = 'medium',
  loading = false,
  mainButtonOption,
  dropdownOptions,
  appearance = 'secondary',
  disabled,
  ...rest
}) => {
  const [dropdownListOpen, setDropdownListOpen] = useState<boolean>(false);

  const convertedOptions = dropdownOptions.map(
    (option: DropdownActionOption): Option => ({
      ...option,
      payload: null,
      key: `${option.label}-key`,
    }),
  );

  const onOptionClick = ({ selectedOptionIdx }: DropdownHandler) => {
    const selectedDropdownOption = dropdownOptions[selectedOptionIdx];

    if (selectedDropdownOption) {
      selectedDropdownOption.onClickAction();
    }
    setDropdownListOpen(false);
  };

  return (
    <Container data-testid={dataTestId} {...rest}>
      <ButtonWrapper>
        <MainButton
          dataTestId="caret-main-button"
          label={mainButtonOption.label}
          onClick={mainButtonOption.onClickAction}
          appearance={appearance}
          loading={loading}
          disabled={disabled}
          size={size}
        />

        <ChevronButton
          dataTestId="caret-toggler-button"
          onClick={e => {
            e.preventDefault();
            setDropdownListOpen(!dropdownListOpen);
          }}
          appearance={appearance}
          size={size}
          disabled={disabled || loading}
          label={
            <Icon name="chevron" flipX={dropdownListOpen} strokeWidth={3} />
          }
        />
      </ButtonWrapper>

      <DropdownListContainer
        options={convertedOptions}
        dropdownListOpen={dropdownListOpen}
        onChange={onOptionClick}
        onClickOutside={() => setDropdownListOpen(false)}
        onClose={() => setDropdownListOpen(false)}
      />
    </Container>
  );
};

const Container = styled.div<{}>``;

const ButtonWrapper = styled.div<{}>`
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const MainButton = styled(Button)<{}>`
  margin-right: 1px;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s} 0 0
      ${theme.getTokens().border.radius.s};
  `};
`;

const ChevronButton = styled(Button)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    border-radius: 0 ${theme.getTokens().border.radius.s}
      ${theme.getTokens().border.radius.s} 0;
    cursor: ${disabled ? 'auto' : 'pointer'};
  `};

  &:focus {
    box-shadow: none;
  }
`;

export default CaretDropdownButton;
