import React, { useState } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import { FormBuilder_Event_Field } from '~/graphql/types';
import PointerSelector from '../PointerSelector';
import PointerRenderer from '../PointerRenderer';
import { issuesByPath } from '~/components/page/Forms/components/Builder/state/issues';
import { useRecoilValue } from 'recoil';
import { isNil } from 'ramda';
import { Label } from '~/components/atom/Typography';
import styled, { css } from 'styled-components';

const text = {
  valueLabel: 'Vul waarde in uit scherm',
  fieldLabel: 'Veld op event',
};
type Props = {
  field: FormBuilder_Event_Field;
  eventId: string;
  onSelect: (pointer: [string, string]) => void;
  pointer?: Array<string>;
  nodeId: string;
};

const FieldMapperRow: React.FCC<Props> = ({
  field,
  eventId,
  onSelect,
  pointer,
  nodeId,
}) => {
  const [showSelector, setShowSelector] = useState(false);
  const issues = useRecoilValue(issuesByPath([nodeId, field.key]));

  const issueMap = issues.reduce((acc, curr) => {
    if (curr && curr.blockKey && !isNil(curr.message)) {
      // field.key
      acc[curr?.blockKey] = curr.message;
    }

    return acc;
  }, {});

  return (
    <JustificationContainer align="center">
      <div
        style={{
          display: 'flex',
          width: '100%',
          position: 'relative',
          flexDirection: 'column',
        }}
      >
        <Label>{field.name}</Label>

        {pointer ? (
          <PointerRenderer
            pointer={pointer}
            onFocus={() => setShowSelector(true)}
          />
        ) : (
          <StyledInput
            externalErrors={issueMap[field.key] ? [issueMap[field.key]] : []}
            type="text"
            width="100%"
            placeholder={text.valueLabel}
            onFocus={() => setShowSelector(true)}
            readOnly
          />
        )}
        {showSelector && (
          <PointerSelector
            eventId={eventId}
            field={field}
            onSelect={onSelect}
            onClose={() => {
              setShowSelector(false);
            }}
          />
        )}
      </div>
    </JustificationContainer>
  );
};

const StyledInput = styled(Input)(
  ({ theme }) => css`
    cursor: pointer;
    margin-bottom: ${theme.space('base')};
    width: 300px;
    max-width: 100%;
    text-overflow: ellipsis;

    &:hover {
      border-color: ${theme.color('primary', 'light')};

      & > input {
        cursor: pointer;
      }
    }
  `,
);

export default FieldMapperRow;
