import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import type { InputHTMLAttributes } from 'react';
import { componentSizes } from '~/styles/constants';
import type { Size } from '~/styles/constants';

export type BaseInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'size' | 'value' | 'defaultValue'
> & {
  size?: Size;
  value?: string | number | null;
  defaultValue?: string | number | null;
  /**
   * Enables listening for key events on focused inputs.
   *
   * @see src/hooks/useKeybinding/index.ts
   */
  allowKeybinding?: boolean;
};

const InputElement = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      dataTestId,
      allowKeybinding = false,
      size = 'medium',
      value,
      defaultValue,
      ...rest
    },
    ref,
  ) => (
    <Input
      ref={ref}
      className={allowKeybinding ? 'mousetrap' : undefined}
      $size={size}
      data-testid={dataTestId}
      role="input"
      value={value ?? undefined}
      defaultValue={defaultValue ?? undefined}
      {...rest}
    />
  ),
);

const Input = styled.input<BaseInputProps & { $size: Size }>(
  ({ $size, theme }) => css`
    border: none;
    width: 100%;
    padding: 0;
    height: ${theme.fontSize(componentSizes[$size].fontSize)};

    line-height: ${theme.lineHeight('s')};
    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};

    color: ${theme.color('text')};

    &::placeholder {
      color: ${theme.color('grey', 'dark')};
    }

    &:focus::placeholder {
      color: transparent;
    }

    /* stylelint-disable property-no-vendor-prefix */
    /* Removes arrows from number input elements on Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    /* Removes arrows from number input elements on Chrome and Safari */
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    /* stylelint-enable property-no-vendor-prefix */
  `,
);

export default InputElement;
