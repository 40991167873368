import { keys, sort } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import Group from './components/Group';
import groupNameToAnchorId from '../SelectableAccordion/utils/groupNameToAnchorId';

export type AccordionGroup<T extends any = {}> = {
  id: string;
  name: string;
  checked: boolean;
  __typename?: string;
} & T;

type GroupType<T> = {
  items: Array<AccordionGroup<T>> | null;
  mainChecked: boolean;
  name: string;
  weight: number;
  appConfiguration?: string;
};

export type GroupLookup<T extends any = {}> = {
  [groupId: string]: GroupType<T>;
};

export type Props = {
  groupLookup: GroupLookup<any>;
  selectable?: boolean;
  onChange: (args: {
    groupId: string;
    groupLookup: {
      items: Array<AccordionGroup<any>> | null;
      mainChecked: boolean;
    };
  }) => void;
};

const ToggleAccordion: React.FCC<Props> = ({
  dataTestId,
  groupLookup,
  onChange,
  selectable = true,
  ...rest
}) => {
  const groupIds = keys(groupLookup);

  const sortedGroups = sort(
    (a, b) => groupLookup[a].weight - groupLookup[b].weight,
    groupIds,
  );

  return (
    <Container data-testid={dataTestId} {...rest}>
      {sortedGroups.map((id: string) => {
        if (!groupLookup[id]) return null;

        const { items, name, mainChecked } = groupLookup[id] as GroupType<any>;

        return (
          <Group
            id={id}
            anchorId={groupNameToAnchorId(name)}
            key={id}
            items={items}
            checked={mainChecked}
            name={name}
            onChange={update => {
              onChange({
                groupId: id,
                groupLookup: {
                  ...groupLookup[id],
                  ...update,
                },
              });
            }}
            selectable={selectable}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default ToggleAccordion;
