import { HeadingFiveElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the OrderedListElement into html element
 * @param {HeadingFiveElement} node - HeadingFiveElement
 */
const serialize = (node: HeadingFiveElement, children: string): string =>
  `<h5>${children}</h5>`;

export default serialize;
