import React, { useState } from 'react';
import Button from '~/components/atom/Button';
import CopyCode from '~/components/atom/CopyCode';
import JustificationContainer from '~/components/atom/JustificationContainer';
import FormContainer from '~/components/organism/ModalsV2/FormContainer';
import { withOverlay } from '~/components/organism/ModalsV2/Overlay';
import Screenshot from '~/components/molecule/Screenshot';
import TextButton from '~/components/atom/TextButton';
import {
  RealworksTokenType,
  useUpdateRealworksAppStatusMutation,
} from '~/graphql/types';
import useAddToast from '~/hooks/useAddToast';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import isDuplicatedToken from '~/components/page/Apps/Realworks/utils/isDuplicatedToken';
import formatToastMessage from '~/util/formatToastMessage';
import { text as activationStepText } from '../../../AddTokenContainerWizard/components/ActivationPendingStep';
import { exportContactsScreenshotLink } from '../../../AddTokenContainerWizard/components/ExportContactsStep';
import { importContactsScreenshotLink } from '../../../AddTokenContainerWizard/components/ImportContactsStep';
import TEST_ID from './index.testid';
import { Variant, Label } from '~/components/atom/Typography';
import InputWithIndicator from '~/components/molecule/InputWithIndicator';

const text = {
  ...activationStepText,
  copyCodeLabel: '1. Plak ons Developer Id in Realworks',
  code: 'eb92a256-b27d-4747-80c4-4d4b45485cc8',
};

export type Props = {
  containerId: string;
  tokenType: RealworksTokenType;
  onClose?: () => void;
};

const AddTokenModal: React.FCC<Props> = ({
  onClose,
  containerId,
  tokenType,
  ...rest
}) => {
  const addToast = useAddToast();
  const [token, setToken] = useState('');
  const { id: accountId } = useCurrentAccount();
  const [updateRealworksAppStatus] = useUpdateRealworksAppStatusMutation({});

  const screenshotSource =
    tokenType === RealworksTokenType.Wonen
      ? exportContactsScreenshotLink
      : importContactsScreenshotLink;

  return (
    <FormContainer header="Token toevoegen" onClose={onClose}>
      <form
        data-testid={TEST_ID.FORM}
        {...rest}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();

          return updateRealworksAppStatus({
            variables: {
              accountId,
              addToken: {
                tokenContainerId: containerId,
                type: tokenType,
                token,
              },
            },
          }).then(({ errors }) => {
            if (errors && errors.length !== 0) {
              if (isDuplicatedToken(errors)) {
                addToast([
                  formatToastMessage(text.duplicateErrorMessage, 'danger'),
                ]);
                return;
              } else {
                addToast([
                  formatToastMessage(text.toastErrorMessage, 'danger'),
                ]);
                return;
              }
            }
            return onClose && onClose();
          });
        }}
      >
        <JustificationContainer
          justification="start"
          margin={['base', null, null, null]}
        >
          <CopyCode
            label={
              <Label
                withoutMargin
                fontWeight="semiBold"
                variant={Variant.primary}
                size="m"
              >
                {text.copyCodeLabel}
              </Label>
            }
            code={text.code}
            style={{ width: '100%' }}
          />
        </JustificationContainer>
        <JustificationContainer justification="center" margin={['base', null]}>
          <Screenshot src={screenshotSource} alt="screenshot" maxWidth={600} />
        </JustificationContainer>
        <AppEntryItemHeader>
          2. Kopieer de “Token” en plak deze in dit veld
        </AppEntryItemHeader>
        <InputWithIndicator
          appearance="primary"
          validation={[value => (value === '' ? 'Vul een waarde in' : true)]}
          value={token}
          onChange={e => setToken(e.target.value)}
          dataTestId={TEST_ID.TOKEN_INPUT}
        />
        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label="Afbreken"
            onClick={onClose}
            withoutPadding
            appearance="danger"
            type="button"
          />
          <Button
            icon="check"
            label="Opslaan"
            type="submit"
            dataTestId={TEST_ID.SUBMIT}
          />
        </JustificationContainer>
      </form>
    </FormContainer>
  );
};

export default withOverlay(AddTokenModal);
