import React from 'react';
import { useTransition, type UseTransitionProps } from 'react-spring';
import JustificationContainer from '~/components/atom/JustificationContainer';
import DeletableItem from '../DeletableItem';
import type { Appearance, Size } from '~/styles/constants';

export type Props = {
  appearance?: Appearance;
  size?: Size;
  selectedValues: Array<string>;
  onRemoved: (item: string, index: number) => void;
};

/**
 * Used when selected options are strings
 */
const DeletableItems: React.FCC<Props> = ({
  selectedValues,
  size = 'medium',
  appearance = 'primary',
  onRemoved,
  ...rest
}) => {
  const transitionProps: UseTransitionProps = {
    config: { mass: 1, tension: 200, friction: 20 },
    from: { opacity: 0, transform: 'translateX(-10px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(-10px)' },
    keys: item => item,
  };

  const transitions = useTransition(selectedValues, transitionProps);

  return (
    <JustificationContainer
      wrap="wrap"
      gap="xxs"
      padding={[null, null, 'xxs', null]}
      {...rest}
    >
      {transitions((style, item, { key }, index) => {
        if (item)
          return (
            <DeletableItem
              key={key}
              onDelete={() => onRemoved(item, index)}
              size={size}
              label={item}
              appearance={appearance}
              style={style}
            />
          );
        return null;
      })}
    </JustificationContainer>
  );
};

export default DeletableItems;
