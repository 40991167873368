import React from 'react';
import styled, { css } from 'styled-components';
import { sort } from 'ramda';

import { LINK_PREFIX } from '../../';
import TemplateDetails from './components/TemplateDetails';
import groupByCategory from './utils/groupByCategory';

import MasterDetail from '~/components/template/MasterDetail';

import formTemplates, {
  MOST_USED_CATEGORY_LABEL,
  MOST_USED_CATEGORY_SLUG,
  type FormTemplate,
} from '~/components/page/Forms/formTemplates';

export type FormTemplatesCategory = {
  [key: string]: {
    name: string;
    slug: string;
    templates: Array<FormTemplate>;
  };
};

export type Props = {
  onSelect: (template: FormTemplate | null) => void;
  template?: FormTemplate | null;
};

export const EMPTY_TEMPLATE_ID = 'empty-form-template';

const FormTemplates: React.FCC<Props> = ({ onSelect, template }) => {
  const groupedTemplates: FormTemplatesCategory =
    groupByCategory(formTemplates);

  const categories = Object.keys(groupedTemplates);
  const sortedCategories = sort((a, b) => a.localeCompare(b), categories);

  return (
    <Container>
      <StyledMasterDetail
        basePath={LINK_PREFIX}
        navbar={[
          {
            type: 'link',
            // To select the first group by default
            to: `/`,
            name: MOST_USED_CATEGORY_LABEL,
          },
          ...sortedCategories
            .filter(slug => slug !== MOST_USED_CATEGORY_SLUG)
            .map(slug => ({
              type: 'link' as const,
              // To select the first group by default
              to: `/${slug}`,
              name: groupedTemplates[slug].name,
            })),
        ]}
      >
        <TemplateDetails
          default
          path={`/${EMPTY_TEMPLATE_ID}`}
          formCategories={groupedTemplates}
          onSelect={onSelect}
          templateId={template?.id ?? null}
        />
        <TemplateDetails
          path=":slug"
          formCategories={groupedTemplates}
          onSelect={onSelect}
          templateId={template?.id ?? null}
        />
      </StyledMasterDetail>
    </Container>
  );
};

const StyledMasterDetail = styled(MasterDetail)(
  ({ theme }) => css`
    min-width: 200px;
    width: 100%;

    border-right: 1px solid ${theme.color('grey')};
    margin-right: ${theme.space('l')};
  `,
);

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.space('l')} 0;
  `,
);

export default FormTemplates;
