import { clone } from 'ramda';
import type { Element as SlateElement, Text as SlateText } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';

type ProcessedNode =
  | {
      type: string;
      [key: string]: any;
      children?: Array<ProcessedNode>;
    }
  | {
      text: string;
    };

export type ProcessedNodes = Array<ProcessedNode>;

function isProcessedNodeWithType(node: ProcessedNode): node is ProcessedNode & {
  type: string;
  [key: string]: any;
  children?: Array<ProcessedNode>;
} {
  return 'type' in node;
}

/**
 * Converts the elements in the remark-slate format to our way of defining them
 *
 * @param {string} nodes - Nodes created by remark-slate
 */
const convertElements = (
  nodes: ProcessedNodes,
): Array<SlateElement | SlateText> => {
  const newObj = clone(nodes);

  const update = (obj?: ProcessedNode | ProcessedNodes) => {
    if (Array.isArray(obj)) {
      obj.forEach(update);
    } else if (typeof obj === 'object' && obj !== null) {
      if (isProcessedNodeWithType(obj)) {
        switch (obj.type) {
          case 'paragraph':
            obj.type = ELEMENTS.DIV;
            break;
          case 'block_quote':
            obj.type = ELEMENTS.BLOCKQUOTE;
            break;
          case 'link':
            obj.type = ELEMENTS.LINK;
            const url = obj.link;
            // we do not use this property so we can delete it
            delete obj.link;
            obj.url = url;
            break;
          case 'heading_one':
            obj.type = ELEMENTS.H1;
            break;
          case 'heading_two':
            obj.type = ELEMENTS.H2;
            break;
          case 'heading_three':
            obj.type = ELEMENTS.H3;
            break;
          case 'heading_four':
            obj.type = ELEMENTS.H4;
            break;
          case 'heading_five':
            obj.type = ELEMENTS.H5;
            break;
          case 'heading_six':
            obj.type = ELEMENTS.H6;
            break;
          default:
            break;
        }
      }
      if (isProcessedNodeWithType(obj) && obj.hasOwnProperty('children')) {
        update(obj.children);
      }
    }
  };

  update(newObj);

  return newObj as Array<SlateElement | SlateText>;
};

export default convertElements;
