import React, { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import {
  DeleteEntityErrorReason,
  TransferResourcesChangeSet,
  ReassignEntityInput,
  UserRole,
} from '~/graphql/types';
import type { DeletionType } from '~/components/bad/Modals/util/constants';

import cleanedFilename from '~/util/cleanedFilename';

import Modal from './Modal';
import BottomButtonRow from './BottomButtonRow';
import TopSectionContainer from './components/TopSectionContainer';
import ListItemCard, {
  ListItemForDeletion,
  MigrationItem,
} from './components/ListItemCard';
import Button from '~/components/atom/Button';
import Catalog from '~/Catalog';
import { convertBackendErrorMessage, getErrorMessageForModal } from './util';
import TEST_ID from './DeleteEntityModal.testid';
import {
  ModalContainer,
  ListContainer,
  SubHeaderContainer,
  FromContainer,
  ToContainer,
  ErrorMessageContainer,
} from './components/deleteModal.style';
import { DELETE_ENTITY_TYPE } from '~/components/bad/Modals/util/constants';
import useOffice from '~/hooks/useOffice';
import useUserLookup from '~/hooks/useUserLookup';
import getUserName from '~/util/getUserName';
import { Link } from '~/components/molecule/Link';
import useRelationshipForRole from '~/hooks/useRelationshipForRole';
import hasValidOffice from './util/hasValidOffice';
import hasValidUser from './util/hasValidUser';
import { NOT_ASSIGNED_TO_USER_ID } from '~/components/page/Settings/Offices/components/OfficeDetails/constants';
import useOfficeRelationsForUsers from '~/hooks/useOfficeRelationsForUsers';
import { values } from 'ramda';

const text = {
  cancelButtonLabel: 'Annuleren',
  successButtonLabel: 'Toewijzen en verwijderen',
  description:
    'Wijs contacten en openstaande taken toe aan een andere gebruiker',
  mutationError:
    'Er is iets mis gegaan bij het toewijzen van contacten en openstaande taken. Probeer het later nog eens.',
  isLastAccountAdminError: (
    <>
      Gebruiker kan niet verwijderd worden omdat hij of zij de laatste
      accountbeheerder is. Ga naar&nbsp;
      <Link to={'/-/settings/account'} dataTestId={TEST_ID.ACCOUNTS_LINK}>
        Account
      </Link>
      &nbsp;pagina om een andere accountbeheerder aan het account toe te voegen
      om vervolgens deze gebruiker te verwijderen.
    </>
  ),
};

type DeleteEntityPayload = {
  __typename: 'DeleteEntityPayload';
  success: boolean;
  error?:
    | {
        lockedByUserId: string;
        reason: DeleteEntityErrorReason;
      }
    | null
    | undefined;
};
export type EntityType = 'OFFICE' | 'USER';
type Props = {
  list: Array<ListItemForDeletion>;

  /** When the modal is closed */
  onClose: () => void;

  /** When the cancel button is pressed */
  onCancel: () => void;

  /** If you are coming from users/userId page use this id to pass in mutation variables otherwise use me.id */
  entityId: string;
  entityType: EntityType;
  onSuccess: () => void;
  // In deleteUserFromAccount mutation it needs a changeSet, in deleteOffice mutation it needs changeSet and transferOfficeResourcesTo parameter for the unassigned users in the office
  onDelete: (
    changeSet: Array<TransferResourcesChangeSet>,
    transferOfficeResourcesTo: ReassignEntityInput | null,
  ) => Promise<DeleteEntityPayload | null>;
  modalHeader: string | React.ReactNode;
  modalDescription: string;
  mutationError?: ApolloError;
  deletionType: DeletionType;
};
const DeleteEntityModal: React.FCC<Props> = ({
  list,
  onClose,
  onCancel,
  onDelete,
  onSuccess,
  entityId,
  entityType,
  modalHeader,
  modalDescription,
  mutationError,
  deletionType,
}) => {
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const accountAdminRelations = useRelationshipForRole({
    type: 'AccountRelationship',
    role: UserRole.Admin,
  });
  const isDeletingUserLastAccountAdmin =
    accountAdminRelations.length === 1 &&
    entityId === accountAdminRelations[0].userId;
  useEffect(() => {
    if (mutationError) {
      const errorMessage = getErrorMessageForModal(mutationError);
      setErrorMessage(errorMessage);
    }

    if (isDeletingUserLastAccountAdmin) {
      setErrorMessage(text.isLastAccountAdminError);
    }
  }, [mutationError, isDeletingUserLastAccountAdmin]);

  const userLookUpTable = useUserLookup();

  const [migrationValues, setMigrationValues] = useState<Array<MigrationItem>>(
    list.map(listItem => {
      switch (entityType) {
        case DELETE_ENTITY_TYPE.USER:
          return {
            sourceUserId: entityId,
            sourceOfficeId: listItem.id,
            targetOfficeId: null,
            targetUserId: null,
            listItem,
          };
        case DELETE_ENTITY_TYPE.OFFICE:
          return {
            sourceUserId: listItem.id,
            sourceOfficeId: entityId,
            targetOfficeId: null,
            targetUserId: null,
            listItem,
          };
        default:
          throw new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | Unknown entity type ${entityType}`,
          );
      }
    }),
  );
  const officeName = useOffice(entityId)?.name;

  const officeRelations = useOfficeRelationsForUsers({ officeId: entityId });
  const officeAmounts = values(officeRelations).map(
    value => Object.keys(value).length,
  );
  const hasOnlyOneOffice = officeAmounts.some(amount => amount === 1);

  // When there are users in the office with only one office relation
  const hasErrors =
    (entityType === DELETE_ENTITY_TYPE.OFFICE && hasOnlyOneOffice) ||
    isDeletingUserLastAccountAdmin;

  const handleSubmit = () => {
    const hasValidationError = migrationValues.some(
      item => !hasValidOffice(item) || !hasValidUser(item),
    );

    const { specificMigrationValues, noOptionMigrationValue } =
      convertMigrationValuesForMutation(entityId, entityType, migrationValues);

    if (hasValidationError) {
      return setShowValidation(true);
    } else {
      return onDelete(specificMigrationValues, noOptionMigrationValue).then(
        result => {
          if (result == null) {
            setErrorMessage(Catalog.genericUnknownErrorMessage);
            return;
          }
          const { error, success } = result;
          if (error == null && success === false) {
            setErrorMessage(Catalog.genericUnknownErrorMessage);
            return;
          }
          if (error != null) {
            const { lockedByUserId, reason } = error;
            const user = userLookUpTable && userLookUpTable[lockedByUserId];
            const lockedByUserName = getUserName(user) || 'Onbekend';
            const entityUser = userLookUpTable && userLookUpTable[entityId];
            const deletingEntityName =
              entityType === DELETE_ENTITY_TYPE.OFFICE
                ? officeName
                : getUserName(entityUser);

            const errorMessage = convertBackendErrorMessage(
              lockedByUserName,
              deletingEntityName ? deletingEntityName : '',
              reason,
            );
            setErrorMessage(errorMessage ? errorMessage : null);
          } else if (success) {
            setErrorMessage(null);
            onSuccess();
          }
        },
      );
    }
  };
  return (
    <Modal onClose={onClose} small>
      <ModalContainer data-testid={TEST_ID.CONTAINER}>
        <TopSectionContainer
          headerText={modalHeader}
          withBorder
          descriptionText={modalDescription}
        />

        <ListContainer>
          <SubHeaderContainer>
            <FromContainer>
              <span>Taken en contacten</span>
            </FromContainer>
            <ToContainer>
              <span>Toewijzen</span>
            </ToContainer>
          </SubHeaderContainer>

          {migrationValues.map((migrationValue, idx) => (
            <ListItemCard
              key={
                entityType === DELETE_ENTITY_TYPE.USER
                  ? migrationValue.sourceOfficeId
                  : migrationValue.sourceUserId
              }
              item={migrationValue.listItem}
              isLastItem={
                idx === list.length - 1 || migrationValues.length === 1
              }
              migrationValue={migrationValue}
              setMigrationValue={data => {
                const newValues = migrationValues.map(item => {
                  if (entityType === DELETE_ENTITY_TYPE.USER) {
                    return item.sourceOfficeId === migrationValue.sourceOfficeId
                      ? data
                      : item;
                  }

                  return item.sourceUserId === migrationValue.sourceUserId
                    ? data
                    : item;
                });

                setMigrationValues(newValues);
              }}
              showValidation={showValidation}
              entityId={entityId}
              entityType={entityType}
              deletionType={deletionType}
            />
          ))}

          {errorMessage && (
            <ErrorMessageContainer data-testid={TEST_ID.ERROR_MESSAGE}>
              {errorMessage}
            </ErrorMessageContainer>
          )}
        </ListContainer>

        <BottomButtonRow>
          <Button
            data-testid={TEST_ID.CANCEL}
            ghost
            size="medium"
            onClick={onCancel}
            label={text.cancelButtonLabel}
          />
          <Button
            data-testid={TEST_ID.SUCCESS}
            appearance="secondary"
            onClick={handleSubmit}
            disabled={hasErrors}
            size="medium"
            label={text.successButtonLabel}
          />
        </BottomButtonRow>
      </ModalContainer>
    </Modal>
  );
};

const convertMigrationValuesForMutation = (
  entityId: string,
  entityType: EntityType,
  migrationValues: Array<MigrationItem>,
): {
  specificMigrationValues: Array<TransferResourcesChangeSet>;
  noOptionMigrationValue: ReassignEntityInput | null;
} => {
  const specificMigrationValues: Array<TransferResourcesChangeSet> = [];
  let noOptionMigrationValue: ReassignEntityInput | null = null;

  migrationValues.forEach(item => {
    const { sourceOfficeId, sourceUserId, targetOfficeId, targetUserId } = item;

    if (item.sourceUserId === NOT_ASSIGNED_TO_USER_ID) {
      noOptionMigrationValue = {
        officeId: targetOfficeId,
        userId: targetUserId,
      };
    } else {
      switch (entityType) {
        case DELETE_ENTITY_TYPE.USER:
          specificMigrationValues.push({
            source: {
              officeId: sourceOfficeId,
              userId: entityId,
            },
            target: {
              officeId: targetOfficeId,
              userId: targetUserId,
            },
          });
          break;
        case DELETE_ENTITY_TYPE.OFFICE:
          specificMigrationValues.push({
            source: {
              officeId: entityId,
              userId: sourceUserId,
            },
            target: {
              officeId: targetOfficeId,
              userId: targetUserId,
            },
          });
          break;
        default:
          throw new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | Unknown entity type ${entityType}`,
          );
      }
    }
  });

  return {
    specificMigrationValues,
    noOptionMigrationValue,
  };
};

export default DeleteEntityModal;
