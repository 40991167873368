import styled, { css } from 'styled-components';

export const ASIDE_WIDTH = '430px';

/** Common attributes to be used in aside. Aligned to the right side */
const BaseAside = styled.aside<{}>(
  ({ theme }) => css`
    padding: ${theme.space('m')};
    position: absolute;
    top: ${theme.space('s')};
    right: ${theme.space('s')};
    width: ${ASIDE_WIDTH};
    background-color: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('around')};
    border-radius: ${theme.getTokens().border.radius.s};
    z-index: ${theme.getTokens().zIndex.dropdown};
  `,
);

export default BaseAside;
