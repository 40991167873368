import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React, { useEffect } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import {
  AppBbWaardecheckFieldsFragment,
  AppVboWaardecheckFieldsFragment,
  GetAppBbWaardecheckQuery,
  GetAppBbWaardecheckRouteAvailabilityQuery,
  GetAppBbWaardecheckRouteAvailabilityQueryVariables,
  GetAppVboWaardecheckQuery,
  GetAppVboWaardecheckRouteAvailabilityQuery,
  GetAppVboWaardecheckRouteAvailabilityQueryVariables,
} from '~/graphql/types';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import MasterDetail from '~/components/template/MasterDetail';
import Settings from './pages/Settings';
import Design from './pages/Design';
import Address from './pages/Address';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import transformToInput from './utils/transformToInput';

import PropertyType from './pages/PropertyType';
import Report from './pages/Report';
import Why from './pages/Why';
import When from './pages/When';
import ValuationFailure from './pages/ValuationFailure';
import ValuationSuccess from './pages/ValuationSuccess';
import AppraisalRequest from './pages/AppraisalRequest';
import AppraisalRequestSuccess from './pages/AppraisalRequestSuccess';
import About from './pages/About';
import Comparison from './pages/Comparison';
import FooterSaveBar from '~/components/bad/SaveBar/FooterSaveBar';
import findDifference from '~/util/findDifference';
import TEST_ID_MASTER_DETAIL from '~/components/template/MasterDetail/index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import getDefaultData from './utils/getDefaultData';
import { navBarItems } from './constants';
import {
  appWaardecheckError,
  appWaardecheckErrorDerived,
  appWaardecheckImageStash,
  appWaardecheckState,
} from './state';

// Make sure to return the same strings in bb-wc and bb-wc-widget
export enum WaardecheckApp {
  VBO = 'VBOWaardecheck',
  BB = 'BBWaardecheck',
}

export type AppWaardecheckFieldsFragment =
  | AppBbWaardecheckFieldsFragment
  | AppVboWaardecheckFieldsFragment;

export enum StateId {
  original = 'original',
  updated = 'updated',
}

type Props = RouteComponentProps & {
  /** Title of the app */
  appTitle: string;

  /** BBWaardeheck or VBOWaardecheck */
  appType: WaardecheckApp;

  /** Get app query data */
  queryData:
    | GetAppBbWaardecheckQuery['getAppBBWaardecheck']
    | GetAppVboWaardecheckQuery['getAppVBOWaardecheck'];

  /** Loading state of get app query */
  queryLoading: boolean;

  /** Loading state of update app mutation */
  mutationLoading: boolean;

  /** Function to call to get the updated values from the mutation */
  onSave: () => Promise<AppWaardecheckFieldsFragment | undefined>;

  /** Data from getRouteAvailability query */
  routeAvailability: {
    data?:
      | GetAppBbWaardecheckRouteAvailabilityQuery
      | GetAppVboWaardecheckRouteAvailabilityQuery;
    variables?:
      | GetAppBbWaardecheckRouteAvailabilityQueryVariables
      | GetAppVboWaardecheckRouteAvailabilityQueryVariables;
    loading: boolean;
  };

  /** Function to call to check if the route it available */
  getRouteAvailability: (route: string) => void;
};

const WaardecheckTemplate: React.FCC<Props> = ({
  uri = '',
  onSave,
  queryData,
  queryLoading,
  appType,
  appTitle,
  mutationLoading,
  routeAvailability,
  getRouteAvailability,
}) => {
  const { name: accountName } = useCurrentAccount();

  const defaultValues = getDefaultData({ accountName, appType });
  const { errorsByPath, errorCount, errorDescription } = useRecoilValue(
    appWaardecheckErrorDerived,
  );

  const [updated, setUpdated] = useRecoilState(
    appWaardecheckState(StateId.updated),
  );
  const [original, setOriginal] = useRecoilState(
    appWaardecheckState(StateId.original),
  );
  const setImageStash = useSetRecoilState(appWaardecheckImageStash);

  const resetState = [
    useResetRecoilState(appWaardecheckState(StateId.updated)),
    useResetRecoilState(appWaardecheckState(StateId.original)),
    useResetRecoilState(appWaardecheckImageStash),
  ];

  const resetErrors = [
    useResetRecoilState(appWaardecheckError),
    useResetRecoilState(appWaardecheckErrorDerived),
  ];

  useEffect(
    () => () => {
      resetState.forEach(r => r());
      resetErrors.forEach(r => r());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...resetState, ...resetErrors],
  );

  const changeCount =
    original != null && updated != null
      ? findDifference(updated, original).differenceCount
      : 0;

  if (!queryData) return <DatHuisLoading />;

  const args = {
    defaultValues,
    loading: queryLoading,
    data: queryData,
  };

  return (
    <>
      <MetaTags>
        <title>{appTitle}</title>
      </MetaTags>
      <ContentContainerDefault
        breadcrumbs={[
          {
            to: '/-/apps',
            label: 'Apps',
          },
          { label: appTitle },
        ]}
      >
        <MasterDetail
          basePath={uri}
          dataTestId={TEST_ID_MASTER_DETAIL.CONTAINER}
          issues={errorsByPath}
          navbar={navBarItems}
        >
          <Settings
            path="/"
            routeAvailability={routeAvailability}
            getRouteAvailability={getRouteAvailability}
            {...args}
          />
          <Design path="/design" {...args} />
          <Address path="/address" {...args} />
          <PropertyType path="/propertyType" {...args} />
          <Why path="/why" {...args} />
          <When path="/when" {...args} />
          <Comparison path="/comparison" {...args} />
          <ValuationFailure path="/valuationFailure" {...args} />
          <ValuationSuccess path="/valuationSuccess" {...args} />
          <AppraisalRequest path="/appraisalRequest" {...args} />
          <AppraisalRequestSuccess path="/appraisalRequestSuccess" {...args} />
          <Report path="/report" {...args} />
          <About path="/about" {...args} />
        </MasterDetail>
        {changeCount !== 0 && (
          <FooterSaveBar
            disableSave={queryLoading || mutationLoading || errorCount !== 0}
            saveErrorMessage={errorDescription}
            onSave={async () => {
              const updatedValue = await onSave();

              if (!updatedValue) return;

              const inputFormatted = transformToInput(updatedValue);

              setUpdated(inputFormatted);
              setOriginal(inputFormatted);
              setImageStash(getImageStash(updatedValue));

              // Remove the widget if it is open
              document
                .querySelectorAll('.DatHuis_WaardecheckApp__close_button')
                // forEach in case something went wrong removing a previous widget
                ?.forEach((el: HTMLElement) => el?.click());
            }}
            onCancel={() => {
              setUpdated(original);
              resetErrors.forEach(r => r());
            }}
            numberOfChanges={changeCount}
          />
        )}
      </ContentContainerDefault>
    </>
  );
};

export const getImageStash = ({
  general,
  about,
  address,
  appraisalRequest,
  appraisalRequestSuccess,
  propertyType,
  report,
  valuationFailure,
  valuationSuccess,
  when,
  why,
}) => ({
  [general.logoImage?.s3key ?? '']: general.logoImage?.url ?? '',
  [general.fallbackImage?.s3key ?? '']: general.fallbackImage?.url ?? '',
  [about.image?.s3key ?? '']: about.image?.url ?? '',
  [address.image?.s3key ?? '']: address.image?.url ?? '',
  [appraisalRequest.image?.s3key ?? '']: appraisalRequest.image?.url ?? '',
  [appraisalRequestSuccess.image?.s3key ?? '']:
    appraisalRequestSuccess.image?.url ?? '',
  [propertyType.image?.s3key ?? '']: propertyType.image?.url ?? '',
  [report.image?.s3key ?? '']: report.image?.url ?? '',
  [valuationFailure.image?.s3key ?? '']: valuationFailure.image?.url ?? '',
  [valuationSuccess.image?.s3key ?? '']: valuationSuccess.image?.url ?? '',
  [when.image?.s3key ?? '']: when.image?.url ?? '',
  [why.image?.s3key ?? '']: why.image?.url ?? '',
});

export default WaardecheckTemplate;
