import type { IconType } from '~/components/atom/Icon';
import type {
  FormBuilder_Locale,
  FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import type { OptionOf } from '~/components/molecule/Dropdown';
import * as TypographyComps from '~/components/atom/Typography';
import { Props as TypographyProps } from '~/components/atom/Typography';
import type { TypographyComponentType } from '../components/ScreenPreview/utils/formatNode/types';

export const ItemTypes = {
  BLOCK_COMPONENT: 'BLOCK_COMPONENT',
};
export const SUBMIT_SCREEN_TITLE = 'Verzendscherm';

export const AREA_HEIGHT = '650px';
export enum DND_SOURCES {
  componentsCollection = 'componentsCollection',
  blocksArea = 'blocksArea',
}

export const localeToIntlKey: Record<FormBuilder_Locale, string> = {
  NL: 'nl',
  EN: 'en',
};

export const localeToLabel: Record<FormBuilder_Locale, string> = {
  NL: 'Label (🇳🇱)',
  EN: 'Label (🇬🇧)',
};

export type BlocksMap = {
  [key in FormBuilder_ScreenNode_Block['__typename']]: {
    icon: IconType;
    label: string;
    color: string;
    placeHolder?: string;
  };
};

export const blocksMap: BlocksMap = {
  FormData_Input_Email: {
    label: 'E-mail invoer',
    icon: 'email-sign',
    color: '#1E2647',
    placeHolder: 'example@email.com',
  },
  FormData_Input_Integer: {
    label: 'Geheel getal invoer',
    icon: 'number',
    color: '#FF6900',
    placeHolder: '13567894342',
  },
  FormData_Input_Multiline: {
    label: 'Meerdere regels invoer',
    icon: 'multi-choice',
    color: '#3763D4',
    placeHolder:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  },
  FormData_Input_Text: {
    label: 'Tekst invoer',
    icon: 'text-background',
    color: '#FFB464',
    placeHolder: 'Placeholder text',
  },
  FormData_Select_Dropdown: {
    label: 'Keuzelijst',
    icon: 'dropdown',
    color: '#018827',
  },
  FormData_Select_MultiButton: {
    label: 'Multi-knop',
    icon: 'multi-choice',
    color: '#FFD6A8',
  },
  FormData_Select_Radio: {
    label: 'Enkele keuzeknop',
    icon: 'single-choice',
    color: '#C6020B',
  },
  FormData_UI_Image: {
    label: 'Afbeelding',
    icon: 'image',
    color: '#B6D3FF',
  },
  FormData_UI_RichText: {
    label: 'Tekst met opmaak',
    icon: 'rich-text',
    color: '#213C80',
    placeHolder: `
    ## Vident atque credit adiecisset quae
    
    Gravis placet extulit iusta gentemque taurum cognovit. Cantare **forma**, ulla
    amnis [qui](http://tritiscapillos.com/) rotatum
    [fecit](http://www.mutua.net/gratamque), domus et albet perenni: Aiaci anno
    hastile *Diomede*!
    
    Ubi ergo sacra isque licet morsus spatium trucis piae scitatur. Contermina funis
    super possunt caeli. Tibi cantus igneus forma ventis niveis, illa longas artes
    possent colubrae de Iovis quas praescia, nec. Inde spumam; hic oneris, et etiam
    purpureo qua sopire suco! Valet genetrici manu, una potuit foramine cognoscite
    senex mecumque **novissima terque**.
    `,
  },
  FormData_UI_Typography: {
    label: 'Typografie',
    icon: 'typography',
    color: '#642901',
  },
  FormData_Input_Contact_Email: {
    icon: 'mail',
    label: 'Contact e-mailadres',
    color: '#FFB464',
    placeHolder: 'example@email.com',
  },
  FormData_Input_Contact_Name: {
    icon: 'typography',
    label: 'Contact Naam',
    color: '#A2040E',
    placeHolder: 'Jhon Jhonson',
  },
  FormData_Input_Contact_Phone: {
    icon: 'phone',
    // The `-` in `telefoon-nummer` is added to break `nummer` word to the next line
    label: 'Contact telefoon-nummer',
    color: '#018827',
    placeHolder: '3197010587401',
  },
};

export const BLOCK_OPTIONS: Array<
  OptionOf<FormBuilder_ScreenNode_Block['__typename']>
> = [
  {
    payload: 'FormData_Input_Contact_Email',
    key: 'FormData_Input_Contact_Email',
    label: blocksMap['FormData_Input_Contact_Email'].label,
  },
  {
    payload: 'FormData_Input_Contact_Name',
    key: 'FormData_Input_Contact_Name',
    label: blocksMap['FormData_Input_Contact_Name'].label,
  },
  {
    payload: 'FormData_Input_Contact_Phone',
    key: 'FormData_Input_Contact_Phone',
    label: blocksMap['FormData_Input_Contact_Phone'].label,
  },
  {
    payload: 'FormData_Input_Email',
    key: 'FormData_Input_Email',
    label: blocksMap['FormData_Input_Email'].label,
  },
  {
    payload: 'FormData_Input_Integer',
    key: 'FormData_Input_Integer',
    label: blocksMap['FormData_Input_Integer'].label,
  },
  {
    payload: 'FormData_Input_Multiline',
    key: 'FormData_Input_Multiline',
    label: blocksMap['FormData_Input_Multiline'].label,
  },
  {
    payload: 'FormData_Input_Text',
    key: 'FormData_Input_Text',
    label: blocksMap['FormData_Input_Text'].label,
  },
  {
    payload: 'FormData_Select_Dropdown',
    key: 'FormData_Select_Dropdown',
    label: blocksMap['FormData_Select_Dropdown'].label,
  },
  {
    payload: 'FormData_Select_MultiButton',
    key: 'FormData_Select_MultiButton',
    label: blocksMap['FormData_Select_MultiButton'].label,
  },
  {
    payload: 'FormData_Select_Radio',
    key: 'FormData_Select_Radio',
    label: blocksMap['FormData_Select_Radio'].label,
  },
  {
    payload: 'FormData_UI_RichText',
    key: 'FormData_UI_RichText',
    label: blocksMap['FormData_UI_RichText'].label,
  },
  {
    payload: 'FormData_UI_Image',
    key: 'FormData_UI_Image',
    label: blocksMap['FormData_UI_Image'].label,
  },
  {
    payload: 'FormData_UI_Typography',
    key: 'FormData_UI_Typography',
    label: blocksMap['FormData_UI_Typography'].label,
  },
];

export const typographyMap: Record<
  TypographyComponentType['props']['type'],
  React.FCC<TypographyProps>
> = {
  Heading1: TypographyComps.Heading1,
  Heading2: TypographyComps.Heading2,
  Heading3: TypographyComps.Heading3,
  Heading4: TypographyComps.Heading4,
  Heading5: TypographyComps.Heading5,
  Heading6: TypographyComps.Heading6,
  SubHeading: TypographyComps.SubHeading,
  Body: TypographyComps.Body,
};
