import React from 'react';
import styled, { useTheme } from 'styled-components';
import { is } from 'ramda';
import type { ApolloError } from '@apollo/client';

import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import ContactActionColumnCell from '../../../ContactActionColumnCell';
import TEST_ID from './index.testid';
import ErrorTypes from '~/ErrorTypes';
import Catalog from '~/Catalog';
import { getErrorTypes } from '~/util/errorHandling';

import type { ContactListData } from '~/components/page/Contacts/util/composeContactListData';
import { RowCell } from '~/components/bad/DataTables';
import CheckboxColumnCell from '~/components/bad/DataTables/components/CheckboxColumnCell';
import LoadingBody from '~/components/bad/DataTables/styling/LoadingBody';
import Row from '~/components/bad/DataTables/styling/Row';
import { ACTION_COLUMN_ACCESSOR } from '~/components/bad/DataTables/util/TableHeaderCell/action';
import { CHECKBOX_COLUMN_ACCESSOR } from '~/components/bad/DataTables/util/TableHeaderCell/checkbox';
import NoResultSection from '~/components/page/NoResultSection';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';
import Body from '~/components/bad/DataTables/styling/Body';

const text = {
  invalidContactFilters:
    'Je hebt ongeldige filters geselecteerd. Ververs de pagina en wijzig de filterselectie.',
  queryError: Catalog.genericUnknownErrorMessage,
  noSearchResults: Catalog.noResults,
  addYourFirstContact: 'Voeg je eerste contact toe',
  emptyStateDescription:
    'Voeg bestaande contacten toe of genereer nieuwe leads via apps.',
  noContacts: 'Je hebt nog geen contacten',
  bouncedEmailTooltip: 'E-mail kan niet worden afgeleverd',
};

// We will fix this after adding types for react-table
type Row = any;

export type Props = {
  dataTestId?: string;
  data: Array<ContactListData>;
  networkStatus: number;
  loading: boolean;
  error: ApolloError | boolean;

  rows: Array<Row>;

  isSelectable: boolean;
  allSelected: boolean;
  totalContactsInAccount: number | null;

  /* Specify the amount of rows that should show in loading state */
  loadingAmount?: number;

  onSuccessfulDelete: (contactId: string) => void;

  checkIfSelected: (id: string) => boolean;

  prepareRow: (row: Row) => void;

  getTableBodyProps: () => Record<string, unknown>;

  onAddContact: () => void;

  toggleRowSelect: (id: string) => void;
};

const BodyContent: React.FCC<Props> = ({
  error,
  networkStatus,
  rows,
  loadingAmount,
  totalContactsInAccount,
  data,
  loading,
  isSelectable,
  allSelected,
  prepareRow,
  getTableBodyProps,
  onAddContact,
  onSuccessfulDelete,
  checkIfSelected,
  toggleRowSelect,
}) => {
  const theme = useTheme();

  if (error) {
    return (
      <StyledNoResultSection data-testid={TEST_ID.ERROR_MESSAGE}>
        {is(Boolean, error)
          ? text.queryError
          : getErrorTypes(error).includes(ErrorTypes.invalidContactFilters)
            ? text.invalidContactFilters
            : text.queryError}
      </StyledNoResultSection>
    );
  }

  if (isInitialLoadStatus(networkStatus)) {
    return (
      <LoadingBody
        rowAmount={rows.length || loadingAmount || 10}
        getTableBodyProps={getTableBodyProps}
      />
    );
  }

  if (totalContactsInAccount === 0) {
    return (
      <EmptyStateComponent
        header={text.noContacts}
        description={text.emptyStateDescription}
        illustration="users"
        buttonLabel={text.addYourFirstContact}
        blobColor={theme.color('grey', 'light')}
        onButtonClick={onAddContact}
        dataTestId={TEST_ID.EMPTY_STATE}
      />
    );
  }

  if (data.length === 0 && !loading) {
    return (
      <StyledNoResultSection>{text.noSearchResults}</StyledNoResultSection>
    );
  }

  return (
    <Body.Standard.Inner {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row);

        const rowProps = { ...row.getRowProps() };
        const contactId = row.id;
        const isDeleted = row.original && row.original.isDeleted === true;
        const isLimited =
          row.original &&
          row.original.permission?.__typename ===
            'Permission_AccessDenied_Plan';
        const isRowSelected = checkIfSelected(contactId);

        return (
          <Row
            {...rowProps}
            $isDeleted={isDeleted}
            data-blurred={isLimited}
            key={rowProps.key}
            data-objectid={contactId}
            data-deleted={isDeleted}
            dataTestId={TEST_ID.LIST_ITEM}
            to={`/-/contacts/${contactId}`}
          >
            {row.cells.map((cell, idx) => {
              const isLastColumn = idx === row.cells.length - 1;

              if (cell.column.id === ACTION_COLUMN_ACCESSOR) {
                return (
                  <ContactActionColumnCell
                    key={`cell-${idx}`}
                    contactId={contactId}
                    cell={cell}
                    onSuccessfulDelete={() => onSuccessfulDelete(contactId)}
                  />
                );
              }

              if (cell.column.id === CHECKBOX_COLUMN_ACCESSOR && isSelectable) {
                return (
                  <CheckboxColumnCell
                    key={`cell-${idx}`}
                    cell={cell}
                    onChange={() => toggleRowSelect(contactId)}
                    value={allSelected ? true : isRowSelected}
                    disabled={allSelected}
                  />
                );
              }

              return (
                <RowCell
                  key={`cell-${idx}`}
                  cell={cell}
                  isLastColumn={isLastColumn}
                />
              );
            })}
          </Row>
        );
      })}
    </Body.Standard.Inner>
  );
};

const StyledNoResultSection = styled(NoResultSection)<{}>`
  max-width: 800px;
`;

export default BodyContent;
