import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled, { css } from 'styled-components';
import Portal from '~/components/molecule/Portal';
import useKeybinding from '~/hooks/useKeybinding';
import useClickOutside from '~/hooks/useClickOutside';

type RootId =
  | 'wizard'
  | 'app-setup'
  | 'global-wizard-portal'
  | 'deactivate-app-modal'
  | 'fullscreen-modal'
  | 'confirm-start-flow-modal'
  | 'import-contacts'
  | 'confirm-modal'
  | 'task-modal'
  | 'insert-html';

export type Props = {
  onClose?: () => false | void | undefined;
  onComplete?: (...args: Array<any>) => void;
  root?: RootId;
};

const innerTransitionStart = {
  transform: 'translate(0, 200%)',
  opacity: 0,
};
const innerTransitionEnd = {
  transform: 'translate(0, 0%)',
  opacity: 1,
};

const Overlay: React.FCC<Props> = ({
  dataTestId,
  children,
  onClose,
  root = 'wizard',
}) => {
  const [show, setShow] = useState(true);
  const onInternalClose = useCallback(
    (disableClosing: boolean) => () => {
      if (!disableClosing && onClose) {
        onClose();
        setShow(false);
      }
    },
    [onClose],
  );

  const transition = useTransition(show, {
    from: innerTransitionStart,
    enter: innerTransitionEnd,
    leave: innerTransitionStart,
  });

  useEffect(() => {
    const toLockElement = document.scrollingElement as HTMLElement;

    // to disable scrolling on the current scrolling element (usually the html tag)
    if (toLockElement != null) {
      toLockElement.style.overflow = 'hidden';
    }

    return () => {
      // enable scrolling on the current scrolling element (afterwards again)
      if (toLockElement != null) {
        toLockElement.style.removeProperty('overflow');
      }
    };
  }, []);

  return (
    <>
      {show && (
        <Portal root={root}>
          <Container data-testid={dataTestId}>
            {transition(
              (style, inner) =>
                inner && (
                  <Inner style={style}>
                    {React.Children.map(
                      children,
                      child =>
                        React.isValidElement(child) && (
                          <ChildContainer
                            child={child}
                            onClose={onClose}
                            onInternalClose={onInternalClose}
                          />
                        ),
                    )}
                  </Inner>
                ),
            )}
          </Container>
        </Portal>
      )}
    </>
  );
};

const ChildContainer: React.FCC<{
  child: React.ReactElement;
  onInternalClose: (x: boolean) => () => void;
  onClose?: () => false | void | undefined;
}> = React.forwardRef(({ child, onInternalClose, onClose }, ref) => {
  const containerRef = useRef(null);
  const childComponent = React.cloneElement(child, {
    ...child.props,
    ref,
    onClose: onClose
      ? onInternalClose(child.props.disableClosing ?? false)
      : undefined,
  });

  useClickOutside(
    containerRef,
    onInternalClose(child.props.disableClosing ?? false),
  );
  useKeybinding({
    keys: ['escape'],
    callback: onInternalClose(child.props.disableClosing ?? false),
  });

  return childComponent;
});

const Container = styled(animated.div)<{}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(242, 242, 242, 0.95);
  height: 100vh;
  overflow-y: scroll;

  ${({ theme }) => css`
    z-index: ${theme.z('top')};
  `}
`;

const Inner = styled(animated.div)<{}>(
  ({ theme }) => css`
    perspective: 5000;
    width: 100%;
    height: 100%;

    ${theme.mq.lessThan('tablet')`
      padding: 0 ${theme.space('xl')};
    `}
  `,
);

/**
 * Adds the overlay to any component wrapped in this HOC
 */
export const withOverlay =
  <P extends object>(Component: React.ComponentType<P>): React.FCC<P & Props> =>
  ({ onClose, root, ...rest }) => (
    <Overlay root={root} onClose={onClose}>
      <Component {...(rest as P)} />
    </Overlay>
  );

export default Overlay;
