import {
  type FormBuilderFragment,
  type FormBuilder_EventFragment,
  type FormBuilder_Event_Field_Type,
  type FormBuilder_Event_Field_Type__Input,
  type FormBuilder_Event_Field__Input,
  type FormBuilder_Event__Input,
  type FormBuilder_NodeFragment,
  type FormBuilder_Node__Input,
  type FormBuilder_ScreenNodeFragment,
  type FormBuilder_ScreenNode_Block__Input,
  type FormBuilder_StyleFragment,
  type FormBuilder_Style__Input,
  type FormBuilder__Input,
} from '~/graphql/types';
import removeTypenames from '~/util/removeTypenames';

const deserializeFormBuilderBlock = (
  block: FormBuilder_ScreenNodeFragment['blocks'][number],
): FormBuilder_ScreenNode_Block__Input => {
  if (block.__typename === 'FormData_UI_Image') {
    return {
      FormData_UI_Image: {
        alt: removeTypenames(block.alt),
        image: {
          s3key: block.image.s3key,
        },
        key: block.key,
        width: block.width ?? 800,
      },
    };
  }

  return { [block.__typename]: removeTypenames(block) };
};

const deserializeFormBuilderNodes = (
  nodes: Array<FormBuilder_NodeFragment>,
): Array<FormBuilder_Node__Input> =>
  nodes.map((node): FormBuilder_Node__Input => {
    if (node.__typename === 'FormBuilder_ScreenNode') {
      return {
        FormBuilder_ScreenNode: {
          id: node.id,
          name: node.name,
          defaultNext: node.defaultNext
            ? removeTypenames(node.defaultNext)
            : undefined,
          blocks: node.blocks.map(
            (block): FormBuilder_ScreenNode_Block__Input =>
              deserializeFormBuilderBlock(block),
          ),
        },
      };
    }

    return {
      FormBuilder_EventNode: {
        id: node.id,
        name: node.name,
        defaultNext: node.defaultNext
          ? removeTypenames(node.defaultNext)
          : undefined,
        formBuilderEventId: node.formBuilderEventId,
        mapping: removeTypenames(node.mapping),
      },
    };
  });

const deserializeFormBuilderEventFieldType = (
  type: FormBuilder_Event_Field_Type,
): FormBuilder_Event_Field_Type__Input => {
  if (type.__typename === 'FormBuilder_Event_Field_Type_Primitive') {
    return {
      Primitive: {
        type: type.type,
      },
    };
  }

  return {
    Option: {
      id: type.id,
    },
  };
};

const deserializeFormBuilderEvents = (
  events: Array<FormBuilder_EventFragment>,
): Array<FormBuilder_Event__Input> =>
  events.map(
    (event): FormBuilder_Event__Input => ({
      fields: event.fields.map(
        (field): FormBuilder_Event_Field__Input => ({
          key: field.key,
          name: field.name,
          metaField: field.metaField,
          type: deserializeFormBuilderEventFieldType(field.type),
        }),
      ),
      id: event.id,
      name: event.name,
    }),
  );

const deserializeFormBuilderStyle = (
  style: FormBuilder_StyleFragment,
): FormBuilder_Style__Input => ({
  availableLocale: style.availableLocale,
  layout: {
    FormBuilder_Style_PageLayout_Default: {
      backgroundColor: style.layout.backgroundColor,
      backgroundImage: style.layout.backgroundImage
        ? {
            s3key: style.layout.backgroundImage.s3key,
          }
        : undefined,
    },
  },
  theme: {
    colors: removeTypenames(style.theme.colors),
    logoLink: style.theme.logoLink,
    ...(style.theme.logo?.s3key
      ? { logo: { s3key: style.theme.logo.s3key } }
      : {}),
  },
});

const deserializeFormBuilder = (
  formBuilder: FormBuilderFragment,
): FormBuilder__Input => ({
  enabled: formBuilder.enabled,
  mode: formBuilder.mode,
  title: removeTypenames(formBuilder.title),
  name: formBuilder.name,
  description: formBuilder.description,
  accountId: formBuilder.accountId,
  events: deserializeFormBuilderEvents(formBuilder.events),
  nodes: deserializeFormBuilderNodes(formBuilder.nodes),
  optionLists: formBuilder.optionLists.map(removeTypenames),
  style: deserializeFormBuilderStyle(formBuilder.style),
});

export default deserializeFormBuilder;
