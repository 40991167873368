import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Div from '~/components/atom/Div';
import Icon from '~/components/atom/Icon';
import { CONDITION_ARROW_TRAIL } from '~/components/page/Automation/v2/components/Builder/constants/zIndexes';

export type Props = {
  dataTestId?: string;
  onDelete?: () => void;
  /** Shows the Container */
  show: boolean;
  /**  */
  isActiveCondition: boolean;
  /**
   * Renders the deletion button if available
   */
  isDeletable: boolean;
};

const ConditionArgumentContainer: React.FCC<Props> = ({
  dataTestId,
  children,
  onDelete,
  isDeletable,
  show,
  isActiveCondition,
  ...rest
}) => {
  const [hover, setHover] = useState(false);
  const showDelete = isDeletable && (isActiveCondition || (hover && show));

  return (
    <Container
      data-testid={dataTestId}
      $show={show || isActiveCondition}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => !hover && setHover(true)}
      $hover={hover || isActiveCondition}
      backgroundColor={{ group: 'white' }}
      border={{
        style: 'dashed',
        radius: 's',
        width: 's',
        color: { group: 'grey' },
      }}
      {...rest}
    >
      {children}
      {showDelete && (
        <DeleteButton onClick={() => onDelete?.()}>
          <Icon name="trashcan" />
        </DeleteButton>
      )}
    </Container>
  );
};

const Container = styled(Div)<{ $show: boolean; $hover: boolean }>(
  ({ $show, $hover }) => css`
    position: relative;
    transition: transform 300ms ease-out;
    /** Make sure the add condition button is visible next to the delete button */
    z-index: ${CONDITION_ARROW_TRAIL - 1};
    max-width: 100%;

    ${() => {
      if (!$show) {
        return css`
          border-color: transparent;
        `;
      }

      if ($hover) {
        return css`
          transform: scale(1.05);
        `;
      }

      return;
    }}
  `,
);

const DeleteButton = styled.button<{}>(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 1px;
    transform: translate(100%, 0);
    padding: ${theme.space('xxs')};
    background-color: ${theme.color('white')};
    border: 1px dashed ${theme.color('grey')};
    border-top-right-radius: ${theme.getTokens().border.radius.s};
    border-bottom-right-radius: ${theme.getTokens().border.radius.s};
    cursor: pointer;
    transition:
      transform,
      background-color 300ms ease-out;

    &:hover {
      background-color: ${theme.color('danger')};
      color: ${theme.color('white')};
    }
  `,
);

export default ConditionArgumentContainer;
