import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';

import Button, { Props as ButtonProps } from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import icons from '~/components/atom/Icon/components';
import { Heading3 } from '~/components/atom/Typography/index';
import TEST_ID from './index.testid';
import Modal from '../Modal';

export type Props = {
  icon?: keyof typeof icons;
  header: React.ReactNode;
  body: React.ReactNode;
  onConfirm: (e) => void;

  /* Method provided by an Overlay component */
  onClose?: () => false | void | undefined;

  confirmAction: Omit<ButtonProps, 'onClick'>;
  cancelAction: {
    label: React.ReactNode;
  };
  loading?: boolean;
};

const Dialog: React.FCC<Props> = ({
  dataTestId,
  header,
  icon = 'triangle',
  body,
  onConfirm,
  confirmAction,
  onClose,
  loading,
  cancelAction,
  ...rest
}) => (
  <Modal maxWidth={`${MAX_DIALOG_WIDTH}px`} onClose={onClose}>
    <Container
      backgroundColor={{ group: 'white' }}
      border={{ radius: 'base' }}
      data-testid={dataTestId}
      direction="column"
      width="100%"
      padding={['xxl', 'xxxxl', 'xl', 'xxxxl']}
      {...rest}
    >
      <JustificationContainer
        nonResponsive
        justification="start"
        align="center"
        gap="m"
        margin={[null, null, 'base', null]}
      >
        <LocalIcon name={icon} background="warning" />
        <Heading3 margin={['xxs', null, null, null]}>{header}</Heading3>
      </JustificationContainer>

      {body}

      <JustificationContainer
        width="100%"
        justification="end"
        gap="m"
        align="center"
        margin={['s', null, null, null]}
      >
        <TextButton
          {...cancelAction}
          size="medium"
          appearance="danger"
          loading={loading}
          onClick={onClose}
          dataTestId={TEST_ID.MODAL_CANCEL_BUTTON}
          withoutPadding
        />
        <Button
          onClick={onConfirm}
          dataTestId={TEST_ID.MODAL_CONFIRM_BUTTON}
          loading={loading}
          appearance="secondary"
          {...confirmAction}
        />
      </JustificationContainer>
    </Container>
  </Modal>
);

const MAX_DIALOG_WIDTH = 760;

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    border-top: 8px solid ${theme.color('warning')};
    box-shadow: ${theme.getTokens().boxShadow.modal};
  `,
);

const LocalIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
    color: ${theme.color('white')};

    /* Correct position of icon */
    svg {
      margin-top: -0.1rem;
      font-size: 0.9em;
    }

    /* Make icon a little bigger */
    &::before {
      width: 1.4em;
      height: 1.4em;
    }
  `,
);

export default Dialog;
