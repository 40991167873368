import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {};

const SaveBarBuster: React.FCC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest} />
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    height: 50px;
    background-color: ${theme.color('white', 'dark')};
  `,
);

export default SaveBarBuster;
