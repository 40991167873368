import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Input from '~/components/bad/Inputs/Input';

export const URL_PREFIX = 'https://waarderapport.';

const text = {
  desiredURL: 'Het domein waar het waarderapport is',
  invalidValue:
    'Helaas, dit is geen geldige URL. Gebruik alleen kleine letters en geen spaties',
};

export type Props = {
  route: string;
  onChange: (route: string) => void;
};

const URLContainer: React.FCC<Props> = ({ route, onChange }) => {
  const [validationError, setValidationError] = useState<string | null>(null);
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    if (touched === true && (route === '' || route?.includes(' '))) {
      return setValidationError(text.invalidValue);
    }

    return setValidationError(null);
  }, [route, touched]);

  return (
    <Container>
      <Label error={validationError}>
        {validationError ? validationError : text.desiredURL}
      </Label>
      <Inner>
        <Prefix>{URL_PREFIX}</Prefix>
        <Input
          type="text"
          error={validationError}
          value={route}
          onFocus={() => setTouched(true)}
          onChange={e => {
            if (e.target) {
              onChange(e.target.value.toLowerCase());
            }
          }}
          name="url-link"
        />
      </Inner>
    </Container>
  );
};

const Container = styled.div<{}>`
  width: 100%;
`;

const Label = styled.label<{ error: string | null }>(
  ({ theme, error }) => css`
    display: block;
    margin: ${theme.space('xxs')} 0 ${theme.space('xxs')} 0;
    color: ${error ? theme.color('danger') : theme.color('text')};
  `,
);

const Prefix = styled.div<{}>(
  ({ theme }) => css`
    background-color: #ddd; /** Yes,yes this should've come from the theme but we don't have it.... */
    height: 100%;
    padding: 11px; /** Exact same result as calculate in Input */
    border-top-left-radius: ${theme.getTokens().border.radius.s};
    border-bottom-left-radius: ${theme.getTokens().border.radius.s};
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
  `,
);
const Inner = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;

  input {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export default URLContainer;
