import React from 'react';
import styled, { css } from 'styled-components';
import { Link as LinkComp } from '~/components/molecule/Link';
import usePermissions from '~/hooks/usePermissions';
import { PermissionId } from '~/graphql/types';
import TEST_ID from '../../index.testid';
import LimitedIcon from '~/components/molecule/LimitedIcon';

type NavigationLinkBase = {
  label: React.ReactNode;
  to: string;
  matchPartial?: boolean;
  permission?: PermissionId;
  onClick?: () => void;
};
export type NavigationLinkWithSubMenu = NavigationLinkBase & {
  subMenu: Array<NavigationLinkBase>;
};
export type NavigationLink = NavigationLinkBase;

const NavLink: React.FC<NavigationLink> = ({
  to,
  label,
  matchPartial,
  permission: permissionId,
  ...rest
}) => {
  const permission = usePermissions(permissionId ? [permissionId] : []);

  return (
    <Link
      to={to}
      key={to}
      dataTestId={TEST_ID.asNavLink(to)}
      partiallyActive={matchPartial ?? false}
      {...rest}
    >
      <span>{label}</span>
      {!permission.allowed && (
        <IconContainer>
          <LimitedIcon size="s" />
        </IconContainer>
      )}
    </Link>
  );
};

const IconContainer = styled.div`
  position: relative;
  height: 1px;
  margin-top: 0.7rem;
`;

const HOVER_LINE_HEIGHT = '8px';
const ACTIVE_LINE_HEIGHT = '4px';
const TRANSITION = 'all ease 0.3s';
const Link = styled(LinkComp)<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    height: 100%;
    text-decoration: none;
    margin: 0 ${theme.space('l')};
    padding: ${theme.space('l')} 0;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.space('xxs')};

    &:first-child {
      margin: 0 ${theme.space('l')} 0 ${theme.space('xl')};
    }

    &:hover &:after,
    &:hover,
    &[data-active='true'] {
      color: ${theme.color('primary', 'light')};
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 0;
      display: block;
      transition: ${TRANSITION};
      background: ${theme.color('primary', 'light')};
    }

    &:hover {
      &::after {
        height: ${HOVER_LINE_HEIGHT};
      }
    }

    &[data-active='true'] {
      &::after {
        content: '';
        position: absolute;
        background: ${theme.color('primary', 'light')};
        width: 100%;
        height: ${ACTIVE_LINE_HEIGHT};
        display: block;
        bottom: 0px;
      }
    }
  `,
);

export default NavLink;
