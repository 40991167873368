import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';

import TEST_ID from './index.testid';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';

import { SortDirection } from '~/graphql/types';

export type SortToggleFunction = (
  sortField: string,
  sortDirection: SortDirection | null,
) => void;

const formListColumns = (
  sortToggle: SortToggleFunction,
  _version: string,
): Array<TableHeaderDefinitionCellType<any>> => {
  const columns = [
    TableHeaderCell.string({
      label: 'Naam',
      dataProperty: 'name',
      width: 100,
      dataTestId: TEST_ID.NAME,
      sorting: {
        onSort: sortDirection => sortToggle('name', sortDirection),
      },
    }),

    TableHeaderCell.string({
      label: 'Description',
      dataProperty: 'formDescription',
      width: 200,
      dataTestId: TEST_ID.DESCRIPTION,
      sorting: {
        onSort: sortDirection => sortToggle('description', sortDirection),
      },
    }),
    TableHeaderCell.action({
      dataTestId: TEST_ID.ACTION,
    }),
  ];

  return columns;
};

export default formListColumns;
