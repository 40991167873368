import React from 'react';
import { GetOfficeQuery, UserStatus, useGetOfficeQuery } from '~/graphql/types';

import convertMailboxes from '~/util/converters/convertMailboxes';
import useUserRights from '~/hooks/useUserRights';
import UpdateOfficeComponent, {
  UpdateOfficeType,
} from '../UpdateOfficeComponent';
import useSessionHydration from '~/hooks/useSessionHydration';
import useOffices from '~/hooks/useOffices';
import useUsers, { ExpandedUser } from '~/hooks/useUsers';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import Loading from '~/components/atom/Loading';
import type { ListItemForDeletion } from '~/components/bad/Modals/components/ListItemCard';
import { emptyUserForOfficeDeletionListItem } from './constants';
import { isNil } from 'ramda';

export type Props = RouteComponentProps<{ officeId: string }>;

const OfficeDetails: React.FCC<Props> = ({ officeId = '' }) => {
  const { id: accountId } = useCurrentAccount();

  const { update: accountAdminMayUpdate, delete: mayDelete } = useUserRights({
    category: 'Office',
  });
  const { update: officeAdminMayUpdate } = useUserRights({
    category: 'Office',
    entity: {
      type: 'office',
      id: officeId,
    },
  });
  const mayEdit = accountAdminMayUpdate || officeAdminMayUpdate;

  const offices = useOffices({});

  const [, refetchSessionHydration] = useSessionHydration();

  const { data, loading, refetch, startPolling, stopPolling } =
    useGetOfficeQuery({
      variables: { officeId, accountId },
    });

  const officeUsers = useUsers({
    officeIds: [officeId],
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
  });

  if (loading) return <Loading />;

  if (!data) return <AppErrorScreen />;

  const currentOffice = data.getOffice;
  if (!currentOffice) return <AppErrorScreen />;

  const signature = currentOffice.Signature ?? null;

  const hasWorkingAreas =
    !isNil(currentOffice.workingAreas) &&
    (currentOffice.workingAreas?.inclusive.length !== 0 ||
      currentOffice.workingAreas?.exclusive.length !== 0);

  return (
    <UpdateOfficeComponent
      accountId={accountId}
      office={convertOffice(currentOffice)}
      mailboxes={convertMailboxes(currentOffice.Mailboxes)}
      canEdit={mayEdit}
      refetch={() => Promise.all([refetch(), refetchSessionHydration()])}
      startPolling={startPolling}
      stopPolling={stopPolling}
      officeUsersList={[
        ...convertUsersForDeleteOfficeModal(officeUsers),
        emptyUserForOfficeDeletionListItem,
      ]}
      officesLength={offices.length}
      hasWorkingAreas={hasWorkingAreas}
      mayDeleteOffice={mayDelete}
      signature={signature}
    />
  );
};

type Office = Exclude<GetOfficeQuery['getOffice'], undefined | null>;

const convertOffice = (office: Office): UpdateOfficeType => {
  const { id, name, email, phone, img, address } = office;

  return {
    id,
    name,
    email: email == null ? null : email,
    phone: phone == null ? null : phone,
    img: img == null ? null : img,
    address: address == null ? null : address,
  };
};

const convertUsersForDeleteOfficeModal = (
  users: Array<ExpandedUser>,
): Array<ListItemForDeletion> =>
  users.map(user => ({
    id: user.id,
    name: user.name,
    img: 'img' in user ? user.img : undefined,
  }));

export default OfficeDetails;
