import React from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import BottomButtonRow from '~/components/bad/Modals/BottomButtonRow';
import Button from '~/components/atom/Button';
import { Heading3 } from '~/components/atom/Typography';
import Modal from '~/components/organism/ModalsV2/Modal';
import Overlay from '~/components/organism/ModalsV2/Overlay';

const text = {
  closeButtonLabel: 'Sluiten',
};
type Props = {
  /** Title of modal */
  title: string;

  /** Message of modal */
  message: string | React.ReactNode;

  /** Called when the modal is closed */
  onClose: () => void;
};
export const ErrorModalContent: React.FCC<Props> = ({
  title,
  message,
  onClose,
}) => {
  let messageComponent = message;

  if (typeof message === 'string') {
    const messageParagraphs = message.split(/\r?\n/);

    messageComponent = messageParagraphs.map((par, idx) => (
      <p key={`p-${idx}`}>{par}</p>
    ));
  }

  return (
    <SmallModalContainer data-testid={TEST_ID.CONTAINER}>
      <InfoContainer>
        <Heading3 color={{ group: 'danger' }}>{title}</Heading3>
        {messageComponent}
      </InfoContainer>
      <BottomButtonRow>
        <Button
          data-testid={TEST_ID.CLOSE_BUTTON}
          onClick={onClose}
          label={text.closeButtonLabel}
        />
      </BottomButtonRow>
    </SmallModalContainer>
  );
};

const ErrorModal = (props: Props) => (
  <Overlay onClose={props.onClose}>
    <Modal maxWidth="500px" onClose={props.onClose}>
      <ErrorModalContent {...props} />
    </Modal>
  </Overlay>
);

const InfoContainer = styled.div<{}>`
  text-align: left;
  width: 100%;
  ${({ theme }) => `
    padding: ${theme.space('xl')};
  `};
`;

const MODAL_MIN_WIDTH = 150;

const SmallModalContainer = styled.div<{}>`
  ${({ theme }) => css`
    max-width: max-content;
    min-height: ${MODAL_MIN_WIDTH}px;
    box-shadow: ${theme.getTokens().boxShadow.base};
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 0;
`;

export default ErrorModal;
