import React from 'react';
import styled, { css } from 'styled-components';
import VideoJS from '~/components/molecule/VideoJS';

export type Props = {
  autoPlay: boolean;
  controls: boolean;
  loop: boolean;
  muted: boolean;
  src: string;
  mime: string;
};

const VideoPlayer: React.FCC<Props> = ({
  dataTestId,
  autoPlay,
  controls,
  muted,
  loop,
  mime,
  src,
  ...rest
}) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: autoPlay,
    muted,
    loop,
    controls,
    responsive: true,
    fluid: true,
    sources: [
      {
        src,
        type: mime,
      },
    ],
  };

  const handlePlayerReady = player => {
    playerRef.current = player;
  };

  return (
    <Container data-testid={dataTestId} {...rest}>
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    overflow: hidden;
    width: 100%;
    height: 100%;
  `,
);

export default VideoPlayer;
