export enum Options {
  morning = 'Goedemorgen',
  afternoon = 'Goedemiddag',
  evening = 'Goedenavond',
}

const getGreeting = (currentHour: number): Options => {
  if (currentHour >= 17) return Options.evening;
  if (currentHour < 12) return Options.morning;
  if (currentHour >= 12) return Options.afternoon;

  return Options.morning;
};

export default getGreeting;
