import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import icons from '~/components/atom/Icon/components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';

export type Props = {
  dataTestId?: string;
  heading: React.ReactNode;
  icon: keyof typeof icons;
  expanded: boolean;
  indicator?: {
    appearance: 'danger' | 'warning';
  };
  onClick?: () => void;
};

const AsideHeader: React.FCC<Props> = ({
  dataTestId,
  heading,
  icon,
  onClick,
  expanded,
  indicator,
  ...rest
}) => (
  <Container
    dataTestId={dataTestId}
    align="center"
    justification="space-between"
    onClick={onClick}
    nonResponsive
    {...rest}
  >
    <JustificationContainer align="center" width="100%">
      <ScaledIcon name={icon} margin={[null, 's', null, null]} />
      <JustificationContainer
        align="center"
        justification="space-between"
        gap="l"
        width="100%"
        padding={[null, 'xs', null, null]}
      >
        <Heading4 withoutMargin variant={Variant.primary}>
          {heading}
        </Heading4>
        {indicator && <Indicator $appearance={indicator.appearance} />}
      </JustificationContainer>
    </JustificationContainer>
    <Icon name="chevron-right" clockwise={expanded ? 90 : 0} />
  </Container>
);

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    cursor: pointer;
    padding: ${theme.space('m')};
  `,
);

const ScaledIcon = styled(Icon)(
  ({ theme }) => css`
    font-size: ${theme.fs('l')};
  `,
);

const Indicator = styled.div<{ $appearance: 'danger' | 'warning' }>(
  ({ theme, $appearance }) => css`
    border-radius: ${theme.getTokens().border.radius.full};
    background: ${theme.color($appearance)};
    height: ${theme.space('m')};
    width: ${theme.space('m')};
  `,
);

export default AsideHeader;
