import React from 'react';
import {
  ReactEditor,
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from 'slate-react';
import styled, { css } from 'styled-components';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { VariableElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import TEST_ID from './index.testid';
import { BAD_VARIABLE_NAME } from '~/components/organism/PluginsEditor/utils/flows/convertVariablesInHtml';
import ActionLabelAsText from '~/components/page/Automation/v2/components/Builder/components/ActionLabelAsText';

export type Props = RenderElementProps & {
  element: VariableElement;
};

const Variable: React.FCC<Props> = ({ attributes, element, children }) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  return (
    <span
      {...attributes}
      draggable
      data-testid={TEST_ID.CONTAINER}
      onDragStart={() => ReactEditor.blur(editor)}
    >
      {/* A hacky way to position the Variable hovering toolbar correctly at the end
      and beginning of new lines. We add children twice as spacers */}
      {children}
      {element.variableName === BAD_VARIABLE_NAME ? (
        <BadVariableContainer contentEditable={false} $active={active}>
          Foute variabele
        </BadVariableContainer>
      ) : (
        <Container contentEditable={false} $active={active}>
          <ActionLabelAsText str={element.variableName || null} />
        </Container>
      )}
      {children}
    </span>
  );
};

type ContainerProps = { $active: boolean };
const Container = styled.span<ContainerProps>(
  ({ $active, theme }) => css`
    /* Used fixed sizes here because the element needs to be perfectly aligned with the text */
    border-radius: ${theme.getTokens().border.radius.s};
    padding: 4px 8px;
    font-size: 12px;
    margin: 0px 1px;
    transition: 0.3s all ease-in-out;
    box-sizing: border-box;
    cursor: pointer;
    color: ${$active ? theme.color('white') : theme.color('primary', 'light')};
    background-color: ${$active ? theme.color('primary') : 'transparent'};
    border: ${theme.getTokens().border.width.s} solid
      ${$active ? 'transparent' : theme.color('primary', 'light')};

    &:hover {
      background-color: ${({ theme }) => theme.color('primary')};
      color: ${({ theme }) => theme.color('white')};
    }
  `,
);

const BadVariableContainer = styled(Container)<ContainerProps>(
  ({ theme, $active }) => css`
    color: ${$active ? theme.color('white') : theme.color('danger')};
    background-color: ${$active ? theme.color('danger') : 'transparent'};
    border: ${$active
      ? '1px solid transparent'
      : `1px solid ${theme.color('danger')}`};

    &:hover {
      background-color: ${({ theme }) => theme.color('danger', 'dark')};
      color: ${({ theme }) => theme.color('white')};
    }
  `,
);

export default {
  /** We pass a random element name for variables so that we can render this component when deserializing */
  nodeName: 'DHVARIABLE',
  renderComponent: props => <Variable {...props} />,
  serialize,
  deserialize: el => ({
    type: ELEMENTS.VARIABLE,
    mappingId: el.getAttribute('dhmappingid'),
    variableName: el.getAttribute('dhvariablename'),
    variableType: el.getAttribute('dhvariabletype'),
    variableInfo: JSON.parse(
      el.getAttribute('dhvariableinfo').replace(/'/g, '"'),
    ),
  }),
};
