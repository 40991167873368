import React from 'react';
import styled, { css } from 'styled-components';
import {
  BulletPoint,
  ComponentUiSumUp,
} from '~/components/template/ConversionPage/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Markdown from '~/components/molecule/Markdown';
import { Heading3, Heading4, Variant } from '~/components/atom/Typography';
import getMarkdownOptions from '../../utils/getMarkdownOptions';
import Icon from '~/components/atom/Icon';

export type Props = ComponentUiSumUp & {};

const SumUp: React.FCC<Props> = ({ dataTestId, ...props }) => (
  <JustificationContainer
    gap="l"
    justification="space-between"
    dataTestId={dataTestId}
  >
    <Spacer>
      <Heading3>{props.title}</Heading3>
    </Spacer>
    <Spacer>
      <JustificationContainer wrap="wrap" gap="l" direction="row">
        {props.points.map(bullet => (
          <Bullet {...bullet} key={`bullet_${bullet.id}`} />
        ))}
      </JustificationContainer>
    </Spacer>
  </JustificationContainer>
);

const Bullet: React.FC<BulletPoint> = ({ body, title }) => (
  <BulletContainer>
    <JustificationContainer
      align="center"
      justification="start"
      gap="base"
      margin={[null, null, 'm', null]}
    >
      <IconContainer>
        <Icon name="check" />
      </IconContainer>
      <Heading4 variant={Variant.primary} withoutMargin>
        {title}
      </Heading4>
    </JustificationContainer>
    <Markdown value={body} options={getMarkdownOptions()} />
  </BulletContainer>
);

const BulletContainer = styled.div`
  max-width: 30%;
  width: 100%;
`;

const IconContainer = styled.span(
  ({ theme }) => css`
    background-color: ${theme.color('accent')};
    color: ${theme.color('accent', 'text')};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: ${theme.getTokens().border.radius.full};
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,
);

const Spacer = styled.div`
  &:first-child {
    flex: 0 0 33%;
  }
  &:last-child {
    flex: 0 0 66%;
  }
`;

export default SumUp;
