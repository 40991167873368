import { memoize } from 'underscore';

import {
  ContactStatus,
  SortDirection,
  FlowV2_Statistics,
  GetFlowsV2QueryVariables,
  GetFlowsV2Query,
  FlowData___ConditionRepresentationFragment,
  FlowAction,
} from '~/graphql/types';
import { ascend, descend, prop } from 'ramda';
import markedForDeletionContactList from '~/components/page/Contacts/util/markedForDeletionContactList';
import { SortSettings } from '~/hooks/useSortSettings';
import { IBuilderContext } from '../../v2/components/Builder/context';
import getSubject from '../../v2/components/UpdateAction/components/Selector/utils/getSubject';
import { getCondition } from '../../v2/components/UpdateAction/components/Selector/utils/getConditions';
import getLabelForRep from '../../v2/components/UpdateAction/components/ConditionEditorV2/utils/getLabelForRep';

export const isDeleted = (contact: {
  status: ContactStatus | null | undefined;
  id: string;
}): boolean => {
  const markedForDeletionContactIds =
    markedForDeletionContactList.getMarkedForDeletionContacts();

  return (
    (contact.status && contact.status === 'DELETING') ||
    markedForDeletionContactIds.includes(contact.id)
  );
};
export type FlowListData = {
  id: string;
  name: string;
  description?: string | null;
  enabled: boolean;
  createdDate: Date;
  lastModified: Date | null;
  Statistics: Omit<FlowV2_Statistics, 'leadScoreAverage'> | null;
};

export const composeFlowListData = (
  items: GetFlowsV2Query['getFlowsV2']['items'] | null | undefined,
  variables: GetFlowsV2QueryVariables,
  version: string,
  sortSettings: SortSettings,
  iBuilderContext: IBuilderContext,
): Array<FlowListData> => {
  if (items == null) return [];

  // @ts-ignore I couldn't explain TS what we're doing here, so ignore for now.
  const sorter = (sortSettings.desc ? descend : ascend)(prop(sortSettings.id));

  return items
    .map(
      ({
        name,
        description,
        enabled,
        id,
        createdDate,
        lastModified,
        Statistics,
        maximumFlowRun,
        StartAction,
      }) => ({
        id,
        name,
        description,
        enabled,
        createdDate: new Date(createdDate),
        lastModified: lastModified?.date ? new Date(lastModified.date) : null,
        Statistics: Statistics || null,
        trigger: {
          actionId: StartAction?.id,
          conditionList: StartAction?.conditionList ?? [],
          iBuilderContext: {
            ...iBuilderContext,
            flowBlueprintId: id,
            initialFlow: {
              flowName: name,
              flowDescription: description,
              enabled: enabled,
              maximumFlowRun: maximumFlowRun,
              actions: [],
            },
          },
        },
      }),
    )
    .sort((a, b) => {
      const getFieldValue = value => {
        if (sortSettings.id === 'Statistics') {
          return value.completedFlowInstancesTotal;
        }

        // Sort on Trigger
        if (sortSettings.id === 'trigger') {
          const condition = value.conditionList.conditions[0];
          const representations: Array<FlowData___ConditionRepresentationFragment> | null =
            condition.__typename === 'Flow___SubjectFieldCondition'
              ? getSubject(
                  { type: condition.type, typeId: condition.typeId },
                  iBuilderContext.opts.subjectMap,
                )?.fields.find(({ key }) => condition.field === key)
                  ?.representation ?? null
              : getCondition(condition?.type, iBuilderContext.opts.conditionMap)
                  ?.representation ?? null;

          const asOne = (representations ?? [])
            ?.map(rep =>
              getLabelForRep(
                {
                  representation: rep,
                  condition,
                  ctx: {
                    action: FlowAction.Start,
                    type: 'trigger',
                  },
                },
                {
                  conditionMap: iBuilderContext.opts.conditionMap,
                  directoryMap: iBuilderContext.opts.directoryMap,
                  instanceMap: iBuilderContext.instanceMap,
                  primitiveInputMap: iBuilderContext.primitiveInputMap,
                  subjectMap: iBuilderContext.opts.subjectMap,
                },
              ),
            )
            .map(labelOutput =>
              'result' in labelOutput ? labelOutput.result : labelOutput.error,
            )
            .join(';;');

          return asOne;
        }

        return value;
      };

      const _a = {
        ...a,
        [sortSettings.id]: getFieldValue(a[sortSettings.id]),
      };
      const _b = {
        ...b,
        [sortSettings.id]: getFieldValue(b[sortSettings.id]),
      };

      return sorter(_a, _b);
    });
};

const memoizedComposeFlowListData = memoize(
  composeFlowListData,
  (
    items: Array<any>,
    variables: GetFlowsV2QueryVariables,
    version: string,
    sortSettings: SortSettings,
  ) => {
    const result = `${version}*@@*${items.length}*@@*${sortSettings.id}*@@*${
      sortSettings.desc ? SortDirection.Desc : SortDirection.Asc
    }*@@*${variables.nextToken || 'null'}`;

    return result;
  },
);

export default memoizedComposeFlowListData;
