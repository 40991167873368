import styled, { css } from 'styled-components';
import TextButton from '../TextButton';
import type { BaseColor } from '~/theme/System/tokens';
import type { Size } from '~/styles/constants';

const hitAreaMap: { [key in Size]: string } = {
  small: '0.4rem',
  medium: '0.5rem',
  large: '0.6rem',
};

/**
 * This component shall be used sparingly. It removes the padding
 * from the TextButton and gives it a larger click area on hover.
 *
 * Only use this when you do not want the button to take up space
 * e.g. when it is used in an input or dropdown
 */
const NoSpaceTextButton = styled(TextButton)<{
  appearance: BaseColor;
  size?: Size;
}>(({ theme, appearance = 'primary', size = 'medium' }) => {
  const colorGroup = appearance === 'secondary' ? 'success' : appearance;
  const hitAreaExpansion = hitAreaMap[size];

  return css`
    padding: 0;
    min-width: max-content;
    position: relative;
    z-index: 1;

    /* Increase the click area of the button */
    &::before {
      content: '';
      position: absolute;
      top: -${hitAreaExpansion};
      left: -${hitAreaExpansion};
      right: -${hitAreaExpansion};
      bottom: -${hitAreaExpansion};
      border-radius: ${theme.getTokens().border.radius.s};
      z-index: -1;
    }

    &:hover {
      color: ${theme.color('white')};
      &::before {
        background-color: ${theme.color(colorGroup, 'light')};
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  `;
});

export default NoSpaceTextButton;
