import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useEntityWarnings, {
  Warning as WarningType,
  Warnings,
} from '~/hooks/useEntityWarnings';
import filterWarnings from './utils/filterWarnings';
import snoozeWarning from './utils/snoozeWarning';
import Warning from '../Warning';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { UseTransitionProps, useTransition, animated } from 'react-spring';
import { ANIMATION_CONFIG } from '~/styles/constants';

const WarningsContainer: React.FCC<{}> = () => {
  const sessionHydrationWarnings = useEntityWarnings();
  const [warnings, setWarnings] = useState<Warnings>([]);

  useEffect(() => {
    const filteredWarnings = filterWarnings(sessionHydrationWarnings);
    setWarnings(filteredWarnings);
  }, [sessionHydrationWarnings]);

  const snooze = (warning: WarningType) => {
    snoozeWarning(warning);
    const remainingWarnings = warnings.filter(
      item => item.hash !== warning.hash,
    );
    setWarnings(remainingWarnings);
  };

  const transitionProps: UseTransitionProps = {
    from: { transform: 'translate(0, -40px)', opacity: 0 },
    enter: { transform: 'translate(0, 0px)', opacity: 1 },
    leave: { transform: 'translate(0, -40px)', opacity: 0 },
    config: ANIMATION_CONFIG.config,
    trail: 100,
    keys: item => item.hash,
  };
  const transitions = useTransition(warnings, transitionProps);

  if (warnings.length === 0) return null;

  return (
    <JustificationContainer
      margin={['xl', null, null, null]}
      gap="xxs"
      direction="column"
    >
      {transitions(
        (style, warning, { key }) =>
          warning && (
            <AnimationContainer style={style} key={key}>
              <Warning onClose={snooze} warning={warning} />
            </AnimationContainer>
          ),
      )}
    </JustificationContainer>
  );
};

const AnimationContainer = styled(animated.div)`
  width: 100%;
`;

export default WarningsContainer;
