import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'ramda';
import { useRecoilState } from 'recoil';
import { Helmet as MetaTags } from 'react-helmet';
import styled, { css } from 'styled-components';
import { Form } from 'react-final-form';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import WidgetPreview from '../WidgetPreview';
import Catalog from '~/Catalog';
import type { GroupLookup } from '~/components/organism/ToggleAccordion';
import Apps from '../Apps';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import URLBuilder from '../UrlBuilder';
import Design from '../Design';
import countWidgetChanges from '../../utils/countWidgetChanges';
import dataToWidgetValues from '../../utils/dataToWidgetValues';
import useDHFlag from '~/hooks/useDHFlag';
import currentWidgetState, { widgetIssues } from '../../state/widgetState';
import { navLinks, widgetRoutes } from '../../constants';
import getIssuesForMasterDetail from '../../utils/getIssuesForMasterDetail';
import { Heading2 } from '~/components/atom/Typography';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import NewSaveBar from '~/components/organism/NewSaveBar';
import MasterDetail from '~/components/template/MasterDetail';
import TEST_ID_MASTER_DETAIL from '~/components/template/MasterDetail/index.testid';
import Settings from '../Settings';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import {
  useUpdateWidgetSettingsMutation,
  type GetWidgetSettingsQuery,
  type SessionHydrationAccountFieldsFragment,
  type WidgetSettingsAppearances,
  type WidgetSettingsLogo,
  type WidgetSettingsPositions,
  type WidgetSettingsStyles,
  type AppConfigurationFragment,
  type WidgetSettings__Input,
} from '~/graphql/types';
import { pinnedAppsState, type PinnedAppsMap } from '../../state/pinnedApps';
import getPinnedApps from '../../utils/getPinnedApps';
import getSelectedItemNames from '../../utils/getSelectedItemNames';
import hasValue from '~/util/hasValue';
import getAppsConfigurationInput from '../../utils/getAppsConfigurationInput';

const text = {
  title: 'Webwinkel widget',
  settings: 'Algemeen',
  design: 'Ontwerp',
  apps: 'Apps',
  linkBuilder: 'Links maken',
  saveSuccess: 'Wijzigingen zijn opgeslagen',
};

export type WidgetValues = {
  settings: {
    googleAnalytics4?: string | null;
  };
  design: {
    style: WidgetSettingsStyles;
    positions: WidgetSettingsPositions;
    appearances: WidgetSettingsAppearances;
    zIndex: number;
    logo?: WidgetSettingsLogo | null;
    highlightImage?: GetWidgetSettingsQuery['getWidgetSettings']['highlightImage'];
  };
  apps: {
    appsGroupLookup: GroupLookup<{ appConfiguration: string }>;
    pinnedApps: PinnedAppsMap;
  };
};

export type Props = {
  account: SessionHydrationAccountFieldsFragment;
  data: GetWidgetSettingsQuery['getWidgetSettings'];
};

type PageKeys = keyof WidgetValues;

type FieldKeys<T extends PageKeys> = keyof WidgetValues[T];

export type onChangeArgs<T extends PageKeys> = {
  pageKey: T;
  fieldKey: FieldKeys<T>;
  newValue: WidgetValues[T][FieldKeys<T>];
};

const WidgetContent: React.FCC<Props & RouteComponentProps> = ({
  account,
  data,
}) => {
  const addToast = useAddToast();
  const isDev = useDHFlag('is-developer');

  useEffect(() => {
    if (isDev) {
      if (!navLinks.find(link => link.to === widgetRoutes.linkBuilder)) {
        navLinks.push({
          type: 'link',
          to: widgetRoutes.linkBuilder,
          icon: 'link',
          name: text.linkBuilder,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errors, setErrors] = useRecoilState(widgetIssues);

  const [initialValues, setInitialValues] = useState<WidgetValues | null>(null);
  const [currentValues, setCurrentValues] = useRecoilState(currentWidgetState);

  const [pinnedApps, setPinnedApps] = useRecoilState(pinnedAppsState);

  /** Forces image inputs to rerender after cancelling changes */
  const [version, setVersion] = useState(0);

  useEffect(() => {
    if (data) {
      const initialWidgetValues = dataToWidgetValues({
        data,
      });
      setInitialValues(initialWidgetValues);
      setCurrentValues(initialWidgetValues);
      setPinnedApps(initialWidgetValues.apps.pinnedApps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onChange = <T extends PageKeys>({
    pageKey,
    fieldKey,
    newValue,
  }: onChangeArgs<T>) => {
    setCurrentValues((prev: WidgetValues) => ({
      ...prev,
      [pageKey]: { ...prev[pageKey], [fieldKey]: newValue },
    }));
  };

  useEffect(() => {
    if (pinnedApps) {
      onChange({
        pageKey: 'apps',
        fieldKey: 'pinnedApps',
        newValue: pinnedApps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedApps]);

  const onCancel = () => {
    setVersion(prev => prev + 1);
    setCurrentValues(initialValues);
    setErrors([]);

    if (initialValues) {
      setPinnedApps(initialValues.apps.pinnedApps);
    }
  };

  const [updateWidgetSettings, { loading }] = useUpdateWidgetSettingsMutation();

  const onAppsGroupLookupChange = useMemo(
    () =>
      ({ groupId, groupLookup }) => {
        setCurrentValues((prevState: WidgetValues) => {
          const { apps } = prevState;
          const { appsGroupLookup } = apps;

          const updatedGroupLookup = {
            ...appsGroupLookup,
            [groupId]: {
              ...appsGroupLookup[groupId],
              ...groupLookup,
            },
          };

          const updatedApps = {
            ...apps,
            appsGroupLookup: updatedGroupLookup,
          };

          return {
            ...prevState,
            apps: updatedApps,
          };
        });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onFormSubmit = async () => {
    if (currentValues) {
      const widgetUpdates = widgetValuesToInput(
        currentValues,
        data.appsConfiguration,
      );
      const { data: updatedData, errors: updatedWidgetErrors } =
        await updateWidgetSettings({
          variables: {
            accountId: account.id,
            widgetSettings: widgetUpdates,
          },
        });

      const hasErrors = updatedWidgetErrors && updatedWidgetErrors.length > 0;

      if (hasErrors) {
        addToast([
          formatToastMessage(Catalog.genericUnknownErrorMessage, 'danger'),
        ]);
        return;
      }

      if (!hasErrors && updatedData) {
        const updatedWidgetSettings = updatedData.updateWidgetSettings;
        setInitialValues(prev => ({
          ...prev,
          ...dataToWidgetValues({
            data: updatedWidgetSettings,
          }),
        }));
        setCurrentValues(prev => ({
          ...prev,
          ...dataToWidgetValues({
            data: updatedWidgetSettings,
          }),
        }));
        const updatedPinnedApps = getPinnedApps(
          updatedWidgetSettings.appsConfiguration,
        );
        setPinnedApps(updatedPinnedApps);
        addToast([formatToastMessage(text.saveSuccess, 'success')]);
      }
    }
  };

  if (currentValues === null || initialValues === null || pinnedApps === null)
    return <DatHuisLoading />;

  const changes = countWidgetChanges(initialValues, currentValues);

  const selectedAppItems = getSelectedItemNames(
    currentValues.apps.appsGroupLookup,
  );

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.title}</title>
      </MetaTags>
      <Heading2 color={{ group: 'primary' }} margin={[null, null, 'xl', null]}>
        {text.title}
      </Heading2>

      {/* We handle form submit from the SaveBar, current Form will be removed after WidgetPreview refactoring */}
      <Form onSubmit={noOp} initialValues={initialValues}>
        {({ form }) => (
          <form
            onSubmit={event => {
              if (event) {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          >
            <SaveBar
              loading={loading}
              changes={changes}
              onSave={onFormSubmit}
              onCancel={() => {
                onCancel();
                form.reset();
              }}
              messages={errors}
              disabled={!changes}
            />

            <MasterDetail
              issues={getIssuesForMasterDetail(errors)}
              basePath="/-/"
              dataTestId={TEST_ID_MASTER_DETAIL.CONTAINER}
              navbar={navLinks}
            >
              <Settings
                {...currentValues.settings}
                onChange={onChange}
                path="/"
              />
              <Design
                path="/design"
                {...currentValues.design}
                version={version}
                onChange={onChange}
              />
              <Apps
                path="/apps"
                appsGroupLookup={currentValues?.apps.appsGroupLookup}
                onChange={onAppsGroupLookupChange}
                pinnedApps={pinnedApps}
                appsAreEnabled={!isEmpty(data.appsConfiguration)}
              />
              <URLBuilder path="/link-builder" />
            </MasterDetail>
            {selectedAppItems.length > 0 &&
              pinnedApps &&
              initialValues.apps.pinnedApps && (
                <WidgetPreview
                  defaultConfiguration={data.defaultConfigValues}
                  pinnedApps={pinnedApps}
                  initialSettings={initialValues}
                  groupLookup={currentValues.apps.appsGroupLookup}
                  initiallyPinnedApps={initialValues.apps.pinnedApps}
                />
              )}
          </form>
        )}
      </Form>
      <Spacer />
    </ContentContainerDefault>
  );
};

const noOp = () => {};

const widgetValuesToInput = (
  widgetValues: WidgetValues,
  appsConfiguration: Array<AppConfigurationFragment>,
): WidgetSettings__Input => {
  const appsConfigurationInput = getAppsConfigurationInput(
    widgetValues.apps.pinnedApps,
    widgetValues.apps.appsGroupLookup,
    appsConfiguration,
  );

  const widgetSettingsInput: WidgetSettings__Input = {
    googleAnalytics4: hasValue(widgetValues.settings.googleAnalytics4)
      ? widgetValues.settings.googleAnalytics4
      : null,
    positions: {
      desktop: widgetValues.design.positions.desktop,
      mobile: widgetValues.design.positions.mobile,
    },
    appearances: {
      desktop: widgetValues.design.appearances.desktop,
      mobile: widgetValues.design.appearances.mobile,
    },
    style: {
      primary: {
        background: widgetValues.design.style.primary.background,
        color: widgetValues.design.style.primary.color,
      },
      secondary: {
        background: widgetValues.design.style.secondary.background,
        color: widgetValues.design.style.secondary.color,
      },
    },
    zIndex: Number(widgetValues.design.zIndex),
    highlightImage: widgetValues.design.highlightImage
      ? {
          s3key: widgetValues.design.highlightImage.s3key,
        }
      : null,
    logo: widgetValues.design.logo?.s3key
      ? { s3key: widgetValues.design.logo.s3key }
      : null,
    appsConfiguration: appsConfigurationInput,
  };

  return widgetSettingsInput;
};

const SaveBar = styled(NewSaveBar)(
  ({ theme }) => css`
    margin-bottom: ${theme.space('l')};
  `,
);

const Spacer = styled.div`
  margin-bottom: 14rem;
`;

export default WidgetContent;
