import styled from 'styled-components';

export const BlockContainer = styled.div<{}>`
  ${({ theme }) => `
    padding: ${theme.space('xxs')};
    box-shadow: ${theme.getTokens().boxShadow.card};
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};
    margin-bottom: ${theme.space('l')};


    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
  `};
`;

export const ContactInformationBlockContainer = styled(BlockContainer)<{}>`
  ${({ theme }) => `
    padding: ${theme.space('m')};
  `};
`;
