import cleanedFilename from '~/util/cleanedFilename';

export default {
  CONTAINER: `${cleanedFilename(__filename)}-container`,
  IMPORT_MULTIPLE_CONTACTS_DROPDOWN: `${cleanedFilename(
    __filename,
  )}-import-multiple-contacts-dropdown`,
  ADD_SINGLE_CONTACT_BUTTON: `${cleanedFilename(
    __filename,
  )}-add-single-contact-button`,
  START_FLOW_DROPDOWN: `${cleanedFilename(__filename)}-start-flow-dropdown`,
  CONFIRM_MODAL: `${cleanedFilename(__filename)}-confirm-modal`,
};
