import React, { useState } from 'react';

import TEST_ID from './InsertHTMLModal.testid';

import styled, { css } from 'styled-components';
import CodeEditor from '~/components/organism/CodeEditor';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import { Body } from '~/components/atom/Typography';
import type { IconType } from '~/components/atom/Icon';

const text = {
  modalHeader: 'HTML invoegen',
  modalDescription: 'Voeg HTML toe voor een handtekening of nieuwsbrief.',
  submitLabel: 'Invoegen',
  cancelLabel: 'Annuleer',
};

type Props = {
  /** When the modal is closed */
  onClose: () => void;

  /** Called on confirm */
  onSubmit: (html: string) => void;

  /** Initial html */
  initialHtml?: string;

  /** Modal header */
  header?: string;

  /** Modal description */
  description?: string | React.ReactNode;

  /** Height of the code editor. Defaults to 300px */
  editorHeight?: string;

  /** Header icon */
  icon?: IconType;
};
const InsertHTMLModal: React.FCC<Props> = ({
  onClose,
  onSubmit,
  initialHtml,
  header,
  description,
  editorHeight,
  icon = 'triangle',
}) => {
  const [html, setHTML] = useState(initialHtml || '');

  return (
    <Overlay
      onClose={onClose}
      dataTestId={TEST_ID.CONTAINER}
      root="insert-html"
    >
      <Dialog
        header={header || text.modalHeader}
        icon={icon}
        body={
          <>
            <Body>{description || text.modalDescription}</Body>
            <EditorContainer>
              <CodeEditor
                value={html}
                onChange={value => setHTML(value)}
                autoFocus
                options={{ autofocus: true }}
                height={editorHeight || '300px'}
                width="100%"
                wrapEnabled
              />
            </EditorContainer>
          </>
        }
        onConfirm={() => onSubmit(html)}
        onClose={onClose}
        confirmAction={{ label: text.submitLabel, dataTestId: TEST_ID.SUBMIT }}
        cancelAction={{ label: text.cancelLabel }}
      />
    </Overlay>
  );
};

const EditorContainer = styled.div<{}>(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.space('base')} 0;
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
  `,
);

export default InsertHTMLModal;
