import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import TEST_ID from './index.testid';
import TextButton from '~/components/atom/TextButton';

import type { Size } from '~/styles/constants';

const text = {
  uploading: 'Uploaden...',
  uploadFile: 'Upload een bestand',
};
export type Props = {
  onUpload: (files: FileList) => void;
  uploading: boolean;
  disabled?: boolean;
  size?: Size;
};
const ImageUploadTextButton: React.FCC<Props> = ({
  onUpload,
  uploading,
  disabled,
  size = 'large',
}) => {
  const fileUploader = useRef<any>(null);
  const _onUpload = useCallback(
    e => {
      if (e.target != null && e.target.files?.length) {
        onUpload(e.target.files);
      }
    },
    [onUpload],
  );

  return (
    <Container data-testid={TEST_ID.CONTAINER}>
      <StyledTextButton
        withoutPadding
        onClick={() => {
          if (disabled) return;
          if (fileUploader.current) fileUploader.current.click();
        }}
        disabled={uploading || disabled}
        label={uploading ? text.uploading : text.uploadFile}
        icon="upload"
        size={size}
        dataTestId={TEST_ID.UPLOAD_BUTTON}
      />

      <input
        hidden
        ref={ref => (fileUploader.current = ref)}
        type="file"
        accept="image/*"
        onChange={_onUpload}
        name="text-button-image-upload-input"
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    margin-bottom: ${theme.space('m')};
    ${theme.mq.lessThan('tabletLandscape')`
      justify-content: center;
    `}
  `}
`;

const StyledTextButton = styled(TextButton)<{}>(
  () => css`
    text-decoration: none;
  `,
);

export default ImageUploadTextButton;
