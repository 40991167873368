import React from 'react';
import styled, { css } from 'styled-components';
import { ContactInformationBlockContainer } from '../ContactBlock.style';

import TEST_ID from './index.testid';
import LeadScoreDisplayer from '~/components/molecule/LeadScoreDisplayer';
import Icon from '~/components/atom/Icon';
import ContactInformationSettings from '../ContactInformationSettings';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import { Heading3, Heading4, Variant } from '~/components/atom/Typography';
import { EmailStatusType } from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import BouncedEmailCTA from './components/BouncedEmailCTA';

const text = {
  contactDetailsTitle: 'Contactgegevens',
  unsubscribed: 'Uitgeschreven',
};
type Props = {
  contactId: string;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  blockMarketing?: boolean | null;
  leadScore: number;
  emailStatus?: EmailStatusType | null;
};
const ContactInformationBlock: React.FCC<Props> = ({
  contactId,
  name,
  phone,
  email,
  blockMarketing,
  leadScore,
  emailStatus,
}) => {
  const viewingModeProps = useViewingModeProps();

  return (
    <ContactInformationBlockContainer data-testid={TEST_ID.CONTAINER}>
      <JustificationContainer
        width="100%"
        align="center"
        justification="space-between"
      >
        <TitleContainer>
          <Heading3
            fontWeight="regular"
            withoutMargin
            variant={Variant.primary}
            {...viewingModeProps}
          >
            {name}
          </Heading3>
        </TitleContainer>

        <SettingsWithLeadScoreContainer>
          <LeadScoreDisplayer leadScore={leadScore} />
          <StyledContactInformationSettings
            contactId={contactId}
            blockMarketing={blockMarketing}
          />
        </SettingsWithLeadScoreContainer>
      </JustificationContainer>
      {blockMarketing && (
        <TagContainer data-testid={TEST_ID.SUBSCRIBE_TAG}>
          {text.unsubscribed}
        </TagContainer>
      )}

      <SubsectionTitle
        padding={['m', null, 'xxs', null]}
        fontWeight="regular"
        variant={Variant.primary}
      >
        {text.contactDetailsTitle}
      </SubsectionTitle>
      <SectionField>
        <IconWrapper>
          <Icon name="phone" />
        </IconWrapper>
        <span {...viewingModeProps}>{phone}</span>

        <IconWrapper>
          <Icon name="mail" />
        </IconWrapper>
        <span {...viewingModeProps}>{email}</span>
      </SectionField>
      <BouncedEmailCTA contactId={contactId} emailStatus={emailStatus} />
    </ContactInformationBlockContainer>
  );
};

const StyledContactInformationSettings = styled(
  ContactInformationSettings,
)<any>`
  display: flex;
  justify-self: end;
`;

const SettingsWithLeadScoreContainer = styled.div<{}>`
  display: flex;
`;

const TitleContainer = styled.div<{}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TagContainer = styled.div<{}>`
  text-align: center;
  padding: 5px 7px 4px;
  width: 100px;

  ${({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    color: ${theme.color('primary', 'light')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('primary', 'light')};
    font-size: ${theme.fontSize('s')};
    margin-top: ${theme.space('xxs')};
  `};
`;

const IconWrapper = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('success')};
    margin-right: ${theme.space('xxs')};
  `,
);

const SectionField = styled.div<{}>`
  display: grid;
  grid-template-columns: 40px auto;
  ${({ theme }) => css`
    grid-row-gap: ${theme.space('s')};
  `};
`;

const SubsectionTitle = styled(Heading4)<{}>(
  ({ theme }) => css`
    border-bottom: solid 1px ${theme.color('grey', 'light')};
  `,
);

export default ContactInformationBlock;
