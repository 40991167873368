import React, { ReactElement, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  ActivityFieldsFragment,
  SessionHydrationAccountFieldsFragment,
  TaskListTaskFieldsFragment,
} from '~/graphql/types';
import Catalog from '~/Catalog';
import ActivityTabs, {
  ActivityTabProps,
} from '~/components/organism/ActivityTabs';
import CreateNewTaskTab from '~/components/organism/ActivityTabs/components/CreateNewTaskTab';
import useUpdateTimelineFn from '~/hooks/useUpdateTimelineFn';
import EventTimelineV2 from '~/components/template/EventTimelineV2';
import TaskModalContext from '~/components/page/Tasks/context/TaskModalContext';
import type { Task } from '~/components/page/Tasks/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import DescriptionTab from '~/components/organism/ActivityTabs/components/DescriptionTab';
import LogActivityTab from '~/components/organism/ActivityTabs/components/LogActivityTab';
import SendMessageTab from '~/components/organism/ActivityTabs/components/SendMessageTab';
import ContactInformation from '../ContactInformation';

export type ModalProps = {
  /** Task details to fill up the task state with, the rest will be queried based on id.
   * If null we will create a new task.
   */
  initialTaskDetails: Task | null;

  /** When closing the modal */
  onClose: () => void;

  /** Get access to account data form context */
  account: SessionHydrationAccountFieldsFragment;

  /** Office id for default dropdown value in insert task modal */
  selectedInFilterOfficeId: string | null;

  /** User id for default dropdown value in insert task modal */
  selectedInFilterUserId: string | null;
};

type Props = ModalProps & {
  /** Handling access to close modal if data updated. Using only in TaskModalComponent */
  handleToggleClose: (canClose: boolean) => void;

  updateTimelineFn?: (newActivity: ActivityFieldsFragment) => void;
};

const text = {
  createNewTaskLabel: Catalog.addTaskTabLabel,
  updateDescriptionLabel: Catalog.descriptionTabLabel,
  addLogTabLabel: Catalog.addLogTabLabel,
  sendEmailLabel: 'Verstuur e-mail',
};

const TaskModalContent: React.FC<Props> = ({
  initialTaskDetails,
  onClose,
  account,
  selectedInFilterOfficeId,
  selectedInFilterUserId,
  handleToggleClose,
  updateTimelineFn,
}) => {
  const isNew = !initialTaskDetails;
  const [taskDetails, setTaskDetails] = useState<Task | null>(
    initialTaskDetails,
  );
  const [contactError, setContactError] = useState<string | undefined>('');

  const updateTaskDetails = (updatedTaskDetails: Task) => {
    setTaskDetails(prevTaskDetails => ({
      ...prevTaskDetails,
      ...updatedTaskDetails,
    }));
  };

  const onSuccess = (udpdatedTask: TaskListTaskFieldsFragment) => {
    if (updateTimelineFn && typeof updateTimelineFn === 'function') {
      updateTimelineFn(udpdatedTask);
    }
  };

  let sendMessageTabComponent: ReactElement<ActivityTabProps> | null = null;

  if (taskDetails && taskDetails.contactId && taskDetails.Contact) {
    const { name: contactName, email: contactEmail } = taskDetails.Contact;
    sendMessageTabComponent = (
      <SendMessageTab
        label={text.sendEmailLabel}
        datatestId="send-message"
        handleCloseModal={onClose}
        contact={{
          id: taskDetails.contactId,
          name: contactName,
          email: contactEmail,
        }}
        onSuccess={onSuccess}
      />
    );
  }

  const createNewTaskTabComponent = (
    <CreateNewTaskTab
      handleSetContactError={setContactError}
      isNew={isNew}
      label={text.createNewTaskLabel}
      datatestId="add-task"
      key="add-task"
      handleCloseModal={onClose}
      accountId={account.id}
      taskDetails={taskDetails}
      contactId={taskDetails?.contactId ?? ''}
      defaultAssignedOfficeId={selectedInFilterOfficeId}
      defaultAssignedUserId={selectedInFilterUserId}
      onSuccess={onSuccess}
    />
  );

  return (
    <TaskModalContext.Provider
      value={{
        taskDetails,
        isNew,
        updateTaskDetails,
        handleToggleClose,
        closeModal: onClose,
        accountId: account.id,
      }}
    >
      <Container
        width="100%"
        data-testid="task-modal"
        data-objectid={
          isNew || !taskDetails ? 'modal-new' : `modal-${taskDetails.id}`
        }
      >
        <ContactContainer
          backgroundColor={{ group: 'tertiary', variant: 'light' }}
          padding={['xl', null, null, null]}
        >
          <ContactInformation error={contactError} />
        </ContactContainer>
        <TaskContainer
          padding={[!isNew ? 'm' : null, null, null, null]}
          direction="column"
          width="100%"
        >
          <JustificationContainer width="100%">
            {isNew || !taskDetails || !taskDetails.contactId ? (
              <ActivityTabs>{[createNewTaskTabComponent]}</ActivityTabs>
            ) : (
              <ActivityTabs>
                <DescriptionTab
                  contactId={taskDetails.contactId}
                  datatestId="update-description"
                  handleCloseModal={onClose}
                  label={text.updateDescriptionLabel}
                  taskDetails={taskDetails}
                  handleToggleClose={handleToggleClose}
                />
                {sendMessageTabComponent}
                <LogActivityTab
                  label={text.addLogTabLabel}
                  datatestId="log-activity"
                  handleCloseModal={onClose}
                  contactId={taskDetails.contactId}
                  taskDetails={taskDetails}
                  onSuccess={onSuccess}
                />
                {createNewTaskTabComponent}
              </ActivityTabs>
            )}
          </JustificationContainer>
          <JustificationContainer
            width="fill-available"
            margin={['l', 'l', 'l', 'm']}
          >
            {taskDetails && taskDetails.Contact && (
              <EventTimelineV2 contact={taskDetails.Contact} disabled />
            )}
          </JustificationContainer>
        </TaskContainer>
      </Container>
    </TaskModalContext.Provider>
  );
};

const Container = styled(JustificationContainer)(
  ({ theme }) => css`
    height: 100%;
    ${theme.mq.lessThan('tabletLandscape')`
      flex-direction: column;
      > * {
        width: 100%;
      }
    `}
  `,
);

const CONTACT_BAR_WIDTH = 300;
const ContactContainer = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    position: fixed;
    width: ${CONTACT_BAR_WIDTH}px;
    height: 100%;

    ${theme.mq.lessThan('tabletLandscape')`
      position: relative;
      width: 100%;
      padding: ${theme.space('l')} 0;
      height: auto;
    `}
  `};
`;

const TaskContainer = styled(JustificationContainer)(
  ({ theme }) => css`
    margin-left: ${CONTACT_BAR_WIDTH}px;
    width: calc(100% - ${CONTACT_BAR_WIDTH}px);

    ${theme.mq.lessThan('tabletLandscape')`
      margin-left: 0;
      width: 100%;
    `}
  `,
);

const WithUpdateTimelineFn: React.FC<Props> = props => {
  const updateTimelineFn = useUpdateTimelineFn(
    props.initialTaskDetails?.contactId ?? null,
  );

  return <TaskModalContent {...props} updateTimelineFn={updateTimelineFn} />;
};

export default WithUpdateTimelineFn;
