import { type HeadingSixElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the OrderedListElement into html element
 * @param {HeadingSixElement} node - HeadingSixElement
 */
const serialize = (node: HeadingSixElement, children: string): string =>
  `<h6>${children}</h6>`;

export default serialize;
