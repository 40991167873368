import { v4 as uuid } from 'uuid';
import type {
  FormBuilder_Event,
  FormBuilder_Event_Field,
  FormBuilder_EventNode,
  FormBuilder_NodeFragment,
  FormBuilder_ScreenNode,
} from '~/graphql/types';
import getFields from '../getFields';
import isBlockWithOutput from '../../isBlockWithOutput';
import sortNodes from '../../sortNodes';
import getBlockKeyFromPointer from '../../getBlockKeyFromPointer';

/**
 * Generate event fields for the submit event
 *
 * @param {Array<FormBuilder_NodeFragment>} nodes - Current nodes in the form builder
 * @param {FormBuilder_Event} event - Event
 * keywords: submitevent, eventfields, autogeneratedevent
 */
const generateEventFields = ({
  nodes,
  event,
}: {
  nodes: Array<FormBuilder_NodeFragment>;
  event: FormBuilder_Event;
}): {
  // Updated event fields
  generatedEventFields: Array<FormBuilder_Event_Field>;

  // Lookup to find the matching event key
  eventKeyLookup: { [blockKey: string]: string };
} => {
  const submitEventNode = nodes.find(
    (node): node is FormBuilder_EventNode =>
      node.__typename === 'FormBuilder_EventNode',
  );

  // We want to keep the event field key the same as much as possible throughout its lifetime
  // so that the contact filters, flow conditions etc. still work for it. Returns: { eventFieldKey: blockKey }
  const existingEventFieldsLookup = submitEventNode?.mapping.reduce(
    (lookup, { key, pointer }) => {
      lookup[key] = getBlockKeyFromPointer(pointer as [string, string]);
      return lookup;
    },
    {} as { [eventFieldKey: string]: string },
  );

  const prevEventFields = [...(event.fields || [])];
  const generatedEventFields: Array<FormBuilder_Event_Field> = [];
  const eventKeyLookup: { [blockKey: string]: string } = {};

  const sortedScreenNodes = sortNodes({ nodes }).filter(
    (node): node is FormBuilder_ScreenNode =>
      node.__typename === 'FormBuilder_ScreenNode',
  );

  sortedScreenNodes.forEach(screenNode => {
    screenNode.blocks.forEach(block => {
      if (!isBlockWithOutput(block)) return;

      const fields = getFields(block);
      if (!fields) return;

      const existingEventFieldForBlock = prevEventFields.find(
        ({ key }) => existingEventFieldsLookup?.[key] === block.key,
      );
      const eventFieldKey = existingEventFieldForBlock
        ? existingEventFieldForBlock.key
        : uuid();

      eventKeyLookup[block.key] = eventFieldKey;

      generatedEventFields.push({
        __typename: 'FormBuilder_Event_Field',
        key: eventFieldKey,
        name: `${screenNode.name}: ${block.label?.nl || block.label?.en}`,
        ...fields,
      });
    });
  });

  return {
    generatedEventFields,
    eventKeyLookup,
  };
};

export default generateEventFields;
