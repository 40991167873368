import { v4 as uuid } from 'uuid';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { BasePoint, Editor, Element, Transforms } from 'slate';
import uploadImageToStorage from '~/components/bad/HTMLEditor/util/uploadImageToStorage';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import {
  DHEditor,
  DhImageElement,
} from '~/components/organism/PluginsEditor/types';
import { reporter } from '~/hooks/useErrorReporter';

const insertAction = (
  editor: DHEditor,
  files: File | FileList,
  userId: string,
  onInserted?: () => void,
) => {
  insertImage(editor, {
    inlineId: uuid(),
    width: 300,
    loading: true,
  });

  if (!editor.selection) return;

  // Track the location of the pending image so we can update its url later.
  const point = Editor.point(editor, editor.selection);
  const pointRef = Editor.pointRef(editor, point);

  let file = files;

  // If there are multiple files dropped, insert the first one
  if ('length' in files && files.length > 0) {
    file = files[0];
  }

  // TODO editor: Fix this to show preview of the image
  // updateImage(editor, pointRef.current, {
  //   url: URL.createObjectURL(file),
  //   opacity: '40%',
  // });

  // @ts-expect-error Here the file is of type File but ts thinks it can be FileList too
  uploadImageToStorage(file, userId)
    .then(({ url, s3key, filename, contentType, contentLength }) => {
      const currentPoint = pointRef.current;
      if (currentPoint) {
        HistoryEditor.withoutSaving(editor, () => {
          updateImage(
            editor,
            {
              url,
              s3key,
              filename,
              contentType,
              contentLength,
              inlineId: uuid(),
              pending: false,
              loading: false,
              opacity: undefined,
            },
            currentPoint,
          );
        });

        Transforms.move(editor, {
          distance: 1,
          unit: 'offset',
        });
        ReactEditor.focus(editor);

        pointRef.unref();
      }

      if (onInserted) onInserted();
    })
    .catch(err => {
      pointRef.unref();
      reporter.captureException(err, 'debug');
      return;
    });
};

export const insertImage = (
  editor: DHEditor,
  imgAttrs: Pick<DhImageElement, 'inlineId' | 'width' | 'loading'>,
) => {
  const image: DhImageElement = {
    type: ELEMENTS.DH_IMAGE,
    ...imgAttrs,
    children: [{ text: '' }],
  };

  Transforms.insertNodes(editor, image);
};

const updateImage = (
  editor: DHEditor,
  imageAttrs: Omit<DhImageElement, 'type' | 'children'>,
  currentPoint: BasePoint,
) => {
  HistoryEditor.withoutSaving(editor, () => {
    Transforms.setNodes(editor, imageAttrs, {
      at: currentPoint,
      match: n => Element.isElement(n) && n.type === ELEMENTS.DH_IMAGE,
    });
  });
};

export default insertAction;
