import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import CreateActivitiesFullPage from '~/components/page/Dev/CreateActivitiesFullPage/index';
import TEST_ID from './index.testid';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import Button from '~/components/atom/Button';
import useDHFlag from '~/hooks/useDHFlag';
import useOnboardingWizard from '~/hooks/useOnboardingWizard';
import useCurrentAccount from '~/hooks/useCurrentAccount';

const text = {
  pageTitle: 'Create an activity',
};
const CreateActivities: React.FCC<RouteComponentProps> = () => {
  const { id: accountId } = useCurrentAccount();
  const { showOnboardingWizard } = useOnboardingWizard();
  const isDeveloper = useDHFlag('is-developer');

  return (
    <>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      {isDeveloper && (
        <Button
          size="large"
          label="Start onboarding wizard"
          onClick={() => showOnboardingWizard()}
        />
      )}

      <CreateActivitiesFullPage
        accountId={accountId}
        dataTestId={TEST_ID.CreateActivities}
      />
    </>
  );
};

export default CreateActivities;
