import { LimitId } from '~/graphql/types';
import useLimitsMap from '../useLimitsMap';

/**
 * Used to retrieve limits
 * BE will communicate which limit to use to limit what feature
 * @param id LimitId generated in types.ts and managed on BE
 * @returns Limit
 */
const useLimit = (id: LimitId) => {
  const limitsMap = useLimitsMap();
  return (
    limitsMap[id] ?? { __typename: 'Limit_Soft', key: id, limit: Infinity }
  );
};

export default useLimit;
