import React from 'react';
import styled, { css } from 'styled-components';

import Validation from '~/util/Validation';
import TEST_ID from './index.testid';
import Icon from '~/components/atom/Icon';
import AvatarForComponent from '../AvatarForComponent';
import type { AvatarForTypes } from '../..';

const text = {
  placeholderText: 'Je hebt nog geen bestand geupload',
};

type Props = {
  avatarFor?: AvatarForTypes;
  src?: string | null;
  simpleImage?: boolean;
  imagePlaceholderText?: string;
};

const ImageOrPlaceholder: React.FCC<Props> = ({
  avatarFor,
  src,
  simpleImage,
  imagePlaceholderText,
}) => {
  if (src && Validation.String.isNonEmptyString(src))
    return <Image src={src} data-testid={TEST_ID.IMG_ELEMENT} />;

  return (
    <>
      {!simpleImage && avatarFor ? (
        <AvatarForComponent avatarFor={avatarFor} />
      ) : (
        <ImageIconContainer data-testid={TEST_ID.IMAGE_PLACHOLDER}>
          <Icon name="image" />
          {imagePlaceholderText ? imagePlaceholderText : text.placeholderText}
        </ImageIconContainer>
      )}
    </>
  );
};

const Image = styled.img<{}>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.base};
  background-color: ${({ theme }) => theme.color('grey')};
`;

const ImageIconContainer = styled.div<{}>(
  ({ theme }) => css`
    background-color: ${theme.color('grey', 'light')};
    color: ${theme.color('text', 'light')};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: ${theme.space('xl')};
    border-radius: ${theme.getTokens().border.radius.base};

    & svg {
      font-size: ${theme.fs('xl')};
    }
  `,
);

export default ImageOrPlaceholder;
