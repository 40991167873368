import React from 'react';
import Textarea from '~/components/bad/Inputs/Textarea';
import ColorInput from '~/components/molecule/ColorInput';
import InputWithLabel from '../InputWithLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Field = {
  label: string;
  value?: string | null | undefined;
  testId?: string;
};

export type Props = {
  dataTestId?: string;
  text: Field;
  color: Field;
  onUpdate: <T>(val: T) => void;
};

const TextAndColorInput: React.FCC<Props> = ({
  text,
  color,
  children,
  onUpdate,
}) => (
  <JustificationContainer align="start" gap="l" width="100%">
    <InputWithLabel label={text.label}>
      <Textarea
        name="text"
        value={text.value !== undefined ? text.value : null}
        onChange={value => onUpdate({ text: value })}
        dataTestId={text.testId}
      />
    </InputWithLabel>

    <JustificationContainer
      style={{ flexBasis: '300px' }}
      direction="column"
      gap="s"
    >
      <ColorInput
        value={color.value || ''}
        onChange={color => onUpdate({ color })}
        label={color.label}
        dataTestId={color.testId}
      />

      {children}
    </JustificationContainer>
  </JustificationContainer>
);

export default TextAndColorInput;
