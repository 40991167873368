import { Editor, Transforms, Element } from 'slate';
import {
  CustomElement,
  DHEditor,
} from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { ReactEditor } from 'slate-react';
import { getElementWithType, getUnhungRange, isElementOfType } from '../..';

export type SimpleBlock =
  | ELEMENTS.DIV
  | ELEMENTS.BLOCKQUOTE
  | ELEMENTS.H1
  | ELEMENTS.H2
  | ELEMENTS.H3
  | ELEMENTS.H4
  | ELEMENTS.H5
  | ELEMENTS.H6;

export const toggleBlock = (editor: DHEditor, format: SimpleBlock) => {
  const isActive = isBlockActive(editor, format);

  const block = { type: format, children: [] };

  if (!isActive) {
    Transforms.setNodes(editor, block);
  } else {
    Transforms.setNodes(
      editor,
      { type: ELEMENTS.DIV, children: [] },
      { match: n => isElementOfType(n, format) },
    );
  }

  ReactEditor.focus(editor);
};

export const isBlockActive = (
  editor: DHEditor,
  format: ELEMENTS.UL | ELEMENTS.OL | SimpleBlock,
) => {
  const { selection } = editor;
  if (!selection) return false;

  // We need to convert Editor.nodes into an array otherwise match is undefined https://slate-js.slack.com/archives/C1RH7AXSS/p1622830353304600
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: getUnhungRange(editor),
      match: n =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        (n as CustomElement).type === format,
    }),
  );

  return !!match;
};

export const setBlockStyle = (
  editor: DHEditor,
  format: 'marginLeft' | 'textAlign',
  value: string,
) => {
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: getUnhungRange(editor),
      match: n => Editor.isBlock(editor, n),
      mode: 'lowest',
    }),
  );
  const selectedEl = match?.[0];

  const previousAttrs = Element.isElement(selectedEl)
    ? selectedEl?.attributes
    : {};

  Transforms.setNodes(
    editor,
    {
      attributes: {
        ...(previousAttrs ? previousAttrs : {}),
        style: {
          ...(previousAttrs?.style ? previousAttrs?.style : {}),
          [format]: value,
        },
      },
    },
    {
      match: n => Editor.isBlock(editor, n),
      mode: 'lowest',
    },
  );

  // focuses on where the cursor was
  Transforms.move(editor, {
    distance: 0,
    unit: 'offset',
  });
  ReactEditor.focus(editor);
};

const convertMarginValueToNumber = (margin: string): number => {
  if (!margin) return 0;
  return parseInt(margin.trim().slice(0, -2), 10) || 0;
};
const getIndentValue = (currentMargin: number, increase: boolean): number => {
  if (increase) {
    return currentMargin >= 0 ? currentMargin + 20 : 0;
  }

  return currentMargin > 0 ? currentMargin - 20 : currentMargin;
};

export const toggleIndent = (editor: DHEditor, increase: boolean) => {
  const selectedEl = getElementWithType({ editor, type: ELEMENTS.DIV });
  if (!selectedEl || !selectedEl.element) return;

  const prevMargin = selectedEl.element?.attributes?.style?.marginLeft;
  const currentMargin = convertMarginValueToNumber(prevMargin);
  const updatedMargin = getIndentValue(currentMargin, increase);

  setBlockStyle(editor, 'marginLeft', updatedMargin + 'px');
};
