import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';
import { useRecoilState } from 'recoil';
import { Heading5, Heading6, Variant } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import useImageUpload from '~/hooks/useImageUpload';
import useMeasure from '~/hooks/useMeasure';
import ColorInput from '~/components/molecule/ColorInput';
import type {
  WidgetSettingsStyleConfigColorPair,
  WidgetSettingsStyles,
} from '~/graphql/types';
import { themeState } from '../../../../state/theme';
import Input from '~/components/molecule/Input';
import webSiteURLValidation from '~/util/websiteURLValidation';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  heading: 'Thema',
  logoImage: 'Logo',
  url: 'Adres van je website',
  urlDescription: 'Waar gaan we heen als we op de link klikken',
  urlPlaceholder: 'https://www.makelaar.nl',
  colors: {
    primary: {
      color: 'Contrasterende kleur',
      background: 'Primaire kleur',
    },
    secondary: {
      color: 'Contrasterende kleur',
      background: 'Accent kleur',
    },
  },
};

const ThemeSettings: React.FCC<Props> = ({
  dataTestId,
  expanded = false,
  onToggleExpand,
}) => {
  const [state, setState] = useRecoilState(themeState);
  const { ref, bounds } = useMeasure();
  const theme = useTheme();

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const logoImage = useImageUpload({
    initialUrl: state.logo?.url,
    s3Key: state.logo?.s3key,
    onUpload: ({ s3Key, url }) => {
      setState(prev =>
        prev
          ? {
              ...prev,
              logo: { __typename: 'DHImage', s3key: s3Key, url },
            }
          : prev,
      );
    },
  });

  const onColorChange = ({
    variant,
    key,
    value,
  }: {
    variant: keyof Omit<WidgetSettingsStyles, '__typename'>;
    key: keyof Omit<WidgetSettingsStyleConfigColorPair, '__typename'>;
    value: string;
  }) =>
    setState(prev =>
      prev
        ? {
            ...prev,
            colors: {
              ...prev.colors,
              [variant]: {
                ...prev.colors[variant],
                [key]: value,
              },
            },
          }
        : prev,
    );

  const onLogoUrlChange = (value: string) =>
    setState(prev =>
      prev
        ? {
            ...prev,
            logoLink: value,
          }
        : prev,
    );

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading={text.heading}
        icon="star"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <InputContainer>
            <Heading5 variant={Variant.primary}>{text.logoImage}</Heading5>
            <ImageUploadComponent
              uploading={logoImage.uploading}
              imageUrl={logoImage.s3Key != null ? logoImage.url : null}
              onUpload={file =>
                void logoImage.upload({
                  file: file[0],
                  filename: 'default_theme_logo_image',
                })
              }
              direction="column"
            />
          </InputContainer>
          {state.logo?.url && (
            <InputContainer>
              <Heading5 withoutMargin variant={Variant.primary}>
                {text.url}
              </Heading5>
              <Heading6>{text.urlDescription}</Heading6>
              <Input
                value={state.logoLink}
                onChange={event => onLogoUrlChange(event?.target.value || '')}
                width="100%"
                name="url"
                placeholder={text.urlPlaceholder}
                validation={[webSiteURLValidation]}
              />
            </InputContainer>
          )}
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.primary.background}
            </Heading5>
            <ColorInput
              value={state.colors.primary.background}
              onChange={color =>
                onColorChange({
                  variant: 'primary',
                  key: 'background',
                  value: color,
                })
              }
            />
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.primary.color}
            </Heading5>
            <ColorInput
              value={state.colors.primary.color}
              onChange={color =>
                onColorChange({
                  variant: 'primary',
                  key: 'color',
                  value: color,
                })
              }
            />
          </InputContainer>

          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.colors.secondary.background}
            </Heading5>
            <ColorInput
              value={state.colors.secondary.background}
              onChange={color =>
                onColorChange({
                  variant: 'secondary',
                  key: 'background',
                  value: color,
                })
              }
            />
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {' '}
              {text.colors.secondary.color}
            </Heading5>
            <ColorInput
              value={state.colors.secondary.color}
              onChange={color =>
                onColorChange({
                  variant: 'secondary',
                  key: 'color',
                  value: color,
                })
              }
            />
          </InputContainer>
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const InputContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('xxs')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow-x: hidden;
`;

export default ThemeSettings;
