import React from 'react';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { BrandSettings } from '../../state';
import InputLabel from '~/components/atom/InputLabel';
import Input from '~/components/molecule/Input';
import type { DeepPartial } from 'utility-types';
import ColorInput from '~/components/molecule/ColorInput';
import TEST_ID from './index.testid';
import ImageInput from '~/components/organism/ImageInput';
import webSiteURLValidation from '~/util/websiteURLValidation';
import { Body } from '~/components/atom/Typography';

const text = {
  title: 'Huisstijl',
  primaryColor: 'Primaire kleur',
  primaryBackgroundColor: 'Primaire achtergrond kleur',
  secondaryColor: 'Secondaire kleur',
  secondaryBackgroundColor: 'Secondaire achtergrond kleur',
  url: 'Adres van je website',
  description:
    'Stel je huisstijl op deze pagina in. Vervolgens kan je in apps de huisstijl met één muisklik toepassen.',
};

export type Props = {
  values: Omit<BrandSettings, 'unsubscribeLinkText'>;

  /** Disables the input fields */
  readOnly: boolean;

  /** Partial update of current changes state */
  onUpdate: (args: DeepPartial<BrandSettings>) => void;
};

const AccountBlock: React.FCC<Props> = ({
  values,
  onUpdate,
  readOnly,
  ...rest
}) => {
  const colors = values.colors;

  return (
    <AnimatedBlock
      title={text.title}
      manualLayout
      dataTestId={TEST_ID.CONTAINER}
      {...rest}
    >
      <JustificationContainer padding={['m', null]} gap="l" direction="column">
        <Body withoutMargin>{text.description}</Body>

        <JustificationContainer direction="column" width="100%">
          <Input
            label={text.url}
            value={values.url}
            onChange={event => onUpdate({ url: event?.target.value || '' })}
            width="100%"
            disabled={readOnly}
            name="url"
            validation={[webSiteURLValidation]}
          />
        </JustificationContainer>
        <JustificationContainer
          direction="column"
          width="100%"
          dataTestId={TEST_ID.LOGO_CONTAINER}
        >
          <InputLabel label="Logo" />
          <ImageInput
            initialUrl={values.logo?.url}
            s3Key={values.logo?.s3key}
            filename="logo"
            onChange={value => {
              onUpdate({
                logo: value
                  ? {
                      __typename: 'DHImage',
                      s3key: value?.s3key || '',
                      // url is needed to update the displayed image, it is not sent in the mutation
                      url: value.url || undefined,
                    }
                  : null,
              });
            }}
            readOnly={readOnly}
            dataTestId={TEST_ID.LOGO_IMAGE_INPUT}
          />
        </JustificationContainer>
        <JustificationContainer width="100%" gap="l" align="end">
          <ColorInput
            value={colors?.primary?.color || ''}
            onChange={color => onUpdate({ colors: { primary: { color } } })}
            label={text.primaryColor}
            readOnly={readOnly}
            dataTestId="picker_design-primaryColor"
          />
          <ColorInput
            value={colors?.primary?.background || ''}
            onChange={color =>
              onUpdate({ colors: { primary: { background: color } } })
            }
            label={text.primaryBackgroundColor}
            readOnly={readOnly}
            dataTestId="picker_design-primaryBackgroundColor"
          />
          <ColorInput
            value={colors?.secondary?.color || ''}
            onChange={color => onUpdate({ colors: { secondary: { color } } })}
            label={text.secondaryColor}
            readOnly={readOnly}
            dataTestId="picker_design-secondaryColor"
          />
          <ColorInput
            value={colors?.secondary?.background || ''}
            onChange={color =>
              onUpdate({ colors: { secondary: { background: color } } })
            }
            label={text.secondaryBackgroundColor}
            readOnly={readOnly}
            dataTestId="picker_design-secondaryBackgroundColor"
          />
        </JustificationContainer>
      </JustificationContainer>
    </AnimatedBlock>
  );
};

export default AccountBlock;
