import React, { useState } from 'react';
import TEST_ID from '../../index.testid';
import { isNil } from 'ramda';
import Dropdown from '~/components/molecule/Dropdown';
import {
  ContactFilters__Input,
  GetFlowsV2Query,
  useDoContactActionMutation,
  useGetFlowsV2Query,
  useLockContactSearchResultsByFilterV2Mutation,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import formatFlowsAsOptions from '../../../util/formatFlowsAsOptions';
import getValidFlows from '../../../util/getValidFlows';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import LockedContactsMessage from '../ActionsContainer/components/LockedContactsMessage';

export const text = {
  startFlow: 'Start flow',
  loadingFlows: 'Flows laden...',
  noFlowsAvailable:
    'Er zijn geen actieve flows. Ga naar Automation in het menu om een flow toe te voegen.',
  preparingContacts: 'Contacten voorbereiden...',
  modalHeader: 'Start flow ',
  modalBodyTemplate: (amount: number, name: string) =>
    `Je staat op het punt om flow "${name}" te starten voor ${amount} contact(en). Weet je zeker dat je door wilt gaan?`,
  cancel: 'Afbreken',
  confirm: 'Start flow',
  successToastTemplate: (amount: number, name: string) =>
    `Flow "${name}" gestart voor ${amount} contact(en)`,
  errorToast:
    'Er is iets misgegaan met het starten van de flow. Probeer het later opnieuw',
};

const NO_FLOWS_KEY = 'no-flow-available';

type Props = {
  /** Array of selected contact id's */
  selectedContacts: Array<string>;

  /** If all contacts on all pages are selected or not */
  allSelected: boolean;

  /** Number of selected contacts */
  selectedContactAmount: number;

  /** query and filters fields returned from GetContactsV2Query, used to lock contact search result */
  queryVariables: {
    query?: string;
    filters: ContactFilters__Input;
  };
};

const StartFlowDropdown: React.FC<Props> = ({
  selectedContacts,
  allSelected,
  queryVariables,
  selectedContactAmount,
}) => {
  const addToast = useAddToast();
  const [showModal, setShowModal] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<
    GetFlowsV2Query['getFlowsV2']['items'][number] | null
  >(null);
  const account = useCurrentAccount();
  const { data, loading } = useGetFlowsV2Query({
    variables: {
      accountId: account.id,
      limit: 999,
    },
    fetchPolicy: 'network-only',
  });

  const [lockContacts, { loading: lockLoading, data: lockData }] =
    useLockContactSearchResultsByFilterV2Mutation();
  const [doContactAction, { loading: actionLoading }] =
    useDoContactActionMutation();

  const queryString = queryVariables.query === '' ? null : queryVariables.query;

  const lockContactsVariables = !allSelected
    ? {
        accountId: account?.id,
        contactIds: selectedContacts,
        query: queryString,
      }
    : {
        accountId: account?.id,
        filters: queryVariables.filters,
        query: queryString,
      };

  const availableFlows = getValidFlows(data?.getFlowsV2.items || []);

  const flowOptions =
    availableFlows.length === 0
      ? [
          {
            label: text.noFlowsAvailable,
            payload: null,
            key: NO_FLOWS_KEY,
          },
        ]
      : formatFlowsAsOptions(availableFlows);

  const lockedTotal = lockData?.lockContactSearchResultsByFilterV2?.total ?? 0;

  return (
    <>
      <Dropdown
        disabled={
          isNil(data?.getFlowsV2) ||
          selectedContacts.length === 0 ||
          lockLoading
        }
        appearance="filled"
        options={flowOptions}
        loading={loading}
        labelLoading={lockLoading}
        onChange={({ option: { payload, key } }) => {
          if (key === NO_FLOWS_KEY) {
            window.open('/-/automation', '_blank');
            return;
          }
          const selectedFlow = data?.getFlowsV2.items.find(
            ({ id }) => id === payload.id,
          );

          if (!selectedFlow) return;
          setSelectedFlow(selectedFlow);

          void lockContacts({
            variables: lockContactsVariables,
          }).then(() => {
            setShowModal(true);
          });
        }}
        dataTestId={TEST_ID.START_FLOW_DROPDOWN}
        placeholder={
          isNil(data?.getFlowsV2)
            ? text.loadingFlows
            : lockLoading
              ? text.preparingContacts
              : text.startFlow
        }
      />
      {showModal && (
        <Overlay
          root="confirm-start-flow-modal"
          onClose={() => setShowModal(false)}
        >
          <Dialog
            loading={loading || lockLoading || actionLoading}
            dataTestId={TEST_ID.CONFIRM_MODAL}
            header={`${text.modalHeader} "${selectedFlow?.name}"?`}
            body={
              <LockedContactsMessage
                selectedContactAmount={selectedContactAmount}
                lockedAmount={lockedTotal}
                loading={lockLoading}
                message={text.modalBodyTemplate(
                  lockedTotal ?? 0,
                  selectedFlow?.name ?? '',
                )}
              />
            }
            onConfirm={() => {
              if (
                lockData?.lockContactSearchResultsByFilterV2 &&
                selectedFlow
              ) {
                void doContactAction({
                  variables: {
                    accountId: account.id,
                    action: {
                      StartFlow: {
                        flowBlueprintId: selectedFlow.id,
                      },
                    },
                    key: lockData?.lockContactSearchResultsByFilterV2.key,
                  },
                }).then(({ errors }) => {
                  if (!errors || errors.length === 0) {
                    setShowModal(false);
                    return addToast([
                      formatToastMessage(
                        text.successToastTemplate(
                          lockData.lockContactSearchResultsByFilterV2.total,
                          selectedFlow.name,
                        ),
                        'success',
                      ),
                    ]);
                  }

                  return addToast([
                    formatToastMessage(text.errorToast, 'danger'),
                  ]);
                });
              }
            }}
            cancelAction={{
              label: text.cancel,
            }}
            confirmAction={{
              icon: 'branches',
              appearance: 'primary',
              label: text.confirm,
              loading: actionLoading,
            }}
          />
        </Overlay>
      )}
    </>
  );
};

export default StartFlowDropdown;
