import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '~/theme';
import Footer, { FOOTER_HEIGHT } from './components/Footer';
import TEST_ID from './index.testid';
import Navigation from '../Navigation';
import useMeasure from '~/hooks/useMeasure';

type Props = {
  /* The component(s) to show between the header/footer components */
  children: React.ReactNode;
};

const NavigationFrame: React.FCC<Props> = ({ children }) => {
  const navigationRef = useRef<HTMLDivElement | null>(null);
  const { bounds } = useMeasure();

  return (
    <MainContainer navigationHeight={bounds.height}>
      <Navigation ref={navigationRef} />
      <ContentContainer data-testid={TEST_ID.PAGE_CONTAINER}>
        {children}
      </ContentContainer>
      <Footer />
    </MainContainer>
  );
};

export const getColumnGap = (theme: Theme) =>
  theme.remToPxRaw(theme.space('l'));

const MainContainer = styled.div<{ navigationHeight: number }>`
  display: grid;
  overflow: hidden;

  ${({ theme, navigationHeight }) => css`
    grid-template-rows:
      auto minmax(
        calc(100vh - ${FOOTER_HEIGHT}px - ${navigationHeight}px),
        min-content
      )
      ${FOOTER_HEIGHT}px;

    ${theme.mq.lessThan('tabletLandscape')`
      grid-template-rows:
      auto minmax(
        calc(
          100vh - ${FOOTER_HEIGHT}px
        ),
        min-content
      )
      ${FOOTER_HEIGHT}px;

      `}
  `};
`;

const ContentContainer = styled.div<{}>`
  display: flex;
  flex-direction: column;
  max-width: 100vw;

  ${({ theme }) => css`
    background-color: ${theme.color('white', 'dark')};
  `}
`;

export default NavigationFrame;
