import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Signature,
  UserRole,
  useUpdateAccountMutation,
  useUpdateAccountRelationMutation,
} from '~/graphql/types';
import { Mailboxes } from '~/graphql/types.client.flow';
import AccountDrawer from '../AccountDrawer';
import AccountAdministrators from '../AccountAdministrators';
import SynchronisedMailboxes from '~/components/page/Settings/components/SynchronisedMailboxes';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import SignatureContainer from '~/components/organism/SignatureContainer';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { SynchroniseEntityType } from '~/components/page/External/Nylas/components/NylasAuthoriseEndpointV1/types';
import usePermissions from '~/hooks/usePermissions';
import EditableText from '~/components/organism/EditableText';
import CopyTextBlock from '~/components/molecule/CopyTextBlock';

type AccountRelation = {
  role: UserRole;
  userId: string;
};

type Props = {
  accountId: string;
  accountName: string;
  mayEdit: boolean;
  mailboxes: Mailboxes;
  signature: Signature | null;
  setErrorMsg: (msg: string | null) => void;
  startPolling: (pollInterval: number) => void;
  stopPolling: () => void;

  /** Refetch getAccount query and session hydration */
  refetch: () => Promise<any>;
};

export const text = {
  accountNameUpdateError: 'Account update failed',
  accountUsersRalationError: 'Account users role update failed',
  emailIntegration: 'E-mailintegratie',
  signature: 'Handtekening account',
  accountIdLabel: 'Account ID',
  mayEditExplanation: 'Alleen accountbeheerders kunnen e-mails synchroniseren.',
};

const AccountComponent: React.FCC<Props> = ({
  accountId,
  accountName,
  mayEdit,
  mailboxes,
  signature,
  refetch,
  startPolling,
  stopPolling,
  setErrorMsg,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const hasEmailPermission = usePermissions(['root.email.account']);
  const [updateAccount] = useUpdateAccountMutation({
    onError: () => setErrorMsg(text.accountNameUpdateError),
  });
  const [updateAccountRelation, { loading: updateRelationLoading }] =
    useUpdateAccountRelationMutation({
      onError: () => setErrorMsg(text.accountUsersRalationError),
    });

  const filteredMailboxes = mailboxes.filter(
    mailbox =>
      mailbox.__typename === 'AccountMailbox' ||
      mailbox.__typename === 'AccountMailboxAlias',
  );

  const onSuccess = ({ data, errors }) => {
    if (!errors && data) {
      void refetch();
    }
  };

  const updateAccountName = async (value: string) =>
    await updateAccount({
      variables: {
        accountId,
        update: {
          name: value,
        },
      },
    }).then(onSuccess);

  const updateUserRole = async ({ role, userId }: AccountRelation) =>
    await updateAccountRelation({
      variables: {
        accountId,
        userId,
        update: {
          role,
        },
      },
    }).then(({ data, errors }) => {
      if (!errors && data) {
        void refetch();
      }
    });

  return (
    <React.Fragment>
      <AccountDetailsForm data-testid="accounts-page">
        <JustificationContainer
          direction="column"
          gap="xxs"
          margin={[null, null, 'xxl', null]}
        >
          <EditableText
            readonly={!mayEdit}
            onSave={updateAccountName}
            text={accountName}
            loading={false}
          />
          <CopyTextBlock textToCopy={accountId} label={text.accountIdLabel} />
        </JustificationContainer>
        <>
          <AccountAdministrators
            loading={updateRelationLoading}
            handleToggleDrawer={setOpenDrawer}
            updateUserRole={updateUserRole}
            isAdmin={mayEdit}
          />
          {hasEmailPermission.allowed && (
            <AnimatedBlock title={text.emailIntegration}>
              <SynchronisedMailboxes
                mailboxes={filteredMailboxes}
                entityType={SynchroniseEntityType.Account}
                entityId={accountId}
                mayEdit={mayEdit}
                mayEditExplanation={text.mayEditExplanation}
                refetch={refetch}
                startPolling={startPolling}
                stopPolling={stopPolling}
              />
            </AnimatedBlock>
          )}

          <AccountDrawer
            handleToggleDrawer={setOpenDrawer}
            updateUserRole={updateUserRole}
            isOpen={openDrawer}
            loading={updateRelationLoading}
          />

          {hasEmailPermission.allowed && (
            <SignatureContainer
              title={text.signature}
              signature={signature}
              refetch={refetch}
              readOnly={!mayEdit}
            />
          )}
        </>
      </AccountDetailsForm>
    </React.Fragment>
  );
};

const ACCOUNT_DETAILS_FORM_MAX_WIDTH = 540;

const AccountDetailsForm = styled.section<{}>`
  width: 100%;
  max-width: ${ACCOUNT_DETAILS_FORM_MAX_WIDTH}px;
`;

export default AccountComponent;
