import React from 'react';
import BlockLayout from '../BlockLayout';
import { useRecoilValue } from 'recoil';
import { formState as formStateAtom } from '~/components/page/Forms/components/Builder/state';
import type {
  FormBuilder_ScreenNode_Block,
  FormData_Ui_RichText,
} from '~/graphql/types';
import { isNil, toLower } from 'ramda';

import Divider from '~/components/atom/Divider';
import InputLabel from '~/components/atom/InputLabel';
import MarkdownEditor from './components/MarkdownEditor';

const text = {
  nl: 'Tekst met opmaak (🇳🇱)',
  en: 'Text with format options (🇬🇧)',
};

export type Props = {
  /** Update block */
  onChange: (block: FormBuilder_ScreenNode_Block) => void;

  block: FormData_Ui_RichText;
  nodeId: string;
};

const RichText: React.FCC<Props> = ({ onChange, nodeId, block }) => {
  const formState = useRecoilValue(formStateAtom);
  const locales = formState?.style.availableLocale.map(toLower);

  if (isNil(locales)) return null;

  return (
    <BlockLayout nodeId={nodeId} blockKey={block.key}>
      {locales.map((locale, index) => (
        <>
          <InputLabel label={text[locale]} />
          <MarkdownEditor
            key={`${block.key}-${locale}`}
            value={block.content[locale] || ''}
            onChange={updatedValue => {
              onChange({
                ...block,
                key: block.key,
                content: {
                  ...block.content,
                  [locale]: updatedValue,
                },
              });
            }}
          />

          {index !== locales.length - 1 && <Divider />}
        </>
      ))}
    </BlockLayout>
  );
};

export default RichText;
