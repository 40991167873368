import React from 'react';

import { useGetApproximateUpcomingOveragesChargeQuery } from '~/graphql/types';

import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import useCurrentAccount from '~/hooks/useCurrentAccount';

import { convertServerDateStringToDate, safeFormattedDate } from '~/util/date';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading4, Small, Variant } from '~/components/atom/Typography';
import { isNil } from 'ramda';
import { asPriceElement } from '~/util/money';
import CombinedInvoiceItem from './components/CombinedInvoiceItem';
import TieredInvoiceItem from './components/TieredInvoiceItem';
import BasicInvoiceItem from './components/BasicInvoiceItem';
import Divider from '~/components/atom/Divider';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import AppErrorScreen from '~/components/template/AppErrorScreen';

const text = {
  title: 'Overige kosten',
  invoiceLabel: 'Facturatie op',
  lastUpdateLabel: 'Laatst bijgewerkt op',
  informationText:
    'Specificatie van extra kosten buiten je huidige abonnement.',

  total: 'Totaal ',
  tax: 'exclusief btw',
  noOverage: 'Er zijn nog geen extra kosten op je abonnement',
};

const OverageDetails: React.FC = () => {
  const account = useCurrentAccount();
  const { data, networkStatus, error } =
    useGetApproximateUpcomingOveragesChargeQuery({
      variables: {
        accountId: account.id,
      },
    });

  if (isInitialLoadStatus(networkStatus)) {
    // If there are no overages we do not want to show anything, so also don't show loading
    return <DatHuisLoading />;
  }

  const overages = data?.getApproximateUpcomingOveragesCharge;

  if (error || overages == null) {
    return <AppErrorScreen inline setBackgroundColor={false} />;
  }

  if (overages.amount === 0) {
    return <Body>{text.noOverage}</Body>;
  }

  const { cycleEndDate, lastUpdatedDate: lastUpdatedDateString } = overages;
  const invoiceDate = convertServerDateStringToDate(cycleEndDate);
  const lastUpdatedDate = convertServerDateStringToDate(lastUpdatedDateString);

  return (
    <JustificationContainer direction="column">
      <Body size="s">{text.informationText}</Body>

      <JustificationContainer direction="column" gap="s" width="100%">
        {overages.items.map(item => {
          switch (item.__typename) {
            case 'BasicInvoiceItem':
              return <BasicInvoiceItem item={item} key={item.name} />;
            case 'CombinedInvoiceItem':
              return <CombinedInvoiceItem item={item} key={item.name} />;
            case 'TieredInvoiceItem':
              return <TieredInvoiceItem item={item} key={item.name} />;
          }
        })}
        <Divider margin={['xxs', null]} />
        <JustificationContainer width="100%" justification="space-between">
          <JustificationContainer align="end" gap="xxxs">
            <Heading4 variant={Variant.primary} withoutMargin>
              {text.total}
            </Heading4>
            <Small withoutMargin>{text.tax}</Small>
          </JustificationContainer>
          <Body size="base" withoutMargin fontWeight="semiBold">
            {asPriceElement(overages.amount)}
          </Body>
        </JustificationContainer>
      </JustificationContainer>

      <JustificationContainer
        direction="column"
        align="end"
        justification="end"
        width="100%"
        margin={['m', null, null, null]}
      >
        {invoiceDate && (
          <Body size="s" withoutMargin={isNil(lastUpdatedDate)}>
            {text.invoiceLabel} {safeFormattedDate(invoiceDate)}
          </Body>
        )}
        {lastUpdatedDate && (
          <Body size="s" withoutMargin>
            {text.lastUpdateLabel} {safeFormattedDate(lastUpdatedDate)}
          </Body>
        )}
      </JustificationContainer>
    </JustificationContainer>
  );
};

export enum OVERAGE_ITEM {
  BASIC = 'BASIC_OVERAGE_ITEM',
  PRICE_PER = 'PRICE_PER_OVERAGE_ITEM',
  GROUPED = 'GROUPED_OVERAGE_ITEM',
}

export default OverageDetails;
