import React from 'react';
import { RenderElementProps } from 'slate-react';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { ImmutableHtmlElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import { Label } from '~/components/atom/Typography';
import Div from '~/components/atom/Div';

export type Props = RenderElementProps & {
  element: ImmutableHtmlElement;
};

const text = {
  label: 'Statische html',
};

export const ImmutableHtml: React.FC<Props> = ({ element }) => (
  // We do not want this element to be focusable
  <div contentEditable={false}>
    <Label>{text.label}</Label>
    <Div
      border={{
        width: 's',
        radius: 's',
        color: { group: 'grey', variant: 'light' },
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: element.dhInnerHtml || ' ',
        }}
      />
    </Div>
  </div>
);

export default {
  nodeName: 'DHIMMUTABLE',
  renderComponent: props => <ImmutableHtml {...props} />,
  deserialize: el => ({
    type: ELEMENTS.IMMUTABLE_HTML_ELEMENT,
    dhInnerHtml: el.getAttribute('dh-innerhtml') || '',
  }),
  serialize,
};
