import React from 'react';
import styled, { css } from 'styled-components';

import TEST_ID from './FlowInstanceList.testid';
import { FlowInstanceStatus, FlowV2InstanceStatus } from '~/graphql/types';
import TextButton from '~/components/atom/TextButton';
import { Body } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
export type FlowInstance = {
  id: string;
  status: FlowInstanceStatus | FlowV2InstanceStatus;
  name: string;
};

type Props = {
  /** List of flow instances */
  value: Array<FlowInstance>;
  onClick: (value: FlowInstance) => void;
};

const FlowInstanceList: React.FCC<Props> = ({ value, onClick }) => {
  const flowInstance = value.map((item, index) => (
    <JustificationContainer
      align="center"
      justification="space-between"
      key={item.id}
      margin={['xxs', null, null, null]}
    >
      <JustificationContainer>
        <FlowInstanceNumber size="base" margin={[null, 'xxs', null, null]}>
          {index + 1}.
        </FlowInstanceNumber>
        <Body size="base" margin={[null, 'xxs', null, null]}>
          {item.name}
        </Body>
      </JustificationContainer>
      <DeleteButton
        withoutPadding
        icon="trashcan"
        appearance="danger"
        size="small"
        onClick={() => onClick(item)}
      />
    </JustificationContainer>
  ));

  return (
    <FlowInstanceListContainer data-testid={TEST_ID.FLOW_INSTANCE_LIST}>
      {flowInstance}
    </FlowInstanceListContainer>
  );
};

const DeleteButton = styled(TextButton)`
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

const FlowInstanceNumber = styled(Body)(
  ({ theme }) => css`
    /* In order to align numbers and text we set a fixed width */
    width: ${theme.space('base')};
  `,
);

const FlowInstanceListContainer = styled.div<{}>`
  width: 100%;
`;

export default FlowInstanceList;
