import React, { useEffect } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';
import DHRouter from '~/components/atom/DHRouter';
import { RouteComponentProps } from '@gatsbyjs/reach-router';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import TestId from './index.testid';
import Wizard from '~/components/organism/Wizard';
import AppCards from './components/AppCards';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  AppStatusLockedReason,
  AppStatus__Input,
  useUpdateAppStatusMutation,
} from '~/graphql/types';
import Loading from '~/components/atom/Loading';
import FullPageInformation from '~/components/template/FullPageInformation';
import useErrorReporter from '~/hooks/useErrorReporter';
import appModals from './components/AppModals';
import useApps from '~/hooks/useApps';
import { AppStatus__typename, WizardFlow } from '~/graphql/types.client';
import useWizard from '~/hooks/useWizard';
import useSessionHydration from '~/hooks/useSessionHydration';
import getActionableStepsForEnablementFlow from '~/util/getActionableStepsForEnablementFlow';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

const text = {
  success: 'App is succesvol ingeschakeld',
  appsTitle: 'Apps',
  errorTitle: 'Geen data gevonden',
  errorExplanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
  disablingExplanation:
    'App is al gedeïnstalleerd. Het kan even duren voordat het op de pagina wordt weergegeven. Ververs de pagina en probeer het nog een keer. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};
type Props = RouteComponentProps & { testDataId?: string };

const AppsList: React.FCC<Props> = ({ uri = '' }) => {
  const addToast = useAddToast();
  const [, refetchSessionHydration] = useSessionHydration();
  const { id: accountId } = useCurrentAccount();
  const errorReporter = useErrorReporter();
  const wizardApi = useWizard();

  const { apps, error, updateQuery, refetch, loading, polling } = useApps();

  /**
   * Reach router defines uri as potentially undefined here, this seems
   * to never be the case as long as we render this inside of the Router
   * component which is where it should be rendered.
   */
  const onClose = async () => navigate(uri as string);

  const [updateAppStatusImpl] = useUpdateAppStatusMutation();

  useEffect(() => {
    const disablingApps = Object.values(apps).filter(
      app => app.appStatus.locked === AppStatusLockedReason.Disabling,
    );

    if (disablingApps.length !== 0) {
      polling.start(2000);
    } else {
      polling.stop();
    }

    return () => {
      polling.stop();
    };
  }, [apps, polling]);

  const updateAppStatus = async ({
    type,
    update,
    enablementFlow,
  }: {
    type: AppStatus__typename;
    update: AppStatus__Input;
    enablementFlow?: WizardFlow;
  }) => {
    const app = apps[type];
    if (!app) return;

    const { data, errors } = await updateAppStatusImpl({
      variables: {
        accountId,
        update,
      },
    });

    if (errors?.length || !data) {
      errorReporter.captureException(
        new Error(
          JSON.stringify(errors || 'No data received from updateAppStatus'),
        ),
        'error',
      );
      return;
    }

    if (data.updateAppStatus.locked === AppStatusLockedReason.Disabling) {
      polling.start(2000);

      updateQuery(data => ({
        ...data,
        getAppStatuses: data.getAppStatuses.map(appStatus => {
          if (appStatus.__typename !== type) return appStatus;

          return {
            ...appStatus,
            // Update the query result to disable the deinstall button
            locked: AppStatusLockedReason.Disabling,
          };
        }),
      }));
    }

    // Remove enablementFlow.id from db states and clear the output when disabling the app
    if (data.updateAppStatus.enabled === false && enablementFlow) {
      return wizardApi.clear(enablementFlow.id);
    }

    const { updateAppStatus } = data;
    updateQuery(data => ({
      ...data,
      getAppStatuses: data.getAppStatuses.map(appStatus => {
        if (appStatus.__typename !== type) {
          return appStatus;
        }

        return updateAppStatus;
      }),
    }));

    const hasStepsToComplete =
      getActionableStepsForEnablementFlow(enablementFlow).length > 0;

    if (enablementFlow && hasStepsToComplete) {
      if (updateAppStatus.enabled) {
        wizardApi.show({
          header: app.name,
          ...enablementFlow,
        });
        return;
      }
    }

    if (!hasStepsToComplete) {
      if (app.type === 'with_config') {
        return navigate(`/-/apps/${app.slug}`);
      }
      if (app.type === 'activate_only') {
        addToast([formatToastMessage(text.success, 'success')]);
      }
    }
  };

  if (loading) return <Loading />;
  if (error || !apps) {
    return (
      <FullPageInformation
        title={text.errorTitle}
        explanation={text.errorExplanation}
      />
    );
  }

  const refetchApps = () => Promise.all([refetch(), refetchSessionHydration()]);

  return (
    <>
      <MetaTags>
        <title>{text.appsTitle}</title>
      </MetaTags>

      <ContentContainerDefault>
        <OverviewListHeader title={text.appsTitle} />
        <Wizard
          header="App enablement [SHOULD BE OVERWRITTEN]"
          id="app-enablement-wizard"
          steps={[]}
          onComplete={() => refetchApps()}
        >
          <DHRouter height="auto">
            {appModals({
              apps,
              updateAppStatus,
              onClose,
              refetch: () => refetchApps(),
            })}
          </DHRouter>
        </Wizard>
        <AppCards apps={apps} data-testid={TestId.CONTAINER} />
      </ContentContainerDefault>
    </>
  );
};

export default AppsList;
