import React from 'react';

import { ApolloError } from '@apollo/client';
import ContactsContext from './ContactsContext';
import { getErrorTypes } from '~/util/errorHandling';
import Catalog from '~/Catalog';
import ErrorTypes from '~/ErrorTypes';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import { useUpdateContactMutation } from '~/graphql/types';

type Props = {
  children: React.ReactNode;
};

const selectErrorMessage = (error: ApolloError) => {
  const errorTypes = getErrorTypes(error);
  if (errorTypes.includes(ErrorTypes.notFoundError)) {
    return 'Dit contact is verwijderd';
  }

  return Catalog.genericUnknownErrorMessageShort;
};

const ContactsContextProvider: React.FCC<Props> = ({ children }) => {
  const [updateContact, { error, loading }] = useUpdateContactMutation({});

  if (error) return <AppErrorScreen message={selectErrorMessage(error)} />;

  return (
    <ContactsContext.Provider
      value={{
        updateContactFn: updateContact,
        loading: loading,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export default ContactsContextProvider;
