import styled, { css } from 'styled-components';

import { Theme } from '~/theme';
import {
  calculateSpaceInsideComponent,
  calculateFontSize,
  calculateLineHeight,
} from '~/components/bad/util/getSizeCalculation';

export const INPUT_ELEMENT_BORDER = '1px';

type InputElementProps = {
  disabled?: boolean;
  error?: string | null;
  small?: boolean;
  large?: boolean;
  accent?: boolean;

  /** tab index order */
  tabIndex?: string;
};

export const getDisabledInputStyle = (theme: Theme) => css`
  background: ${theme.color('white', 'dark')};
  color: ${theme.color('grey')};
  pointer-events: none;
`;

/* eslint-disable */
const InputElement = styled.input<InputElementProps>`
  width: 100%;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.s};

  ${({ theme, small, large }) => {
    const spaceInsideComponent = calculateSpaceInsideComponent(
      theme,
      small,
      large,
    );

    return css`
      font-size: ${calculateFontSize(theme, small, large)}px;
      /* line-height: ${calculateLineHeight(theme, small, large)}px; */
      padding: calc(${spaceInsideComponent}px - ${INPUT_ELEMENT_BORDER});
    `;
  }};

  ${({ theme, disabled }) => disabled && getDisabledInputStyle(theme)};

  ${({ theme, accent }) => {
    let borderColor = theme.color('grey');

    if (accent === true) {
      borderColor = theme.color('accent');
    }

    return css`
      border: ${INPUT_ELEMENT_BORDER} solid ${borderColor};
    `;
  }};

  &[type='email'] {
    /*
    * We cannot force lowercase onChange because of this: https://github.com/facebook/react/issues/955#issuecomment-160703606
    * This is why we "fake" it using CSS and lowercase emails before submit.
    */
    text-transform: lowercase;
  }

  &:hover,
  &:focus {
    ${({ theme, disabled }) =>
      !disabled &&
      css`
        border: ${INPUT_ELEMENT_BORDER} solid ${theme.color('text')};
      `};
  }

  ${({ error, theme }) => {
    if (error) {
      return css`
        &,
        &:hover,
        &:focus {
          border: ${INPUT_ELEMENT_BORDER} solid ${theme.color('danger')};
        }
      `;
    }
    return null;
  }};
`;

/* eslint-enable */

export default InputElement;
