import { isNil } from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { Heading5, Variant } from '~/components/atom/Typography';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { linkStyles } from '~/components/molecule/Link';
import TEST_ID from './index.testid';
import type { Issue as IssueProps } from '~/components/page/Forms/components/Builder/state/issues';
import { nodeById } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import {
  interactionState,
  type FormBuilder_Interaction,
} from '~/components/page/Forms/components/Builder/state';

export type Props = {
  dataTestId?: string;
  showLink?: boolean;
  title?: string;
  onClick?: () => void;
} & IssueProps;

const Issue: React.FCC<Props> = ({
  dataTestId,
  level,
  message,
  nodeId,
  showLink = true,
  title,
  onClick,
  ...rest
}) => {
  const node = useRecoilValue(nodeById(nodeId ?? ''));
  const setInteraction = useSetRecoilState(interactionState);
  const heading = level === 'error' ? 'Foutmelding' : 'Waarschuwing';

  return (
    <Container
      issue={level}
      data-testid={dataTestId}
      onClick={onClick}
      {...rest}
    >
      {!isNil(title || node?.name) && (
        <CardHeading>{title || node?.name}</CardHeading>
      )}
      <MessageContainer>
        <Heading5 inline variant={Variant.primary}>
          {heading}:{' '}
        </Heading5>
        <TextContainer>{message}</TextContainer>
        {!isNil(node) && showLink && (
          <OpenActionButton
            data-testid={TEST_ID.GO_TO_ACTION_WITH_ISSUE}
            onClick={() => {
              if (node) {
                const interactionType: FormBuilder_Interaction =
                  node.__typename === 'FormBuilder_EventNode'
                    ? 'edit-node-event'
                    : 'edit-node-screen';
                setInteraction({
                  interactionType,
                  subjectId: node.id,
                });
              }
            }}
          >
            (aanpassen)
          </OpenActionButton>
        )}
      </MessageContainer>
    </Container>
  );
};

const Container = styled.div<{
  issue: IssueProps['level'];
}>(
  ({ theme, issue }) => css`
    background: ${issue === 'error'
      ? theme.color('danger', 'translucent')
      : theme.color('warning', 'light')};
    padding: ${theme.spacing('m')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;
    border: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    cursor: pointer;

    &:hover {
      border-color: ${theme.color(issue === 'error' ? 'danger' : 'warning')};
    }
  `,
);

const MessageContainer = styled.div<{}>`
  display: inline;
`;

const CardHeading = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('xxs')};
    font-weight: ${theme.fw('semiBold')};
    padding-bottom: ${theme.spacing('xxxs')};
  `,
);

const OpenActionButton = styled.span`
  ${linkStyles}
`;

const TextContainer = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.space('xxs')};
  `}
`;

export default Issue;
