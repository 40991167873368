import React from 'react';
import { OptionOf } from '~/components/molecule/Dropdown';
import {
  toggleBlock,
  type SimpleBlock,
} from '~/components/organism/PluginsEditor/commands/modify/block';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { OptionLabel } from '../..';

const options: Array<OptionOf<SimpleBlock>> = [
  {
    key: 'h1',
    payload: ELEMENTS.H1,
    label: 'Heading 1',
  },
  {
    key: 'h2',
    payload: ELEMENTS.H2,
    label: 'Heading 2',
  },
  {
    key: 'h3',
    payload: ELEMENTS.H3,
    label: 'Heading 3',
  },
  {
    key: 'h4',
    payload: ELEMENTS.H4,
    label: 'Heading 4',
  },
  {
    key: 'h5',
    payload: ELEMENTS.H5,
    label: 'Heading 5',
  },
  {
    key: 'h6',
    payload: ELEMENTS.H6,
    label: 'Heading 6',
  },
  {
    key: 'body',
    payload: ELEMENTS.DIV,
    label: 'Body',
  },
];

const getTypographyOptions = (editor: DHEditor): Array<OptionOf<string>> =>
  options.map(({ key, payload, label }) => ({
    key,
    label: <OptionLabel>{label}</OptionLabel>,
    payload,
    onClickAction: () => toggleBlock(editor, payload),
  }));

export default getTypographyOptions;
