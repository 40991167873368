import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { BlockContainer } from '../ContactBlock.style';
import SlideOpenAnimationContainer from '~/components/molecule/SlideOpenAnimationContainer';
import Icon from '~/components/atom/Icon';
import { Heading4, Variant } from '~/components/atom/Typography';

type Icon = 'GEAR' | 'HOME' | 'PERSON';

export type Props = {
  children: React.ReactNode;

  /** The title to show in the header of the block */
  title: string;

  /** Which icon to show */
  icon: Icon;

  loading?: boolean;
};

const FoldableCardBlock: React.FCC<Props> = ({
  dataTestId,
  children,
  title,
  icon,
  loading,
  ...rest
}) => {
  const [folded, setFolded] = useState<boolean>(false);

  const toggleFold = () => setFolded(prevState => !prevState);

  return (
    <BlockContainer data-testid={dataTestId} {...rest}>
      <Header data-testid="foldable-card-header" onClick={toggleFold}>
        <IconContainer>
          {icon === 'GEAR' && <Icon name="gear" inline />}
          {icon === 'HOME' && <Icon name="map-pin" />}
          {icon === 'PERSON' && <Icon name="user" inline />}
        </IconContainer>
        <HeaderText
          fontWeight="regular"
          withoutMargin
          variant={Variant.primary}
        >
          {title}
        </HeaderText>
        <ChevronContainer>
          <Icon name="chevron" flipX={folded} />
        </ChevronContainer>
      </Header>

      <SlideOpenAnimationContainer isOpen={!folded} useOverflowHidden={true}>
        {ref => (
          <Body ref={ref}>
            {loading ? <Spinner name="loading" /> : children}
          </Body>
        )}
      </SlideOpenAnimationContainer>
    </BlockContainer>
  );
};

const ChevronContainer = styled.div<{}>`
  grid-column: title-end / end;
`;

const IconContainer = styled.div<{}>(
  ({ theme }) => css`
    grid-column: start / title-start;
    font-size: ${theme.fs('m')};
  `,
);

const Body = styled.div<{}>`
  min-width: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('xxs')};
  `};
`;

const HeaderText = styled(Heading4)<{}>`
  grid-column: title-start / title-end;
`;

const Header = styled.div<{}>(
  ({ theme }) => css`
    min-width: 100%;
    display: grid;
    grid-template-columns: [start] 1em [title-start] auto [title-end] 1em [end];
    grid-template-rows: auto;
    grid-column-gap: ${theme.space('xs')};
    align-items: center;
    justify-items: start;
    cursor: pointer;
    user-select: none;

    padding: ${theme.space('xxs')};
    border-bottom: 1px solid ${theme.color('grey', 'light')};
  `,
);

const Spinner = styled(Icon)<{}>`
  display: block;
  & svg {
    margin: auto;
  }
  ${({ theme }) => css`
    padding: ${theme.space('xxs')};
  `};
`;

export default FoldableCardBlock;
