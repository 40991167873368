import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {};

const BlockQuote: React.FCC<Props> = ({ dataTestId, children, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    {children}
  </Container>
);

const Container = styled.blockquote<{}>(
  ({ theme }) => css`
    border-left: ${theme.getTokens().border.width.base} solid
      ${theme.color('grey', 'light')};
    padding-left: ${theme.space('xs')};
    margin: ${theme.space('m')} ${theme.space('xxs')};
  `,
);

export default BlockQuote;
