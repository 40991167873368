import React from 'react';

import cleanedFilename from '~/util/cleanedFilename';

type ModalContextProps = {
  showModal: (modal: React.ReactNode) => void;
  hideModal: () => void;
};
const ModalContext = React.createContext<ModalContextProps>({
  showModal: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | calling showModal before initialized`,
    );
  },
  hideModal: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | calling hideModal before initialized`,
    );
  },
});

export default ModalContext;
