import { ThemeColor } from '~/theme/System/tokens/colorPalette';

type Args = {
  activityType?: string | null;
  isTaskClosed?: boolean;
};

const getHeaderBackgroundForEvent = ({
  activityType,
  isTaskClosed,
}: Args): ThemeColor => {
  switch (activityType) {
    case 'Event_Contact_Call': {
      return { group: 'accent', variant: 'translucent' };
    }

    case 'Event_Contact_Note': {
      return { group: 'warning', variant: 'translucent' };
    }

    case 'Event_Contact_Email': {
      return { group: 'tertiary', variant: 'light' };
    }

    case 'Task_Contact': {
      return {
        group: isTaskClosed ? 'success' : 'primary',
        variant: 'translucent',
      };
    }

    default: {
      return { group: 'primary', variant: 'translucent' };
    }
  }
};

export default getHeaderBackgroundForEvent;
