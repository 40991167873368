import React, { useContext } from 'react';
import { InsertContactMutation as InsertContactMutationResultType } from '~/graphql/types';
import ModalContext from '~/contexts/ModalContext';
import AddContactModal, {
  ContactsMetaType,
} from '~/components/page/Contacts/components/AddContactModal';

type Args = {
  onComplete: (newContact: InsertContactMutationResultType) => void;
  contactsMeta: ContactsMetaType;
};

const useOnAddContact = ({ onComplete, contactsMeta }: Args) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const onAddContact = () =>
    showModal(
      <AddContactModal
        onClose={hideModal}
        onComplete={(...args) => {
          hideModal();
          onComplete(...args);
        }}
        contactsMeta={contactsMeta}
      />,
    );

  return onAddContact;
};

export default useOnAddContact;
