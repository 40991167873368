import cleanedFilename from '~/util/cleanedFilename';

export default {
  NAME: `${cleanedFilename(__filename)}-name`,
  DESCRIPTION: `${cleanedFilename(__filename)}-description`,
  ENABLED: `${cleanedFilename(__filename)}-enabled`,
  ACTION: `${cleanedFilename(__filename)}-action`,
  TRIGGER: `${cleanedFilename(__filename)}-trigger`,
  STATISTICS: `${cleanedFilename(__filename)}-statistics`,
  CREATED: `${cleanedFilename(__filename)}-created`,
  LAST_MODIFIED: `${cleanedFilename(__filename)}-last-modified`,
};
