import React from 'react';
import { BillingAddressFragment } from '~/graphql/types';
import { Body } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  address: BillingAddressFragment;
};

const BillingAddress: React.FCC<Props> = ({
  dataTestId,
  address: { line1, line2, postcode, city },
  ...rest
}) => (
  <JustificationContainer direction="column" data-testid={dataTestId} {...rest}>
    {line1 == null ? (
      ''
    ) : (
      <Body withoutMargin size="base">
        {line1}
        <br />
      </Body>
    )}
    {line2 == null ? (
      ''
    ) : (
      <Body withoutMargin size="base">
        {line2}
        <br />
      </Body>
    )}
    {postcode == null && city == null ? (
      ''
    ) : (
      <Body withoutMargin size="base">
        {postcode} {city} <br />
      </Body>
    )}
  </JustificationContainer>
);

export default BillingAddress;
