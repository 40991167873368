import getStartNode from '~/components/page/Forms/components/Builder/utils/getStartNode';
import type { FormBuilder_NodeFragment } from '~/graphql/types';

/**
 * Deletes the given node from nodes array, updates source node accordingly
 * @param {string} nodeId - Node id to delete
 * @param {Array<FormBuilder_NodeFragment>} nodes - Current nodes
 */
const deleteNode = ({
  nodeId,
  nodes,
}: {
  /** Node to delete */
  nodeId: string;

  /** Current nodes */
  nodes: Array<FormBuilder_NodeFragment>;
}): Array<FormBuilder_NodeFragment> => {
  const currentNode = nodes.find(n => n.id === nodeId);

  const currentSourceNode = nodes.find(
    node => node.defaultNext?.targetNodeId === nodeId,
  );
  const isStartNode = getStartNode(nodes).id === nodeId;

  if (!currentSourceNode || !currentNode || isStartNode) {
    // noop
    return nodes;
  }

  const currentNextNode = nodes.find(
    node => currentNode.defaultNext?.targetNodeId === node.id,
  );

  let updatedSourceNode: FormBuilder_NodeFragment = currentSourceNode;

  if (currentNextNode && !currentNextNode.id.startsWith('trailing-node')) {
    updatedSourceNode = {
      ...currentSourceNode,
      defaultNext: {
        __typename: 'FormBuilder_Node_DefaultNext',
        targetNodeId: currentNextNode.id,
      },
    };
  } else {
    updatedSourceNode = {
      ...currentSourceNode,
      defaultNext: null,
    };
  }

  const nextNodes = [
    ...nodes.filter(({ id }) => id !== nodeId && id !== updatedSourceNode.id),
    updatedSourceNode,
  ];

  return nextNodes;
};
export default deleteNode;
