import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { FormApi } from 'final-form';
import { ApolloError } from '@apollo/client';

import { ContactsMetaType } from '../AddContactModal';
import {
  InsertContactMutation as InsertContactMutationResultType,
  useInsertContactMutation,
} from '~/graphql/types';
import Validation from '~/util/Validation';
import FormUtils from '~/components/organism/FormUtils';
import Button from '~/components/atom/Button';
import Dropdown, { SelectedOption } from '~/components/molecule/Dropdown';
import { Form, Field, EmailField } from '~/components/organism/Forms';

import Catalog from '~/Catalog';
import ErrorTypes, { errorTypeFromMutationError } from '~/ErrorTypes';
import { isNil } from 'ramda';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import Input from '~/components/bad/Inputs/Input';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import useUserOptions from '~/hooks/useUserOptions';

const text = {
  emailLabel: Catalog.emailLabel,
  nameLabel: Catalog.nameLabel,
  phoneLabel: Catalog.phoneLabel,
  officeLabel: Catalog.officeLabel,
  userLabel: Catalog.userLabel,
  submit: 'Opslaan',
  generalErrorMsg: Catalog.genericUnknownErrorMessage,
  noName: Catalog.noName,
  noPhone: Catalog.noPhone,
  noEmail: Catalog.noEmail,
  wrongEmail: Catalog.invalidEmail,
  wrongPhone: Catalog.invalidPhone,
  genericAlreadyExistsMessage: 'Er bestaat al een contact met dit e-mailadres',
  alreadyExistsMessageStart: 'Er bestaat al een contact met het e-mailadres ',
  addContactButton: 'Nieuwe gebruiker',
  noOffice: Catalog.noOffice,
  noUser: Catalog.noUser,
};
type FormFields = {
  name: string | null;
  phone: string | null;
  email: string | null;
  userId: string | null;
  officeId: string | null;
};
type Props = {
  contactsMeta: ContactsMetaType;
  onNewContactAdded: (newContact: InsertContactMutationResultType) => void;
};
const AddSingleContact: React.FCC<Props> = ({
  onNewContactAdded,
  contactsMeta,
}) => {
  const [lastSubmittedEmail, setLastSubmittedEmail] = useState<string | null>(
    null,
  );

  const [insertContact, { error: mutationError, loading }] =
    useInsertContactMutation({
      onCompleted: _update => {
        if (_update) onNewContactAdded(_update);
      },
    });

  const officeOptions = useOfficeOptions();
  const [selectedOfficeId, setSelectedOfficeId] = useState(null);
  const userOptions = useUserOptions({ officeId: selectedOfficeId });

  const handleSubmit = async (
    fields: FormFields,
    form: FormApi<FormFields>,
  ) => {
    const name = fields.name ? fields.name : '';
    const phone = fields.phone ? fields.phone : undefined;
    const email = fields.email ? fields.email.toLocaleLowerCase() : '';
    const userId = fields.userId ? fields.userId : '';
    const officeId = fields.officeId ? fields.officeId : '';

    setLastSubmittedEmail(fields.email);

    const contactNew = {
      name,
      phone,
      email,
      userId,
      officeId,
      accountId: contactsMeta.accountId,
    };
    const result = await insertContact({
      variables: {
        userId,
        officeId,
        accountId: contactsMeta.accountId,
        contact: contactNew,
      },
    });

    // result is undefined if an error occured, so don't reset then
    if (result && result.data) {
      /** We could use form.restart() instead but it is not exposed in the types yet: https://github.com/final-form/react-final-form/issues/840 */
      form.resetFieldState('name');
      form.resetFieldState('phone');
      form.resetFieldState('email');
      form.resetFieldState('userId');
      form.resetFieldState('officeId');
      setTimeout(form.reset, 0);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={{
        name: null,
        phone: null,
        email: null,
        officeId: contactsMeta.officeId || null,
        userId:
          contactsMeta.officeId && contactsMeta.userId
            ? contactsMeta.userId
            : null,
      }}
    >
      {formProp => {
        const { handleSubmit, submitError, submitting, pristine } = formProp;

        const insertContactError =
          mutationError || submitError
            ? selectProperErrorMsg(mutationError, lastSubmittedEmail)
            : false;
        return (
          <form onSubmit={handleSubmit} data-testid="add-contact-form">
            {insertContactError ? (
              <ErrorMsg data-testid="add-contact-error">
                {insertContactError}
              </ErrorMsg>
            ) : null}

            <InputGroup>
              <Field name="name">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    label={text.nameLabel}
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    {...input}
                    tabIndex="1"
                    disabled={loading}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <EmailField name="email">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    tabIndex="2"
                    label={text.emailLabel}
                    error={FormUtils.showError(error, touched)}
                    {...input}
                    disabled={loading}
                  />
                )}
              </EmailField>
            </InputGroup>
            <InputGroup>
              <Field name="phone">
                {({ input, meta: { error, touched } }) => (
                  <Input
                    tabIndex="3"
                    label={text.phoneLabel}
                    type="text"
                    error={FormUtils.showError(error, touched)}
                    {...input}
                    disabled={loading}
                  />
                )}
              </Field>
            </InputGroup>

            <InputGroup>
              <Field name="officeId">
                {({ input: { onChange, value }, meta: { error, touched } }) => (
                  <Dropdown
                    margin={['m', null, null, null]}
                    tabIndex="4"
                    error={FormUtils.showError(error, touched)}
                    dataTestId="officeId-dropdown"
                    placeholder={text.officeLabel}
                    onChange={({ option }: SelectedOption) => {
                      const payload = option ? option.payload : null;
                      const officeId = payload?.id ? payload.id : null;
                      onChange(officeId);
                      setSelectedOfficeId(officeId);
                    }}
                    selectedOptionIdx={officeOptions.findIndex(
                      viewableOffice => viewableOffice.payload?.id == value,
                    )}
                    options={officeOptions}
                  />
                )}
              </Field>
            </InputGroup>
            <InputGroup>
              <Field name="userId">
                {({ input: { onChange, value }, meta: { error, touched } }) => {
                  const selectedOptionIdx = userOptions.findIndex(
                    viewableUser => viewableUser.payload?.id == value,
                  );

                  if (selectedOptionIdx === -1) {
                    const defaultId = userOptions[0].payload?.id;
                    onChange(defaultId || null);
                  }

                  return (
                    <Dropdown
                      margin={['m', null, null, null]}
                      tabIndex="5"
                      error={FormUtils.showError(error, touched)}
                      dataTestId="userId-dropdown"
                      placeholder={text.userLabel}
                      onChange={({ option }: SelectedOption) => {
                        const payload = option ? option.payload : null;

                        onChange(payload?.id ? payload.id : null);
                      }}
                      selectedOptionIdx={selectedOptionIdx}
                      options={userOptions}
                    />
                  );
                }}
              </Field>
            </InputGroup>

            <ActionWrapper>
              <Button
                size="medium"
                onClick={handleSubmit}
                appearance="secondary"
                type="submit"
                disabled={submitting || pristine || loading}
                data-testid="add-new-contact-submit"
                label={text.submit}
              />
            </ActionWrapper>
          </form>
        );
      }}
    </Form>
  );
};

const validate = (fields: FormFields) => {
  const errors: {
    name: string | undefined;
    phone: string | undefined;
    email: string | undefined;
    officeId: string | undefined;
    userId: string | undefined;
  } = {
    name: undefined,
    phone: undefined,
    email: undefined,
    officeId: undefined,
    userId: undefined,
  };
  if (Validation.String.isEmpty(fields.name)) {
    errors.name = text.noName;
  }

  if (!isNil(fields.phone) && !Validation.Phone.isValid(fields.phone)) {
    errors.phone = text.wrongPhone;
  }

  if (Validation.String.isEmpty(fields.email)) {
    errors.email = text.noEmail;
  } else {
    if (!Validation.Email.isValid(fields.email)) {
      errors.email = text.wrongEmail;
    }
  }

  if (Validation.String.isEmpty(fields.officeId)) {
    errors.officeId = text.noOffice;
  }

  if (Validation.String.isEmpty(fields.userId)) {
    errors.userId = text.noUser;
  }

  return errors;
};

const selectProperErrorMsg = (
  mutationError: ApolloError | null | undefined,
  email: string | null | undefined,
) => {
  if (errorTypeFromMutationError(mutationError) === ErrorTypes.contactExists) {
    if (!email) {
      return text.genericAlreadyExistsMessage;
    } else {
      return `${text.alreadyExistsMessageStart} ${email}`;
    }
  }
  return text.generalErrorMsg;
};

const ActionWrapper = styled.div<{}>`
  display: flex;
  align-items: center;
  padding-top: 1em;

  & button {
    margin-left: auto;
  }
`;

const ErrorMsg = styled.div<{}>(
  ({ theme }) => css`
    color: ${theme.color('danger')};
  `,
);

export default AddSingleContact;
