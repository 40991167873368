import React from 'react';
import { Flow___PrimitiveArgument } from '~/graphql/types.client';
import InputForAppearance from '../InputForAppearance';
import PrimitiveListDropdown from '../PrimitiveListDropdown';
import { Props as PrimitiveInputProps } from '../..';

export type Props = {
  input: PrimitiveInputProps['input'];
  value: Flow___PrimitiveArgument | null;
  onChange: (value: Flow___PrimitiveArgument) => void;
  onError?: (error: string | null) => void;
};

const InputForType: React.FCC<Props> = ({
  input,
  value,
  onChange,
  onError,
}) => {
  switch (input.__typename) {
    case 'FlowData___PrimitiveInput':
      return (
        <div style={{ width: '100%' }}>
          <InputForAppearance
            {...input}
            label="Vul een waarde in"
            value={value}
            onChange={onChange}
            // TODO: Fix
            // @ts-ignore
            onError={onError}
            name={input.__typename}
          />
        </div>
      );
    case 'FlowData___PrimitiveListInput':
      return (
        <PrimitiveListDropdown
          input={input}
          value={value}
          onChange={onChange}
        />
      );

    default:
      return null;
  }
};

export default InputForType;
