import React, { useState } from 'react';
import Input from '~/components/bad/Inputs/Input';
import { DoContactAction_Delete__Input } from '~/graphql/types';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import type { ActionModalProps } from '../../constants';

const text = {
  cancelButtonText: 'Annuleer',
  title: 'Verwijder contacten',
  message: 'Contacten kunnen niet worden hersteld',
  deleteModalLabel: "Typ 'verwijder' om te bevestigen",
  confirmButtonText: 'Verwijder',
  confirm: 'Verwijder',
  delete: 'verwijder',
};

export type Props = {} & ActionModalProps;

const Delete: React.FCC<Props> = ({ ...rest }) => {
  const [deleteModalValue, setDeleteModalValue] = useState<string>('');
  const textTypedCorrectly =
    deleteModalValue.toLowerCase() === text.delete ? true : false;

  return (
    <ConfirmActionModal
      actionType="Delete"
      disabled={!textTypedCorrectly}
      actionInput={{
        Delete: {
          delete: true,
        } as DoContactAction_Delete__Input,
      }}
      {...rest}
    >
      {/* @ts-ignore */}
      {loading => (
        <Input
          onChange={event => {
            if (event.target) {
              setDeleteModalValue(event.target.value);
            }
          }}
          value={deleteModalValue}
          label={text.deleteModalLabel}
          disabled={loading}
          name="typeDeleteInputField"
        />
      )}
    </ConfirmActionModal>
  );
};

export default Delete;
