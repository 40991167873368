import React from 'react';
import { Field } from 'react-final-form';

import FoldableCardBlock from '../FoldableCardBlock';
import TagListInput from '~/components/molecule/TagListInput';

type Props = {
  /** If the form is disabled */
  isFormDisabled: boolean;
};
const ContactTagsBlock: React.FCC<Props> = ({ isFormDisabled }) => (
  <FoldableCardBlock icon="GEAR" title="Tags">
    <Field name="tags">
      {({ input: { value, onChange } }) => {
        const list = convertTagStringToList(value);

        return (
          <TagListInput
            selectedValues={list}
            onAdded={newTag => {
              list.push(newTag);

              onChange(convertTagListToString(list));
            }}
            onRemoved={removedTag => {
              const newList = list.filter(tag => tag !== removedTag);

              onChange(convertTagListToString(newList));
            }}
            disabled={isFormDisabled}
          />
        );
      }}
    </Field>
  </FoldableCardBlock>
);

export const convertTagStringToList = (tags: string | null): Array<string> => {
  if (tags == null || tags == '') return [];

  return tags.split(',');
};

export const convertTagListToString = (
  tagList: Array<string>,
): string | null => {
  const listString = tagList.reduce((acc, val) => acc + val + ',', '');

  if (listString === '') return null;

  return listString.substring(0, listString.length - 1);
};

export default ContactTagsBlock;
