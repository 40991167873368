import React, { useEffect, useMemo, useState } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Loading from '~/components/atom/Loading';

import { Body, Heading4, Variant } from '~/components/atom/Typography';
import {
  OutputFieldInvisible,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import useApps, { type ExtendedAppConfig } from '~/hooks/useApps';
import useWizardStep from '~/hooks/useWizardStep';
import { AppConfig } from '~/components/page/Apps/types';
import CollapsibleAppBlock from './components/CollapsibleAppBlock';
import { isEmpty } from 'lodash';
import TextButton from '~/components/atom/TextButton';
import { AppsAndItemsToEnable } from '../SelectAppsForWidget';
import Summary from './components/Summary';
import useWizard from '~/hooks/useWizard';

export const id = 'ConfirmAppsEnablement';
export const title = 'Widget apps activeren';

const text = {
  agreeOnTermsHeader: 'Gebruiksvoorwaarden',
  selectAll: 'Alles selecteren',
  deselectAll: 'Alles deselecteren',

  body: (
    <>
      Om de door jou geselecteerde apps aan te zetten, dien je akkoord te gaan
      met de gebruiksvoorwaarden.
      <br />
      <br />
      Wil je een app toch niet activeren? Ga dan terug naar het vorige stap en
      zet de geselecteerde app uit.
    </>
  ),
};

export type OutputType = {
  type: typeof id;
  appsToEnable: OutputFieldInvisible<AppsAndItemsToEnable | null>;
};

export const Component: React.FCC<WizardStepProps> = ({ outputMap, step }) => {
  const wizardApi = useWizard();

  const selectedAppsToEnable =
    outputMap.SelectAppsForWidget &&
    'appsAndItemsToEnable' in outputMap.SelectAppsForWidget &&
    outputMap.SelectAppsForWidget.appsAndItemsToEnable.value;

  const [confirmedAppsToEnable, setConfirmedAppsToEnable] =
    useState<AppsAndItemsToEnable | null>(null);

  const [appsToDisplay, setAppsToDisplay] = useState<Array<ExtendedAppConfig>>(
    [],
  );
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const { apps, loading } = useApps();

  const confirmedAppKeys = useMemo(
    () => (confirmedAppsToEnable ? Object.keys(confirmedAppsToEnable) : []),
    [confirmedAppsToEnable],
  );

  useEffect(() => {
    if (!loading && apps) {
      // From the selected widget items display dedicated apps that needs to be enabled
      const appsToEnable = selectedAppsToEnable
        ? Object.keys(selectedAppsToEnable)
            .map(appTypename =>
              selectedAppsToEnable[appTypename].length
                ? apps[appTypename]
                : null,
            )
            .filter(app => app !== null)
        : [];
      setAppsToDisplay(appsToEnable);
    }
  }, [apps, loading, selectedAppsToEnable]);

  useEffect(() => {
    if (confirmedAppKeys.length === appsToDisplay.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [appsToDisplay.length, confirmedAppKeys]);

  const toggleSelectAll = () => {
    const allSelected = confirmedAppKeys.length === appsToDisplay.length;
    if (allSelected) {
      // Deselect all
      setAllSelected(false);
      setConfirmedAppsToEnable(null);
    } else {
      if (selectedAppsToEnable) {
        setAllSelected(true);
        const allSelectedApps = appsToDisplay.reduce((acc, curr) => {
          if (!acc[curr.__typename]) {
            acc[curr.__typename] = selectedAppsToEnable[curr.__typename];
          }
          return acc;
        }, {} as AppsAndItemsToEnable);

        setConfirmedAppsToEnable(allSelectedApps);
      }
    }
  };

  const onSelectApp = (app: AppConfig) => {
    if (selectedAppsToEnable) {
      if (confirmedAppsToEnable && confirmedAppsToEnable[app.__typename]) {
        // Delete app from confirmedAppsToEnable if uncheck
        setConfirmedAppsToEnable((prev: AppsAndItemsToEnable) => {
          const updatedConfirmedApps = { ...prev };
          delete updatedConfirmedApps[app.__typename];

          return updatedConfirmedApps;
        });
      } else {
        setConfirmedAppsToEnable((prev: AppsAndItemsToEnable) => ({
          ...prev,
          [app.__typename]: selectedAppsToEnable[app.__typename],
        }));
      }
    }
  };

  const [, api] = useWizardStep(step.id);
  useEffect(() => {
    if (!loading) {
      const output: OutputType = {
        type: id,
        appsToEnable: {
          type: 'invisible',
          value: confirmedAppsToEnable,
        },
      };

      // Disable goNext button if not all terms are agreed on
      if (
        (appsToDisplay.length > 0 && !confirmedAppsToEnable) ||
        appsToDisplay.length !== confirmedAppKeys.length
      ) {
        api.lock(output);
      } else {
        // Step ready to go from mount so free it up
        api.free(output);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appsToDisplay.length,
    confirmedAppKeys.length,
    confirmedAppsToEnable,
    loading,
  ]);

  useEffect(() => {
    if (
      !selectedAppsToEnable ||
      (typeof selectedAppsToEnable === 'object' &&
        !Object.keys(selectedAppsToEnable).length &&
        !loading)
    ) {
      return wizardApi.next();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsToDisplay, loading]);

  if (loading) return <Loading />;

  return (
    <>
      <Body>{text.body}</Body>

      {appsToDisplay.length > 0 && (
        <JustificationContainer
          margin={['m', null, null, null]}
          direction="column"
          gap="base"
        >
          <JustificationContainer width="100%" direction="column">
            <Heading4 variant={Variant.primary}>
              {text.agreeOnTermsHeader}
            </Heading4>
            {appsToDisplay.map(app => (
              <CollapsibleAppBlock
                onSelectApp={onSelectApp}
                key={app.appType}
                app={app}
                outputMap={outputMap}
                confirmedAppsToEnable={confirmedAppsToEnable}
              />
            ))}
          </JustificationContainer>
          <TextButton
            icon={allSelected ? 'check-square' : 'square'}
            size="medium"
            withoutPadding
            onClick={toggleSelectAll}
            label={allSelected ? text.deselectAll : text.selectAll}
          />
          {confirmedAppsToEnable && !isEmpty(confirmedAppsToEnable) && (
            <Summary
              confirmedAppsToEnable={confirmedAppsToEnable}
              outputMap={outputMap}
              apps={apps}
            />
          )}
        </JustificationContainer>
      )}
    </>
  );
};

export default {
  id,
  title,
};
