import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Body, Label, Variant } from '~/components/atom/Typography';
import { isEmpty } from 'ramda';
import {
  OutputFieldColor,
  OutputFieldImage,
  OutputFieldItem,
  WizardStepProps,
} from '~/components/organism/Wizard/context/WizardContext';
import {
  DhImage,
  GetWidgetSettingsQuery,
  useGetAccountQuery,
  useGetWidgetSettingsQuery,
  useUpdateAccountMutation,
  useUpdateWidgetSettingsMutation,
  WidgetSettingsLogo,
  type GetAccountQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useWizardStep from '~/hooks/useWizardStep';
import ColorInput from '~/components/molecule/ColorInput';
import ImageInput from '~/components/organism/ImageInput';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import hasValue from '~/util/hasValue';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import TEST_ID from './index.testid';
import { OutputMap } from '../..';
import useErrorReporter from '~/hooks/useErrorReporter';
import Catalog from '~/Catalog';
import settingsToInput from './utils/settingsToInput';
import isTemporaryImagePath from '~/hooks/useImageUpload/utils/isTemporaryImagePath';
import WidgetTriggerPreview from './components/WidgetTriggerPreview';
import withRequiredMark from '~/hocs/withRequiredMark';
import webSiteURLValidation from '~/util/websiteURLValidation';

const text = {
  logoLabel: 'Logo',
  imageCategory: 'Ontwerp',
  websiteLabel: 'Adres van je website',
  highlightImageLabel: 'Kantoorfoto',
  colors: {
    primary: {
      background: 'Primaire kleur',
      color: 'Contrasterende kleur',
    },
    secondary: {
      background: 'Accent kleur',
      color: 'Contrasterende kleur',
    },
  },
  colorGroupTitle: 'Huisstijl instellen',
  widgetUrlTitle: 'Wat is het adres van je website?',
  body: (
    <>
      De motor van DatHuis is de krachtige webwinkel widget. Dit is een
      uitbreiding voor je website waarmee bezoekers een verleidelijke pop-up te
      zien krijgen die uitnodigt om informatie of diensten aan te vragen.
      Hiermee genereer jij nieuwe leads.
      <br />
      <br />
      Hier pas je de widget aan op je eigen huisstijl. Voeg je logo, teamfoto en
      kleuren toe om deze mooi in te passen op jouw website.
    </>
  ),
  urlIsRequiredError: 'Adres van je website is een verplicht veld',
  urlFieldErrorMsg: `"Wat is het adres van je website?" is een verplicht veld`,
};

export const id = 'WidgetStyleConfiguration';
export const title = 'Webwinkel widget instellen';

export type OutputType = {
  type: typeof id;
  widgetLogo: OutputFieldImage<WidgetSettingsLogo | null | undefined>;
  websiteUrl: OutputFieldItem;
  widgetImage: OutputFieldImage<DhImage | null | undefined>;
  primaryBackgroundColor: OutputFieldColor;
  primaryColor: OutputFieldColor;
  secondaryBackgroundColor: OutputFieldColor;
  secondaryColor: OutputFieldColor;
  completed: boolean;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const account = useCurrentAccount();
  const currentOutput = outputMap[id] as OutputType;

  const { data: widgetSettingsData, loading } = useGetWidgetSettingsQuery({
    variables: {
      accountId: account.id,
    },
  });

  const { data: accountData, loading: accountDataLoading } = useGetAccountQuery(
    {
      variables: {
        accountId: account.id,
      },
    },
  );

  const [stepOutput, setStepOutput] = useState(currentOutput);
  const [urlRequiredError, setUrlError] = useState<string | null>(null);
  const websiteUrlRef = useRef<HTMLInputElement | null>(null);

  const [updateWidgetSettings] = useUpdateWidgetSettingsMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const errorReporter = useErrorReporter();

  useEffect(() => {
    if (urlRequiredError !== null && websiteUrlRef.current) {
      websiteUrlRef.current.focus();
    }
  }, [urlRequiredError]);

  const onBeforeNext = useCallback(
    async (outputMap: OutputMap) => {
      const updatedOutput = outputMap[id] as OutputType;

      const validatedWebsiteUrl = webSiteURLValidation(
        updatedOutput.websiteUrl.value,
      );
      if (validatedWebsiteUrl !== true) {
        setUrlError(validatedWebsiteUrl);
        throw new Error(validatedWebsiteUrl);
      }

      if (!widgetSettingsData?.getWidgetSettings) {
        errorReporter.captureException(
          new Error(`GetWidgetSettings has no data in ${id} wizard step`),
          'debug',
        );

        throw new Error(Catalog.genericUnknownErrorMessage);
      }

      const { widgetSettingsInput, logo, colors } = settingsToInput(
        widgetSettingsData.getWidgetSettings,
        updatedOutput,
      );

      // UPDATE WIDGET SETTINGS
      const updateWidgetResult = await updateWidgetSettings({
        variables: {
          accountId: account.id,
          widgetSettings: widgetSettingsInput,
        },
      });
      if (updateWidgetResult.errors && updateWidgetResult.errors.length) {
        errorReporter.captureException(
          new Error(
            `Errors on updateWidgetSettings in ${id} wizard step: ${JSON.stringify(
              updateWidgetResult.errors,
            )}`,
          ),
          'debug',
        );

        // Caught in the catch block of onBeforeNext. Shows a toast message and persists on the current step
        throw new Error(Catalog.genericUnknownErrorMessage);
      }

      // UPDATE ACCOUNT SETTINGS
      const url = updatedOutput.websiteUrl.value;
      const initialAccountLogo =
        accountData?.getAccount?.AccountSettings?.logo?.s3key;
      const updateAccountResult = await updateAccount({
        variables: {
          accountId: account.id,
          update: {
            name: account.name,
            settings: {
              url: hasValue(url) ? url : null,
              colors: colors,
              // BE accepts the same temporary image path (e.g - tmpPublic/eu-west-1:32ed....) for both mutations,
              // but if it is a permanent path (e.g - acc/a---95ca1741-c....), we should send the permanent path for account settings specifically
              logo:
                logo?.s3key && isTemporaryImagePath(logo.s3key)
                  ? { s3key: logo.s3key }
                  : initialAccountLogo
                    ? { s3key: initialAccountLogo }
                    : null,
            },
          },
        },
      });
      if (updateAccountResult.errors && updateAccountResult.errors.length) {
        errorReporter.captureException(
          new Error(
            `Errors on updateAccount in ${id} wizard step: ${JSON.stringify(
              updateAccountResult.errors,
            )}`,
          ),
          'debug',
        );

        // Caught in the catch block of onBeforeNext. Shows a toast message and persists on the current step
        throw new Error(Catalog.genericUnknownErrorMessage);
      }

      // Make sure to replace temporary values with permanent values
      if (updateWidgetResult.data) {
        const newOutput = {
          ...updatedOutput,
          completed: true,
          widgetImage: {
            ...updatedOutput.widgetImage,
            value: updateWidgetResult.data.updateWidgetSettings.highlightImage,
          },
          widgetLogo: {
            ...updatedOutput.widgetLogo,
            value: updateWidgetResult.data.updateWidgetSettings.logo,
          },
        };
        return newOutput;
      }

      return { ...updatedOutput, completed: true };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widgetSettingsData?.getWidgetSettings],
  );

  useEffect(() => {
    // Set initial/previous state
    if (widgetSettingsData && accountData) {
      setStepOutput(prevOutput => {
        const nextOutput = dataToOutput(
          widgetSettingsData.getWidgetSettings,
          prevOutput,
          accountData.getAccount,
        );

        return nextOutput;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetSettingsData, accountData]);

  const stepOptions = useMemo(
    () => ({
      onBeforeNext,
    }),
    [onBeforeNext],
  );

  const [, api] = useWizardStep(step.id, stepOptions);

  useEffect(() => {
    if (!loading && !accountDataLoading) {
      api.free(stepOutput);
    } else {
      api.lock(stepOutput);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepOutput, accountDataLoading, loading]);

  if (loading || accountDataLoading) return <DatHuisLoading />;

  return (
    <JustificationContainer>
      <JustificationContainer
        gap="l"
        direction="column"
        margin={[null, null, 'xl', null]}
      >
        <Body withoutMargin>{text.body}</Body>
        <ImageInput
          initialUrl={stepOutput.widgetLogo.value?.url}
          onChange={widgetLogo => {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              widgetLogo: {
                category: text.imageCategory,
                type: 'image',
                label: text.logoLabel,
                value: widgetLogo
                  ? {
                      __typename: 'WidgetSettingsLogo',
                      s3key: widgetLogo.s3key ?? '',
                      url: widgetLogo.url ?? '',
                    }
                  : null,
              },
            }));
          }}
          s3Key={stepOutput.widgetLogo.value?.s3key}
          title={text.logoLabel}
          filename="widgetLogo"
          dataTestId={TEST_ID.LOGO_INPUT}
        />
        <ImageInput
          initialUrl={stepOutput.widgetImage.value?.url}
          onChange={widgetImage => {
            setStepOutput(prevOutput => ({
              ...prevOutput,
              type: id,
              widgetImage: {
                category: text.imageCategory,
                type: 'image',
                label: text.highlightImageLabel,
                value: widgetImage
                  ? {
                      __typename: 'DHImage',
                      s3key: widgetImage.s3key ?? '',
                      url: widgetImage.url ?? '',
                    }
                  : null,
              },
            }));
          }}
          s3Key={stepOutput.widgetImage.value?.s3key}
          title={text.highlightImageLabel}
          filename="widgetImage"
          dataTestId={TEST_ID.HIGHLIGHT_IMAGE_INPUT}
        />
        <JustificationContainer direction="column" width="100%">
          <RequiredField variant={Variant.primary} size="m">
            {text.widgetUrlTitle}
          </RequiredField>
          <Input
            ref={websiteUrlRef}
            width="100%"
            size="large"
            placeholder="https://www.makelaar.nl"
            validation={[webSiteURLValidation]}
            value={stepOutput.websiteUrl.value}
            externalErrors={urlRequiredError ? [urlRequiredError] : undefined}
            onChange={e => {
              setUrlError(null);
              setStepOutput(prevOutput => ({
                ...prevOutput,
                websiteUrl: {
                  value: e.target.value,
                  category: text.websiteLabel,
                  type: 'item',
                  icon: 'link',
                  label: e.target.value,
                },
              }));
            }}
            dataTestId={TEST_ID.WEBSITE_URL_INPUT}
          />
        </JustificationContainer>
        <JustificationContainer direction="column" width="100%">
          <Label variant={Variant.primary} size="m">
            {text.colorGroupTitle}
          </Label>
          <JustificationContainer
            margin={[null, null, 'm', null]}
            width="100%"
            gap="l"
          >
            <ColorInput
              value={stepOutput.primaryBackgroundColor.value}
              onChange={value => {
                setStepOutput(prevOutput => ({
                  ...prevOutput,
                  primaryBackgroundColor: {
                    ...prevOutput.primaryBackgroundColor,
                    value,
                  },
                }));
              }}
              label={text.colors.primary.background}
              disabled={loading}
              dataTestId={TEST_ID.PRIMARY_BACKGROUND_COLOR_INPUT}
            />
            <ColorInput
              value={stepOutput.primaryColor.value}
              onChange={value => {
                setStepOutput(prevOutput => ({
                  ...prevOutput,
                  primaryColor: {
                    ...prevOutput.primaryColor,
                    value,
                  },
                }));
              }}
              label={text.colors.primary.color}
              disabled={loading}
              dataTestId={TEST_ID.PRIMARY_COLOR_INPUT}
            />
          </JustificationContainer>

          <JustificationContainer width="100%" gap="l">
            <ColorInput
              value={stepOutput.secondaryBackgroundColor.value}
              onChange={value => {
                setStepOutput(prevOutput => ({
                  ...prevOutput,
                  secondaryBackgroundColor: {
                    ...prevOutput.secondaryBackgroundColor,
                    value,
                  },
                }));
              }}
              label={text.colors.secondary.background}
              disabled={loading}
              dataTestId={TEST_ID.SECONDARY_BACKGROUND_COLOR_INPUT}
            />
            <ColorInput
              value={stepOutput.secondaryColor.value}
              onChange={value => {
                setStepOutput(prevOutput => ({
                  ...prevOutput,
                  secondaryColor: {
                    ...prevOutput.secondaryColor,
                    value,
                  },
                }));
              }}
              label={text.colors.secondary.color}
              disabled={loading}
              dataTestId={TEST_ID.SECONDARY_COLOR_INPUT}
            />
          </JustificationContainer>

          <JustificationContainer
            margin={['base', null, null, null]}
            padding={['xs', 'base', 'xl', 'base']}
            align="center"
            justification="center"
            border={{ width: 's', color: { group: 'grey' }, radius: 'base' }}
            width="100%"
          >
            {/* Will be replaced with the real widget preview soon - https://app.shortcut.com/dathuis/epic/10846/dedicated-widget-page?group_by=none&ct_workflow=all&cf_workflow=500001048 */}
            <WidgetTriggerPreview
              colors={{
                primary: {
                  __typename: 'WidgetSettingsStyleConfigColorPair',
                  color: stepOutput.primaryColor.value,
                  background: stepOutput.primaryBackgroundColor.value,
                },
                secondary: {
                  __typename: 'WidgetSettingsStyleConfigColorPair',
                  color: stepOutput.secondaryColor.value,
                  background: stepOutput.secondaryBackgroundColor.value,
                },
              }}
            />
          </JustificationContainer>
        </JustificationContainer>
      </JustificationContainer>
    </JustificationContainer>
  );
};

const RequiredField = withRequiredMark(Label);

const dataToOutput = (
  data: GetWidgetSettingsQuery['getWidgetSettings'],
  prevOutput: OutputType,
  accountData: GetAccountQuery['getAccount'],
): OutputType => {
  if (!data) return prevOutput;
  const websiteUrl = prevOutput.websiteUrl.value;
  const accountSettings = accountData?.AccountSettings;
  const accountColors = accountSettings?.colors;

  return {
    ...prevOutput,
    type: id,
    widgetLogo: {
      category: text.imageCategory,
      type: 'image',
      label: text.logoLabel,
      value: hasValue(accountSettings?.logo)
        ? {
            __typename: 'WidgetSettingsLogo',
            s3key: accountSettings.logo.s3key,
            url: accountSettings.logo.url,
          }
        : data.logo,
    },
    widgetImage: {
      category: text.imageCategory,
      type: 'image',
      label: text.highlightImageLabel,
      value: data.highlightImage,
    },
    websiteUrl: {
      type: 'item',
      category: text.websiteLabel,
      label: hasValue(accountSettings?.url) ? accountSettings.url : websiteUrl,
      icon: 'link',
      value: hasValue(accountSettings?.url) ? accountSettings?.url : websiteUrl,
    },

    primaryBackgroundColor: {
      ...prevOutput.primaryBackgroundColor,
      label: text.colors.primary.background,
      value: isEmpty(prevOutput?.primaryBackgroundColor.value)
        ? accountColors?.primary.background || data.style.primary.background
        : prevOutput?.primaryBackgroundColor.value,
    },
    primaryColor: {
      ...prevOutput.primaryColor,
      label: text.colors.primary.color,
      value: isEmpty(prevOutput?.primaryColor.value)
        ? accountColors?.primary.color || data.style.primary.color
        : prevOutput?.primaryColor?.value,
    },
    secondaryBackgroundColor: {
      ...prevOutput.secondaryBackgroundColor,
      label: text.colors.secondary.background,
      value: isEmpty(prevOutput?.secondaryBackgroundColor.value)
        ? accountColors?.secondary.background || data.style.secondary.background
        : prevOutput?.secondaryBackgroundColor?.value,
    },
    secondaryColor: {
      ...prevOutput.secondaryColor,
      label: text.colors.secondary.color,
      value: isEmpty(prevOutput?.secondaryColor?.value)
        ? accountColors?.secondary.color || data.style.secondary.color
        : prevOutput?.secondaryColor?.value,
    },
    completed: false,
  };
};

export default {
  id,
  title,
};
