import type { FormBuilderFragment } from '~/graphql/types';
import type { Issue } from '../../state/issues';
import { isEmpty, isNil, keys } from 'ramda';
import { isNonEmptyString } from '~/util/Validation/String';

const messages = {
  missingName: 'Een naam is verplicht voor formulieren',
  missingDescription: 'Er is (nog) geen omschrijving voor dit formulier',
  missingLocale: 'Er is tenminste 1 taal nodig',
  missingLinkForTheLogo: 'Adres van je website is vereist in Thema',
};

const getIssuesForForm = (form: FormBuilderFragment): Array<Issue> =>
  keys(form)
    .map((formKey): Issue | null => {
      switch (formKey) {
        case 'name': {
          if (isNonEmptyString(form[formKey])) {
            return null;
          }

          return {
            level: 'error',
            message: messages.missingName,
          };
        }
        case 'description': {
          if (isNonEmptyString(form[formKey])) {
            return null;
          }

          // Returning warning because its allowed to be empty
          return {
            level: 'warning',
            message: messages.missingDescription,
          };
        }
        case 'style': {
          if (form.style.availableLocale.length === 0) {
            return {
              level: 'error',
              message: messages.missingLocale,
            };
          }

          const theme = form.style.theme;

          if (
            !isNil(theme.logo) &&
            (isEmpty(theme.logoLink) || isNil(theme.logoLink))
          ) {
            return {
              level: 'error',
              message: messages.missingLinkForTheLogo,
            };
          }
        }

        // Fall through, we're handling these in their dedicated areas
        case 'nodes':
        case 'events':
        case 'optionLists':
        case 'id':
        case '__typename':
        case 'accountId':
        default:
          return null;
      }
    })
    .filter((entry): entry is Issue => !isNil(entry));

export default getIssuesForForm;
