import { MutationTuple } from '@apollo/client';
import React from 'react';

import {
  UpdateContactMutation,
  UpdateContactMutationVariables,
} from '~/graphql/types';

import cleanedFilename from '~/util/cleanedFilename';

export type ContactsContextProps = {
  updateContactFn: MutationTuple<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >[0];
  loading: boolean;
};

const ContactsContext = React.createContext<ContactsContextProps>({
  updateContactFn: () => {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | updateContactFn not initialised before calling!`,
    );
  },
  loading: false,
});

export default ContactsContext;
