import React, { useState } from 'react';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import TEST_ID from './index.testid';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import ColorInput from '~/components/molecule/ColorInput';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ImageUploadComponent from '~/components/organism/ImageUploadComponent';
import uploadS3Image from '~/util/uploadS3Image';
import useCurrentUser from '~/hooks/useCurrentUser';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';
import {
  AppValuationReportGeneral_Update,
  AppValuationReportLogoPosition,
} from '~/graphql/types';
import SelectGroup, { Option } from '~/components/molecule/SelectGroup';
import InputWithLabel from '../../components/InputWithLabel';
import Input from '~/components/molecule/Input';
import Div from '~/components/atom/Div';
import { isValidURL } from '~/util/Validation/URL';
import ColorPickerWithTransparentOption from '../../components/ColorPickerWithTransparantOption';
import { useRecoilState } from 'recoil';
import { defaultErrorsByPath, defaultValueReportImageStash } from '../../state';
import isTemporaryImagePath from '~/hooks/useImageUpload/utils/isTemporaryImagePath';
import Textarea from '~/components/bad/Inputs/Textarea';
import BrandSettingsCTA from '~/components/organism/BrandSettingsCTA';
import brandValuesToValueReportValues from './utils/brandValuesToValueReportValues';
import hasValue from '~/util/hasValue';
import ImageInput from '~/components/organism/ImageInput';

const text = {
  title: 'Ontwerp',
  logo: 'Logo',
  logoPosition: 'Logo positie',
  mainColor: 'Hoofdkleur',
  headerTextColor: 'Koptekstkleur',
  accentColor: 'Accentkleur',
  navbarBackgroundColor: 'Navbar achtergrondkleur',
  backgroundColor: 'Achtergrondkleur',
  backgroundImage: 'Achtergrond afbeelding',
  nextButtonMainText: 'Hoofdtekst volgende knop',
  nextButtonMainTextColor: 'Kleur hoofdtekst volgende knop',
  nextButtonSubText: 'Subtekst volgende knop',
  nextButtonSubTextColor: 'Kleur subtekst volgende knop',
  nextButtonBackgroundColor: 'Achtergrondkleur volgende knop',
  stepColor: 'Stap indicator tekstkleur',
  stepBackgroundColor: 'Stap indicator achtergrondkleur',
  left: 'Links',
  middle: 'Midden',
  right: 'Rechts',
  logoLink: 'Logo link',
  logoLinkError: 'Moet beginnen met http:// of https://',
  logoPlaceholder: 'https://vastgoedprofessional.nl/',
};

type ImageField = 'logoImage' | 'backgroundImage';

const pathname = '/design';

export type Props = RouteComponentProps & {
  dataTestId?: string;
  loading: boolean;
  updated?: AppValuationReportGeneral_Update | null;
  onUpdate: (value: AppValuationReportGeneral_Update) => void;
};

const Design: React.FCC<Props> = ({ updated, loading, onUpdate }) => {
  const { id: userId } = useCurrentUser();
  const addToast = useAddToast();

  const [errorsByPath, setErrorsByPath] = useRecoilState(defaultErrorsByPath);
  const [imageStash, setImageStash] = useRecoilState(
    defaultValueReportImageStash,
  );
  const [brandLogoUrl, setBrandLogoUrl] = useState<string | null>(null);

  const [imageLoadingId, setImageLoadingId] = useState<ImageField | null>(null);

  const onImageUpload = async (files: FileList, fieldName: ImageField) => {
    setImageLoadingId(fieldName);

    try {
      const { path, permanentLink } = await uploadS3Image({
        file: files[0],
        uploadImageName: `${userId}/${fieldName}`,
      });

      setImageStash(prev => ({ ...prev, [fieldName]: permanentLink }));
      onUpdate({ [fieldName]: path });
      setImageLoadingId(null);
    } catch (error) {
      addToast([formatToastMessage(Catalog.imageUploadErrorMessage, 'danger')]);
      setImageLoadingId(null);
    }
  };

  const updatedBackgroundImage = updated?.backgroundImage;

  return (
    <AppDetailsContainer
      header={text.title}
      icon="layout"
      loading={loading}
      dataTestId={TEST_ID.CONTAINER}
    >
      <BrandSettingsCTA
        subjects={['logo', 'colors']}
        onConfirm={values => {
          const updatedValues = brandValuesToValueReportValues(values);
          if (hasValue(updatedValues.logoImage)) {
            setBrandLogoUrl(updatedValues.logoImage.url);
            onUpdate({ logoImage: updatedValues.logoImage.s3key });
          }

          if (hasValue(updatedValues.colors)) {
            Object.keys(updatedValues.colors).map(key =>
              onUpdate({ [key]: updatedValues.colors![key] }),
            );
          }
        }}
      />
      <JustificationContainer
        direction="column"
        gap="l"
        margin={['m', null, null, null]}
      >
        <ImageInput
          initialUrl={
            brandLogoUrl || (updated?.logoImage as string | undefined)
          }
          onChange={value => {
            setBrandLogoUrl(null);
            onUpdate({ logoImage: hasValue(value) ? value.s3key : null });
          }}
          s3Key={updated?.logoImage as string | undefined}
          title={text.logo}
          filename="logo"
        />

        <InputWithLabel label={text.logoPosition}>
          <SelectGroup
            selectedIndex={logoPositionOptions.findIndex(
              opt => opt.value === updated?.logoPosition,
            )}
            onChange={opt => {
              onUpdate({ logoPosition: opt.option?.value || null });
            }}
            options={logoPositionOptions}
          />
        </InputWithLabel>

        <Div width="100%">
          <Input
            label={text.logoLink}
            name="logo-link"
            onChange={e => {
              if (e.target) {
                onUpdate({ logoLink: e.target.value || null });

                if (!isValidURL(e.target.value)) {
                  setErrorsByPath(prev => ({
                    ...prev,
                    [pathname]: text.logoLinkError,
                  }));
                } else {
                  setErrorsByPath(prev => ({ ...prev, [pathname]: null }));
                }
              }
            }}
            placeholder={text.logoPlaceholder}
            value={updated?.logoLink || ''}
            externalErrors={
              errorsByPath[pathname] ? [errorsByPath[pathname]] : undefined
            }
          />
        </Div>

        <JustificationContainer width="100%" gap="l">
          <ColorInput
            value={updated?.mainColor || ''}
            onChange={color => onUpdate({ mainColor: color })}
            label={text.mainColor}
            dataTestId="picker_design-mainColor"
          />
          <ColorInput
            value={updated?.headerTextColor || ''}
            onChange={color => onUpdate({ headerTextColor: color })}
            label={text.headerTextColor}
            dataTestId="picker_design-headerTextColor"
          />
          <ColorInput
            value={updated?.accentColor || ''}
            onChange={color => onUpdate({ accentColor: color })}
            label={text.accentColor}
            dataTestId="picker_design-accentColor"
          />
          <ColorInput
            value={updated?.backgroundColor || ''}
            onChange={color => onUpdate({ backgroundColor: color })}
            label={text.backgroundColor}
            dataTestId="picker_design-backgroundColor"
          />
        </JustificationContainer>

        <InputWithLabel label={text.backgroundImage}>
          <ImageUploadComponent
            uploading={imageLoadingId === 'backgroundImage'}
            dataTestId={'backgroundImage-uploader'}
            imageUrl={
              isTemporaryImagePath(updatedBackgroundImage || '')
                ? imageStash.backgroundImage
                : updatedBackgroundImage
            }
            onUpload={async (files: FileList) =>
              onImageUpload(files, 'backgroundImage')
            }
            onDelete={() => {
              onUpdate({ backgroundImage: null });
              setImageStash(prev => ({ ...prev, backgroundImage: null }));
            }}
          />
        </InputWithLabel>

        <InputWithLabel label={text.nextButtonMainText}>
          <Textarea
            name={'text'}
            value={updated?.nextButton?.mainText?.text ?? null}
            onChange={value =>
              onUpdate({ nextButton: { mainText: { text: value } } })
            }
            dataTestId="text-edit-mainText-text"
          />
        </InputWithLabel>

        <JustificationContainer width="100%" gap="l" align="end">
          <ColorInput
            value={updated?.nextButton?.mainText?.color || ''}
            onChange={color =>
              onUpdate({ nextButton: { mainText: { color } } })
            }
            label={text.nextButtonMainTextColor}
            dataTestId="picker_design-next-color"
          />
          <ColorInput
            value={updated?.nextButton?.backgroundColor || ''}
            onChange={color =>
              onUpdate({ nextButton: { backgroundColor: color } })
            }
            label={text.nextButtonBackgroundColor}
            dataTestId="picker_design-next-backgroundColor"
          />
          <ColorInput
            value={updated?.step?.color || ''}
            onChange={color => onUpdate({ step: { color } })}
            label={text.stepColor}
            dataTestId="picker_design-step-color"
          />
          <ColorInput
            value={updated?.step?.backgroundColor || ''}
            onChange={color => onUpdate({ step: { backgroundColor: color } })}
            label={text.stepBackgroundColor}
            dataTestId="picker_design-step-backgroundColor"
          />
        </JustificationContainer>

        <ColorPickerWithTransparentOption
          color={updated?.navbarBackgroundColor}
          title={text.navbarBackgroundColor}
          name="navbarBackgroundColor"
          onChange={color => onUpdate({ navbarBackgroundColor: color })}
          testName="design-step"
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

const logoPositionOptions: Array<Option> = [
  {
    value: AppValuationReportLogoPosition.Left,
    label: text.left,
    dataTestId: 'select-left',
  },
  {
    value: AppValuationReportLogoPosition.Middle,
    label: text.middle,
    dataTestId: 'select-middle',
  },
  {
    value: AppValuationReportLogoPosition.Right,
    label: text.right,
    dataTestId: 'select-right',
  },
];

export default Design;
