import React from 'react';
import { animated, useTransition } from 'react-spring';
import { useTheme } from 'styled-components';
import { componentSizes } from '~/styles/constants';
import type { Size } from '~/styles/constants';
import Icon from '~/components/atom/Icon';

export type Props = {
  hasError?: boolean;
  size?: Size;
};

const Indicator: React.FCC<Props> = ({ hasError, size = 'medium' }) => {
  const theme = useTheme();

  const transitions = useTransition(!hasError, {
    from: { transform: 'translateX(-10px)', opacity: 0, scale: 0 },
    enter: { transform: 'translateX(0px)', opacity: 1, scale: 1 },
    leave: { transform: 'translateX(10px)', opacity: 0, scale: 0 },
    config: {
      tension: 300,
      friction: 20,
    },
  });

  return transitions((style, show) => {
    if (!show) return null;

    return (
      <Icon
        name="check"
        background="success"
        color={theme.color('white')}
        as={animated.span}
        style={{
          /**
           * Fixes: Type 'SpringValue<number>' is not assignable to type 'Opacity | undefined'.
           * P.S. When you remove 'as any' it seems fine but if you run the linter you will get this error.
           *
           * Related issue: https://github.com/pmndrs/react-spring/issues/1645
           * Temporary solution: https://github.com/pmndrs/react-spring/issues/1102#issuecomment-803208159
           */
          ...(style as any),
          position: 'absolute',
          bottom: theme.space(componentSizes[size].padding[0]),
          right: theme.space(componentSizes[size].padding[1]),
        }}
        dataTestId="success-icon"
      />
    );
  });
};

export default Indicator;
