import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type {
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
} from '~/graphql/types';
import type {
  BasePrimitive,
  Flow___PrimitiveArgument,
} from '~/graphql/types.client';
import TEST_ID from './index.testid';
import InputForType from './components/InputForType';
import getInitialPrimitiveInputArgument from './utils/getInitialPrimitiveInputArgument';
import useKeybinding from '~/hooks/useKeybinding';

export type Props = {
  dataTestId?: string;
  input:
    | FlowData___PrimitiveInputFragment
    | FlowData___PrimitiveListInputFragment;
  value: BasePrimitive;
  onChange: (
    value: Flow___PrimitiveArgument,
    opts?: { duplicateInput?: true },
  ) => void;
};

const PrimitiveInput: React.FCC<Props> = ({
  dataTestId,
  input,
  value: initialValue,
  onChange,
  ...rest
}) => {
  const [value, setValue] = useState<Flow___PrimitiveArgument | null>(
    getInitialPrimitiveInputArgument({ input, initialValue }),
  );
  const [error, setError] = useState<null | string>(null);

  useKeybinding({
    callback: event => {
      event.preventDefault();
      event.stopPropagation();

      if (!value) return;
      return onChange(value, { duplicateInput: true });
    },
    keys: 'ctrl+enter',
  });

  return (
    <Container
      data-testid={dataTestId}
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();

        if (!value) return;
        return onChange(value);
      }}
      {...rest}
    >
      <JustificationContainer
        justification="start"
        align="center"
        margin={[null, null, 'l', null]}
      >
        <InputForType
          input={input}
          value={value}
          onChange={setValue}
          onError={err => setError(err)}
        />
      </JustificationContainer>
      <JustificationContainer justification="end" align="center">
        <Button
          label="Opslaan"
          type="submit"
          icon="check"
          appearance="secondary"
          dataTestId={TEST_ID.SUBMIT}
          disabled={value == null || error !== null}
        />
      </JustificationContainer>
    </Container>
  );
};

const Container = styled.form<{}>``;

export default PrimitiveInput;
