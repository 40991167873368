import React from 'react';
import { Heading2, Highlight } from '~/components/atom/Typography';

const PromoDefault = () => (
  <React.Fragment>
    <Heading2
      lineHeight="base"
      align="center"
      color={{ group: 'white' }}
      margin={['l', null, null, null]}
    >
      Efficiënt van leads <Highlight>klanten</Highlight> maken
    </Heading2>
  </React.Fragment>
);

export default PromoDefault;
