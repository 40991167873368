import React from 'react';
import type { FormBuilder_ScreenNode_Block } from '~/graphql/types';
import isInputTypeBlock from '~/components/page/Forms/components/Builder/utils/isInputTypeBlock';
import isSelectTypeBlock from '~/components/page/Forms/components/Builder/utils/isSelectTypeBlock';
import InputForType from '../../../InputForType';
import InputWithOptions from '../../../InputWithOptions';
import SelectTypography from '../../../SelectTypography';
import RichText from '../../../RichText';
import Image from '../../../Image';

type Props = {
  nodeId: string;
  block: FormBuilder_ScreenNode_Block;
  onChange: (block: FormBuilder_ScreenNode_Block) => void;
};

const EditableBlock: React.FC<Props> = ({ block, onChange, nodeId }) => {
  if (isInputTypeBlock(block)) {
    return <InputForType onChange={onChange} nodeId={nodeId} block={block} />;
  }
  if (isSelectTypeBlock(block)) {
    return (
      <InputWithOptions onChange={onChange} block={block} nodeId={nodeId} />
    );
  }

  switch (block.__typename) {
    case 'FormData_UI_RichText':
      return <RichText onChange={onChange} block={block} nodeId={nodeId} />;
    case 'FormData_UI_Typography':
      return (
        <SelectTypography onChange={onChange} block={block} nodeId={nodeId} />
      );
    case 'FormData_UI_Image': {
      return <Image onChange={onChange} block={block} nodeId={nodeId} />;
    }
  }

  return null;
};

export default EditableBlock;
