import React, { useState } from 'react';
import styled from 'styled-components';
import { Helmet as MetaTags } from 'react-helmet';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import {
  useSpring,
  animated,
  useTransition,
  UseTransitionProps,
} from 'react-spring';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { UserStatus } from '~/graphql/types';
import useUsers from '~/hooks/useUsers';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserRights from '~/hooks/useUserRights';
import useSessionHydration from '~/hooks/useSessionHydration';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import Button from '~/components/atom/Button';
import AccountUserCard from '../AccountUserCard';
import { filterUsers, sortByName } from '../../utils';
import TEST_ID from './index.testid';
import AddUserSidebar from '~/components/page/Settings/components/AddUserSidebar';
import useLimit from '~/hooks/useLimit';
import NoResultSection from '~/components/page/NoResultSection';
import { ANIMATION_CONFIG } from '~/styles/constants';

type Props = RouteComponentProps;

const text = {
  pageTitle: 'Gebruikers',
  title: 'Gebruikers',
  noFilterResults: 'Er zijn geen gebruikers gevonden voor de zoekterm ',
  noUsers: 'Er zijn nog geen gebruikers voor deze vestiging',
  addUser: 'Nieuwe gebruiker',
};

const UserList: React.FCC<Props> = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>('');
  const [, refetch] = useSessionHydration();
  const account = useCurrentAccount();

  const users = useUsers({
    statuses: [
      UserStatus.Active,
      UserStatus.Pending,
      UserStatus.InvitedPending,
    ],
  });

  const sortedUsers = sortByName(users);

  const filteredUsers = filterUsers(sortedUsers, filter);

  const { create: mayCreate } = useUserRights({ category: 'Users' });

  const noResultsText = !users.length
    ? text.noUsers
    : `${text.noFilterResults} "${filter}"`;

  const animation = useSpring(ANIMATION_CONFIG);
  const transitionProps: UseTransitionProps = {
    from: ANIMATION_CONFIG.from,
    enter: ANIMATION_CONFIG.to,
    leave: ANIMATION_CONFIG.from,
    config: ANIMATION_CONFIG.config,
    trail: 125,
    keys: user => user.id,
  };
  const transitions = useTransition(filteredUsers, transitionProps);

  const officeOptions = useOfficeOptions();
  const userLimit = useLimit('root.users');

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <OverviewListHeader
        title={text.title}
        buttons={[
          mayCreate && filteredUsers.length < userLimit.limit
            ? {
                node: (
                  <Button
                    appearance="secondary"
                    onClick={() => setShowSidebar(true)}
                    dataTestId={TEST_ID.ADD_USER_BUTTON}
                    label={text.addUser}
                    size="medium"
                  />
                ),
                key: 'add-user-button',
              }
            : undefined,
        ]}
        onFilterChange={setFilter}
        dataTestId="users-list-header"
      />
      {mayCreate && (
        <AddUserSidebar
          officeOptions={officeOptions}
          accountId={account.id}
          onUserAdded={refetch}
          isShowing={showSidebar}
          hide={() => setShowSidebar(false)}
        />
      )}
      {filteredUsers.length === 0 && (
        <NoResultSection>{noResultsText}</NoResultSection>
      )}
      {filteredUsers.length > 0 && (
        <animated.section data-testid="user-list" style={animation}>
          {transitions((style, { id }, { key }) => (
            <animated.div
            /** @TODO Bug fix for User cards not showing at the bottom of the list because of position absolute. Fix the animation later */
            // style={{
            //   ...style,
            //   /** We need to pass position:absolute and z-index here otherwise the user role dropdown does not open on top of the user cards */
            //   position: 'absolute',
            //   top: index * 110,
            //   zIndex: index * -1,
            //   width: '100%',
            // }}
            >
              <AccountUserCard key={key} userId={id} refetch={refetch} />
            </animated.div>
          ))}
        </animated.section>
      )}
    </ContentContainerDefault>
  );
};

export default UserList;

export const Header = styled.header<{}>`
  display: flex;
  align-items: center;
  margin: 0 0 6em;

  & > h2 {
    margin: 0 0.5em 0 0;
  }

  & > button {
    margin-left: auto;
  }
`;
