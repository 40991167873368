import React from 'react';
import styled from 'styled-components';

import { InsertContactMutation as InsertContactMutationResultType } from '~/graphql/types';

import TEST_ID from './index.testid';
import AddSingleContact from '../components/AddSingleContact';
import withModalOverlay from '~/hocs/withModalOverlay';
import { Body, Heading3 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Container as InputContainer } from '~/components/atom/InputContainer';

export type ContactsMetaType = {
  accountId: string;
  officeId: string;
  userId: string;
};

type Props = {
  contactsMeta: ContactsMetaType;
  onClose?: () => void;
  onComplete?: (newContact: InsertContactMutationResultType) => void;
};

const text = {
  addContactLabel: 'Nieuw contact',
  closeButtonLabel: 'Ok',
  singleContactExplanation: 'Voeg een contact toe',
};
const AddContactModal: React.FC<Props> = ({ contactsMeta, onComplete }) => (
  <JustificationContainer
    data-testid={TEST_ID.CONTAINER}
    padding={['xl']}
    direction="column"
    align="center"
  >
    <Heading3 withoutMargin>{text.addContactLabel}</Heading3>
    <Body>{text.singleContactExplanation}</Body>
    <FormContainer direction="column" width="100%">
      <AddSingleContact
        contactsMeta={contactsMeta}
        onNewContactAdded={onComplete ? onComplete : () => {}}
      />
    </FormContainer>
  </JustificationContainer>
);

// TODO: Check if it works
const FormContainer = styled(JustificationContainer)`
  ${InputContainer}, form {
    width: 100%;
  }
`;

export default withModalOverlay<Props>(AddContactModal, { maxWidth: '480px' });
