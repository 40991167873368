import React from 'react';

import {
  Field,
  NumberField,
  PostalCodeField,
} from '~/components/organism/Forms';
import FoldableCardBlock from '../FoldableCardBlock';
import FormUtils from '~/components/organism/FormUtils';
import TEST_ID from './index.testid';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import Input from '~/components/bad/Inputs/Input';

type Props = {
  /** If the form is disabled */
  isFormDisabled: boolean;
};

const ContactLivingSituationBlock: React.FCC<Props> = ({ isFormDisabled }) => {
  const viewingModeProps = useViewingModeProps();

  return (
    <FoldableCardBlock
      icon="HOME"
      title="Woonadres"
      dataTestId="card-block-living-situation"
    >
      <InputGroup>
        <Field name="livingAddressStreet">
          {({ input, meta: { error, touched } }) => (
            <Input
              data-testid={TEST_ID.STREET}
              label="Straat"
              type="text"
              error={FormUtils.showError(error, touched)}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>
      </InputGroup>
      <InputGroup>
        <NumberField name="livingAddressHouseNumber">
          {({ input, meta: { error, touched } }) => (
            <Input
              data-testid={TEST_ID.HOUSE_NUMBER}
              label="Huisnummer"
              type="text"
              error={FormUtils.showError(error, touched)}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </NumberField>
        <Field name="livingAddressAddition">
          {({ input }) => (
            <Input
              data-testid={TEST_ID.HOUSE_NUMBER_ADDITION}
              label="Toevoeging"
              type="text"
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>
      </InputGroup>
      <InputGroup>
        <PostalCodeField name="livingAddressPostcode">
          {({ input, meta: { error, touched } }) => (
            <Input
              data-testid={TEST_ID.POSTCODE}
              label="Postcode"
              type="text"
              error={FormUtils.showError(error, touched)}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </PostalCodeField>
      </InputGroup>
      <InputGroup>
        <Field name="livingAddressCity">
          {({ input, meta: { error, touched } }) => (
            <Input
              data-testid={TEST_ID.CITY}
              label="Woonplaats"
              type="text"
              error={FormUtils.showError(error, touched)}
              disabled={isFormDisabled}
              {...viewingModeProps}
              {...input}
            />
          )}
        </Field>
      </InputGroup>
    </FoldableCardBlock>
  );
};

export default ContactLivingSituationBlock;
