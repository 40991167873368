import React from 'react';
import styled, { css } from 'styled-components';

import { calculateSpaceInsideComponent } from '~/components/bad/util/getSizeCalculation';
import { getDisabledInputStyle } from '~/components/bad/Inputs/InputElement';
import { isNil } from 'ramda';

export const TEXTAREA_ELEMENT_BORDER = '1px';

type Ref = HTMLTextAreaElement;

type Props = {
  disabled?: boolean;
  error?: string | null;
  small?: boolean;
  large?: boolean;
  borderless?: boolean;
  uneditable?: boolean;
  resizable?: boolean;
  value: string;
  autoFocus?: boolean;
  rows?: number;
  onChange?: (event: React.SyntheticEvent<HTMLTextAreaElement>) => void;
};

const Textarea = React.forwardRef<Ref, Props>(
  ({ disabled, borderless, uneditable, rows, error, ...rest }, ref) => (
    <TextareaElement
      rows={rows}
      ref={ref}
      readOnly={uneditable}
      $disabled={disabled}
      $borderless={borderless}
      $uneditable={uneditable}
      $error={error}
      {...rest}
    />
  ),
);

type StyledTextareaProps = {
  $disabled?: boolean;
  $error?: Props['error'];
  $small?: Props['small'];
  $large?: Props['large'];
  $borderless?: Props['borderless'];
  $uneditable?: Props['uneditable'];
  $resizable?: Props['resizable'];
  rows?: Props['rows'];
} & React.HtmlHTMLAttributes<HTMLTextAreaElement>;

const TextareaElement = styled.textarea<StyledTextareaProps>`
  resize: ${({ $resizable }) => ($resizable ? 'both' : 'none')};
  width: 100%;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.getTokens().border.radius.base};

  ${({ theme, $small, $large }) => {
    const spaceInsideComponent = calculateSpaceInsideComponent(
      theme,
      $small,
      $large,
    );

    return css`
      padding: calc(${spaceInsideComponent}px - ${TEXTAREA_ELEMENT_BORDER});
    `;
  }};

  ${({ theme, $disabled }) => $disabled && getDisabledInputStyle(theme)}

  ${({ $uneditable }) =>
    $uneditable &&
    css`
      cursor: default;
      user-select: none;
    `};

  &:hover,
  &:focus {
    ${({ theme, $disabled, $borderless, $uneditable }) => {
      if ($uneditable) {
        return css`
          border: ${TEXTAREA_ELEMENT_BORDER} solid ${theme.color('grey')};
        `;
      } else if (!$disabled && !$borderless) {
        return css`
          border: ${TEXTAREA_ELEMENT_BORDER} solid
            ${theme.color('primary', 'light')};
        `;
      }

      return null;
    }}
  }

  ${({ $error, $borderless, theme }) => {
    if (!isNil($error)) {
      return css`
        &,
        &:hover,
        &:focus {
          border: ${TEXTAREA_ELEMENT_BORDER} solid ${theme.color('danger')};
        }
      `;
    }

    if ($borderless) {
      return css`
        border: 0;
      `;
    } else {
      return css`
        border: ${TEXTAREA_ELEMENT_BORDER} solid ${theme.color('grey')};
      `;
    }
  }};
`;

export default Textarea;
