import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4 } from '~/components/atom/Typography';
import Toolbar from '~/components/molecule/Toolbar';
import Button from '~/components/atom/Button';
import TEST_ID from './index.testid';
import ActionHeadingWithLabel from '../../../ActionHeadingWithLabel';
import { type InteractionCopyAction } from '~/components/page/Automation/v2/state/interactions';

const text = {
  copiedAction: 'Gekopieerde actie:',
  stopCopying: 'Klaar met kopiëren',
  cancel: 'Annuleren',
};

export type Props = {
  onClose: () => void;
  onCancel: () => void;
  currentInteraction: InteractionCopyAction;
  hasChanges: boolean;
};

const CopyStateToolbar: React.FCC<Props> = ({
  hasChanges,
  currentInteraction,
  onClose,
  onCancel,
}) => {
  const copiedAction = currentInteraction.copiedAction;

  return (
    <StyledToolbar dataTestId={TEST_ID.CONTAINER}>
      <JustificationContainer width="100%" align="center" gap="s">
        <Heading4
          margin={[null, 'm', null]}
          size="m"
          color={{ group: 'primary', variant: 'light' }}
        >
          {text.copiedAction}
        </Heading4>

        <ActionHeadingWithLabel action={copiedAction} />
      </JustificationContainer>

      <Button
        size="medium"
        label={text.cancel}
        ghost
        margin={[null, 'base', null, null]}
        onClick={onCancel}
        disabled={!hasChanges}
      />
      <Button
        size="medium"
        label={text.stopCopying}
        onClick={onClose}
        dataTestId={TEST_ID.STOP_COPYING_BUTTON}
      />
    </StyledToolbar>
  );
};

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    border: 2px solid ${theme.color('primary', 'light')};
  `,
);

export default CopyStateToolbar;
