import React from 'react';
import { WithDelayedLoading } from '~/components/bad/util/withDelayedLoading';
import NewOfficeForm from '../NewOfficeForm';
import withDelayedLoading from '~/components/bad/util/withDelayedLoading';
import Drawer from '~/components/organism/Drawer';
import { useInsertOfficeMutation } from '~/graphql/types';

const text = {
  title: 'Nieuwe vestiging',
};

type Props = {
  accountId: string;

  /** Handler on new Office added */
  onOfficeAdded: () => void;

  /** When the sidebar has to hide */
  hide: () => void;

  /** If the drawer is showing */
  isShowing: boolean;
};

const AddOfficeSidebar: React.FC<WithDelayedLoading & Props> = ({
  accountId,
  setLoading,
  removeLoading,
  hide,
  isShowing,
  onOfficeAdded,
  loading: propsLoading,
}) => {
  const onInsertSuccess = () => {
    removeLoading();
    onOfficeAdded();
  };

  const onErrorUpdate = () => {
    removeLoading();
  };

  const [insertOffice, { error, loading }] = useInsertOfficeMutation({
    onCompleted: () => onInsertSuccess(),
    onError: () => onErrorUpdate(),
  });

  return (
    <Drawer active={isShowing} hideSidebar={hide} title={text.title}>
      <NewOfficeForm
        accountId={accountId}
        loading={propsLoading || loading}
        onSubmit={variables => {
          setLoading();
          return insertOffice({
            variables,
          });
        }}
        insertionError={error}
      />
    </Drawer>
  );
};

export default withDelayedLoading<Props>(AddOfficeSidebar);
