import React, { useCallback, useEffect, useState } from 'react';
import { FlowV2_Action_Task_CreateFragment } from '~/graphql/types';
import Checkbox from '~/components/bad/Inputs/Checkbox';
import Dropdown from '~/components/molecule/Dropdown';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import useUserOptions from '~/hooks/useUserOptions';
import { taskTypeOptions } from '~/util/taskTypeOptions';
import { Props as FormProps } from '../ActionForm';
import useRelativeMaps from '~/components/page/Automation/v2/components/Builder/hooks/useRelativeMaps';
import convertTemplateStringToSlateFragment from '~/components/organism/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import useEditorStates from '~/components/organism/PluginsEditor/hooks/useEditorStates';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import PluginsEditor from '~/components/organism/PluginsEditor';
import TemplateStringInput from '~/components/organism/TemplateStringInput';
import withHtml from '~/components/organism/PluginsEditor/plugins/withHtml';
import TEST_ID from './index.testid';
import TextEditorContainer from '../TextEditorContainer';
import useEntityValidationForInputs from '../../../Builder/hooks/useEntityValidationForInputs';
import FieldLabel from '../../../FieldLabel';
import Div from '~/components/atom/Div';
import InputLabel from '~/components/atom/InputLabel';
import { TASK_EDITOR_BUTTONS } from './constants';

export type Props = FormProps & {
  action: FlowV2_Action_Task_CreateFragment;
};

type State = Omit<
  FlowV2_Action_Task_CreateFragment,
  | 'id'
  | 'accountId'
  | 'flowBlueprintId'
  | '__typename'
  | '_v'
  | 'actionType'
  | 'parentIds'
>;

const text = {
  taskTypeLabel: 'Type taak',
  descriptionLabel: 'Omschrijving',
  assignTaskLabel: 'Taak toewijzen',
  checkboxLabel: 'Aan de eigenaar van het contact toewijzen',
  checkboxName: 'overwriteAssignee',
  officeWithNoAccountLabel: 'Vestiging (indien geen eigenaar)',
  userWithNoAccountLabel: 'Gebruiker (indien geen eigenaar)',
  userLabel: 'Gebruiker',
  officeLabel: 'Vestiging',
  officeDropdownLabel: 'Selecteer vestiging',
  userDropdownLabel: 'Selecteer gebruiker',
  title: 'Titel',
};

const FlowActionCreateTask: React.FCC<Props> = ({ action, onChange }) => {
  const initials = {
    type: action.type,
    user: action.user,
    assignToContactOwner: action.assignToContactOwner,
    title: action.title,
    description: action.description,
    assignedToOffice: action.assignedToOffice,
  };
  const [actionDetails, setActionDetails] = useState<State>(initials);

  const {
    type,
    user,
    title,
    description,
    assignToContactOwner,
    assignedToOffice,
  } = actionDetails;

  const officeOptions = useOfficeOptions();
  const userOptions = useUserOptions({
    withAllUsersOption: true,
    officeId: assignedToOffice,
  });

  const { userError, officeError } = useEntityValidationForInputs({
    userOptions,
    userId: actionDetails.user,
    officeId: actionDetails.assignedToOffice,
  });

  const handleChange = useCallback((key, value) => {
    setActionDetails(prev => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const maps = useRelativeMaps({ actionId: action.id });
  const initialTitleValue = convertTemplateStringToSlateFragment({
    html: title.template || '',
    mappings: title.mappings,
    ...maps,
  });
  const {
    key: titleKey,
    value: titleValue,
    onChange: onTitleChange,
    hasChanges: hasTitleChanges,
  } = useEditorStates({
    initialValue: initialTitleValue,
  });

  const initialDescriptionValue = convertTemplateStringToSlateFragment({
    html: description.template || '',
    mappings: description.mappings,
    ...maps,
  });
  const {
    key: descriptionKey,
    value: descriptionValue,
    onChange: onDescriptionChange,
    hasChanges: hasDescriptionChanges,
  } = useEditorStates({
    initialValue: initialDescriptionValue,
  });

  useEffect(() => {
    if (hasTitleChanges) {
      handleChange('titleValue', titleValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleValue, hasTitleChanges]);

  useEffect(() => {
    if (hasDescriptionChanges) {
      handleChange('descriptionValue', descriptionValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionValue, hasDescriptionChanges]);

  return (
    <JustificationContainer direction="column" gap="m" width="100%">
      <Div width="100%">
        <Dropdown
          label={text.taskTypeLabel}
          options={taskTypeOptions}
          onChange={e => handleChange('type', e.option.payload)}
          selectedOptionIdx={taskTypeOptions.findIndex(
            option => option.payload === type,
          )}
          dataTestId={TEST_ID.TASK_TYPE_DROPDOWN}
        />
      </Div>

      <JustificationContainer direction="column" width="100%">
        <InputLabel label={text.title} />
        <TemplateStringInput
          $key={titleKey}
          value={titleValue}
          onChange={onTitleChange}
          dataTestId={TEST_ID.TITLE}
        />
      </JustificationContainer>

      <JustificationContainer width="100%" direction="column">
        <InputLabel label={text.descriptionLabel} />

        <TextEditorContainer>
          <PluginsEditor
            key={descriptionKey}
            value={descriptionValue}
            onChange={onDescriptionChange}
            customElements={[ELEMENTS.VARIABLE]}
            plugins={[
              {
                name: 'withHtml',
                fn: e =>
                  withHtml({
                    editor: e,
                    customElements: [ELEMENTS.VARIABLE],
                  }),
              },
            ]}
            dataTestId={TEST_ID.DESCRIPTION}
            minHeight={150}
            toolbarButtons={TASK_EDITOR_BUTTONS}
          />
        </TextEditorContainer>
      </JustificationContainer>

      <Div>
        <Heading4 variant={Variant.primary}>{text.assignTaskLabel}</Heading4>
        <Checkbox
          value={assignToContactOwner ?? true}
          onChange={() =>
            setActionDetails(prev => ({
              ...prev,
              assignToContactOwner: !prev.assignToContactOwner,
            }))
          }
          label={text.checkboxLabel}
          name={text.checkboxName}
        />
      </Div>

      <JustificationContainer width="100%">
        <FieldLabel>
          {assignToContactOwner
            ? text.officeWithNoAccountLabel
            : text.officeLabel}
        </FieldLabel>
        <Dropdown
          label={text.officeDropdownLabel}
          options={officeOptions}
          onChange={e => handleChange('assignedToOffice', e.option.payload?.id)}
          selectedOptionIdx={officeOptions.findIndex(
            selectedOffice => selectedOffice.payload?.id === assignedToOffice,
          )}
          dataTestId={TEST_ID.ASSIGNED_OFFICE}
          error={officeError}
        />
      </JustificationContainer>

      <JustificationContainer width="100%">
        <FieldLabel>
          {assignToContactOwner ? text.userWithNoAccountLabel : text.userLabel}
        </FieldLabel>
        <Dropdown
          label={text.userDropdownLabel}
          options={userOptions}
          onChange={e => handleChange('user', e.option.payload?.id)}
          selectedOptionIdx={userOptions.findIndex(
            selectedUser => selectedUser.payload?.id == user,
          )}
          dataTestId={TEST_ID.ASSIGNED_USER}
          error={userError}
        />
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default FlowActionCreateTask;
