import { type HeadingFourElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the OrderedListElement into html element
 * @param {HeadingFourElement} node - HeadingFourElement
 */
const serialize = (node: HeadingFourElement, children: string): string =>
  `<h4>${children}</h4>`;

export default serialize;
