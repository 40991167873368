import React from 'react';
import styled, { css } from 'styled-components';
import { Label } from '~/components/atom/Typography';

export type Props = {
  dataTestId?: string;
};

const LabelContainer: React.FCC<Props> = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
);

const LABEL_WIDTH = 130;

const Container = styled(Label)(
  () => css`
    display: inline-block;
    max-width: ${LABEL_WIDTH}px;
    min-width: ${LABEL_WIDTH}px;
  `,
);

export default LabelContainer;
