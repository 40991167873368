import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import { isObject } from 'lodash';
import withErrorBoundary from '~/ErrorBoundary';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import { useSpring, animated } from 'react-spring';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useOffices from '~/hooks/useOffices';
import useUserRights from '~/hooks/useUserRights';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import Button from '~/components/atom/Button';
import AddOfficeSidebar from '../AddOfficeSidebar';
import OfficeOverviewCard from '../OfficeOverviewCard';
import TEST_ID from './index.testid';
import useSessionHydration from '~/hooks/useSessionHydration';
import useLimit from '~/hooks/useLimit';
import NoResultSection from '~/components/page/NoResultSection';
import { ANIMATION_CONFIG } from '~/styles/constants';
import type { SessionHydrationOfficeFieldsFragment } from '~/graphql/types';

type Props = RouteComponentProps;

const text = {
  pageTitle: 'Vestigingen',
  title: 'Vestigingen',
  noFilterResults: 'Geen vestigingen gevonden voor de zoekterm ',
  addLabel: 'Nieuwe vestiging',
};

const OfficeList: React.FCC<Props> = withErrorBoundary<Props>(() => {
  const [filter, updateFilter] = useState<string>('');
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const animation = useSpring(ANIMATION_CONFIG);
  const [, refetchSessionHydration] = useSessionHydration();

  const account = useCurrentAccount();
  const offices = useOffices({});

  const filteredOffices = filterOffices(offices, filter);
  const { create: mayCreate } = useUserRights({
    category: 'Offices',
  });

  const officeLimit = useLimit('root.offices');

  const accountId = account.id;

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <OverviewListHeader
        title={text.title}
        buttons={[
          mayCreate && offices.length < officeLimit.limit
            ? {
                node: (
                  <Button
                    appearance="secondary"
                    onClick={() => setShowSidebar(!showSidebar)}
                    dataTestId={TEST_ID.ADD_OFFICE_BUTTON}
                    label={text.addLabel}
                    size="medium"
                    disabled={!mayCreate}
                  />
                ),
                key: 'add-office-button',
              }
            : undefined,
        ]}
        onFilterChange={updateFilter}
        dataTestId="offices-list-header"
      />

      {mayCreate && (
        <AddOfficeSidebar
          accountId={accountId}
          onOfficeAdded={() =>
            refetchSessionHydration().then(() => setShowSidebar(false))
          }
          hide={() => setShowSidebar(false)}
          isShowing={showSidebar}
        />
      )}

      {offices.length && !filteredOffices.length && (
        <NoResultSection>
          {`${text.noFilterResults} "${filter}"`}
        </NoResultSection>
      )}
      {filteredOffices.length > 0 && (
        <OfficeListContainer data-testid="offices-list" style={animation}>
          {filteredOffices.map(({ name, id, img, address, phone, email }) => (
            <animated.div style={animation} key={id}>
              <OfficeOverviewCard
                name={name}
                id={id}
                img={img}
                address={address ?? null}
                phone={phone}
                email={email}
              />
            </animated.div>
          ))}
        </OfficeListContainer>
      )}
    </ContentContainerDefault>
  );
});

const OfficeListContainer = styled(animated.div)<{}>(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    gap: ${theme.space('l')};
  `,
);

// Export for testing
export const filterOffices = (
  offices: Array<SessionHydrationOfficeFieldsFragment>,
  filter: string,
): Array<SessionHydrationOfficeFieldsFragment> => {
  if (!filter) return offices;

  const filterLwCase = filter.toLowerCase();

  return offices.filter(office => {
    let testString = '';
    if (isObject(office.address)) {
      const { addition, city, houseNumber, postcode, street } =
        office.address || {};
      const addressStreet = `${street} ${houseNumber}${
        addition ? addition : ''
      }`;
      testString = `${testString} ${addressStreet} ${postcode} ${city}`;
    }

    if (typeof office.name === 'string') {
      testString = `${testString} ${office.name}`;
    }

    if (typeof office.phone === 'string') {
      testString = `${testString} ${office.phone}`;
    }

    if (typeof office.email === 'string') {
      testString = `${testString} ${office.email}`;
    }

    return testString.toLowerCase().includes(filterLwCase);
  });
};

export default OfficeList;
