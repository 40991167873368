import React from 'react';
import { isEmpty, memoize } from 'underscore';
import { TableHeaderDefinitionCellType } from '~/components/bad/DataTables/types.flow';
import TableHeaderCell from '~/components/bad/DataTables/util/TableHeaderCell';
import Icon from '~/components/atom/Icon/index';
import { AccountIssue, AdminAccountInfoSortField } from '~/graphql/types';
import { SortToggleFunction } from '~/components/page/Automation/Flows/util/flowListColumns';
import copyTextToClipboard from '~/util/copyTextToClipboard';
import formatToastMessage from '~/util/formatToastMessage';
import TEST_ID from './index.testid';

const accountListColumns = (
  sortToggle: SortToggleFunction,
  version,
  addToast,
): Array<TableHeaderDefinitionCellType<any>> => [
  TableHeaderCell.string({
    label: 'Naam',
    dataProperty: 'name',
    width: 200,
    dataTestId: TEST_ID.NAME,
    sorting: {
      onSort: sortDirection =>
        sortToggle(AdminAccountInfoSortField.Name, sortDirection),
    },
  }),
  TableHeaderCell.string({
    label: 'Account ID',
    dataProperty: 'id',
    dataTestId: TEST_ID.ACCOUNT_ID,
    width: 200,
    renderFunction: value => (
      <span
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          void copyTextToClipboard(value).then(() => {
            addToast([formatToastMessage('Gekopieerd', 'success')]);
          });
        }}
      >
        {value}
      </span>
    ),
  }),
  TableHeaderCell.string({
    label: 'Status',
    dataProperty: 'status',
    dataTestId: TEST_ID.STATUS,
    width: 50,
  }),
  TableHeaderCell.custom({
    label: 'Issues',
    dataProperty: 'issues',
    width: 50,
    renderFunction: (value: Array<AccountIssue>) => (
      <Icon
        name={!isEmpty(value) ? 'exclamation' : 'minus'}
        color={!isEmpty(value) ? '#E91313' : '#AAAAAA'}
        style={{ marginLeft: '1em' }}
      />
    ),
    sorting: {
      onSort: sortDirection =>
        sortToggle(AdminAccountInfoSortField.Issues, sortDirection),
    },
    version,
  }),
];

const memoizedAccountListColumns = memoize(
  accountListColumns,
  (_func, key) => key,
);

export default memoizedAccountListColumns;
