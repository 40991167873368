import styled, { css } from 'styled-components';

const DIVIDER_HEIGHT = '1px';

const Divider = styled.div<{}>(
  ({ theme }) => css`
    height: 100%;
    padding: ${theme.space('m')} 0;

    &:after {
      content: '';
      height: ${theme.space('xxl')};
      width: ${theme.space('xxxs')};
      display: block;
      border-radius: ${theme.getTokens().border.radius.s};
      background: ${theme.color('grey')};
    }

    ${theme.mq.lessThan('tabletLandscape')`
      padding: 0;
      width: 100%;

      &:after {
        content: '';
        height: ${DIVIDER_HEIGHT};
        width: 100%;
        border-radius: ${theme.getTokens().border.radius.s};
        background: ${theme.color('grey')};
      }
     `}
  `,
);

export default Divider;
