import { uniq } from 'ramda';

export const mergeInfinitePaginatedItems = (existing, incoming) => {
  const items = existing ? [...existing.items] : [];

  return {
    nextToken: incoming.nextToken,
    items: uniq([...items, ...incoming.items]),
    __typename: incoming.__typename,
  };
};
export const readInfinitePaginatedItems = existing => {
  if (existing) {
    return {
      nextToken: existing.nextToken,
      items: Object.values(existing.items),
      __typename: existing.__typename,
    };
  }

  return {
    nextToken: null,
    items: [],
    __typename: 'PaginatedInvoices',
  };
};
