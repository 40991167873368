import generateIdForEntity from '~/util/generateIdForEntity';
import { createNewOption } from '../../components/InputWithOptions/components/OptionsEditor';
import type { FormBuilder_OptionList } from '~/graphql/types';

const createEmptyList = (name: string): FormBuilder_OptionList => {
  const id = generateIdForEntity('FORMBUILDER_OPTION');
  return {
    id,
    name,
    __typename: 'FormBuilder_OptionList',
    options: [
      createNewOption({
        __typename: 'FormBuilder_Label_Intl',
        en: `Option 1`,
        nl: `Optie 1`,
      }),
    ],
  };
};

export default createEmptyList;
