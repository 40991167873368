import React, { forwardRef } from 'react';
import InputContainer from '../../atom/InputContainer';
import useInputValue from '~/hooks/useInputValue';
import InputLabel from '../../atom/InputLabel';
import InputElement from '~/components/atom/InputElement';
import type { BaseInputProps } from '~/components/atom/InputElement';
import Icon, { type IconType } from '~/components/atom/Icon';
import type { ValidationFunction } from '~/util/getValidationErrors';
import Div from '~/components/atom/Div';
import type { Appearance } from '~/styles/constants';
import { useTheme } from 'styled-components';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Props = BaseInputProps & {
  dataTestId?: string;

  /** Input label. Becomes an error label if there is a validation error */
  label?: string;

  /** Input appearance */
  appearance?: Appearance;

  /** Width of the container */
  width?: string;

  /** Internal validation */
  validation?: Array<ValidationFunction>;

  /** External validation */
  externalErrors?: Array<string>;

  /** Shows a spinner on the right side */
  loading?: boolean;

  /** Icon to render on the left side of the input element */
  icon?: { name: IconType; color?: ThemeColor };
};

const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      dataTestId,
      size = 'medium',
      disabled = false,
      label,
      value,
      validation = [],
      appearance,
      className,
      externalErrors = [],
      children,
      width,
      loading,
      icon,
      onChange,
      onFocus,
      onBlur,
      required,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();
    const {
      validationErrors,
      hasError,
      hasFocus,
      inputRef,
      onValueChange,
      onFocusChange,
    } = useInputValue({
      value,
      externalErrors,
      validation,
      type: rest.type,
      ref,
      onChange,
      onFocus,
      onBlur,
    });

    return (
      <Div width={width}>
        <InputLabel
          size={size}
          error={validationErrors?.[0]}
          label={label}
          required={required}
        />
        <InputContainer
          size={size}
          hasError={hasError}
          hasFocus={hasFocus}
          width={width}
          appearance={appearance}
          className={className}
          disabled={disabled}
          inputRef={inputRef}
        >
          {icon && (
            <Icon
              name={icon.name}
              color={
                icon.color
                  ? theme.color(icon.color.group, icon.color.variant)
                  : undefined
              }
            />
          )}
          <InputElement
            {...rest}
            value={value}
            dataTestId={dataTestId}
            ref={inputRef}
            size={size}
            disabled={disabled}
            onChange={onValueChange}
            onFocus={e => onFocusChange({ e, focus: true })}
            onBlur={e => onFocusChange({ e, focus: false })}
          />
          {children}
          {loading && <Icon name="spinner" color={theme.color('tertiary')} />}
        </InputContainer>
      </Div>
    );
  },
);
export default Input;
