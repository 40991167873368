import styled, { css } from 'styled-components';
import { FONT_SIZE_OF_18 } from '~/styles/constants';

export const ModalContainer = styled.div<{}>`
  width: 50vw;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ListContainer = styled.div<{}>`
  flex-grow: 1;

  ${({ theme }) => `
    padding: ${theme.space('m')} 4vw;
  `};
`;

export const HeaderPartContainer = styled.div<{}>`
  width: 100%;
  text-align: center;
  align-self: end;

  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    color: ${theme.color('text', 'light')};
    font-size: ${FONT_SIZE_OF_18}px;
  `};
`;

export const SubHeaderContainer = styled.div<{}>`
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
`;
export const FromContainer = styled(HeaderPartContainer)<{}>`
  grid-column: 1 / 2;
`;
export const ToContainer = styled(HeaderPartContainer)<{}>`
  grid-column: 3 / 4;
`;

export const ErrorMessageContainer = styled.div<{}>`
  text-align: center;
  width: 100%;

  ${({ theme }) => `
    color: ${theme.color('danger')};
    margin: ${theme.space('l')} 0;
  `};
`;
