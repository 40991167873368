import React from 'react';

import FoldableCardBlock from '../FoldableCardBlock';

import FlowInstanceList from '../components/FlowInstanceList';
import useConfirmModal from '~/hooks/useConfirmModal';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  FlowV2InstanceStatus,
  useGetFlowV2InstancesQuery,
  useStopFlowV2InstanceMutation,
} from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';

const text = {
  confirmModalTitle: 'Bevestiging',
  confirmModalMessage:
    'Weet je zeker dat je deze flow wil stoppen voor dit contact?',
  confirmModalConfirmButtonText: 'Bevestigen',
  confirmModalCancelButtonText: 'Annuleren',
  activeFlowsButtonLabel: 'Actieve flows',
};
const confirmModalText = {
  title: text.confirmModalTitle,
  message: text.confirmModalMessage,
  buttonConfirmTitle: text.confirmModalConfirmButtonText,
  buttonCancelTitle: text.confirmModalCancelButtonText,
};

type Props = {
  contactId: string;
};
const FlowInstancesBlock: React.FCC<Props> = ({ contactId }) => {
  const [showConfirmModal, setOnConfirmFunction] = useConfirmModal(
    confirmModalText,
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );
  const account = useCurrentAccount();

  const { data, loading, error, refetch } = useGetFlowV2InstancesQuery({
    variables: {
      accountId: account.id,
      contactId,
      statusFilter: [
        FlowV2InstanceStatus.Running,
        FlowV2InstanceStatus.Waiting,
      ],
    },
  });

  const [stopFlowV2Instance] = useStopFlowV2InstanceMutation({
    onCompleted: () => refetch(),
  });

  const flowv2Instances = data?.getFlowV2Instances.items;

  if (loading || loading) return <Loading />;

  if (error || error || !flowv2Instances) {
    return <AppErrorScreen />;
  }

  return (
    <FoldableCardBlock
      dataTestId="card-block-contact-flowinstances"
      icon={'GEAR'}
      title={text.activeFlowsButtonLabel}
    >
      <JustificationContainer gap="base" direction="column">
        {flowv2Instances.length > 0 && (
          <FlowInstanceList
            value={flowv2Instances}
            onClick={item => {
              setOnConfirmFunction(() =>
                stopFlowV2Instance({
                  variables: {
                    accountId: account.id,
                    id: item.id,
                  },
                }),
              );
              showConfirmModal();
            }}
          />
        )}
      </JustificationContainer>
    </FoldableCardBlock>
  );
};

export default FlowInstancesBlock;
