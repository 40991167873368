import React from 'react';
import CopyCode from '~/components/atom/CopyCode';
import GoToPath from '~/components/organism/GoToPath';
import { Heading4, Label, Variant } from '~/components/atom/Typography';

import { text } from '../../index';

export type Props = {
  zapierApiKey: string;
};

const GetApiKey: React.FCC<Props> = ({ zapierApiKey }) => (
  <>
    <CopyCode
      label={
        <Label
          withoutMargin
          fontWeight="semiBold"
          variant={Variant.primary}
          size="m"
        >
          {text.codeLabel}
        </Label>
      }
      code={zapierApiKey}
    />
    <Heading4 margin={['l', null, 's', null]} variant={Variant.primary}>
      {text.secondStep}
    </Heading4>
    <GoToPath path={text.path} label={text.pathLabel} link={text.link} />
  </>
);

export default GetApiKey;
