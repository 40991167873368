import React, { type MutableRefObject } from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { componentSizes, componentAppearances } from '~/styles/constants';
import type { Props as JustificationProps } from '~/components/atom/JustificationContainer';
import type { Appearance, Size } from '~/styles/constants';
import type { SystemSize } from '~/theme/System/tokens';
import arrayToCss from '~/util/arrayToCss';

export type Props = JustificationProps & {
  /** Changes border color */
  appearance?: Appearance;

  /** Makes border danger color */
  hasError?: boolean;

  /** Makes border primary color */
  hasFocus?: boolean;

  /** Changes font-size and spaces used */
  size?: Size;

  /** A shared prop with the input element. Important to pass in both places */
  width?: string;

  /** Removes padding */
  withoutPadding?: boolean;

  /** A shared prop with the input element. Important to pass in both places */
  disabled: boolean;

  /**
   * Important to pass the input ref into the input container.
   * It is used to focus on input element when input container is clicked.
   */
  inputRef: MutableRefObject<HTMLInputElement | null>;

  className?: string;
};

/**
 * Use this to style the container of a textarea, input field etc.
 *
 * Spaces are determined by the size prop so do not pass a custom padding, gap etc.
 */
const InputContainer: React.FCC<Props> = ({
  dataTestId,
  children,
  hasFocus,
  hasError,
  size = 'medium',
  appearance,
  width,
  disabled,
  withoutPadding,
  inputRef,
  ...rest
}) => (
  <Container
    data-testid={dataTestId}
    justification="space-between"
    align="center"
    $hasFocus={hasFocus}
    $appearance={hasError ? 'danger' : appearance}
    $size={size}
    $width={width}
    $disabled={disabled}
    $withoutPadding={withoutPadding}
    onMouseDown={e => {
      // Make sure that input does not lose focus when click is outside InputElement
      // but stil inside the InputContainer
      const target = e.target as HTMLDivElement;
      if (target.tagName.toLowerCase() !== 'input') {
        e.preventDefault();
        if (inputRef.current && !hasFocus) inputRef.current.focus();
      }
    }}
    {...rest}
  >
    {children}
  </Container>
);

const gapMap: { [key in Size]: SystemSize } = {
  small: 'xxs',
  medium: 'xs',
  large: 's',
};

export const Container = styled(JustificationContainer)<{
  $hasFocus?: boolean;
  $disabled?: boolean;
  $width?: string;
  $size?: Size;
  $appearance?: Appearance;
  $withoutPadding?: boolean;
}>(
  ({
    theme,
    $size = 'medium',
    $appearance,
    $hasFocus,
    $width,
    $disabled,
    $withoutPadding,
  }) => css`
    position: relative;

    width: ${$width};
    padding: ${$withoutPadding
      ? '0'
      : arrayToCss(componentSizes[$size].padding, theme)};
    gap: ${theme.space(gapMap[$size])};

    font-size: ${theme.fontSize(componentSizes[$size].fontSize)};
    background-color: ${theme.color('white')};

    border-radius: ${theme.getTokens().border.radius.s};
    border-color: ${$hasFocus && $appearance !== 'danger'
      ? theme.color('primary', 'light')
      : $appearance
        ? theme.color(
            componentAppearances[$appearance].group,
            componentAppearances[$appearance].variant,
          )
        : theme.color('grey')};
    border-style: solid;
    border-width: ${theme.getTokens().border.width.s};

    ${$disabled &&
    css`
      color: ${theme.color('grey')};
      background-color: ${theme.color('white', 'dark')};
      border: 1px solid ${theme.color('grey')};
      pointer-events: none;
      opacity: 0.6;
    `};
  `,
);

export default InputContainer;
