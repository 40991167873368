import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import useStrapiPage from './hooks/useStrapiPage';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import StrapiDynamicZone from '~/components/organism/StrapiDynamicZone';

export type Props = {
  slug: string;
};

const ConversionPage: React.FCC<Props> = ({ dataTestId, slug, ...rest }) => {
  const pageData = useStrapiPage(slug);

  return (
    <>
      <MetaTags>
        <title>{pageData?.attributes.title}</title>
      </MetaTags>
      <ContentContainerDefault
        style={{ maxWidth: '1400px' }}
        data-testid={dataTestId}
        showWarnings={false}
        {...rest}
      >
        {pageData && (
          <StrapiDynamicZone components={pageData.attributes.dynamicZone} />
        )}
      </ContentContainerDefault>
    </>
  );
};

export default ConversionPage;
