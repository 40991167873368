import React, { useEffect } from 'react';
import { Body } from '~/components/atom/Typography';
import { WizardStepProps } from '~/components/organism/Wizard/context/WizardContext';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useWizardStep from '~/hooks/useWizardStep';
import ScriptForRealworksCustomerBlock from './components/ScriptForRealworksCustomerBlock';
import SetupScriptManually from './components/SetupScriptManually';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';

export const id = 'AddWidgetScript';
export const title = 'Widget installatie';

const text = {
  body: (
    <>
      Om de widget zichtbaar te maken op jouw website, zal je deze moeten
      plaatsen. Hieronder vind je instructies hiervoor.
      <br />
      <br />
      Let op: Heb je een Realworks website? Volg dan de stappen onderaan deze
      pagina.
    </>
  ),
  tip: (
    <>
      Kom je er niet uit? &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
  sendScriptByEmailTitle: 'Stuur instructies naar jouw webbeheerder',
};

export type OutputType = {
  type: typeof id;
};

export const Component: React.FCC<WizardStepProps> = ({ step, outputMap }) => {
  const account = useCurrentAccount();
  const currentOutput = outputMap[id] as OutputType;
  const [, api] = useWizardStep(step.id);

  useEffect(() => {
    api.free(currentOutput);
    api.lockGoBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JustificationContainer width="100%" direction="column" gap="l">
      <Body withoutMargin>{text.body}</Body>

      <JustificationContainer direction="column">
        <TipBanner
          width="100%"
          id="add-widget-script-onboarding"
          dismissible={false}
        >
          {text.tip}
        </TipBanner>
        <SetupScriptManually accountId={account.id} />
        <ScriptForRealworksCustomerBlock accountId={account.id} />
      </JustificationContainer>
    </JustificationContainer>
  );
};

export default {
  id,
  title,
};
