import React, { useState } from 'react';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  type FormBuilder_Locale,
  type FormBuilder_OptionList,
  type FormData_Select_MultiButton,
  type FormData_Select_Radio,
} from '~/graphql/types';
import { localeToIntlKey } from '../../../../constants';
import { Heading5, Variant } from '~/components/atom/Typography';
import SelectBlock from '~/components/molecule/SelectBlock';
import { formatOptions } from '../../../ScreenPreview/utils/formatNode';
import withRequiredMark from '~/hocs/withRequiredMark';

type Props = {
  block: FormData_Select_Radio | FormData_Select_MultiButton;
  optionsList: Array<FormBuilder_OptionList>;
  currentLocale: FormBuilder_Locale;
  required?: boolean;
};

const SingleChoice: React.FC<Props> = ({
  block,
  optionsList,
  currentLocale,
  required,
}) => {
  const options = formatOptions(block.optionListId, optionsList);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleRadioChange = op => {
    setSelectedOption(op.payload);
  };

  const Label = required ? RequiredLabel : Heading5;
  return (
    <JustificationContainer direction="column" width="100%">
      <Label variant={Variant.primary}>
        {block.label[localeToIntlKey[currentLocale]]}
      </Label>
      <JustificationContainer direction="column" width="100%">
        {options.map(op => (
          <SelectBlock
            type="radio"
            key={op.payload}
            checkboxTitle={op.label[currentLocale]}
            checked={selectedOption === op.payload}
            onClick={() => handleRadioChange(op)}
          />
        ))}
      </JustificationContainer>
    </JustificationContainer>
  );
};

const RequiredLabel = withRequiredMark<{
  variant?: Variant;
  children: React.ReactNode;
}>(props => <Heading5 {...props} />);

export default SingleChoice;
