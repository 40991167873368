import React from 'react';
import styled from 'styled-components';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import { useGetBillingDetailsQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TextButton from '~/components/atom/TextButton';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';
import useOnUpdatePaymentMethod from './hooks/useOnUpdatePaymentMethod';
import Divider from '~/components/atom/Divider';

export type Props = {};

const text = {
  noBillingSetup: 'Je hebt nog geen betaalmethode',
  paymentsTitle: 'Betaalmethode',
  changePaymentDetails: 'Wijzig betaalmethode',
  paymentDescription: 'Betaling vindt plaats via ',
  paymentTypeDescription: 'SEPA automatische incasso.',
};

const PaymentDetails: React.FCC<Props> = ({ dataTestId, ...rest }) => {
  const account = useCurrentAccount();
  const onUpdatePaymentMethod = useOnUpdatePaymentMethod({
    onComplete: () => {},
  });
  const { data, networkStatus, error } = useGetBillingDetailsQuery({
    variables: {
      accountId: account.id,
    },
  });

  if (isInitialLoadStatus(networkStatus)) {
    // If there are no overages we do not want to show anything, so also don't show loading
    return <DatHuisLoading />;
  }

  if (error) {
    return <AppErrorScreen inline setBackgroundColor={false} />;
  }

  if (!data?.getBillingDetails) {
    return null;
  }

  return (
    <>
      <Container data-testid={dataTestId} {...rest}>
        <Heading4 variant={Variant.primary} withoutMargin>
          {text.paymentsTitle}
        </Heading4>
        {data.getBillingDetails.last4 && (
          <>
            <Body withoutMargin size="s">
              {text.paymentDescription}
              {text.paymentTypeDescription}
            </Body>
            <Body
              data-testid={TEST_ID.BANK_ACCOUNT_NUMBER}
              withoutMargin
              size="s"
            >
              {`Van rekening **** **${data.getBillingDetails.last4.substring(
                0,
                2,
              )} ${data.getBillingDetails.last4.substring(2, 4)}`}
            </Body>
          </>
        )}
        <JustificationContainer padding={['s', null]}>
          <TextButton
            withoutPadding
            label={text.changePaymentDetails}
            onClick={onUpdatePaymentMethod}
            dataTestId={TEST_ID.UPDATE_TRIGGER}
          />
        </JustificationContainer>
      </Container>
      <Divider margin={['s', null]} />
    </>
  );
};

const Container = styled.div<{}>``;

export default PaymentDetails;
