import styled from 'styled-components';

const Disclaimer = styled.p<{}>`
  font-style: italic;

  ${({ theme }) => `
    font-size: ${theme.fontSize('s')};
  `}
`;

export default Disclaimer;
