import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkSlate from 'remark-slate';
import { VFile } from 'vfile';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import { reporter } from '~/hooks/useErrorReporter';
import convertElements, { type ProcessedNodes } from './utils/convertElements';
import { EMPTY_EDITOR_VALUE } from '~/components/organism/PluginsEditor/constants';
import handleNewLinesInMarkdown from '~/components/molecule/Markdown/utils/handleNewLinesInMarkdown';

/**
 * Returns Slate value from a markdown string
 *
 * @param {string} markdownStr - Markdown string
 */
const deserializeMarkdown = (markdownStr: string): EditorValue => {
  if (markdownStr === '') return EMPTY_EDITOR_VALUE;

  if (typeof markdownStr !== 'string') {
    reporter.captureException(new Error('Markdown is not a string'), 'debug');
  }

  const file = new VFile({ value: handleNewLinesInMarkdown(markdownStr) });

  const processor = unified().use(remarkParse).use(remarkSlate);
  const processed = processor.processSync(file).result as ProcessedNodes;
  const converted = convertElements(processed);

  if (converted.length === 0) return EMPTY_EDITOR_VALUE;

  return converted;
};

export default deserializeMarkdown;
