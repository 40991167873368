import React from 'react';
import styled, { css } from 'styled-components';

import { Link as NavLink } from '~/components/molecule/Link';
import LogoIcon from '~/components/atom/Logo';

const Logo: React.FC = () => (
  <LogoContainer>
    <NavLink to="/-/">
      <StyledLogoIcon name="dathuis" />
    </NavLink>
  </LogoContainer>
);

const LOGO_WIDTH = '85px';

const LogoContainer = styled.div<{}>(
  ({ theme }) => css`
    padding: 0 ${theme.space('xl')};

    ${theme.mq.lessThan('mobile')`
      padding: 0;
    `}

    svg {
      width: ${LOGO_WIDTH};
    }
  `,
);

// The span doesn't grow to the avaiable space on Safari, this is why we need to overwrite the default height for the Navigation component
const StyledLogoIcon = styled(LogoIcon)<{}>`
  display: flex;
  height: 100%;
`;

export default Logo;
