import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link as NavLink } from '~/components/molecule/Link';

import { ImgDefinition } from '~/graphql/types.client.flow';
import { DeletionType } from '~/components/bad/Modals/util/constants';

import Dropdown from '~/components/molecule/Dropdown';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import Catalog from '~/Catalog';
import { ErrorMessageContainer } from './deleteModal.style';

import TEST_ID from './ListItemCard.testid';
import {
  DELETE_ENTITY_TYPE,
  DELETION_TYPE,
} from '~/components/bad/Modals/util/constants';
import Icon from '~/components/atom/Icon';
import UserIcon from '~/components/atom/Illustration/components/User';
import useOffice from '~/hooks/useOffice';
import useOffices from '~/hooks/useOffices';
import useOfficeOptions from '~/hooks/useOfficeOptions';
import { FONT_SIZE_OF_18 } from '~/styles/constants';
import hasValidOffice from '../util/hasValidOffice';
import hasValidUser from '../util/hasValidUser';
import { NOT_ASSIGNED_TO_USER_ID } from '~/components/page/Settings/Offices/components/OfficeDetails/constants';
import useUserOptions from '~/hooks/useUserOptions';
import useUserLookup from '~/hooks/useUserLookup';
import type { SessionHydrationUserFields_User_Fragment } from '~/graphql/types';

const text = {
  officeLabel: Catalog.noOffice,
  userLabel: Catalog.noUser,
};
export type ListItemForDeletion = {
  id: string;
  img?: ImgDefinition | null | undefined;
  name: string;
};
export type MigrationItem = {
  sourceUserId: string;
  sourceOfficeId: string;
  targetOfficeId: string | null;
  targetUserId: string | null;
  listItem: ListItemForDeletion;
};
type Props = {
  item: ListItemForDeletion;
  isLastItem: boolean;
  migrationValue: MigrationItem;
  setMigrationValue: (data: MigrationItem) => void;
  showValidation: boolean;
  // This is the userId in deleteUserFromOffice and deleteUserFromAccount and officeId in deleteOffice modal
  entityId: string;
  entityType: string;
  deletionType: DeletionType;
};
const ListItemCard: React.FCC<Props> = ({
  item,
  isLastItem,
  migrationValue,
  setMigrationValue,
  showValidation,
  entityId,
  entityType,
  deletionType,
}) => {
  const [officeFieldTouched, setOfficeFieldTouched] = useState(false);
  const [userFieldTouched, setUserFieldTouched] = useState(false);

  const { img, name } = item;
  const { sourceOfficeId, sourceUserId, targetOfficeId, targetUserId } =
    migrationValue;

  const officesForUser = useOffices({ userIds: [sourceUserId] });

  const isUserWithOnlyOneOffice =
    entityType === DELETE_ENTITY_TYPE.OFFICE &&
    officesForUser.length <= 1 &&
    sourceUserId !== NOT_ASSIGNED_TO_USER_ID;

  const officeToDelete = useOffice(entityId);

  let officeOptions = useOfficeOptions();
  if (deletionType === DELETION_TYPE.DELETE_OFFICE) {
    officeOptions = officeOptions.filter(
      option => option.payload?.id !== entityId,
    );
  }

  const userLookup = useUserLookup(['User']);
  let userOptions = useUserOptions({
    officeId: targetOfficeId,
    withAllUsersOption: true,
  });

  if (
    sourceOfficeId === targetOfficeId ||
    deletionType === DELETION_TYPE.DELETE_USER_FROM_ACCOUNT
  ) {
    userOptions = userOptions.filter(
      user =>
        // On user details page do not show the user as an option
        user.payload?.id !== entityId,
    );
  }

  const image = img ? (
    <ItemImg src={img.medium} />
  ) : (
    <NoItemImage>
      <UserIllustration />
    </NoItemImage>
  );

  const sourceEntityName = (() => {
    if (entityType === DELETE_ENTITY_TYPE.OFFICE) {
      return (
        <>
          {name} <br />
          {officeToDelete?.name}
        </>
      );
    } else {
      const user = userLookup?.[
        entityId
      ] as SessionHydrationUserFields_User_Fragment;

      return (
        <>
          {user?.name || 'onbekend'} <br />
          {name}
        </>
      );
    }
  })();

  return (
    <>
      <Container data-testid={TEST_ID.CONTAINER}>
        <SourceOfficeContainer>
          {image}
          <SourceOfficeNameContainer>
            {sourceEntityName}
          </SourceOfficeNameContainer>
        </SourceOfficeContainer>

        <ArrowContainer>
          <Icon name="arrow" clockwise={90} />
        </ArrowContainer>

        <TargetOfficeContainer
          data-testid={officeToDelete ? sourceUserId : sourceOfficeId}
        >
          <StyledInputGroup>
            <Dropdown
              error={
                !hasValidOffice(migrationValue) &&
                (officeFieldTouched || showValidation == true)
                  ? text.officeLabel
                  : null
              }
              label={text.officeLabel}
              dataTestId={TEST_ID.OFFICE_DROPDOWN}
              options={officeOptions}
              onChange={({ option }) => {
                setMigrationValue({
                  ...migrationValue,
                  targetOfficeId: option.payload?.id,
                });
              }}
              selectedOptionIdx={officeOptions.findIndex(office => {
                if (targetOfficeId === null) return office.payload === null;
                return office.payload?.id == targetOfficeId;
              })}
              onClickOutside={() => {
                setOfficeFieldTouched(true);
              }}
            />
          </StyledInputGroup>
          <StyledInputGroup>
            <Dropdown
              error={
                !hasValidUser(migrationValue) &&
                (userFieldTouched || showValidation == true)
                  ? text.userLabel
                  : null
              }
              label={text.userLabel}
              dataTestId={TEST_ID.USER_DROPDOWN}
              options={userOptions}
              onChange={({ option }) => {
                setMigrationValue({
                  ...migrationValue,
                  targetUserId: option.payload?.id,
                });
              }}
              selectedOptionIdx={userOptions.findIndex(user => {
                // Prevent automatic selection of 'no-selection' option when an office is selected
                if (targetUserId === null && user.key !== 'no-selection') {
                  return;
                }
                return user.payload?.id === targetUserId;
              })}
              onClickOutside={() => {
                setUserFieldTouched(true);
              }}
            />
          </StyledInputGroup>
        </TargetOfficeContainer>
      </Container>

      {entityType === DELETE_ENTITY_TYPE.OFFICE && isUserWithOnlyOneOffice && (
        <ErrorMessageContainer data-testid={TEST_ID.ERROR_MESSAGE}>
          Het is niet mogelijk om {name} te verwijderen van vestiging{' '}
          {officeToDelete?.name}. De gebruiker dient aan tenminste één vestiging
          gekoppeld te zijn. Ga naar{' '}
          <NavLink to={'/-/settings/users'}>Gebruikers</NavLink> om deze
          gebruiker te wijzigen of te verwijderen.
        </ErrorMessageContainer>
      )}

      {!isLastItem && <LineDivider />}
    </>
  );
};

const Container = styled.div<{}>`
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 1fr auto 1fr;
  grid-column: 1 / 4;

  ${({ theme }) => css`
    margin: ${theme.space('m')} 0;
  `};
`;

const SourceOfficeContainer = styled.div<{}>`
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  place-self: center;
`;

const ArrowContainer = styled.div<{}>`
  display: none;
  text-align: center;
  font-size: 40px;
  grid-column: 2 / 3;

  ${({ theme }) => css`
    color: ${theme.color('text', 'light')};

    ${theme.mq.greaterThan('desktop')`
      display: block;
    `}
  `};
`;

const TargetOfficeContainer = styled.div<{}>(
  ({ theme }) => css`
    grid-column: 3 / 4;
    padding: 0 ${theme.space('xs')};
    justify-self: center;
  `,
);

const LineDivider = styled.div<{}>`
  height: 1px;
  grid-column: 1 / 4;

  ${({ theme }) => css`
    background: ${theme.color('grey', 'light')};
  `};
`;

const SourceOfficeNameContainer = styled.div<{}>`
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    font-size: ${FONT_SIZE_OF_18}px;
    margin-top: ${theme.space('m')};

    @media all and (max-width: ${theme.bp('tabletLandscape')}) {
      max-width: 100px;
    }
  `};
`;

const UserIllustration = styled(UserIcon)<{}>`
  position: absolute;
  ${({ theme }) => `
    color: ${theme.color('primary', 'light')};
  `};

  > svg {
    height: 40px;
    width: 40px;
  }
`;

const NoItemImage = styled.div<{}>`
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  ${({ theme }) => css`
    background: ${theme.color('white', 'dark')};
  `};
`;

const ItemImg = styled.img<{}>`
  border-radius: 100%;
  height: 80px;
  width: 80px;
`;

const StyledInputGroup = styled(InputGroup)<{}>`
  min-width: 200px;
  padding: 5px 0;
`;

export default ListItemCard;
