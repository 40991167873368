import React, { forwardRef } from 'react';
import InputContainer from '~/components/atom/InputContainer';
import InputElement from '~/components/atom/InputElement';
import TEST_ID from './index.testid';
import type { Props as ButtonProps } from '~/components/atom/Button';
import type { Props as InputProps } from '~/components/molecule/Input';
import useInputValue from '~/hooks/useInputValue';
import InputLabel from '~/components/atom/InputLabel';
import Div from '~/components/atom/Div';
import NoSpaceTextButton from '~/components/atom/NoSpaceTextButton';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = InputProps & {
  /**
   * Buttons to be added on the right side of the input container
   */
  buttonActions: Array<ButtonProps>;
};

const InputWithButtons: React.FCC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    {
      buttonActions,
      size = 'medium',
      disabled = false,
      label,
      onFocus,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    const {
      value,
      externalErrors,
      validation,
      type,
      onChange,
      appearance,
      width,
    } = rest;

    const {
      validationErrors,
      hasError,
      hasFocus,
      inputRef,
      onValueChange,
      onFocusChange,
    } = useInputValue({
      value,
      externalErrors,
      validation,
      type,
      onChange,
      onFocus,
      onBlur,
      ref,
    });

    return (
      <Div width={width}>
        <InputLabel size={size} error={validationErrors?.[0]} label={label} />

        <InputContainer
          wrap="nowrap"
          width={width}
          size={size}
          disabled={disabled}
          hasError={hasError}
          hasFocus={hasFocus}
          appearance={appearance}
          inputRef={inputRef}
        >
          <InputElement
            {...rest}
            ref={inputRef}
            size={size}
            disabled={disabled}
            dataTestId={TEST_ID.INPUT}
            onChange={onValueChange}
            onFocus={e => onFocusChange({ e, focus: true })}
            onBlur={e => onFocusChange({ e, focus: false })}
          />

          <JustificationContainer gap="base">
            {buttonActions.map(({ icon, appearance, ...rest }) => (
              <NoSpaceTextButton
                dataTestId={TEST_ID.BUTTON}
                key={`button-${icon}`}
                icon={icon}
                appearance={appearance || 'secondary'}
                size={size}
                {...rest}
              />
            ))}
          </JustificationContainer>
        </InputContainer>
      </Div>
    );
  },
);

export default InputWithButtons;
