import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Theme } from '~/theme';

import { useRef } from 'react';

type Props = {
  children: (setRef: (ref: HTMLElement) => void) => React.ReactNode;
};
const LinkedSubField = ({ children }: Props) => {
  const toCenterRef = useRef<null | HTMLElement>(null);
  const containerRef = useRef<null | HTMLElement>(null);
  const [distanceToCenter, setDistanceToCenter] = useState(0);

  const calculateDistance = () => {
    if (
      toCenterRef.current == null ||
      !toCenterRef.current.getBoundingClientRect
    ) {
      setDistanceToCenter(0);
    } else {
      const { top: toCenterTop, bottom: toCenterBottom } =
        toCenterRef.current.getBoundingClientRect();

      if (
        containerRef.current == null ||
        !containerRef.current.getBoundingClientRect
      ) {
        setDistanceToCenter(0);
      } else {
        const { top: containerTop } =
          containerRef.current.getBoundingClientRect();

        const newDistance =
          toCenterTop - containerTop + (toCenterBottom - toCenterTop) / 2;

        setDistanceToCenter(newDistance);
      }
    }
  };

  return (
    <Container
      ref={element => {
        containerRef.current = element;
        calculateDistance();
      }}
    >
      <LineElement distanceToCenter={distanceToCenter} />
      <ChildContainer>
        {children(childRef => {
          toCenterRef.current = childRef;
        })}
      </ChildContainer>
    </Container>
  );
};

const Container = styled.div<{}>`
  display: flex;
  position: relative;

  ${''}
  /* It is expected that this is used in an inputgroup, so take up all the space you can */
  width: 100%;
`;

const getSubFieldDistanceNoUnit = (theme: Theme) =>
  theme.remToPxRaw(theme.space('xl'));
type LineElementProps = {
  distanceToCenter: number;
};
const LineElement = styled.div<LineElementProps>`
  ${({ theme }) => css`
    margin-left: calc(${getSubFieldDistanceNoUnit(theme)}px / 2);
    padding-left: calc(${getSubFieldDistanceNoUnit(theme)}px / 2);
  `};

  ${({ theme, distanceToCenter }) => {
    if (distanceToCenter !== 0) {
      return css`
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: calc(${getSubFieldDistanceNoUnit(theme)}px / 2);
          width: 1px;
          height: ${distanceToCenter}px;
          background-color: ${theme.color('grey')};
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: ${distanceToCenter}px;
          left: calc(${getSubFieldDistanceNoUnit(theme)}px / 2);
          width: calc(${getSubFieldDistanceNoUnit(theme)}px / 2);
          height: 1px;
          background-color: ${theme.color('grey')};
        }
      `;
    }

    return '';
  }};
`;

const ChildContainer = styled.div<{}>`
  flex-grow: 1;
`;

export default LinkedSubField;
