import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { v1 as uuidv1 } from 'uuid';
import JustificationContainer from '~/components/atom/JustificationContainer';

import InputGroupElement from '~/components/bad/Inputs/InputGroupElement';
import type { SystemSize } from '~/theme';

export type Props = {
  checked: boolean;

  /** Set data-testid */

  /** Set data-objectid */
  dataObjectId?: string;
  onChange?: (arg0: React.SyntheticEvent<any> | any) => void;

  /** Label to be displayed */
  label?: string | null;

  /** Name param should be used the same for all radio buttons in same set */
  name?: string;

  /** Disable radiobutton */
  disabled?: boolean;

  className?: string;

  /** Margin of the container */
  margin?: Array<SystemSize | null>;
};

/**
 * RadioButton Element
 *
 * Should pretty much always be used in combination with a InputGroup
 * in order to have consistent styling.
 */
const RadioButton: React.FCC<Props> = ({
  dataTestId,
  name,
  dataObjectId,
  label,
  disabled,
  className,
  margin = [null],
  onChange,
  ...rest
}) => {
  const id = useMemo(() => uuidv1(), []);
  const saveName = name || uuidv1();
  const labelTestId = dataTestId ? dataTestId + '-label' : '';

  return (
    <Container
      data-objectid={dataObjectId}
      className={className}
      margin={margin}
      direction="column"
      width="100%"
      {...rest}
    >
      <RadioButtonElement
        {...rest}
        id={id}
        type="radio"
        name={saveName}
        onChange={onChange}
        data-testid={dataTestId}
        disabled={disabled}
      />
      <label htmlFor={id} data-testid={labelTestId}>
        {label}
      </label>
    </Container>
  );
};

const Container = styled(JustificationContainer)<{ $margin?: boolean }>(
  () => css`
    position: relative;
  `,
);

type RadioButtonElementProps = {
  disabled?: boolean;
};
const RadioButtonElement = styled.input<RadioButtonElementProps>`
  /* "visually hidden" or "screen reader only" technique to hide the item */
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;

  & + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    min-height: 20px;
    display: inline-flex;
    align-items: center;

    ${({ disabled }) =>
      disabled &&
      css`
        pointer-events: none;
        opacity: 0.6;
      `};
  }

  & + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 3px solid ${({ theme }) => theme.color('grey')};
    border-radius: ${({ theme }) => theme.getTokens().border.radius.full};
    background: transparent;
  }

  &:checked + label:before {
    border-color: ${({ theme }) => theme.color('primary', 'light')};
  }

  & + label:after {
    content: '';
    width: ${({ theme }) => theme.space('xs')};
    height: ${({ theme }) => theme.space('xs')};
    background: ${({ theme }) => theme.color('primary', 'light')};
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: ${({ theme }) => theme.getTokens().border.radius.full};
    transition: all 0.2s ease;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`;

export default InputGroupElement(
  RadioButton,
  css`
    align-self: center;
    flex: 0;
  `,
);
