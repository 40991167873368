import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { ANIMATION_CONFIG } from '~/styles/constants';
import HelpButton from '~/components/molecule/HelpButton';
import { Heading3 } from '../Typography';

type Props = {
  title?: string;

  className?: string;

  /** If the normal layout helpers for flex layouts should be ignored */
  manualLayout?: boolean;
  dataIsPolling?: boolean;
};

const AnimatedBlock: React.FCC<Props> = ({
  children,
  dataTestId,
  title,
  dataIsPolling,
  manualLayout,
  ...rest
}) => {
  const [animationFinished, setAnimationFinished] = useState(false);

  const animation = useSpring({
    ...ANIMATION_CONFIG,
    onRest: ({ finished }) => {
      if (finished) setAnimationFinished(true);
    },
  });

  const [styleTransform, setStyleTransform] = useState<any>(
    animation.transform,
  );

  useEffect(() => {
    if (animationFinished) {
      setStyleTransform(undefined);
    }
  }, [animationFinished]);

  const style = { ...animation, transform: styleTransform };

  return (
    <Container
      {...rest}
      $manualLayout={manualLayout}
      data-testid={dataTestId ? dataTestId : ''}
      data-ispolling={dataIsPolling}
      style={style}
    >
      {title && <Heading3 size="m">{title}</Heading3>}
      <animated.div style={style}>{children}</animated.div>
    </Container>
  );
};

export const SectionHelpButton = styled(HelpButton)<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('s')};
    margin-bottom: ${theme.space('s')};
    font-size: ${theme.fontSize('m')};
  `,
);

type ContainerProps = {
  $manualLayout?: boolean;
};

const MIN_CARD_WIDTH = 350;
const MAX_CARD_WIDTH = 530;

const Container = styled(animated.div)<ContainerProps>(
  ({ theme, $manualLayout }) => css`
    flex: 1 0 100%;
    width: 100%;

    background-color: ${theme.color('white')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('white', 'dark')};
    border-radius: ${theme.getTokens().border.radius.s};
    padding: ${theme.space('l')};
    box-shadow: ${theme.getTokens().boxShadow.base};

    ${() => {
      if ($manualLayout !== true) {
        return css`
          min-width: ${MIN_CARD_WIDTH}px;
          max-width: ${MAX_CARD_WIDTH}px;
          margin-bottom: ${theme.space('l')};
        `;
      }
      return ``;
    }}
  `,
);

export default AnimatedBlock;
