import React from 'react';
import styled from 'styled-components';

import { OfficeInList } from '~/components/page/Settings/Profile/components/FormContainer';

import FormFields from './components/FormFields';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import Catalog from '~/Catalog';
import FormSaveBar from '~/components/bad/SaveBar/FormSaveBar';
import Form from '~/components/organism/Forms/Form';
import Validation from '~/util/Validation';
import { UserDetailsFormData } from '~/components/page/Settings/Profile';

type Props = {
  officesList: Array<OfficeInList>;
  showDeleteModal: (arg0: string) => void;
  mayEdit: boolean;
  userId?: string;
  dataIsPolling: boolean;
  initialValues: { email: string; phone: string };
  onFormSubmit?: (formData: UserDetailsFormData) => void;
  readOnly?: boolean;
};

const text = {
  phoneValidation: Catalog.invalidPhone,
  contactInfo: 'Contactgegevens',
};

const UserDetailsForm: React.FCC<Props> = ({
  officesList,
  showDeleteModal,
  mayEdit,
  userId,
  dataIsPolling,
  onFormSubmit,
  initialValues,
  readOnly,
}) => {
  const validate = ({ phone }: UserDetailsFormData): { phone?: string } => {
    const errors: { phone?: string } = {};
    if (!Validation.Phone.isValid(phone)) {
      errors.phone = text.phoneValidation;
    }

    return errors;
  };

  return (
    <AnimatedBlock
      title={text.contactInfo}
      dataTestId="user-details-block"
      dataIsPolling={dataIsPolling}
    >
      <Form
        onSubmit={onFormSubmit ? onFormSubmit : () => {}}
        validate={validate}
        initialValues={initialValues}
      >
        {({ handleSubmit, form, submitError }) => (
          <StyledForm
            id="user-details-form"
            onSubmit={handleSubmit}
            data-testid="user-details-form"
          >
            {submitError ? (
              <div data-testid="sign-in-error-message">{submitError}</div>
            ) : null}
            <FormFields
              officesList={officesList}
              readOnly={readOnly}
              onMainOfficeUpdate={form.change}
              showDeleteModal={showDeleteModal}
              mayEdit={mayEdit}
              userId={userId}
            />

            {<FormSaveBar inBlockComponent />}
          </StyledForm>
        )}
      </Form>
    </AnimatedBlock>
  );
};

const StyledForm = styled.form<{}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export default UserDetailsForm;
