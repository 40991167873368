import React, { useContext } from 'react';
import ModalContext from '~/contexts/ModalContext';
import useCurrentUser from '~/hooks/useCurrentUser';
import ConfirmActionModal from '../../components/ConfirmActionModal';
import BasePopover from '../../components/BasePopover';
import type { ActionModalProps } from '../../constants';

const text = {
  title: 'Contacten exporteren',
  confirm: 'Bevestigen',
  popoverDescription:
    'Exporteer de geselecteerde contacten naar een CSV bestand. Het bestand wordt verzonden naar ',
};

export type Props = {} & ActionModalProps;

const SendCSV: React.FCC<Props> = ({ onCancel, onComplete, ...rest }) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const email = useCurrentUser().email;

  return (
    <BasePopover
      title={text.title}
      handleAction={confirm => {
        if (confirm) {
          showModal(
            <ConfirmActionModal
              actionType="SendCSV"
              actionInput={{
                SendCSV: {
                  noOperation: null,
                },
              }}
              onComplete={() => {
                hideModal();
                onComplete();
              }}
              onCancel={hideModal}
              {...rest}
            />,
          );
        } else {
          onCancel();
        }
      }}
      confirmText={text.confirm}
    >
      {text.popoverDescription} <strong>{email}</strong>
    </BasePopover>
  );
};

export default SendCSV;
