import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { useGetFlowV2Query } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderWithContext from '../BuilderWithContext';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';
import getFlowActionsToClientActions from '../../util/getFlowActionsToClientActions';
import { useSetRecoilState } from 'recoil';
import flowActions from '../../state';
import { clone } from 'ramda';
import type { ClientFlowAction } from '~/graphql/types.client';

type Props = RouteComponentProps<{ id: string }>;

const UpdateFlowBuilder: React.FCC<Props> = ({ id }) => {
  const account = useCurrentAccount();
  const { data, loading } = useGetFlowV2Query({
    variables: {
      id: id as string,
      accountId: account.id,
    },
  });

  const setActions = useSetRecoilState(flowActions);
  const [initialActions, setInitialActions] = useState<Array<ClientFlowAction>>(
    [],
  );

  useEffect(() => {
    if (data?.getFlowV2) {
      const clientActions = getFlowActionsToClientActions(
        data?.getFlowV2.Actions ?? [],
      );
      setActions(clientActions);
      setInitialActions(clone(clientActions));
    }
  }, [data?.getFlowV2, setActions]);

  if (loading || !data?.getFlowV2 || !data.getFlowV2.id)
    return (
      <>
        <MetaTags>
          <title>Laden...</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  const initialSettings = {
    flowId: data.getFlowV2.id,
    flowName: data.getFlowV2.name,
    flowDescription: data.getFlowV2.description,
    enabled: data.getFlowV2.enabled,
    maximumFlowRun: data.getFlowV2.maximumFlowRun,
  };
  const { flowId, flowName, flowDescription, enabled, maximumFlowRun } =
    initialSettings;

  return (
    <BuilderWithContext
      flowId={flowId}
      initialFlow={{
        flowName,
        flowDescription,
        enabled,
        maximumFlowRun,
        actions: initialActions,
      }}
    />
  );
};

export default withRouterTransitionContext(UpdateFlowBuilder);
