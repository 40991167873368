import React, { useState } from 'react';
import { ImgDefinition } from '~/graphql/types.client.flow';
import uploadS3Image from '~/util/uploadS3Image';
import AvatarWithUploader from '../../../AvatarWithUploader';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import JustificationContainer from '~/components/atom/JustificationContainer';
import EditableText from '../../../EditableText';
import CopyTextBlock from '~/components/molecule/CopyTextBlock';
import { useUpdateOfficePictureMutation } from '~/graphql/types';
import styled, { css } from 'styled-components';

type Props = {
  edit?: boolean | null | undefined;
  office: {
    id: string;
    img: ImgDefinition | null | undefined;
    name: string;
  };
  onSuccess: () => void;
  onImageUploadError: () => void;
  updateName: (name: string) => void;
};

const text = {
  officeIdLabel: 'Office ID',
};
const OfficeProfileHeader: React.FCC<Props> = ({
  edit,
  office,
  onSuccess,
  onImageUploadError,
  updateName,
}) => {
  const account = useCurrentAccount();
  const [loading, setLoading] = useState(false);
  const [updateProfilePicture] = useUpdateOfficePictureMutation();

  const imageUrl = office && office.img ? office.img.medium : '';
  const officeId = office.id;

  const onUploadImageDone = (
    identityId: string | null | undefined,
    permanentLink: string | null | undefined,
    filename: string,
  ) => {
    if (!identityId) return;

    const variables = {
      accountId: account.id,
      officeId,
      identityId,
      filename,
    };
    return updateProfilePicture({
      variables,
    }).then(({ data, errors }) => {
      setLoading(false);
      if (!errors && data) {
        onSuccess();
      }
    });
  };

  const onUploadImage = async (file: File) => {
    try {
      setLoading(true);
      const { identityId, permanentLink, filename } = await uploadS3Image({
        file,
      });

      return onUploadImageDone(identityId, permanentLink, filename);
    } catch {
      setLoading(false);
      onImageUploadError();
    }
  };

  return (
    <TitleContainer>
      <AvatarWithUploader
        avatarFor="officeProfile"
        edit={edit}
        imageUrl={imageUrl}
        onUpload={files => onUploadImage(files[0])}
        loading={loading}
      />
      <JustificationContainer direction="column" gap="xxs">
        <EditableText readonly={!edit} onSave={updateName} text={office.name} />
        <CopyTextBlock textToCopy={officeId} label={text.officeIdLabel} />
      </JustificationContainer>
    </TitleContainer>
  );
};

const TitleContainer = styled.div<{}>`
  order: 2;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    ${theme.mq.greaterThan('tabletLandscape')`
      width: 540px;
      order: 1;
    `}
  `};
`;

export default OfficeProfileHeader;
