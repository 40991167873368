import { debounce } from 'lodash';
import { isEmpty } from 'ramda';
import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';

import { isValid } from '~/util/Validation/Tag';
import AnimatedCheckbox from '~/components/molecule/AnimatedCheckbox';

type Attribute = {
  id: number;
  description: string | null;
  tag: string | null;
  checked: boolean;
};

export type Props = {
  row: Attribute;
  checkboxTitle: string;
  inputTitle: string;
  selectable: boolean;
  onChange: (value: string | boolean | null, field: string) => void;
};

const text = {
  tagFieldPlaceholder: 'Alleen kleine letters, cijfers en (-) zijn toegestaan',
  errorLabel: 'Vul een geldige tag in',
};

const SelectableRowWithInput: React.FCC<Props> = ({
  dataTestId,
  row: { id, description, tag, checked },
  checkboxTitle,
  inputTitle,
  onChange,
  selectable,
  ...rest
}) => {
  const currentBp = useCurrentBreakpoint();
  const cachedValue = useRef(tag).current || '';
  const [hasError, setHasError] = useState(false);

  const onTagChange = e => {
    setHasError(!isEmpty(e.target.value) && !isValid(e.target.value));
    onChange(e.target.value, 'tag');
  };
  const onTagChange_ = debounce(onTagChange, 500);

  const onCheckboxChange = useCallback(
    () => onChange(!checked, 'checked'),
    [checked, onChange],
  );

  const externalErrors = hasError ? [text.errorLabel] : undefined;

  if (currentBp === 'mobile') {
    return (
      <Container
        justification="space-between"
        padding={['m', null]}
        data-testid={dataTestId}
        data-objectid={id}
        {...rest}
      >
        <JustifiedRow
          justification="space-between"
          align="center"
          margin={['m', null]}
        >
          <Label>{checkboxTitle}</Label>
          <AnimatedCheckbox
            label={description ?? ''}
            value={checked}
            selectable={selectable}
            onChange={onCheckboxChange}
            name="row-checkbox"
          />
        </JustifiedRow>
        <JustifiedRow justification="space-between" align="center">
          <Label>{inputTitle}</Label>
          <Input
            defaultValue={cachedValue}
            onChange={onTagChange_}
            placeholder={text.tagFieldPlaceholder}
            name="row-input-field"
            externalErrors={externalErrors}
          />
        </JustifiedRow>
      </Container>
    );
  }

  return (
    <Container
      justification="space-between"
      align="center"
      padding={['m', null]}
      data-testid={dataTestId}
      data-objectid={id}
      {...rest}
    >
      <AnimatedCheckbox
        label={description ?? ''}
        value={checked}
        selectable={selectable}
        onChange={onCheckboxChange}
        name="row-checkbox"
      />

      <StyledInput
        defaultValue={cachedValue}
        onChange={onTagChange_}
        placeholder={text.tagFieldPlaceholder}
        externalErrors={externalErrors}
        name="row-input-field"
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)<{}>`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color('grey')};
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }

    /** Checkbox label is always aligned flex-end */
    & > label {
      align-self: center;
    }

    ${theme.mq.lessThan('mobile')`
      & > * {
        margin-bottom: ${theme.space('m')};
      }
    `};
  `}
`;

const JustifiedRow = styled(JustificationContainer)<{}>`
  width: 100%;
  flex-direction: row;
`;

const Label = styled.span<{}>(
  ({ theme }) => css`
    font-weight: ${theme.fw('semiBold')};
    margin-right: ${theme.space('m')};
    font-size: ${theme.fs('base')};
  `,
);

const StyledInput = styled(Input)<{}>`
  flex-basis: 50%;
`;

export default SelectableRowWithInput;
