import gql from 'graphql-tag';
import ActivityFields from './ActivityFields';
import Permission from './Permission';
import EmailStatusFields from './EmailStatus';

export default gql`
  ${Permission}
  fragment ContactListWithLastActivityFields on ContactWithLastActivity {
    __typename
    id
    name
    email
    permission {
      ...Permission
    }

    address {
      street
      houseNumber
      addition
      city
      postcode
    }
    createdDate
    tags
    status
    blockMarketing
    LeadScore
    lastActivity {
      ...ActivityFields
    }
    leadActivity {
      ...ActivityFields
    }
    emailStatus {
      ...EmailStatusFields
    }
    Metadata {
      leadScore
      oneDayChange
      threeDayChange
      sevenDayChange
    }
  }

  ${ActivityFields}
  ${EmailStatusFields}
`;
