import React, { useState } from 'react';
import ModalTemplate from '../../components/ModalTemplate';
import { navigate } from '@gatsbyjs/reach-router';
import useWizard from '~/hooks/useWizard';
import { AppSetupModalProps } from '../..';
import CustomMarkdown from '../../components/CustomMarkdown';
import TEST_ID from './index.testid';
import FullWidthButton from '~/components/atom/FullWidthButton';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import getEnablementFlow from '~/components/organism/Wizard/utils/getEnablementFlow';
import { Body } from '~/components/atom/Typography';

const text = {
  deactivate: 'Deinstalleren',
  goToApp: 'Ga naar de app',
  continueWizard: 'Installatie voltooien',
  cancel: 'Annuleer',
  confirm: 'Deinstalleren',
  modalHeader: 'App deinstalleren',
  modalBody:
    'Weet je zeker dat je deze app wilt verwijderen? Dit kan niet ongedaan worden. Alle lopende campagnes worden stopgezet.',
};

export type Props = AppSetupModalProps & {
  /** Disables all interaction */
  disabled: boolean;
};

const AppEnabled: React.FCC<Props> = ({
  app,
  disabled,
  hasFlows,
  onChangeAppState,
  onClose,
}) => {
  const wizardApi = useWizard();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { name, description, type, slug } = app;

  const deactivateApp = async () => {
    setLoading(true);
    await onChangeAppState();
    setShowModal(false);
    setLoading(false);
    onClose();
  };

  const setupCompleted =
    'metadata' in app.appStatus && app.appStatus.metadata != null
      ? app.appStatus.metadata.setup.completed
      : app.appStatus.enabled;

  const enablementFlow = getEnablementFlow(app, hasFlows);

  return (
    <ModalTemplate
      data={app}
      onClose={onClose}
      dataTestId={TEST_ID.CONTAINER}
      main={<CustomMarkdown value={description} />}
      aside={
        <>
          {enablementFlow && !setupCompleted && (
            <FullWidthButton
              size="medium"
              label={text.continueWizard}
              onClick={() => {
                onClose();
                return wizardApi.show({
                  header: name,
                  ...enablementFlow,
                });
              }}
              margin={[null, null, 'm', null]}
              icon="arrow-right-circle"
              dataTestId={TEST_ID.CONTINUE_WIZARD_BUTTON}
              disabled={disabled}
            />
          )}

          {setupCompleted && type === 'with_config' && (
            <FullWidthButton
              size="medium"
              label={text.goToApp}
              onClick={() => navigate(`/-/apps/${slug}`)}
              margin={[null, null, 'm', null]}
              icon="arrow-right-circle"
              dataTestId={TEST_ID.GO_TO_APP_SETTINGS_BUTTON}
            />
          )}

          <FullWidthButton
            icon="trashcan"
            label={text.deactivate}
            onClick={() => setShowModal(true)}
            disabled={disabled}
            loading={loading}
            size="medium"
            ghost
            dataTestId={TEST_ID.DEACTIVATE_BUTTON}
          />

          {showModal && (
            <Overlay
              root="deactivate-app-modal"
              onClose={() => setShowModal(false)}
            >
              <Dialog
                loading={loading}
                dataTestId={TEST_ID.CONFIRM_MODAL}
                header={text.modalHeader}
                body={<Body>{text.modalBody}</Body>}
                onConfirm={deactivateApp}
                cancelAction={{
                  label: text.cancel,
                }}
                confirmAction={{
                  icon: 'trashcan',
                  appearance: 'danger',
                  label: text.confirm,
                }}
              />
            </Overlay>
          )}
        </>
      }
    />
  );
};

export default AppEnabled;
