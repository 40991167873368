import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import SELECTOR_TEST_ID from '~/components/organism/Selector/index.testid';
import styled, { css } from 'styled-components';
import Portal from '~/components/molecule/Portal';
import Selector, {
  Props as SelectorProps,
} from '~/components/organism/Selector';

import useOnClickOutside from '~/hooks/useOnClickOutside';
import useRepositionToBeInView from '../../../../util/contactFilter/useRepositionToBeInView';
import useSize, { ElementSize } from '~/hooks/useSize';
import useGlobalKeyBinding from '~/hooks/useGlobalKeyBinding';
import { SelectedInnerField } from '../../../FilterInnerSelector';

export type Props = Omit<SelectorProps, 'pointerPosition'> & {
  setPortalledSelectorRef: React.Dispatch<
    React.SetStateAction<React.MutableRefObject<HTMLDivElement | null>>
  >;
  buttonRefs: React.MutableRefObject<{
    [key: string]: HTMLButtonElement | null;
  }>;
  buttonRefKey: string;
  onClickOutside: () => void;
  lastSelectedField: $GetElementType<SelectedInnerField> | undefined;
  debouncedActionSelectorSize: ElementSize;
  children?: React.ReactNode;
};

const PortalledSelector: React.FCC<Props> = ({
  dataTestId,
  setPortalledSelectorRef,
  debouncedActionSelectorSize,
  buttonRefs,
  buttonRefKey,
  onBreadcrumbClick,
  lastSelectedField,
  ...rest
}) => {
  const representationButtonElement = buttonRefs.current[buttonRefKey];
  const [containerRef, transform, recalculatePosition] =
    useRepositionToBeInView<HTMLDivElement>(
      {
        element: representationButtonElement,
        placement: 'top-left',
      },
      0.1,
    );

  const [debouncedSize, callback] = useSize(containerRef);

  useEffect(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedField, debouncedActionSelectorSize]);

  useEffect(() => {
    if (containerRef == null || containerRef.current == null) return;
    // if (debouncedSize.width === 0) return;
    recalculatePosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerRef,
    containerRef.current,
    debouncedActionSelectorSize,
    debouncedSize,
  ]);

  useOnClickOutside(containerRef, rest.onClickOutside);

  useGlobalKeyBinding({
    keys: ['enter'],
    callback: event => {
      event.stopPropagation();
      event.preventDefault();
      rest.onClickOutside();
    },
    enabled: containerRef.current != null,
  });

  const rootRef = useRef(`DH-Filter-Selector-${uuid()}`);

  const innerY = transform.y;

  const innerX = transform.x;

  useEffect(() => {
    setPortalledSelectorRef(containerRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, containerRef.current]);

  return (
    <Portal root={rootRef.current} data-testid={dataTestId}>
      <Container>
        <InnerRestriction
          $shouldHide={transform.x === 0 && transform.y === 0}
          ref={r => {
            containerRef.current = r;
          }}
          style={{
            transform: `translate(${innerX}px, ${innerY}px)`,
            left: 0,
            top: 0,
          }}
        >
          <Selector
            key={
              rest.children
                ? React.Children.map(rest.children, child =>
                    React.isValidElement(child) ? child.key : null,
                  )?.join('')
                : undefined
            }
            pointerPosition={{
              location: 'bottom',
              offset:
                containerRef?.current?.clientWidth != null &&
                containerRef?.current?.clientWidth > 0
                  ? containerRef?.current?.clientWidth / 2
                  : 24,
            }}
            onBreadcrumbClick={onBreadcrumbClick}
            {...rest}
          >
            {rest.children}
          </Selector>
        </InnerRestriction>
      </Container>
    </Portal>
  );
};

const Container = styled.div<{}>`
  position: absolute;
  top: 15px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const InnerRestriction = styled.div<{
  $shouldHide?: boolean;
}>(
  ({ theme, $shouldHide }) => css`
    transition: all 0.2s ease-out;
    ${$shouldHide && `display: none;`}
    & [data-testid='${SELECTOR_TEST_ID.CONTAINER}'] {
      &:after {
        z-index: -1;
      }
    }
    min-width: 400px;
    position: absolute;
    z-index: ${theme.z('top')};
    box-shadow: ${theme.getTokens().boxShadow.modal};
  `,
);

export default PortalledSelector;
