import React from 'react';
import ActionLabel from '../RepresentationLabel/components/ActionLabel';
import { Body } from '~/components/atom/Typography';
import getCardHeading from '../../utils/getCardHeading';
import getCardLabel from '../nodeTypes/components/CardTemplate/utils/getCardLabel';
import type { ClientFlowAction } from '~/graphql/types.client';

export type Props = {
  action: ClientFlowAction;
};

/**
 * Renders action label A2 and the action heading Wacht tot...
 */
const ActionHeadingWithLabel: React.FCC<Props> = ({ action }) => (
  <>
    <ActionLabel label={getCardLabel(action.label)} />
    <Body color={{ group: 'primary' }} withoutMargin size="base">
      {getCardHeading(action.actionType)}
    </Body>
  </>
);

export default ActionHeadingWithLabel;
