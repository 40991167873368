import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import {
  RenderElementProps,
  useFocused,
  useSelected,
  useSlate,
} from 'slate-react';
import styled, { css } from 'styled-components';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { SignatureElement } from '~/components/organism/PluginsEditor/types';
import serialize from './serialize';
import { Editor, Element } from 'slate';
import { throttle } from 'lodash';
import TEST_ID from './index.testid';
import selectSignature from '~/components/organism/PluginsEditor/commands/modify/signature';
import TextButton from '~/components/atom/TextButton';
import SignatureDropdownList, {
  DROPDOWN_WIDTH,
  DropdownPosition,
} from '~/components/molecule/SignatureDropdownList';

export type Props = RenderElementProps & {
  element: SignatureElement;
};

const text = {
  label: 'Selecteer handtekening',
};

const BUTTON_HEIGHT = 40;

export const Signature: React.FC<Props> = ({
  attributes,
  element,
  children,
}) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const active = selected && focused;

  const [isOpen, setIsOpen] = useState(false);

  const isEmpty = Editor.isEmpty(editor, element);
  const isOnlyChildEmpty =
    element.children &&
    element.children.length === 1 &&
    Element.isElement(element.children[0]) &&
    Editor.isEmpty(editor, element.children[0]);

  const noBorder = (isEmpty || isOnlyChildEmpty) && !active;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);

  const [position, setPosition] = useState<DropdownPosition>({
    top: 0,
    left: 0,
  });

  // Anastasia's code to position the Tooltip component with some adjustments
  const recalculate = useCallback(() => {
    if (containerRef.current && childRef.current) {
      const targetRect = containerRef.current.getBoundingClientRect();
      const childRect = childRef.current.getBoundingClientRect();
      const dropdownHeight = childRect.height;

      const scrollY = window.scrollY || document.documentElement.scrollTop;

      let top = targetRect.top + scrollY - dropdownHeight + BUTTON_HEIGHT;
      let left = targetRect.right - DROPDOWN_WIDTH;

      // Check if tooltip exceeds the left edge of the screen
      if (left < 0) {
        left = 0;
      }

      // Check if tooltip exceeds the top edge of the screen
      if (top < 0) {
        top = 0;
      }

      setPosition(prev => {
        if (prev.left !== left || prev.top !== top) {
          return { top, left };
        } else {
          return prev;
        }
      });
    }
  }, []);

  const recalculateThrottled = throttle(recalculate, 100);

  useLayoutEffect(() => {
    recalculateThrottled();
  }, [recalculateThrottled]);

  const onOptionChange = useCallback(
    option => {
      const html = option?.payload?.html;
      const attachments = option.payload?.attachments;

      selectSignature({
        editor,
        html: html ?? null,
        attachments,
      });
    },
    [editor],
  );

  return (
    <Outer
      {...attributes}
      {...element.attributes}
      data-testid={TEST_ID.CONTAINER}
    >
      <Container ref={containerRef} hasBorder={!noBorder}>
        <StyledButton
          icon="pencil"
          appearance="default"
          onClick={() => {
            setIsOpen(true);
            recalculateThrottled();
          }}
          size="small"
          contentEditable={false}
          label={text.label}
          dataTestId={TEST_ID.SELECT_SIGNATURE_BUTTON}
        />

        <SignatureDropdownList
          isOpen={isOpen}
          onChange={onOptionChange}
          onClose={() => setIsOpen(false)}
          position={position}
          ref={childRef}
        />
        {children}
      </Container>
    </Outer>
  );
};

const Outer = styled.div<{}>`
  position: relative;
`;

const StyledButton = styled(TextButton)<{}>(
  () => css`
    float: right;
  `,
);

const Container = styled.div<{ hasBorder }>(
  ({ theme, hasBorder }) => css`
    border-radius: ${theme.getTokens().border.radius.base};
    padding: ${theme.space('xxxs')};
    border: 1px solid transparent;

    &:hover {
      border: ${hasBorder
        ? `1px dashed ${theme.color('grey')}`
        : '1px solid transparent'};
    }
  `,
);

export default {
  nodeName: 'DHSIGNATURE',
  renderComponent: props => <Signature {...props} />,
  deserialize: () => ({
    type: ELEMENTS.SIGNATURE,
  }),
  serialize,
};
