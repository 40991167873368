import React from 'react';
import TextareaElement from './TextareaElement';
import { isEmpty } from '~/util/Validation/String';
import { isNil } from 'ramda';
import InputLabel from '~/components/atom/InputLabel';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Ref = HTMLTextAreaElement;

type Props = {
  name: string;
  value: string | null;
  onChange: (value: string | null, name: string) => void;

  /** Renderes a small element. */
  small?: boolean;

  /** Renderes a large element. */
  large?: boolean;

  /** Error, this replaces the label if its set. */
  error?: string | null | undefined;

  /** Disable the input */
  disabled?: boolean;

  /** If the border should be shown or not */
  borderless?: boolean;

  /** testId for test */

  /** Makes the text area uneditable */
  uneditable?: boolean;

  /** Makes the text area resizable */
  resizable?: boolean;
  label?: string;

  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
  required?: boolean;
};
const Textarea = React.forwardRef<Ref, Props>(
  (
    {
      disabled,
      error,
      large,
      small,
      value,
      dataTestId,
      borderless,
      uneditable,
      label,
      onChange,
      name,
      autoFocus = false,
      resizable = false,
      required,
      ...rest
    },
    ref,
  ) => (
    <JustificationContainer width="100%" direction="column">
      {!isNil(label) && !isEmpty(label) && (
        <InputLabel
          required={required}
          error={error}
          label={label}
          size={small ? 'small' : 'medium'}
        />
      )}
      <TextareaElement
        ref={ref}
        disabled={disabled}
        data-testid={dataTestId || ''}
        value={value || ''}
        error={error}
        data-error={error}
        rows={large ? 6 : 4}
        large={large}
        small={small}
        borderless={borderless}
        uneditable={uneditable}
        resizable={resizable}
        autoFocus={autoFocus}
        onChange={e => {
          if (e.target != null) {
            const { value: newValue } = e.currentTarget;

            if (newValue == null || newValue == '') {
              if (value !== null) {
                onChange(null, name);
              }
            } else {
              onChange(newValue, name);
            }
          }
        }}
        {...rest}
      />
    </JustificationContainer>
  ),
);

export default Textarea;
