import styled, { css } from 'styled-components';

import {
  calculateSpaceInsideComponent,
  calculateFontSize,
} from '~/components/bad/util/getSizeCalculation';

const BORDER_SIZE = 1;
type ContainerProps = {
  small?: boolean;
  large?: boolean;
  error?: string;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  ${({ theme, small, large, error }) => {
    const padding = calculateSpaceInsideComponent(theme, small, large);
    const fontSize = calculateFontSize(theme, small, large);
    const defaultPadding = `${padding - 2 * BORDER_SIZE}px`;
    let border = `${BORDER_SIZE}px solid ${theme.color('grey', 'light')}`;

    if (error) {
      border = `${BORDER_SIZE}px solid ${theme.color('danger')}`;
    }
    return css`
      font-size: ${fontSize}px;
      border: ${border};
      padding: ${defaultPadding};
      border-radius: ${theme.getTokens().border.radius.s};
      input,
      > div {
        margin: 0;
      }
      svg {
        color: ${theme.color('primary')};
        font-size: ${theme.fontSize('m')};
      }
    `;
  }};
`;
