import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import getDataPointColor from '~/util/getDataPointColor';
import ChartTooltip from '../Tooltip';
import Tick from '../components/Tick';
import FakeCursor from '../components/FakeCursor';
import Legend from './components/Legend';
import useLocale from '~/hooks/useLocale';
import { Theme } from '~/theme';
import { ChartDataPoint } from '..';
import TEST_ID from './index.testid';

export type ValueType = { [key: string]: number };

export type DataType = Array<ChartDataPoint<ValueType>>;

export type Props = {
  data: DataType;
  /** To format the value in ChartDataPoint */
  formatOptions: Intl.NumberFormatOptions;
};

const GroupedBarChart: React.FCC<Props> = ({
  data,
  dataTestId,
  formatOptions,
}) => {
  const locale = useLocale();
  const [activeGroup, setActiveGroup] = useState<number | null>(null);
  const [activeBar, setActiveBar] = useState<string | null>(null);
  const numberFormatter = new Intl.NumberFormat(locale, formatOptions);
  const theme = useTheme();

  return (
    <Container data-testid={`${dataTestId}-${TEST_ID.CONTAINER}`}>
      <Legend data={data} setActiveBar={setActiveBar} activeBar={activeBar} />
      <ResponsiveContainer width={'100%'}>
        <BarChart
          width={500}
          height={300}
          data={moveValuesToRoot(data)}
          layout="vertical"
          margin={{ top: 0, right: 16, bottom: 50, left: 120 }}
        >
          <CartesianGrid strokeDasharray="0" horizontal={false} />
          <YAxis
            dataKey="name"
            type="category"
            orientation="left"
            axisLine={false}
            tickLine={false}
            padding={{ top: 0 }}
            onMouseLeave={() => setActiveGroup(null)}
            // @ts-ignore
            onMouseEnter={({ index }) => setActiveGroup(index)}
            tick={<Tick<ValueType> data={data} />}
          />
          <XAxis
            type="number"
            orientation="top"
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            cursor={<FakeCursor setActiveGroup={setActiveGroup} />}
            content={<ChartTooltip />}
            formatter={value => {
              if (typeof value !== 'number') return value;
              return numberFormatter.format(value);
            }}
            isAnimationActive={false}
          />

          {Object.keys(data[0].value).map((key, index) => (
            <Bar key={key} dataKey={key} radius={10} barSize={8}>
              {data.map((entry, groupIndex) => (
                <Cell
                  key={`cell-${entry.id}`}
                  style={{
                    fill: getBarFill(
                      entry,
                      index,
                      groupIndex,
                      activeGroup,
                      activeBar,
                      key,
                      theme,
                    ),
                  }}
                />
              ))}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

const getBarFill = (
  _entry: ChartDataPoint<ValueType>,
  index: number,
  groupIndex: number,
  activeGroup: number | null,
  activeBar: string | null,
  key: string,
  theme: Theme,
) => {
  if (activeBar) {
    return activeBar === key
      ? getDataPointColor(index, theme)
      : theme.color('grey', 'light');
  }

  if (activeGroup !== null) {
    return activeGroup === groupIndex
      ? getDataPointColor(index, theme)
      : theme.color('grey', 'light');
  }

  return getDataPointColor(index, theme);
};

// Recharts is expecting the values to be on the root of the object,
// accessing nested value is hard so that is why we map all the value properties to the root
const moveValuesToRoot = data =>
  data.map(group => ({ ...group, ...group.value }), []);

const Container = styled.div<{}>`
  width: 100%;
  max-width: 620px;
  height: 350px;

  svg.recharts-surface {
    overflow: visible;
  }
`;

export default GroupedBarChart;
