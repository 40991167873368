import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useSlate } from 'slate-react';

import InsertHTMLModal from '~/components/bad/Modals/InsertHTMLModal';
import BaseButton from '../components/Base';
import { tooltipText } from '../text';
import insertHtml from '~/components/organism/PluginsEditor/commands/modify/html';
import { DHEditor } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serializeAllElements from '~/components/organism/PluginsEditor/utils/serialize';
import getImagesAsAttachments from '~/components/organism/PluginsEditor/utils/signatures/getImagesAsAttachments';
import convertAttachments from '~/components/organism/PluginsEditor/utils/signatures/convertAttachments';
import resetEditor from '~/components/organism/PluginsEditor/commands/modify/general';
import WarningMessage from './components/WarningMessage';

const text = {
  modalHeader: 'HTML wijzigen',
};

export type Props = {
  dataTestId?: string;
  editor: DHEditor;
  customElements: Array<ELEMENTS>;
};

const EditHtmlButton: React.FCC<Props> = ({
  dataTestId,
  editor,
  customElements,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
  const state = useSlate();

  const initialHtml = serializeAllElements({
    fragment: state.children,
    customElements,
  });

  const attachmentRef = useRef(getImagesAsAttachments(state.children));

  return (
    <Container data-testid={dataTestId} {...rest}>
      <BaseButton
        icon="debug"
        onClick={() => setShowModal(true)}
        tooltipMessage={tooltipText.debugHtml}
      />

      {showModal && (
        <InsertHTMLModal
          header={text.modalHeader}
          icon="alert-octagon"
          description={<WarningMessage />}
          onClose={() => setShowModal(false)}
          onSubmit={html => {
            const convertedHtml = convertAttachments({
              str: html,
              attachments: attachmentRef.current,
            });

            resetEditor(editor);
            insertHtml({
              editor,
              html: convertedHtml,
              customElements,
              markAsPendingImage: false,
              withExtraSpace: false,
              attachments: attachmentRef.current,
            });

            setShowModal(false);
          }}
          initialHtml={initialHtml}
          editorHeight="500px"
        />
      )}
    </Container>
  );
};

const Container = styled.div<{}>``;

export default EditHtmlButton;
