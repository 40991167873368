import React from 'react';
import styled, { css } from 'styled-components';
import { Heading1 } from '~/components/atom/Typography';

type Props = {};
const NoEventSelected: React.FCC<Props> = () => (
  <Container>
    <Heading1>Selecteer een event om te bewerken</Heading1>
  </Container>
);

const Container = styled.div<{}>`
  ${({ theme }) => css`
    background-color: ${theme.color('grey', 'light')};
    height: 800px;
    width: 800px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    * > * {
      opacity: 0.4;
    }
  `};
`;

export default NoEventSelected;
