import React from 'react';
import styled, { css } from 'styled-components';
import { Heading1 } from '~/components/atom/Typography';
import TEST_ID from './index.testid';
import SearchInput from '../SearchInput';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  buttons?: Array<
    | {
        key: string;
        node: React.ReactNode;
      }
    | undefined
  >;

  /** The title to show in the header */
  title: string;

  /** What to call when the search input is filled in */
  onFilterChange?: (text: string) => void;
  searchInputPlaceholder?: string;
};

const OverviewListHeader: React.FCC<Props> = ({
  title,
  buttons,
  dataTestId,
  searchInputPlaceholder,
  onFilterChange,
}) => (
  <Container data-testid={dataTestId || ''}>
    <TitleContainer>
      <Heading1
        color={{ group: 'primary' }}
        data-testid={TEST_ID.OVERVIEW_HEADER_TITLE}
      >
        {title}
      </Heading1>
    </TitleContainer>

    <JustificationContainer
      border={{ radius: 's' }}
      backgroundColor={{ group: 'white' }}
      width="100%"
      align="center"
      justification="space-between"
      direction="row"
      wrap="wrap"
    >
      {buttons && buttons.length > 0 && (
        <ButtonsContainer>
          {buttons.map(
            button =>
              button && (
                <ButtonContainer key={button.key} data-testid={button.key}>
                  {button.node}
                </ButtonContainer>
              ),
          )}
        </ButtonsContainer>
      )}

      {onFilterChange && (
        <StyledSearchInput
          onFilterChange={onFilterChange}
          placeholder={searchInputPlaceholder}
        />
      )}
    </JustificationContainer>
  </Container>
);

const TitleContainer = styled.div<{}>``;

const Container = styled.div<{}>`
  ${({ theme }) => css`
    margin-bottom: ${theme.space('m')};
  `}
`;

const ButtonContainer = styled.div<{}>`
  display: inline-flex;
  vertical-align: center;

  ${({ theme }) => css`
    padding: ${theme.space('xxs')};
  `};
`;

const StyledSearchInput = styled(SearchInput)<{}>`
  display: inline-flex;
  ${({ theme }) => css`
    margin: ${theme.space('xxs')};
  `};
`;

const ButtonsContainer = styled.div<{}>`
  display: inline-flex;
  flex-wrap: wrap;
  ${({ theme }) => css`
    padding-right: ${theme.space('m')};
  `};
`;

export default OverviewListHeader;
