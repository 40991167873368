import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import Checkbox, {
  Props as CheckboxProps,
} from '~/components/bad/Inputs/Checkbox';
import JustificationContainer from '~/components/atom/JustificationContainer';

export type Props = {
  selectable: boolean;
  label: React.ReactNode;
} & Omit<CheckboxProps, 'label'>;

const AnimatedCheckbox: React.FCC<Props> = ({
  selectable,
  label,
  onChange,
  ...rest
}) => {
  const checkboxStyle = useSpring({
    to: {
      opacity: selectable ? 1 : 0,
      transform: `translateX(${selectable ? '0px' : '-20px'})`,
    },
  });

  const labelStyle = useSpring({
    to: {
      transform: `translateX(${selectable ? '0px' : '-40px'})`,
    },
    config: {
      tension: 180,
      friction: 20,
    },
  });

  return (
    <Container
      justification="start"
      align="center"
      nonResponsive
      onClick={onChange}
    >
      <animated.div style={checkboxStyle}>
        <StyledCheckbox onChange={onChange} {...rest} />
      </animated.div>
      <animated.div style={labelStyle}>{label}</animated.div>
    </Container>
  );
};

const StyledCheckbox = styled(Checkbox)<{}>`
  width: auto;
`;

const Container = styled(JustificationContainer)`
  cursor: pointer;
`;

export default AnimatedCheckbox;
