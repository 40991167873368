import { keys, map, pluck } from 'ramda';
import { createPortal } from 'react-dom';
import React, { CSSProperties, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useSetRecoilState } from 'recoil';

import AsideHeader from '../../AsideHeader';
import nodeTypes from '../../nodeTypes';
import ListItem from '../components/ListItem';
import ListContainer from '../components/ListContainer';
import JustificationContainer from '~/components/atom/JustificationContainer';
import CloseButton from '~/components/atom/CloseButton';
import addBlockMenu from '~/components/page/Automation/v2/state/addBlockMenu';
import { REACT_FLOW_NODES_LAYER } from '../../../constants/reactFlowLayers';
import { ClientFlowAction } from '~/graphql/types.client';
import { FlowAction } from '~/graphql/types';
import TEST_ID from './index.testid';
import useBuilderContext from '../../../hooks/useBuilderContext';

export type Props = {
  onSelect: (actionType: ClientFlowAction['actionType']) => void;
  style?: CSSProperties;
  parentActionType?: ClientFlowAction['actionType'];
};

const AddBlockMenu: React.FCC<Props> = React.memo(({ onSelect, style }) => {
  const setMenuState = useSetRecoilState(addBlockMenu);
  const { availableActions } = useBuilderContext();
  const onClose = useCallback(() => {
    setMenuState(state => ({ ...state, openedOnEdge: false }));
  }, [setMenuState]);

  const animation = useSpring({
    from: {
      transform: `translateX(-50px)`,
      opacity: 0,
    },
    to: {
      transform: `translateX(0)`,
      opacity: 1,
    },
  });

  const availableActionTypes = pluck('type', availableActions);

  return (
    <Container
      data-testid={TEST_ID.ON_EDGE_ADD_BLOCK_MENU}
      style={{ ...animation, ...style }}
    >
      <JustificationContainer
        justification="space-between"
        align="center"
        margin={[null, null, 'base', null]}
      >
        <AsideHeader heading="Bouwstenen" icon="grid" />
        <JustificationContainer
          justification="space-between"
          align="center"
          margin={[null, 'base', null, null]}
        >
          <CloseButton onClick={onClose} />
        </JustificationContainer>
      </JustificationContainer>
      <ListContainer>
        {map(
          (actionType: ClientFlowAction['actionType']) => (
            <ListItem
              key={actionType}
              actionType={actionType}
              onClick={() => {
                onSelect(actionType);
                onClose();
              }}
            />
          ),
          keys(nodeTypes).filter(
            (key: FlowAction | 'empty') =>
              key !== 'empty' &&
              key !== FlowAction.Start &&
              availableActionTypes.includes(key),
          ),
        )}
      </ListContainer>
    </Container>
  );
});

const Container = styled(animated.aside)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('base')};
    left: ${theme.space('base')};

    background: ${theme.color('white')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};

    z-index: 30;
    user-select: none;
    pointer-events: all;
  `,
);

// We're using createPortal to transport the rendering into the react-flow nodes layer
// This is needed to prevent the menu from falling behind the SVG elements of react flow
// and to make it part of the natural scaling and panning behaviour of react-flow
const Portalled: React.FCC<Props> = props =>
  createPortal(
    <AddBlockMenu {...props} />,
    document.querySelector(REACT_FLOW_NODES_LAYER) ||
      document.createElement('div'),
  );

export default React.memo(Portalled);
