import { Ref, useEffect, useRef, useState } from 'react';

type Rect = {
  top: number;
  left: number;
  width: number;
  height: number;
};

type ReturnType = {
  ref: Ref<HTMLDivElement>;
  bounds: Rect;
};

/**
 * @returns Measures dimensions of a DOM element and observes size changes.
 *
 * Use this hook if you want to get the size of an element.
 */
const useMeasure = (): ReturnType => {
  const ref = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<Rect>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const [observer] = useState(
    () => new ResizeObserver(([entry]) => setBounds(entry.contentRect)),
  );

  useEffect(() => {
    if (ref.current) observer.observe(ref.current);

    return () => {
      if (observer) observer.disconnect();
    };
  }, [observer, ref]);

  return {
    ref,
    bounds,
  };
};

export default useMeasure;
