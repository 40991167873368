import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import CollapsibleChevronBlock from '~/components/molecule/CollapsibleChevronBlock';
import Icon, { IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';
import { SystemColorPalette } from '~/theme';

export type Props = {
  dataTestId?: string;

  header: {
    /** Label for the accordion header */
    label: string;

    /** Icon for the accordion header */
    icon?: IconType;
  };

  /** Changes the background-color of the container */
  variant?: keyof SystemColorPalette;

  /** Makes the component controlled. Callback when the accordion is toggled */
  onChange?: () => void;

  /** Makes the component controlled. Open state of the accordion */
  isOpen?: boolean;
};

const OutputAccordion: React.FCC<Props> = ({
  children,
  header,
  variant = 'primary',
  onChange,
  isOpen,
  ...rest
}) => {
  const theme = useTheme();
  const [internalOpen, setInternalOpen] = useState(false);

  const onToggle = onChange ? onChange : () => setInternalOpen(prev => !prev);
  const open = isOpen ?? internalOpen;

  return (
    <CollapsibleChevronBlock
      {...rest}
      variant={variant}
      isOpen={open}
      onToggle={onToggle}
      header={
        <JustificationContainer align="center" gap="base">
          {header.icon && (
            <Icon
              name={header.icon}
              color={theme.color(variant)}
              strokeWidth={2.5}
            />
          )}
          <Heading4 variant={Variant.primary} withoutMargin>
            {header.label}
          </Heading4>
        </JustificationContainer>
      }
    >
      <ChildrenContainer>{children}</ChildrenContainer>
    </CollapsibleChevronBlock>
  );
};

const ChildrenContainer = styled.div<{}>`
  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    padding: ${theme.space('base')};
    border-radius: ${theme.getTokens().border.radius.base};
  `}
`;

export default OutputAccordion;
