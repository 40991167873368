import React, { useContext } from 'react';
import ModalContext from '~/contexts/ModalContext';
import ChangeOrUpdateSubscription from '~/components/page/Settings/Subscription/components/ChangeOrUpdateSubscription';
import useSessionHydration from '../useSessionHydration';

type Args = {
  onComplete?: () => void;
};
const useOnUpgradeSubscription = ({ onComplete }: Args) => {
  const [, refetchSessionHydration] = useSessionHydration();
  const { showModal, hideModal } = useContext(ModalContext);

  const onUpgrade = () =>
    showModal(
      <ChangeOrUpdateSubscription
        onClose={hideModal}
        onComplete={() => {
          hideModal();
          if (onComplete) onComplete();

          return refetchSessionHydration();
        }}
      />,
    );

  return onUpgrade;
};

export default useOnUpgradeSubscription;
