import React from 'react';

import DatePicker from '~/components/organism/DatePicker';
import type { Props as InputProps } from '~/components/molecule/Input';
import Field from './Field';
import FloatingLabel from '~/components/atom/FloatingLabel';

/**
 * In the ecosystem of the forms we handle date as an ISO string.
 * This is also what is sent back to the server.
 */
type DateFieldProps = {
  name: string;
  value: string;
  onChange: (newDate: string) => void;
  error?: string;
  small?: boolean;
  large?: boolean;
  label?: string;
  inputComponentProps?: InputProps;
};
const DateField: React.FC<DateFieldProps> = ({
  error,
  small,
  large,
  label,
  ...props
}) => (
  <div>
    {(error || label) && (
      <FloatingLabel
        actAsPlaceholder={false}
        error={error}
        small={small}
        large={large}
      >
        {error || label || ''}
      </FloatingLabel>
    )}
    <Field {...props} component={DateFieldComponent} />
  </div>
);

const DateFieldComponent = ({
  input: { name, value, onChange },
  ...rest
}: any) => (
  <DatePicker
    name={name}
    size={10}
    value={value}
    onChange={onChange}
    {...rest}
  />
);

export default DateField;
