import gql from 'graphql-tag';

export default gql`
  fragment Permission on Permission {
    ... on Permission_AccessGranted {
      id
    }
    ... on Permission_AccessDenied_Plan {
      id
      reason {
        id
      }
    }
  }
`;
