/**
 * Replaces newline characters with `&nbsp;\n` to ensure proper rendering of empty lines
 * in MarkdownEditor and Markdown components.
 *
 * See for similar issue: https://stackoverflow.com/questions/69026492/adding-multiple-line-break-in-react-markdown
 *
 * @param str - The input string containing newline characters.
 * @returns The modified string with `&nbsp;` added before each newline.
 */
const handleNewLinesInMarkdown = (str: string): string =>
  str.replace(/\n/gi, '&nbsp;\n');

export default handleNewLinesInMarkdown;
