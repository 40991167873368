import { isNil } from 'ramda';
import { Text, type Descendant } from 'slate';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';

/**
 *
 * Converts editor value into a markdown string
 *
 * @param {string} value - Editor value
 */
const serializeMarkdown = (value: EditorValue): string => {
  const serialize = (node: Descendant) => {
    if (Text.isText(node)) {
      let text = node.text;

      if (isNil(text)) return '';
      if (node.bold) {
        text = `**${text}**`;
      }
      if (node.italic) {
        text = `_${text}_`;
      }
      return text;
    }

    const children = node.children.map(n => serialize(n)).join('');

    switch (node.type) {
      case ELEMENTS.DIV:
        return `${children}\n`;
      case ELEMENTS.H1:
        return `# ${children}\n`;
      case ELEMENTS.H2:
        return `## ${children}\n`;
      case ELEMENTS.H3:
        return `### ${children}\n`;
      case ELEMENTS.H4:
        return `#### ${children}\n`;
      case ELEMENTS.H5:
        return `##### ${children}\n`;
      case ELEMENTS.H6:
        return `###### ${children}\n`;
      case ELEMENTS.BLOCKQUOTE:
        return `> ${children}\n`;

      // inline element does not need \n\n
      case ELEMENTS.LINK:
        return `[${children}](${node.url})`;

      default:
        return children;
    }
  };

  const markdownStr = value.map(node => serialize(node)).join('');

  return markdownStr;
};

export default serializeMarkdown;
