import React, { Ref, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import HidableComponent from '~/components/atom/HidableComponent';
import useRepositionToBeInView from '~/components/bad/util/useRepositionToBeInView';

export type Props = {
  onClickOutside?: (e?: MouseEvent) => void;

  /** Goes to the child container */
  className?: string;
};

const PositionedComponent: React.FCC<Props> = ({
  dataTestId,
  onClickOutside,
  children,
  className,
  ...rest
}) => {
  const [dropdownComponentRef, dropdownListTransform, recalculatePosition] =
    useRepositionToBeInView();

  useLayoutEffect(() => {
    recalculatePosition();
  }, [recalculatePosition]);

  const storeAndForwardRef = (ref: null | HTMLElement, parentRef: Ref<any>) => {
    dropdownComponentRef.current = ref;

    // @ts-ignore
    parentRef.current = ref;
  };

  return (
    <HidableComponent
      {...rest}
      data-testid={dataTestId}
      onClickOutside={onClickOutside}
    >
      {ref => (
        <ChildContainer
          className={className}
          $translateX={dropdownListTransform.x}
          $translateY={dropdownListTransform.y}
          ref={r => storeAndForwardRef(r, ref)}
        >
          {children}
        </ChildContainer>
      )}
    </HidableComponent>
  );
};

type ChildContainerProps = {
  $translateX: number;
  $translateY: number;
  className?: string;
};
const ChildContainer = styled.div<ChildContainerProps>`
  position: absolute;

  ${({ $translateX: translateX, $translateY: translateY, theme }) => css`
    transform: translateX(${translateX}px) translateY(${translateY}px);
    z-index: ${theme.z('dropdown')};
  `};
`;

export default PositionedComponent;
