import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { uniq } from 'ramda';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Label, Variant } from '~/components/atom/Typography';

export type Props = {
  title: string;
  description: React.ReactNode;
  itemIcons: Array<string>;
  selectedAmount?: number;
  price: string | null;
  onClick: () => void;
};

const AppsGroupTile: React.FCC<Props> = ({
  dataTestId,
  itemIcons,
  title,
  description,
  selectedAmount,
  price,
  ...rest
}) => {
  const uniqIcons = uniq(itemIcons);

  // We need maximum of 4 icons to display in a grid
  const iconsToDisplay = uniqIcons.slice(0, 4);
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Container
      width="100%"
      padding={['base']}
      gap="m"
      data-testid={dataTestId}
      {...rest}
    >
      {iconsToDisplay.length === 1 ? (
        <IconContainer>
          <img src={iconsToDisplay[0]} />
        </IconContainer>
      ) : (
        <LogoGrid ref={ref}>
          {iconsToDisplay.map((logo, index) => (
            <IconsContainer key={`${index} + ${logo}`}>
              <img src={logo} />
            </IconsContainer>
          ))}
        </LogoGrid>
      )}

      <JustificationContainer direction="column" gap="xxs" width="100%">
        <div>
          <Label size="base" withoutMargin variant={Variant.primary}>
            {title}
          </Label>
          {price && (
            <Label fontWeight="regular" withoutMargin>
              {price}
            </Label>
          )}
          {selectedAmount !== undefined && selectedAmount > 0 && (
            <SelectedAmountLabel fontWeight="regular" variant={Variant.primary}>
              {selectedAmount === 1
                ? 'Geactiveerd'
                : `${selectedAmount} onderdelen geactiveerd`}
            </SelectedAmountLabel>
          )}
        </div>
        <Body size="s">{description}</Body>
      </JustificationContainer>
    </Container>
  );
};

const SelectedAmountLabel = styled(Label)(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.m};
    background: ${theme.color('accent')};
    padding: 4px ${theme.space('xxs')};
    width: max-content;
    color: ${theme.color('white')};
  `,
);

const IconContainerStyles = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${theme.color('grey', 'light')};
    text-align: center;
  `,
);

const ICON_SIZE = '30px';
const IconsContainer = styled(IconContainerStyles)(
  ({ theme }) => css`
    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
    padding: ${theme.space('xxxs')};

    img {
      max-width: 100%;
      max-height: 100%;
    }
  `,
);

const IconContainer = styled(IconContainerStyles)(
  ({ theme }) => css`
    padding: ${theme.space('xs')};
    width: calc(2 * ${ICON_SIZE});
    height: calc(2 * ${ICON_SIZE});
    border-radius: ${theme.getTokens().border.radius.s};

    img {
      width: calc(2 * (${ICON_SIZE} - ${theme.remToPx(theme.space('xs'))}));
      height: calc(2 * (${ICON_SIZE} - ${theme.remToPx(theme.space('xs'))}));
    }
  `,
);

const LogoGrid = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    display: grid;
    grid-template-columns: repeat(2, ${ICON_SIZE});
    grid-template-rows: repeat(2, ${ICON_SIZE});
  `,
);

const Container = styled(JustificationContainer)<{}>(
  ({ theme }) => css`
    border: 1px solid transparent;
    pointer-events: auto;
    cursor: pointer;
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: transform 0.3s ease;
    height: 100%;
    box-shadow: ${theme.boxShadow('s')};

    &:hover {
      transform: translateY(-3px);
      border: 1px solid ${theme.color('primary', 'light')};
    }
  `,
);

export default AppsGroupTile;
