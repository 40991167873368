import React from 'react';

import Button, { Props as ButtonProps } from '~/components/atom/Button';
import useTooltipLayer from '~/hooks/useTooltipLayer';

type Props = ButtonProps & {
  tooltip: {
    message: string;
  };
};

/**
 * Adds a tooltip to the Button component
 */
const ButtonWithTooltip: React.FCC<Props> = ({ tooltip, ...rest }) => {
  const tooltipProps = useTooltipLayer({ tooltipMessage: tooltip?.message });

  return (
    <div {...tooltipProps}>
      <Button {...rest} />
    </div>
  );
};

export default ButtonWithTooltip;
