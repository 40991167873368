import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import type { SelectedOption } from '~/components/molecule/Dropdown';
import {
  ConnectorOperator,
  GetContactsQuery,
  GetContactsQueryVariables,
} from '~/graphql/types';

import GetContacts from '~/graphql/query/GetContacts';
import asNodeInput from '~/components/organism/Filters/asNodeInput';
import useDebounce from '~/hooks/useDebounce';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import TaskModalContext from '~/components/page/Tasks/context/TaskModalContext';
import SearchField from '~/components/bad/Inputs/SearchField';

type Props = {
  error?: string;
};

const ContactSearch = ({ error }: Props) => {
  const { updateTaskDetails } = useContext(TaskModalContext);
  const { id: accountId } = useCurrentAccount();

  const [variablesState, setVariables] = useState<GetContactsQueryVariables>({
    nodes: asNodeInput({ filters: [], operator: ConnectorOperator.And }),
    accountId,
    query: null,
  });
  const variables = useDebounce(variablesState, 300);

  const { data, error: queryError } = useQuery<
    GetContactsQuery,
    GetContactsQueryVariables
  >(GetContacts, {
    variables,
    partialRefetch: true,
    // initially it is null so don't run the query the first time
    skip: variables.query === null,
  });

  const contactResults = data == null ? [] : data.getContacts.items;
  const showErrorMessage = error || queryError;

  return (
    <Container data-testid="task-modal-contact-search">
      <p>Contact toevoegen</p>
      <SearchField
        hasError={Boolean(showErrorMessage)}
        onSearch={query => {
          setVariables(prevVariables => ({
            ...prevVariables,
            query,
          }));
        }}
        options={contactResults.map(contact => ({
          label: contact.name,
          payload: contact,
          key: contact.id,
        }))}
        onSelected={(selectedOption: SelectedOption) => {
          const { option } = selectedOption;
          if (option && option.payload && option.payload.id) {
            const { id, name, email } = option.payload;

            updateTaskDetails({
              contactId: id,
              Contact: {
                id,
                name,
                email,
              },
            });
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  position: relative;
`;

export default ContactSearch;
