import React from 'react';
import { useContext } from 'react';
import ModalContext from '~/contexts/ModalContext';
import UpdateBillingDetails from '../../components/UpdateBillingDetails';

type Args = {
  onComplete?: () => void;
};
const useOnUpdateBillingDetails = ({ onComplete }: Args) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const onUpdateBillingDetails = () =>
    showModal(
      <UpdateBillingDetails
        onClose={hideModal}
        onComplete={() => {
          hideModal();
          if (onComplete) onComplete();
        }}
      />,
    );

  return onUpdateBillingDetails;
};

export default useOnUpdateBillingDetails;
