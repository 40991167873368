import { compose, prop, sortBy, toLower } from 'ramda';
import { GroupLookup } from '~/components/organism/ToggleAccordion';
import { AppConfigurationFragment } from '~/graphql/types';

/**
 * returns groupLookup for SelectableAccordion
 * @param {Array<AppsConfigurationFragment>} apps
 */

const lowerCaseName = compose(toLower, prop('name'));
const sortByName = sortBy(lowerCaseName);

const getAppsGroupLookup = (
  apps: Array<AppConfigurationFragment>,
): GroupLookup<{ appConfiguration: string }> => {
  if (apps.length === 0) return {};

  const sortedApps = sortByName(apps) as Array<AppConfigurationFragment>;

  const groupLookup: GroupLookup<{ appConfiguration: string }> = apps.reduce(
    (acc, app) => {
      if (app.items && app.items.length === 0) return acc;

      const { name, visible, items, type, appConfiguration, pinned } = app;

      acc[type] = {
        name,
        pinned,
        mainChecked: visible,
        weight: sortedApps.findIndex(a => a.type === type),
        items: items
          ? items.map(({ visible, ...rest }) => ({
              ...rest,
              checked: visible,
            }))
          : null,
        appConfiguration,
      };

      return acc;
    },
    {},
  );

  return groupLookup;
};

export default getAppsGroupLookup;
