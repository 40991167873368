import React from 'react';
import Dropdown, { Option } from '~/components/molecule/Dropdown';
import TEST_ID from './index.testid';
import { Heading5, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';

const text = {
  run: 'Maximaal aantal keren dat deze flow voor hetzelfde contact mag starten ',
  unlimited: 'Onbeperkt',
};

type Props = {
  value: number | null;
  onChange: (value: number | null) => void;
};

const RunXTimes: React.FCC<Props> = ({ value = null, onChange }) => {
  const dropdownOptions = getDropdownOptions(10);

  return (
    <JustificationContainer
      dataTestId={TEST_ID.CONTAINER}
      justification="start"
      align="start"
      direction="column"
      margin={[null, null, 'base', null]}
    >
      <Heading5 variant={Variant.primary}>{text.run}</Heading5>

      <Dropdown
        dataTestId={TEST_ID.DROPDOWN}
        options={dropdownOptions}
        selectedOptionIdx={dropdownOptions.findIndex(
          option => option.payload === value,
        )}
        onChange={({ option }) => onChange(option.payload)}
      />
    </JustificationContainer>
  );
};

const getDropdownOptions = (number: number): Array<Option> => {
  const arr = [...new Array(number)];
  return [
    {
      key: 'Unlimited',
      payload: null,
      label: text.unlimited,
    },
    ...arr.map((_, index) => ({
      key: index + 1,
      payload: index + 1,
      label: `${index + 1} keer`,
    })),
  ];
};

export default RunXTimes;
