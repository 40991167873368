import { isNil } from 'lodash';
import { isEmpty, keys } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '~/components/atom/Button';
import { BreadcrumbType } from '~/components/molecule/ContentContainer/components/Breadcrumbs';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import EditableText from '~/components/organism/EditableText';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Link from '~/components/molecule/Link';
import Dialog from '~/components/organism/ModalsV2/Dialog';
import Overlay from '~/components/organism/ModalsV2/Overlay';
import NewSaveBar from '~/components/organism/NewSaveBar';
import { Body, Heading4, Variant } from '~/components/atom/Typography';

import findDifference from '~/util/findDifference';
import FieldsContainer, { ZapierFields } from './components/FieldsContainer';
import TEST_ID from './index.testid';
import getDuplicateKeys from './utils/getDuplicateKeys';
import { useLocation } from '@gatsbyjs/reach-router';
import { reporter } from '~/hooks/useErrorReporter';
import getValidationErrors from '~/util/getValidationErrors';
import type { SaveBarMessage } from '~/components/organism/NewSaveBar/components/MessagesContainer';

export type Props = {
  /** The breadcrumbs to display */
  breadcrumbs: Array<BreadcrumbType>;

  /** Event or trigger name */
  headerName: string;

  /** Body description or other nested elements */
  description: React.ReactNode;

  /** Initial fields that come from query */
  initialFields: ZapierFields;

  /** Link to go back from this page */
  goBackLink: string;

  /** What to do on Save Bar save button */
  onSave: (name: string, fields: ZapierFields) => Promise<void>;

  /** We're saving, disable buttons and whatnot */
  loading?: boolean;

  /** What to do on delete button */
  onDelete?: () => void;

  children: React.ReactNode;
};

export const text = {
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in
      <Link to="#"> onze kennisbank </Link>. Kom je er niet uit? <br />
      <br />
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
  deleteHeading: 'Koppeling verwijderen',
  duplicateKeyErrorMessage:
    'Dezelfde key mag niet meerdere keren gebruikt worden',
  modal: {
    delete: {
      title: (name: string) => `Verwijder uitgaande koppeling “${name}”?`,
      body: 'Weet je zeker dat je deze koppeling wilt verwijderen? Dit kan niet ongedaan worden.',
      confirm: 'Verwijderen',
      cancel: 'Afbreken',
    },
    cancel: {
      title: (_name: string) => `Weet je zeker dat je wilt afbreken?`,
      body: 'Weet je zeker dat je al je wijzigingen wilt annuleren? Dit kan niet ongedaan worden.',
      confirm: 'Bevestig',
      cancel: 'Afbreken',
    },
  },
  emptyInputError: 'Het veld kan niet leeg zijn',
  keyInputError: 'Alleen kleine letters a-z en cijfers 0-9 toegestaan',
};

export const KEY_REGEX = /^[a-z0-9_]+$/;
export const isValidKey = (value: string) => {
  if (value.length === 0) return text.emptyInputError;
  return KEY_REGEX.test(value) ? true : text.keyInputError;
};

export type ErrorObj = {
  key: Array<string> | null;
  label: Array<string> | null;
};

const Details: React.FCC<Props> = ({
  dataTestId,
  breadcrumbs,
  headerName,
  description,
  initialFields,
  children,
  goBackLink,
  onSave,
  onDelete,
  loading = false,
}) => {
  const location = useLocation();
  const isNewConnection =
    location.pathname === '/-/apps/zapier/triggers/new' ||
    location.pathname === '/-/apps/zapier/events/new';

  const [updatedFields, setUpdatedFields] =
    useState<ZapierFields>(initialFields);
  const [changes, setChanges] = useState<number>(0);
  const [showModal, setShowModal] = useState<'delete' | 'cancel' | null>(null);
  const [duplicateKeys, setDuplicateKeys] = useState<Array<string>>(
    getDuplicateKeys(updatedFields),
  );

  useEffect(() => {
    setUpdatedFields(initialFields);
  }, [initialFields]);

  const validations = {
    label: [(value: any) => (!isEmpty(value) ? true : text.emptyInputError)],
    key: [
      (value: any) => isValidKey(value),
      (value: any) =>
        duplicateKeys.includes(value) ? text.duplicateKeyErrorMessage : true,
    ],
  };

  const getChanges = (fields: ZapierFields): number => {
    let differences = 0;

    /**
     * Any fields removed / mutated
     */
    for (const field of initialFields) {
      const corresponding = fields.find(({ id }) => field.id === id);
      if (!corresponding) {
        differences++;
        continue;
      }

      const { dataIsNotEqual } = findDifference(field, corresponding);
      if (dataIsNotEqual) differences++;
    }

    /**
     * Any fields added
     */
    for (const field of fields) {
      const corresponding = initialFields.find(({ id }) => field.id === id);
      if (!corresponding) differences++;
    }

    return differences;
  };

  const errors = updatedFields.map(field =>
    keys(field).reduce((acc, key) => {
      // run validation fn for each field that we have a validation defined.
      if (!validations[key]) return acc;
      const fieldErrors = getValidationErrors(validations[key], field[key]);
      acc[key] = fieldErrors;
      return acc;
    }, {} as ErrorObj),
  );

  const onFieldsChange = (nextFields: ZapierFields) => {
    setUpdatedFields(nextFields);
    setChanges(getChanges(nextFields));
    setDuplicateKeys(getDuplicateKeys(nextFields));
  };

  const _onSave = async () => {
    try {
      await onSave(headerName, updatedFields);
      setChanges(0);
    } catch (error) {
      reporter.captureException(error);
    }
  };

  const modalActions = {
    delete: () => {
      onDelete && onDelete();
      setShowModal(null);
    },
    cancel: () => {
      onFieldsChange(initialFields);
      setChanges(0);
      setShowModal(null);
    },
  };

  const messages: Array<SaveBarMessage> = errors
    .map(({ key, label }) => (key ? key[0] : null) || (label ? label[0] : null))
    .filter(err => !isNil(err))
    .map(error => ({ key: error, message: error, type: 'danger' }));

  const hasErrors = messages.length !== 0;
  const saveButtonEnabled = !hasErrors && (isNewConnection || changes !== 0);

  return (
    <ContentContainerDefault breadcrumbs={breadcrumbs} data-testid={dataTestId}>
      <EditableText
        text={headerName}
        onSave={nextHeaderName => onSave(nextHeaderName, updatedFields)}
        headerSize="l"
        as="h1"
        margin={[null, null, 'm']}
        dataTestId={TEST_ID.EDITABLE_TEXT}
      />
      <NewSaveBar
        onSave={_onSave}
        onCancel={() => setShowModal('cancel')}
        changes={changes}
        messages={messages}
        withGoBackLink
        goBackLink={goBackLink}
        loading={loading}
        dataTestId={TEST_ID.SAVE_BAR}
        disabled={!saveButtonEnabled}
      />
      <JustificationContainer justification="center" align="start">
        <FieldsPartContainer>
          <Body margin={[null, null, 'l', null]}>{description}</Body>
          <FieldsContainer
            fields={updatedFields}
            errors={errors}
            duplicateKeys={duplicateKeys}
            onFieldsChange={onFieldsChange}
          />
          {children}
          {onDelete && (
            <DeletePartContainer>
              <Heading4 variant={Variant.primary}>
                {text.deleteHeading}
              </Heading4>
              <Button
                label="Verwijder koppeling"
                appearance="danger"
                ghost
                size="medium"
                onClick={() => setShowModal('delete')}
                dataTestId={TEST_ID.DELETE}
              />
            </DeletePartContainer>
          )}
        </FieldsPartContainer>

        <TipBanner
          id="zapier-details-tip"
          headerText="Hulp nodig?"
          margin={['l', null, null, 'l']}
        >
          {text.tip}
        </TipBanner>
      </JustificationContainer>
      {showModal !== null && (
        <Overlay onClose={() => setShowModal(null)}>
          <Dialog
            dataTestId={TEST_ID.MODAL}
            header={text.modal[showModal].title(headerName)}
            body={<Body>{text.modal[showModal].body}</Body>}
            onConfirm={modalActions[showModal]}
            cancelAction={{
              label: text.modal[showModal].cancel,
            }}
            confirmAction={{
              icon: 'trashcan',
              appearance: 'danger',
              label: text.modal[showModal].confirm,
            }}
          />
        </Overlay>
      )}
    </ContentContainerDefault>
  );
};

const FieldsPartContainer = styled.div<{}>(
  ({ theme }) => css`
    background: ${theme.color('white')};
    padding: ${theme.space('xl')};
    margin-top: ${theme.space('l')};
    width: 100%;
  `,
);

const DeletePartContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('m')};
  `,
);

export default Details;
