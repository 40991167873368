import React from 'react';
import PluginsEditor from '~/components/organism/PluginsEditor/';
import insertVariable from '~/components/organism/PluginsEditor//commands/modify/variable';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import withHtml from '~/components/organism/PluginsEditor//plugins/withHtml';
import TEST_ID from './index.testid';
import { Container as InputContainer } from '~/components/atom/InputContainer';
import NoSpaceTextButton from '~/components/atom/NoSpaceTextButton';
import { useTheme } from 'styled-components';
import type { EditorValue } from '../PluginsEditor/types';

export type Props = {
  value: EditorValue;
  onChange: (val: EditorValue) => void;

  /**
   * Allows to rerender the editor after saving or cancelling changes so that the
   * editor state is reset like the history
   */
  $key: number;
};

const TemplateStringInput: React.FCC<Props> = ({
  value,
  onChange,
  $key,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <InputContainer width="100%">
      <PluginsEditor
        key={$key}
        value={value}
        onChange={onChange}
        customElements={[ELEMENTS.VARIABLE]}
        singleLine
        plugins={[
          {
            name: 'withHtml',
            fn: e =>
              withHtml({
                editor: e,
                customElements: [ELEMENTS.VARIABLE],
                simpleEditor: true,
              }),
          },
        ]}
        editableProps={{
          style: { padding: '0px', lineHeight: theme.lineHeight('l') },
        }}
        {...rest}
      >
        {/* @ts-ignore cant get children typed correctly */}
        {editor => (
          <NoSpaceTextButton
            appearance="primary"
            icon="user"
            onClick={() => insertVariable(editor)}
            dataTestId={TEST_ID.INSERT_VARIABLE_BUTTON}
          />
        )}
      </PluginsEditor>
    </InputContainer>
  );
};

export default TemplateStringInput;
