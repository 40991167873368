import React from 'react';
import styled from 'styled-components';

import type { TableCell } from '~/components/bad/DataTables/types.flow';

import Checkbox from '~/components/bad/Inputs/Checkbox';
import { cellProps } from '../util/propGetters';

type Props = {
  cell: TableCell<any>;
  onChange: () => void;
  value: boolean;
  disabled?: boolean;
};
const CheckboxColumnCell: React.FCC<Props> = ({
  cell,
  onChange,
  value,
  disabled,
}) => {
  const tdCellProps = cell.getCellProps(cellProps);

  return (
    <StyledTd {...tdCellProps}>
      <CellContainer>
        <Checkbox
          value={value}
          name="selectRowCheckbox"
          disabled={disabled}
          large
          onChange={onChange}
        />
      </CellContainer>
    </StyledTd>
  );
};

const CellContainer = styled.div<{}>``;

const StyledTd = styled.div<{}>`
  cursor: default;
`;

export default CheckboxColumnCell;
