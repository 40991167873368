import React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';
import { SpanElement } from '~/components/organism/PluginsEditor/types';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import serialize from './serialize';

export type Props = RenderElementProps & {
  element: SpanElement;
};

const HeadingTwo: React.FCC<Props> = ({ attributes, element, children }) => (
  <Container {...attributes} {...element.attributes}>
    {children}
  </Container>
);

const Container = styled.h2<{}>`
  margin: 0;
`;

export default {
  renderComponent: props => <HeadingTwo {...props} />,
  nodeName: 'H2',
  deserialize: () => ({
    type: ELEMENTS.H1,
  }),
  serialize,
};
