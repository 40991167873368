/**
 *
 * Helper functions for date manipulation/comparison that add locale
 */
import {
  format as dateFNSFormat,
  parse as dateFNSParse,
  setHours as dateFNSSetHours,
  setMinutes as dateFNSSetMinutes,
  addDays as dateFNSAddDays,
  subDays as dateFNSSubDays,
  parseISO,
  isValid,
  isBefore as dateFNSIsBefore,
  isAfter as dateFNSIsAfter,
  isEqual as dateFNSIsEqual,
  addMilliseconds as dateFNSAddMilliseconds,
  isToday as dateFNSIsToday,
  subMinutes as dateFNSSubMinutes,
  isYesterday,
  startOfDay,
  endOfDay,
  type Locale,
} from 'date-fns';
import { nl, enUS } from 'date-fns/locale';

import { asValidHourOrNull, asValidMinuteOrNull } from './time';
import { PART_OF_DAY } from './constants';

export const DATE_FNS_FORMAT = 'd-M-yyyy';

// supported locales
type SupportedLocales = 'nl' | 'en';
const locales: Record<SupportedLocales, Locale> = {
  en: enUS,
  nl,
};

const text = {
  today: 'Vandaag',
  yesterday: 'Gisteren',
};

const currentLocaleStr = (global.window?.__localeId__ ||
  'nl') as SupportedLocales;
const currentLocale = locales[currentLocaleStr]; //default nl!

const defaultOptions = {
  locale: currentLocale,
};

export const sortByDate = (dateA: string, dateB: string) =>
  new Date(dateB).getTime() - new Date(dateA).getTime();

export const isToday = dateFNSIsToday;

export const format = (date: Date | string, formatStr: string): string => {
  if (typeof date === 'string') {
    return dateFNSFormat(parseISO(date), formatStr, defaultOptions);
  }
  return dateFNSFormat(date, formatStr, defaultOptions);
};

export const parse = (dateString: string, formatStr: string): Date =>
  dateFNSParse(dateString, formatStr, new Date(), defaultOptions);

export const addMilliseconds = (date: Date, amount: number): Date =>
  dateFNSAddMilliseconds(date, amount);

export const addDays = (date: Date | string, amount: number): Date => {
  if (typeof date === 'string') {
    return dateFNSAddDays(parseISO(date), amount);
  }
  return dateFNSAddDays(date, amount);
};

export const subDays = (date: Date | string, amount: number): Date => {
  if (typeof date === 'string') {
    return dateFNSSubDays(parseISO(date), amount);
  }
  return dateFNSSubDays(date, amount);
};

export const startOfDate = (date: Date | number): string | null =>
  convertDateToServerDateString(startOfDay(date));

export const endOfDate = (date: Date | number): string | null =>
  convertDateToServerDateString(endOfDay(date));

export const isBefore = (date: Date, dateToCompare: Date): boolean =>
  dateFNSIsBefore(date, dateToCompare);

export const isBeforeOrEqual = (date: Date, dateToCompare: Date): boolean =>
  dateFNSIsEqual(date, dateToCompare) || isBefore(date, dateToCompare);

export const isAfter = (date: Date, dateToCompare: Date): boolean =>
  dateFNSIsAfter(date, dateToCompare);

export const isAfterOrEqual = (date: Date, dateToCompare: Date): boolean =>
  dateFNSIsEqual(date, dateToCompare) || isAfter(date, dateToCompare);

export const subMinutes = (date: Date, minutesToSubtract: number): Date =>
  dateFNSSubMinutes(date, minutesToSubtract);

export const isDateObjectAndValid = (date?: Date | null | undefined): boolean =>
  date instanceof Date && isValid(date);
/**
 * Formats the given date to a string representation including time.
 * If the date is invalid an empty string will be given.
 *
 * @param {?Date} date the date to format
 */
type FormatOptions = {
  showTodayAsLabel?: boolean;
  showYesterdayAsLabel?: boolean;
};
export const safeFormattedDateTime = (
  date?: Date | null | undefined,
  options?: FormatOptions,
): string => {
  if (date == null || !isDateObjectAndValid(date)) {
    return '';
  }

  if (options && options.showTodayAsLabel === true && isToday(date)) {
    return `${text.today} ${format(date, 'H:mm')}`;
  }

  if (options && options.showYesterdayAsLabel === true && isYesterday(date)) {
    return `${text.yesterday} ${format(date, 'H:mm')}`;
  }

  return format(date, 'd-M-yyyy H:mm');
};
/**
 * Formats the given date to 1 jan 2020 00:00
 *
 * @param {?Date} date the date to format
 */

export const formattedDateTimeWithMonth = (
  date?: Date | null | undefined,
  options?: FormatOptions,
): string => {
  if (date == null || !isDateObjectAndValid(date)) {
    return '';
  }

  if (options && options.showTodayAsLabel === true && isToday(date)) {
    return `${text.today} ${format(date, 'H:mm')}`;
  }

  if (options && options.showYesterdayAsLabel === true && isYesterday(date)) {
    return `${text.yesterday} ${format(date, 'H:mm')}`;
  }

  return format(date, 'd MMM yyyy H:mm');
};

/**
 * Formats the given date to 1 januari 2020
 *
 * @param {?Date} date the date to format
 */

export const formattedDateWithFullMonth = (
  date?: Date | null | undefined,
  options?: FormatOptions,
): string => {
  if (date == null || !isDateObjectAndValid(date)) {
    return '';
  }

  if (options && options.showTodayAsLabel === true && isToday(date)) {
    return text.today;
  }

  if (options && options.showYesterdayAsLabel === true && isYesterday(date)) {
    return text.yesterday;
  }

  return format(date, 'd MMMM yyyy');
};

/**
 * Formats the given date to 1 januari 2020
 *
 * @param {?Date} date the date to format
 */

export const formattedDateWithMonth = (
  date?: Date | null | undefined,
  options?: FormatOptions,
): string => {
  if (date == null || !isDateObjectAndValid(date)) {
    return '';
  }

  if (options && options.showTodayAsLabel === true && isToday(date)) {
    return `${text.today} ${format(date, 'H:mm')}`;
  }

  if (options && options.showYesterdayAsLabel === true && isYesterday(date)) {
    return `${text.yesterday} ${format(date, 'H:mm')}`;
  }

  return format(date, 'd MMM yyyy');
};

/**
 * Same as safeFormattedDateTime but then without the time element
 *
 * @param {?Date} date the date to format
 */
export const safeFormattedDate = (date?: Date | null | undefined): string =>
  date && isDateObjectAndValid(date) ? format(date, 'd-M-yyyy') : '';

/**
 * Same as safeFormattedDateTime but then without the date element
 *
 * @param {?Date} date the date to format
 */
export const safeFormattedTime = (date?: Date | null | undefined): string =>
  date && isDateObjectAndValid(date) ? format(date, 'H:mm') : '';

/**
 * Returns a new date with the given date and given hours.
 * Returns null if the given hour is invalid (< 0 or > 23)
 * Returns null if the given minute is invalid (< 0 or > 59)
 *
 * @param {Date} date The date that acts as base
 * @param {number | string} hours The hour representation (0 - 23)
 * @param {number | string} minutes The minutes representation (0 - 59)
 */
export const setTime = (
  date: Date,
  hours: number | string,
  minutes?: number | string,
): Date | null => {
  const convertedHours = asValidHourOrNull(hours);
  if (convertedHours === null) return null;

  const withHours = dateFNSSetHours(date, convertedHours);

  if (minutes === null || minutes === undefined) {
    return withHours;
  } else {
    const convertedMinutes = asValidMinuteOrNull(minutes);

    if (convertedMinutes === null || minutes === undefined) {
      return null;
    } else {
      return dateFNSSetMinutes(withHours, convertedMinutes);
    }
  }
};

/**
 * When handling pure date fields we set the hours to noon so it remains
 * the same date in different timezones.
 *
 * @param {Date} date The date to convert
 * @returns {Date} A new date object with the time set to noon
 */
export const asUTCNoon = (date: Date): Date =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0, 0),
  );

/****** AWSDateTime *******
 * The server has AWSDateTime validation, these functions help convert
 * from and to the correct string.
 *
 * In general we want to keep dates in the AWSDateTime format as much as possible.
 */

/**
 * Convert the given date (and time hh:mm) to our AWSDateTime string (ISO).
 * Returns null if no date is given or the converted date is invalid
 *
 * @param {?Date | ?string} date The base javascript date object or ISO string representation to convert (see convertServerDateStringToDate for expectation of string)
 * @param {?string} timeString If there is a time string given the date will be set to the given time. Expected format: 'hh:mm'.
 */
export const convertDateToServerDate = (
  date?: Date | null | undefined | string,
  timeString?: string | null | undefined,
): Date | null => {
  if (date === null || date === undefined) return null;

  let convertedDate;
  if (typeof date === 'string') {
    convertedDate = convertServerDateStringToDate(date);
    if (convertedDate === null) return null;
  } else {
    convertedDate = date;
  }

  if (timeString) {
    const tokens = timeString.split(':');
    if (tokens[1]) {
      convertedDate = setTime(convertedDate, tokens[0], tokens[1]);
    } else {
      convertedDate = setTime(convertedDate, tokens[0]);
    }
  }

  if (convertedDate === null) return null;

  return convertedDate;
};
export const convertDateToServerDateString = (
  date?: Date | null | undefined | string,
  timeString?: string | null | undefined,
): string | null => {
  const convertedDate = convertDateToServerDate(date, timeString);
  if (convertedDate === null) return null;
  return convertedDate.toISOString();
};

/**
 * Convert the given dateString (ISO format) to a javascript Date object
 */
export const convertServerDateStringToDate = (
  dateString: string | null | void,
): Date | null => {
  if (dateString === null || dateString === undefined) return null;
  if (!RegExp(ISOStringRegex).test(dateString)) return null;

  const date = parseISO(dateString);
  return isValid(date) ? date : null;
};
const ISOStringRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d{3}Z/;

/**
 * Returns which part of the day it is depending on the time
 */
export const getPartOfDay = (): string => {
  const today = new Date();
  const hour = today.getHours();

  if (hour >= 0 && hour < 6) return PART_OF_DAY.NIGHT;
  if (hour >= 6 && hour < 12) return PART_OF_DAY.MORNING;
  if (hour >= 12 && hour < 18) return PART_OF_DAY.AFTERNOON;
  if (hour >= 18 && hour < 24) return PART_OF_DAY.EVENING;

  return '';
};

export const getStartOfToday = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return today;
};
