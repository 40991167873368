import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Button from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Label } from '~/components/atom/Typography';
import { EMPTY_EDITOR_VALUE } from '~/components/organism/PluginsEditor/constants';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import type { FlowV2_Action_SendEmail_PlainFragment } from '~/graphql/types';
import useDHFlag from '~/hooks/useDHFlag';
import type { BaseColor } from '~/theme/System/tokens/colorPalette';

const text = {
  useCustomHtmlLabel: 'Gebruik aangepaste HTML',
  backToDefaultEditor: 'Terug naar standaard editor',
  enterCustomHtmlModeInfo:
    'Voeg een e-mail in via HTML code. Let op: de inhoud van de editor gaat verloren. Dit is een experimentele functie. DatHuis is niet verantwoordelijk voor problemen die voortvloeien uit het gebruik hiervan.',
  exitCustomHtmlModeInfo:
    'Door op deze knop te drukken, wordt je huidige HTML verwijderd.',
};

export type Props = {
  /** If the current html is in custom html state (includes the template prefix) */
  hasCustomHtml: boolean;

  /** Updates SendEmail action state */
  updateActionDetails: (value: {
    body?: FlowV2_Action_SendEmail_PlainFragment['body'];
    bodyValue?: EditorValue;
    customHtml: string | null;
  }) => void;

  /** Resets the DHEditor value when we switch to custom html */
  onBodyReset: (value?: { value: EditorValue }) => void;
};

const CustomHtmlSwitcher: React.FCC<Props> = ({
  hasCustomHtml,
  updateActionDetails,
  onBodyReset,
}) => {
  const theme = useTheme();
  const hasCustomHtmlFlag = useDHFlag('custom-html');

  // Render this container if there is custom html set, even if the feature flag
  // is turned off, so that users can escape the custom html mode.
  if (hasCustomHtml) {
    return (
      <>
        <Container
          padding={['s']}
          border={{ radius: 's' }}
          gap="s"
          $backgroundColor="danger"
        >
          <Icon name="exclamation" color={theme.color('danger')} />
          <Label withoutMargin color={{ group: 'danger', variant: 'light' }}>
            {text.exitCustomHtmlModeInfo}
          </Label>
          <Button
            label={text.backToDefaultEditor}
            icon="slash"
            appearance="danger"
            ghost
            onClick={() => {
              onBodyReset({ value: EMPTY_EDITOR_VALUE });
              updateActionDetails({
                customHtml: null,
                body: {
                  __typename: 'FlowV2_TemplateString',
                  template: '',
                  mappings: [],
                },
                bodyValue: EMPTY_EDITOR_VALUE,
              });
            }}
          />
        </Container>
      </>
    );
  }

  if (hasCustomHtmlFlag === false) return null;

  return (
    <Container
      $backgroundColor="primary"
      padding={['s']}
      border={{ radius: 's' }}
      gap="s"
    >
      <Icon name="edit" color={theme.color('primary')} />
      <Label withoutMargin color={{ group: 'primary', variant: 'light' }}>
        {text.enterCustomHtmlModeInfo}
      </Label>
      <Button
        label={text.useCustomHtmlLabel}
        icon="html-tag"
        onClick={() => updateActionDetails({ customHtml: '' })}
      />
    </Container>
  );
};

const Container = styled(JustificationContainer)<{
  $backgroundColor: BaseColor;
}>(
  ({ theme, $backgroundColor }) => css`
    width: 100%;
    background-color: ${theme.color($backgroundColor, 'translucent')};
  `,
);

export default CustomHtmlSwitcher;
