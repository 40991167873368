import type { ToolbarComponent } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons';
import { TOOLBAR_DIVIDER } from '~/components/organism/PluginsEditor/components/StaticToolbar/components/Buttons/buttonDefinitions';

export const MARKDOWN_EDITOR_BUTTONS: Array<ToolbarComponent> = [
  { type: 'button', name: 'bold' },
  { type: 'button', name: 'italic' },
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'typography' },
  { type: 'button', name: 'link' },
  { type: 'button', name: 'blockquote' },
  TOOLBAR_DIVIDER,
  { type: 'button', name: 'undo' },
  { type: 'button', name: 'redo' },
];
