import React from 'react';
import styled from 'styled-components';
import { Props } from '..';

const Megaphone: React.FCC<Props> = ({
  color = 'currentColor',
  secondaryColor: _secondaryColor,
  ...rest
}) => (
  <Container {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.55 11.7002C37.95 11.7002 37.35 12.0002 36.75 12.3002C30.6 18.4502 21 26.1002 8.25 26.1002H4.8C3 26.1002 1.5 27.6002 1.5 29.4002V37.5002C1.5 39.3002 3 40.8002 4.8 40.8002H8.4C21 40.8002 30.6 48.1502 36.9 54.3002C37.2 54.6002 37.8 54.9002 38.7 54.9002H44.55V40.5002V25.8002V11.7002H38.55Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7 42.4499L11.1 58.7999C10.65 59.9999 9.14996 60.7499 7.79996 60.1499L5.39996 59.2499C4.04996 58.7999 3.44996 57.2999 4.04996 55.9499L9.74996 41.3999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.55 26.3999V40.1999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.45 36.2999L44.55 38.6999V27.8999L51.45 30.2999V36.2999Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 35.3999H9.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 31.2002H9.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.95 11.7002V48.7502"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.8 23.7002C60.75 28.6502 60.75 36.4502 55.8 41.4002"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.4 18.8999C66.9 26.3999 66.9 38.6999 59.4 46.1999"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63 14.3999C73.05 24.4499 73.05 40.7999 63 50.8499"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default Megaphone;
