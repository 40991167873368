import { atom, selector } from 'recoil';
import type { FormBuilderFragment, FormBuilderMode } from '~/graphql/types';

export type MenuState = {
  openedOnEdge: boolean;
  edgeId: string;
  targetX: number;
  targetY: number;
  sourceId: string;
  targetId: string;
};

export type FormBuilder_Interaction =
  | 'edit-node-screen'
  | 'edit-node-event'
  | 'edit-event';

export type InteractionState = {
  interactionType: FormBuilder_Interaction;
  subjectId: string | null;
};

export const formState = atom<FormBuilderFragment | null>({
  key: 'formBuilder/formState',
  default: null,
});

/**
 * Only set once when the form data is returned
 */
export const initialFormAtom = atom<FormBuilderFragment | null>({
  key: 'formBuilder/initialForm',
  default: null,
});

export const interactionState = atom<InteractionState | null>({
  key: 'formBuilder/formBuilderInteraction',
  default: null,
});

export const menuState = atom<MenuState | null>({
  key: 'formBuilder/formBuilderMenuState',
  default: null,
});

export const formModeSelector = selector<FormBuilderMode | null>({
  key: 'formBuilder/formBuilderFormModeState',
  get: ({ get }) => {
    const form = get(formState);
    if (!form) return null;

    return form.mode;
  },
});
