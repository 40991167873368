import DesignSystem from './System';
import colorPalette, { SystemColorPalette } from './System/tokens/colorPalette';

import {
  zIndex,
  border,
  fontWeight,
  lineHeight,
  breakpoints,
  sizes,
  grid,
  boxShadow,
  SystemBreakpointMap,
  SystemZIndexMap,
  SystemSizeMap,
  SystemBorder,
  SystemLineHeightMap,
  SystemFontWeightMap,
  SystemGridMap,
  SystemBoxShadowMap,
} from './System/tokens';

export type {
  SizeMap,
  SystemSize,
  SystemSizeMap,
  SystemColorPalette,
  SystemFontWeight,
} from './System/tokens';

export type SystemType = {
  baseFontSize: string;
  fontFamily: { sans: string; monospace: string };
  fontWeight: SystemFontWeightMap;
  sizes: SystemSizeMap;
  lineHeight: SystemLineHeightMap;
};

export type SystemTokens = {
  type: SystemType;
  breakpoints: SystemBreakpointMap;
  colors: {
    colorPalette: SystemColorPalette;
    chart: Array<string>;
    portals: Array<string>;
  };
  zIndex: SystemZIndexMap;
  spacing: { scale: SystemSizeMap };
  border: SystemBorder;
  grid: SystemGridMap;
  boxShadow: SystemBoxShadowMap;
};

const tokens: SystemTokens = {
  type: {
    baseFontSize: '14px',
    sizes,
    fontFamily: {
      sans: '"Metropolis","Helvetica Neue","Helvetica","Arial",sans-serif',
      monospace: '"Fira Code", monospace',
    },
    fontWeight,
    lineHeight,
  },
  colors: {
    colorPalette,
    chart: [
      '#5994B3',
      '#7DB39F',
      '#A1CC9E',
      '#D6E6AD',
      '#FFE5BF',
      '#FFBFBF',
      '#CC8FB1',
      '#AC86B3',
      '#8F7DB3',
    ],
    portals: ['#5994B3', '#7DB39F', '#AC86B3', '#fc8f79', '#eebd6b'],
  },
  spacing: {
    scale: sizes,
  },
  border,
  zIndex,
  breakpoints,
  grid,
  boxShadow,
};

const theme = new DesignSystem(tokens);
export type Theme = typeof theme;
export type WithTheme = { theme: Theme };

export default theme;
