import React, { ReactElement, useRef, useEffect, Ref, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isString, isFunction } from 'lodash';
import { useSpring, animated } from 'react-spring';
import { Theme } from '~/theme';
import cleanedFilename from '~/util/cleanedFilename';
import useViewportSize from '~/components/bad/util/useViewportSize';
import Portal from '~/components/molecule/Portal';
import useKeybinding from '~/hooks/useKeybinding';
import { MAIN_CONTENT_WIDTH } from '~/components/organism/NavigationFrame/constants';
import { FONT_SIZE_OF_18 } from '~/styles/constants';
import { reporter } from '~/hooks/useErrorReporter';
import TextButton from '~/components/atom/TextButton';

type Props = {
  onClose: null | (() => void);
  children:
    | React.ReactNode
    | ((modalRef: Ref<any>) => React.ReactNode | Element);
  className?: string;

  /** By setting small the content determines the size of the modal */
  small?: boolean;
};
const Modal: React.FCC<Props> = ({ children, onClose, small, className }) => {
  const modalRef = useRef(null);
  const theme = useTheme();
  const innerModalRef = useRef(null);
  const [closing, setClosing] = useState(false);
  const [modalSpring] = useSpring(
    () => ({
      from: {
        opacity: 0,
        transform: `translateY(-40px)`,
      },
      to: {
        opacity: 1,
        transform: `translateY(0px)`,
      },
      config: {
        friction: 15,
        mass: 1,
        clamp: true,
      },
      onRest: () => {
        if (closing && onClose) onClose();
      },
      reset: true,
      reverse: closing,
    }),
    [closing],
  );

  const [viewportSize] = useViewportSize();

  useKeybinding({
    keys: 'escape',
    callback: () => setClosing(true),
  });

  useEffect(() => {
    const tolockElement = document.scrollingElement as HTMLElement;

    // to disable scrolling on the current scrolling element (usually the html tag)
    if (tolockElement != null) {
      if (
        isString(tolockElement.style.overflow) &&
        tolockElement.style.overflow.length > 0 &&
        tolockElement.style.overflow !== 'hidden'
      ) {
        reporter.captureException(
          new Error(
            `${cleanedFilename(
              __filename,
            )} | Should not occur | overwriting overflow (${
              tolockElement.style.overflow
            } on element (${JSON.stringify(tolockElement, null, 2)})`,
          ),
        );
      }

      tolockElement.style.overflow = 'hidden';
    }

    return () => {
      // enable scrolling on the current scrolling element (afterwards again)
      if (tolockElement != null) {
        tolockElement.style.removeProperty('overflow');
      }
    };
  });

  let modalwidth;
  if (small) {
    modalwidth = 'auto';
  } else {
    // if the screen is much bigger than our MAIN_CONTENT_WIDTH then go
    if (
      viewportSize.width >
      MAIN_CONTENT_WIDTH + theme.remToPxRaw(theme.space('xl'))
    ) {
      modalwidth = `${MAIN_CONTENT_WIDTH}px`;
    } else {
      modalwidth = '90vw';
    }
  }

  let renderedChildren = children as React.ReactNode;
  if (isFunction(children)) {
    // @ts-ignore
    renderedChildren = children(modalRef, modalwidth) as React.ReactNode;
  }

  let closeButtonComponent: ReactElement | null = null;
  if (onClose != null) {
    closeButtonComponent = (
      <CloseButton
        appearance="danger"
        icon="close"
        onClick={() => setClosing(true)}
        dataTestId="close-modal"
      />
    );
  }

  return (
    <Portal>
      <PageContainer className={className || ''} ref={modalRef}>
        <GridContainer
          data-testid="modal-container"
          style={modalSpring}
          $small={small}
          $modalwidth={modalwidth}
        >
          {closeButtonComponent}
          <ModalContainer ref={innerModalRef}>
            {renderedChildren}
          </ModalContainer>
        </GridContainer>
      </PageContainer>
    </Portal>
  );
};
type GridContainerProps = {
  $small?: boolean;
  $modalwidth: number;
};

const GridContainer = styled(animated.div)<GridContainerProps>`
  display: grid;
  max-height: 100vh;

  ${({ theme, $small, $modalwidth }) => {
    const modalHeight = $small ? 'auto' : 'minmax(95vh, min-content)';
    const smallGapWidth = `minmax(${theme.space('m')}, 1fr)`;
    const defaultGapWidth = `minmax(${theme.space('l')}, 1fr)`;

    return css`
      grid-template-columns:
        [main-start]
        ${smallGapWidth}
        [modal-start close-button-start]
        ${$modalwidth}
        [modal-end close-button-end]
        ${smallGapWidth}
        [main-end];
      grid-template-rows:
        [main-start close-button-start]
        ${defaultGapWidth}
        [modal-start close-button-end]
        ${modalHeight}
        [modal-end]
        ${defaultGapWidth}
        [main-end];

      ${theme.mq.greaterThan('tabletLandscape')`
        grid-template-columns:
          [main-start]
          ${defaultGapWidth}
          [modal-start]
          ${$modalwidth}
          [modal-end close-button-start]
          ${defaultGapWidth}
          [main-end close-button-end];
        grid-template-rows:
          [main-start close-button-start]
          ${defaultGapWidth}
          [modal-start close-button-end]
          ${modalHeight}
          [modal-end]
          ${defaultGapWidth}
          [main-end];
      `}

      @media (min-width: ${theme.bp(
        'tabletLandscape',
      )}) and (max-height:${theme.bp('tablet')}) {
        grid-template-columns:
          [main-start]
          ${defaultGapWidth}
          [modal-start]
          ${$modalwidth}
          [modal-end close-button-start]
          ${defaultGapWidth}
          [main-end close-button-end];
        grid-template-rows:
          [main-start]
          ${smallGapWidth}
          [modal-start close-button-start]
          ${modalHeight}
          [modal-end close-button-end]
          ${smallGapWidth}
          [main-end];
      }
    `;
  }};
`;

const CloseButton = styled(TextButton)<{}>`
  cursor: pointer;
  grid-column: close-button-start / close-button-end;
  grid-row: close-button-start / close-button-end;

  ${({ theme }) => css`
    font-size: ${FONT_SIZE_OF_18}px;
    color: ${theme.color('white')};

    align-self: center;
    justify-self: end;

    ${theme.mq.greaterThan('tabletLandscape')`
      align-self: center;
      justify-self: start;
    `};

    @media (min-width: ${theme.bp(
        'tabletLandscape',
      )}) and (max-height: ${theme.bp('tablet')}) {
      align-self: start;
      justify-self: start;
    }
  `};
`;

export const getModalBorderRadius = (theme: Theme) =>
  `${theme.getTokens().border.radius.s}`;
export const ModalContainer = styled.div<{}>`
  grid-column: modal-start / modal-end;
  grid-row: modal-start / modal-end;
  margin-bottom: 3em;

  ${({ theme }) => css`
    border-radius: ${getModalBorderRadius(theme)};
    background-color: ${theme.color('white')};
  `};
`;

const PAGE_CONTAINER_COLOR = 'rgba(74,74,74, 0.6)';

const PageContainer = styled.div<{}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  ${({ theme }) => css`
    background-color: ${PAGE_CONTAINER_COLOR};
    z-index: ${theme.getTokens().zIndex.top};
  `};
`;

export default Modal;
