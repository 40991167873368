import { navigate } from '@gatsbyjs/reach-router';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AssignBlock from '~/components/organism/AssignBlock';
import Button from '~/components/atom/Button';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TextButton from '~/components/atom/TextButton';
import { Body } from '~/components/atom/Typography';
import { UpdateRealworksAppStatus_AddTokenContainer__Input } from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import AppEntryItemHeader from '~/components/page/Apps/components/AppEntryItemHeader';
import { LINK_PREFIX, StepProps } from '../..';
import RealworksHelpBlock from '../RealworksHelpBlock';
import TEST_ID from './index.testid';

const text = {
  header: 'Koppeling instellen',
  description:
    'Maak een koppeling aan en koppel de vestiging in Realworks aan een vestiging en gebruiker in DatHuis.',
  nameInputError: 'De naam van de koppeling kan niet leeg zijn',
  appEntryHeader: '1. Kies een naam voor deze koppeling',
  bodyText:
    'Kies een naam voor deze koppeling. Bijvoorbeeld de vestiging in Realworks.',
  secondAppEntryHeader: '2. Wijs contacten toe aan een vestiging in DatHuis',
  secondBodyText:
    'Wijs relaties die vanuit Realworks worden geimporteerd toe aan een vestiging en gebruiker in DatHuis.',
  inputPlaceHolder: 'Bijv. "Vestiging Amsterdam"',
  nextButton: 'Volgende',
};

const SetupStep: React.FCC<StepProps> = ({ onCancel }) => {
  const [values, setValues] =
    useState<UpdateRealworksAppStatus_AddTokenContainer__Input>({
      name: '',
      officeId: null,
      userId: null,
    });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    void navigate(`${LINK_PREFIX}/import-contacts`, {
      replace: true,
      state: { values: { ...values, relatie: null, wonen: null } },
    });
  };

  return (
    <AppDetailsContainer
      header={text.header}
      icon="link"
      dataTestId={TEST_ID.CONTAINER}
    >
      <Body>{text.description}</Body>

      <form onSubmit={onSubmit} data-testid={TEST_ID.FORM}>
        <RealworksHelpBlock tipId="setup-step-id" />
        <GroupWrapper>
          <AppEntryItemHeader>{text.appEntryHeader}</AppEntryItemHeader>
          <Body>{text.bodyText}</Body>
          <Input
            appearance="primary"
            validation={[value => (value === '' ? text.nameInputError : true)]}
            value={values.name}
            placeholder={text.inputPlaceHolder}
            onChange={e => {
              setValues(prev => ({ ...prev, name: e.target.value }));
            }}
            dataTestId={TEST_ID.NAME_INPUT}
          />
        </GroupWrapper>
        <AppEntryItemHeader>{text.secondAppEntryHeader}</AppEntryItemHeader>
        <Body>{text.secondBodyText}</Body>
        <AssignBlock
          userId={values.userId}
          officeId={values.officeId}
          onChange={({ userId, officeId }) =>
            setValues(prev => ({ ...prev, userId, officeId }))
          }
        />

        <JustificationContainer
          justification="space-between"
          align="center"
          margin={['l', null, null]}
        >
          <TextButton
            label="Afbreken"
            onClick={onCancel}
            withoutPadding
            appearance="danger"
            type="button"
            dataTestId="exit-wizard-button"
          />
          <Button
            icon="check"
            label={text.nextButton}
            type="submit"
            size="medium"
            loading={false}
            disabled={isEmpty(values.name)}
            dataTestId={TEST_ID.NEXT_BUTTON}
          />
        </JustificationContainer>
      </form>
    </AppDetailsContainer>
  );
};

const GroupWrapper = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.space('l')};
  `,
);

export default SetupStep;
