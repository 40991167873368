import { memoize } from 'underscore';

import {
  SortDirection,
  type GetFormsQueryVariables,
  type GetFormsQuery,
} from '~/graphql/types';
import { ascend, descend, prop } from 'ramda';
import { SortSettings } from '~/hooks/useSortSettings';

export type FormListData = {
  id: string;
  name: string;
  description?: string | null;
};

export const composeListData = (
  items: GetFormsQuery['getForms'] | null | undefined,
  sortSettings: SortSettings,
): Array<FormListData> => {
  if (items == null) return [];

  // @ts-ignore I couldn't explain TS what we're doing here, so ignore for now.
  const sorter = (sortSettings.desc ? descend : ascend)(prop(sortSettings.id));

  return items
    .map(({ name, description, id, enabled }) => ({
      id,
      name,
      formDescription: description,
      enabled,
    }))
    .sort((a, b) => {
      const getFieldValue = value => value;

      const _a = {
        ...a,
        [sortSettings.id]: getFieldValue(a[sortSettings.id]),
      };
      const _b = {
        ...b,
        [sortSettings.id]: getFieldValue(b[sortSettings.id]),
      };

      return sorter(_a, _b);
    });
};

const memoizedComposeFormListData = memoize(
  composeListData,
  (
    items: Array<any>,
    variables: GetFormsQueryVariables,
    version: string,
    sortSettings: SortSettings,
  ) => {
    const result = `${version}*@@*${items.length}*@@*${sortSettings.id}*@@*${
      sortSettings.desc ? SortDirection.Desc : SortDirection.Asc
    }`;

    return result;
  },
);

export default memoizedComposeFormListData;
