import React from 'react';
import TEST_ID from './index.testid';
import { ContactFilters__Input } from '~/graphql/types';
import Segments from './components/Segments';
import JustificationContainer from '~/components/atom/JustificationContainer';
import QuickSegments from './components/QuickSegments';

type Props = {
  /** Is used to select segment filters */
  onFilterChangeV2: (params: ContactFilters__Input) => void;

  /** Current filters in the top filter bar */
  currentFilters: ContactFilters__Input;
};

const ContactListLeftFilterBar: React.FCC<Props> = ({
  onFilterChangeV2,
  currentFilters,
}) => (
  <JustificationContainer
    wrap="wrap"
    align="center"
    dataTestId={TEST_ID.CONTAINER}
  >
    <Segments
      currentFilters={currentFilters}
      onFilterChangeV2={onFilterChangeV2}
    />
    <QuickSegments onFilterChangeV2={onFilterChangeV2} />
  </JustificationContainer>
);

export default ContactListLeftFilterBar;
