import React from 'react';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  Appearance,
  componentAppearances,
  type ComponentSizes,
  type Size,
} from '~/styles/constants';
import TEST_ID from './index.testid';
import reactNodeToString from '~/util/reactNodeToString';

export type Props = {
  size?: Size;
  label: string | React.ReactNode;
  appearance: Appearance;
  style?: React.CSSProperties;
  disabled?: boolean;
  onDelete: () => void;
};

const DeletableItem: React.FCC<Props> = ({
  label,
  appearance = 'primary',
  size = 'medium',
  disabled = false,
  onDelete,
  ...rest
}) => (
  <>
    <Container {...rest} onClick={e => e.stopPropagation()}>
      <Label
        $size={size}
        appearance={appearance}
        title={reactNodeToString(label)}
        data-testid={TEST_ID.LABEL}
      >
        {label}
      </Label>

      <RemoveButton
        $size={size}
        $disabled={disabled}
        appearance={appearance}
        onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
          e.stopPropagation();
          onDelete();
        }}
        data-testid={TEST_ID.DELETE_BUTTON}
      >
        <Icon name="close" />
      </RemoveButton>
    </Container>
  </>
);

const Container = styled(animated.div)<{}>(
  () => css`
    display: flex;
    max-width: 100%;
    gap: 1px;
  `,
);

const localSizeMap: ComponentSizes = {
  small: {
    fontSize: 's',
    padding: ['xxs', 'xxs'],
  },
  medium: {
    fontSize: 's',
    padding: ['xs', 'xxs'],
  },
  large: {
    fontSize: 'base',
    padding: ['xs', 's'],
  },
};

const Label = styled.div<{ $size: Size; appearance: Appearance }>`
  ${({ theme, $size, appearance }) => css`
    border-radius: ${theme.getTokens().border.radius.s};
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    background-color: ${theme.color(
      componentAppearances[appearance].group,
      componentAppearances[appearance].variant,
    )};
    color: ${theme.color('white')};

    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    font-size: ${theme.fontSize(localSizeMap[$size].fontSize)};
    padding: calc(${theme.space(localSizeMap[$size].padding[0])} / 2)
      ${theme.space(localSizeMap[$size].padding[1])};
  `};
`;

// We make a custom button because the Button component messes up the height
const RemoveButton = styled(JustificationContainer)<{
  $size: Size;
  $disabled: boolean;
  appearance: Appearance;
}>(
  ({ theme, appearance, $size, $disabled }) => css`
    cursor: pointer;
    border-radius: ${theme.getTokens().border.radius.s};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    background-color: ${theme.color(
      componentAppearances[appearance].group,
      componentAppearances[appearance].variant,
    )};
    color: ${theme.color('white')};
    padding: calc(${theme.space(localSizeMap[$size].padding[0])} / 2);
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: ${theme.color(
        componentAppearances[appearance].group,
        'dark',
      )};
    }

    ${$disabled &&
    css`
      pointer-events: none;
    `}
  `,
);

export default DeletableItem;
