import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import { navigate } from '@gatsbyjs/reach-router';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { PremiumValueReportDetailsFields } from './components/PremiumValueReportDetailsComponent';
import FullPageInformation from '~/components/template/FullPageInformation';
import Loading from '~/components/atom/Loading';
import PremiumValueReportDetailsComponent from './components/PremiumValueReportDetailsComponent';
import useIsMounted from '~/hooks/useIsMounted';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import {
  useDeleteAppPremiumValuationReportMutation,
  useGetAppValuationReportQuery,
  useUpdateAppPremiumValuationReportMutation,
  type ValuationReportPremiumFieldsFragment,
} from '~/graphql/types';

const text = {
  pageTitle: 'Waarderapport',
  errorTitle: 'Geen data gevonden',
  errorExplanation:
    'Er is iets misgegaan bij het ophalen van de data van het account. Waarschijnlijk kan er geen verbinding gemaakt worden met de server. Probeer het nog een keer',
};
type Props = RouteComponentProps<{ reportId: string }>;
const PremiumValueReportDetails: React.FCC<Props> = ({
  reportId,
}: {
  reportId: string;
}) => {
  const isMounted = useIsMounted();
  const account = useCurrentAccount();
  const { data, loading, error } = useGetAppValuationReportQuery({
    variables: {
      accountId: account.id,
      id: reportId,
    },
  });
  const [updateData, { loading: updateLoading }] =
    useUpdateAppPremiumValuationReportMutation({});
  const [deleteData, { loading: deleteLoading }] =
    useDeleteAppPremiumValuationReportMutation({});

  if (loading) {
    return <Loading />;
  }

  if (error || !data)
    return (
      <>
        <FullPageInformation
          title={text.errorTitle}
          explanation={text.errorExplanation}
        />
      </>
    );

  const { getAppValuationReport } = data;

  if (
    getAppValuationReport == null ||
    getAppValuationReport.__typename === 'App_ValuationReport'
  ) {
    return (
      <FullPageInformation
        title={text.errorTitle}
        explanation={text.errorExplanation}
      />
    );
  }
  return (
    <>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>

      <PremiumValueReportDetailsComponent
        report={asProp(getAppValuationReport)}
        updating={loading || updateLoading || deleteLoading}
        updateFn={reportUpdate =>
          updateData({
            variables: {
              accountId: account.id,
              id: reportId,
              update: {
                accountId: account.id,
                ...reportUpdate,
              },
            },
          })
        }
        onDelete={() =>
          deleteData({
            variables: {
              accountId: account.id,
              id: reportId,
            },
          }).then(() => isMounted() && void navigate('/-/apps/value-report'))
        }
      />
    </>
  );
};

const asProp = (
  report: ValuationReportPremiumFieldsFragment,
): PremiumValueReportDetailsFields => {
  const { algasId, name, sendAgentEmail, sendContactEmail, domain } = report;

  return {
    algasId,
    name,
    sendAgentEmail,
    sendContactEmail,
    domain: domain ?? null,
  };
};

export default PremiumValueReportDetails;
