import { createPortal } from 'react-dom';
import React, { type CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { REACT_FLOW_NODES_LAYER } from '~/components/page/Automation/v2/components/Builder/constants/reactFlowLayers';
import { Heading4, Variant } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import IconOnlyButton from '~/components/organism/Selector/components/IconOnlyButton';
import useNodeTypes, {
  nodeTypeColorCoding,
  nodeTypeLabels,
} from '../Canvas/nodeTypes';
import { isNil, keys } from 'ramda';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';
import arrayToCss from '~/util/arrayToCss';
import { useRecoilValue } from 'recoil';
import { menuState } from '../../state';
import useIsBasicForm from '../../hooks/useIsBasicForm';
import { submitScreenState } from '../../state/submitScreen';

export type CreateType = 'screen' | 'event' | 'submitScreen';
export type CreateRelation = { target: string; source: string };
export type Props = {
  onSelect: (createType: CreateType, relation: CreateRelation) => void;
  onClose: () => void;
  style?: CSSProperties;
};

const AddNodeMenu: React.FCC<Props> = React.memo(
  ({ style, onClose, onSelect }) => {
    const isBasicForm = useIsBasicForm();
    const state = useRecoilValue(menuState);
    const submitScreen = useRecoilValue(submitScreenState);
    const nodeTypes = useNodeTypes();
    const animation = useSpring({
      from: {
        transform: `translateX(-50px)`,
        opacity: 0,
      },
      to: {
        transform: `translateX(0)`,
        opacity: 1,
      },
    });

    if (!state) return null;

    const options = keys(nodeTypes).filter(key => {
      if (isBasicForm) {
        // only add submitScreen option on trailing node
        if (!isNil(submitScreen)) return key === 'screenNode';
        return key === 'screenNode' || key === 'submitScreen';
      }
      return key === 'eventNode' || key === 'screenNode';
    });

    return (
      <Container style={{ ...animation, ...style }}>
        <Header
          padding={['xs']}
          gap="xs"
          justification="space-between"
          align="center"
        >
          <Heading4 margin={[null]} variant={Variant.primary}>
            Add Node
          </Heading4>
          <IconOnlyButton icon="close" onClick={onClose} />
        </Header>

        <NodeOptionList>
          {options.map(nodeTypeKey => (
            <NodeOptionListItem
              key={nodeTypeKey}
              onClick={() => {
                const createType = (() => {
                  if (nodeTypeKey === 'eventNode') return 'event';
                  if (nodeTypeKey === 'screenNode') return 'screen';
                  return 'submitScreen';
                })();

                onSelect(createType, {
                  source: state.sourceId,
                  target: state.targetId,
                });
              }}
              $colorCoding={nodeTypeColorCoding[nodeTypeKey]}
            >
              {nodeTypeLabels[nodeTypeKey]}
            </NodeOptionListItem>
          ))}
        </NodeOptionList>
      </Container>
    );
  },
);

const Container = styled(animated.aside)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space('base')};
    left: ${theme.space('base')};

    background: ${theme.color('white')};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};

    z-index: 30;
    user-select: none;
    pointer-events: all;
  `,
);

const Header = styled(JustificationContainer)``;

const NodeOptionList = styled.ul(
  ({ theme }) => css`
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: ${theme.space('s')};
  `,
);

const NodeOptionListItem = styled.li<{ $colorCoding: ThemeColor }>(
  ({ theme, $colorCoding }) => css`
    cursor: pointer;
    padding: ${arrayToCss(['s', 's', 's', 'm'], theme)};
    margin: 0 ${theme.space('xs')};
    position: relative;
    overflow: hidden;
    border-radius: ${theme.getTokens().border.radius.base};
    transition: 0.2s ease-out;

    &:hover {
      box-shadow: ${theme.boxShadow('around')};
    }

    &:before {
      content: '';
      width: 0.2rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: ${theme.color(
        $colorCoding.group,
        $colorCoding.variant,
      )};
    }
  `,
);

// We're using createPortal to transport the rendering into the react-flow nodes layer
// This is needed to prevent the menu from falling behind the SVG elements of react flow
// and to make it part of the natural scaling and panning behaviour of react-flow
const Portalled: React.FCC<Props> = props =>
  createPortal(
    <AddNodeMenu {...props} />,
    document.querySelector(REACT_FLOW_NODES_LAYER) ||
      document.createElement('div'),
  );

export default React.memo(Portalled);
