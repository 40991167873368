import React, { useEffect, useState, useRef } from 'react';
import { Editor, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import styled, { css, useTheme } from 'styled-components';
import Portal from '~/components/molecule/Portal';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import ImageToolbar from './components/ImageToolbar';
import LinkToolbar from './components/LinkToolbar';
import DeleteButton from '../Buttons/Delete';
import VariableToolbar from './components/VariableToolbar';
import { getSelectedElement, getStartPoint } from '../../commands';
import TEST_ID from './index.testid';
import useViewportSize from '~/components/bad/util/useViewportSize';
import withErrorBoundary from '~/ErrorBoundary';
import usePrevious from '~/hooks/usePrevious';
import { equals } from 'ramda';

export type Props = {};

const text = {
  errorMessage:
    'We hebben momenteel problemen met het focussen op de teksteditor. Ververs de pagina en probeer het opnieuw. Blijft de foutmelding komen, neem dan contact met ons op via de chat rechts onderin.',
};

const LINE_HEIGHT = 40;
const TRIANGLE_HEIGHT = 20;

const DEFAULT_POSITION = {
  top: 0,
  left: 0,
  opacity: 0,
};

const HoveringToolbar: React.FCC<Props> = ({}) => {
  const editor = useSlate();
  const theme = useTheme();
  const [{ height }] = useViewportSize();
  const [position, setPosition] = useState<{
    top: number;
    left: number;
    opacity: number;
  }>(DEFAULT_POSITION);
  const { selection } = editor;

  const selectedEl = getSelectedElement(editor);

  const hasTextSelection = selection
    ? Editor.string(editor, selection) !== ''
    : false;

  const noSelection =
    !selectedEl || !selectedEl.element || !selection || hasTextSelection;

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (noSelection) return;

    const range = Editor.range(editor, selection);
    const domRange = ReactEditor.toDOMRange(editor, range);
    const rect = domRange?.getBoundingClientRect();

    if (!rect || !rect.top || !rect.left) return setPosition(DEFAULT_POSITION);

    setPosition({
      top: rect.top + window.scrollY + 20,
      left: rect.left,
      opacity: 1,
    });

    return;
  }, [selectedEl?.element, editor, selection, noSelection]);

  if (noSelection) return null;

  const distanceToBottom = height - position.top;
  const distanceToTop = height - distanceToBottom;
  const openingDirection =
    distanceToTop - distanceToBottom > 0 ? 'top' : 'bottom';
  const firstChildHeight =
    containerRef?.current?.children?.[0]?.clientHeight || 0;

  const top =
    openingDirection === 'top'
      ? position.top - firstChildHeight - LINE_HEIGHT
      : position.top + 10;

  const containerStyle = position
    ? {
        top: `${top}px`,
        left: `${position.left}px`,
        opacity: position.opacity,
      }
    : undefined;

  if (
    selectedEl.element.type === ELEMENTS.IMAGE ||
    selectedEl.element.type === ELEMENTS.DH_IMAGE
  ) {
    return (
      <Portal>
        <Container
          style={containerStyle}
          data-testid={TEST_ID.CONTAINER}
          ref={containerRef}
        >
          <ImageToolbar path={selectedEl.path} element={selectedEl.element} />
        </Container>
      </Portal>
    );
  }

  if (selectedEl?.element.type === ELEMENTS.LINK) {
    return (
      <Portal>
        <Container
          style={containerStyle}
          data-testid={TEST_ID.CONTAINER}
          ref={containerRef}
        >
          <LinkToolbar
            path={selectedEl.path}
            element={selectedEl.element}
            editor={editor}
          />
        </Container>
      </Portal>
    );
  }

  /**
   * This is added be able to delete unknown elements in the editor
   * otherwise it gets difficult to remove them sometimes
   */
  if (selectedEl?.element.type === ELEMENTS.GENERIC_HTML_ELEMENT) {
    return (
      <Portal>
        <Container
          style={containerStyle}
          data-testid={TEST_ID.CONTAINER}
          ref={containerRef}
        >
          <DeleteButton editor={editor} path={selectedEl.path} />
        </Container>
      </Portal>
    );
  }

  if (selectedEl?.element.type === ELEMENTS.VARIABLE) {
    const pointerDirection = openingDirection === 'top' ? 'bottom' : 'top';
    const containerLeft =
      containerRef.current?.getBoundingClientRect()?.left || 0;
    const positionLeft = position.left - (containerLeft + 20);

    return (
      <Portal>
        <div
          style={{
            [pointerDirection]: `${
              openingDirection === 'top'
                ? distanceToBottom + LINE_HEIGHT
                : distanceToTop + TRIANGLE_HEIGHT
            }px`,
            opacity: position.opacity,
            position: 'absolute',
            zIndex: theme.z('top') + 100,
            left: '0',
            right: '0',
            margin: 'auto',
            width: '60vw',
            maxWidth: '700px',
          }}
          data-testid={TEST_ID.CONTAINER}
          ref={containerRef}
          data-objectid="variable-toolbar"
        >
          <VariableToolbar
            editor={editor}
            path={selectedEl.path}
            element={selectedEl.element}
            pointerOffset={positionLeft > 10 ? positionLeft : 18}
            pointerLocation={pointerDirection}
          />
        </div>
      </Portal>
    );
  }

  return null;
};

const Container = styled.div<{}>`
  display: flex;
  position: absolute;
  align-items: center;
  z-index: 1;
  top: 0px;
  left: 0px;
  margin-top: -6px;
  opacity: 0;

  ${({ theme }) => css`
    background-color: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('around')};
    padding: ${theme.space('xxs')};
    border-radius: ${theme.getTokens().border.radius.base};
    z-index: ${theme.z('top') + 100}; /* Really make sure its always on top */
  `}
`;

const SlateErrorContainer = styled.div<{}>`
  ${({ theme }) => css`
    background-color: ${theme.color('danger', 'translucent')};
    color: ${theme.color('danger')};
    padding: ${theme.space('xxs')};
    margin: ${theme.space('xxs')} 0;
    border-radius: ${theme.getTokens().border.radius.base};
  `}
`;

const ErrorHandlingComp = ({ resetError }) => {
  const editor = useSlate();

  /**
   * The reason for error throwing in this component is usually 'Cannot resolve DOM point from Slate point'
   * So we move the selection to the beginning of the editor which is hopefully a focusable point
   */
  useEffect(() => {
    const firstElementPath = getStartPoint(editor);
    const point = { path: firstElementPath, offset: 0 };
    Transforms.setSelection(editor, { anchor: point, focus: point });
  }, [editor]);

  const currentChildren = editor.children;
  const prevChildren = usePrevious(currentChildren);

  useEffect(() => {
    const hasChanged = !equals(currentChildren, prevChildren);
    if (hasChanged) {
      resetError();
    }
  }, [currentChildren, prevChildren, resetError]);

  return <SlateErrorContainer>{text.errorMessage}</SlateErrorContainer>;
};

export default withErrorBoundary(HoveringToolbar, props => (
  <ErrorHandlingComp resetError={props.resetError} />
));
