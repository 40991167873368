import type { TransitionFrom } from 'react-spring';
import type { SystemSize } from '~/theme';
import type { ThemeColor } from '~/theme/System/tokens/colorPalette';

export type Appearance =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'accent'
  | 'danger';

export type Size = 'small' | 'medium' | 'large';

type SizeProps = {
  fontSize: SystemSize;
  padding: [SystemSize, SystemSize];
};

export type ComponentSizes = {
  [key in Size]: SizeProps;
};

export const componentSizes: ComponentSizes = {
  small: {
    fontSize: 's',
    padding: ['xs', 's'],
  },
  medium: {
    fontSize: 'base',
    padding: ['s', 'm'],
  },
  large: {
    fontSize: 'base',
    padding: ['m', 'm'],
  },
};

export const componentAppearances: {
  [key in Appearance]: ThemeColor;
} = {
  primary: {
    group: 'primary',
    variant: 'light',
  },
  secondary: {
    group: 'success',
    variant: 'base',
  },
  tertiary: {
    group: 'grey',
    variant: 'base',
  },
  accent: {
    group: 'accent',
    variant: 'base',
  },
  danger: {
    group: 'danger',
    variant: 'light',
  },
};

// Font size of 18 is taken from the legacy theme. Making it a constant is not the best solution,
// but at least will not hold us from removing legacy theme. It's only used in old components,
// means the constant will be replaced with the theme sizes once we overwrite old components.
export const FONT_SIZE_OF_18 = 18;

// It's only used in old components,
// means the constant will be replaced with the theme sizes once we overwrite old components.
export const APPROXIMATE_HEADER_HEIGHT = 72;

export const ANIMATION_CONFIG = {
  from: {
    opacity: 0,
    transform: 'translateX(-40px)',
  } as TransitionFrom<string>,
  to: {
    opacity: 1,
    transform: 'translateX(0px)',
  } as TransitionFrom<string>,
  config: {
    mass: 1,
    tension: 110,
    friction: 14,
  },
};
