import React, { type ElementType } from 'react';

import {
  Variant,
  type Props as TypographyProps,
} from '~/components/atom/Typography';
import withHelpLink from '~/hocs/withHelpLink';

export type Props = {
  TypographyComponent: ElementType<
    TypographyProps & { children: React.ReactNode }
  >;
};

/**
 * Adds the help icon to the top right corner of the Typography component
 *
 * @param TypographyComponent Any component from Typography
 * @param children Children passed to the component, usually some text
 */
const TypographyWithHelpLink: React.FCC<Props> = ({
  TypographyComponent,
  children,
  ...rest
}) => (
  <TypographyComponent withoutMargin variant={Variant.primary} {...rest}>
    {children}
  </TypographyComponent>
);

export default withHelpLink(TypographyWithHelpLink);
