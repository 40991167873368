import React from 'react';
import styled, { css } from 'styled-components';
import JustificationContainer from '~/components/atom/JustificationContainer';
import AsideContainer from '../BaseAside';
import FullWidthButton from '~/components/atom/FullWidthButton';

export type Props = {
  disabled: boolean;
  loading: boolean;
  onSave: () => void;
  onCancel: () => void;
};

const FloatingSaveBar = React.forwardRef<HTMLDivElement, Props>(
  ({ onSave, onCancel, loading, disabled }, ref) => (
    <Container ref={ref}>
      <JustificationContainer
        width="100%"
        justification="space-between"
        gap="m"
      >
        <StyledButton
          label="Afbreken"
          onClick={onCancel}
          appearance="danger"
          ghost
          size="small"
          disabled={disabled}
        />
        <StyledButton
          label="Aanpassingen opslaan"
          onClick={onSave}
          appearance="secondary"
          icon="save"
          size="small"
          loading={loading}
          disabled={disabled}
        />
      </JustificationContainer>
    </Container>
  ),
);

const StyledButton = styled(FullWidthButton)(
  () => css`
    justify-content: center;
  `,
);

const Container = styled(AsideContainer)<{}>(
  ({ theme }) => css`
    top: ${theme.space('s')};
    // Make sure it is always highest on the right side
    z-index: calc(${theme.getTokens().zIndex.dropdown} + 1);
  `,
);

export default FloatingSaveBar;
