import React from 'react';

import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import NavLink, { NavigationLink } from '../../../NavigationLink';
import { ANIMATION_CONFIG } from '~/styles/constants';

type Props = {
  links: Array<NavigationLink & { onClick?: () => void }>;
};

const MenuLinks: React.FCC<Props> = ({ links }) => {
  const animation = useSpring(ANIMATION_CONFIG);

  return (
    <LinksContainer style={animation}>
      {links.map(link => (
        <Link {...link} key={link.to} onClick={link.onClick} />
      ))}
    </LinksContainer>
  );
};

const LinksContainer = styled(animated.div)<{}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const Link = styled(NavLink)<{}>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    text-decoration: none;
    padding: ${theme.space('xl')} 0;
    position: relative;
    font-size: ${theme.fontSize('l')};
    width: 100%;

    &[data-active='true'] {
      color: ${theme.color('primary', 'light')};

      &::after {
        content: '';
        position: absolute;
        background: ${theme.color('secondary', 'light')};
        width: 4px;
        height: 100%;
        display: block;
        left: -${theme.space('xxl')};
        opacity: 1;
        bottom: 0;
      }
    }
  `,
);

export default MenuLinks;
