/**
 *
 * A Hook to help use the Error modal with a specific payload. It will give the user:
 *  [0] - the component to render. Always show it, if it should be visible is handled by the hook
 *  [1] - the function to show the modal
 *
 * It expects:
 *  labels - object that determines the label of the modal
 *  onClose? - called when the modal is closed
 */

import React, { useState } from 'react';
import ErrorModal from '~/components/template/ErrorModal';

type ReturnProps = [React.ReactNode, () => void];
export type ModalLabels = {
  title: string;
  message: string | React.ReactNode;
};

const useErrorModal = (
  labels: ModalLabels,
  onClose?: () => void,
): ReturnProps => {
  const [showModal, setShowModal] = useState(false);

  const { title, message } = labels;

  const component = showModal ? (
    <ErrorModal
      title={title}
      message={message}
      onClose={() => {
        setShowModal(false);

        if (onClose != null) {
          onClose();
        }
      }}
    />
  ) : null;

  return [component, () => setShowModal(true)];
};

export default useErrorModal;
