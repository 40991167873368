import React from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import JustificationContainer from '~/components/atom/JustificationContainer';
import OverviewListHeader from '~/components/molecule/OverviewListHeader';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import { useGetPlansQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import SubscriptionDetails from './components/SubscriptionDetails';
import OverageDetails from './components/OverageDetails';
import BillingDetails from './components/BillingDetails';
import PaymentDetails from './components/PaymentDetails';
import Agreements from './components/Agreements';
import TEST_ID from './index.testid';
import { Heading4 } from '~/components/atom/Typography';
import ReactivateSubscriptionBanner from './components/ReactivateSubscriptionBanner';

export type Props = {};

const text = {
  pageTitle: 'Abonnement',
  subscriptionHeader: 'Abonnement',
  overageHeader: 'Overige kosten',
  billingDetailsHeader: 'Facturatie',
  externalSubscription:
    'Dit abonnement wordt extern beheerd. Voor vragen neem contact op met de klantenservice',
};

const SubscriptionSettings: React.FC<Props> = () => {
  const account = useCurrentAccount();
  const { data, networkStatus, error, refetch } = useGetPlansQuery({
    variables: {
      accountId: account.id,
    },
  });

  if (isInitialLoadStatus(networkStatus)) {
    return <DatHuisLoading />;
  }

  if (error || !data) {
    return (
      <ContentContainerDefault>
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>
        <AppErrorScreen />
      </ContentContainerDefault>
    );
  }

  if (!data.getPlans.current) {
    return (
      <ContentContainerDefault data-testid={TEST_ID.MANUAL_SUBSCRIPTION_PAGE}>
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>
        <JustificationContainer align="center" justification="center">
          <Heading4>{text.externalSubscription}</Heading4>
        </JustificationContainer>
      </ContentContainerDefault>
    );
  }

  return (
    <ContentContainerDefault data-testid={TEST_ID.CONTAINER}>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <ReactivateSubscriptionBanner
        currentBillingCycle={data.getPlans.current.billingCycle}
        currentPlan={data.getPlans.current}
        nextPlan={data.getPlans.next}
        onSuccess={() => refetch()}
      />
      <OverviewListHeader
        title={text.pageTitle}
        dataTestId="subscription-header"
      />
      <JustificationContainer gap="l" justification="space-between">
        <JustificationContainer gap="l" direction="column" width="100%">
          <AnimatedBlock title={text.subscriptionHeader} manualLayout>
            <SubscriptionDetails
              currentPlan={data.getPlans.current}
              nextPlan={data.getPlans.next}
              onPlanChange={() => refetch()}
            />
          </AnimatedBlock>
          <AnimatedBlock title={text.overageHeader} manualLayout>
            <OverageDetails />
          </AnimatedBlock>
        </JustificationContainer>

        <JustificationContainer width="480px">
          <AnimatedBlock title={text.billingDetailsHeader} manualLayout>
            <BillingDetails />
            <PaymentDetails />
            <Agreements />
          </AnimatedBlock>
        </JustificationContainer>
      </JustificationContainer>
    </ContentContainerDefault>
  );
};

export default SubscriptionSettings;
