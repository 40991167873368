import { navigate } from '@gatsbyjs/reach-router';
import React, { useState } from 'react';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';
import { LINK_PREFIX } from '~/components/page/Apps/Zapier/components/Wizard/index';
import { StepProps } from '../Connect';
import TEST_ID from './index.testid';
import AddEventDiagram from '~/components/molecule/Diagrams/AddZapierEvent';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TipBanner from '~/components/organism/TipBanner';
import ChatLink from '~/components/organism/TipBanner/ChatLink';
import Link from '~/components/molecule/Link';
import {
  useGetZapierEventsLazyQuery,
  useInsertZapierEventMutation,
} from '~/graphql/types';
import EventTemplatesContainer from './components/EventTemplatesContainer';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import useIsMounted from '~/hooks/useIsMounted';
import { EventTemplates } from './components/EventTemplatesContainer/eventTemplates';
import { isNil } from 'ramda';

const text = {
  header: 'Inkomende koppelingen',
  body: 'Om gegevens van Zapier naar DatHuis te sturen, dien je een inkomende koppeling in te stellen. Deze gegevens worden in het contact opgeslagen en worden gebruikt in een flow of doorgestuurd naar jouw CRM.',
  exampleHeader:
    'Een voorbeeld hoe je gegevens via een Zap in DatHuis ontvangt',

  chooseConnectionHeader: 'Kies inkomende koppelingen',
  chooseConnectionBody: (
    <>
      Met een inkomende koppeling bepaal je welke gegevens je kan ontvangen
      vanuit Zapier. We hebben we een aantal inkomende koppelingen voor je
      klaargezet. Deze zijn gemaakt voor de meest voorkomende toepassingen. Na
      het afronden van deze wizard is het mogelijk om zelf een koppeling te
      maken.
    </>
  ),
  tip: (
    <>
      Lees hoe je de koppeling moet instellen in onze{' '}
      <Link to="https://help.dathuis.nl/">kennisbank</Link>. Kom je er niet uit?
      &nbsp;
      <ChatLink linkText="Start een chat met ons." />
    </>
  ),
};

const AddEvent: React.FCC<StepProps> = ({ onClose, disableClosing }) => {
  const { id: accountId } = useCurrentAccount();
  const isMounted = useIsMounted();
  const addToast = useAddToast();
  const [selectedEvents, setSelectedEvents] = useState<EventTemplates>([]);

  const [insertZapierEvent, { loading }] = useInsertZapierEventMutation();
  const [getEventsLazy] = useGetZapierEventsLazyQuery({
    variables: {
      accountId,
    },
  });

  const onNext = async () => {
    if (selectedEvents.length > 0) {
      const data = await Promise.all(
        selectedEvents.map(({ name, fields }) =>
          insertZapierEvent({
            variables: {
              accountId,
              name: name,
              fields: fields,
            },
          }).then(({ data, errors }) => {
            if (errors && errors.length > 0) {
              addToast([
                formatToastMessage(
                  `Het toevoegen van "${name}" inkomende koppeling is mislukt.`,
                  'danger',
                ),
              ]);
            }
            return { data };
          }),
        ),
      );

      const successResult = data.filter(({ data }) => !isNil(data));
      if (successResult.length > 0) {
        addToast([
          formatToastMessage(
            `${successResult.length} inkomende koppeling(en) succesvol toegevoegd.`,
            'success',
          ),
        ]);
      }
    }
    // Update internal Apollo cache with newly added events
    void getEventsLazy();
    if (!isMounted()) return;

    void navigate(`${LINK_PREFIX}/next-steps`, { replace: true });
  };

  return (
    <AppDetailsContainer
      header={text.header}
      icon="incomingLink"
      data-testid={TEST_ID.CONTAINER}
    >
      <Body>{text.body}</Body>

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.exampleHeader}
      </Heading4>

      <AddEventDiagram />

      <Heading4 margin={['xl', null, 'm', null]} variant={Variant.primary}>
        {text.chooseConnectionHeader}
      </Heading4>

      <Body>{text.chooseConnectionBody}</Body>

      <EventTemplatesContainer
        selectedTemplates={selectedEvents}
        onChange={events => setSelectedEvents(events)}
      />
      <TipBanner id="add-event-tip">{text.tip}</TipBanner>

      <JustificationContainer
        justification="space-between"
        align="center"
        margin={['l', null, null]}
      >
        {!disableClosing && (
          <TextButton
            label="Afbreken"
            onClick={onClose}
            withoutPadding
            appearance="danger"
            dataTestId="exit-wizard-button"
          />
        )}
        <Button
          icon="arrowRight"
          label="Volgende stappen"
          onClick={onNext}
          size="medium"
          dataTestId={TEST_ID.NEXT_BUTTON}
          loading={loading}
        />
      </JustificationContainer>
    </AppDetailsContainer>
  );
};

export default AddEvent;
