import React from 'react';
import { useTheme } from 'styled-components';
import Icon, { type IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body } from '~/components/atom/Typography';
import type { Size } from '~/styles/constants';

export type SaveBarMessage<T = {}> = {
  /* Used to link to the field that has an error. */
  url?: string;

  /* Name of the field or any other uniq key */
  key: string | null;

  /* The error message itself */
  message: string | null;

  /** If not passed, it will be danger */
  type: 'danger' | 'warning' | 'success' | 'info';
} & T;

export type Props = {
  messages: Array<SaveBarMessage>;
  size?: Size;
};

const MessagesContainer: React.FCC<Props> = ({ messages, size }) => {
  const theme = useTheme();

  const appearance = messages[0]?.type || 'danger';
  const message = messages[0];

  return (
    <JustificationContainer
      width="100%"
      align="center"
      margin={[null, 'm', null, 'xxs']}
      gap="xxs"
      dataTestId={`savebar-message-${message.key}`}
    >
      <Icon
        style={{ marginBottom: theme.space('xxxs') }}
        color={theme.color(appearance)}
        name={getIcon(message.type)}
        strokeWidth={3}
      />
      <Body
        withoutMargin
        color={{ group: appearance }}
        size={size === 'small' ? 's' : 'base'}
      >
        {message.message}&nbsp;
        {message.url && message.type === 'danger' && (
          <a href={message.url}>Bekijk fout</a>
        )}
      </Body>
    </JustificationContainer>
  );
};

const getIcon = (type: SaveBarMessage['type']): IconType => {
  switch (type) {
    case 'danger':
      return 'slash';
    case 'info':
      return 'info';
    case 'warning':
      return 'alert-octagon';
    case 'success':
      return 'check-circle';
    default:
      return 'slash';
  }
};

export default MessagesContainer;
