import styled, { css } from 'styled-components';

type TableWithStyleProps = {
  onClick?: () => void;
};
const Standard = styled.div<TableWithStyleProps>`
  user-select: none;
  border-spacing: 0;

  ${({ onClick }) => {
    if (onClick) {
      return css`
        cursor: pointer;
      `;
    }

    return ``;
  }};
`;

const StandardOuter = styled.div<{}>(
  ({ theme }) => css`
    padding: 0 ${theme.space('xxs')};
    margin-top: ${theme.space('xxl')};
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.s};
  `,
);

export default {
  Standard,
  StandardOuter,
};
