import { HeadingTwoElement } from '~/components/organism/PluginsEditor/types';

/**
 * serializes the OrderedListElement into html element
 * @param {HeadingTwoElement} node - HeadingTwoElement
 */
const serialize = (node: HeadingTwoElement, children: string): string =>
  `<h2>${children}</h2>`;

export default serialize;
