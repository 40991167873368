import React, { useCallback, useState } from 'react';
import Div from '~/components/atom/Div';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import SearchInput from '~/components/molecule/SearchInput';
import { useMap } from 'react-map-gl';
import useErrorReporter from '~/hooks/useErrorReporter';
import { isNil } from 'ramda';
import Catalog from '~/Catalog';
import type { OptionOf } from '~/components/molecule/Dropdown';

type GeocodingFeature = {
  // An object describing the spatial geometry of the returned feature
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  properties: {
    // ID associated with the feature
    mapbox_id: string;
    // Name of the feature
    name: string;
    // A formatted string of result context comprised of the place, region, country, and postcode
    place_formatted?: string;
  };
};

export type Props = {
  mapboxAccessToken: string;
};

const NO_RESULT_OPTION = {
  key: 'no-results',
  label: Catalog.noResults,
  payload: null,
  type: 'DISABLED' as const,
};

const GEOCODING_V6_FORWARD_URL =
  'https://api.mapbox.com/search/geocode/v6/forward';

// Get results closest to the user's IP location
const PROXIMITY = 'ip';
const LANGUAGE = 'NL';
const LIMIT = 7;

const GeocodeControl: React.FCC<Props> = ({ mapboxAccessToken }) => {
  const errorReporter = useErrorReporter();

  const [options, setOptions] = useState<
    Array<OptionOf<GeocodingFeature | null>>
  >([]);
  const [loading, setLoading] = useState(false);

  const { current: map } = useMap();

  const onSearch = useCallback(
    async (query: string) => {
      if (query.length === 0) {
        return setOptions([]);
      }

      setLoading(true);

      const url = `${GEOCODING_V6_FORWARD_URL}?q=${query}&access_token=${mapboxAccessToken}&language=${LANGUAGE}&proximity=${PROXIMITY}&limit=${LIMIT}`;

      try {
        const res = await fetch(url);

        if (!res.ok) {
          throw new Error(`Geocoding API error: ${res.statusText}`);
        }

        const data = await res.json();
        const features = data.features as Array<GeocodingFeature>;

        if (features.length > 0) {
          setOptions(
            features.map(feature => ({
              key: feature.properties.mapbox_id,
              payload: feature,
              label:
                feature.properties.name +
                (feature.properties.place_formatted
                  ? ` - ${feature.properties.place_formatted}`
                  : ''),
            })),
          );
        } else {
          setOptions([NO_RESULT_OPTION]);
        }
      } catch (error) {
        setOptions([]);
        errorReporter.captureException(error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapboxAccessToken],
  );

  const onSelectOption = (selectedFeature: GeocodingFeature) => {
    setOptions([]);

    if (selectedFeature) {
      const location =
        selectedFeature.geometry?.type === 'Point'
          ? selectedFeature.geometry.coordinates
          : null;

      if (map && !isNil(location)) {
        map.flyTo({
          center: location,
          zoom: 11,
          speed: 1.5,
          // Center it between Aside and left side
          offset: [-150, 0],
        });
      }
    }
  };

  return (
    <>
      <Div width="300px" padding={['s']}>
        <SearchInput onFilterChange={onSearch} />
        <DropdownListContainer
          options={options}
          dropdownListOpen={options?.length > 0}
          onChange={({ selectedOptionIdx }) => {
            const selectedOption = options[selectedOptionIdx];
            if (selectedOption && selectedOption.payload !== null)
              onSelectOption(selectedOption.payload);
          }}
          onClickOutside={() => setOptions([])}
          withDefaultFirstOption
          loading={loading}
        />
      </Div>
    </>
  );
};

export default GeocodeControl;
