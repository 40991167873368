import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
Met de app van ProPropertyPartners bied je zaken als water, energie, televisie, internet, telefonie en verzekering (zoals WA-, zorg- of inboedelverzekering) aan op jouw website.

**Verdien een aantrekkelijke commissie** zodra via jouw website nutsvoorzieningen worden aangevraagd. [Commissies staan hier gepubliceerd](https://www.flipsnack.com/propropertypartners/ppp-catalogus-incl-mlm-en-pp-formulieren/full-view.html) en worden automatisch maandelijks gecrediteerd op jouw DatHuis factuur.

ProPropertyPartners wordt gebruikt door meer dan 2000 vastgoedprofessionals en bedient jaarlijks meer dan 15.000 bewoners, waaronder veel expats die verhuizen naar of wonen in Nederland. Je klant betaalt nooit meer, vaak zelfs minder en krijgt dezelfde kortingen/acties, of net iets meer, dan direct bij de leverancier. Maatwerk tegen scherpe tarieven en goede voorwaarden.

## Installatie
Nadat je de app hebt geactiveerd, worden nutsvoorzieningen automatisch getoond in de widget op je website.`;

const termsOfUse = `Door het activeren van deze app word je klant van ProPropertyPartners (Arx Groep Business 2 Business B.V.) en geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van ProPropertyPartners. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Voor het gebruik van ProPropertyPartners zijn de gebruiksvoorwaarden van Arx Groep Business 2 Business B.V. van toepassing. Commissies die je verdient met ProPropertyPartners worden door DatHuis periodiek uitgekeerd. Meer informatie over ProPropertyPartners vind je op https://propropertypartners.com. Een overzicht van commissies [vind je hier.](https://www.flipsnack.com/propropertypartners/ppp-catalogus-incl-mlm-en-pp-formulieren/full-view.html)`;

export const arxGroep: AppConfig = {
  __typename: 'AppStatus_ARXGroep',
  appType: AppType.Arxgroep,
  type: 'activate_only',
  slug: 'arx-groep',
  name: 'Nutsvoorzieningen - ProPropertyPartners',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Commissie ontvangen'],
  provider: 'ProPropertyPartners',
  logo: {
    src: 'https://dathuis-stash.imgix.net/ProPropertyPartners.png',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/ProPropertyPartners.png',
  },
  price: null,
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  help: {
    href: 'https://help.dathuis.nl/nl/articles/6281885-over-de-nutsvoorzieningen-propropertypartners-app',
  },
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'default',
};
