import React from 'react';
import Pin from '../Pin';
import SelectableRow from '../SelectableRow';
import {
  PinnedApp,
  PinnedAppsMap,
} from '~/components/page/Widget/state/pinnedApps';
import { AccordionGroup } from '../..';
import generatePinnedItem from '../../utils/generatePinnedItem';
import { pinOptions } from '../../__fixtures__';
import { PinAction } from '../Group';

export type Props = {
  items: Array<AccordionGroup>;

  /** Checked state of the group */
  checked: boolean;

  /** Removes checkboxes to only display text */
  selectable: boolean;

  onPin: (type: PinAction, item: PinnedApp) => void;

  onItemChange: (id: string, value: boolean) => void;

  pinnedApps: PinnedAppsMap;
};

const GroupItems: React.FCC<Props> = ({
  pinnedApps,
  items,
  onPin,
  selectable,
  checked: mainChecked,
  onItemChange,
}) => (
  <>
    {items.map(({ name, id, checked }) => {
      const pinnedItem = pinnedApps[id]
        ? { id, pinned: pinnedApps[id] }
        : undefined;

      const selectedOption = pinOptions.find(
        option => option.value === pinnedItem?.pinned.weight,
      );

      return (
        <SelectableRow
          row={{
            name,
            id,
            checked,
          }}
          checkboxTitle="App"
          key={id}
          onChange={value => onItemChange(id, value)}
          selectable={selectable}
          disabled={!mainChecked}
        >
          <Pin
            disabled={!checked || !mainChecked}
            selectedOption={selectedOption}
            options={pinOptions}
            onClick={(type, weight) => {
              const itemToPin = generatePinnedItem({ id, weight });
              onPin(type, itemToPin);
            }}
          />
        </SelectableRow>
      );
    })}
  </>
);

export default GroupItems;
