import React, { useRef, useEffect } from 'react';
import { PasswordField } from '~/components/organism/Forms';
import FormUtils from '~/components/organism/FormUtils';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Input from '~/components/molecule/Input';
import PasswordRules, { type PasswordConditions } from '../PasswordRules';

type Props = {
  submitting: boolean;
  passwordText?: string;
  repeatText?: string;
  passwordConditions: PasswordConditions;
  isFocused?: boolean;
  disabled?: boolean;
};

const PasswordInputGroup: React.FCC<Props> = ({
  passwordConditions,
  submitting,
  passwordText,
  repeatText,
  isFocused,
  disabled,
}) => {
  const passwordRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isFocused) {
      if (passwordRef.current) {
        passwordRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <JustificationContainer width="100%" margin={['m', null]}>
        <PasswordField name="password">
          {({ input, meta: { error, touched } }) => (
            <Input
              {...input}
              width="100%"
              size="large"
              label={passwordText ? passwordText : 'Wachtwoord'}
              type="password"
              disabled={submitting || disabled}
              ref={passwordRef}
              externalErrors={
                error && touched
                  ? [FormUtils.showError(error, touched)]
                  : undefined
              }
              dataTestId={TEST_ID.PASSWORD_INPUT_FIELD}
            />
          )}
        </PasswordField>
      </JustificationContainer>

      <PasswordRules passwordConditions={passwordConditions} />
      <JustificationContainer width="100%" margin={['m', null]}>
        <PasswordField name="passwordRepeat">
          {({ input, meta: { error, touched } }) => (
            <Input
              {...input}
              width="100%"
              size="large"
              label={repeatText ? repeatText : 'Herhaal wachtwoord'}
              type="password"
              disabled={submitting || disabled}
              externalErrors={
                error && touched
                  ? [FormUtils.showError(error, touched)]
                  : undefined
              }
              dataTestId={TEST_ID.REPEAT_PASSWORD_INPUT_FIELD}
            />
          )}
        </PasswordField>
      </JustificationContainer>
    </>
  );
};

export default PasswordInputGroup;
