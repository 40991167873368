import React from 'react';
import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useCollapseAnimation from '~/hooks/useCollapseAnimation';

import { BaseColor, SystemColorPalette } from '~/theme/System/tokens';

export type Props = {
  header: React.ReactNode;
  isOpen: boolean;
  variant?: keyof SystemColorPalette;
  disabled?: boolean;

  /** By default it is true. When it is a checkbox (like in RealworksSelectableAccordion) the whole header should not be clickable */
  headerClickable?: boolean;

  /** Function to pass to header and chevron clicks that opens and closes the children block */
  onToggle: () => void;
};

const CollapsibleChevronBlock: React.FCC<Props> = ({
  dataTestId,
  header,
  variant = 'primary',
  children,
  isOpen,
  disabled,
  onToggle,
  headerClickable = true,
  ...rest
}) => {
  const { innerRef, outer, inner } = useCollapseAnimation({
    isOpen,
  });

  return (
    <Container
      data-testid={dataTestId}
      {...rest}
      color={disabled ? 'grey' : variant}
    >
      <HeaderContainer
        justification="space-between"
        align="center"
        padding={isOpen ? ['m', 'm', null, 'm'] : ['m']}
        onClick={() => !disabled && headerClickable && onToggle()}
        $hasClick={headerClickable || false}
      >
        {header}

        <Chevron
          name="chevron"
          strokeWidth={2.5}
          variant={variant}
          onClick={e => {
            e.stopPropagation();
            if (!disabled) onToggle();
          }}
          flipX={isOpen}
          $disabled={disabled}
        />
      </HeaderContainer>

      <animated.div
        style={{
          opacity: outer.opacity,
          height: outer.height,
          overflow: isOpen ? 'unset' : 'hidden',
        }}
      >
        <animated.div ref={innerRef} style={{ y: inner.y }}>
          <ChildrenContainer>{children}</ChildrenContainer>
        </animated.div>
      </animated.div>
    </Container>
  );
};

const Container = styled.div<{ color: BaseColor }>`
  ${({ color, theme }) => css`
    margin: ${theme.space('xxxs')} 0;
    background-color: ${theme.color(color, 'translucent')};
    border-radius: ${theme.getTokens().border.radius.s};
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  `}
`;

const HeaderContainer = styled(JustificationContainer)<{
  $hasClick: boolean;
  $disabled?: boolean;
}>(
  ({ theme, $hasClick, $disabled }) => css`
    ${theme.mq.lessThan('mobile')`
      flex-direction: row;
    `};

    ${$hasClick &&
    css`
      cursor: ${$disabled ? 'auto' : 'pointer'};
    `}
  `,
);

export const Chevron = styled(Icon)<{
  $disabled?: boolean;
  variant?: keyof SystemColorPalette;
}>(
  ({ $disabled, theme, variant = 'primary' }) => css`
    cursor: ${$disabled ? 'auto' : 'pointer'};
    color: ${theme.color($disabled ? 'grey' : variant)};
    font-size: 1.5rem;

    /** Because of use spring's initial value it is not accessible without z-index */
    z-index: 100;
  `,
);

const ChildrenContainer = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('base')};
  `,
);

export default CollapsibleChevronBlock;
