import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { Helmet as MetaTags } from 'react-helmet';
import Catalog from '~/Catalog';
import EmailNotificationsBlock, {
  EmailNotificationsFormData,
} from '~/components/organism/EmailNotificationsBlock';
import { ListItemForDeletion } from '~/components/bad/Modals/components/ListItemCard';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import withErrorBoundary from '~/ErrorBoundary';
import useCurrentUser from '~/hooks/useCurrentUser';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useUsers from '~/hooks/useUsers';
import SynchronisedMailboxesBlock from './components/SynchronisedMailboxesBlock';
import { emailNotificationsFormDataToMutation } from '~/components/organism/EmailNotificationsBlock/util';
import {
  useGetUserQuery,
  UserRole,
  UserStatus,
  useUpdateMyDetailsMutation,
  useUpdateOfficeRelationMutation,
} from '~/graphql/types';
import AppErrorScreen from '~/components/template/AppErrorScreen';
import Loading from '~/components/atom/Loading';
import { isInitialLoadStatus } from '~/graphql/ApolloConstants';
import ProfileHeader from './components/ProfileHeader';
import FormContainer from './components/FormContainer';
import SignatureContainer from '~/components/organism/SignatureContainer';
import DeleteUserFromOfficeModal from '~/components/bad/Modals/DeleteUserFromOfficeModal';
import { asUserDetailsFormData } from './utils/asUserDetailsFormData';
import { convertOfficesList } from './utils/convertOfficesList';
import { createOfficeItemForModal } from './utils/createOfficeItemForModal';
import useIsMounted from '~/hooks/useIsMounted';
import { SectionContainer } from './Profile.style';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import useSessionHydration from '~/hooks/useSessionHydration';
import usePermissions from '~/hooks/usePermissions';

export type UserDetailsFormData = {
  name: string;
  email: string;
  phone: string;
  selectedMainOfficeId: string;
};

type Props = {
  hasError: boolean;
};

const text = {
  pageTitle: 'Profiel',
  imageUploadError:
    'Er is iets mis gegaan bij het uploaden van het bestand. Probeer het nog een keer.',
  phoneValidation: Catalog.invalidPhone,
  userUpdateError: Catalog.genericUnknownErrorMessage,
  title: 'Profiel',
  submitBtn: 'Wijzig wachtwoord',
  deleteLabel: 'Verwijder profiel',
  signature: 'Handtekening gebruiker',
  notificationSuccessMessage: 'E-mail notificaties succesvol geüpdate',
};

const Profile: React.FCC<Props> = ({ hasError }) => {
  const [, refetchSessionHydration] = useSessionHydration();
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);
  const [cachedOfficeId, setCachedOfficeId] = useState<string | null>(null);
  const [cachedPhone, setCachedPhone] = useState<string | null>(null);
  const [showDeleteUserFromOfficeModal, setShowDeleteUserFromOfficeModal] =
    useState<Array<ListItemForDeletion> | null>(null);
  const addToast = useAddToast();
  const hasEmailPermission = usePermissions(['root.email.user']);

  const [updateMyDetailsMutation, { error: detailsMutationError }] =
    useUpdateMyDetailsMutation();
  const [updateOfficeRelationMutation, { error: officeMutationError }] =
    useUpdateOfficeRelationMutation();

  const me = useCurrentUser();

  const { id: accountId } = useCurrentAccount();

  const { data, networkStatus, refetch, startPolling, stopPolling } =
    useGetUserQuery({
      variables: {
        userId: me.id,
        accountId,
      },
    });

  const accountAdmins = useUsers().filter(
    user => user.role === UserRole.Admin && user.__typename !== 'DeletedUser',
  );

  const isLastAccountAdmin =
    accountAdmins.length === 1 && accountAdmins[0].id === me.id;

  const pollingTimeout: ReturnType<typeof setTimeout> | null = null;
  let isPolling: boolean = false;
  const isComponentMounted = useIsMounted();

  useEffect(
    () => () => {
      if (pollingTimeout) {
        clearTimeout(pollingTimeout);
      }
    },
    [],
  );

  const onSetInitialDataLoaded = () => {
    setInitialDataLoaded(true);
  };

  const onShowErrorMessage = (msg: string) =>
    addToast([formatToastMessage(msg, 'danger')]);

  const onSetCachedData = (data: {
    cachedPhone?: string | null;
    cachedOfficeId?: string | null;
  }) => {
    setCachedOfficeId(
      data.cachedOfficeId === undefined ? cachedOfficeId : data.cachedOfficeId,
    );
    setCachedPhone(
      data.cachedPhone === undefined ? cachedPhone : data.cachedPhone,
    );
  };

  const onUserUpdateError = () => {
    onShowErrorMessage(text.userUpdateError);
    onSetCachedData({
      cachedPhone: null,
    });
  };

  const handleDeleteUserFromOfficeModalAction = (
    officesList: Array<ListItemForDeletion> | null,
  ) => {
    setShowDeleteUserFromOfficeModal(officesList);
  };

  if (hasError) return <AppErrorScreen />;
  if (isInitialLoadStatus(networkStatus)) return <Loading />;
  if (!data || !data.getUser) return <AppErrorScreen />;

  const user = data.getUser;

  const mailboxes = user.Mailboxes;
  const signature = user.Signature ?? null;

  const refetchUser = () => processRefetch();

  const onRelationMutationError = () => {
    void processRefetch();
    onSetCachedData({ cachedOfficeId: null });
    onShowErrorMessage(text.userUpdateError);
  };

  const processRefetch = async () => {
    if (!initialDataLoaded) onSetInitialDataLoaded();

    const { data } = await refetch();
    const { getUser } = data;
    if (getUser === null) {
      return;
    }

    if (cachedPhone && cachedPhone === getUser?.phone) {
      onSetCachedData({
        cachedPhone: null,
      });
    }

    const updatedCachedOffice = getUser?.Offices.find(
      item => item.id === cachedOfficeId,
    );

    if (
      updatedCachedOffice != null &&
      updatedCachedOffice.relation != null &&
      updatedCachedOffice.relation.mainOffice === true
    ) {
      onSetCachedData({
        cachedOfficeId: null,
      });
    }

    if (!isComponentMounted) return;

    await refetchSessionHydration();
  };

  if (detailsMutationError) onUserUpdateError();

  const updateMyName = (name: string) =>
    updateMyDetailsMutation({
      variables: {
        accountId,
        update: {
          name,
        },
      },
    });

  const updateUserDetails = (newFormData: UserDetailsFormData) => {
    const { phone, name } = newFormData;

    onSetCachedData({
      cachedPhone: phone,
    });

    return updateMyDetailsMutation({
      variables: {
        accountId: accountId,
        update: {
          name,
          phone,
        },
      },
    });
  };

  const updateEmailNotifications = (
    newFormData: EmailNotificationsFormData,
  ) => {
    const vars = emailNotificationsFormDataToMutation(newFormData);

    return updateMyDetailsMutation({
      variables: {
        accountId,
        update: vars,
      },
    }).then(data => {
      // We only check for success here, because failure is checked within `detailsMutationError`
      if (data) {
        addToast([
          formatToastMessage(text.notificationSuccessMessage, 'success'),
        ]);
      }
    });
  };

  const userIsBeingDeletedFromOffice = user.Offices.some(
    office => office.relation?.status === UserStatus.Deleting,
  );

  if (userIsBeingDeletedFromOffice) {
    if (isPolling === false) {
      startPolling(3000);
      isPolling = true;
    }
  }

  if (!userIsBeingDeletedFromOffice) {
    if (isPolling === true) {
      stopPolling();
      isPolling = false;
    }
  }

  if (officeMutationError) onRelationMutationError();

  const updateMainOffice = (officeId: string): Promise<any> => {
    const update = { mainOffice: true };

    onSetCachedData({
      cachedOfficeId: officeId,
    });

    return updateOfficeRelationMutation({
      variables: {
        accountId,
        officeId,
        userId: me.id,
        update,
      },
    });
  };

  const userFormData = {
    email: user ? user.email.toLowerCase() : '',
    phone: cachedPhone || user ? user.phone : '',
    cachedOfficeId,
  };

  const initialUserDetailValues = asUserDetailsFormData(user);

  const onUserDetailsFormSubmit = (formNewData: UserDetailsFormData) => {
    void updateUserDetails(formNewData);

    // check is office role realy changed and call mutation if true
    const selectedOfficeId = formNewData.selectedMainOfficeId;
    const officeInitial = user.Offices.find(
      item => item.id === selectedOfficeId,
    );

    if (officeInitial) {
      if (
        officeInitial.relation &&
        officeInitial.relation.mainOffice !== true
      ) {
        return updateMainOffice(selectedOfficeId); // id of changed office
      }
    }
    return;
  };

  const onEmailNotificationsFormSubmit = (
    formNewData: EmailNotificationsFormData,
  ) => updateEmailNotifications(formNewData);

  return (
    <ContentContainerDefault>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <section data-testid="user-profile-page">
        <ProfileHeader
          user={user}
          me={me}
          refetchUser={refetchUser}
          isLastAccountAdmin={isLastAccountAdmin}
          updateMyName={updateMyName}
        />
        <SectionContainer>
          <LeftContainer>
            <FormContainer
              userFormData={userFormData}
              officesList={convertOfficesList(user)}
              onUserDetailsFormSubmit={onUserDetailsFormSubmit}
              initialUserDetailsValues={initialUserDetailValues}
              showDeleteModal={officeId =>
                handleDeleteUserFromOfficeModalAction([
                  createOfficeItemForModal(user.Offices, officeId),
                ])
              }
              mayEdit={user.id === me.id}
              dataIsPolling={isPolling}
              refetch={refetch}
              signature={signature}
              userId={me.id}
            />

            {hasEmailPermission.allowed && (
              <SignatureContainer
                title={text.signature}
                signature={signature}
                refetch={refetch}
                entityTypeForMutation={{ userId: me.id }}
              />
            )}
          </LeftContainer>

          <div>
            {hasEmailPermission.allowed && (
              <SynchronisedMailboxesBlock
                mailboxes={mailboxes}
                startPolling={startPolling}
                stopPolling={stopPolling}
                refetch={() => refetch().then(() => refetchSessionHydration())}
              />
            )}

            <EmailNotificationsBlock
              onFormSubmit={onEmailNotificationsFormSubmit}
              notificationSettings={user.NotificationSettings}
            />
          </div>

          {showDeleteUserFromOfficeModal && (
            <DeleteUserFromOfficeModal
              list={showDeleteUserFromOfficeModal}
              onCancel={() => handleDeleteUserFromOfficeModalAction(null)}
              onClose={() => handleDeleteUserFromOfficeModalAction(null)}
              refetch={refetchUser}
              userId={me.id}
            />
          )}
        </SectionContainer>
      </section>
    </ContentContainerDefault>
  );
};

const LeftContainer = styled.div<{}>`
  ${({ theme }) => css`
    margin-right: ${theme.space('m')};

    ${theme.mq.greaterThan('tablet')`
      margin: 0;
    `}
  `}
`;

export default withErrorBoundary(Profile);
