import React from 'react';
import qs from 'query-string';
import { SELECTED_ACCOUNT_STORAGE_KEY } from '~/util/localStorageKeys';
import DropdownMenu from '../DropdownMenu';
import { State } from '../MobileMenu/index';
import MobileDropdownMenu from '../MobileDropdownMenu';
import useAccounts from '~/hooks/useAccounts';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import { SelectedOption } from '~/components/molecule/Dropdown';
import Divider from '../Divider';

type Props = {
  onDropdownOpen?: (props: State) => void;
  divider?: boolean;
};

const AccountsMenu: React.FCC<Props> = ({
  onDropdownOpen,
  divider = true,
  dataTestId,
}) => {
  const account = useCurrentAccount();
  const accounts = useAccounts();

  // don't show this menu when there is only 1 account
  if (account == null || accounts.length <= 1) return null;

  const { name, id } = account;

  const options = accounts.map(account => ({
    label: account.name,
    illustration: 'briefcase',
    payload: account.id,
    key: account.id,
  }));

  const selectedOptionIdx = accounts.findIndex(x => x.id === id);
  const onChange = (selectedOption: SelectedOption) => {
    const { option } = selectedOption;
    if (option.payload === id) return;

    global.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, option.payload);

    // We cannot switch account with the current filters because the next account might not have these filters
    // e.g. Zapier event IDs
    const locationWithoutFilters = qs.exclude(global.window.location.href, [
      'filters',
    ]);

    // Force reload
    global.window.location.href = locationWithoutFilters;
  };

  const label = name && name.length > 15 ? `${name.substr(0, 15)}...` : name;

  if (onDropdownOpen)
    return (
      <MobileDropdownMenu
        icon="briefcase"
        label={label}
        onClick={() => onDropdownOpen({ options, onChange })}
      />
    );

  return (
    <>
      {divider && <Divider />}
      <DropdownMenu
        options={options}
        onChange={onChange}
        label={label}
        selectedOptionIdx={selectedOptionIdx}
        dataTestId={dataTestId}
      />
    </>
  );
};

export default AccountsMenu;
