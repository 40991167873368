import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Variant, Heading5 } from '~/components/atom/Typography';
import HighlightedHeader from './components/HighlightedHeader';
import illustrations from '~/components/atom/Illustration/components';
import { WidgetSettingsStyles } from '~/graphql/types';

export type Props = {
  dataTestId?: string;
  header?: React.ReactNode;
  description?: React.ReactNode;
  illustration?: keyof typeof illustrations;
  footer?: React.ReactNode;
  colors?: Omit<WidgetSettingsStyles, '__typename'>;
};

const WidgetTriggerPreview: React.FC<Props> = ({
  dataTestId,
  header = 'Wat is mijn huis waard?',
  description = 'Direct een Waardecheck ontvangen.',
  illustration,
  footer = 'Bekijk ook onze andere handige tools',
  colors,
  ...rest
}) => (
  <Container {...rest} data-testid={dataTestId}>
    <HighlightedHeader
      header={header}
      description={description}
      illustration={illustration}
      colors={colors}
    />
    <TriggerFooter
      align="start"
      justification="space-between"
      padding={['m']}
      $color={colors?.primary.color}
    >
      <StyledHeading5
        variant={Variant.primary}
        fontWeight="regular"
        withoutMargin
        $color={colors?.primary.background}
      >
        {footer}
      </StyledHeading5>
      <Icon color={colors?.primary.background} name="arrowRight" />
    </TriggerFooter>
    <CloseButton
      $background={colors?.secondary.background}
      $color={colors?.secondary.color}
    >
      <Icon name="close" />
    </CloseButton>
  </Container>
);

const Container = styled.div<{}>(
  ({ theme }) => css`
    position: relative;
    border-radius: ${theme.getTokens().border.radius.base};
    width: 100%;
    max-width: 288px;
    height: 100%;
    /* max-height: 170px; */
    box-shadow: ${theme.boxShadow('s')};
    margin: ${CLOSE_BUTTON_SIZE}px ${theme.space('base')} 0
      ${theme.space('base')};

    display: flex;
    flex-direction: column;
  `,
);

const StyledHeading5 = styled(Heading5)<{ $color?: string }>(
  ({ theme, $color }) => css`
    margin-right: ${theme.space('m')};
    color: ${$color};
    line-height: ${theme.lineHeight('base')};
  `,
);

const TriggerFooter = styled(JustificationContainer)<{ $color?: string }>(
  ({ theme, $color }) => css`
    height: 65px;
    background: ${$color};
    border-radius: 0 0 ${theme.getTokens().border.radius.base}
      //@ts-ignore (complaining about semi-colons because the line is too long)
      ${theme.getTokens().border.radius.base};

    svg {
      width: 1.2em;
      height: 1.2em;
    }
  `,
);

export const CLOSE_BUTTON_SIZE = 32;
export const CLOSE_BUTTON_POSITION = 16;

const CloseButton = styled.div<{ $background?: string; $color?: string }>(
  ({ theme, $background, $color }) => css`
    position: absolute;
    width: ${theme.space('xl')};
    height: ${theme.space('xl')};
    padding: 4px;
    border-radius: ${theme.getTokens().border.radius.full};
    background: ${$background};
    display: flex;
    justify-content: center;
    align-items: center;
    top: -${CLOSE_BUTTON_POSITION}px;
    right: -${CLOSE_BUTTON_POSITION}px;

    svg {
      color: ${$color};
      height: auto;
      width: auto;
    }
  `,
);

export default WidgetTriggerPreview;
