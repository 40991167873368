import { BreadcrumbType } from './components/Breadcrumbs';

import React from 'react';
import styled, { css } from 'styled-components';
import Breadcrumbs from './components/Breadcrumbs';
import { MAIN_CONTENT_WIDTH } from '~/components/organism/NavigationFrame/constants';
import WarningsContainer from '~/components/organism/WarningsContainer';

type Props = {
  style?: $Object;
  children: React.ReactNode;

  /** Set the max-width of the content container, default is MAIN_CONTENT_WIDTH */
  maxContentWidth?: string;

  /** The breadcrumbs to display */
  breadcrumbs?: Array<BreadcrumbType>;

  /** The testId */
  'data-testid'?: string;

  /** The test object id */
  'data-objectid'?: string;

  /** Toggles WarningsContainer visibility */
  showWarnings?: boolean;
};
const ContentContainerDefault = React.forwardRef<HTMLDivElement, Props>(
  ({ children, breadcrumbs, showWarnings = true, ...rest }, ref) => {
    const hasBreadcrumbs = breadcrumbs && breadcrumbs.length !== 0;

    const breadcrumbContainer = (
      <BreadcrumbContainer fullHeight={hasBreadcrumbs}>
        {hasBreadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
      </BreadcrumbContainer>
    );

    return (
      <ContentContainerDefaultContainer ref={ref}>
        <ContentContainerDefaultContent {...rest}>
          {showWarnings && <WarningsContainer />}
          {breadcrumbContainer}
          {children}
        </ContentContainerDefaultContent>
      </ContentContainerDefaultContainer>
    );
  },
);

const BREADCRUMB_HEIGHT = '72px';
const BREADCRUMB_MOBILE_HEIGHT = '50px';

const BreadcrumbContainer = styled.div<{ fullHeight?: boolean }>`
  display: flex;
  align-items: center;
  ${({ theme, fullHeight }) => css`
    height: ${fullHeight ? BREADCRUMB_HEIGHT : theme.space('xl')};

    ${theme.mq.lessThan('tabletLandscape')`
      height: ${BREADCRUMB_MOBILE_HEIGHT};
    `}
  `};
`;

const ContentContainerDefaultContainer = styled.div<{}>`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  ${({ theme }) => css`
    margin: 0 ${theme.space('xxxl')};
  `};
`;

type ContentContainerDefaultContentProps = {
  maxContentWidth?: string;
};

const ContentContainerDefaultContent = styled.div<ContentContainerDefaultContentProps>`
  width: 100%;

  ${({ theme, maxContentWidth }) => {
    const maxWidth = maxContentWidth || `${MAIN_CONTENT_WIDTH}px`;

    return css`
      max-width: ${maxWidth};
      padding-bottom: ${theme.space('m')};
    `;
  }}
`;

export default ContentContainerDefault;
