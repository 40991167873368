import React from 'react';
import { useContext } from 'react';
import ModalContext from '~/contexts/ModalContext';
import UpdatePaymentMethod from '../../components/UpdatePaymentMethod';

type Args = {
  onComplete?: () => void;
};
const useOnUpdatePaymentMethod = ({ onComplete }: Args) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const onUpdatePaymentMethod = () =>
    showModal(
      <UpdatePaymentMethod
        onClose={hideModal}
        onComplete={() => {
          hideModal();
          if (onComplete) onComplete();
        }}
      />,
    );

  return onUpdatePaymentMethod;
};

export default useOnUpdatePaymentMethod;
