import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Dropdown, {
  Option,
  SelectedOptionOf,
} from '~/components/molecule/Dropdown';
import Loading from '~/components/atom/Loading';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import convertTemplateStringToSlateFragment from '~/components/organism/PluginsEditor/utils/flows/convertTemplateStringToSlate';
import {
  FlowV2_Action_Realworks_SendContactFragment,
  useGetAppStatusRealworksQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useEditorStates from '~/components/organism/PluginsEditor/hooks/useEditorStates';
import useRelativeMaps from '~/components/page/Automation/v2/components/Builder/hooks/useRelativeMaps';
import InputLabel from '~/components/page/Apps/components/InputLabel';
import ErrorScreen from '~/components/page/ErrorScreen';
import getActiveTokenOptions from '~/util/getActiveTokenOptions';
import { Props as FormProps } from '../ActionForm';
import PluginsEditor from '~/components/organism/PluginsEditor';
import withHtml from '~/components/organism/PluginsEditor/plugins/withHtml';
import JustificationContainer from '~/components/atom/JustificationContainer';
import TEST_ID from './index.testid';
import TextEditorContainer from '../TextEditorContainer';
import InputLabelWithHelpLink from '~/components/molecule/InputLabelWithHelpLink';
import type { EditorValue } from '~/components/organism/PluginsEditor/types';
import { REALWORKS_EDITOR_BUTTONS } from './constants';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Realworks_SendContactFragment;
};

type State = {
  description: FlowV2_Action_Realworks_SendContactFragment['description'];
  tokenContainerId: FlowV2_Action_Realworks_SendContactFragment['tokenContainerId'];
};

const text = {
  dropdownLabel: 'Vestiging',
  helpLink: 'https://help.dathuis.nl/nl/articles/5447060-over-de-realworks-app',
  description: 'Omschrijving',
};

const RealworksSendContact: React.FCC<Props> = ({ action, onChange }) => {
  const initials = {
    tokenContainerId: action.tokenContainerId,
    description: action.description,
  };

  const [actionDetails, setActionDetails] = useState<State>(initials);
  const { id: accountId } = useCurrentAccount();

  const { data, loading } = useGetAppStatusRealworksQuery({
    variables: {
      accountId,
    },
  });

  const { description, tokenContainerId } = actionDetails;

  const realworksStatus = data?.getAppStatusRealworks;

  useEffect(() => {
    onChange({ ...action, ...actionDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionDetails]);

  const onHandleChange = useCallback(
    (key: string, value: EditorValue | SelectedOptionOf<Array<Option>>) => {
      setActionDetails(prev => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const maps = useRelativeMaps({ actionId: action.id });
  const initialTitleValue = convertTemplateStringToSlateFragment({
    html: description.template || '',
    mappings: description.mappings,
    ...maps,
  });
  const {
    key,
    value,
    onChange: onDescriptionChange,
    hasChanges,
  } = useEditorStates({
    initialValue: initialTitleValue,
  });

  useEffect(() => {
    if (hasChanges) {
      onHandleChange('descriptionValue', value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, hasChanges]);

  if (!data || !realworksStatus) return <ErrorScreen />;
  if (loading) return <Loading />;

  const options = getActiveTokenOptions(realworksStatus);

  return (
    <div>
      <Container>
        <InputLabelWithHelpLink
          label={text.dropdownLabel}
          help={{ link: text.helpLink }}
        />
        <Dropdown
          options={options}
          onChange={e => onHandleChange('tokenContainerId', e.option.payload)}
          selectedOptionIdx={options.findIndex(
            opt => opt.payload == tokenContainerId,
          )}
          dataTestId={TEST_ID.TOKEN_CONTAINER_DROPDOWN}
        />
      </Container>
      <Container>
        <StyledContainer direction="column" margin={['m', null, null, null]}>
          <InputLabel label={text.description} />
          <TextEditorContainer>
            <PluginsEditor
              key={key}
              value={value}
              onChange={onDescriptionChange}
              customElements={[ELEMENTS.VARIABLE]}
              plugins={[
                {
                  name: 'withHtml',
                  fn: e =>
                    withHtml({
                      editor: e,
                      customElements: [ELEMENTS.VARIABLE],
                    }),
                },
              ]}
              dataTestId={TEST_ID.DESCRIPTION}
              minHeight={150}
              toolbarButtons={REALWORKS_EDITOR_BUTTONS}
            />
          </TextEditorContainer>
        </StyledContainer>
      </Container>
    </div>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xxs')} 0;
  `,
);

const StyledContainer = styled(JustificationContainer)`
  width: 100%;
`;

export default RealworksSendContact;
