import React, { useState } from 'react';
import { UserRole } from '~/graphql/types';
import AnimatedBlock from '~/components/atom/AnimatedBlock';
import Button from '~/components/atom/Button';
import TextButton from '~/components/atom/TextButton';
import ConfirmModal from '~/components/template/ConfirmModal';
import useUserOptions from '~/hooks/useUserOptions';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';

type Props = {
  handleToggleDrawer: (open: boolean) => void;
  updateUserRole: (arg0: { role: UserRole; userId: string }) => void;
  isAdmin: boolean;
  loading: boolean;
};

const text = {
  inputLabel: 'Gebruiker',
  linkButton: 'Administrator toevoegen',
  h3: 'Account administrators',
  modalTitle:
    'Weet je het zeker dat je deze gebruiker geen beheerdersrechten meer wilt geven?',
  confirmButtonTitle: 'Ja ik weet het zeker',
};

const AccountAdministrators: React.FC<Props> = ({
  handleToggleDrawer,
  updateUserRole,
  isAdmin,
  loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null | undefined>(null);

  const handleModalAction = (confirm: boolean) => {
    setShowModal(false);

    if (confirm && selectedId) {
      updateUserRole({
        role: UserRole.User,
        userId: selectedId,
      });
    }
  };

  const admins = useUserOptions().filter(
    user => user.payload?.role === UserRole.Admin,
  );

  const showDeleteButton = isAdmin && admins.length > 1;

  return (
    <AnimatedBlock title={text.h3} dataTestId="admin-user-container">
      {showModal && (
        <ConfirmModal
          title={text.modalTitle}
          handleAction={handleModalAction}
          buttonConfirmTitle={text.confirmButtonTitle}
        />
      )}
      {admins.map(admin => (
        <JustificationContainer
          key={admin.payload?.id}
          align="end"
          width="100%"
          margin={['m', null]}
          gap="l"
        >
          <Input
            width="100%"
            size="large"
            label={text.inputLabel}
            type="text"
            disabled={true}
            // We are sure that the label here is the name of the user
            value={admin.label as string}
            data-testid={`admin-input-${admin.payload?.id}`}
            name={`admin-input-${admin.label}`}
          />
          {showDeleteButton && (
            <Button
              ghost
              appearance="danger"
              icon="trashcan"
              size="large"
              dataTestId={`admin-user-delete-button-${admin.payload?.id}`}
              disabled={loading}
              onClick={() => {
                setSelectedId(admin.payload?.id);
                setShowModal(true);
              }}
            />
          )}
        </JustificationContainer>
      ))}
      {isAdmin && (
        <TextButton
          margin={['l', null, null, null]}
          withoutPadding
          size="medium"
          dataTestId="add-new-admin-link"
          disabled={loading}
          onClick={() => handleToggleDrawer(true)}
          label={text.linkButton}
        />
      )}
    </AnimatedBlock>
  );
};

export default AccountAdministrators;
