import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Helmet as MetaTags } from 'react-helmet';
import { v1 as uuidv1 } from 'uuid';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import {
  GetContactsV2Query as GetContactsV2QueryType,
  GetContactsV2QueryVariables,
  SortByAdvancedInput,
  SortFieldAdvanced,
  SortDirection,
  useGetContactsV2Query,
  ContactFilters__Input,
  type SessionHydrationOfficeFieldsFragment,
  type SessionHydrationUserFieldsFragment,
} from '~/graphql/types';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import {
  ContactListData,
  isDeleted,
} from '~/components/page/Contacts/util/composeContactListData';
import { CONTACT_LIST_LIMIT } from '../../constants';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ContactListLeftFilterBar from './ContactListLeftFilterBar';
import Catalog from '~/Catalog';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import contactListColumns, {
  getSortFieldName,
} from '../../util/contactListColumns';
import { isEmptyObject } from '~/util/object';
import TEST_ID from './index.testid';
import Pagination from '~/components/bad/DataTables/components/Pagination';
import serializeFiltersV2 from '~/components/page/Contacts/util/serializeFiltersV2';
import useDebounce from '~/hooks/useDebounce';
import useRowSelect from '~/components/bad/DataTables/useRowSelect';
import { useLocation, navigate } from '@gatsbyjs/reach-router';
import useQueryParams from '~/hooks/useQueryParams';
import useIsMounted from '~/hooks/useIsMounted';
import useCurrentUser from '~/hooks/useCurrentUser';
import ContactsToolBar from './ContactsToolBar';
import TableTotalAmountContainer from '../TableTotalAmountContainer';
import cleanFilters from './util/cleanFilters';
import ContactListTable from '../ContactListTable';
import composeContactListData from '~/components/page/Contacts/util/composeContactListData';
import ContactListFilterBar, { emptyFilterGroupV2 } from './FilterBar';
import useContactFilterOptionsV2 from '~/hooks/useContactFilterOptionsV2';
import { clone, equals, isNil } from 'ramda';
import useSortSettings, { SortSettings } from '~/hooks/useSortSettings';
import { useSetRecoilState } from 'recoil';
import activeFilter from './ContactListLeftFilterBar/components/Segments/state/activeFilter';
import useLimit from '~/hooks/useLimit';
import UpgradePlanCta from '~/components/organism/UpgradePlanCTA';
import getPercentage from '~/util/getPercentage';
import PercentageBar from '~/components/molecule/PercentageBar';
import JustificationContainer from '~/components/atom/JustificationContainer';
import StrapiLink from '~/components/organism/StrapiLink';

export const text = {
  pageTitle: 'Contacten',
  allUsers: 'Alle gebruikers',
  allOffices: 'Alle vestigingen',
  queryError: Catalog.genericUnknownErrorMessage,
  showThisContact: 'Toon dit contact',
  approachingLimitHeader: 'Let op!',
  overLimitHeader: 'Let op!',
  approachingLimitBody:
    'Je bent heel dicht bij de hoeveelheid contacten die in uw huidige plan zijn toegestaan. Upgrade om toegang te krijgen tot meer contacten',
  overLimitBody:
    'Je bent over de hoeveelheid contacten die in uw huidige plan zijn toegestaan. Upgrade om toegang te krijgen tot meer contacten',
  approachingLimitButtonLabel: 'Krijg nu meer contacten',
};

export type ViewableContact = {
  id: string;
  name: string;
  email: string;
  phone: string;
  office?: SessionHydrationOfficeFieldsFragment | null;
  assignedUser?: SessionHydrationUserFieldsFragment | null;
};

type Props = RouteComponentProps<{
  currentPage: number;
}> & {
  storedFilters: ContactFilters__Input;
};

export enum CONTACT_SIDEBAR_STATE {
  MULTIPLE_CONTACTS = 'MULTIPLE_CONTACTS',
  SINGLE_CONTACT = 'SINGLE_CONTACT',
}

export type SortToggleFunction = (
  sortField: SortFieldAdvanced,
  sortDirection: SortDirection | null,
) => void;

type QueryVariablesType = {
  limit: number;
  query: string;
  sortBy: SortByAdvancedInput | null;
};

const getSortBy = (sortSettings: SortSettings): SortByAdvancedInput => ({
  field: getSortFieldName(sortSettings.id),
  direction: sortSettings.desc ? SortDirection.Desc : SortDirection.Asc,
});

const ContactList: React.FCC<Props> = ({
  currentPage: _currentPage,
  storedFilters,
}) => {
  const isMounted = useIsMounted();
  const currentPage = _currentPage && _currentPage > 1 ? _currentPage : 1;
  const [sortSettings, updateSortSettings] = useSortSettings(
    'contactSortSettings',
  );
  const contactsLimit = useLimit('root.contacts');

  const [queryVariables, setQueryVariables] = useState<QueryVariablesType>({
    limit: CONTACT_LIST_LIMIT,
    query: '',
    sortBy: getSortBy(sortSettings),
  });

  const debouncedQueryVariables = useDebounce(queryVariables, 500);
  const account = useCurrentAccount();
  const [version, setVersion] = useState(uuidv1());
  const [key] = useState(uuidv1());
  const tableData = useRef<Array<ContactListData>>([]);

  const me = useCurrentUser();
  const location = useLocation();

  const [filters, setFilters] = useState<ContactFilters__Input>(storedFilters);

  useEffect(() => {
    setFilters(storedFilters);
  }, [storedFilters]);

  const [, setQueryParams] = useQueryParams({
    f: serializeFiltersV2(cleanFilters(filters)),
  });

  const setActiveFilter = useSetRecoilState(activeFilter);

  const selectionProps = useRowSelect();
  const { itemsSelected, allSelected, deselectAllItems } = selectionProps;

  const accountError = !account || !account.id;
  const accountId = account ? account.id : '';

  const variables = {
    ...debouncedQueryVariables,
    filters: cleanFilters(filters),
    accountId,
    // pages are 0 based
    page: currentPage - 1,
    extend: {
      lastActivity: false,
      leadActivity: true, // get lead activity
      metadata: true, // get lead score
    },
  };

  const { data, error, networkStatus, loading, updateQuery, refetch } =
    useGetContactsV2Query({
      variables,
    });

  const { options: filterOptions, loading: filterOptionsLoading } =
    useContactFilterOptionsV2();

  if (filterOptionsLoading) return <DatHuisLoading />;

  if (!loading) {
    tableData.current =
      data == null || isEmptyObject(data)
        ? []
        : composeContactListData(data.getContactsV2.items, variables, version);
  }

  const filteredItemsCount =
    data && data.getContactsV2
      ? data.getContactsV2.total -
        data.getContactsV2.items.filter(isDeleted).length
      : null;

  const contactsMeta = {
    accountId: account ? account.id : '',
    officeId: '',
    userId: me.id,
  };

  const totalContactsInAccount =
    data?.getContactsV2?.totalContactsInAccount || 0;

  const selectedContactAmount = allSelected
    ? filteredItemsCount
    : itemsSelected.length;

  const limitPercentage = getPercentage(
    totalContactsInAccount,
    contactsLimit.limit,
  );

  const approachingLimit = limitPercentage < 100;
  const overLimit = limitPercentage > 100;

  return (
    <>
      <ContentContainerDefault style={{ maxWidth: '1400px' }}>
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>

        <ContactListContainer
          data-testid={TEST_ID.CONTAINER}
          data-queryinflight={loading}
        >
          <ContactsToolBar
            onRefresh={() => refetch()?.then(() => setVersion(uuidv1()))}
            contactsMeta={contactsMeta}
            onDeleteContact={() => {
              deselectAllItems();

              return refetch().then(() => {
                if (!isMounted()) return;

                setVersion(uuidv1());

                if (tableData.current.length === 0 && currentPage !== 1) {
                  const nextPage =
                    currentPage - 1 !== 0
                      ? `/-/contacts/page/${currentPage - 1}`
                      : '/-/contacts/page/1';
                  void navigate(`${nextPage}${location.search}`, {
                    replace: true,
                  });
                }
              });
            }}
            onAddTags={() =>
              refetch().then(() => {
                deselectAllItems();
                setVersion(uuidv1());
              })
            }
            onFilterChange={newQuery => {
              setQueryVariables(prevVariables => ({
                ...prevVariables,
                query: newQuery,
              }));

              deselectAllItems();
              void navigate('/-/contacts/page/1' + location.search);
            }}
            onActionCompleted={deselectAllItems}
            selectedContacts={itemsSelected}
            allSelected={allSelected}
            queryVariables={variables}
            selectedContactAmount={selectedContactAmount || 0}
          />

          <DividerContainer>
            <FilterListContainer>
              <ContactListLeftFilterBar
                onFilterChangeV2={newFilters => {
                  const clonedNewFilters = cleanFilters(newFilters);
                  const encodedFilters = serializeFiltersV2(clonedNewFilters);

                  setQueryParams(
                    {
                      f: encodedFilters,
                    },
                    { next: '/-/contacts/page/1' },
                  );

                  deselectAllItems();
                }}
                currentFilters={filters}
              />
            </FilterListContainer>
            <TableContainer>
              <ContactListFilterBar
                filters={filters}
                onAddFilterGroup={() => {
                  const emptyGroup = emptyFilterGroupV2();
                  const clonedNewFilters = clone({
                    ...filters,
                    nodes: [
                      ...filters.nodes,
                      {
                        ...emptyGroup,
                        Node: {
                          ...emptyGroup.Node,
                          nodes: [{ Leaf: undefined }],
                        },
                      },
                    ],
                  });
                  setFilters(clonedNewFilters);
                }}
                filterOptions={filterOptions}
                onChange={newFilters => {
                  const clonedNewFilters = cleanFilters(newFilters);
                  const encodedFilters = serializeFiltersV2(clonedNewFilters);

                  setQueryParams(
                    {
                      f: encodedFilters,
                    },
                    { next: '/-/contacts/page/1' },
                  );

                  const filtersChanged = !equals(clonedNewFilters, filters);
                  if (filtersChanged) {
                    setActiveFilter({
                      segmentId: null,
                      filterId: null,
                    });
                  }

                  deselectAllItems();
                }}
              />
              {contactsLimit && limitPercentage >= 80 && (
                <UpgradePlanCta
                  header={
                    approachingLimit
                      ? text.approachingLimitHeader
                      : text.overLimitHeader
                  }
                  description={
                    approachingLimit
                      ? text.approachingLimitBody
                      : text.overLimitBody
                  }
                  buttonLabel={text.approachingLimitButtonLabel}
                >
                  <JustificationContainer margin={['xs', null, null, null]}>
                    <StrapiLink
                      label="Leer meer over dit limiet"
                      reason={contactsLimit.reason}
                      color="white"
                    />
                  </JustificationContainer>
                  <JustificationContainer
                    margin={['base', null, null, null]}
                    width="100%"
                  >
                    <PercentageBar
                      currentValue={totalContactsInAccount}
                      total={contactsLimit.limit}
                      color={overLimit ? 'danger' : 'white'}
                      visiblePercentage
                    />
                  </JustificationContainer>
                </UpgradePlanCta>
              )}

              <TableTotalAmountContainer
                filteredItemsCount={filteredItemsCount || 0}
                totalContactsInAccount={totalContactsInAccount || 0}
                selectedContactAmount={selectedContactAmount || 0}
              />

              <ContactListTable
                contactsMeta={contactsMeta}
                columns={contactListColumns((sortField, sortDirection) => {
                  !isNil(sortDirection) &&
                    updateSortSettings({ sortField, sortDirection });
                  setQueryVariables(prevQueryVariables => ({
                    ...prevQueryVariables,
                    sortBy:
                      sortDirection == null
                        ? null
                        : {
                            field: sortField,
                            direction: sortDirection,
                          },
                  }));
                }, key)}
                data={tableData.current}
                networkStatus={networkStatus}
                loading={loading}
                error={error ?? accountError}
                onSuccessfulDelete={contactId => {
                  if (!isMounted()) return;

                  if (tableData.current.length === 1 && currentPage !== 1) {
                    void navigate(
                      `/-/contacts/page/${currentPage - 1}${location.search}`,
                      {
                        replace: true,
                      },
                    );
                  } else {
                    updateQuery(
                      getContactsForQueryUpdateAfterRemoveQueryFunction(
                        contactId,
                      ),
                    );
                    setVersion(uuidv1());
                  }
                }}
                selectionProps={selectionProps}
                totalContactsInAccount={
                  !loading ? totalContactsInAccount : null
                }
              />

              {filteredItemsCount != null && (
                <PaginationContainer>
                  <Pagination
                    totalItems={filteredItemsCount}
                    currentPage={currentPage}
                    itemsPerPage={CONTACT_LIST_LIMIT}
                    onSelect={newPage => {
                      void navigate(
                        `/-/contacts/page/${newPage}${location.search}`,
                        { replace: false },
                      );
                    }}
                  />
                </PaginationContainer>
              )}
            </TableContainer>
          </DividerContainer>
        </ContactListContainer>
      </ContentContainerDefault>
    </>
  );
};

const getContactsForQueryUpdateAfterRemoveQueryFunction =
  (
    contactId: string,
  ): ((
    previousQueryResult: GetContactsV2QueryType,
    options: { variables: GetContactsV2QueryVariables },
  ) => GetContactsV2QueryType) =>
  previousResult => {
    const newList = previousResult.getContactsV2.items.filter(
      contact => contact.id !== contactId,
    );

    return {
      ...previousResult,
      getContactsV2: {
        ...previousResult.getContactsV2,
        items: newList,
        total:
          previousResult.getContactsV2.total -
          (previousResult.getContactsV2.items.length - newList.length),
      },
    };
  };

const ContactListContainer = styled.div<{}>``;

const PaginationContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    flex-direction: row-reverse;
    margin-top: ${theme.space('l')};
  `,
);

const DividerContainer = styled.div<{}>`
  display: flex;
`;

const FilterListContainer = styled.div<{}>(
  ({ theme }) => css`
    flex: 0.2;
    margin: ${theme.space('m')} ${theme.space('m')} 0 0;
  `,
);

const TableContainer = styled.div<{}>`
  flex: 1;
`;

export default ContactList;
