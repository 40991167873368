import styled, { css } from 'styled-components';

const Standard = styled.div<{}>`
  white-space: nowrap;
  display: flex;

  ${({ theme }) => css`
    padding: ${theme.space('m')} ${theme.space('m')} 0 ${theme.space('m')};
  `}
`;

const Large = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('m')};
    background-color: ${theme.color('white')};
    border-top-right-radius: ${theme.getTokens().border.radius.s};
    border-top-left-radius: ${theme.getTokens().border.radius.s};
  `,
);

export default {
  Standard,
  Large,
};
