import { isEmpty } from 'ramda';
import React from 'react';
import styled from 'styled-components';

import TEST_ID from './index.testid';
import Button from '~/components/atom/Button';
import Input from '~/components/molecule/Input';
import JustificationContainer from '~/components/atom/JustificationContainer';
import type { ValidationFunction } from '~/util/getValidationErrors';

export type Props = {
  onSave: () => void;
  onCancel: () => void;
  onChange: (event: any) => void;
  loading: boolean;
  value: string;
  validation: Array<ValidationFunction>;
};

const checkIsEmpty = value =>
  !isEmpty(value) && value.trim().length ? true : 'Vul een waarde in';

const EditingSection: React.FCC<Props> = ({
  onSave,
  onCancel,
  onChange,
  loading,
  value,
  validation,
  ...rest
}) => (
  <>
    <Container dataTestId={TEST_ID.CONTAINER} align="end" gap="base" {...rest}>
      <StyledInput
        value={value}
        placeholder="Typ hier een tekst"
        onChange={onChange}
        autoFocus
        validation={[checkIsEmpty, ...validation]}
        dataTestId={TEST_ID.INPUT}
      />
      <Button
        size="medium"
        icon="close"
        ghost
        appearance="danger"
        onClick={onCancel}
        disabled={loading}
        dataTestId={TEST_ID.CANCEL_BUTTON}
      />
      <Button
        size="medium"
        label="Klaar"
        icon="check"
        appearance="secondary"
        onClick={onSave}
        disabled={isEmpty(value)}
        loading={loading}
        dataTestId={TEST_ID.SUBMIT_BUTTON}
      />
    </Container>
  </>
);

const Container = styled(JustificationContainer)<{}>`
  width: 100%;
`;

const StyledInput = styled(Input)<{}>`
  width: 100%;
`;

export default EditingSection;
