import React from 'react';
import styled, { css } from 'styled-components';

import InputGroupElement from '~/components/bad/Inputs/InputGroupElement';
import TEST_ID from './index.testid';

type Props = {
  children: string;
};
const ErrorLabel = ({ children }: Props) => (
  <Container data-testid={TEST_ID.CONTAINER}>{children}</Container>
);

const Container = styled.div<{}>`
  ${({ theme }) => css`
    color: ${theme.color('danger')};
  `}
`;

export default InputGroupElement(ErrorLabel, 'align-self: center');
