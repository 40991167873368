import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Contact_Update,
  GetContactQuery as GetContactQueryType,
} from '~/graphql/types';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import { Form } from '~/components/organism/Forms';
import { lowercaseFirstLetter } from '~/util/string';
import ContactTagsBlock, {
  convertTagStringToList,
  convertTagListToString,
} from '../ContactTagsBlock';

import useCurrentAccount from '~/hooks/useCurrentAccount';
import { convertServerLeadScoreToFrontendLeadScore } from '~/components/page/Contacts/util/composeContactListData';
import { isNil } from 'ramda';
import ContactInformationBlock from '../ContactInformationBlock';
import FlowInstancesBlock from '../FlowInstancesBlock';
import ContactSettingsBlock from '../ContactSettingsBlock';
import ContactLivingSituationBlock from '../ContactLivingSituationBlock';
import ContactDetailsBlock from '../ContactDetailsBlock';
import RealworksSendContactBlock from '../RealworksSendContactBlock';
import ContactsContext from '../../ContactsContext/ContactsContext';
import usePermissions from '~/hooks/usePermissions';
import FormFooterSaveBar from '~/components/bad/SaveBar/FormFooterSaveBar';

export const text = {
  noName: Catalog.noName,
  noEmail: Catalog.noEmail,
  noPhone: Catalog.noPhone,
  invalidPhone: Catalog.invalidPhone,
  invalidEmail: Catalog.invalidEmail,
  invalidLivingPostcode: Catalog.invalidPostalCode,
  noLivingAddressStreet: Catalog.requiredField,
  noLivingAddressHouseNumber: Catalog.requiredField,
  noLivingAddressCity: Catalog.requiredField,
  noLivingAddressPostcode: Catalog.requiredField,
  errorExplanation: Catalog.genericUnknownErrorMessageShort,
};
type Props = {
  getContact: NonNullable<GetContactQueryType['getContact']>;
};

const ContactDetailsBlockComponent: React.FCC<Props> = ({ getContact }) => {
  const account = useCurrentAccount();
  const { updateContactFn, loading: updateContactLoading } =
    useContext(ContactsContext);

  const hasFlows = usePermissions(['root.automation']);

  const {
    name,
    email,
    phone,
    id: contactId,
    blockMarketing,
    LeadScore,
    officeId,
    userId,
    emailStatus,
  } = getContact;

  const initialValues = asFormData(getContact);

  return (
    <ContactDetailsBlocks>
      <Form
        validate={validate}
        onSubmit={values =>
          updateContactFn({
            variables: {
              accountId: account.id,
              id: contactId,
              update: asDatabaseUpdateObject(values),
            },
          })
        }
        initialValues={initialValues}
        keepDirtyOnReinitialize={true}
      >
        {formapi => {
          const { handleSubmit, submitting, form, pristine } = formapi;
          const { getFieldState, change } = form;
          const disableForm =
            !!submitting && !updateContactLoading && !pristine;

          return (
            <form data-testid="contact-details-form" onSubmit={handleSubmit}>
              <ContactInformationBlock
                contactId={contactId}
                name={name}
                phone={phone}
                email={email}
                blockMarketing={blockMarketing}
                leadScore={convertServerLeadScoreToFrontendLeadScore(LeadScore)}
                emailStatus={emailStatus}
              />
              {hasFlows.allowed && <FlowInstancesBlock contactId={contactId} />}
              <ContactTagsBlock isFormDisabled={disableForm} />
              <RealworksSendContactBlock
                officeId={officeId}
                userId={userId}
                contactId={contactId}
              />
              <ContactSettingsBlock
                isFormDisabled={disableForm}
                getFieldState={getFieldState}
                change={change}
              />
              <ContactLivingSituationBlock isFormDisabled={disableForm} />
              <ContactDetailsBlock isFormDisabled={disableForm} />
              <FormFooterSaveBar disableSave={updateContactLoading} />
            </form>
          );
        }}
      </Form>
    </ContactDetailsBlocks>
  );
};
type FormData = {
  officeId: string | null;
  userId: string | null;
  livingAddressStreet: string | null;
  livingAddressHouseNumber: number | null;
  livingAddressAddition: string | null;
  livingAddressPostcode: string | null;
  livingAddressCity: string | null;
  phone: string | null;
  email: string | null;
  name: string | null;
  tags: string | null; // string with tags separated by ,
};

const asFormData = (
  getContact: NonNullable<GetContactQueryType['getContact']>,
): FormData => {
  const { address, email, officeId, userId, phone, name, tags } = getContact;

  return {
    officeId: officeId ?? null,
    userId: userId ?? null,
    livingAddressStreet: address?.street ?? null,
    livingAddressHouseNumber: address?.houseNumber ?? null,
    livingAddressAddition: address?.addition ?? null,
    livingAddressPostcode: address?.postcode ?? null,
    livingAddressCity: address?.city ?? null,
    phone: phone ?? null,
    name: name ?? null,
    email,
    tags: convertTagListToString(tags),
  };
};

type ErrorObj = { [key in keyof FormData]?: string | undefined };

const validate = (formData: FormData): ErrorObj => {
  const errors: ErrorObj = {};
  const {
    name,
    email,
    phone,
    livingAddressStreet,
    livingAddressHouseNumber,
    livingAddressPostcode,
    livingAddressCity,
  } = formData;

  if (Validation.String.isEmpty(name)) {
    errors.name = text.noName;
  }

  if (Validation.String.isEmpty(email)) {
    errors.email = text.noEmail;
  }
  if (!Validation.Email.isValid(email)) {
    errors.email = text.invalidEmail;
  }

  if (!isNil(phone) && !Validation.Phone.isValid(phone)) {
    errors.phone = text.invalidPhone;
  }

  if (hasAnyAddressFieldFilled(formData)) {
    if (Validation.String.isEmpty(livingAddressStreet)) {
      errors.livingAddressStreet = text.noLivingAddressStreet;
    }
    if (Validation.String.isEmpty(livingAddressHouseNumber)) {
      errors.livingAddressHouseNumber = text.noLivingAddressHouseNumber;
    }

    if (Validation.String.isEmpty(livingAddressPostcode)) {
      errors.livingAddressPostcode = text.noLivingAddressPostcode;
    }
    if (!Validation.Postcode.isValid(livingAddressPostcode)) {
      errors.livingAddressPostcode = text.invalidLivingPostcode;
    }
    if (Validation.String.isEmpty(livingAddressCity)) {
      errors.livingAddressCity = text.noLivingAddressCity;
    }
  }
  return errors;
};

const hasAnyAddressFieldFilled = ({
  livingAddressStreet,
  livingAddressHouseNumber,
  livingAddressAddition,
  livingAddressPostcode,
  livingAddressCity,
}: FormData): boolean =>
  !Validation.String.isEmpty(livingAddressStreet) ||
  !Validation.String.isEmpty(livingAddressHouseNumber) ||
  !Validation.String.isEmpty(livingAddressPostcode) ||
  !Validation.String.isEmpty(livingAddressCity) ||
  !Validation.String.isEmpty(livingAddressAddition);

const ContactDetailsBlocks = styled.div<{}>`
  grid-column: detail-block-start / detail-block-end;

  display: flex;
  flex-direction: column;
`;

const asDatabaseUpdateObject = (form: FormData): Contact_Update => {
  const updateObject: Contact_Update = {};

  const newAddress: Partial<Contact_Update['address']> = {};
  Object.keys(form).forEach(key => {
    if (key.startsWith('livingAddress')) {
      const asAddressField = lowercaseFirstLetter(
        key.replace('livingAddress', ''),
      );

      newAddress[asAddressField] = form[key];
    } else if (key === 'tags') {
      updateObject[key] = convertTagStringToList(form[key]);
    } else if (key === 'email') {
      updateObject[key] = form[key]?.toLocaleLowerCase();
    } else {
      updateObject[key] = form[key];
    }
  });

  updateObject.address = newAddress as Contact_Update['address'];

  // if no address fields are filled then null out the field
  if (!hasAnyAddressFieldFilled(form)) {
    updateObject.address = null;
  }

  return updateObject;
};

export default ContactDetailsBlockComponent;
