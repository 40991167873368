import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import React from 'react';

import { Body, Heading3 } from '~/components/atom/Typography';
import { type AppStatus_StellaxAi__Input } from '~/graphql/types';
import AppDetailsContainer from '~/components/page/Apps/components/AppDetailsContainer';

import JustificationContainer from '~/components/atom/JustificationContainer';
import SelectProduct from '~/components/template/AppSetupModal/components/SelectProduct';
import { PRODUCT_OPTIONS } from '../../appConfig';
import Input from '~/components/molecule/Input';
import ImageInput from '~/components/organism/ImageInput';
import { fieldMapping } from './constants';
import BrandSettingsCTA from '~/components/organism/BrandSettingsCTA';
import Div from '~/components/atom/Div';
import type { SelectedBrandSettings } from '~/components/template/BrandSettingsModal';

type Props = {
  values: AppStatus_StellaxAi__Input;
  onChange: (changes: NonNullable<AppStatus_StellaxAi__Input>) => void;
  logoUrl: any;
  setLogoUrl: Function;
} & RouteComponentProps;

export const text = {
  description: `Stel het type rapport in dat je wilt aanbieden op je website. Op basis van deze selectie wordt het abonnementstarief in rekening gebracht. Deze keuze kan je iedere maand wijzigen. Betaalde abonnementsgelden worden niet gerestitueerd.`,
  details: 'Ontwerp',
  detailsDescription:
    'Stel hieronder de bedrijfsgegevens en het logo in die worden weergegeven in het rapport.',
};

const AppDetails: React.FCC<Props> = ({
  values,
  onChange,
  logoUrl,
  setLogoUrl,
}) => (
  <AppDetailsContainer
    header="Rapport instellingen"
    icon="edit"
    loading={false}
  >
    <Body margin={[null, null, 'm', null]}>{text.description}</Body>
    <JustificationContainer direction="column" width="100%">
      <SelectProduct
        wrap={false}
        options={PRODUCT_OPTIONS}
        selectedOption={PRODUCT_OPTIONS.find(
          ({ payload }) => payload === values.reportType,
        )}
        onSelect={option => {
          onChange({ ...values, reportType: option.payload });
        }}
      />
      <Heading3>{text.details}</Heading3>
      <Body>{text.detailsDescription}</Body>
      <Div margin={[null, null, 'm', null]} width="100%">
        <BrandSettingsCTA
          subjects={['logo', 'url']}
          onConfirm={brandSettings => {
            const update = brandSettingsToUpdate(brandSettings, values);
            if (brandSettings.logo?.url) {
              setLogoUrl(brandSettings.logo?.url);
            }

            onChange({
              ...values,
              ...update,
            });
          }}
        />
      </Div>
      <JustificationContainer width="100%" gap="m">
        <Input
          width="100%"
          name="name"
          label={fieldMapping.name.label}
          required={fieldMapping.name.required}
          value={values.name}
          type="text"
          validation={[fieldMapping.name.validation]}
          onChange={event => {
            const val = event?.target?.value;
            if (val != null) {
              onChange({
                ...values,
                name: val,
              });
            }
          }}
        />
        <Input
          width="100%"
          name="email"
          label={fieldMapping.email.label}
          required={fieldMapping.email.required}
          value={values.email}
          type="text"
          validation={[fieldMapping.email.validation]}
          onChange={event => {
            const val = event?.target?.value;
            if (val != null) {
              onChange({
                ...values,
                email: val,
              });
            }
          }}
        />
      </JustificationContainer>
      <JustificationContainer width="100%" margin={['m', null, null, null]}>
        <Input
          width="100%"
          name="website"
          label={fieldMapping.website.label}
          required={fieldMapping.website.required}
          value={values.website}
          type="text"
          validation={[fieldMapping.website.validation]}
          onChange={event => {
            const val = event?.target?.value;
            if (val != null) {
              onChange({
                ...values,
                website: val,
              });
            }
          }}
        />
      </JustificationContainer>
    </JustificationContainer>
    <JustificationContainer direction="column" gap="m" width="100%">
      <ImageInput
        deletable={false}
        initialUrl={logoUrl}
        required={fieldMapping.logo.required}
        onChange={value => {
          if (value) {
            setLogoUrl(null);
            onChange({
              ...values,
              logo: {
                s3key: value?.s3key,
              },
            });
          }
        }}
        s3Key={values.logo?.s3key}
        title={fieldMapping.logo.label}
        filename="logo"
      />
    </JustificationContainer>
  </AppDetailsContainer>
);

const brandSettingsToUpdate = (
  brandSettings: SelectedBrandSettings,
  values: AppStatus_StellaxAi__Input,
): AppStatus_StellaxAi__Input => {
  const update: AppStatus_StellaxAi__Input = {
    ...values,
    logo:
      brandSettings && brandSettings.logo && brandSettings.logo?.s3key
        ? {
            s3key: brandSettings.logo.s3key,
          }
        : values.logo,
    website:
      brandSettings && brandSettings.url ? brandSettings.url : values.website,
  };

  return update;
};

export default AppDetails;
