import { useCallback, useMemo } from 'react';
import { Resetter, useResetRecoilState } from 'recoil';
import { formState, interactionState, menuState } from '../../state';

/**
 * Resets all recoil atoms related to form builder state
 */
const useResetFormBuilder = (): Resetter => {
  const resetFormState = useResetRecoilState(formState);
  const resetInteractionState = useResetRecoilState(interactionState);
  const resetMenuState = useResetRecoilState(menuState);

  const resetFns = useMemo(
    () => [resetFormState, resetInteractionState, resetMenuState],
    [resetFormState, resetInteractionState, resetMenuState],
  );

  const resetAll = useCallback(() => {
    resetFns.forEach(fn => fn());
  }, [resetFns]);

  return resetAll;
};

export default useResetFormBuilder;
