import React, { useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import qs from 'query-string';
import { Helmet as MetaTags } from 'react-helmet';
import { FlowAction, useGetFlowV2TemplateQuery } from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import BuilderWithContext from '../BuilderWithContext';
import { useRecoilState, useSetRecoilState } from 'recoil';
import flowActions from '../../state';
import getFlowActionsToClientActions from '../../util/getFlowActionsToClientActions';
import { withRouterTransitionContext } from '~/contexts/RouterTransitionContext';
import interactions from '../../state/interactions';
import { EMPTY_TEMPLATE_ID } from '../FlowTemplates';
import { ClientFlowAction } from '~/graphql/types.client';
import { isNonEmptyString } from '~/util/Validation/String';

export type Props = RouteComponentProps<{ id: string }>;

const text = {
  flowName: 'Nieuwe flow',
  flowDescription: 'Bouw je eigen flow',
};

const CreateFlowBuilder: React.FCC<Props> = ({ id }) => {
  const account = useCurrentAccount();

  const { name, description } = useMemo(
    () => qs.parse(global.window?.location?.search || ''),
    [],
  );

  const initialDescription = isNonEmptyString(description) ? description : null;
  const initialName = isNonEmptyString(name) ? name : text.flowName;

  const { data, loading } = useGetFlowV2TemplateQuery({
    variables: {
      accountId: account.id,
      templateId: id as string,
      name: initialName,
      description: initialDescription,
    },
  });
  const [, setActions] = useRecoilState(flowActions);

  const setActiveInteraction = useSetRecoilState(interactions);
  const [initialActions, setInitialActions] = useState<Array<ClientFlowAction>>(
    [],
  );

  useEffect(() => {
    if (data?.getFlowV2Template) {
      const templateActions = data.getFlowV2Template.Actions;

      //If it is an empty template, there will be only one action - Start action, then we want to overwrite it with an empty condition list
      if (id === EMPTY_TEMPLATE_ID && templateActions.length === 1) {
        const startAction = templateActions.find(
          action => action.actionType === FlowAction.Start,
        );

        const actions = startAction
          ? [
              {
                ...startAction,
                conditionList: {
                  __typename: 'FlowV2_ConditionList' as const,
                  exp: '([0])',
                  conditions: [],
                },
              },
            ]
          : [];

        const clientActions = getFlowActionsToClientActions(actions);

        setInitialActions(clientActions);
        setActions(clientActions);

        setActiveInteraction({
          type: 'updateAction',
          action: clientActions[0],
        });
      } else {
        const clientActions = getFlowActionsToClientActions(
          templateActions || [],
        );
        setInitialActions(clientActions);
        setActions(clientActions);
      }
    }
  }, [
    initialName,
    initialDescription,
    data?.getFlowV2Template,
    setActions,
    id,
    setActiveInteraction,
  ]);

  if (loading || !data?.getFlowV2Template)
    return (
      <>
        <MetaTags>
          <title>Laden...</title>
        </MetaTags>
        <DatHuisLoading />
      </>
    );

  return (
    <BuilderWithContext
      flowId={data.getFlowV2Template.id}
      initialFlow={{
        flowName: data.getFlowV2Template.name,
        flowDescription: data.getFlowV2Template.description,
        enabled: data.getFlowV2Template.enabled,
        maximumFlowRun: data.getFlowV2Template.maximumFlowRun,
        actions: initialActions,
      }}
    />
  );
};

export default withRouterTransitionContext(CreateFlowBuilder);
