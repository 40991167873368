import { AppType } from '~/graphql/types';
import { AppConfig } from '../types';

const description = `
## Omschrijving
*Nu voor € 59,- in plaats van € 79,- per maand!*

In samenwerking met de Nationale Hypotheekbond presenteert DatHuis de ultieme leadgeneratie tools voor hypotheken. De Nationale Hypotheekbond is sinds jaar en dag een toonaangevende softwareleverancier voor financiële intermediairs. Nu zijn deze tools ook voor jou beschikbaar. Met de eenvoudige interface, duidelijke taalgebruik en volledigheid van informatie haal je de beste hypotheken leadgenerator in huis.

Dit pakket bestaat uit de volgende vier tools:

De bespaarcheck [(voorbeeld)](https://consumenten.hypotheekbond.nl/rekentools/hypotheekcheck-bespaarcheck/), Bereken je maximale hypotheek [(voorbeeld)](https://consumenten.hypotheekbond.nl/rekentools/bereken-je-maximale-hypotheek/), Maandlasten berekenen [(voorbeeld)](https://consumenten.hypotheekbond.nl/rekentools/maandlasten-hypotheek-berekenen/), en Kan ik deze woning kopen? [(voorbeeld)](https://consumenten.hypotheekbond.nl/rekentools/kan-ik-deze-woning-kopen/).`;

const termsOfUse =
  'Door het activeren van deze app geef je DatHuis toestemming om gegevens uit te wisselen tussen jouw DatHuis account en het platform van de Hypotheekbond. Daarbij is DatHuis de verwerker van jouw gegevens. Hoe we gegevens verwerken staat beschreven in de verwerkersovereenkomst. Een kopie van de verwerkersovereenkomst en algemene voorwaarden zijn beschikbaar op de abonnementspagina in de instellingen van jouw account. Voor het gebruik van het Hypotheekbond platform zijn de gebruiksvoorwaarden van Hypotheekbond van toepassing. Het tarief voor de tools van de Nationale Hypotheekbond bedraagt € 59,- per maand per website (URL) en wordt automatisch geïncasseerd. Er zijn geen eenmalige activatie- en implementatiekosten voor de websitetools van € 225. Voor de websitetools geldt een maximum van 150 berekeningen per maand; daarboven geldt een vergoeding van € 0,59 per berekening. Alle genoemde bedragen zijn excl. BTW. Meer informatie vind je op [https://www.hfbg.nl](https://www.hfbg.nl)';

export const hypotheekbond: AppConfig = {
  __typename: 'AppStatus_Hypotheekbond',
  appType: AppType.Hypotheekbond,
  type: 'with_config',
  slug: 'hypotheekbond',
  name: 'Nationale Hypotheekbond',
  tags: ['Leadgeneratie', 'E-mailcampagne', 'Informatievoorziening'],
  provider: 'Nationale Hypotheekbond',
  logo: {
    src: 'https://dathuis-stash.imgix.net/hypotheekbond.svg',
  },
  image: {
    src: 'https://dathuis-stash.imgix.net/hypotheekbond.svg',
  },
  price: {
    interval: {
      amount: 1,
      duration: 'month',
    },
    amount: 5900,
    currency: 'EUR',
  },
  description,
  termsOfUse,
  compatibility: 'Beschikbaar voor alle gebruikers',
  setupFlow: 'basic',
  visibility: 'visible',
  cancellability: 'daily',
  wizard: 'custom',
};
