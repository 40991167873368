import React, { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import styled, { css } from 'styled-components';
import { useTable, useFlexLayout, useSortBy } from 'react-table';
import { memoize } from 'lodash';

import {
  AdminGetUsersForAccountQuery,
  QueryAdmin_GetUsersForAccountArgs,
  useAdminGetUsersForAccountQuery,
} from '~/graphql/types';

import { HeaderCell, RowCell } from '~/components/bad/DataTables';
import { Header } from '~/components/bad/DataTables/styling';
import { isEmpty, isNil, pluck } from 'ramda';
import TEST_ID from './index.testid';
import Pagination from '~/components/bad/DataTables/components/Pagination';
import memoizedAccountUsersListColumns from './utils/accountUsersListColumns';
import TableBodyComponent from '~/components/bad/DataTables/styling/Body';
import Row from '~/components/bad/DataTables/styling/Row';
import UserActionColumnCell from '../UserActionColumnCell';
import NoResultSection from '~/components/page/NoResultSection';
import { ACTION_COLUMN_ACCESSOR } from '~/components/bad/DataTables/util/TableHeaderCell/action';
import TableContainer from '~/components/page/Accounts/AccountsList/components/TableContainer';
import ErrorTypes, { errorTypeFromMutationError } from '~/ErrorTypes';
import Catalog from '~/Catalog';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Button from '~/components/atom/Button';
import copyTextToClipboard from '~/util/copyTextToClipboard';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

export type Props = {
  accountId?: string;
};

const PAGE_LIMIT = 20;

const AccountUsersTable: React.FCC<Props> = ({ accountId = '', ...rest }) => {
  const addToast = useAddToast();
  const currentBreakpoint = useCurrentBreakpoint();
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [version] = useState<string>(uuidv1());
  const [key] = useState(uuidv1());

  const columns = memoizedAccountUsersListColumns(key);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn,
      getRowId: ({ id }) => id,
    },
    useFlexLayout,
    useSortBy,
  );

  const [queryVariables, setQueryVariables] = useState({
    accountId,
    limit: PAGE_LIMIT,
    page: 0,
  });
  const { data, loading, error } = useAdminGetUsersForAccountQuery({
    variables: queryVariables,
  });

  useEffect(() => {
    if (!isNil(data) && !isEmpty(data) && !loading) {
      const items = data?.admin_getUsersForAccount?.items.map(item => ({
        ...item,
        role: item?.relation?.role,
      }));

      setTableData(
        memoizedAccountUsersListData(items, queryVariables, version),
      );
    }
  }, [data, version, loading, queryVariables]);

  useEffect(() => {
    if (currentBreakpoint === 'mobile' || currentBreakpoint === 'tablet') {
      setHiddenColumns(['email']);
    } else {
      toggleHideAllColumns(false);
    }
  }, [setHiddenColumns, toggleHideAllColumns, currentBreakpoint]);

  const totalAmount = data?.admin_getUsersForAccount.total || 0;

  const isImpersonatingError =
    errorTypeFromMutationError(error) ===
    ErrorTypes.impersonationIsOngoingError;

  return (
    <>
      <JustificationContainer
        justification="end"
        align="center"
        margin={[null, null, 'm', null]}
      >
        <Button
          label="Copy UserList for Feature Flags"
          appearance="primary"
          onClick={() =>
            data &&
            onCopyUserList(data.admin_getUsersForAccount.items).then(() => {
              addToast([
                formatToastMessage('Userlist copied', 'success', {
                  label: 'Create new Userlist at Gitlab',
                  to: 'https://gitlab.dathuis.nl/dh/sfa-app/-/feature_flags_user_lists/new',
                }),
              ]);
            })
          }
        />
      </JustificationContainer>
      <Container {...getTableProps()} data-testid={TEST_ID.CONTAINER} {...rest}>
        <AmountContainer>
          Showing {tableData.length} of {totalAmount} users
        </AmountContainer>

        <TableContainer>
          <Header.Large>
            {headerGroups.map(headerGroup => {
              const key = headerGroup.getHeaderGroupProps().key;
              return (
                <StyledHeaderTr key={key} data-testid={TEST_ID.HEADER_ROW}>
                  {headerGroup.headers.map((column, idx) => {
                    const isLastColumn = idx === headerGroup.headers.length - 1;

                    return (
                      <HeaderCell
                        key={`header-${idx}`}
                        column={column}
                        isLastColumn={isLastColumn}
                      />
                    );
                  })}
                </StyledHeaderTr>
              );
            })}
          </Header.Large>

          {error && (
            <NoResultSection>
              {isImpersonatingError ? (
                <>
                  You are impersonating another user. Stop impersonating to see
                  the users in the account.
                </>
              ) : (
                Catalog.genericUnknownErrorMessageShort
              )}
            </NoResultSection>
          )}

          {tableData.length === 0 && !error && (
            <NoResultSection>{Catalog.noResults}</NoResultSection>
          )}

          <TableBodyComponent.Standard.Inner
            {...getTableBodyProps()}
            data-testid={TEST_ID.TABLE_BODY}
          >
            {rows.map(row => {
              prepareRow(row);
              const rowProps = { ...row.getRowProps() };

              return (
                <Row
                  {...rowProps}
                  key={rowProps.key}
                  data-objectid={row.original.id}
                  dataTestId={TEST_ID.LIST_ITEM}
                >
                  {row.cells.map((cell, idx) => {
                    const isLastColumn = idx === row.cells.length - 1;

                    if (cell.column.id === ACTION_COLUMN_ACCESSOR) {
                      return (
                        <UserActionColumnCell
                          key={`action-cell-${idx}`}
                          cell={cell}
                          accountId={accountId}
                        />
                      );
                    }

                    return (
                      <RowCell
                        key={`cell-${idx}`}
                        cell={cell}
                        isLastColumn={isLastColumn}
                      />
                    );
                  })}
                </Row>
              );
            })}
          </TableBodyComponent.Standard.Inner>
        </TableContainer>

        <PaginationContainer>
          <Pagination
            totalItems={totalAmount}
            currentPage={Number(queryVariables.page) + 1 ?? 0}
            itemsPerPage={PAGE_LIMIT}
            onSelect={value => {
              setQueryVariables(prevVariables => ({
                ...prevVariables,
                page: value - 1,
              }));
            }}
          />
        </PaginationContainer>
      </Container>
    </>
  );
};

const memoizedAccountUsersListData = memoize(
  (composedData, _variables, _version) => composedData,
  (
    items: Array<any>,
    variables: QueryAdmin_GetUsersForAccountArgs,
    version: string,
  ) => `${version}*@@*${items.length}*@@*${variables.page}`,
);

const onCopyUserList = async (
  items: AdminGetUsersForAccountQuery['admin_getUsersForAccount']['items'],
) => {
  const userList = pluck('id', items);
  return copyTextToClipboard(userList.join(','));
};

const defaultColumn = {
  minWidth: 20,
  width: 120,
  maxWidth: 200,
};

const StyledHeaderTr = styled.div<{}>`
  white-space: nowrap;
  display: flex;
`;

const PaginationContainer = styled.div<{}>`
  display: flex;
  flex-direction: row-reverse;

  ${({ theme }) => css`
    margin-top: ${theme.space('l')};
  `}
`;

const AmountContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-bottom: ${theme.space('s')};
    text-align: right;
    font-weight: ${theme.fw('semiBold')};
  `,
);

const Container = styled.div<{}>``;

export default AccountUsersTable;
