import React, { useState } from 'react';
import { useSpring } from 'react-spring';
import styled, { css, useTheme } from 'styled-components';

import Blobs from './components/Blobs';
import TEST_ID from './index.testid';
import illustrations from '~/components/atom/Illustration/components';
import Illustration from '~/components/atom/Illustration';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Body, Heading2 } from '~/components/atom/Typography';
import Button from '~/components/atom/Button';

export type Props = {
  header: React.ReactNode;
  description?: string | React.ReactNode;
  illustration?: keyof typeof illustrations;
  buttonLabel?: React.ReactNode;
  onButtonClick?: () => void;
  blobColor?: string;
};

const EmptyStateComponent: React.FCC<Props> = ({
  dataTestId,
  header,
  description,
  buttonLabel,
  illustration,
  onButtonClick,
  blobColor,
}) => {
  const theme = useTheme();
  const blobRange = [0, 1, 2, 3, 4, 5];
  const [selectedBlob, setSelectedBlob] = useState<number>(0);

  const getRandomNumberFromRange = selected => {
    const nextRandomIndex = Math.floor(Math.random() * blobRange.length);
    return nextRandomIndex === selectedBlob
      ? getRandomNumberFromRange(selected)
      : nextRandomIndex;
  };

  const [{ x }] = useSpring(
    () => ({
      from: { x: selectedBlob },
      to: async next => {
        await next({ x: selectedBlob });
      },
      config: {
        duration: 4000,
      },
      onRest: () => setSelectedBlob(getRandomNumberFromRange(selectedBlob)),
    }),
    [selectedBlob],
  );

  return (
    <Container data-testid={dataTestId}>
      <Blobs blobRange={blobRange} x={x} color={blobColor} />
      <IllustrationContainer>
        <Illustration
          name={illustration || 'box'}
          color={theme.color('text')}
          secondaryColor={theme.color('danger')}
        />
      </IllustrationContainer>
      <JustificationContainer
        justification="center"
        align="center"
        direction="column"
      >
        <Heading2 margin={['l', null, null, null]} align="center">
          {header}
        </Heading2>
        <Description margin={['xl', null]} align="center">
          {description}
        </Description>
      </JustificationContainer>
      <Button
        appearance="secondary"
        size="medium"
        label={buttonLabel}
        icon="plus"
        onClick={onButtonClick}
        pulse
        dataTestId={TEST_ID.ADD_ITEM_BUTTON}
      />
    </Container>
  );
};

const Container = styled.div<{}>`
  position: relative;
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 100px 0;

  > :not(:first-child) {
    z-index: 100;
  }
`;

const Description = styled(Body)<{}>(
  ({ theme }) => css`
    width: 60%;
    margin: ${theme.space('xl')} auto;
  `,
);

const IllustrationContainer = styled.div<{}>`
  max-width: 4.5rem;
`;

export default EmptyStateComponent;
