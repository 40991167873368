import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import SettingsMenu from './components/SettingsMenu';
import TEST_ID from './index.testid';
import LayoutSettings from './components/LayoutSettings';
import ThemeSettings from './components/ThemeSettings';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import Issues from './components/Issues';
import Events from './components/Events';
import InAdvancedForm from '../InAdvancedForm';

export type Props = {
  dataTestId?: string;
  isFullScreen?: boolean;
};

const AsideContainer: React.FCC<Props> = ({
  dataTestId,
  isFullScreen = false,
  ...rest
}) => {
  const [expanded, setExpanded] = useState<
    'settings' | 'layout' | 'theme' | 'issues' | 'events' | null
  >('settings');

  return (
    <Container data-testid={dataTestId} isFullScreen={isFullScreen} {...rest}>
      <Content>
        <SettingsMenu
          dataTestId={TEST_ID.SETTINGS_MENU}
          expanded={expanded === 'settings'}
          onToggleExpand={() =>
            setExpanded(prev => (prev === 'settings' ? null : 'settings'))
          }
        />
        <LayoutSettings
          expanded={expanded === 'layout'}
          onToggleExpand={() =>
            setExpanded(prev => (prev === 'layout' ? null : 'layout'))
          }
        />
        <ThemeSettings
          expanded={expanded === 'theme'}
          onToggleExpand={() =>
            setExpanded(prev => (prev === 'theme' ? null : 'theme'))
          }
        />
        <InAdvancedForm>
          <Events
            expanded={expanded === 'events'}
            onToggleExpand={() =>
              setExpanded(prev => (prev === 'events' ? null : 'events'))
            }
          />
        </InAdvancedForm>
        <Issues
          expanded={expanded === 'issues'}
          onToggleExpand={() =>
            setExpanded(prev => (prev === 'issues' ? null : 'issues'))
          }
        />
      </Content>
    </Container>
  );
};
const CONTAINER_WIDTH = 350;

const Container = styled.div<{ isFullScreen: boolean }>(
  ({ theme, isFullScreen }) => css`
    max-width: ${CONTAINER_WIDTH}px;
    background-color: ${theme.color('white')};
    position: absolute;
    margin-top: ${isFullScreen ? theme.space('xxl') : theme.space('base')};
    width: 100%;
    z-index: ${theme.z('footer') - 1};
    border: ${theme.getTokens().border.width.s} solid
      ${theme.color('grey', 'light')};
    border-radius: ${theme.getTokens().border.radius.base};
    overflow: hidden;
  `,
);

const Content = styled.div(
  ({ theme }) => css`
    height: 100%;
    max-height: 86vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${scrollBarStyles}

    & > *:not(:last-child) {
      position: relative;
    }

    & > *:not(:last-child)::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: ${theme.space('m')};
      right: ${theme.space('m')};
      border-bottom: 0.5px solid ${theme.color('grey')};
      border-radius: ${theme.getTokens().border.radius.s};
    }
  `,
);

export default AsideContainer;
