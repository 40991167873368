import React from 'react';
import styled, { css } from 'styled-components';

import { getModalBorderRadius } from './Modal';

type Props = {
  children?: React.ReactNode;
};
const BottomButtonRow: React.FCC<Props> = ({ children }) => (
  <ButtonsRow>{children}</ButtonsRow>
);

const ButtonsRow = styled.div<{}>`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  min-width: 100%;

  ${({ theme }) => css`
    padding: ${theme.space('s')} ${theme.space('l')};
    background-color: ${theme.color('white', 'dark')};
    border-bottom-left-radius: ${getModalBorderRadius(theme)};
    border-bottom-right-radius: ${getModalBorderRadius(theme)};
    margin-top: ${theme.space('m')};

    & > * {
      margin-left: ${theme.space('m')};
    }
  `};
`;

export default BottomButtonRow;
