import React from 'react';
import styled, { css } from 'styled-components';

import UserMenu from './components/UserMenu';
import AccountsMenu from './components/AccountsMenu';
import MobileMenu from './components/MobileMenu';
import Logo from './components/Logo';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';
import Divider from './components/Divider';
import isPopupWindow from '~/util/isPopupWindow';

import type { NavigationLink } from './components/NavigationLink';
import NavLink from './components/NavigationLink';
import useDHFlag from '~/hooks/useDHFlag';

const requestLinks: Array<NavigationLink> = [
  {
    label: 'Dashboard',
    to: '/-/',
  },
  {
    label: 'Taken',
    to: '/-/tasks',
  },
  {
    label: 'Contacten',
    to: '/-/contacts',
    matchPartial: true,
  },
  {
    label: 'Automation',
    to: '/-/automation/flows',
    permission: 'root.automation',
  },
  {
    label: 'Apps',
    to: '/-/apps',
    matchPartial: true,
  },
  {
    label: 'Formulieren',
    to: '/-/forms',
    matchPartial: true,
  },
  {
    label: 'Webwinkel widget',
    to: '/-/widget',
    matchPartial: true,
  },
];

type Ref = HTMLDivElement;

export type Props = {};

const Navigation = React.forwardRef<Ref, Props>(({ ...rest }, ref) => {
  const currentBp = useCurrentBreakpoint();
  const hasFormBuilder = useDHFlag('form-builder');

  // do not show it in a popup window
  if (isPopupWindow()) {
    // returning null here messes up the main content's height because navigationRef is used to determine its height
    return <div />;
  }

  const filteredLinks = requestLinks.filter(({ to }) => {
    if (to === '/-/forms') return hasFormBuilder;
    return true;
  });

  if (['mobile', 'tablet', 'tabletLandscape'].includes(currentBp))
    return <MobileMenu links={filteredLinks} />;

  return (
    <NavigationContainer {...rest} data-testid="app-header" ref={ref}>
      <Logo />
      <Divider />
      <NavigationContent>
        <LinksContainer>
          {filteredLinks.map(link => (
            <NavLink key={link.to} {...link} />
          ))}
        </LinksContainer>
        <OptionsContainer>
          <UserMenu />
          <AccountsMenu />
        </OptionsContainer>
      </NavigationContent>
    </NavigationContainer>
  );
});

const NavigationContent = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;

    ${theme.mq.lessThan('tablet')`
     flex-direction: column;
     font-size: ${theme.fs('m')};
  `}
  `,
);

const OptionsContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LinksContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const NavigationContainer = styled.header<{}>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    box-shadow: ${theme.boxShadow('base')};
    background-color: ${theme.color('white')};
    z-index: ${theme.getTokens().zIndex.top};
    font-size: ${theme.fs('m')};
  `}
`;

export default Navigation;
