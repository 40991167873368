import React, { useState } from 'react';
import TEST_ID from './index.testid';
import DonutChart from '~/components/organism/Charts/DonutChart';
import { useGetContactsCountByLeadSourceQuery } from '~/graphql/types';
import CardHeader from '../CardHeader';
import { Heading5, Body } from '~/components/atom/Typography';
import Loading from '~/components/organism/Charts/DonutChart/components/Loading';
import useToday from '~/hooks/useToday';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import getDateRangeOptionsWithEndDate from '../../utils/getDateRangeOptionsWithEndDate';
import WithMasonryCard from '../withMasonryCard';

export type Props = {
  today?: Date;
};

const text = {
  heading: 'Nieuwe contacten per leadbron',
  noNewContacts: 'Geen nieuwe contacten',
  noNewContactsBody: 'Je hebt geen nieuwe contacten door leadbronnen',
  donutTotalLabel: 'total nieuwe contacten',
  errorHeader: 'Uh-oh 😱',
  errorBody:
    'Het lijkt er op dat er iets mis is gegaan bij het ophalen van jouw contacten. Probeer het later opnieuw.',
};

const ContactsCountByLeadSource: React.FCC<Props> = ({
  dataTestId,
  today: originalToday,
}) => {
  const today = useToday(originalToday);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const options = getDateRangeOptionsWithEndDate(today);
  const dateRange = options[selectedOptionIndex].payload;

  const { id: accountId } = useCurrentAccount();

  const { data, loading, error } = useGetContactsCountByLeadSourceQuery({
    variables: {
      accountId,
      ...dateRange,
    },
  });

  const FilledCardHeader = () => (
    <CardHeader
      headingText={text.heading}
      onOptionChange={({ selectedOptionIdx }) =>
        setSelectedOptionIndex(selectedOptionIdx)
      }
      options={options}
      selectedOptionIndex={selectedOptionIndex}
      dataTestId={`${dataTestId}-${TEST_ID.CARD_HEADER}`}
    />
  );

  if (loading) {
    return (
      <>
        <FilledCardHeader />
        <Loading />
      </>
    );
  }

  if (error) {
    return (
      <>
        <FilledCardHeader />
        <Heading5>{text.errorHeader}</Heading5>
        <Body>{text.errorBody}</Body>
      </>
    );
  }

  if (data && data.getContactsCountByLeadSource.length <= 0) {
    return (
      <>
        <FilledCardHeader />
        <Heading5 dataTestId={`${dataTestId}-${TEST_ID.NO_CONTACTS}`}>
          {text.noNewContacts}
        </Heading5>
        <Body>{text.noNewContactsBody}</Body>
      </>
    );
  }

  return (
    <>
      <FilledCardHeader />
      <DonutChart
        label={text.donutTotalLabel}
        dataTestId={TEST_ID.DONUT_CHART}
        data={(data?.getContactsCountByLeadSource ?? []).map(
          ({ name, type, count }) => ({
            id: `${name}-${type}`,
            name,
            value: count,
          }),
        )}
        formatOptions={{
          style: 'decimal',
          minimumFractionDigits: 0,
        }}
      />
    </>
  );
};

export default WithMasonryCard(ContactsCountByLeadSource, {
  containerTestId: TEST_ID.CONTAINER,
});
