import { reporter } from '~/hooks/useErrorReporter';
import { WindowLocation } from '@gatsbyjs/reach-router';
import cleanedFilename from '~/util/cleanedFilename';
import { Credentials, WindowState } from '../..';

/**
 * Extracts the email and password parameters from the url:
 * https://devsfa.dathuis.nl/login?email=user@dathuis.nl&password=Test123.
 *
 * Using the "magic link" our users can login without needing to enter their email and password.
 * We send them this link within an email when a new user has signed up etc.
 *
 * @param {WindowLocation<WindowState>} location - Window location with the added state
 * keywords: loginmagiclink
 */
const getParamsFromMagicLink = (
  location: WindowLocation<WindowState>,
): Credentials => {
  const url = location.href;

  let emailMatch = url.match(new RegExp(/email=.*(?=&password=)/, 'g'));
  const passwordMatch = url.match(new RegExp(/password=.*/, 'g'));

  if (!passwordMatch) {
    emailMatch = url.match(new RegExp(/email=.*$/, 'g'));
  }

  let email = emailMatch ? emailMatch[0].slice(6) : null;
  let password = passwordMatch ? passwordMatch[0].slice(9) : null;

  // decode if possible, don't break if malformed
  if (email != null) {
    try {
      email = decodeURIComponent(email);
    } catch (e) {
      // send us a message but just null out the email
      reporter.captureException(
        new Error(
          `${cleanedFilename(__filename)} | Could not decode email ${email}`,
        ),
      );

      email = null;
    }
  }

  // decode if possible, don't break if malformed
  if (password != null) {
    try {
      password = decodeURIComponent(password);
    } catch (e) {
      // send us a message but just null out the password
      reporter.captureException(
        new Error(
          `${cleanedFilename(
            __filename,
          )} | Could not decode password ${password}`,
        ),
      );

      password = null;
    }
  }

  return {
    email,
    password,
  };
};

export default getParamsFromMagicLink;
