import React from 'react';

import MasterDetails from '~/components/molecule/ContentContainer/components/MasterDetails';
import Profile from './Profile';
import Account from './Account';
import Invoices from './Invoices';
import Offices from './Offices';
import Users from './Users';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';

import Catalog from '~/Catalog';
import { IconType } from '~/components/atom/Icon';
import canSeeRoute, { PathViewRights } from '~/util/canSeeRoute';
import Wallet from './Wallet';
import useUserRights from '~/hooks/useUserRights';
import SubscriptionSettings from './Subscription';
import usePermissions from '~/hooks/usePermissions';
import Brand from './Brand';
import Widget from '../Widget';

const text = {
  subscriptionLabel: Catalog.subscriptionLabel,
  invoicesLabel: Catalog.invoicesLabel,
  automationLabel: Catalog.automationLabel,
  walletLabel: Catalog.walletLabel,
  brandLabel: Catalog.brandLabel,
};
const SettingsPage: React.FCC<RouteComponentProps> = () => {
  const { view: mayViewInvoices } = useUserRights({ category: 'Invoices' });
  const { view: mayViewSubscription } = useUserRights({
    category: 'Subscription',
  });

  const mayViewAutomation = usePermissions(['root.automation']);

  return (
    <MasterDetails
      routes={getSideMenuOptions({
        mayViewInvoices,
        mayViewSubscription,
        mayViewAutomation: mayViewAutomation.allowed,
      })}
    />
  );
};

const getSideMenuOptions = (auth: PathViewRights) =>
  allOptions.filter(({ link }) => canSeeRoute(auth, link));

const allOptions = [
  {
    path: '/-/settings/profile',
    link: '/-/settings/profile',
    label: {
      dataTestId: 'nav-link-settings-slide-profile',
      text: 'Mijn profiel',
      icon: 'user' as IconType,
    },
    detail: Profile,
  },
  {
    path: '/-/settings/offices/*',
    link: '/-/settings/offices',
    label: {
      dataTestId: 'nav-link-settings-slide-offices',
      text: 'Vestigingen',
      icon: 'office' as IconType,
    },
    detail: Offices,
  },
  {
    path: '/-/settings/users/*',
    link: '/-/settings/users',
    label: {
      dataTestId: 'nav-link-settings-slide-users',
      text: 'Gebruikers',
      icon: 'users' as IconType,
    },
    detail: Users,
  },
  {
    path: '/-/settings/account/*',
    link: '/-/settings/account',
    label: {
      dataTestId: 'nav-link-settings-slide-account',
      text: 'Account',
      icon: 'briefcase' as IconType,
    },
    detail: Account,
  },
  {
    path: '/-/settings/brand/*',
    link: '/-/settings/brand',
    label: {
      dataTestId: 'nav-link-settings-slide-brand',
      text: text.brandLabel,
      icon: 'star' as IconType,
    },
    detail: Brand,
  },
  {
    path: '/-/settings/subscription/*',
    link: '/-/settings/subscription',
    label: {
      dataTestId: 'nav-link-settings-slide-subscription',
      text: text.subscriptionLabel,
      icon: 'file-text' as IconType,
    },
    detail: SubscriptionSettings,
  },
  {
    path: '/-/settings/invoices/*',
    link: '/-/settings/invoices',
    label: {
      dataTestId: 'nav-link-settings-slide-invoices',
      text: text.invoicesLabel,
      icon: 'file' as IconType,
    },
    detail: Invoices,
  },
  {
    path: '/-/settings/wallet/*',
    link: '/-/settings/wallet',
    label: {
      dataTestId: 'nav-link-settings-slide-wallet',
      text: text.walletLabel,
      icon: 'credit-card' as IconType,
    },
    detail: Wallet,
  },
  {
    path: '/-/settings/widget/*',
    redirectTo: '/-/widget/',
    link: '/-/settings/widget',
    label: {
      dataTestId: 'nav-link-settings-slide-widget',
      text: 'Widget',
      icon: 'star' as IconType,
    },
    detail: Widget,
    hidden: true,
  },
];

export default SettingsPage;
