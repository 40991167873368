import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { State, Props as MobileMenuProps } from '../../index';
import Divider from '~/components/organism/Navigation/components/Divider';
import ChevronIcon from '~/components/organism/Navigation/components/ChevronIcon';
import AccountsMenu from '~/components/organism/Navigation/components/AccountsMenu';
import UserMenu from '~/components/organism/Navigation/components/UserMenu';
import MenuLinks from '../MenuLinks';

type Props = {
  links: MobileMenuProps['links'];
  menuOpen: boolean;
  setMenuOpen: (v: boolean) => void;
};

const MenuList: React.FCC<Props> = ({ menuOpen, links, setMenuOpen }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [state, setState] = useState<State>({
    options: [],
    onChange: () => undefined,
  });

  const MAX_STEP = 1;

  const onNextStep = (props: State) => {
    if (currentStep < MAX_STEP) {
      setCurrentStep(currentStep + 1);
      setState(props);
    }
  };

  const goBack = () => setCurrentStep(0);

  return (
    <>
      {menuOpen && (
        <MenuListContainer>
          <Divider />
          {currentStep === 0 && (
            <>
              <MenuLinks links={links} />
              <Divider />
              <OptionsContainer>
                <UserMenu onDropdownOpen={onNextStep} />
                <AccountsMenu onDropdownOpen={onNextStep} />
              </OptionsContainer>
            </>
          )}

          {currentStep === 1 && state.options.length && (
            <>
              <Button onClick={goBack}>
                <ChevronIcon clockWise={90} />
                <StyledSpan>Terug</StyledSpan>
              </Button>
              <MenuLinks
                links={state.options.map((link, index) => ({
                  to: link.payload,
                  label: link.label,
                  onClick: () => {
                    state.onChange({
                      option: link,
                      selectedOptionIdx: index,
                    });
                    setMenuOpen(false);
                  },
                }))}
              />
            </>
          )}
        </MenuListContainer>
      )}
    </>
  );
};

const StyledSpan = styled.span<{}>(
  ({ theme }) => css`
    margin-left: ${theme.space('m')};
  `,
);

const Button = styled.button<{}>(
  ({ theme }) => css`
    background: none;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: ${theme.space('xxl')} 0 ${theme.space('xl')} 0;
  `,
);

const OptionsContainer = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('s')} 0;
    width: 100%;
  `,
);

const MenuListContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0 ${theme.space('xxl')};
    background: ${theme.color('white')};
    box-shadow: ${theme.boxShadow('base')};
  `,
);

export default MenuList;
