import React, { useEffect } from 'react';
import { Node_Condition_Number__Input, ZapierField } from '~/graphql/types';
import { ZapierMetadataFilter } from '../../types.flow';
import { operatorOptions } from '~/components/organism/Filters/Primitive/Number/constants';
import { isNil } from 'ramda';
import Dropdown from '~/components/molecule/Dropdown';
import useDropdown from '~/hooks/useDropdown';
import { Operator } from '~/components/organism/Filters/Primitive/Number/types.flow';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import Input from '~/components/bad/Inputs/Input';

export type NumberComparisonChange = {
  numberPrimitiveFilter: Node_Condition_Number__Input;
};

type Props = {
  field: ZapierField;
  filter: ZapierMetadataFilter;
  onChange: (change: NumberComparisonChange) => void;
};

const NumberComparison: React.FCC<Props> = ({ filter, onChange }) => {
  const numberPrimitiveFilter: Node_Condition_Number__Input = filter?.value
    ?.numberPrimitiveFilter ?? {
    eq: 0,
    negate: false,
  };

  useEffect(() => {
    onChange({ numberPrimitiveFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operator = getOperatorFromFilter(numberPrimitiveFilter);

  const [selectedOperationIdx, onSelectedOperationChanged] =
    useDropdown<Operator>(
      operatorOptions,
      operatorOptions.findIndex(option => option.key === operator),
      newPayload =>
        onChange({
          numberPrimitiveFilter: {
            negate: false,
            [newPayload]: 0,
          },
        }),
    );

  return (
    <>
      <InputGroup>
        <Dropdown
          options={operatorOptions}
          selectedOptionIdx={selectedOperationIdx}
          onChange={onSelectedOperationChanged}
        />
      </InputGroup>
      <InputGroup>
        <Input
          size="10"
          name="Waarde"
          value={
            !isNil(numberPrimitiveFilter[operator])
              ? numberPrimitiveFilter[operator]?.toString()
              : '0'
          }
          type="number"
          onChange={event => {
            const val = event?.target?.value;
            if (val != null) {
              onChange({
                numberPrimitiveFilter: {
                  ...numberPrimitiveFilter,
                  [operator]: parseInt(val),
                },
              });
            }
          }}
        />
      </InputGroup>
    </>
  );
};

export const getOperatorFromFilter = (
  filter: Node_Condition_Number__Input,
): Operator => {
  if (!filter) return Operator.GREATER_THAN_EQUAL;

  const isEq = 'eq' in filter && filter.eq !== null;
  const isGt = 'gt' in filter && filter.gt !== null;
  const isGte = 'gte' in filter && filter.gte !== null;
  const isLt = 'lt' in filter && filter.lt !== null;
  const isLte = 'lte' in filter && filter.lte !== null;

  if (isEq) return Operator.EQUALS;
  if (isGt) return Operator.GREATER_THAN;
  if (isGte) return Operator.GREATER_THAN_EQUAL;

  if (isLt) return Operator.LESS_THAN_EQUAL;
  if (isLte) return Operator.LESS_THAN_EQUAL;

  return Operator.GREATER_THAN_EQUAL;
};

export default NumberComparison;
