import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import { Helmet as MetaTags } from 'react-helmet';
import MapsContainer, {
  type Client_Feature,
  type LoadArgs,
} from './components/MapsContainer';
import MapBoxContext from '~/contexts/MapBoxContext';
import styled, { css } from 'styled-components';
import useMeasure from '~/hooks/useMeasure';
import { FOOTER_HEIGHT } from '~/components/organism/NavigationFrame/components/Footer';
import EntryCard from '~/components/molecule/EntryCard';
import useOffice from '~/hooks/useOffice';
import { equals, flatten, isNil, lensIndex, over, pluck, reject } from 'ramda';
import type { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { Body, Heading4, Variant } from '~/components/atom/Typography';
import featuresToInput from './utils/featuresToInput';
import { scrollBarStyles } from '~/components/molecule/OverflowScrollWrapper';
import OutputAccordion from '~/components/organism/OutputAccordion';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  useUpdateOfficeMutation,
  type Office_Update,
  type WorkingAreas,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import removeTypenames from '~/util/removeTypenames';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';
import Catalog from '~/Catalog';
import getClientWorkingAreas, {
  type Client_WorkingArea,
  type Client_WorkingAreas,
} from './utils/getClientWorkingAreas';
import getCurrentWorkingAreas from './utils/getCurrentWorkingAreas';
import { v4 as uuidv4 } from 'uuid';
import useKeybinding from '~/hooks/useKeybinding';
import useOffices from '~/hooks/useOffices';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import type ExtendedMapboxDraw from './components/MapsContainer/components/Draw/ExtendedMapboxDraw';
import type { WorkingAreaType } from './components/MapsContainer/components/Draw';
import Divider from '~/components/atom/Divider';
import AddWorkingAreaContainer from './components/AddWorkingAreaContainer';
import BaseAside, { ASIDE_WIDTH } from './components/BaseAside';
import FloatingSaveBar from './components/FloatingSaveBar';
import AssignUserDropdown from './components/AssignUserDropdown';
import MissingUsersErrorMessage from './components/MissingUsersErrorMessage';
import TypographyWithHelpLink from '~/components/molecule/TypographyWithHelpLink';

type Props = RouteComponentProps<{ officeId: string }>;

const workingAreaSentiment: {
  [key in WorkingAreaType]: 'positive' | 'negative';
} = {
  inclusive: 'positive',
  exclusive: 'negative',
};

const workingAreaVariant: {
  [key in WorkingAreaType]: 'success' | 'danger';
} = {
  inclusive: 'success',
  exclusive: 'danger',
};

const text = {
  pageTitle: 'Werkgebied',
  title: 'Werkgebied',
  subHeading: 'Werkgebieden',
  selectedAreas: 'Geselecteerde werkgebieden',
  noFeatures: 'Klik op de kaart om een werkgebied te defineren',
  exclusive: 'Uitgesloten werkgebieden',
  inclusive: 'Werkgebieden',
  helpLink:
    'https://help.dathuis.nl/nl/articles/9565399-werkgebieden-instellen',
  workingAreaLabel: {
    inclusive: 'inclusief',
    exclusive: 'exclusief',
  },
  description: `Teken het werkgebied op de kaart. Klik op een punt in het werkgebied om de selectie te voltooien. Klik op 'Klaar met selecteren' om het proces af te ronden.

Gebruik het werkgebied in Automation om contacten automatisch aan de juiste vestiging toe te wijzen.`,
  showOtherOffices: 'Toon werkgebieden van andere vestigingen',

  userWasRemovedError:
    'Een van de geselecteerde gebruikers was verwijderd. Ververs de pagina en probeer het opnieuw.',
};

const defaultWorkingAreas: WorkingAreas = {
  __typename: 'WorkingAreas',
  inclusive: [],
  exclusive: [],
};

const WorkingArea: React.FCC<Props> = ({ officeId }: { officeId: string }) => {
  const saveBarRef = useRef<HTMLDivElement | null>(null);
  const saveBarOffsetY = saveBarRef.current?.getBoundingClientRect().y;

  const office = useOffice(officeId);
  const offices = useOffices({}).filter(o => o.id !== officeId);
  const [asideToggleState, setAsideToggleState] = useState<{
    [key in WorkingAreaType]: boolean;
  }>({
    inclusive: false,
    exclusive: false,
  });

  const addToast = useAddToast();
  const { id: accountId } = useCurrentAccount();
  const [updateOffice, { loading }] = useUpdateOfficeMutation();

  const [initialWorkingAreas, setInitialWorkingAreas] =
    useState<Client_WorkingAreas>([]);
  const [workingAreas, setWorkingAreas] = useState<Client_WorkingAreas>([]);
  const [showReadOnly, setShowReadOnly] = useState<boolean>(true);
  const readOnlyFeatures: Client_WorkingAreas = flatten(
    offices.map(o => {
      const clientWorkingAreas = getClientWorkingAreas({
        workingAreas: o.workingAreas ?? defaultWorkingAreas,
        officeId: o.id,
        // Add readOnly property for any other future needs
        readOnly: true,
      });

      return clientWorkingAreas;
    }),
  );

  const [activeDrawType, setActiveDrawType] = useState<WorkingAreaType | null>(
    null,
  );
  const [selectedWorkingAreas, setSelectedWorkingAreas] = useState<
    Array<string>
  >([]);

  const { bounds, ref } = useMeasure();
  const drawRef = useRef<ExtendedMapboxDraw>();

  const onReset = () => {
    if (drawRef.current) {
      drawRef.current.deleteAll();
    }
    setActiveDrawType(null);
  };

  useKeybinding({
    action: 'keydown',
    keys: ['esc'],
    callback: () => {
      if (activeDrawType !== null) {
        setActiveDrawType(null);
        if (drawRef.current) drawRef.current.cancelSelection();
      }
    },
  });

  useKeybinding({
    action: 'keydown',
    keys: ['backspace', 'del'],
    callback: (_, key) => {
      const currentlySelectedWorkingAreas =
        drawRef.current?.getSelected().features;

      if (
        currentlySelectedWorkingAreas &&
        (key === 'backspace' || key === 'del')
      ) {
        onDelete(currentlySelectedWorkingAreas);
        onReset();
      }
    },
  });

  useEffect(() => {
    if (initialWorkingAreas.length === 0 || workingAreas.length === 0) {
      const initial = getClientWorkingAreas({
        workingAreas: office?.workingAreas ?? defaultWorkingAreas,
        officeId,
      });
      setInitialWorkingAreas(initial);
      setWorkingAreas(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoaded = useCallback(({ draw }: LoadArgs) => {
    drawRef.current = draw;
  }, []);

  const onDelete = useCallback((featuresToDelete: Array<Feature>) => {
    setWorkingAreas(prevAreas =>
      reject(
        (area: Client_WorkingArea) =>
          featuresToDelete.some(({ id }) => id === area.feature.id),
        prevAreas,
      ),
    );
  }, []);

  const onUpdateWorkingArea = ({
    featureId,
    updatedValue,
  }: {
    // Feature to update
    featureId: string;
    // Value to update
    updatedValue: { userId: string | null } | { feature: Client_Feature };
  }) => {
    setWorkingAreas(prev => {
      const currentFeatureIndex = prev.findIndex(
        area => area.feature.id === featureId,
      );

      const arrayLens = lensIndex<{
        feature: Feature;
      }>(currentFeatureIndex);

      const prevWorkingArea = prev[currentFeatureIndex];

      return over(
        arrayLens,
        () => ({
          ...prevWorkingArea,
          ...updatedValue,
        }),
        prev,
      );
    });
  };

  const onUpdate = (features: Array<Feature>) => {
    features.forEach(feature => {
      if (feature && feature.id) {
        onUpdateWorkingArea({
          featureId: feature.id as string,
          updatedValue: {
            feature: {
              ...feature,
              properties: { ...feature.properties },
            },
          },
        });
      }
    });
  };

  const onCreate = useCallback(([feature]: Array<Feature>) => {
    setWorkingAreas(prev => [
      ...prev,
      {
        userId: null,
        feature: {
          ...feature,
          id: `${officeId}-${uuidv4()}`,
          properties: { ...feature.properties },
        },
      },
    ]);

    // show newly added feature in Aside
    if (feature.properties)
      setAsideToggleState(prev => ({
        ...prev,
        [feature.properties?.inclusive ? 'inclusive' : 'exclusive']: true,
      }));

    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async (rawFeatures: Client_WorkingAreas) => {
    const featuresInput = featuresToInput(rawFeatures);

    const update: Office_Update = removeTypenames({
      workingAreas: featuresInput,
    });
    const { data, errors } = await updateOffice({
      variables: {
        accountId,
        officeId,
        update,
      },
    });

    if (errors && errors.length > 0) {
      return addToast([formatToastMessage(text.userWasRemovedError, 'danger')]);
    }

    if (data && data.updateOffice.workingAreas) {
      const updatedFeatures = getClientWorkingAreas({
        officeId,
        workingAreas: data.updateOffice.workingAreas,
      });

      // Remove the current features from drawRef to prevent duplication
      onReset();

      // Ensure that adding updated features is delayed a bit so that the state is reset
      // first and then the new features are added in the useEffect of Draw/index.tsx
      setTimeout(() => {
        setInitialWorkingAreas(updatedFeatures);
        setWorkingAreas(updatedFeatures);
      }, 100);

      setSelectedWorkingAreas([]);

      return addToast([
        formatToastMessage(Catalog.genericSuccessMessage, 'success'),
      ]);
    }
  };

  const onSelect = (features: Array<Feature<Geometry, GeoJsonProperties>>) => {
    setActiveDrawType(null);

    if (features.length === 0) return setSelectedWorkingAreas([]);

    setSelectedWorkingAreas(
      features
        .map(feature => feature.id)
        .filter((id): id is string => typeof id === 'string'),
    );

    features.forEach(feature => {
      if (feature.properties) {
        setAsideToggleState(prev => ({
          ...prev,
          [feature.properties?.inclusive === true ? 'inclusive' : 'exclusive']:
            true,
        }));
      }
    });
  };

  const currentWorkingAreas = getCurrentWorkingAreas(workingAreas);
  const hasChanges = !equals(initialWorkingAreas, workingAreas);

  const currentUserIds = pluck('userId', currentWorkingAreas.inclusive).filter(
    (id): id is string => !isNil(id),
  );

  return (
    <MapBoxContext.Provider value={process.env.MAPBOX_ACCESS_TOKEN as string}>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <MapLimitContainer ref={ref}>
        <MapsContainer
          officeId={officeId}
          features={[
            ...workingAreas.map(wa => wa.feature),
            ...(showReadOnly ? readOnlyFeatures.map(wa => wa.feature) : []),
          ]}
          height={bounds.height - FOOTER_HEIGHT}
          width={bounds.width}
          onLoaded={onLoaded}
          onCreate={onCreate}
          onDelete={onDelete}
          onUpdate={onUpdate}
          onSelect={onSelect}
        />
      </MapLimitContainer>

      <FloatingSaveBar
        onSave={async () => onSave(workingAreas)}
        onCancel={() => {
          setWorkingAreas(initialWorkingAreas);
          setSelectedWorkingAreas([]);
          onReset();
        }}
        loading={loading}
        disabled={loading || hasChanges === false}
        ref={saveBarRef}
      />

      <AsideContainer offsetY={saveBarOffsetY}>
        <JustificationContainer width="100%" gap="base" direction="column">
          <TypographyWithHelpLink
            help={{ link: text.helpLink }}
            TypographyComponent={Heading4}
          >
            {text.subHeading}
          </TypographyWithHelpLink>

          <Body size="base" withoutMargin>
            {text.description}
          </Body>

          <ToggleCheckbox
            value={showReadOnly}
            label={text.showOtherOffices}
            onClick={() => setShowReadOnly(prev => !prev)}
            containerProps={{
              direction: 'row',
              width: '100%',
              justification: 'space-between',
              align: 'center',
              gap: 'xxs',
            }}
            size="small"
          />
          <MissingUsersErrorMessage
            officeId={officeId}
            currentUserIds={currentUserIds}
          />
        </JustificationContainer>

        <Divider margin={['l', null]} />

        <EntryList>
          <Heading4 withoutMargin variant={Variant.primary}>
            {text.selectedAreas}
          </Heading4>
          {Object.keys(currentWorkingAreas).map((key: WorkingAreaType) => (
            <OutputAccordion
              key={key}
              header={{
                label: text[key],
              }}
              variant={workingAreaVariant[key]}
              onChange={() =>
                setAsideToggleState(prev => ({
                  ...prev,
                  [key]: !prev[key],
                }))
              }
              isOpen={asideToggleState[key]}
            >
              {currentWorkingAreas[key].length === 0 && (
                <Heading4>{text.noFeatures}</Heading4>
              )}
              <JustificationContainer direction="column" gap="m">
                {currentWorkingAreas[key].map(
                  (workingArea: Client_WorkingArea, index) => (
                    <EntryCard
                      key={`${workingArea.feature.id}-${index}`}
                      sentiment={workingAreaSentiment[key]}
                      title={`${office?.name} werkgebied #${index + 1}`}
                      description={
                        key === 'inclusive' ? (
                          <AssignUserDropdown
                            onUpdateWorkingArea={onUpdateWorkingArea}
                            workingArea={workingArea}
                            officeId={officeId}
                          />
                        ) : null
                      }
                      selected={selectedWorkingAreas?.includes(
                        workingArea.feature.id as string,
                      )}
                      buttons={[
                        {
                          icon: 'delete',
                          appearance: 'danger',
                          onClick: () => {
                            if (drawRef.current) {
                              drawRef.current.delete(
                                workingArea.feature.id as string,
                              );
                              onDelete([workingArea.feature]);
                            }
                          },
                        },
                      ]}
                      onClick={() => {
                        if (drawRef.current && workingArea.feature.id)
                          drawRef.current.toggleSelection(
                            workingArea.feature.id,
                          );
                      }}
                    />
                  ),
                )}
              </JustificationContainer>
            </OutputAccordion>
          ))}
        </EntryList>
      </AsideContainer>

      <StyledAsideContainer>
        <AddWorkingAreaContainer
          activeDrawType={activeDrawType}
          onStopSelection={() => {
            setActiveDrawType(null);
            if (drawRef.current) drawRef.current.stopSelection();
          }}
          onStartSelection={type => {
            if (drawRef.current) {
              setActiveDrawType(type);
              drawRef.current.startSelection(type);
            }
          }}
        />
      </StyledAsideContainer>
    </MapBoxContext.Provider>
  );
};
const EntryList = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.space('base')};
  `,
);

const MapLimitContainer = styled.div<{}>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const AsideContainer = styled(BaseAside)<{ offsetY?: number }>(
  ({ theme, offsetY }) => css`
    top: calc(${offsetY || 0}px);
    bottom: ${theme.space('xxxxl')};
    display: flex;
    flex-direction: column;
    overflow-x: auto;

    ${scrollBarStyles}
  `,
);

const StyledAsideContainer = styled(AsideContainer)<{}>(
  ({ theme }) => css`
    top: unset;
    padding: 0;
    width: auto;
    right: calc(${ASIDE_WIDTH} + ${theme.space('l')});

    ${theme.mq.lessThan('tabletLandscape')`
      right: ${theme.space('l')};
      bottom: calc(${theme.space('xxxxl')} + ${theme.space('m')});
    `}
  `,
);

export default WorkingArea;
