import gql from 'graphql-tag';

import ContactListWithLastActivityFields from '../fragments/ContactListWithLastActivityFields';

export default gql`
  query GetContacts(
    $nodes: Node__Input!
    $accountId: ID!
    $query: String
    $limit: Int
    $sortBy: SortByAdvancedInput
    $page: Int
  ) {
    getContacts(
      nodes: $nodes
      accountId: $accountId
      query: $query
      limit: $limit
      sortBy: $sortBy
      page: $page
    ) {
      items {
        ...ContactListWithLastActivityFields
      }
      total
      totalContactsInAccount
    }
  }

  ${ContactListWithLastActivityFields}
`;
