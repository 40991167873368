import { useCallback } from 'react';
import type { SignupUtmInput } from '~/graphql/types';

type BaseEvent<T extends string> = {
  event: T;
};
type LoginEvent = BaseEvent<'login'> & {
  loginMethod: string;
};

type RegisterEvent = BaseEvent<'register'> & {
  message: string;
};

type CompleteRegistrationTypeEvent = BaseEvent<'completeRegistration'> & {
  planId: string;
} & SignupUtmInput;

type CompleteOnboardingWizardEvent = BaseEvent<'completeOnboardingWizard'>;

type ScreenSizeEvent = BaseEvent<'screenSize'> & {
  size: string;
};

export type TrackingEvent =
  | LoginEvent
  | RegisterEvent
  | ScreenSizeEvent
  | CompleteRegistrationTypeEvent
  | CompleteOnboardingWizardEvent;

// We export the function separately so we can call it from class based components
// But sadly these events won't be enriched with user or account context
// This should mimic gtag syntax
// ```
// function gtag(){dataLayer.push(arguments);}
//  gtag('event', '<event_name>', {
//    <event_parameters>
//  });
// ```
export const fireTrackingEvent = (event: TrackingEvent) => {
  global.window.dataLayer = global.window.dataLayer || [];

  function gtag() {
    // eslint-disable-next-line prefer-rest-params
    global.window.dataLayer.push(arguments);
  }

  const { event: name, ...parameters } = event;

  // @ts-ignore
  gtag('event', name, parameters);
};

const useFireTrackingEvent = () => {
  // Any context from hooks can be provided here
  const fireEvent = useCallback((event: TrackingEvent) => {
    fireTrackingEvent(event);
  }, []);

  return fireEvent;
};

export default useFireTrackingEvent;
