import styled, { css } from 'styled-components';

const PaginationButton = styled.button<{
  disabled?: boolean;
  selected?: boolean;
  chevronItem?: boolean;
  digit?: number;
}>`
  ${({ theme }) => css`
    padding: ${theme.space('xxxs')};
    margin: 0 ${theme.space('xxxs')};
    min-width: ${theme.space('l')};
    height: ${theme.space('l')};
    color: ${theme.color('grey')};
    border: ${theme.getTokens().border.width.s} solid ${theme.color('grey')};
    border-radius: ${theme.getTokens().border.radius.s};
    background-color: ${theme.color('white')};

    &:hover {
      border: ${theme.getTokens().border.width.s} solid
        ${theme.color('primary', 'light')};
    }
  `};

  ${({ disabled }) => {
    if (!disabled) {
      return css`
        cursor: pointer;
      `;
    }
    return null;
  }};

  ${({ digit }) => css`
    width: ${digit ? `${digit}em` : 'auto'};
  `};

  ${({ disabled, theme, selected, chevronItem }) => {
    if (selected) {
      return css`
        color: ${theme.color('primary', 'light')};
        border: ${theme.getTokens().border.width.s} solid
          ${theme.color('primary', 'light')};
      `;
    }

    if (chevronItem) {
      if (disabled) {
        return css`
          color: ${theme.color('grey')};
          background-color: ${theme.color('white', 'dark')};
          font-size: ${theme.fontSize('xs')};

          &:hover {
            border: ${theme.getTokens().border.width.s} solid
              ${theme.color('grey')};
          }
        `;
      }

      return css`
        color: ${theme.color('grey', 'dark')};
        font-size: ${theme.fontSize('xs')};
      `;
    }
    return null;
  }};
`;

export default PaginationButton;
