import React from 'react';

import {
  BillingCycle,
  NextPlanFragment,
  SelectedPlanFragment,
  useUpdatePlanMutation,
} from '~/graphql/types';

import useCurrentAccount from '~/hooks/useCurrentAccount';
import useSessionHydration from '~/hooks/useSessionHydration';
import CallToActionBlock from '~/components/organism/CallToActionBlock';
import { isNil } from 'ramda';
import { convertServerDateStringToDate, safeFormattedDate } from '~/util/date';
import useAddToast from '~/hooks/useAddToast';
import formatToastMessage from '~/util/formatToastMessage';

const billingCycleLabels = {
  [BillingCycle.Yearly]: 'Jaarlijks',
  [BillingCycle.Monthly]: 'Maandelijks',
};
const text = {
  buttonLabel: 'Heractiveer',
  header: 'Let op!',
  messageStart: (currentPlan: SelectedPlanFragment, endDate: string) =>
    `Het ${currentPlan.name} ${
      billingCycleLabels[currentPlan.billingCycle]
    } is opgezegd. Tot ${endDate}`,
  messageEnd: ' kan je gebruik blijven maken van DatHuis.',
  errorMsg:
    'Er is iets misgegaan bij het heractiveren van je abonnement. Probeer het nog een keer of neem contact op met ons.',
  successMessage: 'Het abonnement is opnieuw geactiveerd',
};
type Props = {
  currentPlan: SelectedPlanFragment;
  currentBillingCycle: BillingCycle;
  nextPlan?: NextPlanFragment | null;
  onSuccess: () => void;
};
const ReactivateSubscriptionBanner: React.FCC<Props> = ({
  currentPlan,
  currentBillingCycle,
  nextPlan,
  onSuccess,
}) => {
  const [, refetchSessionHydration] = useSessionHydration();
  const account = useCurrentAccount();
  const [updatePlan] = useUpdatePlanMutation();
  const addToast = useAddToast();

  if (isNil(nextPlan) || (!isNil(nextPlan) && !isNil(nextPlan.plan)))
    return null;

  const endDate = safeFormattedDate(
    convertServerDateStringToDate(nextPlan.changeDate),
  );

  return (
    <>
      <CallToActionBlock
        header={text.header}
        description={`${text.messageStart(currentPlan, endDate)}${
          text.messageEnd
        }`}
        icon={{
          background: 'danger',
          name: 'triangle',
        }}
        button={{
          label: text.buttonLabel,
          appearance: 'secondary',
          icon: 'arrow',
          onClick: () =>
            updatePlan({
              variables: {
                accountId: account.id,
                planId: currentPlan.id,
                billingCycle: currentBillingCycle,
              },
            })
              .then(({ data, errors }) => {
                if (!data || (errors && errors?.length > 0)) {
                  return addToast([
                    formatToastMessage(text.errorMsg, 'danger'),
                  ]);
                }
                addToast([formatToastMessage(text.successMessage, 'success')]);
                void refetchSessionHydration();
              })
              .then(onSuccess),
        }}
      />
      <br />
    </>
  );
};

export default ReactivateSubscriptionBanner;
