import styled, { css } from 'styled-components';
import { Container as InputContainer } from '~/components/atom/InputContainer';

const TextEditorContainer = styled(InputContainer)(
  ({ theme }) => css`
    width: 100%;
    padding: ${theme.space('xxs')};
  `,
);

export default TextEditorContainer;
