import React from 'react';
import Dropdown from '~/components/molecule/Dropdown';
import type { FormBuilder_Locale } from '~/graphql/types';
import useLocaleOptions from '~/components/page/Forms/components/Builder/hooks/useLocaleOptions';

export type Props = {
  currentLocale: FormBuilder_Locale;
  onChange: (locale: FormBuilder_Locale) => void;
};

const LanguageSwitcher: React.FCC<Props> = ({ currentLocale, onChange }) => {
  const localeOptions = useLocaleOptions();
  if (localeOptions.length === 0) return null;

  return (
    <Dropdown
      size="small"
      width="max-content"
      options={localeOptions}
      selectedOptionIdx={localeOptions.findIndex(
        curr => curr.key === currentLocale,
      )}
      onChange={option => onChange(option.option.key as FormBuilder_Locale)}
    />
  );
};

export default LanguageSwitcher;
