import React, { useState, useEffect, useMemo } from 'react';
import { Helmet as MetaTags } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';

import { FlowListData } from '~/components/page/Automation/Flows/util/composeFlowListData';
import {
  FlowData___ActionFragment,
  FlowFieldsV2Fragment,
  GetFlowsV2Query,
  GetFlowsV2QueryVariables,
  SortDirection,
  useDeleteFlowV2Mutation,
  useGetFlowsV2Query,
} from '~/graphql/types';

import TEST_ID from './index.testid';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import FlowListTable from '../components/FlowListTable';
import composeFlowListData from '../util/composeFlowListData';
import flowListColumns from '../util/flowListColumns';
import InfiniteScroll from '~/components/molecule/InfiniteScroll';
import {
  globalHistory,
  navigate,
  RouteComponentProps,
} from '@gatsbyjs/reach-router';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Loading from '~/components/atom/Loading';
import Button from '~/components/atom/Button';
import Toolbar from '~/components/molecule/Toolbar';
import { Heading1 } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useSortSettings from '~/hooks/useSortSettings';
import useResetFlowState from '../../v2/components/Builder/hooks/useResetFlowState';
import useDHFlag from '~/hooks/useDHFlag';
import { LINK_PREFIX } from '../../v2/components/Wizard';
import { isNil, prop, uniqBy } from 'ramda';
import { useSetRecoilState } from 'recoil';
import flowActions from '../../v2/state';
import getFlowActionsToClientActions from '~/components/page/Automation/v2/util/getFlowActionsToClientActions';
import DatHuisLoading from '~/components/atom/DatHuisLoading';
import generateMapsCombined from '../../v2/components/UpdateAction/components/Selector/utils/generateMapsCombined';
import EmptyStateComponent from '~/components/template/EmptyStateComponent';

const text = {
  pageTitle: 'Automation',
  title: 'Automation',
  addFlowLabel: 'Nieuwe flow',
  noFlows: 'Je hebt nog geen flows',
  emptyStateDescription:
    'Benader leads en relaties met gepersonaliseerde marketing campagnes.',
  notification:
    'Maak kennis met de nieuwe flowbuilder v2! Al je bestaande flows zijn beschikbaar en vind je onderaan deze pagina. Binnenkort wordt de bestaande flowbuilder vervangen. Al je bestaande flows worden dan automatisch overgezet.',
  deleteAllFlows: 'Delete all flows',
};

export const FLOW_LIST_LIMIT = 30;

const FlowList: React.FC<RouteComponentProps> = () => {
  const account = useCurrentAccount();
  const [version, setVersion] = useState<string>(uuidv4());
  const isDeveloper = useDHFlag('is-developer');

  const [sortSettings, updateSortSettings] =
    useSortSettings('flowSortSettings');

  const resetFlowState = useResetFlowState();

  useEffect(() => {
    resetFlowState();
  }, [resetFlowState]);

  const baseVariables = useMemo(
    () => ({
      accountId: account.id,
      limit: FLOW_LIST_LIMIT,
    }),
    [account],
  );

  const { data, fetchMore, networkStatus, loading, error, updateQuery } =
    useGetFlowsV2Query({
      variables: baseVariables,
      fetchPolicy: 'network-only',
    });

  const [deleteFlow] = useDeleteFlowV2Mutation();

  const onDeleteAllFlows = () => {
    data &&
      data.getFlowsV2 &&
      data.getFlowsV2.items.forEach(async item => {
        await deleteFlow({
          variables: {
            id: item.id,
            accountId: account.id,
          },
        });
      });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const [tableData, setTableData] = useState<Array<FlowListData>>([]);

  const firstItem = data?.getFlowsV2.items[0];
  const memoizedIBuilderContext = useMemo(() => {
    if (!data?.getFlowData) return null;

    const {
      subjectMap,
      conditionMap,
      subjectToConditionMap,
      directoryMap,
      primitiveInputMap,
      instanceMap,
    } = generateMapsCombined(data.getFlowData);

    const availableActions =
      data.getFlowData.availableActions.filter(
        (action): action is FlowData___ActionFragment => !isNil(action),
      ) ?? [];

    return {
      flowBlueprintId: firstItem?.id ?? '',
      opts: {
        subjectMap,
        conditionMap,
        subjectToConditionMap,
        directoryMap,
      },
      instanceMap,
      primitiveInputMap,
      primitiveInput: data.getFlowData.primitiveInput,
      primitiveListInput: data.getFlowData.primitiveListInput,
      superSubjects: data.getFlowData.superSubjects,
      subjects: data.getFlowData.subjects,
      instances: data.getFlowData.instance,
      accountId: account.id,
      availableActions,
      initialFlow: {
        flowName: firstItem?.name ?? 'no name',
        flowDescription: firstItem?.description ?? 'no description',
        enabled: firstItem?.enabled ?? true,
        maximumFlowRun: firstItem?.maximumFlowRun ?? 0,
        actions: [],
      },
    };
  }, [account.id, data?.getFlowData, firstItem]);

  useEffect(() => {
    if (data?.getFlowsV2 && memoizedIBuilderContext) {
      setTableData(
        composeFlowListData(
          data?.getFlowsV2.items || [],
          { ...baseVariables, nextToken: data?.getFlowsV2?.nextToken },
          version,
          sortSettings,
          memoizedIBuilderContext,
        ),
      );
    }
  }, [
    baseVariables,
    data?.getFlowsV2,
    memoizedIBuilderContext,
    sortSettings,
    version,
  ]);

  const nextToken = data?.getFlowsV2?.nextToken;

  const loadMore = () =>
    fetchMore({
      variables: {
        ...baseVariables,
        nextToken: nextToken || null,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        const prevData = previousResult.getFlowsV2;
        const newData = fetchMoreResult.getFlowsV2;

        return {
          ...previousResult,
          getFlowsV2: {
            ...prevData,
            items: [...prevData.items, ...(newData.items || [])],
            nextToken: newData.nextToken,
          },
        };
      },
    });

  const setActions = useSetRecoilState(flowActions);

  useEffect(() => {
    if (data && data.getFlowsV2.items.length !== 0) {
      const startActions = data.getFlowsV2.items
        .map(({ StartAction }) => StartAction)
        .filter(x => x) as FlowFieldsV2Fragment['Actions'];

      const newActions =
        startActions.length > 0
          ? getFlowActionsToClientActions(startActions)
          : [];

      setActions(prev => uniqBy(prop('id'), [...prev, ...newActions]));
    }
  }, [data, data?.getFlowsV2.items, setActions]);

  useEffect(
    () =>
      globalHistory.listen(() => {
        setActions(() => []);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!data) return <DatHuisLoading />;

  return (
    <ContentContainerDefault style={{ maxWidth: '1400px' }}>
      <MetaTags>
        <title>{text.pageTitle}</title>
      </MetaTags>
      <Heading1 color={{ group: 'primary' }} margin={[null, null, 'xl', null]}>
        {text.title}
      </Heading1>

      <JustificationContainer
        align="start"
        justification="start"
        margin={['xl', null, 'xxl', null]}
      >
        <Toolbar>
          <Button
            size="medium"
            appearance="secondary"
            label={text.addFlowLabel}
            icon="plus"
            onClick={() => navigate(`${LINK_PREFIX}/v2/`)}
            dataTestId={TEST_ID.OVERVIEW_HEADER}
          />
          {isDeveloper && (
            <Button
              size="medium"
              appearance="danger"
              label={text.deleteAllFlows}
              icon="trashcan"
              onClick={onDeleteAllFlows}
            />
          )}
        </Toolbar>
      </JustificationContainer>

      {loading ? (
        <Loading />
      ) : tableData.length === 0 ? (
        <EmptyStateComponent
          dataTestId={TEST_ID.EMPTY_STATE}
          header={text.noFlows}
          description={text.emptyStateDescription}
          buttonLabel={text.addFlowLabel}
          onButtonClick={() => navigate(`${LINK_PREFIX}/v2/`)}
        />
      ) : (
        <InfiniteScroll
          fetchMoreFn={loadMore}
          hasMore={nextToken != null}
          data-testid={TEST_ID.CONTAINER}
        >
          <FlowListTable
            columns={flowListColumns(
              (key: string, direction: SortDirection) => {
                updateSortSettings({
                  sortField: key,
                  sortDirection: direction,
                });
                setVersion(uuidv4());
              },
              version,
            )}
            data={tableData}
            loading={loading}
            networkStatus={networkStatus}
            error={error != null}
            onSuccessfulDelete={flowId => {
              updateQuery(getFlowsUpdateAfterRemoveQueryFunction(flowId));

              const nextID = uuidv4();
              setVersion(nextID);
            }}
            onSuccessfulEnabledChange={(newStatus, flowId) => {
              updateQuery(
                getFlowsUpdateAfterUpdatingStatusFunction(flowId, newStatus),
              );

              setVersion(uuidv4());
            }}
          />
        </InfiniteScroll>
      )}
    </ContentContainerDefault>
  );
};

const getFlowsUpdateAfterRemoveQueryFunction =
  (
    flowId: string,
  ): ((
    previousQueryResult: GetFlowsV2Query,
    options: { variables: GetFlowsV2QueryVariables },
  ) => GetFlowsV2Query) =>
  previousResult => {
    const newList = previousResult.getFlowsV2.items.filter(
      flow => flow.id !== flowId,
    );

    return {
      ...previousResult,
      getFlowsV2: {
        ...previousResult.getFlowsV2,
        items: newList,
      },
    };
  };

const getFlowsUpdateAfterUpdatingStatusFunction =
  (
    newStatus: boolean,
    flowId: string,
  ): ((
    previousQueryResult: GetFlowsV2Query,
    options: { variables: GetFlowsV2QueryVariables },
  ) => GetFlowsV2Query) =>
  previousResult => {
    const newList = previousResult.getFlowsV2.items.map(flow => {
      if (flow.id === flowId) {
        return {
          ...flow,
          enabled: newStatus,
        };
      }

      return flow;
    });

    return {
      ...previousResult,
      getFlowsV2: {
        ...previousResult.getFlowsV2,
        items: newList,
      },
    };
  };

export default FlowList;
