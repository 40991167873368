import { v4 as uuid } from 'uuid';
import type { Flow___ConditionPartial } from '../ConditionEditorV2';
import type {
  FlowData___PrimitiveInputFragment,
  FlowData___PrimitiveListInputFragment,
  Flow___ArgumentFragment,
} from '~/graphql/types';
import type { Flow___PrimitiveArgument } from '~/graphql/types.client';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import type { Crumb } from '../ConditionEditorV2/components/Breadcrumbs';
import SelectorComp from '~/components/organism/Selector';
import getFieldsByPath, {
  FlowPathEntry,
  FlowPath,
  GetFieldsByPathOpts,
} from './utils/getFieldsByPath';
import { isEqualSubject } from './utils/getSubject';
import getPointerForPath from './utils/getPointerForPath';
import { assertNever } from '~/util/assertNever';
import ConditionInput from '../ConditionEditorV2/components/PrimitiveInput';
import getValueForArgument from './utils/getValueForArgument';
import TEST_ID from './index.testid';
import RepresentationLabel from '../../../Builder/components/RepresentationLabel';
import ActionLabelAsText from '../../../Builder/components/ActionLabelAsText';
import useKeybinding from '~/hooks/useKeybinding';

export type Props = {
  /**
   * The initial path to open to, if an argument is selected it will be used instead
   */
  initialPath?: FlowPath;
  /**
   * The selected argument if there is one.
   */
  argument?: Flow___PrimitiveArgument;

  inputPrimitives?: Array<FlowData___PrimitiveInputFragment>;
  inputListPrimitives?: Array<FlowData___PrimitiveListInputFragment>;
  pointerOffset?: number | null;
  pointerLocation?: 'top' | 'bottom';
  opts: GetFieldsByPathOpts;
  onSelect: (
    selection: Flow___ArgumentFragment | Flow___ConditionPartial,
    opts?: { duplicateInput?: true },
  ) => void;
  onClose: () => void;
  children?: React.ReactNode;
  maxHeightInPx?: number;
  /** Used when we want to pass the ref as a prop */
  selectorRef?: React.RefObject<HTMLDivElement> | null;
};

const Selector = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      initialPath = [],
      argument,
      pointerOffset,
      pointerLocation = 'top',
      inputPrimitives = [],
      inputListPrimitives = [],
      onClose,
      onSelect,
      opts,
      children,
      selectorRef,
      ...rest
    },
    ref,
  ) => {
    const [primitiveInput, setPrimitiveInput] = useState<
      | FlowData___PrimitiveInputFragment
      | FlowData___PrimitiveListInputFragment
      | null
    >(null);
    const [showPrimitive, setShowPrimitive] = useState(false);
    const [currentPath, setCurrentPath] = useState(initialPath);
    const [currentFields, setCurrentFields] = useState(
      getFieldsByPath(initialPath, opts),
    );

    useKeybinding({
      callback: event => {
        event.preventDefault();
        event.stopPropagation();

        return onClose();
      },
      keys: 'escape',
    });

    useEffect(() => {
      setCurrentFields(getFieldsByPath(currentPath, opts));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath]);

    useEffect(() => {
      const matchingInputPrimitive = [
        ...inputPrimitives,
        ...inputListPrimitives,
      ].find(primitive =>
        opts.limitToInstanceSubjects?.find(limit =>
          isEqualSubject({ type: primitive.type }, { type: limit.type }),
        ),
      );

      if (!matchingInputPrimitive) return;

      setPrimitiveInput(matchingInputPrimitive);
      if (argument) setShowPrimitive(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argument]);

    if (currentFields.error !== undefined) {
      return <h1>{currentFields.error}</h1>;
    }

    const onFlowPathFieldSelect = (field: FlowPathEntry) => {
      switch (field.__typename) {
        case 'FlowData___SubjectField':
          return onSelect({
            id: uuid(),
            __typename: 'Flow___SubjectFieldCondition',
            args: field.args.map(() => null),
            type: field.parent.type,
            typeId: field.parent.typeId,
            field: field.key,
          });
        case 'FlowData___InstanceCondition': {
          return onSelect({
            id: uuid(),
            __typename: 'Flow___InstanceCondition',
            args: field.args.map(() => null),
            input: getPointerForPath(currentPath),
            type: field.type,
          });
        }
        case 'FlowData___InstanceField': {
          if (
            opts.limitToInstanceSubjects != null &&
            opts.limitToInstanceSubjects.some(subject =>
              isEqualSubject(subject, field.type),
            )
          ) {
            return onSelect({
              __typename: 'Flow___Argument_Pointer',
              pointer: getPointerForPath([...currentPath, field]),
            });
          }

          return setCurrentPath(prev => [...prev, field]);
        }
        case 'FlowData___Directory':
          return setCurrentPath(prev => [...prev, field]);
        default:
          assertNever(field);
      }
    };

    const breadcrumbs = currentPath.reduce(
      (curr, field, index) => {
        const key =
          field.__typename === 'FlowData___InstanceCondition'
            ? `${field.__typename}-${field.type}-${index}`
            : `${field.__typename}-${field.key}-${index}`;

        return [
          ...curr,
          {
            label: field.label,
            key,
          },
        ];
      },
      [
        {
          label: '...',
          key: 'root',
        },
      ],
    );

    const crumbs = breadcrumbs.map(crumb => ({
      ...crumb,
      label: <ActionLabelAsText str={crumb.label} />,
    }));

    return (
      <SelectorComp
        pointerPosition={{
          location: pointerLocation,
          offset: pointerOffset ?? 24,
        }}
        actions={[
          {
            key: 'close',
            icon: 'close',
            appearance: 'icon',
            onClick: onClose,
          },
        ]}
        crumbs={
          showPrimitive
            ? [
                {
                  label: '...',
                  key: 'root',
                },
                {
                  label: 'Waarde invoeren',
                  key: 'waardeinvoeren',
                },
              ]
            : crumbs
        }
        onBreadcrumbClick={(crumb: Crumb, index: number) => {
          if (crumb.key === 'root') {
            setShowPrimitive(false);

            return setCurrentPath([]);
          }
          if (showPrimitive) return;

          return setCurrentPath(currentPath.slice(0, index));
        }}
        dataTestId={TEST_ID.CONTAINER}
        ref={ref || selectorRef}
        {...rest}
      >
        {showPrimitive && primitiveInput != null ? (
          <ConditionInput
            input={primitiveInput}
            value={argument ? getValueForArgument(argument) : null}
            onChange={(value, opts) => onSelect(value, opts)}
          />
        ) : (
          <>
            {primitiveInput != null && currentPath.length === 0 && (
              <ListItem
                key={'Primitive'}
                onClick={() => setShowPrimitive(true)}
                data-objectid="custom-input"
              >
                Waarde invoeren
              </ListItem>
            )}

            {React.Children.map(
              children,
              child =>
                React.isValidElement(child) &&
                React.cloneElement(child, { currentPath, ...child.props }),
            )}

            {currentFields.result.map((field, index) => {
              const key =
                field.__typename === 'FlowData___InstanceCondition'
                  ? `${field.__typename}-${field.type}-${index}`
                  : `${field.__typename}-${field.key}-${index}`;

              return (
                <ListItem
                  key={key}
                  onClick={e => {
                    e.stopPropagation();
                    onFlowPathFieldSelect(field);
                  }}
                  data-testid={TEST_ID.LIST_ITEM}
                  data-objectid={`${field.__typename}-${
                    field.__typename === 'FlowData___InstanceCondition'
                      ? field.type
                      : field.key
                  }`}
                  // @ts-ignore ignored because it's only used in e2e tests
                  data-isconditiononself={field?.instance?.subject != null}
                >
                  <RepresentationLabel label={field.label} />
                </ListItem>
              );
            })}
          </>
        )}
      </SelectorComp>
    );
  },
);

export const ListItem = styled.li(
  ({ theme }) => css`
    padding: ${theme.space('xs')};
    cursor: pointer;
    color: ${theme.color('text')};
    border-radius: ${theme.getTokens().border.radius.base};
    transition: all 0.3s ease-out;
    /** We need the extra height when there are pointer values that overflow to the next line */
    line-height: 1.8;

    &:hover {
      background-color: ${theme.color('grey', 'light')};
      color: ${theme.color('grey', 'text')};
    }
  `,
);

export default Selector;
