import React from 'react';
import styled, { css } from 'styled-components';

const BackLink = () => (
  <LinkWrapper>
    <Link data-testid="go-back-btn" href="/-/">
      ← Ga terug
    </Link>
  </LinkWrapper>
);

const LinkWrapper = styled.div<{}>`
  text-align: left;
`;

const Link = styled.a<{}>`
  ${({ theme }) => css`
    color: ${theme.color('primary')};
  `};

  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default BackLink;
