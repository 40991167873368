import { pick } from 'ramda';
import {
  WidgetSettingsAppConfigurationItem,
  WidgetSettingsAppConfigurationItem__Input,
} from '~/graphql/types';
import { AppStatus } from '~/graphql/types.client';

/**
 * Returns an input for widgetAppConfigurationItems in AppStatus, if the configuration is there
 * @param {AppStatus} appStatus
 * @param {Array<WidgetSettingsAppConfigurationItem>} updatedItems
 * keywords: widgetAppConfiguration, appStatus, input, update
 */
const getWidgetAppConfigurationUpdate = (
  appStatus?: AppStatus,
  updatedItems?: Array<WidgetSettingsAppConfigurationItem>,
):
  | {
      widgetAppConfigurationItems: Array<WidgetSettingsAppConfigurationItem__Input>;
    }
  | {} => {
  if (!appStatus) return {};

  // If updated items is not empty, send updated items, otherwise send initial widgetAppConfigurationItems which is `[]` by default
  if ('widgetAppConfigurationItems' in appStatus) {
    const update = {
      widgetAppConfigurationItems: (
        updatedItems ?? appStatus.widgetAppConfigurationItems
      )?.map(pick(['id', 'visible'])),
    };

    return update;
  } else {
    return {};
  }
};

export default getWidgetAppConfigurationUpdate;
