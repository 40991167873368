import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { GetCouponQuery as GetCouponQueryResult } from '~/graphql/types';
import Button from '~/components/atom/Button';
import Input from '~/components/molecule/Input';
import query from '~/graphql/query/GetCoupon';
import Catalog from '~/Catalog';
import convertToCoupon, { Coupon } from '../../../../../utils/convertToCoupon';
import { asPriceString } from '~/util/money';

import TEST_ID from './index.testid';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import Validation from '~/util/Validation';
import TextButton from '~/components/atom/TextButton';
import InputGroup from '~/components/bad/Inputs/InputGroup';

type GetCouponQueryResponse = {
  data: GetCouponQueryResult;
};
export const text = {
  couponLabel: 'Kortingscode',
  activateButtonLabel: 'Activeer',
  errorMessage: Catalog.genericUnknownErrorMessageShort,
  noCouponFound: 'Ongeldige kortingscode',
  invalidCoupon: 'Deze kortingscode is niet meer geldig',
  couponApplied: (name: string) =>
    `Kortingscode ${name.toUpperCase()} toegepast`,
};
type Props = {
  amount: number;
  couponInfo: Coupon | null;
  setCouponInfo: (coupon: Coupon | null) => void;
  initialCouponCode?: string | null;
};
const CouponInput: React.FCC<Props> = ({
  amount,
  couponInfo,
  initialCouponCode,
  setCouponInfo,
}) => {
  const [couponText, setCouponText] = useState<string | null>(
    initialCouponCode ?? null,
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const account = useCurrentAccount();

  const handleCouponRequest = async () => {
    setLoading(true);

    if (!Validation.String.isNonEmptyString(couponText)) {
      setLoading(false);
      setError(text.invalidCoupon);
      return;
    }
    await client
      .query({
        query,
        variables: {
          accountId: account.id,
          coupon: couponText,
        },
      })
      .then(({ data }: GetCouponQueryResponse) => {
        setLoading(false);
        const { getCoupon } = data;

        if (getCoupon == null) {
          setError(text.noCouponFound);
        } else {
          const couponInfo = convertToCoupon(data);

          if (couponInfo == null) {
            setError(text.invalidCoupon);
          } else {
            setCouponInfo(couponInfo);
          }
        }
      })
      .catch(() => {
        setLoading(false);

        setError(text.errorMessage);
      });
  };

  useEffect(() => {
    // If we preset the couponCode, apply it on mount
    if (Validation.String.isNonEmptyString(couponText))
      void handleCouponRequest();
    // We only want to do this once it is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasError = error != null;
  if (hasError) {
    return (
      <Container>
        <InputGroup>
          <CouponTextContainer hasError={true}>
            <TextButton
              icon="close"
              appearance="danger"
              onClick={() => {
                setError(null);
              }}
              dataTestId={TEST_ID.CANCEL_BUTTON}
            />

            <CouponLabel data-testid={TEST_ID.ERROR_MESSAGE}>
              {error}
            </CouponLabel>
          </CouponTextContainer>
        </InputGroup>
      </Container>
    );
  }
  if (couponInfo == null) {
    return (
      <Container>
        <InputGroup>
          <>
            <StyledInput
              dataTestId={TEST_ID.INPUT}
              name="coupon"
              value={couponText}
              onChange={e => {
                if (e.target) {
                  setCouponText(e.target.value);
                }
              }}
              label={text.couponLabel}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  void handleCouponRequest();
                }
              }}
            />
            <StyledButton
              size="medium"
              ghost
              loading={loading}
              onClick={handleCouponRequest}
              data-testid={TEST_ID.ACTIVATE_BUTTON}
              label={text.activateButtonLabel}
            />
          </>
        </InputGroup>
      </Container>
    );
  }
  return (
    <Container>
      <InputGroup>
        <CouponTextContainer hasError={false}>
          <TextButton
            icon="trashcan"
            appearance="danger"
            onClick={() => {
              setCouponInfo(null);
            }}
            dataTestId={TEST_ID.CANCEL_BUTTON}
          />

          <CouponDescription>
            <CouponLabel data-testid={TEST_ID.COUPON_LABEL}>
              {text.couponApplied(couponInfo.name)}
            </CouponLabel>
            {couponInfo.durationString && (
              <CouponSubLabel data-testid={TEST_ID.COUPON_SUB_LABEL}>
                {couponInfo.durationString}
              </CouponSubLabel>
            )}
          </CouponDescription>

          <PriceLabel data-testid={TEST_ID.PRICE_OFF_LABEL}>
            - {asPriceString(couponInfo.calculateOff(amount))}
          </PriceLabel>
        </CouponTextContainer>
      </InputGroup>
    </Container>
  );
};
const StyledInput = styled(Input)`
  width: 100%;
`;

const CouponDescription = styled.div<{}>``;
const CouponLabel = styled.div<{}>``;
const CouponSubLabel = styled.div<{}>(
  ({ theme }) => css`
    font-size: ${theme.fontSize('base')};
  `,
);
const Container = styled.div<{}>``;

const PriceLabel = styled.span<{}>`
  margin-left: auto;
`;
const StyledButton = styled(Button)<{}>`
  margin-top: 15px;
  margin-left: 16px;
`;

type CouponTextContainerProps = {
  hasError: boolean;
};
const CouponTextContainer = styled.div<CouponTextContainerProps>`
  display: flex;
  width: 100%;
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme.space('xxs')} 0;
    font-size: ${theme.fontSize('m')};
  `};

  ${({ theme, hasError }) => {
    if (hasError) {
      return css`
        color: ${theme.color('danger')};
      `;
    } else {
      return css`
        color: ${theme.color('success')};
      `;
    }
  }}
`;

export default CouponInput;
