import React from 'react';
import styled, { css } from 'styled-components';
import { unified } from 'unified';
import parse from 'remark-parse';
import remark2react from 'remark-react';
import lineBreaks from 'remark-breaks';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
} from '~/components/atom/Typography';
import BlockQuote from '~/components/atom/BlockQuote';

export type Props = {
  value: string;

  options?: {
    remarkReactComponents: any;
  };
};

const Markdown: React.FCC<Props> = ({
  value,
  dataTestId,
  options,
  ...rest
}) => {
  const _options = options
    ? {
        remarkReactComponents: {
          ...defaultRemarkReactComponents,
          ...options.remarkReactComponents,
        },
      }
    : {
        remarkReactComponents: defaultRemarkReactComponents,
      };

  return (
    <Container data-testid={dataTestId} {...rest}>
      {
        unified()
          .use(parse)
          .use(lineBreaks)
          .use(remark2react, _options)
          .processSync(value).result as React.ReactElement
      }
    </Container>
  );
};

export const defaultRemarkReactComponents = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
  h5: Heading5,
  p: ({ children }: React.HTMLAttributes<HTMLParagraphElement>) => (
    <>{children}</>
  ),
  a: (props: React.HTMLAttributes<HTMLAnchorElement>) => (
    <InlineAnchor {...props} target="_blank" rel="noreferrer noopener" />
  ),
  br: () => <br />,
  blockquote: BlockQuote,
};

const Container = styled.div``;

export const InlineAnchor = styled.a<React.HTMLAttributes<HTMLAnchorElement>>(
  ({ theme }) => css`
    color: ${theme.color('text')};
    margin: 0 ${theme.space('xxxs')};
  `,
);

export default Markdown;
