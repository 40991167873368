import React from 'react';

import CompanyDetailsForm from './CompanyDetailsForm';
import Validation from '~/util/Validation';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import {
  useInsertAccountMutation,
  useInsertOfficeMutation,
} from '~/graphql/types';
import getStoredUTMParams from '~/util/getStoredUTMParams';

export type FormData = {
  company: string | null;
};
type Props = {
  afterSubmit: () => void;
};
const text = {
  noName: 'Voer de naam van jouw kantoor',
  errorMessage: Catalog.genericUnknownErrorMessage,
  defaultOfficeName: 'Hoofdvestiging',
};

const CompanyDetails: React.FCC<Props> = ({ afterSubmit }) => {
  const [insertAccount, { error, loading }] = useInsertAccountMutation({});
  const [
    insertOffice,
    { error: insertOfficeError, loading: insertOfficeLoading },
  ] = useInsertOfficeMutation({
    onCompleted: () => {
      afterSubmit();
    },
  });

  const validate = ({ company }: FormData) => {
    const errors: {
      company: string | undefined;
    } = {
      company: undefined,
    };

    if (Validation.String.isEmpty(company)) {
      errors.company = text.noName;
    }

    return errors;
  };

  const submitError = error || insertOfficeError ? text.errorMessage : false;

  const handleSubmit = ({ company }: FormData) => {
    if (company === null) {
      throw Error(
        `${cleanedFilename(
          __filename,
        )} | Should not occur | Company field is empty but user somehow submitted it`,
      );
    }

    const utm = getStoredUTMParams();

    return insertAccount({
      variables: {
        account: {
          name: company,
          utm,
        },
      },
    }).then(async result => {
      if (result && result.data) {
        const { insertAccount } = result.data;

        await insertOffice({
          variables: {
            accountId: insertAccount.id,
            office: { name: text.defaultOfficeName },
          },
        });
      }
    });
  };

  return (
    <CompanyDetailsForm
      handleSubmit={handleSubmit}
      validate={validate}
      submitError={submitError}
      loading={loading || insertOfficeLoading}
      initialValues={{ company: null }}
    />
  );
};

export default CompanyDetails;
