import React from 'react';
import styled from 'styled-components';

export type Props = {};

const Office: React.FCC<Props> = ({ dataTestId, ...rest }) => (
  <Container data-testid={dataTestId} {...rest}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 60.1997H71"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.3 28.1997H15.5V60.2997H65.3V28.1997Z"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9 36.0996H21.1V60.1996H32.9V36.0996Z"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5 18.8999H13.2V28.1999H67.5V18.8999Z"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9 48.1997H29.6"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.3 34.5996H37.9V52.0996H60.3V34.5996Z"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8 41.1997H56.5"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 36.0996V42.3996C4.5 45.9996 8.6 47.2996 8.6 47.2996C8.6 47.2996 12.7 45.9996 12.7 42.3996V36.0996H4.5Z"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 36.0996H17.6"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2 18.8998L40.4 11.7998L59.5 18.8998"
        stroke="#4A4A4A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Container>
);

const Container = styled.div<{}>``;

export default Office;
