import { isNil, lensIndex, over, remove } from 'ramda';
import React, { useCallback, useRef, useState } from 'react';
import {
  FormBuilder_Locale,
  FormBuilder_ScreenNode,
  Maybe,
  type FormBuilder_ScreenNode_Block,
} from '~/graphql/types';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ScreenPreview from './components/ScreenPreview';
import Button from '~/components/atom/Button';
import type { EditProps } from '../..';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  type SetterOrUpdater,
} from 'recoil';
import { nodeById, nodesSelector } from '../../../../state/nodesAndEvents';
import DnDContext from '~/contexts/DnDContext';
import LanguageSwitcher from './components/LanguageSwitcher';
import BlocksArea from './components/BlocksArea';
import ComponentsCollectionArea from './components/ComponentsCollectionArea';
import EditBlockArea from './components/EditBlockArea';
import { AREA_HEIGHT } from './constants';
import ErrorScreen from '~/components/page/ErrorScreen';
import { issuesByPath } from '../../../../state/issues';
import { autoGeneratedEvent } from '../../../../state/autoGeneratedEvent';
import useConfirmModal from '~/hooks/useConfirmModal';
import isBlockWithOutput from '../../../../utils/isBlockWithOutput';
import SectionContainer from './components/SectionContainer';
import TopBar from './components/TopBar';

export type ScreenFormState = FormBuilder_ScreenNode;
export type Props = EditProps<ScreenFormState>;

const text = {
  goBackButtonLabel: 'Ga terug',
  preview: 'Voorbeeld van formulier',
  deleteWarningLabels: {
    title: 'Pas op!',
    message:
      'Het is belangrijk om dezelfde velden te blijven gebruiken voor uw automatisering etc. Weet je zeker dat je dit veld wilt verwijderen?',
    buttonConfirmTitle: 'Verwijder',
    buttonCancelTitle: 'Annuleren',
  },
};

const EditNodeScreen: React.FCC<Props> = ({ id, onSave, onCancel }) => {
  const setNodesState = useSetRecoilState(nodesSelector);
  const setAutoGeneratedEvent = useSetRecoilState(autoGeneratedEvent);

  const [node, setNodeState] = useRecoilState(nodeById(id)) as [
    Maybe<FormBuilder_ScreenNode>,
    SetterOrUpdater<FormBuilder_ScreenNode | null>,
  ];

  const [focusedBlock, setFocusedBlock] =
    useState<FormBuilder_ScreenNode_Block | null>(null);

  const onBlockFocus = (block: Maybe<FormBuilder_ScreenNode_Block> | null) =>
    setFocusedBlock(block);

  const onBlockChange = (update: FormBuilder_ScreenNode_Block) => {
    const currentBlockIndex = node?.blocks.findIndex(
      block => block.key === focusedBlock?.key,
    );

    if (!focusedBlock || isNil(currentBlockIndex) || currentBlockIndex === -1)
      return;

    setNodeState(prev => {
      if (!prev) return prev;

      const lens = lensIndex<FormBuilder_ScreenNode_Block>(currentBlockIndex);
      const nextBlocks = over(
        lens,
        block => ({ ...block, ...update }),
        prev.blocks,
      );

      const updatedBlock = nextBlocks[currentBlockIndex];

      setFocusedBlock(prev => ({ ...prev, ...updatedBlock }));

      return {
        ...prev,
        blocks: nextBlocks,
      };
    });

    setAutoGeneratedEvent({});
  };

  const [currentLocale, setCurrentLocale] = useState<FormBuilder_Locale>(
    FormBuilder_Locale.Nl,
  );
  const issues = useRecoilValue(issuesByPath([id]));

  const [activeTab, setActiveTab] = useState<'builder' | 'preview'>('builder');

  // We keep track of the shape we got initially so we can restore it on cancel.
  const initialState = useRef<FormBuilder_ScreenNode>({
    id,
    blocks: node?.blocks ?? [],
    name: node?.name ?? '',
    __typename: 'FormBuilder_ScreenNode',
    defaultNext: node?.defaultNext,
  });

  const blocksAreaRef = useRef<HTMLDivElement>(null);

  const scrollToBlock = useCallback((blockKey: string) => {
    if (blocksAreaRef.current) {
      const blockElement = blocksAreaRef.current.querySelector(
        `[data-block-key="${blockKey}"]`,
      );
      if (blockElement) {
        blockElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, []);

  const onDelete = useCallback((block, index) => {
    setFocusedBlock(null);
    setNodeState(prev => {
      if (!prev) return prev;

      const nextBlocks = remove(index, 1, prev.blocks);

      return {
        ...prev,
        blocks: nextBlocks,
      };
    });

    // Remove all mapping items in Event nodes that uses the deleted block
    setNodesState(prev =>
      prev.map(node => {
        if (node.__typename === 'FormBuilder_EventNode') {
          return {
            ...node,
            mapping: node.mapping.filter(
              item => !item.pointer[1].includes(block.key),
            ),
          };
        }
        return node;
      }),
    );

    setAutoGeneratedEvent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showConfirmModal, onConfirmFn] = useConfirmModal(
    text.deleteWarningLabels,
    () => {},
    () => {},
    undefined,
    false,
    'destructive',
  );

  if (!node) return <ErrorScreen />;

  if (activeTab === 'preview') {
    return (
      <JustificationContainer
        direction="column"
        width="100%"
        padding={['l', 'xl']}
      >
        <SectionContainer
          justification="end"
          gap="m"
          margin={[null, null, 'l', null]}
        >
          <Button
            ghost
            icon="arrowLeft"
            label={text.goBackButtonLabel}
            onClick={() => setActiveTab('builder')}
          />
        </SectionContainer>
        <ScreenPreview width="100%" node={node} />
      </JustificationContainer>
    );
  }

  return (
    <JustificationContainer
      direction="column"
      width="100%"
      padding={['l', 'xl']}
    >
      <TopBar
        node={node}
        setNodeState={setNodeState}
        onSave={onSave}
        onCancel={onCancel}
        initialState={initialState}
        hasIssues={issues.length > 0}
      />

      {/* Builder content */}
      <DnDContext>
        <JustificationContainer
          direction="column"
          justification="space-between"
          width="100%"
          height="100%"
        >
          <JustificationContainer width="100%" gap="m">
            {/* Left section */}
            <ComponentsCollectionArea id={id} />

            {/* Middle section */}
            <JustificationContainer
              style={{
                width: '100%',
                height: AREA_HEIGHT,
                overflowY: 'scroll',
              }}
              width="100%"
              direction="column"
              gap="base"
            >
              <SectionContainer>
                <Button
                  ghost
                  icon="eye"
                  size="small"
                  label={text.preview}
                  onClick={() =>
                    setActiveTab(
                      activeTab === 'builder' ? 'preview' : 'builder',
                    )
                  }
                />
                <LanguageSwitcher
                  currentLocale={currentLocale}
                  onChange={locale => setCurrentLocale(locale)}
                />
              </SectionContainer>

              <BlocksArea
                nodeId={node.id}
                ref={blocksAreaRef}
                issues={issues}
                focusedBlock={focusedBlock || null}
                onBlockFocus={onBlockFocus}
                currentLocale={currentLocale}
                onDelete={(block, index) => {
                  if (isBlockWithOutput(block)) {
                    onConfirmFn(() => onDelete(block, index));
                    return showConfirmModal();
                  }
                  onDelete(block, index);
                }}
              />
            </JustificationContainer>

            {/* Right section */}
            {(focusedBlock || issues.length > 0) && (
              <>
                <JustificationContainer
                  style={{
                    height: AREA_HEIGHT,
                    overflowY: 'scroll',
                    maxWidth: '300px',
                  }}
                  width="100%"
                  direction="column"
                  gap="base"
                >
                  <EditBlockArea
                    focusedBlock={focusedBlock || null}
                    node={node}
                    id={id}
                    onBlockChange={onBlockChange}
                    onBlockFocus={(block: FormBuilder_ScreenNode_Block) => {
                      onBlockFocus(block);
                      if (block) scrollToBlock(block.key);
                    }}
                  />
                </JustificationContainer>
              </>
            )}
          </JustificationContainer>
        </JustificationContainer>
      </DnDContext>
    </JustificationContainer>
  );
};

export default EditNodeScreen;
