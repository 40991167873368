/**
 * A hook to provide a component with the size of the viewport. It will give the user:
 * [0] - viewportSize: { width: number, height: number }
 *
 * It expects:
 *   onViewportChange - a function that is called whenever the viewport has been changed if the user wants to be eventdriven
 */

import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

type ViewportSize = {
  width: number;
  height: number;
};
type ReturnProps = [ViewportSize];

/**
 * @deprecated use src/hooks/useViewportSize
 */
const useViewportSize = (
  onChange?: (size: ViewportSize) => void,
): ReturnProps => {
  const [viewportSize, setViewportSize] = useState<ViewportSize>({
    width: global.window.innerWidth,
    height: global.window.innerHeight,
  });

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      const newSize = {
        width: global.window.innerWidth,
        height: global.window.innerHeight,
      };
      setViewportSize(newSize);

      if (onChange != null) {
        onChange(newSize);
      }
    }, 200);

    // Also call on load
    if (onChange != null) {
      onChange({
        width: global.window.innerWidth,
        height: global.window.innerHeight,
      });
    }

    global.window.addEventListener('resize', calcInnerWidth);

    return () => global.window.removeEventListener('resize', calcInnerWidth);
  }, [onChange]);

  return [viewportSize];
};

export default useViewportSize;
