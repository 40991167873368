import React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import Divider from '~/components/atom/Divider';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  Heading4,
  Variant,
  Heading5,
  Label,
} from '~/components/atom/Typography';
import type { OutputMap } from '~/components/organism/WizardSteps';
import { AppsAndItemsToEnable } from '~/components/organism/WizardSteps/OnboardingWizardSteps/SelectAppsForWidget';
import getAppPriceBySetupFlow from '~/components/page/Apps/StellaxAI/utils/getAppPriceBySetupFlow';
import { AppsHashMap } from '~/hooks/useApps';
import { ANIMATION_CONFIG } from '~/styles/constants';
import getPriceWithInterval from '~/util/getPriceWithInterval';
import { asPriceString } from '~/util/money';

const getTotal = (
  appsToEnable: AppsAndItemsToEnable | null,
  apps: AppsHashMap,
): number => {
  let total = 0;
  if (appsToEnable) {
    Object.keys(appsToEnable).map(key => {
      total += apps[key].price?.amount ?? 0;
    });
  }

  return total;
};

const text = {
  summary: 'Overzicht',
};

export type Props = {
  apps: AppsHashMap;
  confirmedAppsToEnable: AppsAndItemsToEnable;
  outputMap: OutputMap;
};

const Summary: React.FCC<Props> = ({
  dataTestId,
  apps,
  confirmedAppsToEnable,
  outputMap,
  ...rest
}) => {
  const animation = useSpring(ANIMATION_CONFIG);
  const totalAmount = getTotal(confirmedAppsToEnable, apps);

  return (
    <Container style={animation}>
      <JustificationContainer
        {...rest}
        dataTestId={dataTestId}
        direction="column"
        width="100%"
        padding={['base']}
        backgroundColor={{ group: 'info', variant: 'translucent' }}
        border={{ radius: 'base' }}
      >
        <Heading4 variant={Variant.primary}>{text.summary}</Heading4>
        {Object.keys(confirmedAppsToEnable).map(key => {
          const name = apps[key].name;
          const price = getPriceWithInterval(
            getAppPriceBySetupFlow({ app: apps[key], outputMap }),
          );
          return (
            <JustificationContainer key={key} align="center" gap="xxs">
              - {name}:{' '}
              <Label withoutMargin color={{ group: 'primary' }}>
                {price}
              </Label>
            </JustificationContainer>
          );
        })}

        {totalAmount > 0 && (
          <>
            <Divider margin={['s', null]} />
            <Heading5 variant={Variant.primary}>
              Totaal: {asPriceString(totalAmount)} per maand
            </Heading5>
          </>
        )}
      </JustificationContainer>
    </Container>
  );
};

const Container = styled(animated.section)`
  width: 100%;
`;

export default Summary;
