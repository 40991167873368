import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet as MetaTags } from 'react-helmet';

import {
  GetContactQuery as GetContactQueryType,
  useGetContactQuery,
} from '~/graphql/types';

import Validation from '~/util/Validation';
import ErrorTypes from '~/ErrorTypes';
import { getErrorTypes } from '~/util/errorHandling';
import Catalog from '~/Catalog';
import cleanedFilename from '~/util/cleanedFilename';
import ContentContainerDefault from '~/components/molecule/ContentContainer/Default';
import TEST_ID from './index.testid';
import type { RouteComponentProps } from '@gatsbyjs/reach-router';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import useMainOffice from '~/hooks/useMainOffice';
import useCurrentUser from '~/hooks/useCurrentUser';
import ActivityTabs from '~/components/organism/ActivityTabs';
import SendMessageTab from '~/components/organism/ActivityTabs/components/SendMessageTab';
import LogActivityTab from '~/components/organism/ActivityTabs/components/LogActivityTab';
import CreateNewTaskTab from '~/components/organism/ActivityTabs/components/CreateNewTaskTab';
import ContactDetailsBlockComponent from '../ContactDetailsBlockComponent';
import ContactsContextProvider from '../../ContactsContext/ContactsContextProvider';
import { EventTimelineWithTaskModal } from '~/components/template/EventTimelineV2';
import useUpdateTimelineFn from '~/hooks/useUpdateTimelineFn';
import ConversionPage from '~/components/template/ConversionPage';
import Loading from '~/components/atom/Loading';
import AppErrorScreen from '~/components/template/AppErrorScreen';

export const text = {
  addLogTabLabel: Catalog.addLogTabLabel,
  pageTitle: 'Contact',
  contactListTitle: 'Contactenlijst',
  noContactFound: 'Geen contact gevonden',
  addTaskLabel: Catalog.addTaskTabLabel,
  errorTitle: 'Geen data gevonden',
  sendEmailLabel: 'Verstuur e-mail',
};
type Props = RouteComponentProps<{ contactId: string }>;
const Contact: React.FC<Props> = ({ contactId }) => {
  const account = useCurrentAccount();

  const { loading, data, error } = useGetContactQuery({
    variables: {
      accountId: account.id,
      contactId: contactId as string,
    },
  });

  if (loading) return <Loading />;

  const errorComponent = getErrorComponent(error, data);
  if (errorComponent != null) {
    return errorComponent;
  }

  if (data == null || data.getContact == null) {
    throw Error(
      `${cleanedFilename(
        __filename,
      )} | Should not occur | data is null, getErrorComponent should have found that`,
    );
  }

  const { getContact } = data;

  if (getContact.permission?.__typename === 'Permission_AccessDenied_Plan') {
    return <ConversionPage slug={getContact.permission.reason.id} />;
  }

  return <RenderingComponent getContact={getContact} />;
};

const getErrorComponent = (error, data): JSX.Element | null => {
  if (error != null) {
    const errorTypes = getErrorTypes(error);
    if (
      errorTypes.includes(ErrorTypes.invalidContactId) ||
      errorTypes.includes(ErrorTypes.notFoundError)
    ) {
      return <AppErrorScreen message={text.noContactFound} />;
    } else {
      return <AppErrorScreen />;
    }
  }

  if (!data || !data.getContact) {
    return <AppErrorScreen />;
  }

  if (data.getContact.status === 'DELETING') {
    return <AppErrorScreen message={text.noContactFound} />;
  }

  return null;
};

type RenderingProps = {
  getContact: NonNullable<GetContactQueryType['getContact']>;
};
const RenderingComponent = ({ getContact }: RenderingProps) => {
  const me = useCurrentUser();
  const account = useCurrentAccount();
  const { name, email, id: contactId } = getContact;
  const updateTimelineFn = useUpdateTimelineFn(contactId);

  const breadcrumbName: string = Validation.String.isNonEmptyString(name)
    ? name
    : email;

  const mainOffice = useMainOffice(me.id);

  let defaultAssignedOfficeId;
  if (mainOffice != null) {
    defaultAssignedOfficeId = mainOffice.id;
  }
  const defaultAssignedUserId = me.id;

  return (
    <ContactsContextProvider>
      <ContentContainerDefault
        breadcrumbs={[
          { to: '/-/contacts', label: text.contactListTitle },
          { label: breadcrumbName },
        ]}
      >
        <MetaTags>
          <title>{text.pageTitle}</title>
        </MetaTags>

        <Container data-testid={TEST_ID.CONTACT_PAGE}>
          <ContactDetailsBlockComponent getContact={getContact} />
          <TaskAndTimelineContainer>
            <ActivityTabs bordered>
              <SendMessageTab
                label={text.sendEmailLabel}
                datatestId="send-message"
                contact={{
                  id: contactId,
                  name,
                  email,
                }}
                onSuccess={newEmailActivity => {
                  updateTimelineFn(newEmailActivity);
                }}
              />
              <LogActivityTab
                label={text.addLogTabLabel}
                datatestId="log-activity"
                contactId={contactId}
                onSuccess={newLogActivity => {
                  updateTimelineFn(newLogActivity);
                }}
              />
              <CreateNewTaskTab
                isNew={true}
                taskDetails={null}
                label={text.addTaskLabel}
                datatestId="add-task"
                contactId={contactId}
                accountId={account.id}
                handleSetContactError={() => {
                  /** We can ignore this call, there is no set contact error to handle */
                }}
                defaultAssignedOfficeId={defaultAssignedOfficeId}
                defaultAssignedUserId={defaultAssignedUserId}
                onSuccess={newTask => {
                  updateTimelineFn(newTask);
                }}
              />
            </ActivityTabs>
            <TimelineContainer>
              <EventTimelineWithTaskModal contact={getContact} />
            </TimelineContainer>
          </TaskAndTimelineContainer>
        </Container>
      </ContentContainerDefault>
    </ContactsContextProvider>
  );
};

const TaskAndTimelineContainer = styled.div<{}>`
  grid-column: task-timeline-start / task-timeline-end;
`;

const TimelineContainer = styled.div<{}>(
  ({ theme }) => css`
    margin-top: ${theme.space('xl')};
  `,
);

const CONTACTS_BLOCK_WIDTH = 320;

const Container = styled.div<{}>`
  display: grid;
  grid-template-rows: auto;

  ${({ theme }) => css`
    grid-column-gap: ${theme.space('xl')};
    grid-template-columns:
      [detail-block-start] ${CONTACTS_BLOCK_WIDTH}px [detail-block-end task-timeline-start] calc(
        100% - ${CONTACTS_BLOCK_WIDTH}px - ${theme.space('xl')}
      )
      [task-timeline-end];
  `};
`;

export default Contact;
