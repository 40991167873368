import React from 'react';
import Label from '~/components/template/EventTimelineV2/components/Block/components/Data/components/Label';
import Link from '~/components/molecule/Link';
import Markdown from '~/components/molecule/Markdown';
import {
  Body,
  Heading4,
  Heading5,
  Heading6,
  Strong,
  Variant,
} from '~/components/atom/Typography';
import { MarkdownBlock } from '~/graphql/types';
import styled, { css } from 'styled-components';
import { BLUR_STYLES } from '../styles';

export type Props = Omit<MarkdownBlock, '__typename' | 'id'> & {
  dataTestId?: string;
  withoutMargin?: boolean;
};

const MarkdownBlockComponent: React.FC<Props> = ({
  css,
  label,
  value,
  withoutMargin,
  blurred,
}) => (
  <>
    {label && <Label>{label}</Label>}

    <StyledMarkdown
      value={value}
      options={{
        remarkReactComponents: getComponents({ css, withoutMargin }),
      }}
      $blurred={blurred || false}
    />
  </>
);

export default MarkdownBlockComponent;

const StyledMarkdown = styled(Markdown)<{ $blurred: boolean }>(
  ({ $blurred }) => css`
    ${$blurred && BLUR_STYLES};
  `,
);

const getComponents = (extraProps?: {
  css?: string | null;
  withoutMargin?: boolean;
}) => ({
  h1: props => (
    <Heading4
      {...props}
      {...extraProps}
      variant={Variant.primary}
      color={{ group: 'primary' }}
      css={extraProps?.css}
    />
  ),
  h2: props => (
    <Heading5
      {...props}
      {...extraProps}
      fontWeight="semiBold"
      color={{ group: 'text' }}
      css={extraProps?.css}
    />
  ),
  h3: props => (
    <Heading6
      {...props}
      {...extraProps}
      color={{ group: 'text', variant: 'light' }}
      fontWeight="regular"
      css={extraProps?.css}
    />
  ),
  strong: props => (
    <Strong
      {...props}
      {...extraProps}
      inline
      color={{ group: 'primary', variant: 'light' }}
      css={extraProps?.css}
      style={{ fontSize: 'inherit' }}
    />
  ),
  p: props => (
    <Body size="s" {...props} {...extraProps} css={extraProps?.css} />
  ),
  a: props => (
    <Link {...props} {...extraProps} to={props.href} css={extraProps?.css} />
  ),
});
