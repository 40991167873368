import Color from 'color';

export type ColorVariant = {
  base: string;
  text: string;
  light: string;
  dark: string;
  translucent: string;
};

export type IColorPalette<T> = {
  text: T;
  white: T;
  primary: T;
  secondary: T;
  tertiary: T;
  danger: T;
  warning: T;
  info: T;
  success: T;
  grey: T;
  accent: T;
};
export type SystemColorPalette = IColorPalette<ColorVariant>;
export type BaseColor = keyof SystemColorPalette;
export type BaseColorVariant = keyof ColorVariant;
export type ThemeColor = {
  group: BaseColor;
  variant?: BaseColorVariant;
};

/** If you change anything, always make sure that Colours documentation is inline with this object,
 *  see `docs/2 Colours.stories.mdx` */
const colorPalette: SystemColorPalette = {
  text: {
    base: '#4A4A4A',
    text: '#4A4A4A',
    light: '#A4A4A4',
    dark: '#4A4A4A',
    translucent: '#4A4A4A15',
  },

  white: {
    light: '#fff',
    text: '#000',
    base: '#fff',
    dark: '#F7F7F7',
    translucent: '#fff',
  },

  primary: {
    light: '#2676A5',
    base: '#164663',
    text: '#fff',
    dark: '#164663',
    translucent: '#2676A515',
  },

  secondary: {
    light: '#2676A5',
    base: '#164663',
    text: '#fff',
    dark: Color('#164663').darken(0.1).hex(),
    translucent: '#2676A515',
  },

  tertiary: {
    light: '#f2f6f7',
    base: '#d9f1ff',
    text: '#000',
    dark: '#798697',
    translucent: '#2676A515',
  },

  grey: {
    base: '#BFC5CD',
    text: '#000',
    light: '#EDEFF2',
    dark: '#AAAAAA',
    translucent: '#BFC5CD15',
  },

  danger: {
    base: '#DD121B',
    text: '#fff',
    light: Color('#DD121B').lighten(0.1).hex(),
    dark: Color('#DD121B').darken(0.1).hex(),
    translucent: '#B9020B15',
  },
  warning: {
    base: '#ECB75C',
    text: '#fff',
    light: '#FFF8EC',
    dark: Color('#ECB75C').darken(0.1).hex(),
    translucent: '#ECB75C15',
  },
  info: {
    base: '#5191B7',
    text: '#fff',
    light: Color('#5191B7').lighten(0.1).hex(),
    dark: Color('#5191B7').darken(0.1).hex(),
    translucent: '#5191B715',
  },
  success: {
    base: '#3DA07B',
    text: '#fff',
    light: Color('#3DA07B').lighten(0.1).hex(),
    dark: Color('#3DA07B').darken(0.1).hex(),
    translucent: '#3DA07B15',
  },
  accent: {
    base: '#fc7e64',
    text: '#fff',
    light: Color('#fc7e64').lighten(0.1).hex(),
    dark: Color('#fc7e64').darken(0.1).hex(),
    translucent: '#fc7e6415',
  },
};

export default colorPalette;
