import styled, { css } from 'styled-components';

export const TitleContainer = styled.div<{}>`
  display: flex;
  align-items: center;
`;

export const FiltersContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

export const LineElement = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    max-width: 5rem;
    width: 100%;
    margin-left: 1rem;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 100%;

    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';

      border-right: ${theme.getTokens().border.width.base} solid
        ${theme.color('grey')};
      border-bottom: 5px solid transparent;
      height: calc(100% + 5px + ${theme.space('base')});
      width: 50%;
    }
  `,
);

export const GroupContainer = styled.div<{}>(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    min-width: 700px;
    width: 100%;
    position: relative;
    margin-bottom: ${theme.space('base')};

    &:first-child ${LineElement}:after {
      border-top-right-radius: 5px;
      border-top: ${theme.getTokens().border.width.base} solid
        ${theme.color('grey')};
    }

    &:last-child ${LineElement}:after {
      border-bottom-right-radius: 5px;
      border-bottom: ${theme.getTokens().border.width.base} solid
        ${theme.color('grey')};

      height: 100%;
    }
  `,
);

export const ButtonsContainer = styled.div<{}>(
  ({}) => css`
    display: flex;
    width: 100%;
    justify-content: flex-end;
  `,
);
