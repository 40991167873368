import React from 'react';
import styled, { css } from 'styled-components';

export default () => (
  <Container data-testid="app-footer">
    <Disclaimer>&copy; DatHuis B.V. {new Date().getFullYear()}</Disclaimer>
  </Container>
);

export const FOOTER_HEIGHT = 40;

const Container = styled.div<{}>`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    z-index: ${theme.getTokens().zIndex.footer};
    box-shadow: ${theme.getTokens().boxShadow.base};
    min-height: ${FOOTER_HEIGHT}px;
    background-color: ${theme.color('white')};
    padding-left: ${theme.space('xxxxl')};
  `};
`;

const Disclaimer = styled.span<{}>`
  ${({ theme }) => css`
    font-size: ${theme.fontSize('s')};
    color: ${theme.color('text')};
  `};
`;
