import React, { useState } from 'react';
import styled from 'styled-components';

import Overlay from '~/components/organism/ModalsV2/Overlay';
import Modal from '~/components/organism/ModalsV2/Modal';
import TaskModalContent, { ModalProps } from './components/TaskModalContent';
import ConfirmModal from '~/components/template/ConfirmModal';

const text = {
  title: 'Let op: openstaande wijzigingen',
  message:
    'Door weg te navigeren van de pagina gaan de niet opgeslagen gegevens verloren.',
  buttonConfirmTitle: 'Wijzigingen vergeten',
};

const TaskModal: React.FC<ModalProps> = ({
  onClose,
  initialTaskDetails,
  account,
  selectedInFilterOfficeId,
  selectedInFilterUserId,
}) => {
  const [canClose, setCanClose] = useState<boolean>(true);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handleToggleClose = (canClose: boolean): void => {
    setCanClose(canClose);
  };

  const handleClose = (priorityClose?: boolean) => {
    if (canClose || priorityClose) {
      onClose();
    }

    if (typeof priorityClose === 'boolean') {
      setShowConfirm(false);
    } else {
      setShowConfirm(!canClose);
    }
  };

  return (
    <Overlay root="task-modal" onClose={() => handleClose()}>
      <Modal onClose={() => handleClose()}>
        <Container>
          {showConfirm && (
            <ConfirmModal
              dataTestId="close-modal-confirm-dialog"
              title={text.title}
              message={text.message}
              handleAction={handleClose}
              buttonConfirmTitle={text.buttonConfirmTitle}
            />
          )}
          <TaskModalContent
            selectedInFilterOfficeId={selectedInFilterOfficeId}
            selectedInFilterUserId={selectedInFilterUserId}
            account={account}
            onClose={onClose}
            initialTaskDetails={initialTaskDetails}
            handleToggleClose={handleToggleClose}
          />
        </Container>
      </Modal>
    </Overlay>
  );
};

const Container = styled.div<{}>`
  height: 100vh;
`;

export default TaskModal;
