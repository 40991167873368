import * as React from 'react';
import styled from 'styled-components';
import { navigate } from '@gatsbyjs/reach-router';
import BackLink from './BackLink';
import InputGroup from '~/components/bad/Inputs/InputGroup';
import SelectGroup from '~/components/molecule/SelectGroup';

type Props = {
  selectedIdx?: number;
  showBackLink?: boolean;
  location?: Location;
};

const AuthNav: React.FCC<Props> = ({
  showBackLink = false,
  selectedIdx,
  location,
}) => {
  const onTabChange = (option: { selectedOptionIdx: number }) => {
    const { search = '' } = location || {};
    if (option.selectedOptionIdx === 0) void navigate('/login/' + search);
    else void navigate('/register/' + search);
  };

  return (
    <AuthNavWrapper>
      <div>
        {selectedIdx !== undefined && (
          <InputGroup>
            <SelectGroup
              appearance="accent"
              onChange={onTabChange}
              selectedIndex={selectedIdx}
              options={[
                {
                  value: 1,
                  label: 'Inloggen',
                  dataTestId: 'auth-goto-login-btn',
                },
                {
                  value: 2,
                  label: 'Registreren',
                  dataTestId: 'auth-goto-register-btn',
                },
              ]}
            />
          </InputGroup>
        )}
      </div>

      {showBackLink && <BackLink />}
    </AuthNavWrapper>
  );
};

const AuthNavWrapper = styled.div<{}>`
  text-align: right;
  flex: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  & > div {
    margin-top: 0;
  }
`;

export default AuthNav;
