import React from 'react';
import styled, { css } from 'styled-components';

export type Props = {
  children: React.ReactNode;
  className?: string;

  maxWidth?: string;
};

const MasonryCard: React.FCC<Props> = ({
  children,
  className = '',
  dataTestId = '',
  maxWidth = '640px',
  ...rest
}) => (
  <Container
    className={className}
    data-testid={dataTestId}
    maxWidth={maxWidth}
    {...rest}
  >
    {children}
  </Container>
);

const Container = styled.div<{ maxWidth: string }>`
  min-height: 100px;
  display: inline-block;

  ${({ theme, maxWidth }) => css`
    width: 100%;
    max-width: ${maxWidth};
    padding: ${theme.space('l')} ${theme.space('l')};
    box-shadow: ${theme.boxShadow('s')};
    background-color: ${theme.color('white')};
    border-radius: ${theme.getTokens().border.radius.base};
    /** This should be same as column-gap of MasonryGrid container */
    margin-bottom: ${theme.space('xxl')};
  `}
`;

export default MasonryCard;
