import { MarkStyles } from '~/components/organism/PluginsEditor/components/Buttons/Mark';
import ELEMENTS from '~/components/organism/PluginsEditor/components/elements/elementsEnum';
import { DHEditor } from '../types';
import { Range, Transforms } from 'slate';
import { toggleMark } from '../commands/modify/mark';
import { getFullRange, getSelectedElement } from '../commands';
import { nestList } from '../commands/modify/list';
import resetEditor from '../commands/modify/general';

let pressedKeys: Array<string> = [];
let lastKeyTime = Date.now();

const onKeydown = (
  editor: DHEditor,
  e: React.KeyboardEvent<HTMLDivElement>,
  customElements?: Array<ELEMENTS>,
) => {
  const currentTime = Date.now();

  if (currentTime - lastKeyTime > 300) {
    pressedKeys = [];
  }

  pressedKeys.push(e.key);
  lastKeyTime = currentTime;

  const isModifier = e.ctrlKey || e.metaKey;

  const fullRange = getFullRange(editor);
  const allSelected = editor.selection
    ? Range.equals(editor.selection, fullRange)
    : false;

  /** Mark events */

  if (isModifier && e.key === 'b') {
    e.preventDefault();
    toggleMark(editor, MarkStyles.STRONG);
  }
  if (isModifier && e.key === 'i') {
    e.preventDefault();
    toggleMark(editor, MarkStyles.EM);
  }
  if (isModifier && e.key === 'u') {
    e.preventDefault();
    toggleMark(editor, MarkStyles.U);
  }

  /** List events */

  if (e.key === 'Tab') {
    e.preventDefault();
    const selectedEl = getSelectedElement(editor, 'highest');
    const elementType = selectedEl?.element.type;
    if (!elementType) return;

    if (elementType === ELEMENTS.UL || elementType === ELEMENTS.OL) {
      nestList(editor, elementType);
    }
  }

  if (allSelected) {
    if (!editor.selection) return;

    /**
     *  Fixes this issue: 'select all' and 'delete' does not delete everything when there is a list, blockquote etc
     *  https://github.com/ianstormtaylor/slate/issues/2500
     * */
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Enter') {
      resetEditor(editor, customElements);
    }

    return;
  }

  /** Table events */

  if (e.key === 'Enter') {
    const selectedEl = getSelectedElement(editor, 'all');

    if (
      selectedEl?.element.type === ELEMENTS.GENERIC_HTML_ELEMENT &&
      selectedEl.element.name === 'table'
    ) {
      e.preventDefault();
    }
  }

  /** General events */

  if (checkDoubleKeyPress('Enter', pressedKeys)) {
    e.preventDefault();
    pressedKeys = [];

    /** Insert a new empty line on two sequential Enter key press */
    Transforms.insertNodes(
      editor,
      {
        type: ELEMENTS.DIV,
        children: [{ text: '' }],
      },
      { mode: 'highest' },
    );
  }
};

const checkDoubleKeyPress = (keyName, pressedKeys) => {
  if (pressedKeys.length < 2) return false;

  return pressedKeys.filter(n => n === keyName).length > 1;
};

export default onKeydown;
