import React from 'react';
import { stringSlicer } from '~/util/string';
import { getUserName } from '~/graphql/utils';
import DropdownMenu from '../DropdownMenu/index';
import { navigate } from '@gatsbyjs/reach-router';
import { State } from '../MobileMenu/index';
import MobileDropdownMenu from '../MobileDropdownMenu/index';
import useCurrentUser from '~/hooks/useCurrentUser';
import useMenuOptions from '../../hooks/useMenuOptions';
import useViewingModeProps from '~/hooks/useViewingModeProps';
import type { SelectedOption } from '~/components/molecule/Dropdown';

export type Props = {
  onDropdownOpen?: (props: State) => void;
};

const UserMenu: React.FCC<Props> = ({ onDropdownOpen }) => {
  const me = useCurrentUser();
  const viewingModeProps = useViewingModeProps();

  const options = useMenuOptions();
  const src = me && me.img ? me.img.medium : null;
  const NAME_MAX_LENGTH = 14;
  const name =
    me && me.name ? stringSlicer(getUserName(me), NAME_MAX_LENGTH) : '';

  const onChange = (selectedOption: SelectedOption) => {
    const { option } = selectedOption;
    if (option) {
      const { payload } = option;
      if (payload) {
        if (payload.startsWith('http')) {
          global.window.open(payload, '_blank');
        } else {
          void navigate(payload);
        }
      }
    }
  };

  if (onDropdownOpen)
    return (
      <MobileDropdownMenu
        image={src}
        label={name}
        onClick={() => onDropdownOpen({ options, onChange })}
      />
    );

  return (
    <DropdownMenu
      label={name}
      userImage={src}
      options={options}
      onChange={onChange}
      redacted={viewingModeProps['data-redacted']}
    />
  );
};

export default UserMenu;
