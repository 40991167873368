import React, { useCallback, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import AsideHeader from '../AsideHeader';
import { animated, useSpring } from 'react-spring';

import { useRecoilState } from 'recoil';
import { Heading5, Variant } from '~/components/atom/Typography';
import Textarea from '~/components/bad/Inputs/Textarea';
import TEST_ID from './index.testid';
import { formState } from '../../../../state';
import useMeasure from '~/hooks/useMeasure';
import AnimatedCheckbox from '~/components/molecule/AnimatedCheckbox';
import JustificationContainer from '~/components/atom/JustificationContainer';
import useLocaleOptions, {
  type LocaleOption,
} from '../../../../hooks/useLocaleOptions';
import useIsBasicForm from '../../../../hooks/useIsBasicForm';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import { FormBuilderMode } from '~/graphql/types';
import useDHFlag from '~/hooks/useDHFlag';

type Props = {
  dataTestId?: string;
  expanded?: boolean;
  onToggleExpand: () => void;
};

const text = {
  emptyInputError: 'De naam mag niet leeg zijn',
  availableLocales: 'Beschikbare talen',
  name: 'Naam',
  description: 'Omschrijving',
  deactivateLabel: 'Deactiveer',
  activateLabel: 'Activeer',
};

const SettingsMenu: React.FCC<Props> = ({
  dataTestId,
  expanded = true,
  onToggleExpand,
}) => {
  const isDeveloper = useDHFlag('is-developer');
  const [state, setState] = useRecoilState(formState);
  const { ref, bounds } = useMeasure();
  const theme = useTheme();
  const isBasicForm = useIsBasicForm();

  // Do not allow to save the changes if there's an error
  const [error, setError] = useState<string | null>(null);

  const spring = useSpring({
    height: expanded ? bounds.height + theme.remToPxRaw(theme.space('m')) : 0,
  });

  const onChange = useCallback((key: 'name' | 'description', value: any) => {
    setState(prev => (prev ? { ...prev, [key]: value } : prev));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const localeOptions = useLocaleOptions();

  const onLocaleSelect = (option: LocaleOption) =>
    setState(prev => {
      if (!prev) return prev;

      const availableLocale = prev?.style.availableLocale ?? [];
      const isOptionIncluded = availableLocale.includes(option.key);
      const newAvailableLocale = isOptionIncluded
        ? availableLocale.filter(locale => locale !== option.key)
        : [...availableLocale, option.key];

      return {
        ...prev,
        style: {
          ...prev?.style,
          availableLocale: newAvailableLocale,
        },
      };
    });

  return (
    <Container data-testid={dataTestId}>
      <AsideHeader
        dataTestId={TEST_ID.ASIDE_HEADER}
        heading="Instellingen"
        icon="gear"
        onClick={onToggleExpand}
        expanded={expanded}
      />
      <OverflowContainer style={spring}>
        <Inner ref={ref}>
          <InputContainer>
            {isDeveloper && (
              <ToggleCheckbox
                label="Basic form"
                onChange={() => {
                  // @ts-ignore we need to pass null to mode
                  setState(prev => {
                    if (!prev) return prev;
                    return {
                      ...prev,
                      mode:
                        prev.mode === FormBuilderMode.Basic
                          ? null
                          : FormBuilderMode.Basic,
                    };
                  });
                }}
                value={isBasicForm}
              />
            )}
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>{text.name}</Heading5>
            <Textarea
              dataTestId={TEST_ID.NAME}
              name="name"
              error={error}
              label={error ?? ''}
              value={state?.name ?? ''}
              onChange={value => {
                if (isNil(value) || isEmpty(value)) {
                  setError(text.emptyInputError);
                  onChange('name', '');
                  return;
                }
                setError(null);
                onChange('name', value);
              }}
            />
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>{text.description}</Heading5>
            <Textarea
              dataTestId={TEST_ID.DESCRIPTION}
              name="description"
              value={state?.description ?? ''}
              onChange={value => {
                if (isNil(value) || isEmpty(value)) {
                  onChange('description', null);
                  return;
                }
                onChange('description', value);
              }}
            />
          </InputContainer>
          <InputContainer>
            <Heading5 variant={Variant.primary}>
              {text.availableLocales}
            </Heading5>
            <JustificationContainer direction="column" gap="xxs">
              {localeOptions.map(option => (
                <AnimatedCheckbox
                  key={option.key}
                  label={option.label}
                  value={
                    state?.style.availableLocale.includes(option.key) ?? false
                  }
                  selectable
                  onChange={() => onLocaleSelect(option)}
                />
              ))}
            </JustificationContainer>
          </InputContainer>
          <InputContainer>
            <ToggleCheckbox
              size="small"
              label={state?.enabled ? text.deactivateLabel : text.activateLabel}
              onChange={() => {
                setState(prev => {
                  if (!prev) return prev;

                  return {
                    ...prev,
                    enabled: !prev?.enabled,
                  };
                });
              }}
              value={state?.enabled ?? false}
              containerProps={{
                justification: 'space-between',
                direction: 'row',
                align: 'center',
                width: '100%',
              }}
            />
          </InputContainer>
        </Inner>
      </OverflowContainer>
    </Container>
  );
};

const Container = styled.aside(
  ({ theme }) => css`
    z-index: ${theme.z('top')};
    user-select: none;
    width: 100%;
  `,
);

const Inner = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.space('m')};
  `,
);

const InputContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.space('xxs')};
  `,
);

const OverflowContainer = styled(animated.div)`
  overflow: hidden;
`;

export default SettingsMenu;
