import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import ButtonWithTooltip from '~/components/molecule/ButtonWithTooltip';
import type { OptionOf } from '~/components/molecule/Dropdown';
import Dropdown from '~/components/molecule/Dropdown';
import Input from '~/components/molecule/Input';
import { fieldById } from '~/components/page/Forms/components/Builder/state/nodesAndEvents';
import { optionListsState } from '~/components/page/Forms/components/Builder/state/optionList';
import {
  FormBuilder_Event_Field,
  FormBuilder_Event_MetaField,
  FormBuilder_OptionList,
  FormBuilder_PrimitiveType,
} from '~/graphql/types';

const text = {
  required: {
    Email:
      'E-mail is een verplicht veld. Jouw contact is aangemaakt met dit e-mailadres.',
  },
};

type Props = {
  field: FormBuilder_Event_Field;
  eventId: string;
  onDelete: (field: FormBuilder_Event_Field) => void;
};

const isRequired = (field: FormBuilder_Event_Field): boolean =>
  field.metaField === 'Contact_Email';

const FieldEditor: React.FCC<Props> = ({ field, eventId, onDelete }) => {
  const optionLists = useRecoilValue(optionListsState);
  const [stateField, setStateField] = useRecoilState(
    fieldById({ eventId, fieldId: field.key }),
  );
  const options = getDropdownOptions(optionLists);
  const selectedOptionIndex = options.findIndex(({ payload }) => {
    if (
      stateField?.type.__typename === 'FormBuilder_Event_Field_Type_Primitive'
    ) {
      return payload === stateField.type.type;
    }
    if (stateField?.type.__typename === 'FormBuilder_Event_Field_Type_Option') {
      return payload === stateField.type.id;
    }
    return false;
  });

  return (
    <JustificationContainer gap="m" width="100%" align="end">
      <Input
        width="100%"
        label="Veld naam"
        value={stateField?.name ?? ''}
        onChange={e => {
          setStateField(prev => {
            if (!prev) return prev;

            return { ...prev, name: e.target.value };
          });
        }}
      />
      <Dropdown
        label="Veld type"
        options={options}
        disabled={field.metaField === FormBuilder_Event_MetaField.ContactEmail}
        selectedOptionIdx={selectedOptionIndex}
        onChange={selection => {
          setStateField(prev => {
            if (!prev) return prev;
            if (isPrimitiveType(selection.option.payload)) {
              return {
                ...prev,
                type: {
                  __typename: 'FormBuilder_Event_Field_Type_Primitive',
                  type: selection.option.payload,
                },
              };
            }
            return {
              ...prev,
              type: {
                __typename: 'FormBuilder_Event_Field_Type_Option',
                id: selection.option.payload,
              },
            };
          });
        }}
      />

      {field.metaField && <span>Metafield: {field.metaField}</span>}

      {isRequired(field) ? (
        <ButtonWithTooltip
          icon="delete"
          onClick={() => onDelete(field)}
          appearance="danger"
          size="medium"
          disabled
          tooltip={{ message: text.required.Email }}
        />
      ) : (
        <Button
          icon="delete"
          onClick={() => onDelete(field)}
          appearance="danger"
          size="medium"
        />
      )}
    </JustificationContainer>
  );
};

const isPrimitiveType = (
  subject: any,
): subject is FormBuilder_PrimitiveType => {
  const primitiveArray = Object.keys(FormBuilder_PrimitiveType);
  return primitiveArray.includes(subject);
};

const getDropdownOptions = (
  optionLists: Array<FormBuilder_OptionList>,
): Array<
  OptionOf<FormBuilder_PrimitiveType | FormBuilder_OptionList['id']>
> => [
  {
    key: 'String',
    label: 'String',
    payload: FormBuilder_PrimitiveType.String,
  },
  {
    key: 'Number',
    label: 'Number',
    payload: FormBuilder_PrimitiveType.Number,
  },
  {
    key: 'Email',
    label: 'Email',
    payload: FormBuilder_PrimitiveType.Email,
  },
  ...optionLists.map(({ id, name }) => ({
    label: name,
    key: id,
    payload: id,
  })),
];

export default FieldEditor;
