import React from 'react';
import {
  Handle,
  NodeProps,
  Position,
  getIncomers,
  useEdges,
  useNodes,
  type Node,
} from 'reactflow';
import styled, { css } from 'styled-components';
import type { CommonNodeData } from '../../..';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Icon from '~/components/atom/Icon';
import { useSetRecoilState } from 'recoil';
import { menuState } from '../../../../../state';

type NodeData = CommonNodeData & {
  value: number;
};

const TrailingNode: React.FC<NodeProps<NodeData>> = ({
  isConnectable,
  id,
  xPos,
  yPos,
}) => {
  const nodes = useNodes();
  const edges = useEdges();
  const currentNode = nodes.find(node => node.id === id) as Node<unknown>;

  const setMenuState = useSetRecoilState(menuState);

  const x = xPos + 70 / 2;
  const y = yPos + 50;

  const [firstIncomer] = getIncomers(currentNode, nodes, edges);

  return (
    <Container>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <Button
        onClick={() => {
          setMenuState({
            openedOnEdge: false,
            edgeId: id,
            targetX: x,
            targetY: y,
            sourceId: firstIncomer.id,
            targetId: id,
          });
        }}
        type="button"
      >
        <JustificationContainer justification="center" align="center">
          <Icon name="plus" />
          &nbsp;<Label>Toevoegen</Label>
        </JustificationContainer>
      </Button>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 200px;
    height: 5rem;
    padding: ${theme.space('l')};

    border-radius: ${theme.getTokens().border.radius.base};

    background-color: ${theme.color('grey', 'translucent')};
    color: ${theme.color('text', 'light')};
  `,
);

const Button = styled.button<{}>(
  ({ theme }) => css`
    /* Font-size to scale the Icon */
    font-size: ${theme.fs('l')};
    padding: ${theme.space('l')};

    border: none;
    border-radius: ${theme.getTokens().border.radius.base};

    cursor: pointer;
    user-select: none;

    background-color: transparent;

    transition: all 0.3s ease-out;

    &:hover {
      color: ${theme.color('primary', 'dark')};
    }
  `,
);

const Label = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fs('base')};
    font-weight: ${theme.fw('semiBold')};
    margin: 0;
  `,
);

export default TrailingNode;
