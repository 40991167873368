import React from 'react';

import TEST_ID from './index.testid';
import Loading from '~/components/atom/Loading';
import { Body } from '~/components/atom/Typography';
import JustificationContainer from '~/components/atom/JustificationContainer';
import styled, { css } from 'styled-components';
import Divider from '~/components/atom/Divider';
import AmountRow from './components/AmountRow';

export type Props = {
  /** Lock mutation loading */
  loading: boolean;

  /** Amount returned from lock mutation */
  lockedAmount: number;

  /** Total selected contact amount */
  selectedContactAmount: number;

  /** Default body message */
  message?: string;
};

export const text = {
  selectedAmount: 'Geselecteerd aantal:',
  currentAmount: 'Huidig aantal:',
  contactAmountDoesNotMatchWarning:
    'Er zijn wijzigingen gemaakt aan de contactenlijst. Het aantal contacten is veranderd.',
  contactCount: (count: number) =>
    `Deze actie wordt toegepast op ${count} ${
      count === 1 ? 'contact' : 'contacten'
    }. Weet je het zeker?`,
};

const LockedContactsMessage: React.FCC<Props> = ({
  loading,
  lockedAmount,
  selectedContactAmount,
  message,
}) => {
  if (loading) return <Loading />;

  if (selectedContactAmount !== lockedAmount) {
    return (
      <>
        <Body withoutMargin>{text.contactCount(lockedAmount)}</Body>
        <Explanation
          direction="column"
          gap="m"
          padding={['m']}
          margin={['l', null]}
        >
          <Body withoutMargin dataTestId={TEST_ID.WARNING_MESSAGE}>
            {text.contactAmountDoesNotMatchWarning}
            <br />
          </Body>
          <Divider color={{ group: 'warning' }} margin={[null]} />
          <JustificationContainer align="center" gap="l">
            <AmountRow
              label={text.selectedAmount}
              amount={selectedContactAmount}
            />
            <AmountRow label={text.currentAmount} amount={lockedAmount} />
          </JustificationContainer>
        </Explanation>
      </>
    );
  }

  return (
    <Body dataTestId={TEST_ID.INFO_MESSAGE}>
      {message ?? text.contactCount(lockedAmount)}
    </Body>
  );
};

const Explanation = styled(JustificationContainer)(
  ({ theme }) => css`
    background-color: ${theme.color('warning', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
  `,
);

export default LockedContactsMessage;
