import { selector, selectorFamily } from 'recoil';
import { formState } from '.';
import getIssuesForNodes from '../utils/getIssuesForNodes';
import { FormBuilderMode, type FormBuilder_Locale } from '~/graphql/types';
import getIssuesForForm from '../utils/getIssuesForForm';
import { submitScreenState } from './submitScreen';
import { contactInputBlocksUsed } from './contactInputBlocks';
import { isNil } from 'ramda';

const text = {
  missingSubmitEvent:
    'Er worden geen gebeurtenissen verzonden vanaf het formulier. Voeg een Verzendscherm toe om een gebeurtenis te ontvangen op de pagina van je contactpersoon.',
  contactEmailMissing:
    'Er moet een contact-e-mailadres in het formulier, voeg het toe in een van de schermen.',
};

export type Issue = {
  level: 'error' | 'warning';
  nodeId?: string | null;
  blockKey?: string | null;
  optionKey?: string | null;
  localeKey?: FormBuilder_Locale;
  message: string;
};

export const issueState = selector<Array<Issue>>({
  key: 'formBuilder/issueState',
  get: ({ get }) => {
    const form = get(formState);
    if (!form) return [];

    const isBasicForm = form.mode === FormBuilderMode.Basic;
    const submitScreenMissing = get(submitScreenState) == null;
    const contactEmailMissing = isNil(
      get(contactInputBlocksUsed).Contact_Email,
    );

    const issues: Array<Issue> = [
      ...getIssuesForForm(form),
      ...getIssuesForNodes(form),
    ];

    if (isBasicForm && submitScreenMissing) {
      issues.push({
        level: 'warning',
        message: text.missingSubmitEvent,
      });
    }

    if (contactEmailMissing) {
      issues.push({
        level: 'error',
        message: text.contactEmailMissing,
      });
    }

    return issues;
  },
});

export const issuesByPath = selectorFamily<
  Array<Issue | null>,
  [string, string] | [string]
>({
  key: 'formBuilder/issuesByBlockId',
  get:
    pathToBlock =>
    ({ get }) => {
      const issues = get(issueState);
      if (!issues) return [];

      const issuesForNode = issues.filter(
        issue => issue.nodeId === pathToBlock[0],
      );

      if (!pathToBlock[1]) return issuesForNode;

      const issuesForBlock = issuesForNode.filter(
        issue => issue.blockKey === pathToBlock[1],
      );

      return issuesForBlock;
    },
});
