import React, { useState, useEffect } from 'react';
import Amplify from '../../amplify';
import possibleTypes from '~/graphql/possibleTypes.json';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  from,
  ServerError,
  ServerParseError,
  FetchResult,
  Observable,
} from '@apollo/client';
import { ErrorHandler, onError } from '@apollo/client/link/error';
import type { Observable as ApolloObservable } from '@apollo/client/utilities';

import isSubscriptionOperation from './utils/isSubscriptionOperation';
import createSubscriptionWebsocketLink from './CognitoOpenID/createSubscriptionWebsocketLink';
import createHttpLink from './CognitoOpenID/createHttpLink';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import ErrorTypes from '~/ErrorTypes';
import { getErrorType } from '~/util/errorHandling';
import { cond, equals, T } from 'ramda';
import {
  mergeInfinitePaginatedItems,
  readInfinitePaginatedItems,
} from './utils/pagination';
import { reporter } from '~/hooks/useErrorReporter';

const unauthPaths = [
  '/login',
  '/register',
  '/verify',
  '/forgot-password',
  '/setup-newpassword',
  '/setup-user-details',
];

const isUnAuthPath = (pathname: string): boolean => {
  for (const path of unauthPaths) {
    if (pathname.startsWith(path)) return true;
  }
  return false;
};

const useDHApolloClient = () => {
  const [client, setClient] = useState<ApolloClient<any> | null>(null);
  const location = useLocation();

  useEffect(() => {
    const createApolloClient = async () => {
      const region = process.env.AWS_REGION;
      const url = process.env.API_ENDPOINT;

      if (!url || !region) {
        throw Error(`Some environment variables are missing, you probably forgot to make your .env file.
          API_ENDPOINT=${url}
          AWS_REGION=${region}`);
      }

      const appSyncApiUrl = url;
      const getJWTToken = async () => {
        try {
          const session = await Amplify.Auth.currentSession();
          return session.getAccessToken().getJwtToken();
        } catch (error) {
          if (
            error &&
            typeof error === 'string' &&
            error.includes('No current user')
          ) {
            if (isUnAuthPath(location.pathname)) {
              return navigate(`${location.pathname}${location.search}`, {
                state: {
                  ...(location.state ?? {}),
                  from: location.pathname,
                },
              });
            }

            return navigate(`/login${location.search}`, {
              state: {
                ...(location.state ?? {}),
                from: location.pathname,
              },
            });
          }
        }
      };

      const wsLink = await createSubscriptionWebsocketLink({
        appSyncApiUrl,
        getJWTToken,
      });

      if (!wsLink) {
        reporter.captureException(
          new Error('Unable to create WebSocket Link for Apollo client'),
        );
        return;
      }

      const subscriptionLink = ApolloLink.split(
        isSubscriptionOperation,
        wsLink,
        createHttpLink({ appSyncApiUrl, getJWTToken }),
      );

      const errorLink = onError(errorHandler);
      const link = from([errorLink, subscriptionLink]);

      try {
        const client = new ApolloClient({
          defaultOptions: {
            mutate: {
              errorPolicy: 'all',
            },
            query: {
              errorPolicy: 'all',
              fetchPolicy: 'network-only',
            },
            watchQuery: {
              errorPolicy: 'all',
              fetchPolicy: 'cache-and-network',
              nextFetchPolicy: 'cache-first',
            },
          },
          link,
          cache: new InMemoryCache({
            possibleTypes,
            typePolicies: {
              Flow___InstanceCondition: {
                keyFields: false,
              },
              ContactFiltersFlattened: {
                keyFields: false,
              },
              Flow___SubjectFieldCondition: {
                keyFields: false,
              },
              ContactWithLastActivity: {
                keyFields: false,
              },
              Query: {
                fields: {
                  getInvoices: {
                    keyArgs: false,
                    merge: mergeInfinitePaginatedItems,
                    read: readInfinitePaginatedItems,
                  },
                  getCreditInvoices: {
                    keyArgs: false,
                    merge: mergeInfinitePaginatedItems,
                    read: readInfinitePaginatedItems,
                  },
                },
              },
            },
          }),
        });
        setClient(client);
      } catch (error) {
        reporter.captureException(error);
      }
    };

    void createApolloClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return client;
};

const handleGraphQLError = async (errorType: string | null): Promise<void> =>
  cond([
    [equals(ErrorTypes.accountLockedError), () => navigate('/-/locked')],
    [equals(ErrorTypes.accountPausedError), () => navigate('/-/paused')],
    [equals(ErrorTypes.accountCancelledError), () => navigate('/-/cancelled')],
    [equals(ErrorTypes.refreshRequiredError), () => window.location.reload()],

    // Default
    [T, () => {}],
  ])(errorType);

const handleNetworkError = async (
  error: Error | ServerError | ServerParseError,
): Promise<void> =>
  cond([
    [equals('No current user'), () => navigate('/logout')],

    // Default
    [T, () => {}],
  ])(error);

const errorHandler: ErrorHandler = ({
  graphQLErrors,
  networkError,
}): ApolloObservable<FetchResult> | void => {
  if (networkError)
    return convertToObservable(handleNetworkError(networkError));
  if (Array.isArray(graphQLErrors)) {
    for (const graphQLError of graphQLErrors) {
      return convertToObservable(
        handleGraphQLError(getErrorType(graphQLError)),
      );
    }
  }
};

const AppSyncProvider = ({
  children,
}: {
  children: any;
}): JSX.Element | null => {
  const client = useDHApolloClient();
  if (!client) return null;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
export default AppSyncProvider;

/**
 * Converts Promise to Observable
 *
 * errorHandler is supposed to return an Observable or void, it cannot return a promise
 * Without this conversion we get an error 'retriedResult.subscribe is not a function' in Paused, Locked, Cancelled pages
 *
 * check node_modules/@apollo/client/link/error/index.js to see where errorHandler is used
 */
const convertToObservable = (
  promise: Promise<void>,
): ApolloObservable<FetchResult> | void => {
  if (promise)
    new Observable(observer => {
      promise
        .then(result => {
          observer.next(result);
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
};
