import React from 'react';
import PaginationButton from '~/components/bad/DataTables/components/PaginationButton';
import Icon from '~/components/atom/Icon';
import useInfinitePagination from '~/components/bad/util/useInfinitePagination';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import cleanedFilename from '~/util/cleanedFilename';
import composeInvoiceListData from '../../util/composeInvoiceListData';
import invoiceListColumns from '../../util/invoiceListColumns';
import InvoiceListTable from '../InvoiceListTable';
import PaginationContainer from '../PaginationContainer';
import TEST_ID from './index.testid';
import { useGetCreditInvoicesQuery } from '~/graphql/types';
import DatHuisLoading from '~/components/atom/DatHuisLoading';

type Props = {
  limit: number;
};
const CreditInvoices: React.FCC<Props> = ({ limit }) => {
  const account = useCurrentAccount();
  const [currentPage, goToPreviousPage, goToNextPage, grabItems, knownPages] =
    useInfinitePagination(limit);

  const variables = { accountId: account.id, limit };

  const { data, loading, networkStatus, fetchMore, error } =
    useGetCreditInvoicesQuery({
      variables,
      notifyOnNetworkStatusChange: true,
    });

  const invoices = data?.getCreditInvoices?.items;

  if (!loading && (error || !invoices)) {
    return <DatHuisLoading />;
  }

  const nextToken = data?.getCreditInvoices?.nextToken;

  const loadMore = () =>
    fetchMore({
      variables: {
        ...variables,
        nextToken: nextToken || null,
      },
    });

  return (
    <>
      <InvoiceListTable
        loading={loading}
        networkStatus={networkStatus}
        columns={invoiceListColumns(true)}
        data={composeInvoiceListData(grabItems(invoices), variables)}
        dataTestId={TEST_ID.CONTAINER}
      />
      <PaginationContainer>
        <PaginationButton
          disabled={knownPages === currentPage && nextToken == null}
          chevronItem
          data-testid={TEST_ID.GO_TO_NEXT_BUTTON}
          onClick={() => {
            if (knownPages === currentPage && nextToken != null) {
              loadMore()
                .then(() => {
                  goToNextPage();
                })
                .catch(e => {
                  throw Error(`${cleanedFilename(__filename)} | Error ${e}`);
                });
            } else {
              goToNextPage();
            }
          }}
        >
          <Icon name="chevron" clockwise={-90} />
        </PaginationButton>
        <PaginationButton
          disabled={currentPage === 1}
          chevronItem
          data-testid={TEST_ID.GO_TO_PREVIOUS_BUTTON}
          onClick={() => {
            goToPreviousPage();
          }}
        >
          <Icon name="chevron" clockwise={90} />
        </PaginationButton>
      </PaginationContainer>
    </>
  );
};

export default CreditInvoices;
