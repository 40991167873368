import React, { Component } from 'react';
import { omit } from 'ramda';
import styled, { css } from 'styled-components';
import { convertToFullTimeString, asFullTimeField } from '~/util/time';
import Input from '~/components/bad/Inputs/Input';
import { format } from '~/util/date';
import { getDisabledInputStyle } from '~/components/bad/Inputs/InputElement';
import { SelectedOption, Option } from '~/components/molecule/Dropdown';
import DropdownListContainer from '~/components/molecule/Dropdown/components/DropdownListContainer';
import HidableComponent from '../atom/HidableComponent';

export type TimeInputComponentType = 'DEFAULT' | 'BORDERLESS';
type Props = {
  name: string;
  size: string;
  value: string | null | undefined;
  setValue: (val: React.ReactNode | null) => any;

  /** The component type to use to display the input field */
  inputComponentType?: TimeInputComponentType;

  disabled?: boolean;
};
type State = {
  dropdownListOpen: boolean;
};
export default class TimePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dropdownListOpen: false,
    };
  }

  selectOption = (selectedOption: SelectedOption) => {
    this.props.setValue(
      selectedOption.option ? selectedOption.option.label : null,
    );
    this.setState({
      dropdownListOpen: false,
    });
  };

  handleInput = (value: string) => {
    this.props.setValue(sanitize(value));
    this.setState({ dropdownListOpen: false });
  };

  hideList = () => {
    this.setState({ dropdownListOpen: false });
  };

  toggleList = () => {
    this.setState(prevState => ({
      dropdownListOpen: !prevState.dropdownListOpen,
    }));
  };

  ensureFullTimefield = () => {
    const { value, setValue } = this.props;
    const fullValue = asFullTimeField(value);

    if (fullValue !== value) setValue(fullValue);
  };

  render() {
    const { dropdownListOpen } = this.state;
    const { inputComponentType, disabled, ...rest } = this.props;

    const inputProps = {
      ...rest,
      size: '5',
      placeholder: 'hh:mm',
      onFocus: this.toggleList,
      onBlur: this.ensureFullTimefield,
      onChange: (event: Event) => {
        const target = event.target;
        if (target instanceof HTMLInputElement) {
          this.handleInput(target.value);
        }
      },
    };

    const inputComponent =
      inputComponentType && inputComponentType === 'BORDERLESS' ? (
        // There is a clash between the type of value for input fields from styled-components and our value.
        // setting the type of value in the props of the styled-component has no effect.
        // TODO: Investigate further
        //@ts-expect-error
        <TimeInput {...omit(['size'], inputProps)} $disabled={disabled} />
      ) : (
        <Input {...inputProps} />
      );

    const { value } = this.props;
    const options = timeOptions();

    let selectedOptionIdx: number | undefined = undefined;

    const currentTimeDate = format(new Date(), 'H:mm');

    const timeValue = value || getRoundedTime(currentTimeDate);

    options.forEach((item, index) => {
      if (item.key === timeValue) {
        selectedOptionIdx = index;
      }
    });

    return (
      <HidableComponent onClickOutside={this.hideList} allowBubble={true}>
        {ref => (
          <Container ref={ref}>
            {inputComponent}
            {dropdownListOpen && (
              <DropdownListContainer
                dropdownListOpen={dropdownListOpen}
                selectedOptionIdx={selectedOptionIdx}
                options={options}
                onChange={this.selectOption}
                onClose={() =>
                  this.setState({
                    dropdownListOpen: false,
                  })
                }
              />
            )}
          </Container>
        )}
      </HidableComponent>
    );
  }
}

const TimeInput = styled.input<{
  $disabled?: boolean;
  value: string | null | undefined;
}>`
  border: 0;
  text-align: center;
  ${({ theme }) => css`
    color: ${theme.color('primary', 'light')};
  `};
  ${({ theme, $disabled }) => $disabled && getDisabledInputStyle(theme)}
`;

const Container = styled.div<{}>`
  position: relative;
  cursor: pointer;

  ${({ theme }) => css`
    margin: 0 ${theme.space('s')};
  `};
`;

const timeOptions = (): Array<Option> => {
  const times: Array<Option> = [];
  for (let h = 0; h < 24; h++) {
    for (let q = 0; q < 4; q++) {
      const time = convertToFullTimeString(h, q * 15);
      times.push({
        label: time,
        payload: null,
        key: time,
      });
    }
  }
  return times;
};

export const getRoundedTime = (timeValue: string): string => {
  const minsIndex = timeValue.indexOf(':') + 1;
  const hours = timeValue.slice(0, minsIndex - 1);
  const mins = parseInt(timeValue.slice(minsIndex));

  const roundedMins = mins / 30 > 1 ? '30' : '00';
  return `${hours}:${roundedMins}`;
};

const sanitize = (input: string): string => input.replace(/[^\d:]/g, '');
