import { truncate } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
import useCurrentBreakpoint from '~/hooks/useCurrentBreakpoint';

import Button from '~/components/atom/Button';
import Icon from '~/components/atom/Icon';

import { Body } from '~/components/atom/Typography';
import Link from '~/components/molecule/Link';

export type Props = {
  path: Array<string>;
  link: string;
  label: string;
};

const GoToPath: React.FCC<Props> = ({
  dataTestId,
  path,
  link,
  label,
  ...rest
}) => {
  const currentBp = useCurrentBreakpoint();
  return (
    <Container data-testid={dataTestId} {...rest}>
      <ChunkList>
        {path.map((chunk, index) => (
          <Chunk key={chunk}>
            <ChunkLabel withoutMargin title={chunk}>
              {['tablet', 'mobile'].includes(currentBp) &&
              index + 1 !== path.length
                ? truncate(chunk, { length: 4 })
                : chunk}
            </ChunkLabel>
            {/* Don't show icon on last item */}
            {index + 1 !== path.length && (
              <DividingIcon name="arrow" clockwise={90} />
            )}
          </Chunk>
        ))}
      </ChunkList>
      <Link to={link} style={{ textDecoration: 'none' }} target="_blank">
        <Button label={label} size="medium" />
      </Link>
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    padding: ${theme.space('s')};
    margin-bottom: ${theme.space('base')};
    background-color: ${theme.color('primary', 'translucent')};
    border-radius: ${theme.getTokens().border.radius.base};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${theme.mq.lessThan('tablet')`
      padding: ${theme.space('xxs')};
      flex-direction: column;

      justify-content: center;
      align-items: flex-start;
    `}
  `,
);

const ChunkList = styled.ul<{}>`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ theme }) => css`
    margin: 0 ${theme.space('s')} 0 0;
    padding: 0 0 0 ${theme.space('base')};
    ${theme.mq.lessThan('tablet')`
      width: 100%;
      justify-content: space-evenly;
      padding: 0 0 0 ${theme.space('xxs')};
      margin-bottom: ${theme.space('xxs')};
    `}
  `}
`;

const DividingIcon = styled(Icon)<{}>(
  ({ theme }) => css`
    margin: 0 ${theme.space('s')};
    color: ${theme.color('primary', 'light')};
    ${theme.mq.lessThan('tablet')`
      margin: 0 ${theme.space('xxs')};
    `}
  `,
);

const Chunk = styled.li<{}>`
  display: flex;
  white-space: nowrap;
  cursor: default;
`;

const ChunkLabel = styled(Body)<{ title?: string }>``;

export default GoToPath;
