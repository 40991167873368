import { isNil } from 'ramda';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Icon, { IconType } from '~/components/atom/Icon';
import JustificationContainer from '~/components/atom/JustificationContainer';
import {
  PlanFeatureAppearance,
  PlanFeatureFragment,
  PlanOptionFragment,
} from '~/graphql/types';
import { SystemColorPalette } from '~/theme';

export type Props = {
  items: Array<PlanFeatureFragment>;
  highlightedCaveat?: null | number;
  showCaveats?: boolean;
  previousTier?: PlanOptionFragment;
};

const text = {
  includedTemplate: (name: string) => `Alles in ${name}, plus:`,
};

const Features: React.FC<Props> = ({
  items,
  highlightedCaveat,
  showCaveats,
  previousTier,
}) => (
  <>
    {previousTier && (
      <Feature
        feature={{
          __typename: 'PlanFeature',
          // appearance: PlanFeatureAppearance.Included,
          caveats: [],
          emphasized: true,
          text: text.includedTemplate(previousTier.name),
        }}
      />
    )}
    {items.map((item, index) => (
      <Feature
        feature={item}
        key={index}
        highlightedCaveat={highlightedCaveat}
        showCaveats={showCaveats}
      />
    ))}
  </>
);

export const Feature: React.FC<{
  feature: Omit<PlanFeatureFragment, 'appearance'> & {
    appearance?: PlanFeatureAppearance;
  };
  highlightedCaveat?: null | number;
  showCaveats?: boolean;
}> = ({ feature, highlightedCaveat, showCaveats }) => {
  const theme = useTheme();
  return (
    <Container
      align="start"
      key={`highlight-${feature}`}
      $highlight={
        !isNil(highlightedCaveat)
          ? feature.caveats.includes(highlightedCaveat)
          : false
      }
    >
      <Icon
        name={feature.appearance ? iconMap[feature.appearance].name : 'star'}
        color={
          feature.appearance
            ? theme.color(iconMap[feature.appearance].color)
            : 'transparent'
        }
      />
      <Item $emphasized={feature.emphasized}>
        {feature.text}
        {showCaveats &&
          !isNil(feature.caveats) &&
          feature.caveats.map((caveat, index) => (
            <CaveatIndexContainer key={`caveat-${index}-${caveat}`}>
              {(caveat + 1).toString()}
            </CaveatIndexContainer>
          ))}
      </Item>
    </Container>
  );
};

const iconMap: Record<
  PlanFeatureAppearance,
  { name: IconType; color: keyof SystemColorPalette }
> = {
  Excluded: { name: 'close', color: 'danger' },
  Included: { name: 'check', color: 'success' },
};

const Container = styled(JustificationContainer)<{ $highlight: boolean }>(
  ({ $highlight, theme }) => css`
    box-shadow: ${$highlight ? theme.boxShadow('around') : 'unset'};
    transform: scale(${$highlight ? 1.1 : 1});
    transition: all 0.2s ease-out;
    width: 100%;
    padding: calc(${theme.space('xs')} / 2) 0;
  `,
);

const Item = styled.div<{
  $emphasized?: boolean;
  $only?: boolean;
}>(
  ({ theme, $emphasized = false, $only }) => css`
    font-size: ${theme.fontSize('s')};
    margin-left: ${$only ? '0' : theme.space('s')};
    position: relative;
    text-decoration: ${$emphasized ? 'underline' : 'none'};
    font-weight: ${$emphasized ? theme.fw('semiBold') : theme.fw('regular')};
  `,
);

export const CaveatIndexContainer = styled.small(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    font-weight: ${theme.fw('regular')};
  `,
);

export default Features;
