import { isEmpty, isNil } from 'lodash';
import { has } from 'ramda';
import { SortDirection } from '~/graphql/types';
import { ParsedSettings } from '../..';

/**
 * returns parsed and valid object of sort settings
 * @param {string} savedSettings - sort settings from localStorage
 * keywords: sortSettings, sortField, sortDirection
 */

const retrieveValidObject = (
  savedSettings: string | null | undefined,
): ParsedSettings | null => {
  if (isNil(savedSettings) || isEmpty(savedSettings)) return null;

  const parsedSettings: ParsedSettings = JSON.parse(savedSettings);

  const hasProperties =
    has('sortField', parsedSettings) && has('sortDirection', parsedSettings);

  const { sortField, sortDirection } = parsedSettings;

  const hasValidSortField =
    typeof sortField === 'string' && !isEmpty(sortField);
  const hasValidDirection =
    sortDirection === SortDirection.Asc || sortDirection === SortDirection.Desc;

  if (hasProperties && hasValidSortField && hasValidDirection) {
    return parsedSettings;
  }
  return null;
};

export default retrieveValidObject;
